import { IUserMini } from "../types/user";
import { FC, Fragment } from "react";
import UserAvatar from "./UserAvatar";
import { SidePopupV2 } from "./DismissablePopup";
import "../style/components/UserAvatarList.scss";

export interface UserAvatarListUser extends Omit<IUserMini, "sub"> {
  isInvite?: boolean;
}

export interface IUserAvatarListProps {
  users?: UserAvatarListUser[];
  maxShown?: number;
}

const maxUsersListedInMorePopup = 5;

const UserAvatarList: FC<IUserAvatarListProps> = ({
  users = [],
  maxShown = 0,
}) => {
  if (users.length === 0) {
    return null;
  }

  const shownUsers = maxShown > 0 ? users.slice(0, maxShown) : users;
  const hiddenUsers = maxShown > 0 ? users.slice(maxShown) : [];
  const hiddenUsersInPopup =
    maxShown > 0 ? hiddenUsers.slice(0, maxUsersListedInMorePopup) : [];

  return (
    <div className="user-avatar-list">
      {shownUsers.map((u) => (
        <SidePopupV2
          key={u.id}
          className="user-avatar-list-item"
          micro
          noWrap
          position="top"
          text={
            <>
              {u.name && (
                <>
                  {u.name}
                  <br />
                </>
              )}
              <em>{u.email}</em>
              {!!u.isInvite && (
                <>
                  <br />
                  (invited)
                </>
              )}
            </>
          }
        >
          <UserAvatar avatar={u.avatar} />
        </SidePopupV2>
      ))}
      {hiddenUsers.length > 0 && (
        <SidePopupV2
          className="more-text"
          noWrap
          micro
          text={
            <>
              {hiddenUsersInPopup.map((u) => (
                <Fragment key={u.id}>
                  {u.name ? (
                    <>
                      {u.name} ({u.email})
                    </>
                  ) : (
                    u.email
                  )}
                  <br />
                </Fragment>
              ))}
              {hiddenUsersInPopup.length < hiddenUsers.length && (
                <em>+ {hiddenUsers.length - hiddenUsersInPopup.length} more</em>
              )}
            </>
          }
        >
          + {hiddenUsers.length} more
        </SidePopupV2>
      )}
    </div>
  );
};

export default UserAvatarList;
