import { FC, useState } from "react";
import Button from "../core/Button";
import {
  fetchSharedAssessmentsList,
  fetchUserData,
} from "../../reducers/commonActions";
import TextField from "../TextField";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import { trackEvent } from "../../tracking";
import { DefaultThunkDispatch } from "../../types/redux";
import ModalV2 from "../ModalV2";
import TrustPageAPI from "../../../verifiedvendors/api/trustpage.api";

export const RequestVerifiedVendorAccessModalName =
  "RequestVerifiedVendorAccessModal";

interface RequestVerifiedVendorAccessModalProps {
  vendorId: number;
  vendorName: string;
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  onRequested: () => void;
}

const RequestVerifiedVendorAccessModal: FC<
  RequestVerifiedVendorAccessModalProps
> = (props) => {
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [requestAccessToSharedAssessment] =
    TrustPageAPI.useRequestAccessToTrustPageV1Mutation();

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    trackEvent("SharedProfile_RequestAccess");

    requestAccessToSharedAssessment({
      vendorId: props.vendorId,
      message: message,
    })
      .unwrap()
      .then(() => {
        // Re-fetch the user data in the background to ensure we get the latest value for hasSharedAssessmentsAccess
        props.dispatch(fetchUserData());
        // Fetch the list again in the background
        props.dispatch(fetchSharedAssessmentsList(true));

        props.dispatch(addDefaultSuccessAlert("Access requested"));
        props.onRequested();
      })
      .catch(() => {
        setIsLoading(false);
        props.dispatch(
          addDefaultUnknownErrorAlert("Error requesting access to Trust Page")
        );
      });
  };

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      headerContent={
        <h2>Request access to {props.vendorName}&apos;s Trust Page</h2>
      }
      footerContent={
        <>
          <Button tertiary onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading}
            disabled={loading || !isValid}
            onClick={onSubmit}
          >
            Send request
          </Button>
        </>
      }
    >
      <div>
        A representative from {props.vendorName || "this company"} will review
        your request. You will be notified if and when access is granted.
      </div>
      <br />
      <TextField
        name="message"
        multiLine
        maxLength={500}
        allowTextOverflow
        value={message}
        placeholder="Provide an optional message here"
        onChanged={(v, isValid) => {
          setMessage(v);
          setIsValid(isValid);
        }}
      />
    </ModalV2>
  );
};

export default RequestVerifiedVendorAccessModal;
