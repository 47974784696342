import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../_common/components/ModalV2";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  ContentLibraryDocument,
  GetDocumentsSortByCol,
} from "../types/contentLibrary.types";
import "../styles/ContentLibraryBrowserModal.scss";
import classNames from "classnames";
import SearchBox from "../../_common/components/SearchBox";
import { useContentLibraryList } from "./ContentLibraryListCard";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import moment from "moment/moment";
import MultiSelectionButton from "../../_common/components/MultiSelectionButton";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction, {
  ErrorCardWithAction,
} from "../../_common/components/EmptyCardWithAction";
import EmptyStateCircle from "../../_common/components/EmptyStateCircle";
import IconButton from "../../_common/components/IconButton";
import Button from "../../_common/components/core/Button";
import {
  AcceptedFileTypes,
  MaxFileSizeB,
} from "../../_common/types/fileRestrictions";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import FileDropzone from "../../_common/components/FileDropzone";
import TextField, {
  useTextWithValid,
} from "../../_common/components/TextField";
import DragDropUpload from "../../_common/components/DragDropUpload";
import DocumentTypeSelector from "./DocumentTypeSelector";
import ContentLibraryAPI, {
  getDocumentsListV1Resp,
} from "../api/contentLibraryAPI";
import { LogError } from "../../_common/helpers";
import {
  useBasicPermissions,
  UserWriteContentLibrary,
} from "../../_common/permissions";
import { useFindDuplicateDocumentsForFile } from "../types/contentLibraryHooks";

interface IDuplicateDocumentConfirmationModalProps extends BaseModalProps {
  onUploadAndAdd: () => Promise<void>;
  onUseDocument: (doc: ContentLibraryDocument) => void;
  duplicateDocuments: getDocumentsListV1Resp;
  addedDocumentUUIDs: string[];
}

const DuplicateDocumentConfirmationModal: FC<
  IDuplicateDocumentConfirmationModalProps
> = ({
  active,
  onClose,
  onUploadAndAdd,
  onUseDocument,
  duplicateDocuments,
  addedDocumentUUIDs,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const onAddDocumentAnyway = useCallback(async () => {
    setLoading(true);

    try {
      await onUploadAndAdd();
      onClose();
    } catch (e) {
      LogError("error uploading document to content library", e);
      dispatch(addDefaultUnknownErrorAlert("Error uploading document"));
      setLoading(false);
    }
  }, [dispatch, onUploadAndAdd, onClose]);

  if (duplicateDocuments.documents.length === 0) {
    // Shouldn't happen
    return;
  }

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      width={800}
      footerClassName="content-library-browser-dup-doc-modal-foot"
      headerContent="This document already exists in your Content Library. Adding this document again will create a duplicate."
      footerContent={
        <>
          <div className="btn-group footer-left">
            <Button
              tertiary
              onClick={() =>
                // For now, just go to the first one in the list (the last one to be updated)
                window.open(
                  `/contentlibrary/document/${duplicateDocuments.documents[0].uuid}`,
                  "_blank"
                )
              }
            >
              <div className="cr-icon-external-link" />
              Go to document
            </Button>
          </div>
          <div className="btn-group">
            <Button loading={loading} onClick={onAddDocumentAnyway}>
              Add document anyway
            </Button>
            <Button
              primary
              onClick={() => {
                onUseDocument(duplicateDocuments.documents[0]);
                if (
                  addedDocumentUUIDs.includes(
                    duplicateDocuments.documents[0].uuid
                  )
                ) {
                  dispatch(
                    addDefaultWarningAlert("Document was already added")
                  );
                }

                onClose();
              }}
            >
              Use existing document
            </Button>
          </div>
        </>
      }
    />
  );
};

export interface IContentLibraryBrowserModal extends BaseModalProps {
  addedDocumentUUIDs: string[];
  onAddDocument: (doc: ContentLibraryDocument) => void;
  onRemoveDocument?: (uuid: string) => void;

  // If the user doesn't have content library write permissions, we can still allow a secondary
  // upload action here that bypasses adding to the content library.
  onUploadDocumentWithoutWritePerm?: (file: File) => Promise<void>;
}

const ContentLibraryBrowserModal: FC<IContentLibraryBrowserModal> = ({
  active,
  onClose,
  addedDocumentUUIDs,
  onAddDocument,
  onRemoveDocument,
  onUploadDocumentWithoutWritePerm,
}) => {
  const dispatch = useAppDispatch();
  const perms = useBasicPermissions();
  const userHasWritePerm = !!perms.userPermissions[UserWriteContentLibrary];
  const [isUploadTab, setIsUploadTab] = useState(false);

  const userCanUpload = userHasWritePerm || !!onUploadDocumentWithoutWritePerm;

  const {
    filterBy,
    setFilterBy,
    clearFilters,
    isFiltered,

    xTableSortBy,
    xTableOnSortChange,
    xTablePagination,

    documentTypesLoading,
    documentTypeFilterOptions,
    onSelectDocumentTypeFilter,
    onClearDocumentTypeFilter,

    queryData: { data, error, isFetching, refetch },
  } = useContentLibraryList(5);

  const columnHeaders: IXTableColumnHeader[] = useMemo(
    () => [
      {
        id: GetDocumentsSortByCol.Name,
        text: "Document",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: GetDocumentsSortByCol.Type,
        text: "Type",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: GetDocumentsSortByCol.Updated,
        text: "Updated",
        sortable: true,
        startingSortDir: SortDirection.DESC,
      },
      {
        id: "view-col",
        text: "",
        sortable: false,
      },
      {
        id: "add-col",
        text: "",
        sortable: false,
      },
    ],
    []
  );

  const rows: IXTableRow[] = useMemo(
    () =>
      (data?.documents ?? []).map((doc) => {
        return {
          id: doc.uuid,
          cells: [
            <XTableCell key="name" className="name-cell">
              <div className="name-cell-inner">
                <img
                  className="file-type"
                  alt="File type icon"
                  src={GetIconForFilename(doc.versions[0].filename)}
                />
                {doc.name}
              </div>
            </XTableCell>,
            <XTableCell key="type">{doc.documentType}</XTableCell>,
            <XTableCell key="updated">
              {moment(doc.updatedAt).format("LL")}
            </XTableCell>,
            <XTableCell key="view" className="shrink-cell">
              <IconButton
                icon={<div className="cr-icon-external-link" />}
                popupDelay={0}
                hoverText="View in Content Library"
                onClick={() =>
                  window.open(`/contentlibrary/document/${doc.uuid}`, "_blank")
                }
              />
            </XTableCell>,
            <XTableCell key="add" className="shrink-cell">
              {addedDocumentUUIDs.includes(doc.uuid) ? (
                <Button
                  className="add-btn"
                  primary
                  onClick={() => onRemoveDocument?.(doc.uuid)}
                >
                  <div className="cr-icon-check" /> Added
                </Button>
              ) : (
                <Button className="add-btn" onClick={() => onAddDocument(doc)}>
                  <div className="cr-icon-plus" /> Add
                </Button>
              )}
            </XTableCell>,
          ],
        };
      }),
    [data, addedDocumentUUIDs, onAddDocument, onRemoveDocument]
  );

  // Upload tab stuff
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [documentName, documentNameValid, setDocumentName] = useTextWithValid();
  const [documentNameSetManually, setDocumentNameSetManually] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [uploading, setUploading] = useState(false);

  const resetFileUploadStep = useCallback(() => {
    setSelectedFile(undefined);
    setDocumentName("", false);
    setDocumentNameSetManually(false);
    setSelectedDocumentType("");
  }, [setDocumentName]);

  useEffect(() => {
    if (selectedFile && !documentNameSetManually) {
      // Prefill the document name when a file is added
      setDocumentName(selectedFile.name, true);
    }
  }, [selectedFile, documentNameSetManually, setDocumentName]);

  const onFileDrop: DropzoneOptions["onDrop"] = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        // Reset the file upload step first
        resetFileUploadStep();
        setSelectedFile(acceptedFiles[0]);
        setIsUploadTab(true);
      } else if (rejectedFiles.length === 1) {
        dispatch(
          addDefaultWarningAlert("File not supported", [
            "The file may be too big or an unsupported file type.",
          ])
        );
      } else {
        dispatch(addDefaultWarningAlert("Please upload one file at a time."));
      }
    },
    [dispatch, resetFileUploadStep]
  );

  const onFileRejected = useCallback(
    (_file: File) => {
      dispatch(
        addDefaultWarningAlert("File not supported", [
          "The file may be too big or an unsupported file type.",
        ])
      );
    },
    [dispatch]
  );

  const textFieldSetDocumentName = useCallback(
    (docName: string, valid: boolean) => {
      setDocumentName(docName, valid);
      if (docName !== documentName) {
        setDocumentNameSetManually(true);
      }
    },
    [setDocumentName, documentName]
  );

  const documentTypeSelectorOnChange = useCallback(
    (docType: string) => {
      setSelectedDocumentType(docType);
    },
    [setSelectedDocumentType]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MaxFileSizeB,
    accept: AcceptedFileTypes,
    onDrop: onFileDrop,
    noClick: true,
    disabled: !userCanUpload,
  });

  const fileValidForUpload =
    !!selectedFile &&
    (!userHasWritePerm || (documentNameValid && !!selectedDocumentType));

  const onDuplicateDocModalClose = useCallback(() => setUploading(false), []);
  const [openDuplicateDocsModal, duplicateDocsModal] = useModalV2(
    DuplicateDocumentConfirmationModal,
    onDuplicateDocModalClose
  );

  const [createDocument] =
    ContentLibraryAPI.useCreateContentLibraryDocumentMutation();
  const findDuplicateDocuments = useFindDuplicateDocumentsForFile(
    selectedFile ? [selectedFile] : []
  );

  const onUpload = useCallback(async () => {
    if (!fileValidForUpload || !userCanUpload) {
      return;
    }

    setUploading(true);

    if (userHasWritePerm) {
      try {
        const onUseDocument = (doc: ContentLibraryDocument) => {
          onAddDocument(doc);
          setUploading(false);
          setIsUploadTab(false);
          resetFileUploadStep();
        };

        const uploadAndAddDoc = async () => {
          const resp = await createDocument({
            file: selectedFile,
            name: documentName,
            type: selectedDocumentType,
          }).unwrap();

          dispatch(
            addDefaultSuccessAlert(`"${documentName}" added to Content Library`)
          );

          onUseDocument(resp.document);
        };

        const duplicateDocs = await findDuplicateDocuments();
        if (duplicateDocs.documents.length > 0) {
          openDuplicateDocsModal({
            onUploadAndAdd: uploadAndAddDoc,
            onUseDocument: onUseDocument,
            duplicateDocuments: duplicateDocs,
            addedDocumentUUIDs,
          });
          return;
        }

        const resp = await createDocument({
          file: selectedFile,
          name: documentName,
          type: selectedDocumentType,
        }).unwrap();

        dispatch(
          addDefaultSuccessAlert(`"${documentName}" added to Content Library`)
        );

        onAddDocument(resp.document);
        setUploading(false);
        setIsUploadTab(false);
        resetFileUploadStep();
      } catch (e) {
        LogError("error uploading document", e);
        dispatch(addDefaultUnknownErrorAlert("Error uploading document"));
        setUploading(false);
      }
    } else {
      // No write permissions - call the secondary upload action instead
      try {
        await onUploadDocumentWithoutWritePerm?.(selectedFile);
        setUploading(false);
        setIsUploadTab(false);
        resetFileUploadStep();
        // Also close the modal in this case
        onClose();
      } catch (e) {
        LogError("error uploading document", e);
        dispatch(addDefaultUnknownErrorAlert("Error uploading document"));
        setUploading(false);
      }
    }
  }, [
    fileValidForUpload,
    createDocument,
    dispatch,
    documentName,
    onAddDocument,
    resetFileUploadStep,
    selectedDocumentType,
    selectedFile,
    setIsUploadTab,
    onClose,
    onUploadDocumentWithoutWritePerm,
    userCanUpload,
    userHasWritePerm,
    findDuplicateDocuments,
    openDuplicateDocsModal,
    addedDocumentUUIDs,
  ]);

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className="content-library-browser-modal"
        headerClassName="content-library-browser-modal-header"
        headerContent={
          <div className="header-tabs">
            <div
              className={classNames("tab", { active: !isUploadTab })}
              onClick={() => {
                setIsUploadTab(false);
                resetFileUploadStep();
              }}
            >
              <div className="tab-icn cr-icon-file-search" /> Content Library
            </div>
            {userCanUpload && (
              <div
                className={classNames("tab", { active: isUploadTab })}
                onClick={() => setIsUploadTab(true)}
              >
                <div className="tab-icn cr-icon-upload-cloud" />
                Upload
              </div>
            )}
          </div>
        }
      >
        {isUploadTab ? (
          <div className="content-library-browser-upload">
            <DragDropUpload
              maxFileSize={MaxFileSizeB}
              acceptedFileTypeFilters={AcceptedFileTypes}
              onFileSelected={setSelectedFile}
              onFileRejected={onFileRejected}
              selectedFile={selectedFile}
            />

            {userHasWritePerm && (
              <>
                <label htmlFor="name">Name</label>
                <TextField
                  name="name"
                  value={documentName}
                  onChanged={textFieldSetDocumentName}
                  placeholder="Document name"
                  required
                  minLength={2}
                  maxLength={500}
                />

                <label htmlFor="docType">Type</label>
                <DocumentTypeSelector
                  selectedDocumentType={selectedDocumentType}
                  setSelectedDocumentType={documentTypeSelectorOnChange}
                />
              </>
            )}

            <div className="actions-row">
              <Button
                primary
                disabled={!fileValidForUpload}
                onClick={onUpload}
                loading={uploading}
              >
                <div className="cr-icon-plus" />
                Upload and add
              </Button>
            </div>
          </div>
        ) : (
          <>
            {!isFetching && error ? (
              <ErrorCardWithAction action={refetch} actionText="Try again" />
            ) : (
              <FileDropzone
                className="content-library-browser-main"
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                isDragActive={isDragActive}
              >
                <div className="search-filter">
                  <SearchBox
                    onChanged={(val) => setFilterBy({ name: val || undefined })}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    value={filterBy.name ?? ""}
                    minLength={3}
                  />
                  <MultiSelectionButton
                    primary={false}
                    text="Document type"
                    optionGroups={documentTypeFilterOptions}
                    disabled={documentTypesLoading}
                    showCount={filterBy.documentTypes.length > 0}
                    onSelectionChangedByValue={onSelectDocumentTypeFilter}
                    onResetDefault={onClearDocumentTypeFilter}
                    autoWidth
                  />
                </div>
                {isFetching || rows.length > 0 ? (
                  <XTable
                    className="content-library-table"
                    rows={rows}
                    columnHeaders={columnHeaders}
                    loading={isFetching}
                    sortedBy={xTableSortBy}
                    onSortChange={xTableOnSortChange}
                    pagination={xTablePagination}
                  />
                ) : isFiltered ? (
                  <SearchEmptyCard
                    searchItemText="documents"
                    searchString={filterBy.name}
                    onClear={clearFilters}
                    clearText="Clear search and filters"
                  />
                ) : filterBy.archived ? (
                  <EmptyCardWithAction
                    emptyText="No archived documents found"
                    emptySubText="When you archive documents on the Active tab, they will appear here."
                  />
                ) : (
                  <EmptyStateCircle
                    imgName="ContentLibrary"
                    title="Drop files here"
                    subtext={
                      <>or hit the &quot;Upload document&quot; button.</>
                    }
                  />
                )}
              </FileDropzone>
            )}
          </>
        )}
      </ModalV2>
      {duplicateDocsModal}
    </>
  );
};

export default ContentLibraryBrowserModal;
