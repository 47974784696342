import ReportCard from "../../../_common/components/ReportCard";
import { useModalV2 } from "../../../_common/components/ModalV2";
import Icon from "../../../_common/components/core/Icon";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../../_common/permissions";

import { FC, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./AppApprovalFlow.scss";
import { App, AppStatus } from "../../api/types";
import AppApprovalModal, { AppApprovalModalMode } from "./AppApprovalModal";
import AppInReviewModal from "./AppInReviewModal";

interface AppApprovalFlowProps {
  app: App;
}

const AppApprovalFlow: FC<AppApprovalFlowProps> = ({ app }) => {
  const [hidden, setHidden] = useState(false);

  const [openAppApprovalModal, appApprovalModal] = useModalV2(AppApprovalModal);
  const [openAppInReviewModal, appInReviewModal] = useModalV2(AppInReviewModal);

  const approveApp = () => {
    openAppApprovalModal({
      app: app,
      modalMode: AppApprovalModalMode.ApproveApp,
    });
  };

  const reviewApp = () => {
    openAppInReviewModal({
      app: app,
    });
  };

  const doNotApproveApp = () => {
    openAppApprovalModal({
      app: app,
      modalMode: AppApprovalModalMode.DoNotApprovedApp,
    });
  };

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  if (!userHasUserBaseWrite) {
    return <></>;
  }

  if (app.status !== AppStatus.NeedsReviewAppStatus) {
    return <></>;
  }

  return (
    <>
      {appApprovalModal}
      {appInReviewModal}
      <ReportCard newStyles className="app-approval-flow">
        <div className="approval-flow-heading">
          <h4>
            Manage access{" "}
            <SidePopupV2
              className="info-icon"
              text="Mark an application as being approved, not approved for your organization"
            >
              <Icon name="info" />
            </SidePopupV2>
          </h4>
          <button className="approval-hide" onClick={() => setHidden(!hidden)}>
            {hidden ? (
              <>
                <i className="cr-icon-eye approval-hide-icon" />
                <div>View</div>
              </>
            ) : (
              <>
                <i className="cr-icon-noeye approval-hide-icon" />
                <div>Hide</div>
              </>
            )}
          </button>
        </div>

        <TransitionGroup component={null}>
          {!hidden && (
            <CSSTransition timeout={250} classNames="expand">
              <div className="button-container">
                <button className="approval-button" onClick={approveApp}>
                  <div className="cr-icon-accepted approval-icon approved-icon" />

                  <div className="approval-message">
                    <div className="approval-title">
                      {"Approve application"}
                    </div>
                    <div className="approval-description">
                      {
                        "This application can be used in your organization. You can approve for all users or for specific team(s)"
                      }
                    </div>
                  </div>

                  <div className="cr-icon-chevron approval-chevron" />
                </button>

                <button className="approval-button" onClick={reviewApp}>
                  <div className="cr-icon-unclassified approval-icon needs-approval-icon" />

                  <div className="approval-message">
                    <div className="approval-title">
                      {"Mark as 'in review'"}
                    </div>
                    <div className="approval-description">
                      {
                        "Send an email to users asking about their use of this application"
                      }
                    </div>
                  </div>

                  <div className="cr-icon-chevron approval-chevron" />
                </button>

                <button className="approval-button" onClick={doNotApproveApp}>
                  <div className="cr-icon-cancel approval-icon not-approved-icon" />

                  <div className="approval-message">
                    <div className="approval-title">
                      {"Do not approve application"}
                    </div>
                    <div className="approval-description">
                      {"This application is not approved for use"}
                    </div>
                  </div>

                  <div className="cr-icon-chevron approval-chevron" />
                </button>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </ReportCard>
    </>
  );
};

export default AppApprovalFlow;
