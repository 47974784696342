import { FC, Fragment, useState } from "react";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { orderBy as _orderBy } from "lodash";
import {
  RemediationRequest,
  RemediationRequestRisk,
  RemediationRequestStatus,
} from "../../_common/types/remediation";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import TabButtons, { tabButton } from "../../_common/components/TabButtons";
import SearchBox from "../../_common/components/SearchBox";
import XTable, {
  IIconOption,
  ISortedBy,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import {
  useAssuranceType,
  useLocationStateUpdater,
  usePagination,
} from "../../_common/hooks";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";

import { HoverColor } from "../../_common/components/IconButton";
import {
  openConfirmationModalType,
  useConfirmationModalV2,
} from "../../_common/components/modals/ConfirmationModalV2";

import "../style/components/RemediationRequestTableV2.scss";
import { formatDateAsLocal, pluralise } from "../../_common/helpers";
import ActionBar from "../../_common/components/ActionBar";
import {
  deleteRemediationRequestDraft,
  deleteRemediationRequests,
  updateRemediationRequestArchived,
} from "../../_common/reducers/remediationRequest.actions";
import { fetchAlertsOrActivityStreamForOrgUser } from "../reducers/cyberRiskActions";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import RemediationIcon from "../../_common/images/remediation-icon.svg";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { getVendorWords } from "../../_common/constants";
import { AssuranceType } from "../../_common/types/organisations";
import { RiskSource, RiskStatus } from "../../_common/types/risks";
import { newFactCategoryMeta } from "../../_common/factCategoryHelpers";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { CorrespondenceIconButton } from "./CorrespondenceIconButton";
import { appConnect } from "../../_common/types/reduxHooks";
import { locationState } from "../../_common/types/router";
import { useHistory, useLocation } from "react-router-dom";

export const defaultSupportHref =
  "https://help.upguard.com/en/articles/3764595-how-to-request-remediation-from-a-vendor";
export const defaultSupportLinkText = "Support article";

export enum RemediationRequestTableV2Mode {
  breachsight,
  vendorrisk, // SHOULD NOT BE USED BY THIS COMPONENT ---> check VendorRiskRemediationRequestTableV2 instead
  singlevendor,
  tasks,
  userrisk,
}

export type RemediationRequestTableTab =
  | "active"
  | "progress"
  | "review"
  | "completed"
  | "archived"
  | "draft";

export interface RemediationRequestTableLocationState {
  pageNum?: number;
  tab?: RemediationRequestTableTab;
  openComments?: boolean;
  loadDraftId?: number;
}

interface IRemediationRequestTableV2Props {
  dispatch: DefaultThunkDispatch;
  requestRemediation?: () => void;
  remediationRequests: RemediationRequest[];
  remediationRequestIdsLoading?: boolean;
  mode: RemediationRequestTableV2Mode;
  userHasWriteRemediationPermission?: boolean;
  userHasWriteRemediationInSomePortfolio?: boolean;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  isForSubsidiaries?: boolean;
  preSelectTab?: RemediationRequestTableTab;
  taskRouteOverride?: string; // when in task mode, will use the provided prefix instead of the default /vendors/remediation path
}

export interface IRemediationRequestForDisplay {
  orgName?: string;
  vendorName?: string;
  vendorHostname?: string;
  vendorId?: number;
  title: string;
  subTitle: string;
  websites: string[];
  websitesCount: number;
  surveys: string[];
  lastUpdated: string;
  dueDate?: string;
  status: RemediationRequestStatus;
  id: number; // either the ID of the remediation request OR survey OR evidence
  uniqueId: number; // the number used for the column ID, needs to be different to the above in case of a colision
  archived: boolean;
  openRiskNames: string[];
  closedRiskNames: string[];
  canWrite: boolean;
  evidences: string[];
  risks: RemediationRequestRisk[];
  messages: number;
  unreadMessages: number;
  saasUsers: string[];
}

export const isDraft = (r: IRemediationRequestForDisplay) =>
  r.status === RemediationRequestStatus.Draft;

export const toRemediationRequestForDisplay = (
  requests: RemediationRequest[],
  selectedTab: RemediationRequestTableTab
): IRemediationRequestForDisplay[] => {
  return requests.map(({ details }) => {
    const closedRiskNames = [];
    const openRiskNames = [];
    const riskCategories = new Set<string>();
    const surveys: Record<number, string> = {};
    const evidences = new Set<string>();
    const selectedWebsites = new Set<string>();
    const saasUsers = new Set<string>();
    let totalSelectAllWebsitesFailedCountSnapshot = 0;

    if (details.risks) {
      for (const risk of details.risks) {
        riskCategories.add(newFactCategoryMeta[risk.factCategory].name);
        risk.websites?.forEach((w) => selectedWebsites.add(w));
        risk.surveys?.forEach((s) => (surveys[s.surveyId || 0] = s.surveyName));
        risk.additionalEvidences?.forEach((ev) =>
          evidences.add(ev.evidenceName)
        );
        risk.saasUsers?.forEach((s) =>
          saasUsers.add(s.userName || s.userEmail)
        );

        if (risk.isAllWebsites) {
          totalSelectAllWebsitesFailedCountSnapshot +=
            risk.allWebsitesFailedCountSnapshot;
        }

        const { riskStatus, failTitle } = risk;

        if (
          riskStatus === RiskStatus.Open ||
          riskStatus === RiskStatus.Partial ||
          (selectedTab === "draft" && riskStatus === "Draft")
        ) {
          openRiskNames.push(failTitle);
        } else {
          closedRiskNames.push(failTitle);
        }
      }

      closedRiskNames.sort((a, b) => a.localeCompare(b));
      openRiskNames.sort((a, b) => a.localeCompare(b));
    }

    return {
      id: details.id,
      uniqueId: 1000000000 + details.id,
      websites: [...selectedWebsites.values()], //`${websites.size} domains`,
      websitesCount:
        selectedWebsites.size + totalSelectAllWebsitesFailedCountSnapshot,
      surveys: Object.values(surveys),
      saasUsers: [...saasUsers.values()],
      archived: details.archived,
      lastUpdated: details.updatedAt,
      dueDate: details.dueDate,
      closedRiskNames,
      openRiskNames,
      title: details.title || "TODO",
      subTitle: Array.from(riskCategories.values()).join(", "),
      status: details.status,
      vendorName: details.vendorName,
      vendorHostname: details.vendorDomain,
      vendorId: details.vendorId,
      orgName: details.orgName,
      canWrite: details.canWrite,
      evidences: [...evidences.values()],
      risks: details.risks,
      messages: details.messages,
      unreadMessages: details.unreadMessages,
    };
  });
};

export const toggleArchived = (
  itemsToArchive: number[],
  requests: IRemediationRequestForDisplay[],
  selectedTab: string,
  isForSubsidiaries: boolean,
  mode: RemediationRequestTableV2Mode,
  dispatch: DefaultThunkDispatch
) => {
  // TODO - this might not be needed if we only allow the ids of normal remediation requests
  const idsToArchive = requests
    .filter((r) => itemsToArchive.indexOf(r.uniqueId) > -1)
    .map((r) => r.id);
  const vendorIDs = requests
    .filter((r) => itemsToArchive.includes(r.uniqueId))
    .map((r) => r.vendorId);

  return dispatch(
    updateRemediationRequestArchived(
      idsToArchive,
      selectedTab !== "archived",
      mode === RemediationRequestTableV2Mode.userrisk,
      isForSubsidiaries,
      vendorIDs
    )
  )
    .then(() => {
      if (mode !== RemediationRequestTableV2Mode.tasks) {
        dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
      }
    })
    .catch((e) => {
      dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred updating the status of this remediation request"
        )
      );
      throw e;
    });
};

export const deleteDraft = async (
  itemsToDelete: number[],
  requests: IRemediationRequestForDisplay[],
  dispatch: DefaultThunkDispatch
) => {
  // TODO - this might not be needed if we only allow the ids of normal remediation requests
  const idsToDelete = requests
    .filter((r) => itemsToDelete.indexOf(r.uniqueId) > -1)
    .map((r) => r.id);

  Promise.all(
    idsToDelete.map((i) => dispatch(deleteRemediationRequestDraft(i)))
  ).catch((e) => {
    dispatch(
      addDefaultUnknownErrorAlert(
        "An error occurred deleting draft remediation request"
      )
    );
    throw e;
  });
};

export const confirmArchiveRequests = (
  singular: boolean,
  toggleArchive: () => void,
  openConfirmationModal: openConfirmationModalType
) =>
  openConfirmationModal({
    title: singular
      ? "Archive remediation request?"
      : "Archive remediation requests?",
    description: (
      <p>
        Are you sure you want to archive{" "}
        {singular ? "this remediation request" : "these remediation requests"}?
        Archiving a remediation request will mark it as closed.
      </p>
    ),
    buttonAction: toggleArchive,
    buttonText: "Yes, archive",
    cancelText: "Cancel",
    iconClass: "cr-icon-archive",
  });

export const confirmUnarchiveRequests = (
  singular: boolean,
  toggleUnarchive: () => void,
  openConfirmationModal: openConfirmationModalType
) =>
  openConfirmationModal({
    title: singular
      ? "Unarchive remediation request?"
      : "Unarchive remediation requests?",
    description: (
      <p>
        Are you sure you want to unarchive{" "}
        {singular ? "this remediation request" : "these remediation requests"}?
      </p>
    ),
    buttonAction: toggleUnarchive,
    buttonText: "Yes, unarchive",
    cancelText: "Cancel",
    iconClass: "cr-icon-archive",
  });

export const confirmDeleteDrafts = (
  singular: boolean,
  deleteDrafts: () => void,
  openConfirmationModal: openConfirmationModalType
) =>
  openConfirmationModal({
    title: singular ? "Delete draft?" : "Delete drafts?",
    description: (
      <p>
        Are you sure you want to delete{" "}
        {singular ? "this draft" : "these drafts"}? Deleting a draft cannot be
        undone.
      </p>
    ),
    buttonAction: deleteDrafts,
    buttonText: "Yes, delete",
    cancelText: "Cancel",
    iconClass: "cr-icon-trash",
    dangerousAction: true,
  });

export const confirmDeleteArchivedRequests = (
  singular: boolean,
  deleteRemediationRequests: () => void,
  openConfirmationModal: openConfirmationModalType
) => {
  openConfirmationModal({
    title: singular
      ? "Delete remediation request?"
      : "Delete remediation requests?",
    description: (
      <p>
        Are you sure you want to delete{" "}
        {singular ? "this remediation request" : "these remediation requests"}?
        This action cannot be undone.
      </p>
    ),
    buttonAction: deleteRemediationRequests,
    buttonText: "Yes, delete",
    iconClass: "cr-icon-trash",
    dangerousAction: true,
  });
};

export const emptyInfo = (
  selectedTab: string,
  assuranceType: AssuranceType,
  mode: RemediationRequestTableV2Mode,
  isForSubsidiaries: boolean
) => {
  let emptyText = "";
  let emptySubtext = "";

  const vendorWords = getVendorWords(assuranceType);

  switch (selectedTab) {
    case "active":
      emptyText = "No active remediation requests";
      if (mode === RemediationRequestTableV2Mode.tasks) {
        emptySubtext = "When you receive a new request, it will appear here.";
      } else {
        emptySubtext = "When you create a new request, it will appear here.";
      }
      break;
    case "progress":
      emptyText = "No remediation requests in progress";
      if (
        mode === RemediationRequestTableV2Mode.breachsight ||
        mode === RemediationRequestTableV2Mode.userrisk
      ) {
        if (isForSubsidiaries) {
          emptySubtext =
            "After you send a remediation request, those that are awaiting action from a subsidiary will appear here.";
        } else {
          emptySubtext =
            "After you send a remediation request, those that are awaiting action from the recipient will appear here.";
        }
      } else if (mode === RemediationRequestTableV2Mode.tasks) {
        emptySubtext =
          "After you receive a remediation request, those that are awaiting your action will appear here.";
      } else {
        emptySubtext = `When you have sent remediation requests, those that are awaiting an action from the ${vendorWords.singular} will appear here.`;
      }
      break;
    case "review":
      emptyText = "No requests awaiting your review";
      if (mode === RemediationRequestTableV2Mode.breachsight) {
        if (isForSubsidiaries) {
          emptySubtext =
            "Remediation requests that have been submitted by a subsidiary for your review will appear here.";
        } else {
          emptySubtext =
            "Remediation requests that have been submitted by the recipient for your review will appear here.";
        }
      } else if (mode === RemediationRequestTableV2Mode.tasks) {
        emptySubtext =
          "Remediation requests that you have submitted for review will appear here.";
        emptyText = "No requests submitted for review";
      } else {
        emptySubtext = `Remediation requests sent back from the ${vendorWords.singular} for your review will appear in this section.`;
      }
      break;
    case "completed":
      emptyText = "No completed remediation requests";
      if (mode === RemediationRequestTableV2Mode.breachsight) {
        emptySubtext =
          "Remediation requests that you have marked as complete will appear here.";
      } else if (mode === RemediationRequestTableV2Mode.tasks) {
        emptySubtext =
          "Remediation requests that the sender has marked as completed will appear here.";
      } else {
        emptySubtext = `Remediation requests that have been marked as completed by you will appear in this section`;
      }
      break;
    case "archived":
      emptyText = "No archived remediation requests";
      if (mode === RemediationRequestTableV2Mode.breachsight) {
        emptySubtext =
          "When you archive a remediation request, it will appear here.";
      } else if (mode === RemediationRequestTableV2Mode.tasks) {
        emptySubtext = "Archived remediation request will appear here.";
      } else {
        emptySubtext = `When you archive requests, they will appear in this section`;
      }
      break;
    case "draft":
      emptyText = "No draft remediation requests";
      emptySubtext = "Your draft remediation requests will appear here";
  }

  return { emptyText, emptySubtext };
};

export const statusToPill = (
  status: RemediationRequestStatus,
  archived: boolean,
  mode: RemediationRequestTableV2Mode
) => {
  if (archived) {
    return (
      <PillLabel className={"status-pill"} color={LabelColor.Grey}>
        Archived
      </PillLabel>
    );
  }

  switch (status) {
    case RemediationRequestStatus.Open:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Blue}>
          In progress
        </PillLabel>
      );
    case RemediationRequestStatus.AwaitingReview:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Orange}>
          {mode !== RemediationRequestTableV2Mode.tasks
            ? "Awaiting Review"
            : "Sent for review"}
        </PillLabel>
      );
    case RemediationRequestStatus.Closed:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Green}>
          Completed
        </PillLabel>
      );
    case RemediationRequestStatus.Draft:
      return (
        <PillLabel className={"status-pill"} color={LabelColor.Grey}>
          Draft
        </PillLabel>
      );
  }

  return (
    <PillLabel className={"status=pill"} color={LabelColor.Red}>
      UNKNOWN
    </PillLabel>
  );
};

export const RemediationRequestTableV2: FC<IRemediationRequestTableV2Props> = (
  props
) => {
  const {
    requestRemediation,
    remediationRequests,
    mode,
    userHasWriteRemediationPermission,
    userHasWriteRemediationInSomePortfolio = false,
    isManagementAnalystSession,
    managedOrgId,
    dispatch,
    isForSubsidiaries,
    preSelectTab,
    remediationRequestIdsLoading: loading,
    taskRouteOverride,
  } = props;

  const history = useHistory<
    locationState & RemediationRequestTableLocationState
  >();
  const location = useLocation<RemediationRequestTableLocationState>();

  const assuranceType = useAssuranceType();

  const [selectedTab, setSelectedTab] = useState<string>(
    preSelectTab ?? location.state?.tab ?? "active"
  );
  const [searchString, setSearchString] = useState("");
  const [sortedBy, setSortedBy] = useState<ISortedBy>({
    columnId: "updated",
    direction: SortDirection.DESC,
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [stateLoading, setStateLoading] = useState(false);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const onTabChange = (tabId: string) => {
    // Reset the filter text and sorting when changing tab
    setSelectedRows([]);
    setSelectedTab(tabId);
  };

  // todo - additional evidence remediation once we have that
  const allRequests: IRemediationRequestForDisplay[] = [];

  allRequests.push(
    ...toRemediationRequestForDisplay(
      remediationRequests,
      selectedTab as RemediationRequestTableTab
    )
  );

  const localToggleArchived = (itemsToArchive: number[]) => {
    setStateLoading(true);
    setSelectedRows([]);
    toggleArchived(
      itemsToArchive,
      allRequests,
      selectedTab,
      !!props.isForSubsidiaries,
      mode,
      dispatch
    ).then(() => setStateLoading(false));
  };

  const localDeleteDraft = (itemsToDelete: number[]) => {
    setStateLoading(true);
    setSelectedRows([]);
    deleteDraft(itemsToDelete, allRequests, dispatch).then(() =>
      setStateLoading(false)
    );
  };

  const localConfirmArchiveRequests = (requestIds: number[]) =>
    confirmArchiveRequests(
      requestIds.length === 1,
      () => localToggleArchived(requestIds),
      openConfirmationModal
    );

  const localConfirmUnarchiveRequests = (requestIds: number[]) =>
    confirmUnarchiveRequests(
      requestIds.length === 1,
      () => localToggleArchived(requestIds),
      openConfirmationModal
    );

  const localConfirmDeleteDrafts = (requestIds: number[]) =>
    confirmDeleteDrafts(
      requestIds.length === 1,
      () => localDeleteDraft(requestIds),
      openConfirmationModal
    );

  const localConfirmDeleteArchivedRequests = (requestIds: number[]) => {
    // TODO - this might not be needed if we only allow the ids of normal remediation requests
    const idsToDelete = allRequests
      .filter((r) => requestIds.indexOf(r.uniqueId) > -1)
      .map((r) => r.id);

    const deleteRemediations = async () => {
      try {
        await dispatch(deleteRemediationRequests(idsToDelete));
        dispatch(
          addDefaultSuccessAlert(
            `Successfully deleted remediation ${
              idsToDelete.length === 1 ? "request" : "requests"
            }.`
          )
        );
      } catch (e) {
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred deleting remediation requests. Please contact UpGuard Support."
          )
        );
        throw e;
      }
    };
    confirmDeleteArchivedRequests(
      idsToDelete.length === 1,
      deleteRemediations,
      openConfirmationModal
    );
  };

  const applyFilterText = (
    filteredAndSortedRequests: IRemediationRequestForDisplay[]
  ) => {
    const lowerFilterText = searchString.toLowerCase().trim();
    filteredAndSortedRequests = filteredAndSortedRequests.filter(
      (r) =>
        r.title.toLowerCase().indexOf(lowerFilterText) > -1 ||
        (!!r.vendorName &&
          r.vendorName.toLowerCase().indexOf(lowerFilterText) > -1) ||
        (!!r.vendorHostname &&
          r.vendorHostname.toLowerCase().indexOf(lowerFilterText) > -1)
    );
    return filteredAndSortedRequests;
  };

  const activeRequests = applyFilterText(
    allRequests.filter(
      (r) =>
        r.status !== RemediationRequestStatus.Closed &&
        r.status !== RemediationRequestStatus.DeletedDraft &&
        !r.archived &&
        r.status !== RemediationRequestStatus.Draft
    )
  );
  const progressRequests = applyFilterText(
    allRequests.filter(
      (r) => r.status === RemediationRequestStatus.Open && !r.archived
    )
  );
  const reviewRequests = applyFilterText(
    allRequests.filter(
      (r) => r.status === RemediationRequestStatus.AwaitingReview && !r.archived
    )
  );
  const completedRequests = applyFilterText(
    allRequests.filter(
      (r) => r.status === RemediationRequestStatus.Closed && !r.archived
    )
  );
  const archivedRequests = applyFilterText(
    allRequests.filter((r) => r.archived)
  );
  const draftRequests = applyFilterText(
    allRequests.filter((r) => r.status === RemediationRequestStatus.Draft)
  );

  const tabs: tabButton[] = [
    {
      id: "active",
      text: "Active " + (!loading ? `(${activeRequests.length})` : ""),
    },
    {
      id: "progress",
      text: "In progress  " + (!loading ? `(${progressRequests.length})` : ""),
    },
    {
      id: "review",
      text: `Awaiting review ` + (!loading ? `(${reviewRequests.length})` : ""),
    },
    {
      id: "completed",
      text: `Completed ` + (!loading ? `(${completedRequests.length})` : ""),
    },
    {
      id: "archived",
      text: `Archived ` + (!loading ? `(${archivedRequests.length})` : ""),
    },
  ];

  if (mode !== RemediationRequestTableV2Mode.tasks) {
    tabs.push({
      id: "draft",
      text: "Drafts " + (!loading ? `(${draftRequests.length})` : ""),
    });
  }

  const { emptyText, emptySubtext } = emptyInfo(
    selectedTab,
    assuranceType,
    mode,
    !!isForSubsidiaries
  );

  let action;
  if (
    selectedTab === "active" &&
    (userHasWriteRemediationPermission ||
      userHasWriteRemediationInSomePortfolio)
  ) {
    action = requestRemediation;
  }

  let filteredAndSortedRequests: IRemediationRequestForDisplay[] = allRequests;

  switch (selectedTab) {
    case "active":
      filteredAndSortedRequests = activeRequests;
      break;
    case "progress":
      filteredAndSortedRequests = progressRequests;
      break;
    case "review":
      filteredAndSortedRequests = reviewRequests;
      break;
    case "completed":
      filteredAndSortedRequests = completedRequests;
      break;
    case "archived":
      filteredAndSortedRequests = archivedRequests;
      break;
    case "draft":
      filteredAndSortedRequests = draftRequests;
  }

  switch (sortedBy.columnId) {
    case "vendor":
    case "subs":
      filteredAndSortedRequests = _orderBy(
        filteredAndSortedRequests,
        (r) => r.vendorName,
        sortedBy.direction === SortDirection.DESC ? ["desc"] : ["asc"]
      );
      break;
    case "title":
      filteredAndSortedRequests = _orderBy(
        filteredAndSortedRequests,
        (r) => r.title,
        sortedBy.direction === SortDirection.DESC ? ["desc"] : ["asc"]
      );
      break;
    case "updated":
      filteredAndSortedRequests = _orderBy(
        filteredAndSortedRequests,
        (r) => r.lastUpdated,
        sortedBy.direction === SortDirection.DESC ? ["desc"] : ["asc"]
      );
      break;
    case "dueDate":
      filteredAndSortedRequests = _orderBy(
        filteredAndSortedRequests,
        (r) => r.dueDate,
        sortedBy.direction === SortDirection.DESC ? ["desc"] : ["asc"]
      );
      break;
    case "status":
      filteredAndSortedRequests = _orderBy(
        filteredAndSortedRequests,
        (r) => r.status,
        sortedBy.direction === SortDirection.DESC ? ["desc"] : ["asc"]
      );
      break;
  }

  const [currentPageItems, currentPage, totalPages, onPageChange] =
    usePagination(filteredAndSortedRequests, 20, location.state?.pageNum); // TODO confirm paging

  useLocationStateUpdater({ pageNum: currentPage, tab: selectedTab });

  const columns: IXTableColumnHeader[] = [];

  columns.push({
    id: "title",
    text: "Request name",
    sortable: true,
  });

  if (mode === RemediationRequestTableV2Mode.tasks) {
    columns.push({
      id: "org",
      text: "Requested by",
      sortable: true,
    });
  }

  if (isForSubsidiaries) {
    columns.push({ id: "subs", text: "Subsidiary", sortable: true });
  }

  columns.push(
    { id: "risks", text: "Risks open", sortable: false },
    {
      id: "assets",
      text:
        mode === RemediationRequestTableV2Mode.userrisk ? "Users" : "Assets",
      sortable: false,
    },
    { id: "updated", text: "Last updated", sortable: true },
    { id: "dueDate", text: "Due", sortable: true },
    { id: "status", text: "Status", sortable: true },
    { id: "progress", text: "% complete", sortable: false },
    { id: "messages", text: "Messages", sortable: false }
  );

  const rows: IXTableRow[] = currentPageItems.map((r) => {
    const cells = [];

    cells.push(
      <XTableCell key={"title"} className={"title-cell"}>
        <h3>{r.title}</h3>
        <p>{r.subTitle}</p>
      </XTableCell>
    );

    if (mode === RemediationRequestTableV2Mode.tasks) {
      cells.push(
        <XTableCell key={"org"} className={"org-cell"}>
          {r.orgName}
        </XTableCell>
      );
    }

    if (isForSubsidiaries) {
      cells.push(
        <XTableCell key={"subs"} className={"subs-cell"}>
          <p>{r.vendorName}</p>
        </XTableCell>
      );
    }

    const websitesAssetText = `${r.websitesCount} ${pluralise(
      r.websitesCount,
      " domain",
      " domains"
    )}`;
    const specificWebsites = r.websites.slice(0, 10);
    const websitePopupContent = specificWebsites.map((a, i) => (
      <Fragment key={i}>
        {a} <br />
      </Fragment>
    ));

    if (r.websitesCount > specificWebsites.length) {
      websitePopupContent.push(
        <Fragment key={"more"}>
          <br />+ {r.websitesCount - specificWebsites.length} more
        </Fragment>
      );
    }

    const surveyPopupContent = r.surveys.slice(0, 10).map((a, i) => (
      <Fragment key={i}>
        {a} <br />
      </Fragment>
    ));

    if (r.surveys.length > 10) {
      surveyPopupContent.push(
        <Fragment key={"more"}>
          <br />+ {r.surveys.length - 10} more
        </Fragment>
      );
    }

    const saasPopupContent = r.saasUsers.slice(0, 10).map((a, i) => (
      <Fragment key={i}>
        {a} <br />
      </Fragment>
    ));

    if (r.saasUsers.length > 10) {
      saasPopupContent.push(
        <Fragment key={"more"}>
          <br />+ {r.saasUsers.length - 10} more
        </Fragment>
      );
    }

    const hasAdditionalEvidenceRisks = r.risks.some(
      (risk) => risk.source === RiskSource.AdditionalEvidence
    );

    const totalRisks = r.risks.length;
    let totalRemediated = 0;
    r.risks.forEach((risk) => {
      if (
        risk.riskStatus === RiskStatus.Closed ||
        risk.riskStatus === RiskStatus.Waived
      ) {
        totalRemediated += 1;
      }
    });

    cells.push(
      <XTableCell key={"risks"} className={"risk-cell"}>
        <SidePopupV2
          text={
            r.openRiskNames.length > 0
              ? r.openRiskNames.map((or, i) => (
                  <Fragment key={i}>
                    {or} <br />
                  </Fragment>
                ))
              : "No open risks"
          }
        >
          <span>
            {`${r.openRiskNames.length} open risk${
              r.openRiskNames.length === 1 ? "" : "s"
            }`}
          </span>
        </SidePopupV2>
      </XTableCell>,
      <XTableCell key={"assets"} className={"assets-cell"}>
        <div className={"assets-div"}>
          {r.websites.length > 0 ? (
            // Only show if there are some specific websites
            // as those will be listed in the popup
            // Otherwise just display the count alone
            <SidePopupV2 text={websitePopupContent} className="assets-popup">
              <span>{websitesAssetText}</span>
            </SidePopupV2>
          ) : (
            r.websitesCount > 0 && (
              // There are no specific websites... but there is
              // an overall failed count. Those are coming from
              // "all websites" cloudscan risks.
              <span>{websitesAssetText}</span>
            )
          )}
          {r.surveys.length > 0 && (
            <SidePopupV2 text={surveyPopupContent}>
              <span>
                {`${r.surveys.length} questionnaire${
                  r.surveys.length === 1 ? "" : "s"
                }`}
              </span>
            </SidePopupV2>
          )}
          {hasAdditionalEvidenceRisks && <span>{`All documents`}</span>}
          {r.saasUsers.length > 0 && (
            <SidePopupV2 text={saasPopupContent}>
              <span>
                {`${r.saasUsers.length} ${pluralise(
                  r.saasUsers.length,
                  "user",
                  "users"
                )}`}
              </span>
            </SidePopupV2>
          )}
        </div>
      </XTableCell>,
      <XTableCell key={"last-updated"} className={"updated-cell"}>
        {formatDateAsLocal(r.lastUpdated)}
      </XTableCell>,
      <XTableCell key={"due-date"} className={"due-cell"}>
        {r.dueDate ? formatDateAsLocal(r.dueDate) : ""}
      </XTableCell>,
      <XTableCell key={"status"} className={"status-cell"}>
        {statusToPill(r.status, r.archived, mode)}
      </XTableCell>,
      <XTableCell key={"progress"} className={"progress-cell"}>
        {`${
          totalRisks > 0
            ? Math.floor((totalRemediated / totalRisks) * 100)
            : 100
        }%`}
      </XTableCell>,
      <XTableCell key={"messages"} className={"messages-cell"}>
        {!isDraft(r) && (
          <CorrespondenceIconButton
            totalMessages={r.messages}
            haveUnreadMessages={r.unreadMessages > 0}
            onClick={() => onClickRow(true)}
          />
        )}
      </XTableCell>
    );

    const iconOptions: IIconOption[] = [];
    if (
      mode !== RemediationRequestTableV2Mode.tasks &&
      (userHasWriteRemediationPermission || r.canWrite)
    ) {
      if (selectedTab !== "draft") {
        iconOptions.push({
          id: "archive",
          icon: <div className={"cr-icon-archive"} />,
          hoverText: r.archived ? "Unarchive" : "Archive",
          hoverColor: r.archived ? HoverColor.Green : HoverColor.Red,
          onClick: () => {
            r.archived
              ? localConfirmUnarchiveRequests([r.uniqueId])
              : localConfirmArchiveRequests([r.uniqueId]);
          },
        });

        if (r.archived) {
          // Support deleting this remediation request if it's already archived
          iconOptions.push({
            id: "delete",
            icon: <div className="cr-icon-trash" />,
            hoverText: "Delete",
            hoverColor: HoverColor.Red,
            onClick: () => localConfirmDeleteArchivedRequests([r.uniqueId]),
          });
        }
      } else {
        iconOptions.push({
          id: "delete",
          icon: <div className={"cr-icon-trash"} />,
          hoverText: "Delete draft",
          hoverColor: HoverColor.Red,
          onClick: () => localConfirmDeleteDrafts([r.uniqueId]),
        });
      }
    }

    const onClickRow = (openComments = false) => {
      let path = "",
        backTo = "",
        backToText = "";

      if (isManagementAnalystSession) {
        if (isDraft(r)) {
          path = `/analysts/tpvm/${managedOrgId}/${r.vendorId}/remediation/add`;
        } else {
          path = `/analysts/tpvm/${managedOrgId}/${r.vendorId}/remediation/${r.id}`;
        }
        backTo = `/analysts/tpvm/${managedOrgId}/${r.vendorId}/remediation`;
        backToText = "Back to Remediation Requests";
      } else if (mode === RemediationRequestTableV2Mode.tasks) {
        const prefix = taskRouteOverride ?? "/vendors/remediation";

        // no drafts in tasks
        path = `${prefix}/${r.id}`;
        backTo = `${prefix}`;
        backToText = "Back to Remediation Requests";
      } else if (isForSubsidiaries) {
        if (isDraft(r)) {
          path = `/subsidiaries/${r.vendorId}/remediation/add`;
        } else {
          path = `/subsidiaries/${r.vendorId}/remediation/${r.id}`;
        }
        backTo = `/subsidiaries/${r.vendorId}/remediation`;
        backToText = "Back to Remediation";
      } else if (mode === RemediationRequestTableV2Mode.breachsight) {
        // no survey mode here..
        if (isDraft(r)) {
          path = `/selfremediation/add`;
        } else {
          path = `/selfremediation/${r.id}`;
        }
        backTo = "/selfremediation";
        backToText = "Back to Remediation";
      } else if (mode === RemediationRequestTableV2Mode.userrisk) {
        if (isDraft(r)) {
          path = `/userbase/remediation/add`;
        } else {
          path = `/userbase/remediation/${r.id}`;
        }
        backTo = "/userbase";
        backToText = "Back to Remediation";
      } else {
        // Both single vendor and vendor risk use the same paths but the back to is different
        if (isDraft(r)) {
          path = `/vendor/${r.vendorId}/remediation/add`;
        } else {
          path = `/vendor/${r.vendorId}/remediation/${r.id}`;
        }

        if (mode === RemediationRequestTableV2Mode.singlevendor) {
          backTo = `/vendor/${r.vendorId}/remediation`;
        }
        backToText = "Back to Remediation";
      }

      history.push(path, {
        openComments,
        backContext: { backTo, backToText, goBack: true },
        loadDraftId: isDraft(r) ? r.id : undefined,
      });
    };

    if (!(isDraft(r) && !userHasWriteRemediationPermission && !r.canWrite)) {
      iconOptions.push({
        id: "main",
        icon: <div className={"cr-icon-chevron"} />,
        onClick: () => onClickRow(),
      });
    }

    return {
      id: r.uniqueId,
      cells: cells,
      selectionDisabled: mode === RemediationRequestTableV2Mode.tasks,
      selectionDisabledHelpText:
        "Bulk archiving of requests related to questionnaires is disabled as archiving this request will also archive the associated questionnaire.",
      selected: selectedRows.indexOf(r.uniqueId) > -1,
      iconOptions,
      onClick: !(
        isDraft(r) &&
        !userHasWriteRemediationPermission &&
        !r.canWrite
      )
        ? onClickRow
        : undefined,
    };
  });

  return (
    <ReportCard newStyles className={"remediation-request-card-v2"}>
      <div className={"header"}>
        <div className={"header-title"}>Remediation requests</div>
        {(userHasWriteRemediationPermission ||
          userHasWriteRemediationInSomePortfolio) &&
          requestRemediation && (
            <div className={"header-right"}>
              <Button primary onClick={requestRemediation}>
                <i className="icon-x rotate-45" />
                Request remediation
              </Button>
            </div>
          )}
      </div>
      <div className={"tab-row"}>
        <TabButtons
          onChangeTab={onTabChange}
          tabs={tabs}
          activeTabId={selectedTab}
        />
        <SearchBox
          onChanged={setSearchString}
          value={searchString}
          placeholder={"Search remediation requests"}
        />
      </div>
      <XTable
        className={"remediation-requests-table"}
        rows={rows}
        columnHeaders={columns}
        sortedBy={sortedBy}
        onSortChange={(columnId, direction) =>
          setSortedBy({ columnId, direction })
        }
        pagination={{
          currentPage,
          onPageChange,
          totalPages,
          hidePaginationIfSinglePage: true,
        }}
        onSelectClick={(id: number | string, selected) =>
          setSelectedRows((prevSelectedRows) => {
            const prevSelected = [...prevSelectedRows];
            if (selected) {
              return [...prevSelected, id as number];
            } else {
              const idx = prevSelected.indexOf(id as number);
              prevSelected.splice(idx, 1);
              return prevSelected;
            }
          })
        }
        onSelectNoneClick={() => setSelectedRows([])}
        onSelectAllClick={() =>
          setSelectedRows(filteredAndSortedRequests.map((r) => r.uniqueId))
        }
        onSelectToggle={(selectAll) =>
          setSelectedRows(
            selectAll ? filteredAndSortedRequests.map((r) => r.uniqueId) : []
          )
        }
        loading={loading || stateLoading}
        selectable={
          mode !== RemediationRequestTableV2Mode.tasks &&
          userHasWriteRemediationPermission
        }
        iconOptions
      />
      {currentPageItems.length === 0 &&
        !loading &&
        !stateLoading &&
        (searchString === "" ? (
          <EmptyCardWithAction
            emptyText={emptyText}
            emptySubText={emptySubtext}
            onActionClick={action}
            actionButtonText={"Request remediation"}
            iconSrc={RemediationIcon}
          />
        ) : (
          <SearchEmptyCard
            searchItemText={"remediation requests"}
            onClear={() => setSearchString("")}
            clearText={"Clear search"}
            searchString={searchString}
          />
        ))}
      <ActionBar
        active={selectedRows.length > 0}
        className={"remediation-actions"}
      >
        <div className={"selected-text"}>{`You have selected ${
          selectedRows.length
        } remediation request${selectedRows.length > 1 ? "s" : ""}`}</div>
        <Button
          className={"cancel-button"}
          tertiary
          onClick={() => setSelectedRows([])}
        >
          Cancel
        </Button>
        {selectedTab === "draft" ? (
          <Button
            className="archive-button"
            onClick={() => localConfirmDeleteDrafts(selectedRows)}
            filledDanger
          >
            <div className="cr-icon-trash" /> Delete
          </Button>
        ) : selectedTab === "archived" ? (
          <Button
            className="archive-button"
            onClick={() => localConfirmUnarchiveRequests(selectedRows)}
          >
            <div className="cr-icon-archive" /> Unarchive
          </Button>
        ) : (
          <Button
            className="archive-button"
            onClick={() => localConfirmArchiveRequests(selectedRows)}
          >
            <div className="cr-icon-archive" /> Archive
          </Button>
        )}
        {selectedTab === "archived" && (
          <Button
            className="archive-button"
            onClick={() => localConfirmDeleteArchivedRequests(selectedRows)}
            filledDanger
          >
            <div className="cr-icon-trash" /> Delete
          </Button>
        )}
      </ActionBar>
      {confirmationModal}
    </ReportCard>
  );
};

interface IRemediationRequestTableV2WrappedOwnProps
  extends Omit<IRemediationRequestTableV2Props, "remediationRequests"> {
  remediationRequestIds?: number[];
}

interface IRemediationRequestTableV2WrappedConnectedProps {
  remediationRequests: RemediationRequest[];
}

type IRemediationRequestTableV2WrappedProps =
  IRemediationRequestTableV2WrappedOwnProps &
    IRemediationRequestTableV2WrappedConnectedProps;

const RemediationRequestTableV2Wrapped: FC<
  IRemediationRequestTableV2WrappedProps
> = (props) => {
  return <RemediationRequestTableV2 {...props} />;
};

export default appConnect<
  IRemediationRequestTableV2WrappedConnectedProps,
  undefined,
  IRemediationRequestTableV2WrappedOwnProps
>((state, props) => {
  const remediationRequests: RemediationRequest[] = [];
  props.remediationRequestIds?.forEach((id) => {
    const req = state.common.remediationRequests
      ? state.common.remediationRequests[id]
      : undefined;
    if (req) {
      remediationRequests.push(req);
    }
  });

  return {
    remediationRequests: remediationRequests,
  };
})(RemediationRequestTableV2Wrapped);
