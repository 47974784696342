import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import {
  contactSupport,
  getCurrentOrgFromUserData,
  validateEmail,
} from "../../../_common/helpers";
import "../../style/components/NamedRoleInviteModal.scss";
import TextAreaWithPills, {
  IToken,
} from "../../../_common/components/TextAreaWithPills";
import { LabelColor } from "../../../_common/types/label";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import {
  IOrganisationUserCountAndLimits,
  organisationAccountType,
  organisationUserLimitType,
} from "../../../_common/types/organisations";
import PillLabel from "../PillLabel";
import InfoBanner, { BannerType } from "../InfoBanner";
import { FC, useCallback, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { backContext, useDefaultHistory } from "../../../_common/types/router";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { NamedRoleInviteLocationState } from "../../views/NamedRoleInvite";
import { fetchOrgUserLimits } from "../../reducers/org.actions";
import { CannedTypes } from "../../helpers/roles";
import { getOrgUsers } from "../../reducers/cyberRiskActions";

interface UserCountPillProps {
  limits?: IOrganisationUserCountAndLimits;
  activePlusPendingCount?: number;
}

export const UserCountPill = ({
  limits,
  activePlusPendingCount,
}: UserCountPillProps) => {
  if (!limits || limits.userLimitType == organisationUserLimitType.none) {
    return <></>;
  }

  const remainingUserSlots = limits.userLimit - limits.activeUserCount;

  const pillPopupText =
    "Users who only have read-only access, or Trust Page access, do not count towards your license limit.";

  // If the `activePlusPendingCount` exceeds the org user limit, then we
  // display the original `limits.activeUserCount` because
  // all the pending users must be free-only, and therefore will
  // not count towards the license limit.
  const actualLicensesUsed =
    activePlusPendingCount && activePlusPendingCount <= limits.userLimit
      ? activePlusPendingCount
      : limits.activeUserCount;

  return (
    <>
      <PillLabel
        color={
          remainingUserSlots > 1
            ? LabelColor.Green
            : remainingUserSlots > 0
              ? LabelColor.Orange
              : LabelColor.Red
        }
        popupContent={pillPopupText}
      >{`${actualLicensesUsed}/${limits.userLimit} Licenses Used`}</PillLabel>
    </>
  );
};

interface INamedRoleInviteModalProps extends BaseModalProps {
  isDistributorView?: boolean;
  adminRolesOnly?: boolean;
  selectCannedRole?: CannedTypes;
  returnTo?: backContext;
}

const NamedRoleInviteModal: FC<INamedRoleInviteModalProps> = ({
  active,
  onClose,
  isDistributorView = false,
  adminRolesOnly = false,
  selectCannedRole,
  returnTo,
}) => {
  const dispatch = useAppDispatch();
  const history = useDefaultHistory<NamedRoleInviteLocationState>();
  const orgName = useAppSelector(
    (state) => getCurrentOrgFromUserData(state.common.userData)?.name ?? ""
  );
  const isFreeOrg = useAppSelector(
    (state) =>
      getCurrentOrgFromUserData(state.common.userData)?.accountType ===
      organisationAccountType.free
  );
  const orgUserLimits = useAppSelector(
    (state) => state.cyberRisk.orgUserLimits
  );
  const userLimits = isDistributorView
    ? {
        activeUserCount: 0,
        userLimit: 0,
        userLimitType: organisationUserLimitType.none,
      }
    : orgUserLimits;

  const [emails, setEmails] = useState<string[]>([]);
  const userCount = (userLimits?.activeUserCount ?? 0) + emails.length;
  const invalidEmails = emails.filter((email) => !validateEmail(email));
  const duplicateEmails = Object.entries(
    emails.reduce<Record<string, number>>(
      (acc, email) => ({
        ...acc,
        [email]: (acc[email] || 0) + 1,
      }),
      {}
    )
  )
    .filter(([_, value]) => value > 1)
    .map(([key, _]) => key);

  const tokens = emails.reduce<IToken[]>((acc, email) => {
    const isValid =
      validateEmail(email) &&
      acc.findIndex((token) => token.value === email) === -1;
    let errMsg = "";
    if (!validateEmail(email)) {
      errMsg = "Invalid email address";
    } else if (acc.findIndex((token) => token.value === email) !== -1) {
      errMsg = "Duplicate address";
    }

    return [
      ...acc,
      {
        value: email,
        color: isValid ? LabelColor.Blue : LabelColor.Red,
        popupMsg: errMsg,
      },
    ];
  }, [] as IToken[]);

  const showBanner =
    userLimits &&
    userLimits.userLimitType == organisationUserLimitType.hard &&
    userCount > userLimits.userLimit;

  const onNewEmails = useCallback(
    (newEmails: string[]) =>
      setEmails((emails) => [...emails, ...newEmails.map((e) => e.trim())]),
    []
  );

  const onRemovedEmail = useCallback(
    (idx: number) =>
      setEmails((emails) => {
        const newEmails = [...emails];
        newEmails.splice(idx, 1);
        return newEmails;
      }),
    []
  );

  const onContinue = () => {
    if (!userLimits) {
      return;
    }

    if (!emails.length) {
      dispatch(
        addSimpleErrorAlert("You must enter at least one email address")
      );
      return;
    }

    if (invalidEmails.length > 0) {
      dispatch(
        addSimpleErrorAlert("he following email addresses were invalid:", [
          invalidEmails.join(","),
        ])
      );
      return;
    }

    if (duplicateEmails.length > 0) {
      dispatch(
        addSimpleErrorAlert("The following email addresses were duplicates:", [
          duplicateEmails.join(","),
        ])
      );
      return;
    }

    history.push("/namedroleinvite", {
      backContext: returnTo ?? {
        backTo: "/settings/users",
        backToText: "Back to Users Settings",
      },
      emails: emails,
      freeRolesOnly:
        userLimits?.userLimitType == organisationUserLimitType.hard &&
        userCount > userLimits.userLimit,
      adminRolesOnly,
      selectCannedRole,
    });

    // This modal could persist if called from the top nav, so clear out the emails after we've moved on to the role selection
    setEmails([]);
  };

  useEffect(() => {
    if (active) {
      // Ensure we have org user limits when the modal pops up
      dispatch(fetchOrgUserLimits());
      dispatch(getOrgUsers());
    }
  }, [active, dispatch]);

  return (
    <ModalV2
      className={"role-bases-invite-modal"}
      active={active}
      onClose={onClose}
      headerClassName={"named-role-invite-modal-header"}
      headerContent={
        <>
          <h2>{`Invite users to ${orgName}`}</h2>
          <p>
            You can invite multiple users by separating them with a comma,
            space, or newline.
          </p>
          {adminRolesOnly && (
            <InfoBanner
              className={"header-note"}
              message={
                "Please note: Your account currently has no admin users. As a result, your first invitees to the account must be administrators."
              }
            />
          )}
        </>
      }
      footerContent={
        <>
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            primary
            color={"blue"}
            onClick={onContinue}
            disabled={
              !userLimits ||
              emails.length === 0 ||
              invalidEmails.length > 0 ||
              duplicateEmails.length > 0
            }
          >
            Continue and set permissions
            <i className="cr-icon-arrow-right" />
          </Button>
        </>
      }
    >
      {userLimits ? (
        <div>
          <div className={"emails-label"}>
            Enter email addresses
            {!isFreeOrg && (
              <UserCountPill
                limits={userLimits}
                activePlusPendingCount={userCount}
              />
            )}
          </div>
          <TextAreaWithPills
            onNewTokens={onNewEmails}
            onRemovedToken={onRemovedEmail}
            tokens={tokens}
            defaultColor={LabelColor.Blue}
            delimiters={[",", " ", "\n"]}
          />
          {showBanner && (
            <InfoBanner
              type={BannerType.WARNING}
              message={
                <p>
                  Due to your license limit, you can only add users for
                  read-only or free feature access. Contact support to increase
                  your license limit.
                </p>
              }
              button={
                userLimits.userLimitType == organisationUserLimitType.hard && (
                  <Button onClick={contactSupport}>Contact support</Button>
                )
              }
            />
          )}
        </div>
      ) : (
        <LoadingBanner />
      )}
    </ModalV2>
  );
};

export default NamedRoleInviteModal;
