import { FC } from "react";
import { useBack } from "../../../_common/types/router";
import { useRouteMatch } from "react-router-dom";
import { PermissionRouteParams } from "../../UserBaseNavItems";
import UserBasePermissionHeader from "../../components/headers/UserBasePermissionHeader";
import {
  permissionSummaryUrl,
  permissionUsersUrl,
  permissionsUrl,
} from "../../UserBaseAppRouter";
import userbaseApi from "../../api/userbase.api";
import UserTableForScope from "../../components/users/UsersTableForScope";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";

const PermissionUsersView: FC = ({}) => {
  const { backAction, backText } = useBack();

  // Get the permission ID from the URL
  const match = useRouteMatch<PermissionRouteParams>();
  const scopeExternalId = match.params.externalID;

  // Get the data
  const { data, isLoading } = userbaseApi.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });
  const permission = data?.scope;

  return (
    <div className="userbase-permission-users">
      <UserBasePermissionHeader
        scopeExternalId={scopeExternalId}
        breadcrumbs={[
          { text: "Permissions", to: permissionsUrl },
          {
            text: data?.scope.description ?? "Permission Description",
            to: permissionSummaryUrl(scopeExternalId),
          },
          {
            text: "Users",
            to: permissionUsersUrl(scopeExternalId),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Users"}
      />
      {isLoading && <LoadingBanner />}

      {!isLoading && permission && (
        <UserTableForScope
          userUUIDs={permission.userUUIDs ?? []}
          scope={permission}
        />
      )}
    </div>
  );
};

export default PermissionUsersView;
