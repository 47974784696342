import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import DropDown from "../../_common/components/core/DropDown";
import Button from "../../_common/components/core/Button";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { fetchFindings, deleteFinding } from "../reducers/actions";
import {
  DateStringToUnixDateWithoutTimezone,
  severityMap,
  findingSeverityNumberToText,
} from "../../_common/helpers";
import { ReviewPublishModalName } from "./modals/ReviewPublishModal";
import { ConfirmationModalName } from "../../_common/components/modals/ConfirmationModal";
import { openModal } from "../../_common/reducers/commonActions";
import findingTypes from "../../_common/breachsight_constants";

import "../style/Findings.scss";
import Icon from "../../_common/components/core/Icon";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { breachSightProductDisplayName } from "../../_common/types/products";

export const FindingEntry = ({
  selected,
  finding,
  mode,
  decodeFindingType,
  onPublishFindingClick,
  onTransferFindingClick,
  onTransferCompanyClick,
  onEditFindingClick,
  onDeleteFindingClick,
  previewOnly,
}) => {
  return (
    <div className={classnames("finding", { selected })}>
      <div className="finding-data-bar">
        <div className="finding-header">
          {severityMap[findingSeverityNumberToText(finding.severity)].icon}
          <div>
            <div className="finding-title">{finding.name}</div>
            <div className="finding-url">
              <a href={finding.url} target="_blank" rel="noopener noreferrer">
                {finding.url}
              </a>
            </div>
          </div>
        </div>
        <div className="finding-layout">
          <div className="finding-image">
            {finding.image ? (
              <img
                className="finding-image"
                src={finding.image}
                alt="screenshot"
              />
            ) : (
              <div className="finding-image empty">no image</div>
            )}
          </div>
          <div className="finding-attribute-list">
            <label htmlFor="type">Type</label>
            <div className="text">{decodeFindingType(finding.type)}</div>
            <label htmlFor="attribution">Attribution</label>
            <div className="text">{finding.attribution}</div>
            <label htmlFor="significance">Significance</label>
            <div className="text">{finding.note}</div>
            <label htmlFor="detected">Detected On</label>
            <div className="text">
              <DateTimeFormat
                dateTime={moment(
                  DateStringToUnixDateWithoutTimezone(
                    finding.date_detected,
                    true
                  )
                ).toISOString()}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {!previewOnly && (
          <div className="finding-buttonbar">
            <Button onClick={() => onEditFindingClick(finding)}>
              Edit
              <Icon name="edit" onClick={() => onEditFindingClick(finding)} />
            </Button>
            <Button danger onClick={() => onDeleteFindingClick(finding)}>
              Remove
              <Icon
                name="trash"
                onClick={() => onDeleteFindingClick(finding)}
              />
            </Button>
            {mode === "CUSTOMER" && (
              <Button primary onClick={() => onPublishFindingClick(finding)}>
                Publish
              </Button>
            )}
            {mode === "COMPANY" && (
              <div className="transfer-div">
                <DropDown
                  id="transfer-menu"
                  action={<div className="transfer-button">{"Reassign"}</div>}
                >
                  <Button onClick={() => onTransferFindingClick(finding)}>
                    {`Reassign to ${breachSightProductDisplayName} Account`}
                  </Button>
                  <Button onClick={() => onTransferCompanyClick(finding)}>
                    Reassign to another Company
                  </Button>
                </DropDown>
              </div>
            )}
            {mode === "GROUP" && (
              <div className="transfer-div">
                <DropDown
                  id="transfer-menu"
                  action={<div className="transfer-button">{"Assign"}</div>}
                >
                  <Button onClick={() => onTransferFindingClick(finding)}>
                    {`Assign to ${breachSightProductDisplayName} Account`}
                  </Button>
                  <Button onClick={() => onTransferCompanyClick(finding)}>
                    Assign to another Company
                  </Button>
                </DropDown>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

FindingEntry.propTypes = {
  selected: PropTypes.bool.isRequired,
  finding: PropTypes.object.isRequired,
  mode: PropTypes.string,
  decodeFindingType: PropTypes.func.isRequired,
  onPublishFindingClick: PropTypes.func,
  onTransferFindingClick: PropTypes.func,
  onTransferCompanyClick: PropTypes.func,
  onEditFindingClick: PropTypes.func,
  onDeleteFindingClick: PropTypes.func,
  previewOnly: PropTypes.bool,
};

FindingEntry.defaultProps = {
  previewOnly: false,
  onPublishFindingClick: null,
  onTransferFindingClick: null,
  onTransferCompanyClick: null,
  onEditFindingClick: null,
  onDeleteFindingClick: null,
  mode: "CUSTOMER",
};

class Findings extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    orgId: PropTypes.number,
    groupId: PropTypes.number,
    companyDomain: PropTypes.string,
    mode: PropTypes.string.isRequired,
    setFindingPanel: PropTypes.func.isRequired,
    setCustomerSelectionPanel: PropTypes.func.isRequired,
    setCompanySelectionPanel: PropTypes.func.isRequired,
    findings: PropTypes.array,
    selectedFinding: PropTypes.object,
    customerList: PropTypes.object,
  };

  static defaultProps = {
    orgId: 0,
    groupId: 0,
    companyDomain: "",
    findings: null,
    selectedFinding: null,
    customerList: { result: {} },
  };

  UNSAFE_componentWillMount() {
    if (!this.props.findings) {
      this.props.dispatch(
        fetchFindings(
          false,
          this.props.orgId,
          this.props.groupId,
          this.props.companyDomain
        )
      );
    }
  }

  onPublishFindingClick = (finding) => {
    this.props.dispatch(
      openModal(ReviewPublishModalName, {
        findings: [finding],
        orgId: this.props.orgId,
        decodeFindingType: this.decodeFindingType,
      })
    );
  };

  onTransferFindingClick = (finding) => {
    this.props.setCustomerSelectionPanel(true, finding, false);
  };

  onTransferAllClicked = () => {
    this.props.setCustomerSelectionPanel(true, null, true);
  };

  onTransferCompanyClick = (finding) => {
    this.props.setCompanySelectionPanel(true, finding, false);
  };

  onTransferCompanyAllClick = () => {
    this.props.setCompanySelectionPanel(true, null, true);
  };

  onEditFindingClick = (finding) => {
    this.props.setFindingPanel(true, null, finding);
  };

  onDeleteFindingClick = (finding) => {
    let description =
      "The finding will be removed completely from the system (it is not based on a search result).";
    if (finding.datastoreSearchResultId > 0 || finding.uuid !== "") {
      description =
        "All finding data will be removed and the associated search result will be returned to the 'unprocessed' state.";
    }

    this.props.dispatch(
      openModal(ConfirmationModalName, {
        title: `Are you sure you want to remove finding '${finding.name}'?`,
        description: <p>{description}</p>,
        buttonText: "Yes, remove",
        buttonAction: async () => {
          try {
            await this.props.dispatch(
              deleteFinding(
                finding.organisation_id,
                finding.group_id,
                finding.finding_id,
                this.props.companyDomain
              )
            );
          } catch (e) {
            this.props.dispatch(
              addDefaultUnknownErrorAlert("Error removing the finding")
            );
            throw e;
          }
          this.props.dispatch(addDefaultSuccessAlert("Finding removed"));
        },
      })
    );
  };

  onPublishAllClicked = () => {
    this.props.dispatch(
      openModal(ReviewPublishModalName, {
        findings: this.props.findings,
        orgId: this.props.orgId,
        decodeFindingType: this.decodeFindingType,
      })
    );
  };

  decodeFindingType = (type) => {
    const ty = type.trim().toLowerCase();
    let decoded = null;
    findingTypes.map((entry) => {
      if (entry.id === ty) {
        decoded = entry.text;
      }
      return null;
    });
    if (decoded) {
      return decoded;
    }
    return "Undetermined";
  };

  render() {
    if (!this.props.findings) {
      return (
        <div className="analyst-findings">
          <LoadingBanner tight />
        </div>
      );
    }

    const anyFindings = this.props.findings && this.props.findings.length > 0;
    return (
      <div className="analyst-findings">
        {anyFindings ? (
          <div>
            <div className="top-bar">
              {this.props.mode === "CUSTOMER" && (
                <div className="transfer-div">
                  <Button primary onClick={this.onPublishAllClicked}>
                    Publish all Findings
                  </Button>
                </div>
              )}
              {this.props.mode == "COMPANY" && (
                <div className="transfer-div">
                  <DropDown
                    id="transfer-all-menu"
                    action={
                      <div className="transfer-button">{"Reassign all"}</div>
                    }
                  >
                    <Button onClick={this.onTransferAllClicked}>
                      {`Reassign all to ${breachSightProductDisplayName} Account`}
                    </Button>
                    <Button onClick={this.onTransferCompanyAllClick}>
                      Reassign all to another Company
                    </Button>
                  </DropDown>
                </div>
              )}
              {this.props.mode === "GROUP" && (
                <div className="transfer-div">
                  <DropDown
                    id="transfer-all-menu"
                    action={
                      <div className="transfer-button">{"Assign all"}</div>
                    }
                  >
                    <Button onClick={this.onTransferAllClicked}>
                      {`Assign all to ${breachSightProductDisplayName} Account`}
                    </Button>
                    <Button onClick={this.onTransferCompanyAllClick}>
                      Assign all to Company
                    </Button>
                  </DropDown>
                </div>
              )}
            </div>
            {this.props.findings.length > 0 && (
              <div className="findings">
                {this.props.findings.map((finding) => (
                  <FindingEntry
                    key={finding.finding_id}
                    selected={
                      this.props.selectedFinding
                        ? this.props.selectedFinding.finding_id ===
                          finding.finding_id
                        : false
                    }
                    mode={this.props.mode}
                    finding={finding}
                    decodeFindingType={this.decodeFindingType}
                    onPublishFindingClick={this.onPublishFindingClick}
                    onTransferFindingClick={this.onTransferFindingClick}
                    onTransferCompanyClick={this.onTransferCompanyClick}
                    onEditFindingClick={this.onEditFindingClick}
                    onDeleteFindingClick={this.onDeleteFindingClick}
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            {this.props.mode === "CUSTOMER" && (
              <span>There are no unpublished findings for this customer.</span>
            )}
            {this.props.mode === "GROUP" && (
              <span>
                There are no unpublished findings for this exploratory search.
              </span>
            )}
            {this.props.mode === "COMPANY" && (
              <span>There are no unpublished findings for this company.</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Findings;
