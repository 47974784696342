import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import {
  useLocaleCompare,
  useSortingWithPagination,
} from "../../../_common/hooks";
import { SaaSUser } from "../../api/types";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { formatDateAsLocal, LogError } from "../../../_common/helpers";
import TeamsPills from "../TeamsPills";
import ReportCard from "../../../_common/components/ReportCard";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { FC } from "react";
import "./RiskUserSelection.scss";
import classnames from "classnames";
import userbaseApi from "../../api/userbase.api";
import { calculateWaivedAssetsForRisks } from "../../../vendorrisk/reducers/risks.actions";
import { riskProfileUrl } from "../../UserBaseAppRouter";
import { useHistory } from "react-router-dom";
import UserBaseAPI from "../../api/userbase.api";
import { computeUsersInRemediation } from "../../views/RiskProfileView";
import { useHasOrgEntitlement } from "../../../_common/permissions";
import * as Permissions from "../../../_common/permissions";

interface IRiskUserSelectionProps {
  riskId: string;
  selectedUserUUIDs: string[];
  onSelectionChange: (selectedUUIDs: string[]) => void;
  headerTitle: string;
  className?: string;
}

const RiskUserSelection: FC<IRiskUserSelectionProps> = ({
  riskId,
  selectedUserUUIDs,
  onSelectionChange,
  headerTitle,
  className,
}) => {
  const history = useHistory();
  const { caseInsensitiveCompare } = useLocaleCompare();

  const orgHasUserBaseRemediationRequests = useHasOrgEntitlement(
    Permissions.OrgAccessUserBaseRemediationRequests
  );

  const { data: risksData, isLoading: risksLoading } =
    userbaseApi.useGetUserBaseRisksV1Query(
      { riskId },
      { skip: !riskId } // don't fetch the risks unless a valid risk ID is provided
    );
  const { data: riskUsers, isLoading: riskUsersLoading } =
    userbaseApi.useGetUserBaseRiskUsersV1Query(
      { riskId },
      { skip: !riskId } // don't fetch the risks unless a valid risk ID is provided
    );
  const { data: riskWaivers } = userbaseApi.useGetUserBaseRiskWaiversV1Query();
  const { data: remediationsRequests, isLoading: remediationRequestLoading } =
    UserBaseAPI.useGetUserBaseRemediationsV1Query(undefined, {
      skip: !orgHasUserBaseRemediationRequests,
    });

  const isLoading =
    risksLoading || riskUsersLoading || remediationRequestLoading;
  const risks = risksData?.risks ?? [];
  const risk = risks.find((r) => r.id === riskId);

  const waivedRiskAssets = calculateWaivedAssetsForRisks(
    risk ? [risk] : [],
    true,
    undefined,
    undefined,
    riskWaivers?.waivers ?? []
  );
  const usersWaived =
    risk && waivedRiskAssets[risk.id]
      ? waivedRiskAssets[risk.id].assetsWaived
      : {};
  const usersPendingWaiver =
    risk && waivedRiskAssets[risk.id]
      ? waivedRiskAssets[risk.id].assetsPendingWaiver
      : {};
  const waivedUserUUIDs = Object.keys({
    ...usersWaived,
    ...usersPendingWaiver,
  });

  const usersUnderRemediation = computeUsersInRemediation(
    remediationsRequests?.requests ?? []
  );
  const underRemediationUserUUIDs: string[] =
    usersUnderRemediation[riskId]?.userUUIDs ?? [];

  const uuidsOfUnselectableUsers = [
    ...new Set([...waivedUserUUIDs, ...underRemediationUserUUIDs]),
  ];

  const users = riskUsers?.users ?? [];
  const usersAvailableForSelection = users.filter(
    (u) => !uuidsOfUnselectableUsers.includes(u.uuid)
  );

  const columHeaders: IXTableColumnHeader[] = [
    { id: "name", text: "Name", sortable: true, className: "col-name" },
    { id: "team", text: "Team", sortable: true, className: "col-team" },
    {
      id: "first_detected",
      text: "First detected",
      sortable: true,
      className: "col-first-detected",
    },
  ];

  const [
    pageItems,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<SaaSUser, "name" | "team" | "first_detected">(
    users,
    "name",
    SortDirection.ASC,
    {
      name: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase() ?? u.email],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
      team: {
        orderFuncs: [
          (u) => u.teams.sort(caseInsensitiveCompare).join(",") ?? "",
        ],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
      first_detected: {
        orderFuncs: [(u) => u.firstDetected ?? ""],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
    },
    20
  );

  if (isLoading) {
    return <LoadingBanner />;
  }

  // we landed on a risk user selection page for a specific risk so
  // make sure the risk can be found in the active list of risks if
  // not go back to the risk profile
  if (!risk) {
    LogError(
      `Cannot find UserBase risk ID ${riskId}`,
      new Error(`Cannot find UserBase risk ID`)
    );
    history.push(riskProfileUrl);
    return;
  }

  const rows = pageItems.map<IXTableRow<string>>((u) => {
    return {
      id: u.uuid,
      selected: selectedUserUUIDs.includes(u.uuid),
      selectionDisabled: uuidsOfUnselectableUsers.includes(u.uuid),
      selectionDisabledHelpText: "User is already under remediation or waived",
      cells: [
        <XTableCell key={"name"}>{u.name || u.email}</XTableCell>,
        <XTableCell key={"team"}>
          {u.teams ? <TeamsPills teams={u.teams} /> : "-"}
        </XTableCell>,
        <XTableCell key={"first_detected"}>
          {u.firstDetected ? formatDateAsLocal(u.firstDetected) : "-"}
        </XTableCell>,
      ],
    };
  });

  const onSelectUser = (userUUID: string) => {
    if (selectedUserUUIDs.indexOf(userUUID) === -1) {
      onSelectionChange([...selectedUserUUIDs, userUUID]);
    } else {
      onSelectionChange(
        selectedUserUUIDs.filter(
          (selectedUserUUID) => selectedUserUUID != userUUID
        )
      );
    }
  };

  const onSelectToggle = (selectAll: boolean) => {
    if (selectAll) {
      onSelectionChange(usersAvailableForSelection.map((u) => u.uuid));
    } else {
      onSelectionChange([]);
    }
  };

  return (
    <>
      <ReportCard
        newStyles
        className={classnames("userrisk-risk-user-selection", className)}
      >
        <div className={"header"}>{headerTitle}</div>
        <div className={"risk"}>
          <AdjustedSeverityIcon
            severity={SeverityAsString(risk.severity)}
            baseSeverity={
              risk.baseSeverity
                ? SeverityAsString(risk.baseSeverity)
                : undefined
            }
          />
          <div className={"name"}>{risk.title}</div>
          <div className={"category"}>{risk.categoryTitle}</div>
          <div className={"occurrences"}>
            {users.length} occurrences{" "}
            {waivedUserUUIDs.length > 0
              ? ` (${waivedUserUUIDs.length} already waived or pending waiver)`
              : ""}
          </div>
        </div>
        <XTable
          className={"userrisk-risk-user-selection-table"}
          loading={isLoading}
          selectable={true}
          hideColumnHeaders={false}
          onSelectToggle={onSelectToggle}
          onSelectAllClick={() => onSelectToggle(true)}
          onSelectNoneClick={() => onSelectToggle(false)}
          onSelectClick={onSelectUser}
          columnHeaders={columHeaders}
          rows={rows}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          expandableRows={false}
          pagination={{
            currentPage,
            totalPages,
            onPageChange,
            hidePaginationIfSinglePage: true,
          }}
        />
      </ReportCard>
    </>
  );
};

export default RiskUserSelection;
