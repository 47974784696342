import DragDropUpload, {
  DragDropUploadBaseProps,
  EDragDropUploadStyle,
} from "./DragDropUpload";
import { FC, useCallback, useEffect, useState } from "react";
import Modal, { BaseModalProps } from "./ModalV2";
import Button from "./core/Button";
import DragDropUploadV2 from "./DragDropUploadV2";
import XTable, { IXTableRow, XTableCell } from "./core/XTable";
import { TimerProgressBar } from "./ProgressBar";
import "../style/components/DragDropUpload.scss";
import IconButton from "./IconButton";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import { pluralise } from "../helpers";

enum EFileErrorType {
  Unknown,
  Size,
  Type,
  Upload,
}

interface DragDropUploadMultiProps
  extends Omit<
    DragDropUploadBaseProps,
    "onFileSelected" | "onFileRejected" | "multiple" | "loading"
  > {
  style?: EDragDropUploadStyle;
  onUploadFile: (file: File) => Promise<any>;
  onDone: (numUploaded: number) => void;
  autoCloseOnAllComplete?: boolean;
  maxConcurrentUpload?: number;
}

interface FileError {
  type: EFileErrorType;
  message: string;
}

interface FileToUpload extends File {
  errors: FileError[];
  errorMessage?: string;
  uploading?: boolean;
  completed?: boolean;
  uuid?: string; // Used for uniqueness
}

const DragDropUploadMulti: FC<DragDropUploadMultiProps> = ({
  style = EDragDropUploadStyle.Default,
  onUploadFile,
  onDone,
  autoCloseOnAllComplete,
  maxConcurrentUpload = 2,
  maxFileSize,
  acceptedFileTypeFilters,
  disabled,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<FileToUpload[]>([]);
  const [modalActive, setModalActive] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<FileToUpload[]>([]);
  const [uploading, setUploading] = useState(false);

  const filesSelected = (acceptedFiles: File[], rejectedFiles: File[]) => {
    setSelectedFiles([]);

    const files = [...rejectedFiles, ...acceptedFiles] as FileToUpload[];

    files.forEach((f) => (f.uuid = uuidv4()));

    checkFiles(files, maxFileSize, acceptedFileTypeFilters);

    setSelectedFiles(files);

    if (files.length > 1) {
      setModalActive(true);
    }

    startUpload(files.filter((f) => f.errors.length == 0));
  };

  const startUpload = (specificFilesToUpload?: FileToUpload[]) => {
    const filesToUpload: FileToUpload[] = [];

    if (specificFilesToUpload && specificFilesToUpload.length >= 1) {
      filesToUpload.push(...specificFilesToUpload);
    } else {
      // No specific files to upload so just send everything that is ready
      filesToUpload.push(
        ...selectedFiles.filter(
          (f) => f.errors.length == 0 && !f.uploading && !f.completed
        )
      );
    }

    if (filesToUpload.length === 0) {
      return; // Nothing to do
    }

    setPendingFiles((existing) => {
      const newPending = [...existing];
      filesToUpload.forEach((f) => {
        if (!newPending.find((np) => np.uuid === f.uuid)) {
          newPending.push(f);
        }
      });
      return newPending;
    });
  };

  const retry = (filesToRetry: FileToUpload[]) => {
    filesToRetry.forEach((f) => (f.errors = []));

    setSelectedFiles((existing) => {
      const newSelected = [...existing];
      filesToRetry.forEach((f) => {
        const idx = newSelected.findIndex((ef) => ef.uuid === f.uuid);
        newSelected[idx].errors = [];
      });

      return newSelected;
    });

    startUpload(filesToRetry.filter((f) => f.errors.length == 0));
  };

  const remove = (fileToRemove: FileToUpload) => {
    setSelectedFiles((existing) => {
      const remaining = existing.filter((f) => f !== fileToRemove);

      // Close the modal if all files removed
      if (remaining.length === 0) {
        setModalActive(false);
      }

      return remaining;
    });
  };

  // Trigger onDone + auto-close the modal if needed
  const done = useCallback(
    (completedFiles: number) => {
      if (autoCloseOnAllComplete) {
        setModalActive(false);
        onDone(completedFiles);
        setSelectedFiles([]);
      } else {
        onDone(completedFiles);
      }
    },
    [onDone, autoCloseOnAllComplete]
  );

  // Respond when something changes about the selected files
  useEffect(() => {
    // Set overall uploading state
    setUploading(selectedFiles.some((f) => f.uploading === true));

    // Trigger onDone if all files completed + auto-close the modal
    if (
      selectedFiles.length > 0 &&
      selectedFiles.every((f) => f.completed === true)
    ) {
      done(selectedFiles.length);
    }

    // Always show the modal if any errors
    if (selectedFiles.some((f) => f.errors.length > 0)) {
      setModalActive(true);
    }
  }, [selectedFiles]);

  // Respond when files queued to upload are changed
  useEffect(() => {
    let filesToUpload = pendingFiles.filter(
      (f) => f.errors.length == 0 && !f.completed && !f.uploading
    );

    if (filesToUpload.length === 0) {
      return; // Nothing to do here
    }

    const currentlyUploading = selectedFiles.filter((f) => f.uploading).length;
    const remainingUploadSlots = maxConcurrentUpload - currentlyUploading;

    filesToUpload = filesToUpload.slice(0, remainingUploadSlots);

    if (filesToUpload.length === 0) {
      return;
    }

    // Remove the files to upload from the pending set
    setPendingFiles((existing) => {
      return existing.filter(
        (ef) => !filesToUpload.find((f) => f.uuid === ef.uuid)
      );
    });

    // Only send max concurrent at once
    filesToUpload.forEach((f) => {
      // Mark for upload
      setSelectedFiles((existing) => {
        const newSelected = [...existing];
        const idx = newSelected.findIndex((ef) => ef.uuid === f.uuid);
        newSelected[idx].uploading = true;
        return newSelected;
      });

      onUploadFile(f)
        .then(() => {
          setSelectedFiles((existing) => {
            const newSelected = [...existing];
            const idx = newSelected.findIndex((ef) => ef.uuid === f.uuid);
            newSelected[idx].completed = true;
            newSelected[idx].uploading = false;
            return newSelected;
          });

          // Trigger more if needed
          startUpload();
        })
        .catch(() => {
          setSelectedFiles((existing) => {
            const newSelected = [...existing];
            const idx = newSelected.findIndex((ef) => ef.uuid === f.uuid);
            const errored = newSelected.splice(idx, 1);
            errored[0].errors.push({
              type: EFileErrorType.Upload,
              message: "Upload error",
            });
            errored[0].uploading = false;
            return [...errored, ...newSelected];
          });

          // Trigger more if needed
          startUpload();
        });
    });
  }, [pendingFiles, selectedFiles]);

  // Callback from modal close with remaining errors after being manually closed by user
  const modalClosed = () => {
    setModalActive(false);

    const filesUploadedSuccessfully = selectedFiles.filter((f) => f.completed);

    if (filesUploadedSuccessfully.length > 0) {
      done(filesUploadedSuccessfully.length);
    }

    setSelectedFiles([]);
  };

  const baseProps: DragDropUploadBaseProps = {
    multiple: true,
    loading: uploading,
    onSelectionChange: filesSelected,
    maxFileSize: maxFileSize,
    acceptedFileTypeFilters: acceptedFileTypeFilters,
    disabled: disabled,
  };

  return (
    <>
      {style === EDragDropUploadStyle.Default && (
        <DragDropUpload
          doNotKeepState={true}
          clickText={"Click to upload documents"}
          {...baseProps}
        />
      )}
      {style == EDragDropUploadStyle.Compact && (
        <DragDropUploadV2 {...baseProps} />
      )}
      <MultiFileUploadModal
        active={modalActive}
        onClose={modalClosed}
        files={selectedFiles}
        uploading={uploading}
        onRetry={retry}
        onRemove={remove}
      />
    </>
  );
};

const checkFiles = (
  files: FileToUpload[],
  maxSize: number,
  acceptedFileTypeFilters?: string[]
) => {
  files.forEach((f) => {
    f.errors = [];
    if (f.size > maxSize) {
      f.errors.push({
        type: EFileErrorType.Size,
        message: `File size exceeds ${formatFileSize(maxSize)}`,
      });
    }
    if (
      f.type &&
      acceptedFileTypeFilters &&
      !acceptedFileTypeFilters.includes(f.type)
    ) {
      const acceptedTypes = "jpg, png, pdf, docx, xls, csv, txt.";
      f.errors.push({
        type: EFileErrorType.Type,
        message: `File type not supoported. Try: ${acceptedTypes}`,
      });
    }
  });
};

const formatFileSize = (bytes: number): string => {
  const KB = 1000;
  const MB = KB * 1000;

  if (bytes < MB) {
    const sizeInKB = Math.ceil(bytes / KB);
    return `${sizeInKB}KB`;
  } else {
    const sizeInMB = Math.ceil(bytes / MB);
    return `${sizeInMB}MB`;
  }
};

interface MultiFileUploadModalProps extends BaseModalProps {
  files: FileToUpload[];
  uploading: boolean;
  onRetry: (files: FileToUpload[]) => void;
  onRemove: (file: FileToUpload) => void;
}

const MultiFileUploadModal: FC<MultiFileUploadModalProps> = ({
  active,
  onClose,
  files,
  uploading,
  onRetry,
  onRemove,
}) => {
  const availableTime = 6000; // TODO work out a reasonable approximation based on size?

  const fileRows = files.map((f) => {
    const fileNameContainerClasses = classNames("file-name-size-container", {
      errors: f.errors,
      done: f.completed,
    });

    const row: IXTableRow = {
      id: f.uuid ?? "",
      cells: [
        <XTableCell key={"filename"} className={"file-cell"}>
          <div className={fileNameContainerClasses}>
            <span className={"file-name"}>{f.name}</span>
          </div>
          {f.errors.length > 0 &&
            f.errors.map((e) => (
              <div
                key={e.type}
                className={"error-container multi-error-container"}
              >
                <div className={"error-content"}>
                  <i className={"cr-icon-risk"} /> {getErrorText(e)}
                </div>
              </div>
            ))}
        </XTableCell>,
        <XTableCell
          key={"progress"}
          className={f.errors.length == 0 ? "progress-cell" : ""}
        >
          {f.errors.length == 0 && (
            <TimerProgressBar
              availableTime={availableTime}
              initialProgress={5}
              randomiseProgressPerTick
              isRunning={f.uploading ?? false}
              shouldCompleteOnStop
              timeUpdateSplit={750}
            />
          )}
          {f.errors.length > 0 && (
            <div className="error-buttons">
              {f.errors.length == 1 &&
                f.errors[0].type === EFileErrorType.Upload && (
                  <IconButton
                    icon={<i className={"cr-icon-redo"} />}
                    onClick={() => onRetry([f])}
                    hoverText={"Retry upload"}
                  />
                )}
              {f.errors.length > 0 && (
                <IconButton
                  icon={<i className={"icon-x"} />}
                  onClick={() => onRemove(f)}
                  hoverText={"Remove"}
                />
              )}
            </div>
          )}
        </XTableCell>,
      ],
    };

    return row;
  });

  // Files that have failed upload can be retried - other error types cannot
  const retryableFiles = files.filter(
    (f) => f.errors.length == 1 && f.errors[0].type === EFileErrorType.Upload
  );

  let headerText = `Uploading ${files.length} documents`;
  const numErrors = files.filter((f) => f.errors.length > 0).length;
  const numComplete = files.filter((f) => f.completed).length;
  const numUploading = files.filter(
    (f) => !f.completed && f.errors.length == 0
  ).length;

  if (numErrors) {
    headerText += ` (${numErrors} failed)`;
  }

  return (
    <Modal
      className={"multi-file-upload-modal"}
      active={active}
      onClose={onClose}
      headerContent={headerText}
      footerClassName={"multi-file-upload-modal-footer"}
      footerContent={
        <>
          <div className={"footer-left"}>
            {numUploading > 0 && (
              <div className={"num-completed"}>{numUploading} processing</div>
            )}
            {numUploading === 0 && (
              <>
                {numComplete > 0 && (
                  <div className={"num-completed"}>{numComplete} complete</div>
                )}
                {numErrors > 0 && (
                  <div className={"num-completed"}>
                    {numErrors} {pluralise(numErrors, "error", "errors")}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="btn-group">
            {retryableFiles.length > 0 && (
              <Button
                tertiary
                disabled={uploading}
                onClick={() => onRetry(retryableFiles)}
              >
                Retry all
              </Button>
            )}
            <Button primary disabled={uploading} onClick={onClose}>
              Close
            </Button>
          </div>
        </>
      }
      disallowClose
    >
      <XTable
        columnHeaders={[
          {
            id: "filename",
            text: "File",
          },
          {
            id: "progress",
            text: "",
          },
        ]}
        rows={fileRows}
        className={"files-uploading-table"}
        stickyColumnHeaders
      />
    </Modal>
  );
};

const getErrorText = (error: FileError) => {
  if (error) {
    switch (error.type) {
      case EFileErrorType.Size:
        return error.message ?? "File size too large";
      case EFileErrorType.Type:
        return error.message ?? "File type unsupported";
      case EFileErrorType.Upload:
        return error.message ?? "Upload error";
      default:
        return "An error occurred";
    }
  }
  return "";
};

export default DragDropUploadMulti;
