import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice, { FeedTypes, isEmptySnapshot } from "../Slice";
import ThreatMonitoringAPI, {
  GetResultsV1ReqBody,
} from "../api/threatmonitoring.api";
import { ThreatMonitoringFeedType } from "../api/types";
import { useDefaultHistory, useNavigateTo } from "../../_common/types/router";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";

// useXTablePagination manages pagination for a feed shown in an XTable component.
// The returned object can be used for the XTable pagination prop, but also includes additional information
// which can be used to display the current page, displayed ranges and totals etc.
export function useXTablePagination<I = any>({
  feedType,
  feedItems,
  totalResults,
  pageSize,
}: {
  feedType: FeedTypes;
  feedItems?: I[];
  totalResults?: number;
  pageSize: number;
}) {
  const dispatch = useAppDispatch();
  const pageNum = useAppSelector((s) =>
    tmSlice.selectors.pageNumberForFeed(s, feedType)
  );

  feedItems = feedItems || [];
  totalResults = totalResults || 0;

  const totalPages = Math.ceil(totalResults / pageSize);
  const startIdx = pageNum * pageSize + 1;
  const endIdx = startIdx + feedItems.length - 1;

  return {
    startIdx,
    endIdx,
    totalPages,
    totalResults,
    pageNum,
    currentPage: pageNum + 1,
    setPageNum: (pageNum: number) => {
      dispatch(
        tmSlice.actions.setPageNumber({
          feed: feedType,
          page: pageNum,
        })
      );
    },
    onPageChange: (page: number) => {
      dispatch(
        tmSlice.actions.setPageNumber({
          feed: feedType,
          page: page - 1,
        })
      );
    },
    hidePaginationIfSinglePage: true,
  };
}

// useStartSession returns a function which can be used to start a threat assessment session for the feedQuery.
// Starting a session means snapshotting the current page results and total results, and navigating to the threat detail view.
export function useStartSession(query: GetResultsV1ReqBody, backText: string) {
  const dispatch = useAppDispatch();
  const navTo = useNavigateTo({ feedQuery: query }, { useGoBack: true });
  const [fetchSessionUUIDs] =
    ThreatMonitoringAPI.useLazyGetResultUUIDsV1Query();

  return async function (uuid: string) {
    try {
      const resp = await fetchSessionUUIDs(query).unwrap();

      dispatch(
        tmSlice.actions.snapshotSession({
          uuids: resp.uuids,
          query,
        })
      );
      navTo(`/breachrisk/threatmonitoring/threat/${uuid}`, backText);
    } catch (e) {
      console.error(e);
      dispatch(tmSlice.actions.clearSessionSnapshot());
      dispatch(addDefaultUnknownErrorAlert("Failed to load threats"));
    }
  };
}

// useResultSessionPagination manages pagination and navigation for a threat assessment session.
// This uses a snapshot of the current open feed page, so that workflow actions on threat results don't modify the set of results in the session.
export function useResultSessionPagination({
  uuid,
  feedQuery,
}: {
  uuid: string;
  feedQuery: GetResultsV1ReqBody;
}) {
  const history = useDefaultHistory<{ feedQuery: GetResultsV1ReqBody }>();
  const session = useAppSelector(tmSlice.selectors.sessionSnapshot);

  // get the currently selected result
  const { data, isLoading, error } = ThreatMonitoringAPI.useGetResultV1Query({
    uuid: uuid,
  });

  const { data: totalsData } = ThreatMonitoringAPI.useGetResultTotalsV1Query({
    feedType: ThreatMonitoringFeedType.Open,
    feedUpTo: feedQuery.feedUpTo || getDateOfLastFeedDownload(),
    filters: feedQuery.filters,
  });

  if (isLoading || error || isEmptySnapshot(session)) {
    return {
      isLoading,
      error,
    };
  }

  const indexOfResult = session.uuids.indexOf(uuid);

  if (indexOfResult === -1) {
    return {
      result: null,
      error: new Error("Result not found"),
    };
  }

  const result = data?.result;
  const actors = data?.actors;

  const next = async () => {
    if (indexOfResult + 1 >= session.uuids.length) {
      return;
    }

    const nextItemUUID = session.uuids[indexOfResult + 1];

    history.replace(`/breachrisk/threatmonitoring/threat/${nextItemUUID}`, {
      ...history.location.state,
      feedQuery,
    });
  };

  const prev = async () => {
    if (indexOfResult === 0) {
      return;
    }

    const prevItemUUID = session.uuids[indexOfResult - 1];

    history.replace(`/breachrisk/threatmonitoring/threat/${prevItemUUID}`, {
      ...history.location.state,
      feedQuery,
    });
  };

  return {
    result,
    actors,
    idx: indexOfResult + 1,
    totalInSession: session.uuids.length,
    totalOpen: totalsData?.count,
    isLoading: false,
    next,
    prev,
    hasNext: indexOfResult + 1 < session.uuids.length,
    hasPrev: indexOfResult > 0,
  };
}
