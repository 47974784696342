import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import SlidePanelKVTable from "../SlidePanelKVTable";
import { appSummaryUrl } from "../../UserBaseAppRouter";
import AppStatusDisplay from "../applications/AppStatusDisplay";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import userbaseApi from "../../api/userbase.api";
import "./SaaSAppRiskPanel.scss";

import Button from "../../../_common/components/core/Button";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import Score from "../../../vendorrisk/components/Score";
import ScrollableDiv from "../../../vendorrisk/components/ScrollableDiv";
import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";

import _ from "lodash";

interface SaaSAppRiskPanelProps {
  appName: string;
}

const SaaSAppRiskPanel: FC<SaaSAppRiskPanelProps> = ({ appName }) => {
  const history = useHistory();

  const { data, isLoading } = userbaseApi.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  const app = useMemo(() => {
    return data?.app;
  }, [data?.app, isLoading]);

  const { data: appUsers, isLoading: appUsersIsLoading } =
    userbaseApi.useGetUserBaseAppUsersV1Query({ app: appName });

  const appFirstDetected = useMemo(() => {
    return _.min((appUsers?.users ?? []).map((u) => u.firstDetected));
  }, [appUsers?.users, appUsersIsLoading]);

  return (
    <div className="saas-app-risk-panel">
      <div className="title-and-buttons">
        <div className="saas-app-title">{appName}</div>
        <div className="saas-app-title-buttons">
          <Button arrow onClick={() => history.push(appSummaryUrl(appName))}>
            View app profile
          </Button>
        </div>
      </div>

      <ScrollableDiv newStyles isLoading={isLoading}>
        <SlidePanelSection title="User overview">
          <SlidePanelKVTable
            rows={[
              {
                key: "Security Rating",
                value: (
                  <div className="score-container">
                    <ColorGrade
                      score={app?.securityScore}
                      size={ColorGradeSize.Small}
                    />
                    <Score score={app?.securityScore} small />
                  </div>
                ),
              },
              {
                key: "Exposure Level",
                value: (
                  <ScopeRiskLevelDisplay
                    riskLevel={app?.highestScopeRiskLevel}
                  />
                ),
              },
              {
                key: "Current Users",
                value: app?.numCurrentUsers ?? "-",
              },
              {
                key: "First Detected",
                value: appFirstDetected ? (
                  <DateTimeFormat dateTime={appFirstDetected} dateOnly />
                ) : (
                  "-"
                ),
              },
              { key: "Category", value: app?.category ?? "-" },
              {
                key: "App Status",
                value: app ? <AppStatusDisplay app={app} /> : "-",
              },
              {
                key: "Approved Teams",
                value:
                  app?.approvedForTeams && app?.approvedForTeams.length > 0
                    ? app?.approvedForTeams.map((t) => t.name).join(", ")
                    : "-",
              },
              {
                key: "Approved Roles",
                value:
                  app?.approvedForRoles && app?.approvedForRoles.length > 0
                    ? app?.approvedForRoles.map((r) => r.name).join(", ")
                    : "-",
              },
            ]}
          />
        </SlidePanelSection>

        <SlidePanelSection title="Summary">
          <div className="long-text">{app?.summary}</div>
        </SlidePanelSection>

        <SlidePanelSection title="Description">
          <div className="long-text">{app?.description}</div>
        </SlidePanelSection>
      </ScrollableDiv>
    </div>
  );
};

export default SaaSAppRiskPanel;
