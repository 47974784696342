import { Steps } from "../../../_common/components/StepsWithSections";
import ReportCard from "../../../_common/components/ReportCard";
import BreachSightWaiverDetailsStep, {
  ApproverType,
  ExpireType,
} from "./BreachSightWaiverDetailsStep";
import BreachSightWaiverConfirmStep from "./BreachSightWaiverConfirmStep";
import ActionBar from "../../../_common/components/ActionBar";
import { FC, ReactNode, useState } from "react";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import { TextFieldData } from "../../../_common/components/TextField";
import moment from "moment";
import { IRisk } from "../RiskSummaryDetails";
import { Filters } from "../filter/types";
import { calculateWaivedAssetsForRisks } from "../../reducers/risks.actions";
import { OrganisationAcceptedRisk } from "../../reducers/customerAcceptedRisks.actions";
import Button from "../../../_common/components/core/Button";
import SelectRiskTable, { AssetSelection } from "./SelectRiskTable";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import "./BreachSightCreateRiskWaiverFlow.scss";

interface BreachSightCreateRiskWaiverFlowProps {
  orgSupportsDomainPortfolios?: boolean;
  editableDomainPortfolioIds?: number[];
  filters?: Filters;
  requireApprovalsRiskWaivers: boolean;
  canUsePublicWaivers: boolean;

  risks?: IRisk[];
  riskWaivers?: OrganisationAcceptedRisk[];

  waiverDetails: BreachSightCreateRiskWaiverFlowCreationDetails;
  onWaiverDetailsChanged: (
    newDetails: BreachSightCreateRiskWaiverFlowCreationDetails
  ) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  initiallyExpandedRiskId?: string;

  selectRiskUIOverride?: ReactNode;
}

export interface BreachSightCreateRiskWaiverFlowCreationDetails {
  selectedRisk?: VendorSummaryRisk;
  assetSelection: AssetSelection;
  approverType: ApproverType;
  approverEmail: TextFieldData;
  justification: TextFieldData;
  expireType: ExpireType;
  expireDate: string;
  isPublic: boolean;
}

export const getNewBlankWaiver = (
  selectedRisk?: VendorSummaryRisk,
  allWebsites?: boolean,
  userHasPortfolioSpecificAccess?: boolean,
  selectedWebsites?: string[]
): BreachSightCreateRiskWaiverFlowCreationDetails => {
  return {
    selectedRisk,
    assetSelection: {
      hostnameSelection: {
        allSelected: (allWebsites && !userHasPortfolioSpecificAccess) || false,
        includeFuture: false,
        selected: selectedWebsites || [],
      },
      allQuestionnairesSelected: false,
      selectedQuestionnaires: [],
      selectedPublicQuestionnaires: [],
      manifestSelection: undefined,
      repositorySelection: undefined,
      saasSelection: undefined,
    },
    approverType: ApproverType.Self,
    approverEmail: { value: "", isValid: false },
    justification: { value: "", isValid: false },
    expireType: ExpireType.Never,
    expireDate: moment().add(1, "year").format("YYYY-MM-DD"), // Set to a year in the future by default
    isPublic: false,
  };
};

const BreachSightCreateRiskWaiverFlow: FC<
  BreachSightCreateRiskWaiverFlowProps
> = ({
  orgSupportsDomainPortfolios,
  editableDomainPortfolioIds,
  requireApprovalsRiskWaivers,
  canUsePublicWaivers,
  filters,
  risks,
  riskWaivers,
  waiverDetails,
  onWaiverDetailsChanged,
  onCancel,
  isSubmitting,
  onSubmit,
  initiallyExpandedRiskId,
  selectRiskUIOverride,
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const {
    selectedRisk,
    assetSelection,
    approverType,
    approverEmail,
    justification,
    expireType,
    expireDate,
    isPublic,
  } = waiverDetails;

  const waiverDetailsUpdated = (
    newDetails: Partial<BreachSightCreateRiskWaiverFlowCreationDetails>
  ) => {
    const details = {
      ...waiverDetails,
      ...newDetails,
    };

    onWaiverDetailsChanged(details);
  };

  const waivedRiskAssets = calculateWaivedAssetsForRisks(
    risks ?? [],
    true,
    undefined,
    undefined,
    riskWaivers
  );

  let disablePublicText: string | undefined = undefined;
  if (
    assetSelection.hostnameSelection.includeFuture ||
    (assetSelection.hostnameSelection.customDomains &&
      assetSelection.hostnameSelection.customDomains.length > 0)
  ) {
    disablePublicText =
      "A public risk waiver cannot include custom domains, or domains & IPs we detect in the future.";
  }

  const isStepTwoDisabled = () => {
    if (!selectedRisk) {
      return true;
    }

    switch (selectedRisk.riskType) {
      case VendorSummaryRiskType.Cloudscan:
        return (
          !assetSelection.hostnameSelection.includeFuture &&
          assetSelection.hostnameSelection.selected.length === 0
        );
      case VendorSummaryRiskType.AppguardPackageVuln:
        return (
          (assetSelection.manifestSelection?.selectedUuids ?? []).length === 0
        );
      case VendorSummaryRiskType.AppguardRepoConfig:
        return (
          (assetSelection.repositorySelection?.selectedUuids ?? []).length === 0
        );
      case VendorSummaryRiskType.SaaS:
        return (assetSelection.saasSelection?.selectedUuids ?? []).length === 0;
      default:
        return false;
    }
  };

  const isStepThreeDisabled = () => {
    if (isStepTwoDisabled()) {
      return true;
    }

    return (
      !approverType ||
      !justification.isValid ||
      (approverType === ApproverType.Other &&
        (!approverEmail.value || !approverEmail.isValid)) ||
      (expireType === ExpireType.Other &&
        (!expireDate || moment().add(1, "day").isAfter(expireDate, "day")))
    );
  };

  let selectAssetsText = "Select assets";
  if (selectedRisk && selectedRisk.riskType === VendorSummaryRiskType.SaaS) {
    selectAssetsText = "Select users";
  }

  return (
    <div className={"breachsight-create-risk-waiver-flow"}>
      <Steps
        steps={[
          {
            id: "selectrisk",
            text: selectAssetsText,
            onClick: () => setCurrentStep(1),
          },
          {
            id: "approver",
            text: "Configure",
            onClick: () => setCurrentStep(2),
            disabled: isStepTwoDisabled(),
          },
          {
            id: "review",
            text: "Review",
            onClick: () => setCurrentStep(3),
            disabled: isStepThreeDisabled(),
          },
        ]}
        currentStep={currentStep}
      />
      {currentStep === 1 &&
        (!!selectRiskUIOverride ? (
          selectRiskUIOverride
        ) : (
          <ReportCard newStyles className={"select-risk-card"}>
            <div className={"header"}>Select risk to waive</div>
            <SelectRiskTable
              isCustomer={true}
              orgSupportsDomainPortfolios={orgSupportsDomainPortfolios}
              editableDomainPortfolioIds={editableDomainPortfolioIds}
              filters={filters}
              risks={(risks ?? []).filter((r) => !r.passed && !r.isWaived)}
              isLoading={!risks}
              selectedRisk={selectedRisk}
              selection={assetSelection}
              waivedRiskAssets={waivedRiskAssets}
              waiverType={WaiverType.RiskWaiver}
              onSelectRisk={(risk, assetSelection) =>
                waiverDetailsUpdated({
                  selectedRisk: risk,
                  assetSelection: assetSelection,
                })
              }
              initiallyExpandedRiskId={initiallyExpandedRiskId}
            />
          </ReportCard>
        ))}
      {currentStep === 2 && (
        <ReportCard newStyles className={"breachsight-waiver-details-step"}>
          <div className={"header"}>Approvals and Expiry</div>
          <div className={"report-card-content"}>
            <BreachSightWaiverDetailsStep
              requireApprovalsRiskWaivers={requireApprovalsRiskWaivers}
              approverType={approverType}
              approverEmail={approverEmail}
              onSetApprover={(a, v) =>
                waiverDetailsUpdated({
                  approverType: a,
                  approverEmail: v,
                })
              }
              canUsePublicWaivers={canUsePublicWaivers}
              isPublic={isPublic}
              disablePublicText={disablePublicText}
              onSetPublic={(v) => waiverDetailsUpdated({ isPublic: v })}
              justification={justification}
              onSetJustification={(v) =>
                waiverDetailsUpdated({
                  justification: v,
                })
              }
              expireType={expireType}
              expireDate={expireDate}
              onSetExpire={(t, d) =>
                waiverDetailsUpdated({
                  expireType: t,
                  expireDate: d ?? moment().add(1, "year").format("YYYY-MM-DD"),
                })
              }
            />
          </div>
        </ReportCard>
      )}
      {currentStep === 3 && (
        <ReportCard newStyles>
          <div className={"header"}>Review and complete</div>
          <div className={"report-card-content"}>
            <BreachSightWaiverConfirmStep
              selectedRisk={selectedRisk}
              assetSelection={assetSelection}
              approverType={approverType}
              approverEmail={approverEmail.value}
              justification={justification.value}
              canUsePublicWaivers={canUsePublicWaivers}
              isPublic={isPublic}
              expireType={expireType}
              expireDate={expireDate}
            />
          </div>
        </ReportCard>
      )}
      <ActionBar active>
        <div className="actions">
          {currentStep === 1 && (
            <>
              <div>
                {selectedRisk
                  ? `Selected: ${selectedRisk.title}`
                  : "Please select a risk"}
              </div>
              <div className={"buttons"}>
                <Button tertiary onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  arrow
                  primary
                  disabled={isStepTwoDisabled()}
                  onClick={() => setCurrentStep(2)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div>
                <Button leftArrow onClick={() => setCurrentStep(1)}>
                  Previous
                </Button>
              </div>
              <div className={"buttons"}>
                <Button tertiary onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  arrow
                  primary
                  disabled={isStepThreeDisabled()}
                  onClick={() => setCurrentStep(3)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <div>
                <Button leftArrow onClick={() => setCurrentStep(2)}>
                  Previous
                </Button>
              </div>
              <div className={"buttons"}>
                <Button tertiary onClick={onCancel}>
                  Cancel
                </Button>
                <Button primary loading={isSubmitting} onClick={onSubmit}>
                  Create waiver
                </Button>
              </div>
            </>
          )}
        </div>
      </ActionBar>
    </div>
  );
};

export default BreachSightCreateRiskWaiverFlow;
