import { FC, useEffect, useState } from "react";

import Button from "../../_common/components/core/Button";

import "./RemediateThreatModal.scss";
import TextField from "../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringRemediationOutcome,
  ThreatMonitoringRemediationOutcomeSelectableOptions,
  ThreatMonitoringRemediationStatus,
} from "../api/types";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import {
  threatRemediationOutcomeTypeToString,
  threatRemediationStatusTypeToString,
} from "../funcs/domain";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import ThreatMonitoringAPI, {
  ThreatMonitoringTagTypes,
} from "../api/threatmonitoring.api";
import { RiskSummary } from "../../vendorrisk/views/remediation_request/ReviewAndSendStep";
import LoadingBanner from "../../_common/components/core/LoadingBanner";

interface RemediateThreatModalProps extends BaseModalProps {
  threat?: DisplayableThreatMonitoringResult;
  remediationRequestID?: number;
  onSubmit?: (message?: string) => void;
}
const SimpleRemediationModal: FC<
  RemediateThreatModalProps & {
    outcome: string;
    setOutcome: (value: string) => void;
    additionalInformation: string;
    setAdditionalInformation: (value: string) => void;
    loading: boolean;
    submit: () => void;
    remediationRequestID: number;
  }
> = ({
  active,
  onClose,
  outcome,
  setOutcome,
  additionalInformation,
  setAdditionalInformation,
  loading,
  submit,
  remediationRequestID,
}) => {
  const { data, isFetching, isError, error } =
    ThreatMonitoringAPI.useGetResultByRemediationIDV1Query({
      remediationRequestID: remediationRequestID,
    });

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    submit();
    data &&
      dispatch(
        ThreatMonitoringAPI.util.invalidateTags([
          ThreatMonitoringTagTypes.results,
          ...data.results.map((r) => ({
            type: ThreatMonitoringTagTypes.result,
            id: r.uuid,
          })),
        ])
      );
  };

  useEffect(() => {
    if (isError) {
      console.error(error);
      dispatch(addDefaultUnknownErrorAlert("Error getting threat finding"));
      onClose();
    }
  }, [isError, error]);
  return (
    <ModalV2
      active={active}
      onClose={onClose}
      disallowClose={true}
      headerContent={"Are you sure you want to mark this as completed?"}
      headerClassName={"remediate-threat-modal-header"}
      className={"remediate-threat-modal"}
      footerClassName={"remediate-threat-modal-footer"}
      footerContent={
        <>
          <Button tertiary onClick={onClose} disabled={loading || isFetching}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading || isFetching}
            onClick={handleSubmit}
            disabled={
              outcome === "" ||
              (outcome === ThreatMonitoringRemediationStatus.Waived &&
                additionalInformation === "")
            }
          >
            <i className="cr-icon-check" /> Mark as completed
          </Button>
        </>
      }
    >
      <div className="report-card-sections">
        <div className="report-card-section">
          <div className="section-label">
            <div className="required">Outcome</div>
            <div className="subtitle">
              What was the outcome of this request?
            </div>
          </div>
          <div className="section-content">
            <ColorCheckbox
              key={ThreatMonitoringRemediationStatus.Remediated}
              label={threatRemediationStatusTypeToString(
                ThreatMonitoringRemediationStatus.Remediated
              )}
              checked={outcome === ThreatMonitoringRemediationStatus.Remediated}
              onClick={() =>
                setOutcome(ThreatMonitoringRemediationStatus.Remediated)
              }
              radio
              disabled={loading}
            />
            <div className="subtitle">
              Risk has been successfully remediated
            </div>
            <ColorCheckbox
              key={ThreatMonitoringRemediationStatus.Waived}
              label={threatRemediationStatusTypeToString(
                ThreatMonitoringRemediationStatus.Waived
              )}
              checked={outcome === ThreatMonitoringRemediationStatus.Waived}
              onClick={() =>
                setOutcome(ThreatMonitoringRemediationStatus.Waived)
              }
              radio
              disabled={loading}
            />
            <div className="subtitle">
              Risk consequence accepted, or this was not a legitimate risk.
            </div>
          </div>
        </div>
        {outcome === ThreatMonitoringRemediationStatus.Waived && (
          <div className="report-card-section">
            <div className="section-label">
              <div className="required">Reason</div>
              <div className="subtitle">
                Why is this threat event risk being waived?
              </div>
            </div>
            <div className="section-content">
              <TextField
                className="input"
                value={additionalInformation}
                multiLine
                onChanged={(s) => setAdditionalInformation(s)}
                placeholder="Enter a reason for waiving this threat risk"
              />
            </div>
          </div>
        )}
      </div>
    </ModalV2>
  );
};

const DetailedRemediationModal: FC<
  RemediateThreatModalProps & {
    outcome: string;
    setOutcome: (value: string) => void;
    additionalInformation: string;
    setAdditionalInformation: (value: string) => void;
    loading: boolean;
    submit: () => void;
    threatUUID: string;
  }
> = ({
  active,
  onClose,
  outcome,
  setOutcome,
  additionalInformation,
  setAdditionalInformation,
  loading,
  submit,
  threatUUID,
}) => {
  const { data, isFetching, isError, error } =
    ThreatMonitoringAPI.useGetThreatRiskV1Query({ uuid: threatUUID });
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError) {
      console.error(error);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error getting potential risk for threat finding"
        )
      );
      onClose();
    }
  }, [isError, error]);
  return (
    <ModalV2
      active={active}
      onClose={onClose}
      disallowClose={true}
      headerContent={"Close this threat as remediated?"}
      headerClassName={"remediate-threat-modal-header"}
      className={"remediate-threat-modal"}
      footerClassName={"remediate-threat-modal-footer"}
      footerContent={
        <>
          <Button tertiary onClick={onClose} disabled={loading || isFetching}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading || isFetching}
            onClick={submit}
            disabled={outcome === ""}
          >
            Close threat
          </Button>
        </>
      }
    >
      <div className="report-card-sections">
        <div className="report-card-section">
          <div className="risk-section">
            {isFetching ? (
              <LoadingBanner tight />
            ) : (
              data && (
                <RiskSummary
                  severity={data.riskSeverity}
                  title={data.failTitle}
                  description={data.description}
                />
              )
            )}
          </div>
        </div>
        <div className="report-card-section">
          <div className="section-label">
            <div className="required">Outcome</div>
            <div className="subtitle">How was the threat remediated?</div>
          </div>
          <div className="section-content">
            {Object.values(
              ThreatMonitoringRemediationOutcomeSelectableOptions
            ).map((o) => (
              <ColorCheckbox
                key={o}
                label={threatRemediationOutcomeTypeToString(o)}
                checked={outcome === o}
                onClick={() => setOutcome(o)}
                radio
                disabled={loading || isFetching}
              />
            ))}
          </div>
        </div>
        <div className="report-card-section">
          <div className="section-label">
            Additional information (optional)
            <div className="subtitle">
              Add any additional context or links here
            </div>
          </div>
          <div className="section-content">
            <TextField
              className="input"
              value={additionalInformation}
              multiLine
              onChanged={(s) => setAdditionalInformation(s)}
              placeholder="Add any additional context or links here"
            />
          </div>
        </div>
      </div>
    </ModalV2>
  );
};

const RemediateThreatModal: FC<RemediateThreatModalProps> = (props) => {
  const { remediationRequestID, threat, onSubmit, onClose } = props;
  const [outcome, setOutcome] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [loading, setLoading] = useState(false);

  const [closeResultAsRemediated] =
    ThreatMonitoringAPI.useCloseResultAsRemediatedV1Mutation();

  const dispatch = useAppDispatch();
  const submit = () => {
    setLoading(true);
    const handleClose = () => {
      setLoading(false);
      onClose();
    };

    if (onSubmit) {
      onSubmit(additionalInformation);
      handleClose();
    } else if (threat) {
      closeResultAsRemediated({
        uuid: threat.uuid,
        outcome: outcome as ThreatMonitoringRemediationOutcome,
        additionalInformation,
      })
        .then(() => {
          dispatch(
            addDefaultSuccessAlert(
              "Threat moved to closed, and marked as remediated"
            )
          );
          handleClose();
        })
        .catch((e) => console.error(e))
        .finally(() => setLoading(false));
    } else {
      handleClose();
    }
  };

  return remediationRequestID ? (
    <SimpleRemediationModal
      {...props}
      outcome={outcome}
      setOutcome={setOutcome}
      additionalInformation={additionalInformation}
      setAdditionalInformation={setAdditionalInformation}
      loading={loading}
      submit={submit}
      remediationRequestID={remediationRequestID}
    />
  ) : (
    threat && (
      <DetailedRemediationModal
        {...props}
        outcome={outcome}
        setOutcome={setOutcome}
        additionalInformation={additionalInformation}
        setAdditionalInformation={setAdditionalInformation}
        loading={loading}
        submit={submit}
        threatUUID={threat.uuid}
      />
    )
  );
};

export default RemediateThreatModal;
