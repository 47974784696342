import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ScrollableDiv from "../ScrollableDiv";
import "../../style/components/securityprofile/ControlPanel.scss";
import BackArrow from "../../../_common/components/BackArrow";
import VendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import { CheckItemSlidePanelSection } from "../filter/SlidePanelSection";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import StatusIcon from "./StatusIcon";
import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import CheckResultLine from "./CheckResultLine";
import { ControlState, ControlStateText } from "../../types/securityProfile";
import Button from "../../../_common/components/core/Button";
import { useCallback, useState } from "react";
import { produce } from "immer";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import classNames from "classnames";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";

interface IControlPanelProps {
  onClickBack?: () => void;
  vendorId: number;
  controlId: string;
}

const ControlPanel = (props: IControlPanelProps) => {
  const dispatch = useAppDispatch();

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: props.vendorId,
    });
  const controlStates = securityProfile?.controlStates;

  const controlState = controlStates?.[props.controlId];

  const { data: controlData, isLoading: controlLoading } =
    VendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: props.vendorId,
      controlId: props.controlId,
    });
  const control = controlData?.control;

  const { data: excludedControls } =
    VendorSecurityProfileAPI.useGetExcludedControlsQuery({
      vendorId: props.vendorId,
    });

  const [setExcludedControls] =
    VendorSecurityProfileAPI.useSetExcludedControlsMutation();

  const isExcluded =
    excludedControls?.excludedControls.includes(props.controlId) ?? false;

  const [settingNA, setSettingNA] = useState(false);

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const setControlExclusion = useCallback(() => {
    if (!excludedControls) {
      return;
    }

    setSettingNA(true);

    const newExclusion = produce(excludedControls.excludedControls, (draft) => {
      if (isExcluded) {
        const idx = draft.findIndex((c) => c == props.controlId);
        draft.splice(idx, 1);
      } else {
        draft.push(props.controlId);
      }
    });

    const buttonAction = () => {
      return setExcludedControls({
        vendorId: props.vendorId,
        excludedControls: newExclusion,
      })
        .unwrap()
        .then(() => {
          dispatch(
            addDefaultSuccessAlert(
              isExcluded
                ? "Control has been marked as applicable"
                : "Control has been marked as N/A"
            )
          );
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert(
              isExcluded
                ? "Unable to mark control as applicable"
                : "Unable to mark control as N/A"
            )
          );
        })
        .finally(() => {
          setSettingNA(false);
        });
    };

    if (isExcluded) {
      buttonAction();
    } else {
      openConfirmationModal({
        buttonAction,
        buttonText: "Yes, mark as n/a",
        primaryAction: true,
        title: "Are you sure you want to mark as n/a?",
        description: (
          <>
            <p>
              This control or check will no longer appear on the security
              profile for this vendor.
            </p>

            <p>This change will apply to all users within your organization.</p>
          </>
        ),
      });
    }
  }, [excludedControls, isExcluded, props.controlId, props.vendorId]);

  if (controlLoading || !control) {
    return (
      <div className="loading-overlay show">
        <LoadingBanner />
      </div>
    );
  }

  const excludedClass = (...className: string[]) =>
    classNames(className, { excluded: isExcluded });

  const controlStateToUse = isExcluded
    ? ControlState.NA
    : controlState ?? ControlState.Unknown;

  return (
    <div className="control-panel">
      <div className={"control-panel-header"}>
        {!!props.onClickBack && (
          <BackArrow popup={"Back"} onClick={props.onClickBack} />
        )}
        <h2 className={excludedClass()}>{control.text}</h2>
        <Button onClick={setControlExclusion} loading={settingNA}>
          {isExcluded ? "Mark as applicable" : "Mark as N/A"}
        </Button>
      </div>
      <ScrollableDiv newStyles>
        <CheckItemSlidePanelSection
          title="Control details"
          startExpanded={true}
        >
          <div className={excludedClass("detail-table")}>
            <InfoTable>
              <InfoTableRow
                label="STATUS"
                rowClass="detail-row"
                valueClass="status-value"
                value={
                  <>
                    <StatusIcon controlState={controlStateToUse} />
                    <div>{ControlStateText[controlStateToUse]}</div>
                  </>
                }
              />
              <InfoTableRow
                rowClass="detail-row"
                label="OBJECTIVE"
                value={control.objective}
              />
            </InfoTable>
          </div>
        </CheckItemSlidePanelSection>
        {!isExcluded && (
          <CheckItemSlidePanelSection
            title="Risks / Checks"
            startExpanded={true}
          >
            <div className="check-table">
              {control.checks.map((c) => (
                <CheckResultLine
                  key={c.id}
                  vendorId={props.vendorId}
                  controlId={props.controlId}
                  checkId={c.id}
                />
              ))}
            </div>
          </CheckItemSlidePanelSection>
        )}
      </ScrollableDiv>
      {confirmationModal}
    </div>
  );
};

export default ControlPanel;
