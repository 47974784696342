import { escapeRegExp } from "../helpers/string.helpers";
import { useAppSelector } from "./reduxHooks";

// Helper function to replace variables in a set of strings. The strings will be returned in the same order as they
// were passed in.
export const replaceVariablesInDefaultTexts = (
  texts: string[],
  replacements: { [mergeTag: string]: string }
): string[] => {
  return texts.map((text: any) => {
    Object.entries(replacements).forEach(([mergeTag, replacement]) => {
      text = text.replaceAll(mergeTag, replacement);
    });
    return text;
  });
};

export enum DefaultTextType {
  RiskAssessmentCommentary = "risk-assessment-commentary",
  QuestionnaireTitle = "questionnaire-title",
  QuestionnaireMessage = "questionnaire-message",
  QuestionnaireCollaboratorTitle = "questionnaire-collaborator-title",
  QuestionnaireCollaboratorMessage = "questionnaire-collaborator-message",
  RemediationRequestTitle = "remediation-request-title",
  RemediationRequestMessage = "remediation-request-message",
  IdentityBreachSubject = "identity-breach-subject",
  IdentityBreachMessage = "identity-breach-message",
  VendorRelationshipQuestionnaireMessage = "vendor-relationship-questionnaire-message",
  EvidenceRequestTitle = "evidence-request-title",
  EvidenceRequestBody = "evidence-request-body",
  ManagedServiceTitle = "managed-service-title",
  ManagedServiceBody = "managed-service-body",

  ReportGeneratedTitle = "report-generated-title",
  ReportGeneratedBody = "report-generated-body",

  // new risk assessment text types
  RiskAssessmentIntroduction = "risk-assessment-introduction",
  RiskAssessmentBackground = "risk-assessment-background",
  RiskAssessmentFindings = "risk-assessment-findings",
  RiskAssessmentFindingsNoWebRisk = "risk-assessment-findings-no-web-risk",
  RiskAssessmentConclusion = "risk-assessment-conclusion",
}

export interface DefaultText {
  textType: DefaultTextType;
  defaultText: string;
  hideSection: boolean;
}

export type OrgDefaultTexts = { [key in DefaultTextType]?: DefaultText };
export type SystemDefaultTexts = { [key in DefaultTextType]: string };

export interface MergeTag {
  tag: string;
  description: string;
  value: string;
}

export const replaceMergeTags = (s: string, tags: MergeTag[]): string =>
  tags.reduce(
    (prev, tag) =>
      prev.replace(
        new RegExp(escapeRegExp(tag.tag), "g"),
        tag.value ?? "*{merge tag value not defined}*"
      ),
    s
  );

export const useDefaultText = (type: DefaultTextType) =>
  useAppSelector((state) => state.cyberRisk.orgDefaultTexts?.[type]);
