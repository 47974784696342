import {
  useCurrentOrg,
  useCurrentUser,
  useCurrentUserTasks,
} from "../../selectors/commonSelectors";
import NavLink, { NewFeatureInAppMessagePopupContent } from "../NavLink";
import { InAppMessageType } from "../InAppMessage";
import { pageDescriptions } from "../../pageDescriptions";
import * as Permissions from "../../permissions";
import {
  getUserTrustExchangeSections,
  tasksRouterUrlPrefix,
  trustExchangeTaskUrlPrefix,
} from "../../helpers/navigation.helpers";
import { ReactNode } from "react";
import { matchPath } from "react-router-dom";

type TrustExchangeNavItems = {
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

const TrustExchangeNavItems = (props: TrustExchangeNavItems) => {
  const currentOrg = useCurrentOrg();
  const userData = useCurrentUser();
  const userTaskData = useCurrentUserTasks();

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);

  const trustExchangeNavItems = getUserTrustExchangeSections(
    userData,
    currentOrg,
    userTaskData
  );

  const showMySharedProfile = trustExchangeNavItems.includes("trust_page");
  const showContentLibrary = trustExchangeNavItems.includes("content_library");

  const taskRoutes: ReactNode[] = [];

  const routePrefix = `${tasksRouterUrlPrefix}/${trustExchangeTaskUrlPrefix}`;

  if (trustExchangeNavItems.includes("questionnaires")) {
    taskRoutes.push(
      <NavLink
        key="questionnaires"
        className="unstyled"
        text="Answer Questionnaires"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/questionnaires`,
          "Answer Questionnaires"
        )}
        to={`/${routePrefix}/questionnaires`}
        inAppMessageType={
          userData.currentOrgID !== 0 &&
          hasUserPermission(Permissions.UserReadSurveyImportExport)
            ? InAppMessageType.ImportSurveysNewFeature
            : undefined
        }
        inAppMessageText={
          userData.currentOrgID !== 0 &&
          hasUserPermission(Permissions.UserReadSurveyImportExport) ? (
            <NewFeatureInAppMessagePopupContent
              featureName="Import Excel questionnaires"
              description="Been sent an Excel questionnaire? Use our AI Autofill tools to complete and export it in no time."
              learnMoreLink="https://help.upguard.com/en/articles/9034980-how-to-import-and-complete-security-questionnaires"
            />
          ) : undefined
        }
        isActive={(_, location) => {
          return (
            !!matchPath(location.pathname, {
              path: `/${routePrefix}/questionnaires`,
            }) ||
            !!matchPath(location.pathname, {
              path: "/vendors/surveys",
            })
          );
        }}
      />
    );
  }

  if (trustExchangeNavItems.includes("additional_evidence_requests")) {
    taskRoutes.push(
      <NavLink
        key="additional_evidence"
        className="unstyled"
        text="Document Requests"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/additionalevidence`,
          "Document Requests"
        )}
        to={`/${routePrefix}/additionalevidence`}
      />
    );
  }

  if (trustExchangeNavItems.includes("remediation_requests")) {
    taskRoutes.push(
      <NavLink
        key="remediation_requests"
        className="unstyled"
        text="Remediation Requests"
        onClick={props.clickNavLinkFunc(
          `/${routePrefix}/remediation`,
          "Remediation Requests"
        )}
        to={`/${routePrefix}/remediation`}
      />
    );
  }

  return (
    <>
      <div className="product-name">Trust Exchange</div>
      <div className="product-navigation-items">
        {trustExchangeNavItems.length > 0 ? (
          <>
            {showContentLibrary && (
              <NavLink
                className="unstyled"
                text="Content Library"
                onClick={props.clickNavLinkFunc(
                  "/contentlibrary",
                  "Content Library"
                )}
                to="/contentlibrary"
                inAppMessageText={
                  <NewFeatureInAppMessagePopupContent
                    featureName="Content Library"
                    description="Easily manage documents in one central location and build a repository of content to power AI autofill."
                    learnMoreLink={
                      "https://help.upguard.com/en/articles/8973526-what-is-the-content-library"
                    }
                  />
                }
                inAppMessageType={InAppMessageType.ContentLibraryNewFeature}
              />
            )}
            {showMySharedProfile && (
              <NavLink
                className="unstyled"
                inAppMessageText={
                  <NewFeatureInAppMessagePopupContent
                    featureName="Security and Privacy Pages"
                    description="You can now update security and privacy pages on your Trust Page."
                    learnMoreLink={
                      "https://help.upguard.com/en/articles/7051467-how-to-use-security-and-privacy-pages-in-vendor-risk"
                    }
                  />
                }
                inAppMessageType={InAppMessageType.VendorEvidenceNewFeature}
                text="Trust Page"
                hoverText={pageDescriptions.MySharedAssessment}
                onClick={props.clickNavLinkFunc("/trustpage", "Trust Page")}
                to="/trustpage"
              />
            )}
            {taskRoutes?.length > 0 && (
              <>
                {(showContentLibrary || showMySharedProfile) && (
                  <div className="section-divider">
                    <hr />
                  </div>
                )}
                <div className="section-header">
                  <span className="section-header-text">Tasks</span>
                </div>
              </>
            )}
            {taskRoutes}
          </>
        ) : null}
      </div>
    </>
  );
};

export default TrustExchangeNavItems;
