import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import {
  ExportFiletype,
  ExportSection,
  ExportType,
} from "../../../_common/types/exportReport";
import {
  cloneDeep as _cloneDeep,
  get as _get,
  sortBy as _sortBy,
} from "lodash";
import {
  AvailableExportConfig,
  CannedExportConfig,
  CustomCannedExportConfig,
  DefaultCannedExportConfig,
  exportConfigRequiresVendorWithCompletedAssessment,
  exportConfigRequiresVendorWithCompletedManagedV3Assessment,
  ErrNoRadioOptionsSelected,
  RequiredExtraStep,
  exportConfigRequiresVendorWithCompletedSecurityProfileAssessment,
} from "../../../_common/types/exportConfig";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getExportSections } from "../../reducers/cyberRiskSelectors";
import ReportDeliveryStep, {
  reportDeliveryStateValidationErr,
  useReportDeliveryState,
} from "./ReportDeliveryStep";
import { useConfiguredExportConfigReducer } from "./exportConfigReducer";
import { fetchExportSections } from "../../reducers/export.actions";
import Button from "../../../_common/components/core/Button";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import SelectPortfoliosStep from "./SelectPortfoliosStep";
import { PortfolioType } from "../../reducers/portfolios.actions";
import "../../style/components/reporting/ReportsLibraryModal.scss";
import classNames from "classnames";
import { History } from "history";
import {
  IReportGenerateLocationState,
  SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS,
  useSubmitGenerateReport,
} from "../../views/reporting/ReportGenerate";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  OrgAccessCustomReportingTemplates,
  OrgAccessDomainPortfolios,
  OrgAccessVendorPortfolios,
} from "../../../_common/permissions";
import {
  AssuranceType,
  organisationAccountType,
} from "../../../_common/types/organisations";
import { getVendorWords } from "../../../_common/constants";
import ReportVendorSelector from "../../../_common/components/ReportVendorSelector";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import ReportTypeBadge, {
  BadgeImageID,
  BadgeImageRefs,
  BadgeModule,
} from "./ReportTypeBadge";
import { locationState } from "../../../_common/types/router";
import { ExportConfigurationOptions } from "./ExportConfiguration";
import { fetchCannedExportConfigs } from "../../reducers/cannedExports.actions";
import {
  fetchCustomerLimitData,
  refreshVendorSearchResults,
  setVendorSearch,
  updateVendorWatchStatusWithRefresh,
} from "../../reducers/cyberRiskActions";
import { IVendorSearchVendorAndWatched } from "../../../_common/types/vendor";
import { ReportVendorMonitor } from "../../../_common/components/ReportVendorMonitor";
import { appConnect } from "../../../_common/types/reduxHooks";
import VendorAssessmentAPI from "../../reducers/vendorAssessmentAPI";
import { skipToken } from "@reduxjs/toolkit/query";
interface IReportsLibraryModalPropsProviderProps extends BaseModalProps {
  history: History;
  filterAvailableReportTypes?: ExportType[];
  initialSelectedCannedExportConfig?: CannedExportConfig;
  initialVendorId?: number;
  initialVendorName?: string;
  managedOrgId?: number;
}

interface IReportsLibraryModalOwnProps
  extends IReportsLibraryModalPropsProviderProps {
  selectedCannedExportConfig?: CannedExportConfig;
  setSelectedCannedExportConfig: (c: CannedExportConfig) => void;
  vendorId?: number;
  vendorName?: string;
  setVendor: (vendorId: number, vendorName: string) => void;
}

interface IReportsLibraryModalConnectedProps {
  defaultCannedExportConfigs?: DefaultCannedExportConfig[];
  customCannedExportConfigs?: CustomCannedExportConfig[];
  unfilteredAvailableConfig?: {
    [t in ExportFiletype]: AvailableExportConfig;
  };
  unfilteredAvailableConfigWithoutVendorId?: {
    [t in ExportFiletype]: AvailableExportConfig;
  };
  orgSupportsVendorPortfolios: boolean;
  orgSupportsDomainPortfolios: boolean;
  orgSupportCustomReportingTemplates: boolean;
  assuranceType: AssuranceType;
  orgIsFreeTrial: boolean;
  vendorWatchLimit: number;
  vendorWatchCount: number;
  existingFilterText?: string;
  initialVendorIsWatched?: boolean;
}

type IReportsLibraryModalProps = IReportsLibraryModalOwnProps &
  IReportsLibraryModalConnectedProps &
  DefaultThunkDispatchProp;

type reportsLibraryModalStepID =
  | "select_report_type"
  | "set_vendor"
  | "watch_vendor"
  | "set_global_options"
  | "select_vendor_portfolios"
  | "select_domain_portfolios"
  | "report_schedule";

const ReportsLibraryModal: FC<IReportsLibraryModalProps> = ({
  dispatch,
  history,
  active,
  onClose,
  defaultCannedExportConfigs,
  customCannedExportConfigs,
  filterAvailableReportTypes,
  initialSelectedCannedExportConfig,
  selectedCannedExportConfig,
  setSelectedCannedExportConfig,
  vendorId,
  vendorName,
  initialVendorId,
  initialVendorIsWatched,
  setVendor,
  unfilteredAvailableConfig,
  unfilteredAvailableConfigWithoutVendorId,
  orgSupportsVendorPortfolios,
  orgSupportsDomainPortfolios,
  orgSupportCustomReportingTemplates,
  assuranceType,
  orgIsFreeTrial,
  vendorWatchCount,
  vendorWatchLimit,
  existingFilterText,
  managedOrgId,
}) => {
  const vendorWords = getVendorWords(assuranceType);
  const [currentStepID, setCurrentStepID] =
    useState<reportsLibraryModalStepID>("select_report_type");

  // provide a stack to keep track of which steps we've covered in case we want to go back
  const [previousStepIdx, setPreviousStepIdx] = useState([] as number[]);
  const pushPreviousStepIdx = (idx: number) => {
    const newIdx = [...previousStepIdx];
    newIdx.push(idx);
    setPreviousStepIdx(newIdx);
  };
  const popPreviousStepIdx = (): number | undefined => {
    const newIdx = [...previousStepIdx];
    const idx = newIdx.pop();
    setPreviousStepIdx(newIdx);
    return idx;
  };

  // provides a way to request the system move directly on to the next step when the set of steps is recalculated
  // this allows business processes to pause while a dependency that affects the reporting steps is loaded/calculated.
  const [immediateMoveOnRecalc, setImmediateMoveOnRecalc] = useState(0);

  const [reportDeliveryState, setReportDeliveryState] =
    useReportDeliveryState();
  const [reportGenerateLoading, setReportGenerateLoading] = useState(false);

  const [selectedVendorPortfolioIDs, setSelectedVendorPortfolioIDs] = useState<
    number[]
  >([]);
  const onSetSelectedVendorPortfolioIDs = useCallback(
    (portfolioIDs: number[]) => setSelectedVendorPortfolioIDs(portfolioIDs),
    []
  );

  const [selectedDomainPortfolioIDs, setSelectedDomainPortfolioIDs] = useState<
    number[]
  >([]);
  const onSetSelectedDomainPortfolioIDs = useCallback(
    (portfolioIDs: number[]) => setSelectedDomainPortfolioIDs(portfolioIDs),
    []
  );

  const [preSelectedVendorDetails, setPreSelectedVendorDetails] = useState(
    {} as IVendorSearchVendorAndWatched
  );
  const [monitorVendorRunnning, setMonitorVendorRunning] = useState(false);

  const [allVendorsSearchResult, setAllVendorsSearchResult] = useState(
    [] as IVendorSearchVendorAndWatched[]
  );
  const [filterText, setFilterText] = useState(existingFilterText ?? "");

  const baseDefaultConfig =
    selectedCannedExportConfig?.isDefault === false
      ? selectedCannedExportConfig.baseDefaultConfig
      : selectedCannedExportConfig;

  const cannedConfig = selectedCannedExportConfig?.config;
  const reportType = baseDefaultConfig?.exportType;
  const filetype = baseDefaultConfig?.filetype;
  const cannedReportName = selectedCannedExportConfig?.name;

  const availableConfig = filetype
    ? unfilteredAvailableConfig?.[filetype]
    : undefined;

  const [configuredExportConfigState, configuredExportConfigDispatch] =
    useConfiguredExportConfigReducer(availableConfig, cannedConfig);

  const requireVendorWithCompletedAssessment =
    exportConfigRequiresVendorWithCompletedAssessment(
      configuredExportConfigState.valid
        ? configuredExportConfigState.configuredExportConfig
        : initialSelectedCannedExportConfig?.config
    );

  const requestedVendorWithCompletedAssessment =
    exportConfigRequiresVendorWithCompletedAssessment(
      selectedCannedExportConfig?.config
    );

  useEffect(() => {
    if (!defaultCannedExportConfigs) {
      dispatch(fetchCannedExportConfigs(false)).catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error fetching report types. Please contact UpGuard Support."
          )
        );
      });
    }
  }, [dispatch, defaultCannedExportConfigs]);

  // effect: when the selected vendor changes, grab the export sections to update the values of the configuredExportConfigState
  // this will in turn update the definition of the valid steps in the process.
  useEffect(() => {
    if (!reportType) {
      return;
    }

    if (!unfilteredAvailableConfig) {
      dispatch(
        fetchExportSections({
          exportType: reportType,
          exportOptions: vendorId ? { vendor_id: vendorId } : {},
          vendorId,
          applyFilters: false,
        })
      ).catch((e) => {
        console.error(`Failed to load report sections for vendor: ${e}`);
      });
    }
  }, [dispatch, unfilteredAvailableConfig, reportType, vendorId]);

  useEffect(() => {
    if (selectedCannedExportConfig && availableConfig) {
      configuredExportConfigDispatch({
        type: "UPDATE_AVAILABLE_CONFIG",
        availableConfig: availableConfig,
        configuredConfig: selectedCannedExportConfig?.config,
      });
    }
  }, [
    configuredExportConfigDispatch,
    availableConfig,
    selectedCannedExportConfig,
  ]);

  useEffect(() => {
    dispatch(fetchCustomerLimitData());
  }, []);

  // memo: calculate the set of valid steps. will be re-executed whenever the vendorId changes (due to the effect above)
  const validSteps = useMemo(() => {
    const validSteps: reportsLibraryModalStepID[] = [];

    if (!initialSelectedCannedExportConfig) {
      validSteps.push("select_report_type");
    }

    if (baseDefaultConfig?.vendorIDRequired && !initialVendorId) {
      validSteps.push("set_vendor");
    }

    if (
      baseDefaultConfig?.vendorIDRequired &&
      !initialVendorId &&
      SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS &&
      orgIsFreeTrial &&
      preSelectedVendorDetails &&
      (!preSelectedVendorDetails.isWatched ||
        (requestedVendorWithCompletedAssessment &&
          !preSelectedVendorDetails.assessments?.some(
            (a) => !!a.lastPublishedDate
          )))
    ) {
      validSteps.push("watch_vendor");
    }

    if (
      configuredExportConfigState.availableExportConfig
        .requireGlobalOptionsOnQuickGenerate
    ) {
      validSteps.push("set_global_options");
    }

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.VendorPortfolios
      ) &&
      orgSupportsVendorPortfolios
    ) {
      validSteps.push("select_vendor_portfolios");
    }

    if (
      configuredExportConfigState.requiredExtraSteps.includes(
        RequiredExtraStep.DomainPortfolios
      ) &&
      orgSupportsDomainPortfolios
    ) {
      validSteps.push("select_domain_portfolios");
    }

    validSteps.push("report_schedule");
    return validSteps;
  }, [
    baseDefaultConfig,
    initialSelectedCannedExportConfig,
    configuredExportConfigState,
    orgSupportsVendorPortfolios,
    orgSupportsDomainPortfolios,
    initialVendorId,
  ]);

  // effect: when the set of valid steps changes, check whether we have an instruction (for a particular chosen vendor)  to step on the 'current stage'
  // and perform the step immediately.
  useEffect(() => {
    if (immediateMoveOnRecalc !== 0) {
      // an immediate move on steps recalc has been requested.
      // Note: if the immediate move has been requested with a negative vendorID, then we want it to move 'sliently' - ie we
      // dont want the current page to be placed on the back button stack.
      if (immediateMoveOnRecalc > 0) {
        pushPreviousStepIdx(validSteps.indexOf(currentStepID));
      }
      setCurrentStepID((currentStepID) => {
        const stepIdx = validSteps.indexOf(currentStepID);
        return validSteps[stepIdx + 1];
      });
      setImmediateMoveOnRecalc(0);
    }
  }, [validSteps]);

  useEffect(() => {
    // Ensure that we start on a valid step
    if (
      configuredExportConfigState.initialised &&
      validSteps.indexOf(currentStepID) === -1
    ) {
      setCurrentStepID(validSteps[0]);
    }
  }, [validSteps, currentStepID, configuredExportConfigState]);

  const onSuccessfulSchedule = useCallback(() => {
    onClose();
    history.push("/reportexports/scheduled", {
      noRemoveWhispers: true,
    });
  }, [onClose, history]);
  const onSuccessfulGenerate = useCallback(() => {
    onClose();
    history.push("/reportexports/generated", {
      noRemoveWhispers: true,
    });
  }, [onClose, history]);

  const openAssessmentsView = (vendorID: number, createAssessment: boolean) => {
    history.push(`/vendor/${vendorID}/assessment`, {
      startInitial: createAssessment,
    });
  };

  const submitGenerateReport = useSubmitGenerateReport(
    dispatch,
    reportType,
    filetype,
    cannedReportName,
    reportDeliveryState,
    configuredExportConfigState,
    vendorId,
    vendorName,
    setReportGenerateLoading,
    selectedVendorPortfolioIDs,
    selectedDomainPortfolioIDs,
    onSuccessfulSchedule,
    onSuccessfulGenerate,
    orgSupportsVendorPortfolios,
    orgSupportsDomainPortfolios,
    !!selectedCannedExportConfig && !selectedCannedExportConfig.isDefault,
    false
  );

  const monitorVendor = (
    vendorID: number,
    vendorName: string,
    vendorHostname: string,
    onSuccess: () => void
  ) => {
    setMonitorVendorRunning(true);
    dispatch(
      updateVendorWatchStatusWithRefresh(
        vendorID,
        true,
        history,
        [],
        vendorName,
        vendorHostname
      )
    )
      .then(() => onSuccess())
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            `An error occurred attempting to monitor ${vendorWords.singular} ${vendorName}. Please contact Sales directly at sales@upguard.com.`
          )
        );
      })
      .finally(() => setMonitorVendorRunning(false));
  };

  const isLoadingConfig =
    !unfilteredAvailableConfig && !unfilteredAvailableConfigWithoutVendorId;

  const [goPrevButton, goNextButton] = useMemo(() => {
    let goPrevButton: ReactNode | undefined = undefined;
    // Default to show a disabled "Quick generate" button
    // in the footer just to make the modal look right to a user
    let goNextButton: ReactNode = (
      <Button
        primary
        disabled
        loading={isLoadingConfig && !!selectedCannedExportConfig}
      >
        Quick generate
      </Button>
    );

    const stepIdx = validSteps.indexOf(currentStepID);
    const goToNextStep = () => {
      pushPreviousStepIdx(stepIdx);
      setCurrentStepID(validSteps[stepIdx + 1]);
    };
    /*
    const goToNextStepSecretly = (steps: number) => {
      setCurrentStepID(validSteps[stepIdx + steps]);
    };
     */
    const goToPrevStep = () => {
      const stepIdx = popPreviousStepIdx();
      if (stepIdx != undefined && stepIdx >= 0) {
        setCurrentStepID(validSteps[stepIdx]);
        /* special hacky case - if we're allowing unwatched vendors then we need to reset the vendor selection to allow the stepos to be recalculated successfully */
        if (
          validSteps[stepIdx] == "set_vendor" &&
          SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS &&
          orgIsFreeTrial
        ) {
          setPreSelectedVendorDetails({} as IVendorSearchVendorAndWatched);
          setVendor(0, "");
        }
      }
    };
    const goToStepIncrement = (inc: number) => {
      pushPreviousStepIdx(stepIdx);
      setCurrentStepID(validSteps[stepIdx + inc]);
    };

    if (stepIdx > 0) {
      goPrevButton = (
        <Button leftArrow onClick={() => goToPrevStep()}>
          Previous
        </Button>
      );
    }

    if (
      currentStepID === "select_report_type" &&
      selectedCannedExportConfig &&
      baseDefaultConfig &&
      !isLoadingConfig
    ) {
      goNextButton = (
        <>
          <Button
            onClick={() =>
              (
                history as History<locationState & IReportGenerateLocationState>
              ).push("/reportexports/create", {
                reportType: baseDefaultConfig.exportType,
                filetype: baseDefaultConfig.filetype,
                baseDefaultID: selectedCannedExportConfig.isDefault
                  ? selectedCannedExportConfig.defaultID
                  : undefined,
                baseUUID: selectedCannedExportConfig.isDefault
                  ? undefined
                  : selectedCannedExportConfig.uuid,
                createdBy: selectedCannedExportConfig.isDefault
                  ? undefined
                  : selectedCannedExportConfig.createdBy,
                cannedConfig: selectedCannedExportConfig.config,
                cannedReportName: selectedCannedExportConfig.name,
                cannedReportBadgeID: baseDefaultConfig?.badgeImageID,
                cannedReportDescription: selectedCannedExportConfig.description,
                vendorIdRequired: baseDefaultConfig.vendorIDRequired,
                vendorId,
                vendorName,
                backContext: {
                  goBack: true,
                  backToText: "Back",
                },
              })
            }
          >
            Customize report
          </Button>
          <Button
            primary
            disabled={!configuredExportConfigState.valid}
            onClick={goToNextStep}
          >
            Quick generate
          </Button>
        </>
      );
    }

    if (currentStepID === "set_vendor") {
      const supportUnwatchedVendors =
        SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS && orgIsFreeTrial;
      if (!supportUnwatchedVendors) {
        let disabledReason = !vendorId
          ? `Select a ${vendorWords.singular} to continue`
          : undefined;
        if (vendorId && !configuredExportConfigState.valid) {
          if (
            configuredExportConfigState.validationErr?.endsWith(
              ErrNoRadioOptionsSelected
            )
          ) {
            // If a vendor is set, but the error is no options selected, the vendor is invalid for the report.
            // This can occur if the vendor's website count is over the maximum allowed for the report type.
            disabledReason = "This vendor is invalid for the selected report";
          } else {
            disabledReason = configuredExportConfigState.validationErr;
          }
        }
        goNextButton = (
          <SidePopupV2 text={disabledReason}>
            <Button
              primary
              disabled={!!disabledReason}
              loading={!unfilteredAvailableConfig}
              /* if we aren't running in PLG mode, then we need to skip the 'watch vendor' and 'assess vendor' steps since only watched/assessed vendors are shown */
              onClick={() => {
                // make sure the vendors page doesn't inherit the search terms that are used here.
                setVendorSearch({
                  loading: false,
                  query: "",
                  results: {},
                });
                // and go to next step
                goToStepIncrement(1);
              }}
              arrow
            >
              Next
            </Button>
          </SidePopupV2>
        );
      }
    }

    if (currentStepID === "watch_vendor") {
      const disabledReason =
        !preSelectedVendorDetails || !preSelectedVendorDetails.id
          ? `No ${vendorWords.singular} has been selected`
          : vendorWatchCount &&
              vendorWatchLimit &&
              vendorWatchCount >= vendorWatchLimit &&
              !preSelectedVendorDetails.isWatched
            ? `No free ${vendorWords.singular} slots are available`
            : undefined;

      goNextButton = (
        <SidePopupV2 text={disabledReason}>
          <Button
            primary
            disabled={!!disabledReason}
            loading={monitorVendorRunnning || immediateMoveOnRecalc < 0}
            onClick={() => {
              if (!preSelectedVendorDetails.isWatched) {
                /* first of all, we watch the vendor */
                monitorVendor(
                  preSelectedVendorDetails.id,
                  preSelectedVendorDetails.name,
                  preSelectedVendorDetails.primary_hostname,
                  () => {
                    dispatch(refreshVendorSearchResults());
                    dispatch(
                      addDefaultSuccessAlert(
                        vendorWatchLimit > 0
                          ? `Monitored a new ${vendorWords.singular} (${
                              vendorWatchCount + 1
                            }/${vendorWatchLimit})`
                          : `Monitored a new ${vendorWords.singular}`,
                        [
                          `Visit the ${vendorWords.singular} summary for ${preSelectedVendorDetails.name} to edit vendor details. `,
                        ]
                      )
                    );
                    dispatch(fetchCustomerLimitData());
                    /* if we're not immediately racking off to the assessments page, we need to set the vendor so that the report sections are loaded */
                    /* and the correct steps for the report-type/watched vendor are defined. when these things are loaded and set, the step will be moved on */
                    /* immediately */
                    if (!requireVendorWithCompletedAssessment) {
                      /* we've watched the vendor. now we have to set up the reporting steps based on this vendor and the reporting sections */
                      setImmediateMoveOnRecalc(
                        -1 * preSelectedVendorDetails.id
                      );
                      setVendor(
                        preSelectedVendorDetails.id,
                        preSelectedVendorDetails.name
                      );
                    } else {
                      /* we want an assessments report, and have only just monitored them. so navigate away to the assessments page instead */
                      openAssessmentsView(preSelectedVendorDetails.id, true);
                    }
                  }
                );
              } else if (
                requestedVendorWithCompletedAssessment &&
                !preSelectedVendorDetails.assessments?.some(
                  (a) => !!a.lastPublishedDate
                )
              ) {
                /* we want an assessments report, but there are no assessments. navigate away to the assessments page instead */
                openAssessmentsView(preSelectedVendorDetails.id, true);
              } else {
                /* if we're watching already and already have an assessment >>we should never get here<< */
                console.error(
                  "Monitor step NEXT clicked, but we're already watching and no assessment has been requested."
                );
                goToNextStep();
              }
            }}
            arrow
          >
            {preSelectedVendorDetails.isWatched &&
            requestedVendorWithCompletedAssessment &&
            !preSelectedVendorDetails.assessments?.some(
              (a) => !!a.lastPublishedDate
            )
              ? `Conduct risk assessment`
              : !preSelectedVendorDetails.isWatched &&
                  requestedVendorWithCompletedAssessment
                ? `Monitor ${vendorWords.singular} and conduct risk assessment`
                : `Monitor ${vendorWords.singular} and continue`}
          </Button>
        </SidePopupV2>
      );
    }

    if (currentStepID === "set_global_options") {
      const disabledReason =
        configuredExportConfigState.globalOptsValidationErr;
      goNextButton = (
        <SidePopupV2 text={disabledReason}>
          <Button
            primary
            disabled={!!disabledReason}
            loading={!unfilteredAvailableConfig}
            onClick={goToNextStep}
            arrow
          >
            Next
          </Button>
        </SidePopupV2>
      );
    }

    if (currentStepID === "select_vendor_portfolios") {
      const disabledReason =
        selectedVendorPortfolioIDs.length === 0
          ? "At least one portfolio must be selected."
          : undefined;
      goNextButton = (
        <SidePopupV2 text={disabledReason}>
          <Button
            primary
            disabled={!!disabledReason}
            loading={!unfilteredAvailableConfig}
            onClick={goToNextStep}
            arrow
          >
            Next
          </Button>
        </SidePopupV2>
      );
    }

    if (currentStepID === "select_domain_portfolios") {
      const disabledReason =
        selectedDomainPortfolioIDs.length === 0
          ? "At least one portfolio must be selected."
          : undefined;
      goNextButton = (
        <SidePopupV2 text={disabledReason}>
          <Button
            primary
            disabled={!!disabledReason}
            loading={!unfilteredAvailableConfig}
            onClick={goToNextStep}
            arrow
          >
            Next
          </Button>
        </SidePopupV2>
      );
    }

    if (currentStepID === "report_schedule") {
      const validationErr =
        reportDeliveryStateValidationErr(reportDeliveryState);

      goNextButton = (
        <SidePopupV2 text={validationErr}>
          <Button
            primary
            disabled={!!validationErr}
            loading={reportGenerateLoading}
            onClick={submitGenerateReport}
          >
            Generate
          </Button>
        </SidePopupV2>
      );
    }

    return [goPrevButton, goNextButton];
  }, [
    history,
    validSteps,
    currentStepID,
    submitGenerateReport,
    selectedVendorPortfolioIDs,
    selectedDomainPortfolioIDs,
    reportGenerateLoading,
    reportDeliveryState,
    vendorWords,
    vendorId,
    vendorName,
    unfilteredAvailableConfig,
    selectedCannedExportConfig,
    isLoadingConfig,
    configuredExportConfigState,
    baseDefaultConfig,
    monitorVendorRunnning,
  ]);

  const availableExportConfigs = useMemo(() => {
    if (!defaultCannedExportConfigs) {
      return undefined;
    }

    let allConfigs: CannedExportConfig[] = [...defaultCannedExportConfigs];
    if (orgSupportCustomReportingTemplates && customCannedExportConfigs) {
      allConfigs.push(...customCannedExportConfigs);
    }

    // Sort the list of reports by module, in the following order
    const modulesSortOrder: BadgeModule[] = [
      BadgeModule.Upguard,
      BadgeModule.Breachsight,
      BadgeModule.VendorRisk,
    ];

    allConfigs = _sortBy(allConfigs, [
      // Sort by module
      (item) =>
        modulesSortOrder.indexOf(
          item.isDefault ? item.module : item.baseDefaultConfig.module
        ),
    ]);

    if (!filterAvailableReportTypes) {
      return allConfigs;
    }

    return allConfigs.filter(
      (c) =>
        filterAvailableReportTypes.indexOf(
          c.isDefault ? c.exportType : c.baseDefaultConfig.exportType
        ) > -1
    );
  }, [
    defaultCannedExportConfigs,
    customCannedExportConfigs,
    filterAvailableReportTypes,
    orgSupportCustomReportingTemplates,
  ]);

  const isPortfolioStep =
    currentStepID === "select_vendor_portfolios" ||
    currentStepID === "select_domain_portfolios";

  const { data: vendorAssessments } =
    VendorAssessmentAPI.useGetVendorAssessmentStreamsV1Query(
      vendorId &&
        (initialVendorIsWatched ||
          preSelectedVendorDetails.isWatched ||
          managedOrgId) // Fetch assessments if we had a watched vendor passed in, or the selected vendor is watched
        ? {
            vendorID: vendorId,
          }
        : skipToken
    );

  const vendorIdHasAssessments = useMemo(() => {
    return !!(
      vendorAssessments &&
      vendorAssessments.streams.some(
        (s) =>
          (!s.managedAssessmentID ||
            s.managedAssessmentServiceLevel !== "v3") &&
          !s.forSecurityProfile
      )
    );
  }, [vendorAssessments]);

  const vendorIdHasPublishedManagedV3Assessment = useMemo(() => {
    return !!(
      vendorAssessments &&
      vendorAssessments.streams.some(
        (s) =>
          !!s.publishedDate &&
          !!s.managedAssessmentID &&
          s.managedAssessmentServiceLevel === "v3"
      )
    );
  }, [vendorAssessments]);

  const vendorIdHasPublishedSecurityProfileAssessment = useMemo(() => {
    return !!(
      vendorAssessments &&
      vendorAssessments.streams.some(
        (s) => !!s.publishedDate && !!s.forSecurityProfile
      )
    );
  }, [vendorAssessments]);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      scroll="content"
      className={classNames("reports-library-modal", {
        "select-portfolio-modal": isPortfolioStep,
      })}
      footerClassName={
        goPrevButton || goNextButton
          ? "reports-library-modal-footer"
          : undefined
      }
      headerContent={
        isLoadingConfig
          ? "Generate report"
          : currentStepID === "select_report_type"
            ? "Generate report"
            : currentStepID === "set_global_options"
              ? "Customize report"
              : isPortfolioStep
                ? "Select portfolios"
                : selectedCannedExportConfig
                  ? `Generate ${selectedCannedExportConfig.name}`
                  : "Generate report"
      }
      footerContent={
        <>
          <div className="btn-group">{goPrevButton}</div>
          <div className="btn-group">{goNextButton}</div>
        </>
      }
    >
      {currentStepID === "select_report_type" ? (
        <>
          {!!initialSelectedCannedExportConfig || !availableExportConfigs ? (
            <LoadingBanner />
          ) : (
            <div className="report-type-selector">
              {availableExportConfigs.map((conf) => {
                let disabledReason = undefined;
                // if exportConfigRequiresVendorWithCompletedManagedV3Assessment returns true then exportConfigRequiresVendorWithCompletedAssessment
                // should always return true, so check the former case first then optionally check the later
                if (
                  exportConfigRequiresVendorWithCompletedManagedV3Assessment(
                    conf.config
                  )
                ) {
                  if (!vendorIdHasPublishedManagedV3Assessment) {
                    disabledReason = `Complete a managed risk assessment for this ${vendorWords.singular} to enable this report.`;
                  }
                } else if (
                  exportConfigRequiresVendorWithCompletedSecurityProfileAssessment(
                    conf.config
                  )
                ) {
                  if (!vendorIdHasPublishedSecurityProfileAssessment) {
                    disabledReason = `Complete a security profile risk assessment for this ${vendorWords.singular} to enable this report.`;
                  }
                } else if (
                  exportConfigRequiresVendorWithCompletedAssessment(
                    conf.config
                  ) &&
                  !vendorIdHasAssessments
                ) {
                  disabledReason = `Complete a risk assessment for this ${vendorWords.singular} to enable this report.`;
                }
                return (
                  <ReportTypeBadge
                    key={conf.isDefault ? conf.defaultID : conf.uuid}
                    selected={
                      (selectedCannedExportConfig?.isDefault &&
                        conf.isDefault &&
                        selectedCannedExportConfig.defaultID ===
                          conf.defaultID) ||
                      (!selectedCannedExportConfig?.isDefault &&
                        !conf.isDefault &&
                        selectedCannedExportConfig?.uuid === conf.uuid)
                    }
                    reportDef={{
                      module: conf.isDefault
                        ? conf.module
                        : conf.baseDefaultConfig.module,
                      imageRef:
                        BadgeImageRefs[
                          conf.isDefault
                            ? conf.badgeImageID
                            : BadgeImageID.Custom
                        ],
                      title: conf.name,
                      subtext: conf.description,
                      disabled: !!disabledReason,
                      disabledReason,
                    }}
                    assuranceType={assuranceType}
                    onClick={() => setSelectedCannedExportConfig(conf)}
                  />
                );
              })}
            </div>
          )}
        </>
      ) : isLoadingConfig ? (
        <LoadingBanner />
      ) : currentStepID === "set_vendor" ? (
        <ReportVendorSelector
          selectedVendorIDs={vendorId ? [vendorId] : []}
          reportType={reportType}
          onSelectVendor={(
            selected: boolean,
            vendorId: number,
            vendorName: string
          ) => {
            if (selected) {
              setVendor(vendorId, vendorName);
            }
          }}
          onPreselectVendor={(details: IVendorSearchVendorAndWatched) => {
            setPreSelectedVendorDetails(details);
          }}
          pageSize={5}
          allVendorsSearchResult={allVendorsSearchResult}
          setAllVendorsSearchResult={setAllVendorsSearchResult}
          filterText={filterText}
          setFilterText={setFilterText}
          vendorWords={vendorWords}
          assuranceType={assuranceType}
          assessedVendorsOnly={requireVendorWithCompletedAssessment}
          supportUnwatchedVendors={
            SUPPORT_UNMONITORED_VENDORS_FOR_FREE_TRIALS && orgIsFreeTrial
          }
          onNext={(steps: number) => {
            const stepIdx = validSteps.indexOf(currentStepID);
            pushPreviousStepIdx(stepIdx);
            setCurrentStepID(validSteps[stepIdx + steps]);
          }}
          setImmediateMoveOnRecalc={(v: number) => {
            setImmediateMoveOnRecalc(v);
          }}
          loadingReportGen={immediateMoveOnRecalc}
          useStateFilters={false}
        />
      ) : currentStepID === "watch_vendor" ? (
        <ReportVendorMonitor
          dispatch={dispatch}
          selectedVendorDetails={preSelectedVendorDetails}
          assessedVendorsOnly={requestedVendorWithCompletedAssessment}
          vendorWords={vendorWords}
          vendorWatchLimit={vendorWatchLimit}
          vendorWatchCount={vendorWatchCount}
        />
      ) : currentStepID === "set_global_options" ? (
        <ExportConfigurationOptions
          availableOptions={
            configuredExportConfigState.availableExportConfig.globalOptions ??
            []
          }
          configuredExportConfig={
            configuredExportConfigState.configuredExportConfig
          }
          configuredExportConfigDispatch={configuredExportConfigDispatch}
        />
      ) : currentStepID === "select_vendor_portfolios" ? (
        <SelectPortfoliosStep
          portfolioType={PortfolioType.Vendor}
          onSelectionChange={onSetSelectedVendorPortfolioIDs}
          initialSelectedPortfolioIDs={selectedVendorPortfolioIDs}
        />
      ) : currentStepID === "select_domain_portfolios" ? (
        <SelectPortfoliosStep
          portfolioType={PortfolioType.Domain}
          onSelectionChange={onSetSelectedDomainPortfolioIDs}
          initialSelectedPortfolioIDs={selectedDomainPortfolioIDs}
        />
      ) : currentStepID === "report_schedule" ? (
        <ReportDeliveryStep
          reportDeliveryState={reportDeliveryState}
          setReportDeliveryState={setReportDeliveryState}
        />
      ) : undefined}
    </ModalV2>
  );
};

const ConnectedReportsLibraryModal = appConnect<
  IReportsLibraryModalConnectedProps,
  never,
  IReportsLibraryModalOwnProps
>((state, props) => {
  const unfilteredAvailableConfig = props.selectedCannedExportConfig
    ? (
        getExportSections(
          state,
          props.selectedCannedExportConfig.isDefault
            ? props.selectedCannedExportConfig.exportType
            : props.selectedCannedExportConfig.baseDefaultConfig.exportType,
          false,
          {
            vendor_id: props.vendorId,
          },
          props.vendorId,
          false,
          false,
          undefined
        ) as ExportSection
      ).data?.availableConfigByFiletype
    : undefined;

  const unfilteredAvailableConfigWithoutVendorId =
    props.selectedCannedExportConfig
      ? (
          getExportSections(
            state,
            props.selectedCannedExportConfig.isDefault
              ? props.selectedCannedExportConfig.exportType
              : props.selectedCannedExportConfig.baseDefaultConfig.exportType,
            false,
            {},
            undefined,
            false,
            false,
            undefined
          ) as ExportSection
        ).data?.availableConfigByFiletype
      : undefined;

  const userData = _get(state.common, "userData", undefined);
  let isPLGFreeTrial = false;
  let currentOrgIsTrial = false;
  let isVendorFreeTrial = false;
  if (userData && userData.orgList && userData.currentOrgID > 0) {
    for (let i = 0; i < userData.orgList.length; i++) {
      if (userData.orgList[i].id === userData.currentOrgID) {
        currentOrgIsTrial =
          userData.orgList[i].accountType === organisationAccountType.trial;
        isVendorFreeTrial = !!userData.orgList[i].isVendorTrial;
        isPLGFreeTrial = !!userData.orgList[i].freeTrialSource;
        break;
      }
    }
  }

  const vendorWatchLimit = state.cyberRisk.customerData.vendorWatchLimit;
  const vendorWatchCount = state.cyberRisk.customerData.vendorWatchCount;
  const existingFilterText = ""; // state.cyberRisk.vendorSearch.query;

  // Check if we're watching the vendor if one was passed in to this modal
  const initialVendorIsWatched =
    !!props.initialVendorId &&
    (state.cyberRisk.vendors[props.initialVendorId]?.watching?.result
      ?.watching ??
      false);

  const newProps: IReportsLibraryModalConnectedProps = {
    defaultCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.default,
    customCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.custom,
    unfilteredAvailableConfig,
    unfilteredAvailableConfigWithoutVendorId,
    orgSupportsVendorPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessVendorPortfolios
    ),
    orgSupportsDomainPortfolios: state.common.userData.orgPermissions.includes(
      OrgAccessDomainPortfolios
    ),
    orgSupportCustomReportingTemplates:
      state.common.userData.orgPermissions.includes(
        OrgAccessCustomReportingTemplates
      ),
    assuranceType: state.common.userData.assuranceType,
    orgIsFreeTrial: currentOrgIsTrial && (isPLGFreeTrial || isVendorFreeTrial),
    vendorWatchCount: vendorWatchCount ?? 0,
    vendorWatchLimit: vendorWatchLimit ?? 0,
    existingFilterText,
    initialVendorIsWatched,
  };

  return newProps;
})(ReportsLibraryModal);

const ReportsLibraryModalPropsProvider: FC<
  IReportsLibraryModalPropsProviderProps
> = ({
  initialVendorId,
  initialVendorName,
  initialSelectedCannedExportConfig,
  ...otherProps
}) => {
  const initialConfig = _cloneDeep(initialSelectedCannedExportConfig);

  const [selectedCannedExportConfig, setSelectedCannedExportConfig] =
    useState(initialConfig);
  const [vendorId, setVendorId] = useState(initialVendorId);
  const [vendorName, setVendorName] = useState(initialVendorName);

  const setVendor = useCallback((vendorId: number, vendorName: string) => {
    setVendorId(vendorId);
    setVendorName(vendorName);
  }, []);

  return (
    <ConnectedReportsLibraryModal
      initialVendorId={initialVendorId}
      vendorId={vendorId}
      vendorName={vendorName}
      setVendor={setVendor}
      initialSelectedCannedExportConfig={initialSelectedCannedExportConfig}
      selectedCannedExportConfig={selectedCannedExportConfig}
      setSelectedCannedExportConfig={setSelectedCannedExportConfig}
      {...otherProps}
    />
  );
};

export default ReportsLibraryModalPropsProvider;
