import { useEffect, useMemo } from "react";
import { IUserMini, UserEmailAddress } from "../../_common/types/user";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import UsersAPI from "../../_common/api/usersAPI";

type UserMap = Record<number, IUserMini>;

type UserMapReturn = {
  userMap?: UserMap;
  error?: ReturnType<
    typeof ThreatMonitoringAPI.useGetReadWriteUsersV1Query
  >["error"];
  isLoading?: boolean;
};

// useRWUserMap returns a map of read-write users, keyed by their ID.
export function useRWUserMap(actors?: IUserMini[]): UserMapReturn {
  const { data, error, isLoading } =
    ThreatMonitoringAPI.useGetReadWriteUsersV1Query();

  useEffect(() => {
    if (error) {
      console.error("Error fetching read-write users", error);
      // TODO: Show error to user, neatly?
    }
  }, [error]);

  return useMemo(() => {
    if (error || isLoading || !data) {
      return { error, isLoading };
    }

    const users = [...data.users, ...(actors || [])];

    const userMap = (users || []).reduce(
      (acc, user) => ({ ...acc, [user.id]: user }),
      {} as Record<number, IUserMini>
    );

    return { userMap };
  }, [data, actors, error, isLoading]);
}

type OrgUserMap = Record<number, UserEmailAddress>;
export function useOrgUserMap() {
  const { data, error, isLoading } =
    UsersAPI.useListOrganisationUserEmailAddressesV1Query();

  return useMemo(() => {
    if (error || isLoading || !data) {
      return { error, isLoading };
    }

    const userMap = (data?.userEmails || []).reduce(
      (acc, user) => ({ ...acc, [user.id]: user }),
      {} as OrgUserMap
    );

    return { userMap };
  }, [data, error, isLoading]);
}
