import { useMemo } from "react";
import UsersAPI from "../api/usersAPI";

export interface OrganisationUserEmailOrInvite {
  id: number;
  isInvite: boolean;
  email: string; // users property is called "emailAddress"
  name?: string; // invites don't have a name property
  avatar?: string; // invites don't have an avatar property
}

interface OptionalNameRequiredEmail {
  name?: string;
  email: string;
}

// This sort is used so that users will see consistent ordering whenever
// this hook is used
export function sortByNameEmptyLastThenEmail(
  a: OptionalNameRequiredEmail,
  b: OptionalNameRequiredEmail
) {
  if (!a.name && b.name) return 1;
  if (a.name && !b.name) return -1;

  // Try to sort by name, and if they're equal then sort by email instead
  if (a.name && b.name)
    return a.name.localeCompare(b.name) || a.email.localeCompare(b.email);
  return a.email.localeCompare(b.email);
}

export default function useOrganisationUsersAndInvites() {
  const { data: userEmailsResponse, isFetching: isFetchingUserEmails } =
    UsersAPI.useListOrganisationUserEmailAddressesV1Query();
  const { data: userInvitesResponse, isFetching: isFetchingUserInvites } =
    UsersAPI.useListOrganisationUserInvitesV1Query();

  const usersAndInvites = useMemo(() => {
    const users = userEmailsResponse?.userEmails;
    const invites = userInvitesResponse?.invites;

    const result = users
      ?.map<OrganisationUserEmailOrInvite>((user) => ({
        id: user.id,
        isInvite: false,
        email: user.emailAddress,
        name: user.name,
        avatar: user.avatar,
      }))
      .concat(
        invites?.map((invite) => ({
          // Consumers will need to consider isInvite + id together for safety
          id: invite.id,
          isInvite: true,
          email: invite.email,
        })) || []
      );

    if (result) result.sort(sortByNameEmptyLastThenEmail);

    return result;
  }, [userEmailsResponse?.userEmails, userInvitesResponse?.invites]);

  return {
    data: usersAndInvites,
    isFetching: isFetchingUserEmails || isFetchingUserInvites,
  };
}
