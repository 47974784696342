export const pageDescriptions = {
  Dashboard:
    "View latest alerts and notifications, and track your overall cyber risk profile.",
  ActivityStreamDashboard:
    "View latest alerts and notifications, and track your overall cyber risk profile.",
  Alerts: "View latest alerts and notifications for your company",
  ActivityStream:
    "View your activity stream and the open tasks for you to complete",
  RiskProfile: "View the cyber risks identified for your company",
  VendorRiskProfile: "View the cyber risks identified for this vendor",
  UserBaseRiskProfile: "View the SaaS risks identified for this company",
  UserBaseDashboard: "View UserRisk dashboard",
  UserBaseApplications: "View the SaaS applications for this company",
  UserBasePermissions:
    "View the SaaS permissions granted by users for this company",
  UserBaseIdentityBreaches: "View the identity breaches for this company",
  UserBaseUsers: "View the users of this application",
  UserBaseAppOverview: "View the summary of this application",
  UserBaseUserOverview: "View the summary of this user",
  UserBasePermissionOverview: "View the summary of this permission",
  UserBasePermissionApplications:
    "View the applications requesting this permission",
  UserBasePermissionUsers: "View the users who granted this permission",
  UserBaseIdentityBreachOverview: "View the summary of this identity breach",
  UserBaseUserRiskProfile: "View the risk profile of this user",
  UserBaseUserApplications: "View the applications used by this user",
  UserBaseUserPermissions: "View the permissions granted by this user",
  UserBaseAppPermissions: "View permissions granted to this application",
  UserBaseRiskModifications:
    "Accept or modify risks on behalf of your organization",
  UserBaseRemediations: "Remediate your own organization's user risks",
  SubsidiarySummary: "View the summary for your subsidiary",
  SubsidiaryRiskProfile: "View the cyber risks identified for your subsidiary",
  VendorSecurityProfile: "View the security profile of this vendor",
  VendorSummary: "View the summary of this vendor",
  WebsitesAndAPIs:
    "View the domains that belong to your company, and their corresponding cyber risks",
  VendorWebsitesAndAPIs:
    "View the domains owned by this vendor, and their corresponding cyber risks",
  SubsidiaryWebsitesAndAPIs:
    "View the domains owned by this subsidiary, and their corresponding cyber risks",
  IPAddresses:
    "View the IP addresses that belong to your company, and their corresponding cyber risks",
  VendorIPAddresses:
    "View the IP addresses owned by this vendor, and their corresponding cyber risks",
  SubsidiaryIPAddresses:
    "View the IP addresses owned by this subsidiary, and their corresponding cyber risks",
  Identities:
    "See where credentials of your employees have been exposed in third-party data breaches",
  Typosquatting: "View potential typo-squatting risks to your domains",
  TyposquattingDetails:
    "View the details of potential typosquatting permutations for this domain",
  DataLeaks: "See where your company's data is exposed on the Internet",
  VendorDataLeaks:
    "Our team of analysts and proprietary data leak detection engine gives us an unparalleled ability to find leaked credentials and exposed data before it falls into the wrong hands.",
  Portfolio: "Manage your list of vendors",
  VendorPublicAttestations:
    "Browse security information and previous Attestations made public by this verified vendor",
  Remediation: "Work with your vendors to remediate their cyber risks",
  ConcentrationRisk:
    "View the concentration risk of fourth parties to your vendors",
  ExecutiveSummaryReport: "View the executive summary report for your account",
  VendorPortfolioRiskProfile:
    "View cyber risks identified across all your vendors",
  VendorRemediation: "Work with this vendor to remediate their cyber risks",
  SubsidiaryRemediation:
    "Work with this subsidiary to remediate their cyber risks",
  Questionnaires:
    "Send security questionnaires to your vendors, and manage their responses",
  QuestionnaireLibrary:
    "Manage the questionnaire types available to your account",
  VendorQuestionnaires:
    "Send security questionnaires to this vendor, and manage their responses",
  SupplyChain: "See the fourth parties that this vendor relies on",
  VendorDocuments:
    "Browse and capture the entire set of documentation for this vendor",
  VendorContacts: "Capture contact information for this vendor",
  VendorSharedAssets:
    "View assets shared for this vendor from other members of your account group",
  SelfRemediation: "Remediate your own organization's cyber risks",
  RiskWaivers: "Accept risks on behalf of your organization",
  Vulns: "View known vulnerabilities in the software detected on your websites",
  VendorVulns:
    "View known vulnerabilities in the software detected on this vendor's websites",
  SubsidiaryVulns:
    "View known vulnerabilities in the software detected on this subsidiary's websites",
  MySharedAssessment:
    "Manage the Trust Page that you wish to make publicly available for your organization",
  SharedAssessment: "View the Trust Page published by an organization",
  VendorAssessmentRiskProfile:
    "View the risks that have been included as 'in scope' for an existing risk assessment of this organization",
  SharedAssessmentsList: "View the Trust Page you have access to",
  PrefilledQuestionnaires:
    "Manage the Trust Pages that you wish to make publicly available for your organization",
  PrefilledVendorQuestionnaires:
    "Questionnaires that have been prefilled and made publicly available by authorized representatives of this vendor",
  WhoisDomainSearch:
    "Use whois as a source of additional domains for a specific vendor",
  VendorAssessment:
    "Capture and audit a full assessment of your vendor's security posture",
  VendorAssessmentsWebsitesAndAPIs:
    "When a risk assessment is conducted on a vendor, the domains & IPs tracked by UpGuard can be included as evidence in the assessment. This page shows the domains & IPs, along with their corresponding risks, at the time the risk assessment was conducted.",
  ReportExports: "View the status of queued exports and download the results",
  DataLeaksStatistics:
    "View the results of the CyberResearch team on behalf of your organization",
  AdditionalEvidence:
    "Collect additional materials related to the security posture of your vendors, and use them to identify potential risks",
  AdditionalEvidenceDetails:
    "View and/or edit the details of a specific piece of additional evidence for a vendor",
  AuditLog: "View logs of events that have occurred in your account.",
  CyberResearchManagedVendors: "View your vendors under third party management",
  Subsidiaries: "Monitor and manage your subsidiaries and related entities",
  ActivityStreamUserSettings:
    "Set up notifications triggers and manage what you see on your home page, and which notification emails you receive.",
  BreachNewsFeed: "View news articles and incident reports on data breaches",
  RoleBasedSettings: "Set custom permissions or assign a role.",
  RemediationRequest: "This is an individual remediation request.",
  VendorComparison: "View and compare vendors",
  DetectedProducts: "View products detected on your Domains and IPs",
  UserBaseAdmin: "Administer your UserRisk account",
  ThreatMonitoring: "View the threat monitoring dashboard",
  ThreatMonitoringAnalytics: "View the threat monitoring analytics",
  BreachsightCloud: "View the Cloud dashboard",
  ThreatMonitoringDarkWeb:
    "View the threats collected from the Dark Web specifically",
};
