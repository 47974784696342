import { IUserData } from "../../_common/types/redux";
import {
  OrganisationAcceptedRisk,
  OrganisationAcceptedRiskStatus,
  RiskAssetType,
  sortOrganisationAcceptedRiskStatus,
  updateCustomerAcceptedRiskOpts,
} from "../reducers/customerAcceptedRisks.actions";
import { IUserMini, IUserMiniMap } from "../../_common/types/user";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import { columnSortDef, useSorting } from "../../_common/hooks";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import IconSvg from "../images/risk-assessment-header-icon.svg";
import "../style/components/RiskWaiversTable.scss";
import SeverityIcon from "../../_common/components/SeverityIcon";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import EditRiskWaiverModal from "./risk_waivers/EditRiskWaiverModal";
import ApproveRiskWaiverModal from "./modals/ApproveRiskWaiverModal";
import RiskName from "./risk_profile/RiskName";
import BreachSightRiskWaiverPanel, {
  PillAcceptedRiskStatus,
  PillAcceptedRiskVisibility,
  RiskExpires,
} from "./risk_waivers/BreachSightRiskWaiverPanel";
import { SeverityAsString } from "../../_common/types/severity";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { LogError } from "../../_common/helpers";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";

interface IRiskWaiversTableProps {
  risks?: VendorSummaryRisk[] | undefined;
  acceptedRisks?: OrganisationAcceptedRisk[];
  users?: IUserMini[];
  loading: boolean;
  currentUser?: IUserData;
  userHasWriteAccess: boolean;
  userHasWriteAccessForAnyPortfolios?: boolean;
  filtersActive?: boolean;
  initiallySelectedId?: number;
  headerTextOverride?: string;
  descriptionTextOverride?: string;
  onCreateClick?: () => void;
  hideStatusAndUserDetails?: boolean;
  hideJustification?: boolean;
  hideSearch?: boolean;
  canUsePublicWaivers: boolean;
  onDelete?: (r: OrganisationAcceptedRisk) => Promise<void>;
  onUpdate?: (opts: updateCustomerAcceptedRiskOpts) => Promise<void>;
}

type sortableTableColumnIDs =
  | "severity"
  | "risk_name"
  | "status"
  | "created_at"
  | "expires_at";

const getAssetList = (assets: string[]) => {
  const assetsToDisplay = assets
    .slice(0, 10)
    .map((w) => <div key={w}>{w}</div>);
  if (assets.length > 10) {
    assetsToDisplay.push(
      <div key={"more"}>
        <br />
        <span>and {assets.length - 10} more...</span>
      </div>
    );
  }
  return <div>{assetsToDisplay}</div>;
};

const getAssetsCell = (r: OrganisationAcceptedRisk) => {
  switch (r.riskAssetType) {
    case RiskAssetType.cloudscan:
      return r.allWebsites ? (
        <SidePopupV2
          text={
            <>
              Risk will be waived for{" "}
              {r.websites.length === 1
                ? "the domain/IP"
                : "all of the domains & IPs"}{" "}
              we currently detect with this risk, as well as any new domains &
              IPs we detect in future.
              {r.websites.length > 0 && (
                <>
                  <br />
                  <br />
                  Currently detected domains & IPs: {getAssetList(r.websites)}
                </>
              )}
            </>
          }
        >
          <span>All domains & IPs</span>
        </SidePopupV2>
      ) : (
        <SidePopupV2 text={getAssetList(r.websites)}>
          <span>
            {r.websites.length}{" "}
            {r.websites.length === 1 ? "domain/IP" : "domains & IPs"}
          </span>
        </SidePopupV2>
      );
    case RiskAssetType.appguardRepo:
      return (
        <SidePopupV2 text={getAssetList(r.repoNames)}>
          <span>
            {r.repoNames.length}{" "}
            {r.repoNames.length === 1 ? "repository" : "repositories"}
          </span>
        </SidePopupV2>
      );
    case RiskAssetType.appguardManifest:
      return (
        <SidePopupV2 text={getAssetList(r.manifestLocations)}>
          <span>
            {r.manifestLocations.length}{" "}
            {r.manifestLocations.length === 1 ? "inventory" : "inventories"}
          </span>
        </SidePopupV2>
      );
    case RiskAssetType.userbaseUser:
      return (
        <SidePopupV2 text={getAssetList(r.userInfos.map((u) => u.name))}>
          <span>
            {r.userInfos.length} {r.userInfos.length === 1 ? "user" : "users"}
          </span>
        </SidePopupV2>
      );
    default:
      LogError(`unexpected risk waiver asset type: ${r.riskAssetType}`);
      return <></>;
  }
};

const RiskWaiversTable: FC<IRiskWaiversTableProps> = ({
  risks,
  acceptedRisks,
  users,
  loading,
  currentUser,
  userHasWriteAccess,
  userHasWriteAccessForAnyPortfolios = false,
  initiallySelectedId,
  headerTextOverride,
  descriptionTextOverride,
  onCreateClick,
  hideStatusAndUserDetails,
  hideJustification,
  hideSearch,
  canUsePublicWaivers,
  filtersActive,
  onDelete,
  onUpdate,
}) => {
  const dispatch = useAppDispatch();

  const [slidePanelWaiverId, setSlidePanelWaiverId] = useState<
    number | undefined
  >(undefined);
  const [lastShownInitiallySelectedId, setLastShownInitiallySelectedId] =
    useState<number | undefined>(undefined);

  const [searchText, setSearchText] = useState("");

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [editingWaiver, setEditingWaiver] = useState<
    OrganisationAcceptedRisk | undefined
  >(undefined);
  const [approvingRejectingWaiver, setApprovingRejectingWaiver] = useState<
    OrganisationAcceptedRisk | undefined
  >(undefined);
  const [isApproving, setIsApproving] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      !initiallySelectedId ||
      initiallySelectedId === lastShownInitiallySelectedId
    ) {
      return;
    }

    const initiallySelected = acceptedRisks?.find(
      (ar) => ar.id === initiallySelectedId
    );
    if (initiallySelected) {
      setSlidePanelWaiverId(initiallySelected.id);
      setLastShownInitiallySelectedId(initiallySelected.id);
    }
  }, [acceptedRisks, initiallySelectedId, lastShownInitiallySelectedId]);

  const usersMap = useMemo(
    () =>
      users?.reduce((prev: IUserMiniMap, cur) => {
        prev[cur.id] = cur;
        return prev;
      }, {}),
    [users]
  );

  const tableSortingFuncs = useMemo<
    Record<sortableTableColumnIDs, columnSortDef<OrganisationAcceptedRisk>>
  >(
    () => ({
      severity: {
        orderFuncs: [
          (r) => r.riskSeverity,
          (r) => r.riskName,
          (r) => r.createdAt,
        ],
        sortDirsDesc: [
          SortDirection.DESC,
          SortDirection.ASC,
          SortDirection.DESC,
        ],
        sortDirsAsc: [SortDirection.ASC, SortDirection.ASC, SortDirection.DESC],
      },
      risk_name: {
        orderFuncs: [(r) => r.riskName, (r) => r.createdAt],
        sortDirsDesc: [SortDirection.DESC, SortDirection.DESC],
        sortDirsAsc: [SortDirection.ASC, SortDirection.DESC],
      },
      status: {
        orderFuncs: [
          (r) => sortOrganisationAcceptedRiskStatus(r.status),
          (r) => r.isPublic,
          (r) => r.createdAt,
        ],
        sortDirsDesc: [
          SortDirection.DESC,
          SortDirection.DESC,
          SortDirection.DESC,
        ],
        sortDirsAsc: [
          SortDirection.ASC,
          SortDirection.DESC,
          SortDirection.DESC,
        ],
      },
      created_at: {
        orderFuncs: [(r) => r.createdAt, (r) => r.createdAt],
        sortDirsDesc: [SortDirection.DESC, SortDirection.DESC],
        sortDirsAsc: [SortDirection.ASC, SortDirection.DESC],
      },
      expires_at: {
        orderFuncs: [(r) => r.expiresAt, (r) => r.createdAt],
        sortDirsDesc: [SortDirection.DESC, SortDirection.DESC],
        sortDirsAsc: [SortDirection.ASC, SortDirection.DESC],
      },
    }),
    []
  );

  const filteredAcceptedRisks = useMemo(
    () =>
      searchText.length > 1
        ? acceptedRisks?.filter(
            (r) =>
              r.riskName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              r.websites.some(
                (w) => w.toLowerCase().indexOf(searchText.toLowerCase()) > -1
              )
          )
        : acceptedRisks,
    [acceptedRisks, searchText]
  );

  const [sortedItems, sortedBy, onSortChange] = useSorting<
    OrganisationAcceptedRisk,
    sortableTableColumnIDs
  >(
    filteredAcceptedRisks ?? [],
    "status",
    SortDirection.ASC,
    tableSortingFuncs
  );

  const deleteRiskWaiver = useCallback(
    (r?: OrganisationAcceptedRisk) => {
      let text =
        "Deleting this waiver will cause this risk to become visible and contribute to your overall score.";
      if (r?.status === OrganisationAcceptedRiskStatus.AwaitingApproval) {
        text = "The approver will no longer be able to approve this waiver.";
      } else if (r?.status !== OrganisationAcceptedRiskStatus.Active) {
        text = "This waiver will no longer be shown in the Risk waivers list.";
      }
      openConfirmationModal({
        title: `Delete risk waiver`,
        description: text,
        buttonText: "Delete",
        dangerousAction: true,
        descriptionClassName: "delete-waiver-desc",
        buttonAction: async () => {
          if (onDelete && r) {
            await onDelete(r).then(() => setSlidePanelWaiverId(undefined));
          }
        },
      });
    },
    [dispatch, openConfirmationModal]
  );

  const approveRejectRiskWaiver = useCallback(
    (r: OrganisationAcceptedRisk, approve: boolean) => {
      setApprovingRejectingWaiver(r);
      setIsApproving(approve);
    },
    []
  );

  const getIconsForAcceptedRisk = (
    r: OrganisationAcceptedRisk
  ): IIconOption[] => {
    const options: IIconOption[] = [];

    if (userHasWriteAccess || r.canWrite) {
      switch (r.status) {
        case "active":
        case "awaitingapproval":
          if (onUpdate) {
            options.push({
              id: "edit",
              hoverText: "Edit",
              onClick: () => setEditingWaiver(r),
              icon: <i className="cr-icon-pencil" />,
              hidden: !onUpdate,
            });
          }
          options.push({
            id: "delete",
            hoverText: "Delete",
            onClick: () => deleteRiskWaiver(r),
            icon: <i className="cr-icon-trash-2" />,
          });
          break;
        default:
          options.push(
            ...[
              {
                id: "delete",
                hoverText: "Delete",
                onClick: () => deleteRiskWaiver(r),
                icon: <i className="cr-icon-trash-2" />,
              },
            ]
          );
          break;
      }
    } else if (
      currentUser?.emailAddress === r.approverEmail ||
      currentUser?.id === r.approverId
    ) {
      switch (r.status) {
        case "awaitingapproval":
          options.push(
            ...[
              {
                id: "approve",
                hoverText: "Approve",
                onClick: () => approveRejectRiskWaiver(r, true),
                icon: <i className="cr-icon-accepted" />,
              },
              {
                id: "reject",
                hoverText: "Reject",
                onClick: () => approveRejectRiskWaiver(r, false),
                icon: <i className="cr-icon-exclamation" />,
              },
            ]
          );
          break;
        default:
          break;
      }
    }

    options.push({
      id: "view",
      hoverText: "View",
      onClick: () =>
        setSlidePanelWaiverId((currentWaiverId) => {
          if (currentWaiverId && currentWaiverId === r.id) {
            // If the current open waiver is this one, then close the slide panel
            return undefined;
          }
          return r.id;
        }),
      icon: <i className="cr-icon-chevron" />,
    });

    return options;
  };

  const rows: IXTableRow[] = useMemo(
    () =>
      sortedItems.map((r) => {
        const iconOptions: IIconOption[] = getIconsForAcceptedRisk(r);

        return {
          id: r.id,
          selected: slidePanelWaiverId === r.id,
          onClick: () =>
            setSlidePanelWaiverId((currentWaiverId) => {
              if (currentWaiverId && currentWaiverId === r.id) {
                // If the current open waiver is this one, then close the slide panel
                return undefined;
              }
              return r.id;
            }),
          cells: [
            <XTableCell key="severity" className="shrink-cell">
              <SeverityIcon severity={SeverityAsString(r.riskSeverity)} />
            </XTableCell>,
            <XTableCell key="risk_id">
              <RiskName
                riskName={r.riskName}
                riskCategoryGroupName={r.riskCategoryName}
              />
            </XTableCell>,
            <XTableCell key="assets" className="assets-cell">
              {getAssetsCell(r)}
            </XTableCell>,
            <XTableCell key="justification" hide={hideJustification}>
              {r.requesterReason || r.approverReason}
            </XTableCell>,
            <XTableCell key="status" hide={hideStatusAndUserDetails}>
              <PillAcceptedRiskStatus status={r.status} />
              <PillAcceptedRiskVisibility
                risk={r}
                canUsePublicWaivers={canUsePublicWaivers}
              />
            </XTableCell>,
            <XTableCell key="created_at" className="date-cell">
              {<DateTimeFormat dateTime={r.createdAt} dateOnly />}
            </XTableCell>,
            <XTableCell key="expires_at" className="date-cell">
              <RiskExpires risk={r} />
            </XTableCell>,
          ],
          iconOptions: iconOptions,
        };
      }),
    [currentUser, slidePanelWaiverId, sortedItems, userHasWriteAccess, usersMap]
  );

  let selectedWaiver: OrganisationAcceptedRisk | undefined;
  if (slidePanelWaiverId) {
    selectedWaiver = acceptedRisks?.find((ar) => ar.id === slidePanelWaiverId);
  }

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "severity",
      text: "Sev.",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "risk_name",
      text: "Risk name",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    { id: "assets", text: "Waived for", sortable: false },
    {
      id: "justification",
      text: "Justification",
      sortable: false,
      hide: hideJustification,
    },
    {
      id: "status",
      text: "Status",
      sortable: true,
      hide: hideStatusAndUserDetails,
      helpText: canUsePublicWaivers
        ? "Public risk waivers will be viewable by UpGuard Vendor Risk users outside of your organization. Anyone viewing your page will see the waiver and the justification you’ve provided."
        : undefined,
    },
    { id: "created_at", text: "Created", sortable: true },
    { id: "expires_at", text: "Expiry", sortable: true },
  ];

  const showApproveRejectPanelOptions =
    (currentUser?.emailAddress === selectedWaiver?.approverEmail ||
      currentUser?.id === selectedWaiver?.approverId) &&
    selectedWaiver?.status === OrganisationAcceptedRiskStatus.AwaitingApproval;

  return (
    <>
      <ReportCard newStyles className="risk-waivers-table">
        <div className="header">
          <div className="header-left">
            {headerTextOverride ?? "Risk Waivers"}
            {descriptionTextOverride && (
              <div className="header-sub">{descriptionTextOverride}</div>
            )}
          </div>
          {(userHasWriteAccess || userHasWriteAccessForAnyPortfolios) &&
            onCreateClick && (
              <div className={"header-right"}>
                <Button primary onClick={onCreateClick}>
                  <div className={"icon-x rotate-45"} />
                  Create risk waiver
                </Button>
              </div>
            )}
        </div>
        {(acceptedRisks ?? []).length === 0 && !loading ? (
          filtersActive ? (
            <SearchEmptyCard searchItemText={"risk waivers"} />
          ) : (
            <EmptyCardWithAction
              iconSrc={IconSvg}
              emptyText={"Risk Waivers"}
              emptySubText={`Creating a risk waiver will remove the risk from your Risk 
                        Profile. We recommend creating a risk waiver only if you are accepting 
                        a risk or have compensating control information.`}
              actionButtonText={"Create risk waiver"}
              actionDisabled={
                !userHasWriteAccess && !userHasWriteAccessForAnyPortfolios
              }
              onActionClick={onCreateClick}
              supportLinkHref={
                "https://help.upguard.com/en/articles/3883003-how-to-use-risk-waivers-in-breachsight"
              }
            />
          )
        ) : (
          <>
            {!hideSearch && (
              <SearchBox
                onChanged={(v) => setSearchText(v)}
                value={searchText}
                disabled={loading}
              />
            )}
            {!loading && filteredAcceptedRisks?.length === 0 ? (
              <SearchEmptyCard
                onClear={() => setSearchText("")}
                searchItemText={"risk waivers"}
              />
            ) : (
              <XTable
                className="risk-waivers-table-table"
                loading={loading}
                columnHeaders={columnHeaders}
                sortedBy={sortedBy}
                onSortChange={onSortChange}
                iconOptions
                rows={rows}
              />
            )}
          </>
        )}
        <BreachSightRiskWaiverPanel
          active={!!slidePanelWaiverId}
          onClose={() => setSlidePanelWaiverId(undefined)}
          selectedWaiver={selectedWaiver}
          showApproveRejectPanelOptions={showApproveRejectPanelOptions}
          userHasWriteAccess={userHasWriteAccess}
          hideStatusAndUserDetails={hideStatusAndUserDetails == true}
          canUsePublicWaivers={canUsePublicWaivers}
          usersMap={usersMap ?? {}}
          onEdit={onUpdate ? () => setEditingWaiver(selectedWaiver) : undefined}
          onDelete={() => deleteRiskWaiver(selectedWaiver)}
          onApprove={(isApproving: boolean) => {
            setApprovingRejectingWaiver(selectedWaiver);
            setIsApproving(isApproving);
          }}
        />
      </ReportCard>
      {onUpdate && (
        <EditRiskWaiverModal
          active={editingWaiver !== undefined}
          onClose={() => {
            setSlidePanelWaiverId(undefined);
            setEditingWaiver(undefined);
          }}
          waiver={editingWaiver}
          usersMap={usersMap ?? {}}
          risks={risks}
          riskWaivers={acceptedRisks}
          canUsePublicWaivers={canUsePublicWaivers}
          onUpdate={onUpdate}
        />
      )}
      <ApproveRiskWaiverModal
        isVendorRiskWaiver={false}
        isShowing={approvingRejectingWaiver !== undefined}
        waiverId={approvingRejectingWaiver?.id ?? 0}
        isApproving={isApproving ?? false}
        onClose={() => {
          setApprovingRejectingWaiver(undefined);
        }}
      />
      {confirmationModal}
    </>
  );
};

export default RiskWaiversTable;
