import { useDispatch } from "react-redux";
import { useDefaultHistory } from "../../_common/types/router";
import { FilterTypes } from "../../vendorrisk/components/filter";
import { UserRiskFilters } from "../../vendorrisk/components/filter/types";
import { getClearedFilters } from "../../vendorrisk/components/filter/FilterPanel";
import { setCustomerDataFilters } from "../../vendorrisk/reducers/cyberRiskActions";

// Hook for handling navigating to another page with a set of user risk filters.
// Note that this function clears the filters and only sets the filters that are passed.
// If no filters are passed this effectively cancels all filtering
export const useNavigateWithUserRiskFilters = (
  supportedFilters: FilterTypes[]
) => {
  const history = useDefaultHistory();
  const dispatch = useDispatch();

  const navigateWithUserRiskFilters = (
    url: string,
    backText: string,
    filters?: Partial<UserRiskFilters>
  ) => {
    // Set the filters
    let newFilters = getClearedFilters(supportedFilters);
    if (filters) {
      newFilters = { ...newFilters, ...filters };
    }

    dispatch(setCustomerDataFilters(newFilters));

    // Navigate to the URL
    history.push(url, {
      backContext: {
        backTo: history.location.pathname,
        backToText: backText,
      },
    });
  };

  return navigateWithUserRiskFilters;
};
