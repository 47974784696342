import ReportCard from "../../../_common/components/ReportCard";
import moment from "moment";
import IconButton from "../../../_common/components/IconButton";
import { RiskDetail } from "../../../_common/types/vendor";
import {
  fetchCustomerOrVendorRiskWebsites,
  IAdditionalEvidenceCheckToAdd,
  ICloudscanCheckAndWebsite,
  ISaasCheckToAdd,
  ISurveyCheckToAdd,
} from "../../reducers/remediation.actions";
import { AdditionalEvidenceRiskEvidence } from "../../../_common/types/vendorSummary";
import ExpandableItem from "../../../_common/components/ExpandableItem";
import { FC, ReactNode, useEffect } from "react";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString, SeverityInt } from "../../../_common/types/severity";
import {
  RiskWebsiteOptions,
  useRiskWebsites,
} from "../../reducers/remediationRequest.selector";
import { usePermissions } from "../../../_common/permissions";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { DocumentLink } from "../../components/DocumentLink";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { ContactDisplay } from "../../components/contacts/ContactSelect";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import "../../style/views/remediation_request/ReviewAndSendStep.scss";

interface ReviewAndSendStepProps {
  vendorName?: string;
  vendorID?: number;
  selectedContacts: ContactDisplay[];
  title: string;
  emailMessage: string;
  isSubsidiary: boolean;
  isSelfRemediation: boolean;
  dueDate?: string;
  reminderDate?: string;
  risks?: RiskDetail[];
  selectedCloudscanChecks: Record<string, ICloudscanCheckAndWebsite>;
  selectedSurveyChecks: Record<string, ISurveyCheckToAdd>;
  selectedAdditionalEvidenceChecks: Record<
    string,
    IAdditionalEvidenceCheckToAdd
  >;
  selectedDocuments: AdditionalEvidenceRiskEvidence[];
  selectedSaasChecks: Record<string, ISaasCheckToAdd>;
  onDownloadDocument: (e: AdditionalEvidenceRiskEvidence) => void;
  onEditVendor?: () => void;
  onEditRisks?: () => void;
  onEditDocuments: () => void;
  onEditSettings: () => void;
  onEditRecipients?: () => void;
  isUserRiskRemediation?: boolean;
}

export interface NameAndEmail {
  name?: string;
  email: string;
}

export const ReviewAndSendStep: FC<ReviewAndSendStepProps> = ({
  vendorID,
  vendorName,
  selectedContacts,
  title,
  emailMessage,
  isSubsidiary,
  isSelfRemediation,
  dueDate,
  reminderDate,
  risks,
  selectedCloudscanChecks,
  selectedSurveyChecks,
  selectedAdditionalEvidenceChecks,
  selectedSaasChecks,
  selectedDocuments,
  onDownloadDocument,
  onEditVendor,
  onEditDocuments,
  onEditRisks,
  onEditSettings,
  onEditRecipients,
  isUserRiskRemediation = false,
}) => {
  return (
    <div>
      <ReportCard newStyles className={"remediation-request-review-card"}>
        <div className="header">Review and send</div>
        <div className="report-card-sections">
          {!!vendorName && !isSelfRemediation && (
            <div className="report-card-section">
              <div className="section-label">
                {isSubsidiary ? "Subsidiary" : "Vendor"}
              </div>
              <div className="section-content">{vendorName}</div>
              <div className="section-action">
                {!!onEditVendor && (
                  <IconButton
                    icon={<div className="cr-icon-pencil-2" />}
                    onClick={onEditVendor}
                  />
                )}
              </div>
            </div>
          )}

          <div className="report-card-section">
            <div className="section-label">
              {isUserRiskRemediation ? "Risks and users" : "Risks and assets"}
            </div>
            <div className="section-content">
              {risks
                ?.filter((risk) => !risk.passed)
                .map((risk) => (
                  <RiskSummaryWithAssets
                    key={risk.id}
                    risk={risk}
                    cloudscanCheck={selectedCloudscanChecks[risk.id]}
                    surveyCheck={selectedSurveyChecks[risk.id]}
                    evidenceCheck={selectedAdditionalEvidenceChecks[risk.id]}
                    saasCheck={selectedSaasChecks[risk.id]}
                    riskWebsiteOptions={{
                      isSelfRemediation,
                      isSubsidiary,
                      vendorID,
                    }}
                  />
                ))}
            </div>
            {onEditRisks && (
              <div className="section-action">
                <IconButton
                  icon={<div className="cr-icon-pencil-2" />}
                  onClick={onEditRisks}
                />
              </div>
            )}
          </div>

          {Object.keys(selectedAdditionalEvidenceChecks).length > 0 && (
            <div className="report-card-section documents-section">
              <div className="section-label">Documents</div>
              <div className="section-content">
                {selectedDocuments.length === 0 && (
                  <em>No documents will be shared with recipients</em>
                )}
                {selectedDocuments.length > 0 &&
                  selectedDocuments.map((document) => (
                    <DocumentLink
                      key={document.id}
                      evidenceName={document.name}
                      filename={document.filename}
                      virusSafe={document.virusSafe}
                      onDownload={() => onDownloadDocument(document)}
                    />
                  ))}
              </div>
              <div className="section-action">
                <IconButton
                  icon={<div className="cr-icon-pencil-2" />}
                  onClick={onEditDocuments}
                />
              </div>
            </div>
          )}

          <div className="report-card-section">
            <div className="section-label">Request name</div>
            <div className="section-content">{title}</div>
            <div className="section-action">
              <IconButton
                icon={<div className="cr-icon-pencil-2" />}
                onClick={onEditSettings}
              />
            </div>
          </div>

          <div className="report-card-section">
            <div className="section-label">Recipients</div>
            <div className="section-content">
              {selectedContacts.map((r) => (
                <div className="recipient" key={r.emailAddress}>
                  {!!r.name && <div className="recipient-name">{r.name}</div>}
                  <div className="recipient-email">{r.emailAddress}</div>
                </div>
              ))}
            </div>
            <div className="section-action">
              <IconButton
                icon={<div className="cr-icon-pencil-2" />}
                onClick={onEditRecipients ? onEditRecipients : onEditSettings}
              />
            </div>
          </div>

          {!!dueDate && (
            <div className="report-card-section">
              <div className="section-label">Due date</div>
              <div className="section-content">
                {moment(dueDate).format("ll")}
              </div>
              <div className="section-action">
                <IconButton
                  icon={<div className="cr-icon-pencil-2" />}
                  onClick={onEditSettings}
                />
              </div>
            </div>
          )}
          {!!reminderDate && (
            <div className="report-card-section">
              <div className="section-label">Reminder date</div>
              <div className="section-content">
                {moment(reminderDate).format("ll")}
              </div>
              <div className="section-action">
                <IconButton
                  icon={<div className="cr-icon-pencil-2" />}
                  onClick={onEditSettings}
                />
              </div>
            </div>
          )}

          <div className="report-card-section">
            <div className="section-label">Message</div>
            <div className="section-content">
              <RichTextEditV2 readOnly value={emailMessage} />
            </div>
            <div className="section-action">
              <IconButton
                icon={<div className="cr-icon-pencil-2" />}
                onClick={onEditSettings}
              />
            </div>
          </div>
        </div>
      </ReportCard>
    </div>
  );
};

interface RiskSummaryWithAssetsProps {
  risk: RiskDetail;
  cloudscanCheck?: ICloudscanCheckAndWebsite;
  surveyCheck?: ISurveyCheckToAdd;
  evidenceCheck?: IAdditionalEvidenceCheckToAdd;
  saasCheck?: ISaasCheckToAdd;
  riskWebsiteOptions: RiskWebsiteOptions;
}

const getSelectedSurveyNames = (
  risk: RiskDetail,
  surveyCheck: ISurveyCheckToAdd
): string[] =>
  risk.surveys
    ?.filter(
      (s) =>
        (s.publicSurvey
          ? surveyCheck.publicSurveys
          : surveyCheck.surveys
        )?.includes(s.surveyId)
    )
    .map((s) => s.surveyName) ?? [];

export const RiskSummaryWithAssets: FC<RiskSummaryWithAssetsProps> = ({
  risk,
  cloudscanCheck,
  surveyCheck,
  evidenceCheck,
  riskWebsiteOptions,
  saasCheck,
}) => {
  // don't render anything if this risk is not actually selected
  if (!cloudscanCheck && !surveyCheck && !evidenceCheck && !saasCheck) {
    return null;
  }
  let assetsList: ReactNode = undefined;
  if (cloudscanCheck && cloudscanCheck.selectAll) {
    // if we're selecting all domains, we need HostnameList to load the actual list of domains/IPs
    assetsList = (
      <ExpandableItem
        header={`${risk.numFailedCloudscans} ${
          risk.numFailedCloudscans > 1 ? "domains and IPs" : "domain/IP"
        } selected`}
      >
        <HostnameList risk={risk} riskWebsiteOptions={riskWebsiteOptions} />
      </ExpandableItem>
    );
  } else if (cloudscanCheck && !cloudscanCheck.selectAll) {
    assetsList = (
      <ExpandableItem
        header={`${cloudscanCheck.websites.length} ${
          cloudscanCheck.websites.length > 1 ? "domains and IPs" : "domain/IP"
        } selected`}
      >
        <AssetList names={cloudscanCheck.websites} />
      </ExpandableItem>
    );
  } else if (surveyCheck) {
    const surveyNames = getSelectedSurveyNames(risk, surveyCheck);
    assetsList = (
      <ExpandableItem
        header={`${surveyNames.length} ${
          surveyNames.length > 1 ? "questionnaires" : "questionnaire"
        } selected`}
      >
        <AssetList names={surveyNames} />
      </ExpandableItem>
    );
  }
  return (
    <RiskSummary
      severity={risk.severity}
      baseSeverity={risk.baseSeverity}
      title={risk.title}
      description={risk.categoryTitle}
      assetsList={assetsList}
    />
  );
};

interface RiskSummaryProps {
  severity: SeverityInt;
  baseSeverity?: SeverityInt;
  title: string;
  description: string;
  assetsList?: ReactNode;
}

export const RiskSummary: FC<RiskSummaryProps> = ({
  severity,
  baseSeverity,
  title,
  description,
  assetsList,
}) => {
  return (
    <div className="risk-summary">
      <div className="risk-header">
        <AdjustedSeverityIcon
          severity={SeverityAsString(severity)}
          baseSeverity={
            baseSeverity ? SeverityAsString(baseSeverity) : undefined
          }
        />
        <div className="risk-details">
          <div className="risk-title">{title}</div>
          {description && <div className="risk-description">{description}</div>}
        </div>
      </div>
      {assetsList}
    </div>
  );
};

interface HostnameListProps {
  risk: RiskDetail;
  riskWebsiteOptions: RiskWebsiteOptions;
}

const HostnameList: FC<HostnameListProps> = ({ risk, riskWebsiteOptions }) => {
  const permissions = usePermissions();
  const hostnames = useRiskWebsites(risk.id, riskWebsiteOptions);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!hostnames?.data?.hostnamesWithRisk && !hostnames?.loading) {
      dispatch(
        fetchCustomerOrVendorRiskWebsites(
          risk.id,
          riskWebsiteOptions.vendorID,
          riskWebsiteOptions.isSelfRemediation,
          riskWebsiteOptions.isSubsidiary,
          permissions.editableDomainPortfolioIds
        )
      );
    }
  }, []);
  return hostnames?.data?.hostnamesWithRisk ? (
    <AssetList names={hostnames.data.hostnamesWithRisk} />
  ) : (
    <LoadingBanner />
  );
};

interface AssetListProps {
  names: string[];
}

const maxAssetsInList = 10;

const AssetList: FC<AssetListProps> = ({ names }) => {
  const assetsToDisplay = names.slice(0, maxAssetsInList);
  const assetsNotShown = names.length - assetsToDisplay.length;
  return (
    <ul>
      {assetsToDisplay.map((a) => (
        <li key={a}>{a}</li>
      ))}
      {assetsNotShown > 0 && (
        <li>
          <em>{assetsNotShown} more...</em>
        </li>
      )}
    </ul>
  );
};
