import { Component, FC } from "react";
import { get as _get } from "lodash";

import { withPermissions } from "../../_common/permissions";
import {
  ISortedBy,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import XTable from "../../_common/components/core/XTable";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import moment from "moment";
import { setChangesActive } from "../reducers/cyberRiskActions";
import { severityMap } from "../../_common/helpers";
import CyberTrendScore from "./CyberTrendScore";
import HelpImg1 from "../images/changes-help-1.png";
import HelpImg2 from "../images/changes-help-2.png";
import "../style/components/Changes.scss";
import RiskSummaryTable from "./RiskSummaryTable";
import Icon from "../../_common/components/core/Icon";
import VendorRiskWaiversCard, {
  mergeWaiverArrays,
} from "./VendorRiskWaiversCard";
import RiskWaiversTable from "./RiskWaiversTable";
import PillLabel from "./PillLabel";
import { LabelColor } from "../../_common/types/label";
import { getVendorWords } from "../../_common/constants";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import { ISurveyListItemResponse } from "../../_common/types/survey";
import { ExpandableReportCard } from "../../_common/components/ReportCard";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import Score from "./Score";
import InfoBanner from "./InfoBanner";
import { locationState } from "../../_common/types/router";
import { History } from "history";
import classNames from "classnames";
import { IStringKeyedVerifiedCount } from "../../_common/types/kevs";
import { AssuranceType } from "../../_common/types/organisations";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";
import { appConnect } from "../../_common/types/reduxHooks";
import { MultiProductNavContentLeftClass } from "../../_common/helpers/navigation.helpers";

const provisionalRisksKBURL =
  "https://help.upguard.com/en/articles/6708571-what-are-provisional-risks";

interface ChangesViewFrameProps extends DefaultThunkDispatchProp {
  className?: string;
  active: boolean;
  onChangeActiveState: (newState: boolean) => void;
  surveyDiff?: boolean; // indicates is beind used in survey diff mode
}

export const ChangesViewFrame: FC<ChangesViewFrameProps> = ({
  className,
  dispatch,
  active,
  onChangeActiveState,
  surveyDiff,
}) => (
  <TransitionGroup id="changes_view_frame" className={active ? "active" : ""}>
    {active && (
      <CSSTransition
        key="changesviewframe"
        timeout={250}
        classNames="changes-view-anim"
      >
        <div
          className={classNames(
            "changes-view-frame",
            { [MultiProductNavContentLeftClass]: !surveyDiff },
            {
              ["for-survey"]: surveyDiff,
            },
            className
          )}
        >
          <div
            className="changes-view-tab"
            onClick={() => {
              if (!surveyDiff) {
                dispatch(setChangesActive(false));
              }
              if (onChangeActiveState) {
                onChangeActiveState(false);
              }
            }}
          >
            Changes View <Icon name="x" />
          </div>
        </div>
      </CSSTransition>
    )}
  </TransitionGroup>
);

interface VendorDiffsOwnProps {
  history: History<locationState>;
  vendorDiffs: any[]; //TODO
  surveyDiffs: any[]; //TODO

  startDate: number;
  endDate: number;
  vendor?: boolean;
  assuranceType: AssuranceType;
}

interface VendorDiffsConnectedProps {
  surveys: ISurveyListItemResponse[];
}

type VendorDiffsProps = VendorDiffsOwnProps &
  VendorDiffsConnectedProps &
  DefaultThunkDispatchProp;

interface VendorDiffsState {
  sortedBy: ISortedBy;
  surveySortedBy: ISortedBy;
}

class VendorDiffs extends Component<VendorDiffsProps, VendorDiffsState> {
  state = {
    sortedBy: {
      columnId: "diff",
      direction: SortDirection.ASC,
    },
    surveySortedBy: {
      columnId: "vendorName",
      direction: SortDirection.ASC,
    },
  };

  render() {
    const {
      history,
      vendorDiffs,
      surveyDiffs,
      startDate,
      endDate,
      vendor,
      assuranceType,
    } = this.props;

    let anyVendorsHaveGottenWorse = false;
    let anyVendorsSurveysHaveGoneDown = false;
    for (let i = 0; i < vendorDiffs.length; i++) {
      if (vendorDiffs[i].difference < 0) {
        anyVendorsHaveGottenWorse = true;
        break;
      }
    }
    for (let i = 0; i < surveyDiffs.length; i++) {
      if (
        surveyDiffs[i].difference < 0 &&
        surveyDiffs[i].eventType == "VendorSurveyScoreChange"
      ) {
        anyVendorsSurveysHaveGoneDown = true;
      }
      switch (surveyDiffs[i].eventType) {
        case "VendorSurveyScoreChange":
          surveyDiffs[i].desc = "Score changed";
          break;
        case "VendorSurveyAdded":
          surveyDiffs[i].desc = "New questionnaire submitted";
          break;
        case "VendorSurveyArchived":
          surveyDiffs[i].desc = "Questionnaire archived or cancelled";
          break;
        case "SharedVendorSurveyAdded":
          surveyDiffs[i].desc = "New Trust Page questionnaire included";
          break;
        case "SharedVendorSurveyRemoved":
          surveyDiffs[i].desc =
            "Trust Page questionnaire excluded or inaccessible";
          break;
      }
    }

    // sort the vendors
    const sortedVendorDiffs = [...vendorDiffs];
    switch (this.state.sortedBy.columnId) {
      case "name":
        sortedVendorDiffs.sort((a, b) =>
          a.vendorName.localeCompare(b.vendorName)
        );
        break;
      case "start_score":
        sortedVendorDiffs.sort((a, b) => a.scoreA - b.scoreA);
        break;
      case "end_score":
        sortedVendorDiffs.sort((a, b) => a.scoreB - b.scoreB);
        break;
      case "diff":
        sortedVendorDiffs.sort((a, b) => a.difference - b.difference);
        break;
    }

    if (this.state.sortedBy.direction === "desc") {
      sortedVendorDiffs.reverse();
    }

    const sortedSurveyDiffs = [...surveyDiffs];
    switch (this.state.surveySortedBy.columnId) {
      case "name":
        sortedVendorDiffs.sort((a, b) =>
          a.vendorName.localeCompare(b.vendorName)
        );
        break;
      case "survey_name":
        sortedVendorDiffs.sort((a, b) =>
          a.surveyName.localeCompare(b.surveyName)
        );
        break;
      case "start_score":
        sortedVendorDiffs.sort((a, b) => a.scoreA - b.scoreA);
        break;
      case "end_score":
        sortedVendorDiffs.sort((a, b) => a.scoreB - b.scoreB);
        break;
      case "diff":
        sortedVendorDiffs.sort((a, b) => a.difference - b.difference);
        break;
    }

    if (this.state.surveySortedBy.direction === "desc") {
      sortedSurveyDiffs.reverse();
    }

    const surveyColHeaders = [];
    if (!vendor) {
      surveyColHeaders.push({ id: "name", text: "Vendor", sortable: true });
    }
    surveyColHeaders.push({
      id: "survey_name",
      text: "Questionnaire",
      sortable: true,
    });
    surveyColHeaders.push({
      id: "start_score",
      text: `Score at ${moment(startDate).format("MMM DD")}`,
      sortable: true,
    });
    surveyColHeaders.push({
      id: "end_score",
      text: `Score at ${moment(endDate).format("MMM DD")}`,
      sortable: true,
    });
    surveyColHeaders.push({ id: "diff", text: "Difference", sortable: true });
    surveyColHeaders.push({
      id: "change",
      text: "What Changed",
      sortable: false,
    });

    const surveyRows: IXTableRow[] = [];
    sortedSurveyDiffs.map((s) => {
      const cells = [];
      if (!vendor) {
        cells.push(<XTableCell key="name">{s.vendorName}</XTableCell>);
      }
      cells.push(
        <XTableCell key="survey">
          {s.surveyName}
          {s.publicSurvey && (
            <PillLabel
              color={LabelColor.Orange}
              className={"shared-questionnaire"}
            >
              Shared questionnaire
            </PillLabel>
          )}
        </XTableCell>
      );
      cells.push(
        <XTableCell key="start_score">
          <div className={"grade-and-score"}>
            <ColorGrade score={s.scoreA} size={ColorGradeSize.Small} />
            <Score small score={s.scoreA} />
          </div>
        </XTableCell>
      );
      cells.push(
        <XTableCell key="end_score">
          <div className={"grade-and-score"}>
            <ColorGrade score={s.scoreB} size={ColorGradeSize.Small} />
            <Score small score={s.scoreB} />
          </div>
        </XTableCell>
      );
      cells.push(
        <XTableCell key="diff">
          {s.scoreA > 0 && s.scoreB > 0 ? (
            <CyberTrendScore trend={s.difference} />
          ) : (
            <div>{"-"}</div>
          )}
        </XTableCell>
      );
      cells.push(<XTableCell key="change">{s.desc}</XTableCell>);
      surveyRows.push({
        id: s.vendorId + s.surveyName,
        onClick: () => {
          // Add the survey data to state so it's there when we load the survey page
          const backContext = {
            goBack: true,
            backToText: `Back to ${s.vendorName} changes view`,
          };
          this.props.history.push(`/surveys/${s.surveyId}`, { backContext });
        },
        cells: cells,
      });
    });

    return (
      <>
        {vendorDiffs.length > 0 && (
          <ExpandableReportCard
            newStyles
            className="summary-category-card changes-category-card"
            isInitiallyExpanded={false}
            headerContent={
              <>
                {anyVendorsHaveGottenWorse
                  ? severityMap.medium.icon
                  : severityMap.pass.icon}
                Vendors have changed score
              </>
            }
          >
            <XTable
              columnHeaders={[
                { id: "name", text: "Vendor", sortable: true },
                {
                  id: "start_score",
                  text: `Score at ${moment(startDate).format("MMM DD")}`,
                  sortable: true,
                },
                {
                  id: "end_score",
                  text: `Score at ${moment(endDate).format("MMM DD")}`,
                  sortable: true,
                },
                { id: "diff", text: "Difference", sortable: true },
              ]}
              rows={sortedVendorDiffs.map((v) => ({
                id: v.vendorId,
                onClick: () =>
                  history.push(`/vendor/${v.vendorId}`, {
                    from: "changes",
                  } as any),
                cells: [
                  <XTableCell key="name">{v.vendorName}</XTableCell>,
                  <XTableCell key="start_score">
                    <div className={"grade-and-score"}>
                      <ColorGrade
                        score={v.scoreA}
                        size={ColorGradeSize.Small}
                      />
                      <Score small score={v.scoreA} />
                    </div>
                  </XTableCell>,
                  <XTableCell key="end_score">
                    <div className={"grade-and-score"}>
                      <ColorGrade
                        score={v.scoreB}
                        size={ColorGradeSize.Small}
                      />
                      <Score small score={v.scoreB} />
                    </div>
                  </XTableCell>,
                  <XTableCell key="diff">
                    <CyberTrendScore trend={v.difference} />
                  </XTableCell>,
                ],
              }))}
              sortedBy={this.state.sortedBy}
              onSortChange={(columnId, direction) =>
                this.setState({ sortedBy: { columnId, direction } })
              }
            />
          </ExpandableReportCard>
        )}
        {surveyDiffs.length > 0 && vendor && (
          <ExpandableReportCard
            newStyles
            className="summary-category-card changes-category-card"
            isInitiallyExpanded={false}
            headerContent={
              <>
                {anyVendorsSurveysHaveGoneDown
                  ? severityMap.medium.icon
                  : severityMap.pass.icon}
                {`${
                  getVendorWords(assuranceType).singularTitleCase
                } questionnaires have changed`}
              </>
            }
          >
            <XTable
              columnHeaders={surveyColHeaders}
              rows={surveyRows}
              sortedBy={this.state.surveySortedBy}
              onSortChange={(columnId, direction) =>
                this.setState({ surveySortedBy: { columnId, direction } })
              }
            />
          </ExpandableReportCard>
        )}
      </>
    );
  }
}

export const ConnectedVendorDiffs = appConnect<
  VendorDiffsConnectedProps,
  never,
  VendorDiffsOwnProps
>((state, props) => {
  const surveys: ISurveyListItemResponse[] = [];
  if (props.surveyDiffs) {
    for (let i = 0; i < props.surveyDiffs.length; i++) {
      const survey = _get(
        state.common.surveys,
        `${props.surveyDiffs[i].surveyId}.survey`
      );
      if (survey) {
        surveys.push(survey);
      }
    }
  }

  return {
    surveys,
  };
})(withPermissions(VendorDiffs));

interface ChangesProps extends DefaultThunkDispatchProp {
  history: History<locationState>;
  onToggleCloudscanDiffPanel: (
    hostname: string,
    actualStartDate?: number,
    actualEndDate?: number
  ) => void;
  loading?: boolean;
  startDate?: number;
  endDate?: number;
  data: DiffData;
  vendor?: boolean;
  filterByCategory?: string;
  urlPrefix: () => string;
  assuranceType: AssuranceType;
  riskKEVCounts?: IStringKeyedVerifiedCount;
  canUsePublicWaivers: boolean;
  vendorName?: string;
  onClearFilter?: () => void;
}

export interface DiffData {
  vendorId: string | null | undefined;
  isSubsidiary: boolean;
  risksIntroduced: any[];
  risksResolved: any[];
  risksNowScored: any[];
  surveyDiffs: any[];
  newHostnames: any[];
  removedHostnames: any[];
  scoringAlgorithmChangeOccurred: boolean;
  acceptedRisksAdded: any[];
  acceptedRisksRemoved: any[];
  acceptedRiskUsers: any[];
  vendorRiskWaiversAdded: IVendorRiskWaiver[];
  vendorRiskWaiversExpired: IVendorRiskWaiver[];
  isVendorHostnamesNowInactive: boolean;
  overallScoreDiff: ScoreDiff;
  vendorRiskAdjustmentsAdded: IVendorRiskWaiver[];
  vendorRiskAdjustmentsExpired: IVendorRiskWaiver[];
}

export interface ScoreDiff {
  scoreA: number;
  scoreB: number;
  dateA: string;
  dateB: string;
}

class Changes extends Component<ChangesProps> {
  static defaultProps = {
    loading: false,
    startDate: null,
    endDate: null,
    data: null,
    vendor: false,
    filterByCategory: "",
  };

  render() {
    if (this.props.loading) {
      return (
        <div className="changes">
          <LoadingBanner />
        </div>
      );
    }

    if (!this.props.data) {
      return (
        <div className="changes">
          <div className="changes-help">
            <div className="item">
              <img src={HelpImg1} />
              <div className="item-text">
                Click another point of the graph to select the comparison date.
                To exit, click &quot;Exit&quot; on the graph.
              </div>
            </div>
            <div className="item">
              <img src={HelpImg2} style={{ width: "81.7%" }} />
              <div className="item-text">
                Then click View Changes to enter the changes view. To exit,
                click &quot;Exit&quot; on the graph.
              </div>
            </div>
          </div>
        </div>
      );
    }

    const { startDate, endDate, data, vendor, assuranceType } = this.props;
    const vendorWords = getVendorWords(assuranceType);
    const {
      risksIntroduced,
      risksResolved,
      risksNowScored,
      surveyDiffs,
      newHostnames,
      removedHostnames,
      scoringAlgorithmChangeOccurred,
      acceptedRisksAdded,
      acceptedRisksRemoved,
      acceptedRiskUsers,
      vendorRiskWaiversAdded,
      vendorRiskWaiversExpired,
      isVendorHostnamesNowInactive,
      vendorRiskAdjustmentsAdded,
      vendorRiskAdjustmentsExpired,
    } = data;

    const actualStartDate =
      startDate && endDate && startDate < endDate ? startDate : endDate;
    const actualEndDate =
      startDate && endDate && startDate < endDate ? endDate : startDate;

    if (
      !risksIntroduced?.length &&
      !risksResolved?.length &&
      !risksNowScored?.length &&
      !surveyDiffs?.length &&
      !newHostnames?.length &&
      !removedHostnames?.length &&
      !acceptedRisksAdded?.length &&
      !acceptedRisksRemoved?.length &&
      !vendorRiskWaiversAdded?.length &&
      !vendorRiskWaiversExpired?.length &&
      !isVendorHostnamesNowInactive &&
      !scoringAlgorithmChangeOccurred &&
      !vendorRiskAdjustmentsAdded?.length &&
      !vendorRiskAdjustmentsExpired?.length
    ) {
      return (
        <div className="changes">
          <div className="no-changes-text">
            No changes found between {moment(actualStartDate).format("MMM DD")}{" "}
            and {moment(actualEndDate).format("MMM DD")}.
          </div>
        </div>
      );
    }

    const risksNowScoredFiltered = this.props.filterByCategory
      ? (risksNowScored ?? []).filter(
          (risk) => risk.factCategory === this.props.filterByCategory
        )
      : risksNowScored;

    return (
      <div className="changes">
        {isVendorHostnamesNowInactive && (
          <InfoBanner
            message={`All hostnames for this ${vendorWords.singular} became inactive during this
                  period`}
          />
        )}
        {scoringAlgorithmChangeOccurred &&
          (risksNowScoredFiltered?.length > 0 ? (
            <InfoBanner
              message={`Some risks reached the end of their provisional status
                    during this time period and were included in score
                    calculations for the first time.`}
              linkText={"Read help article"}
              linkURL={provisionalRisksKBURL}
            />
          ) : !risksNowScored?.length ? (
            <InfoBanner
              message={
                "Changes to the scoring algorithm occurred in this period"
              }
            />
          ) : undefined)}
        {surveyDiffs?.length > 0 && (
          <ConnectedVendorDiffs
            dispatch={this.props.dispatch}
            history={this.props.history}
            vendorDiffs={[]}
            surveyDiffs={surveyDiffs}
            startDate={actualStartDate ?? 0}
            endDate={actualEndDate ?? 0}
            vendor={vendor}
            assuranceType={assuranceType}
          />
        )}
        {newHostnames?.length > 0 && (
          <ExpandableReportCard
            newStyles
            className="summary-category-card changes-category-card"
            isInitiallyExpanded={false}
            headerContent={
              <>
                <i className={"cr-icon-risk"} />
                {newHostnames.length === 1
                  ? "1 new domain/IP was detected"
                  : `${newHostnames.length} new domains & IPs detected`}
              </>
            }
          >
            <div>
              <div className="hostname-list">
                {newHostnames
                  .sort((a, b) => a.hostname.localeCompare(b.hostname))
                  .map((h) => (
                    <div
                      className="hostname-list-item"
                      key={h.hostname}
                      onClick={() =>
                        this.props.onToggleCloudscanDiffPanel(
                          h.hostname,
                          actualStartDate,
                          actualEndDate
                        )
                      }
                    >
                      <span className="hostname">{h.hostname}</span>
                      <div className={"grade-and-score"}>
                        <ColorGrade
                          score={h.score}
                          size={ColorGradeSize.Small}
                        />
                        <Score small score={h.score} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </ExpandableReportCard>
        )}
        {removedHostnames?.length > 0 && (
          <ExpandableReportCard
            newStyles
            className="summary-category-card changes-category-card"
            isInitiallyExpanded={false}
            headerContent={
              <>
                <i className={"cr-icon-risk"} />
                {removedHostnames.length === 1
                  ? "1 domain/IP removed"
                  : `${removedHostnames.length} domains & IPs removed`}
              </>
            }
          >
            <div>
              <div className="hostname-list">
                {removedHostnames
                  .sort((a, b) => a.hostname.localeCompare(b.hostname))
                  .map((h) => (
                    <div
                      className="hostname-list-item"
                      key={h.hostname}
                      onClick={() =>
                        this.props.onToggleCloudscanDiffPanel(
                          h.hostname,
                          actualStartDate,
                          actualEndDate
                        )
                      }
                    >
                      <span className="hostname">{h.hostname}</span>
                      <div className={"grade-and-score"}>
                        <ColorGrade
                          score={h.score}
                          size={ColorGradeSize.Small}
                        />
                        <Score small score={h.score} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </ExpandableReportCard>
        )}
        {risksNowScored && risksNowScored.length > 0 && (
          <RiskSummaryTable
            history={this.props.history}
            onToggleCloudscanDiffPanel={this.props.onToggleCloudscanDiffPanel}
            title="Provisional risks now scored"
            risks={risksNowScored}
            changesMode
            changesStartDate={actualStartDate}
            changesEndDate={actualEndDate}
            filterByCategory={this.props.filterByCategory}
            filterActive={!!this.props.filterByCategory}
            urlPrefix={this.props.urlPrefix}
            canManageVendorRiskWaivers={false}
            riskKEVCounts={this.props.riskKEVCounts}
            isRisksNowScored
            hideShowWaivedRisksOption
            onClearFilter={this.props.onClearFilter}
          />
        )}
        {risksIntroduced && risksIntroduced.length > 0 && (
          <RiskSummaryTable
            history={this.props.history}
            onToggleCloudscanDiffPanel={this.props.onToggleCloudscanDiffPanel}
            title="Risks introduced"
            risks={risksIntroduced}
            changesMode
            changesStartDate={actualStartDate}
            changesEndDate={actualEndDate}
            filterByCategory={this.props.filterByCategory}
            filterActive={!!this.props.filterByCategory}
            urlPrefix={this.props.urlPrefix}
            canManageVendorRiskWaivers={false}
            riskKEVCounts={this.props.riskKEVCounts}
            hideShowWaivedRisksOption
            onClearFilter={this.props.onClearFilter}
          />
        )}
        {risksResolved && risksResolved.length > 0 && (
          <RiskSummaryTable
            history={this.props.history}
            onToggleCloudscanDiffPanel={this.props.onToggleCloudscanDiffPanel}
            title="Risks resolved"
            risks={risksResolved}
            changesMode
            changesStartDate={actualStartDate}
            changesEndDate={actualEndDate}
            filterByCategory={this.props.filterByCategory}
            filterActive={!!this.props.filterByCategory}
            urlPrefix={this.props.urlPrefix}
            canManageVendorRiskWaivers={false}
            riskKEVCounts={this.props.riskKEVCounts}
            hideShowWaivedRisksOption
            onClearFilter={this.props.onClearFilter}
          />
        )}
        {acceptedRisksAdded?.length > 0 && (
          <RiskWaiversTable
            headerTextOverride="Risk Waivers Added"
            acceptedRisks={acceptedRisksAdded}
            users={acceptedRiskUsers}
            loading={false}
            userHasWriteAccess={false}
            hideJustification
            canUsePublicWaivers={this.props.canUsePublicWaivers}
          />
        )}
        {acceptedRisksRemoved?.length > 0 && (
          <RiskWaiversTable
            headerTextOverride="Risk Waivers Expired"
            acceptedRisks={acceptedRisksRemoved}
            users={acceptedRiskUsers}
            loading={false}
            userHasWriteAccess={false}
            hideJustification
            canUsePublicWaivers={this.props.canUsePublicWaivers}
          />
        )}
        {vendorRiskWaiversAdded?.length > 0 && (
          <VendorRiskWaiversCard
            headerTextOverride={`${vendorWords.singularTitleCase} Risk Waivers Added`}
            hideSearch
            dispatch={this.props.dispatch}
            history={this.props.history}
            riskWaivers={mergeWaiverArrays(vendorRiskWaiversAdded)}
            assuranceType={this.props.assuranceType}
            vendorName={this.props.vendorName}
          />
        )}
        {vendorRiskWaiversExpired?.length > 0 && (
          <VendorRiskWaiversCard
            headerTextOverride={`${vendorWords.singularTitleCase} Risk Waivers Expired`}
            hideSearch
            dispatch={this.props.dispatch}
            history={this.props.history}
            riskWaivers={mergeWaiverArrays(vendorRiskWaiversExpired)}
            assuranceType={this.props.assuranceType}
            vendorName={this.props.vendorName}
          />
        )}
        {vendorRiskAdjustmentsAdded?.length > 0 && (
          <VendorRiskWaiversCard
            headerTextOverride={`${vendorWords.singularTitleCase} Risk Adjustments Added`}
            hideSearch
            dispatch={this.props.dispatch}
            history={this.props.history}
            riskWaivers={mergeWaiverArrays(vendorRiskAdjustmentsAdded)}
            assuranceType={this.props.assuranceType}
            vendorName={this.props.vendorName}
          />
        )}
        {vendorRiskAdjustmentsExpired?.length > 0 && (
          <VendorRiskWaiversCard
            headerTextOverride={`${vendorWords.singularTitleCase} Risk Adjustments Expired`}
            hideSearch
            dispatch={this.props.dispatch}
            history={this.props.history}
            riskWaivers={mergeWaiverArrays(vendorRiskAdjustmentsExpired)}
            assuranceType={this.props.assuranceType}
            vendorName={this.props.vendorName}
          />
        )}
      </div>
    );
  }
}

export default Changes;
