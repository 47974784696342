// Mirrors the export config types in exports/export_config in the backend.

import { ExportFiletype, ExportType } from "./exportReport";
import {
  BadgeImageID,
  BadgeModule,
} from "../../vendorrisk/components/reporting/ReportTypeBadge";
import { IUserMini } from "./user";

export enum RequiredExtraStep {
  VendorPortfolios = "VendorPortfolios",
  DomainPortfolios = "DomainPortfolios",
}

export interface AvailableExportConfig {
  globalOptions?: AvailableOption[];
  requireGlobalOptionsOnQuickGenerate: boolean;
  sections?: AvailableSection[];
  supportedMergeTags?: SupportedMergeTag[];
  requiredExtraSteps?: RequiredExtraStep[];
}

export interface AvailableExportSelectListItem {
  itemID: string;
  itemText: string;
  isDefault: boolean;
}

export interface AvailableOption {
  id: string;
  title: string;
  description: string;

  conditionalOnIDs?: Record<string, boolean>; // The boolean option IDs to look for when determining whether this option is shown

  optionSectionOpts?: {
    hideOptionTitle?: boolean;
    children?: AvailableOption[];
  };

  checkboxOpts?: {
    defaultSelected: boolean;
    disabled: boolean;
    disabledReason: string;
    oneRequired: boolean;
    requiredExtraSteps?: RequiredExtraStep[];
    children?: AvailableOption[];
  };

  radioOpts?: {
    defaultSelected: boolean;
    disabled: boolean;
    disabledReason: string;
    requiredExtraSteps?: RequiredExtraStep[];
  };

  textOpts?: {
    required: boolean;
    placeholder: string;
    multiline: boolean;
    maxLength: number;
    markdownSupported: boolean;
    mergeTagsSupported: boolean;
  };

  selectListOpts?: {
    items: AvailableExportSelectListItem[];
  };
}

export interface AvailableSection {
  id: string;
  title: string;
  description: string;
  imageURL?: string;
  positionImageBelowOptions?: boolean;

  movable: boolean;
  defaultSelected: boolean;
  disabled: boolean;
  disabledReason: string;
  oneRequired: boolean;

  options?: AvailableOption[];
  requiredExtraSteps?: RequiredExtraStep[];
}

export interface SupportedMergeTag {
  tag: string;
  description: string;
}

export interface ConfiguredExportConfig {
  vendorIDRequired: boolean;
  globalOptions: Record<string, ConfiguredOption | undefined>;
  sections: ConfiguredSection[];
}

export interface ConfiguredOption {
  id: string;
  valueBool: boolean;
  valueText: string;
  disabled: boolean;
}

export interface ConfiguredSection {
  id: string;
  value: boolean;
  options: Record<string, ConfiguredOption | undefined>;
}

export interface DefaultCannedExportConfig {
  isDefault: true; // Literal set here to enable discriminating between these types
  exportType: ExportType;
  filetype: ExportFiletype;
  name: string;
  description: string;
  config: ConfiguredExportConfig;
  module: BadgeModule;
  vendorIDRequired: boolean;
  monitoredVendorRequired: boolean;
  defaultID: string;
  badgeImageID: BadgeImageID;
  reportTypeName: string;
  showInCreateTemplateView: boolean;
}

export interface CustomCannedExportConfig {
  isDefault: false; // Literal set here to enable discriminating between these types
  uuid: string;
  baseDefaultID: string;
  baseDefaultConfig: DefaultCannedExportConfig;
  name: string;
  description: string;
  config: ConfiguredExportConfig;
  orgID: number;
  createdBy: number;
  createdByUser: IUserMini;
  createdAt: string;
  updatedAt: string;
}

export type CannedExportConfig =
  | DefaultCannedExportConfig
  | CustomCannedExportConfig;

export interface StarredExportType {
  customCannedExportUUID?: string;
  defaultCannedConfigID?: string;
  otherReportID?: string;
}

const textFieldDefaultMaxLength = 200;

export const getAvailableOptionByID = (
  id: string,
  config: AvailableExportConfig
) => {
  const foundOpt = getAvailableOptionByIDInOptions(
    id,
    config.globalOptions ?? []
  );
  if (foundOpt) {
    return foundOpt;
  }

  if (config.sections) {
    for (let i = 0; i < config.sections.length; i++) {
      const section = config.sections[i];
      const foundOpt = getAvailableOptionByIDInOptions(
        id,
        section.options ?? []
      );
      if (foundOpt) {
        return foundOpt;
      }
    }
  }

  return undefined;
};

const getAvailableOptionByIDInOptions = (
  id: string,
  options: AvailableOption[]
): AvailableOption | undefined => {
  for (let i = 0; i < options.length; i++) {
    const opt = options[i];
    if (opt.id === id) {
      return opt;
    }

    if (opt.optionSectionOpts?.children?.length) {
      const foundOpt = getAvailableOptionByIDInOptions(
        id,
        opt.optionSectionOpts.children
      );
      if (foundOpt) {
        return foundOpt;
      }
    }
  }

  return undefined;
};

export const getOptionByID = (id: string, config: ConfiguredExportConfig) => {
  const val = config.globalOptions?.[id];
  if (val) {
    return val;
  }

  for (let i = 0; i < config.sections.length; i++) {
    const val = config.sections[i].options?.[id];
    if (val) {
      return val;
    }
  }

  return undefined;
};

export const getValueBoolByID = (
  id: string,
  config: ConfiguredExportConfig
) => {
  const val = getOptionByID(id, config);

  return val?.valueBool ?? false;
};

export const isOptionHidden = (
  availableOption: AvailableOption,
  configuredExportConfig: ConfiguredExportConfig
) => {
  if (
    availableOption.conditionalOnIDs == undefined ||
    Object.values(availableOption.conditionalOnIDs).length == 0
  ) {
    return false;
  }

  return Object.entries(availableOption.conditionalOnIDs).some(
    ([conditionalOnID, conditionOnValue]) => {
      const dependentValue = getValueBoolByID(
        conditionalOnID,
        configuredExportConfig
      );

      return dependentValue !== conditionOnValue;
    }
  );
};

export const exportConfigRequiresVendorWithCompletedAssessment = (
  config: ConfiguredExportConfig | undefined
) => {
  if (!config) {
    return false;
  }
  return getValueBoolByID("base_on_risk_assessment", config);
};

export const exportConfigRequiresVendorWithCompletedManagedV3Assessment = (
  config: ConfiguredExportConfig | undefined
) => {
  if (!config) {
    return false;
  }
  return getValueBoolByID("base_on_managed_v3_risk_assessment", config);
};

export const exportConfigRequiresVendorWithCompletedSecurityProfileAssessment =
  (config: ConfiguredExportConfig | undefined) => {
    if (!config) {
      return false;
    }
    return getValueBoolByID("base_on_security_profile_risk_assessment", config);
  };

// getRequiredExtraStepsFromConfig collects a unique list of all the RequiredExtraSteps that
// are required based on enabled options and sections in an export config.
export const getRequiredExtraStepsFromConfig = (
  config: ConfiguredExportConfig,
  availableConfig: AvailableExportConfig
): RequiredExtraStep[] => {
  const requiredExtraSteps = new Set<RequiredExtraStep>();
  availableConfig.requiredExtraSteps?.forEach((step) =>
    requiredExtraSteps.add(step)
  );

  const processOptions = (opts: AvailableOption[]) => {
    opts.forEach((opt) => {
      if (isOptionHidden(opt, config)) {
        return;
      }

      if (opt.optionSectionOpts) {
        processOptions(opt.optionSectionOpts.children ?? []);
      } else if (opt.checkboxOpts || opt.radioOpts) {
        const enabled = getValueBoolByID(opt.id, config);
        if (enabled) {
          // Add any required steps as this option is enabled
          const theseRequiredExtraSteps =
            opt.checkboxOpts?.requiredExtraSteps ??
            opt.radioOpts?.requiredExtraSteps;

          theseRequiredExtraSteps?.forEach((step) =>
            requiredExtraSteps.add(step)
          );

          // For checkbox options, also process its children
          processOptions(opt.checkboxOpts?.children ?? []);
        }
      }
    });
  };

  // Process all global opts first
  processOptions(availableConfig.globalOptions ?? []);

  // Then run through all the sections
  availableConfig.sections?.forEach((sec) => {
    const configuredSection = config.sections.find((s) => s.id === sec.id);
    if (!configuredSection?.value) {
      // Ignore disabled sections
      return;
    }

    // Add any required steps for the section specifically
    sec.requiredExtraSteps?.forEach((step) => requiredExtraSteps.add(step));

    // And finally process any contained options
    processOptions(sec.options ?? []);
  });

  return Array.from(requiredExtraSteps);
};

// NOTE: the below logic is mirrored on the backend in export_config/export_config_validate.go.
// Ensure changes you make here are reflected on the backend validation too.

interface validateConfiguredExportConfigResult {
  globalOptsValid: boolean;
  globalOptsValidationErr?: string;
  sectionsValid: boolean;
  sectionsValidationErr?: string;
}

export const validateConfiguredExportConfig = (
  availableConfig: AvailableExportConfig,
  config: ConfiguredExportConfig
): validateConfiguredExportConfigResult => {
  const result: validateConfiguredExportConfigResult = {
    globalOptsValid: true,
    sectionsValid: true,
  };

  // First compare our global options
  const optsErr = compareOptions(availableConfig.globalOptions ?? [], config);
  if (optsErr) {
    result.globalOptsValid = false;
    result.globalOptsValidationErr = optsErr;
  }

  // Next run through the available sections
  const sectionsErr = validateSections(availableConfig.sections ?? [], config);
  if (sectionsErr) {
    result.sectionsValid = false;
    result.sectionsValidationErr = sectionsErr;
  }

  return result;
};

const validateSections = (
  sections: AvailableSection[],
  config: ConfiguredExportConfig
): string | undefined => {
  if (sections.length !== config.sections.length) {
    return `configured sections length does not match available sections length`;
  }

  if (sections.length === 0) {
    return;
  }

  if (!config.sections.find((s) => s.value)) {
    return `At least one section must be selected.`;
  }

  const getMovableSectionBounds = (index: number) => {
    let minIndex = index;
    let maxIndex = index;

    for (let i = index - 1; i >= 0; i--) {
      if (sections[i].movable) {
        minIndex = i;
      } else {
        break;
      }
    }

    for (let i = index + 1; i < sections.length; i++) {
      if (sections[i].movable) {
        maxIndex = i;
      } else {
        break;
      }
    }

    return [minIndex, maxIndex];
  };

  let oneRequiredApplicable = false;
  const oneRequiredApplicableSectionTitles: string[] = [];
  let oneRequiredSatisfied = false;

  for (let i = 0; i < sections.length; i++) {
    const availableSection = sections[i];
    let foundConfigSection;

    if (availableSection.movable) {
      // Find the min and max valid indexes for this section. Ensure it appears in the configured sections between those indexes
      const [minIndex, maxIndex] = getMovableSectionBounds(i);

      for (let j = minIndex; j <= maxIndex; j++) {
        if (config.sections[j].id === availableSection.id) {
          foundConfigSection = config.sections[j];
          break;
        }
      }

      if (!foundConfigSection) {
        return `movable section (${availableSection.id}) was not found at an expected index`;
      }
    } else {
      // Immovable sections must always retain the same index in the configured sections
      if (config.sections[i].id !== availableSection.id) {
        return `immovable section (${availableSection.id}) was not found at the expected index`;
      }

      foundConfigSection = config.sections[i];
    }

    if (
      availableSection.disabled &&
      availableSection.defaultSelected !== foundConfigSection.value
    ) {
      return `disabled section (${availableSection.id}) did not match expected selection`;
    }

    // Keep track of sections where at least one is required to be selected
    if (availableSection.oneRequired) {
      oneRequiredApplicable = true;
      oneRequiredApplicableSectionTitles.push(availableSection.title);
      if (foundConfigSection.value) {
        oneRequiredSatisfied = true;
      }
    }

    // Validate the options for this section, if it's selected
    if (foundConfigSection.value) {
      const err = compareOptions(availableSection.options ?? [], config);
      if (err) {
        return `Section "${availableSection.title}" is invalid: ${err}`;
      }
    }
  }

  if (oneRequiredApplicable && !oneRequiredSatisfied) {
    return `At least one section out of the following must be selected: ${oneRequiredApplicableSectionTitles.join(
      ", "
    )}`;
  }

  return undefined;
};

export const ErrNoRadioOptionsSelected = "no radio options were selected";

const compareOptions = (
  availableOpts: AvailableOption[],
  config: ConfiguredExportConfig
): string | undefined => {
  let oneRequiredApplicable = false;
  const oneRequiredOptionTitles: string[] = [];
  let oneRequiredSatisfied = false;
  let radioOptsApplicable = false;
  let radioOptsSatisfied = false;

  for (let i = 0; i < availableOpts.length; i++) {
    const availableOpt = availableOpts[i];

    const configuredOpt = getOptionByID(availableOpt.id, config);
    if (!configuredOpt) {
      return `option with ID ${availableOpt.id} not found`;
    }

    // Skip validation if this is a hidden option
    if (isOptionHidden(availableOpt, config)) {
      continue;
    }

    // Now do validation depending on the type of option
    if (availableOpt.optionSectionOpts) {
      // Just look at the section's children
      const err = compareOptions(
        availableOpt.optionSectionOpts.children ?? [],
        config
      );
      if (err) {
        return err;
      }
    } else if (availableOpt.checkboxOpts) {
      // If this option was disabled, ensure the selected value matches the default
      if (
        availableOpt.checkboxOpts.disabled &&
        availableOpt.checkboxOpts.defaultSelected !== configuredOpt.valueBool
      ) {
        return `disabled option (${availableOpt.id}) did not match default`;
      }

      if (availableOpt.checkboxOpts.oneRequired) {
        oneRequiredApplicable = true;
        oneRequiredOptionTitles.push(availableOpt.title);

        if (configuredOpt.valueBool) {
          oneRequiredSatisfied = true;
        }
      }

      // Dig in and check this option's children too, if this option is active
      if (configuredOpt.valueBool) {
        const err = compareOptions(
          availableOpt.checkboxOpts.children ?? [],
          config
        );
        if (err) {
          return err;
        }
      }
    } else if (availableOpt.radioOpts) {
      radioOptsApplicable = true;

      if (availableOpt.radioOpts.disabled && configuredOpt.valueBool) {
        return `disabled radio option (${availableOpt.id}) was selected`;
      }

      if (radioOptsSatisfied && configuredOpt.valueBool) {
        return `multiple radio options were selected`;
      }

      if (configuredOpt.valueBool) {
        radioOptsSatisfied = true;
      }
    } else if (availableOpt.textOpts) {
      if (
        availableOpt.textOpts.required &&
        configuredOpt.valueText.length === 0
      ) {
        return `Required field "${availableOpt.title}" is blank.`;
      }

      let maxLen = availableOpt.textOpts.maxLength;
      if (maxLen === 0) {
        maxLen = textFieldDefaultMaxLength;
      }

      if (configuredOpt.valueText.length > maxLen) {
        return `value of (${availableOpt.id}) exceeded max length`;
      }
    } else if (availableOpt.selectListOpts) {
      if (availableOpt.selectListOpts.items.length == 0) {
        return undefined;
      }
    } else {
      return `available option ${availableOpt.id} does not have a type`;
    }
  }

  if (oneRequiredApplicable && !oneRequiredSatisfied) {
    return `At least one out of the following options are required: ${oneRequiredOptionTitles.join(
      ", "
    )}`;
  }

  if (radioOptsApplicable && !radioOptsSatisfied) {
    return ErrNoRadioOptionsSelected;
  }

  return undefined;
};
