import { FC } from "react";
import { Doughnut as ReactDoughnut } from "react-chartjs-2";
import { ConvertHexToRGBA } from "../../_common/helpers";
import classnames from "classnames";
import "./Doughnut.scss";

export interface DoughnutDataPoint {
  label: string;
  count: number;
  hexColor: string;

  // Optional click handler for the data point
  onClick?: () => void;
}
interface DonutProps {
  // optional class name for the component
  className?: string;

  // label to be shown at the center of the donut chart together with the total
  totalLabel: string;

  // Data to use
  data: DoughnutDataPoint[];

  // onClick for the graph component (used primarily to navigate to a more detailed data view)
  onClick?: () => void;
}

const Doughnut: FC<DonutProps> = ({ className, totalLabel, data, onClick }) => {
  const total = data.reduce((sum, current) => sum + current.count, 0);

  const filteredData = data.filter((d) => d.count > 0);

  return (
    <div className={classnames("doughnut-chart-and-breakdown", className)}>
      <div className={"chart"} onClick={() => onClick?.()}>
        <ReactDoughnut
          data={{
            labels: filteredData.map((d) => d.label),
            datasets: [
              {
                label: "Permissions",
                data: filteredData.map((d) => d.count),
                backgroundColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
                borderColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
                hoverBackgroundColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
                hoverBorderColor: filteredData.map((d) =>
                  ConvertHexToRGBA(d.hexColor, 100)
                ),
              },
            ],
          }}
          options={{
            legend: { display: false },
            tooltips: { enabled: false },
            cutoutPercentage: 60,
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
        <div className={"total-permissions"}>
          <span className={"number"}>{total}</span>
          <span className={"label"}>{totalLabel}</span>
        </div>
      </div>
      <div className={"breakdown"}>
        {filteredData.map((d) => (
          <div key={d.label} onClick={() => d.onClick?.()}>
            <span
              className={"square"}
              style={{ backgroundColor: ConvertHexToRGBA(d.hexColor, 100) }}
            ></span>
            {d.label}: {d.count}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doughnut;
