import { useContext, useMemo, useState } from "react";
import Button from "../../../_common/components/core/Button";
import { NumberWithCommas } from "../../../_common/helpers";
import Icon from "../../../_common/components/core/Icon";
import ActionBar from "../../../_common/components/ActionBar";
import {
  Breach,
  BreachNotificationStatus,
  BreachType,
} from "../../../_common/types/emailExposures";
import BreachTypeIconMalware from "../../images/id-breach-icon-malware.svg";
import BreachTypeIconPaste from "../../images/id-breach-icon-paste.svg";
import "../../style/components/IdentityBreachesCard.scss";
import TabButtons from "../../../_common/components/TabButtons";
import SearchBox from "../../../_common/components/SearchBox";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import Card from "../../../_common/components/core/Card";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import classnames from "classnames";
import {
  useColumnSelector,
  usePagination,
  useSorting,
} from "../../../_common/hooks";
import PillLabel from "../PillLabel";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import LabelList from "../LabelList";
import {
  getBreachDomain,
  getSeverityIconForBreach,
  isDarkBreach,
} from "./EmailExposures";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import { sortBy as _sortBy } from "lodash";
import { setArchivedIdentityBreaches } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import { LabelColor } from "../../../_common/types/label";
import MultiSelectionButton from "../../../_common/components/MultiSelectionButton";
import { IUserMiniMap } from "../../../_common/types/user";
import UserAvatar from "../../../_common/components/UserAvatar";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import IdentityBreachesCardFilterPanel, {
  IdentityBreachesFilterContext,
  IdentityBreachesFilterInitialState,
} from "./IdentityBreachesCardFilterPanel";
import { dateMatchesFilter, isDateFilterActive } from "../filter/DateFilter";
import { FilterLabelButton } from "../filter/FilterLabel";
import { CircleImg } from "../../../_common/components/CircleImg";
import { TourHighlightFromPLGContent } from "../../../_common/components/TourHighlight";

type StatusFilter = "active" | "all" | "archived";

export const emailExposureSeverityNumberToLabelColor: Record<
  number,
  LabelColor
> = {
  1: LabelColor.Blue,
  2: LabelColor.Yellow,
  3: LabelColor.Orange,
  4: LabelColor.Red,
};

const getSortedDataClasses = (breach: Breach) =>
  _sortBy(
    breach.DataClasses || [],
    "Severity",
    (dc) => -1 * dc.Class.length
  ).reverse();

interface IIdentityBreachesProps {
  userHasWriteEmailExposuresPermission: boolean;
  breaches: Breach[];
  breachAssigneeSharedUsers?: IUserMiniMap;
  isLoading: boolean;
  onClickedBreachRow: (breach: Breach) => void;
  dispatch: DefaultThunkDispatch;
  reloadData: () => void;
  notificationStatuses: Record<
    number,
    { notificationStatus?: BreachNotificationStatus; notifiedAt?: string }
  >;
}

export const IdentityBreachesCard = ({
  userHasWriteEmailExposuresPermission,
  breaches,
  breachAssigneeSharedUsers,
  isLoading,
  onClickedBreachRow,
  dispatch,
  reloadData,
  notificationStatuses,
}: IIdentityBreachesProps): JSX.Element => {
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("active" as StatusFilter);
  const [selectedIDs, setSelectedIDs] = useState([] as number[]);
  const [archivingBreaches, setArchivingBreaches] = useState(false);
  const [unarchivingBreaches, setUnarchivingBreaches] = useState(false);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const { filterState, setFilterState } = useContext(
    IdentityBreachesFilterContext
  );

  const setArchivedBreaches = async (archived: boolean) => {
    if (archived) {
      setArchivingBreaches(true);
    } else {
      setUnarchivingBreaches(true);
    }

    try {
      await dispatch(setArchivedIdentityBreaches(selectedIDs, archived));
      dispatch(
        addDefaultSuccessAlert(
          "Breaches have been " + (archived ? "archived" : "marked active")
        )
      );
      setSelectedIDs([]);
      reloadData();
    } catch (e: any) {
      dispatch(
        addDefaultUnknownErrorAlert("An error occurred updating the breaches", [
          e.message,
        ])
      );
    }
    setArchivingBreaches(false);
    setUnarchivingBreaches(false);
  };

  const filteredBreaches = useMemo(() => {
    const filterText = searchText.toLowerCase().trim();
    const breachDateFilterActive = isDateFilterActive(filterState.breachDate);
    const publishDateFilterActive = isDateFilterActive(
      filterState.publishedDate
    );

    return breaches.filter((b) => {
      if (statusFilter === "active" && b.Archived) {
        return false;
      }

      if (statusFilter === "archived" && !b.Archived) {
        return false;
      }

      if (filterText && !b.Title.toLowerCase().includes(filterText)) {
        return false;
      }

      if (
        breachDateFilterActive &&
        (!b.BreachDate ||
          !dateMatchesFilter(b.BreachDate, filterState.breachDate))
      ) {
        return false;
      }

      if (
        publishDateFilterActive &&
        !dateMatchesFilter(b.PublishedDate, filterState.publishedDate)
      ) {
        return false;
      }

      if (
        filterState.dataClasses.length > 0 &&
        !b.DataClasses?.find((c) => filterState.dataClasses.includes(c.Class))
      ) {
        return false;
      }

      if (filterState.excludedSeverities.length > 0) {
        if (
          b.Archived ||
          isDarkBreach(b) ||
          filterState.excludedSeverities.includes(b.RiskScore + 1)
        ) {
          return false;
        }
      }

      if (
        filterState.numEmployees.lowerVal !== undefined &&
        b.OrgCount < filterState.numEmployees.lowerVal
      ) {
        return false;
      }
      if (
        filterState.numEmployees.upperVal !== undefined &&
        b.OrgCount > filterState.numEmployees.upperVal
      ) {
        return false;
      }
      if (
        filterState.numVIPs.lowerVal !== undefined &&
        b.VipCount < filterState.numVIPs.lowerVal
      ) {
        return false;
      }
      if (
        filterState.numVIPs.upperVal !== undefined &&
        b.VipCount > filterState.numVIPs.upperVal
      ) {
        return false;
      }

      if (filterState.darkWebOnly && !isDarkBreach(b)) {
        return false;
      }

      if (filterState.noDarkWeb && isDarkBreach(b)) {
        return false;
      }

      if (filterState.excludedBreachTypes.length > 0) {
        if (filterState.excludedBreachTypes.includes(b.BreachType)) {
          return false;
        }
      }

      return true;
    });
  }, [breaches, searchText, statusFilter, filterState]);

  const [activeBreachesSelected, archivedBreachesSelected] = useMemo(() => {
    const selectedBreaches = breaches.filter((b) => selectedIDs.includes(b.ID));
    const activeBreachesSelected =
      selectedBreaches.filter((b) => !b.Archived).length > 0;
    const archivedBreachesSelected =
      selectedBreaches.filter((b) => b.Archived).length > 0;

    return [activeBreachesSelected, archivedBreachesSelected];
  }, [breaches, selectedIDs]);

  const [sortedBreaches, sortedBy, onSortChange] = useSorting<
    Breach,
    | "risk_score"
    | "date_of_breach"
    | "date_published"
    | "details"
    | "num_involved"
    | "num_vips"
    | "notified_at"
    | "assignee"
    | "breach_type"
  >(filteredBreaches, "date_published", SortDirection.DESC, {
    risk_score: {
      orderFuncs: [
        (breach) =>
          breach.Archived ? -2 : isDarkBreach(breach) ? -1 : breach.RiskScore,
        (breach) => breach.Name,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    date_of_breach: {
      orderFuncs: [(breach) => breach.BreachDate || "unknown"],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    date_published: {
      orderFuncs: [(breach) => breach.PublishedDate],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    details: {
      orderFuncs: [(breach) => breach.Title],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    num_involved: {
      orderFuncs: [(breach) => breach.OrgCount, (breach) => breach.Name],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    num_vips: {
      orderFuncs: [(breach) => breach.VipCount, (breach) => breach.Name],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    notified_at: {
      orderFuncs: [
        (breach) => breach.NotifiedAt || "",
        (breach) => breach.Name,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    assignee: {
      orderFuncs: [
        (breach) =>
          breach.AssigneeUserID
            ? breachAssigneeSharedUsers?.[breach.AssigneeUserID]?.name ||
              breachAssigneeSharedUsers?.[breach.AssigneeUserID]?.email ||
              ""
            : "",
        (breach) => breach.Name,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    breach_type: {
      orderFuncs: [(breach) => breach.BreachType],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
  });

  const [currentPageItems, currentPage, totalPages, onPageChange] =
    usePagination(sortedBreaches, 30);

  const getNotificationState = (breach: Breach) => {
    // check for any active notification statuses
    const notificationStatus = notificationStatuses[breach.ID];
    let status = breach.NotificationStatus;
    let notifiedAt = breach.NotifiedAt;
    if (notificationStatus) {
      notifiedAt = notificationStatus.notifiedAt;
      status = notificationStatus.notificationStatus;
    }

    switch (status) {
      case "error":
        return {
          icon: (
            <div className="notified-icon not-notified">
              <Icon name="close" />
            </div>
          ),
          label: <span className="no-notification-label">Error</span>,
        };
      case "sending":
        return {
          icon: <LoadingIcon className="notification-sending-icon" />,
          label: "Sending",
        };
      case "sent":
        return {
          icon: null,
          label: <DateTimeFormat dateTime={notifiedAt} dateOnly />,
        };
    }
    return {
      icon: null,
      label: "-",
    };
  };

  const columnHeaders: IXTableColumnHeader[] = [
    { id: "risk_score", text: "Sev.", sortable: true, displayLocked: true },
    { id: "date_of_breach", text: "Date of breach", sortable: true },
    { id: "date_published", text: "Published date", sortable: true },
    { id: "breach_type", text: "Breach type", sortable: true },
    {
      id: "details",
      text: "Details",
      sortable: true,
      displayLocked: true,
    },
    { id: "data_classes", text: "Data exposed", sortable: false },
    {
      id: "num_involved",
      text: "Employees",
      sortable: true,
    },
    {
      id: "num_vips",
      text: "VIPs",
      sortable: true,
    },
    {
      id: "notified_at",
      text: "Notified?",
      sortable: true,
    },
    {
      id: "messages",
      text: "",
      displayLocked: true,
      sortable: false,
    },
    {
      id: "assignee",
      text: "Assignee",
      sortable: true,
      className: "shrink-cell",
      startingSortDir: SortDirection.ASC,
    },
  ];

  const [
    selectedHeaders,
    selectedHeaderIds,
    onSelectHeader,
    resetHeaders,
    filterCells,
  ] = useColumnSelector(columnHeaders);

  const getBreachRow = (breach: Breach, idx: number): IXTableRow => {
    const cells = [];
    const breachName = breach.Title;
    const notificationState = getNotificationState(breach);
    cells.push(
      <XTableCell key="risk_score" className="breach-severity">
        {breach.Archived && (
          <PillLabel className="archived-pill-label" color={LabelColor.Grey}>
            Archived
          </PillLabel>
        )}
        {!breach.Archived &&
          getSeverityIconForBreach(breach.RiskScore, isDarkBreach(breach))}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="date_of_breach" className="breach-date">
        {breach.BreachDate ? (
          <DateTimeFormat dateTime={breach.BreachDate} dateOnly />
        ) : (
          <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
        )}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="publish_date" className="publish-date">
        <DateTimeFormat dateTime={breach.PublishedDate} dateOnly />
      </XTableCell>
    );
    cells.push(
      <XTableCell key="breach_type" className="breach-type-label">
        {breach.BreachType}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="details" className="breach-domain">
        <TourHighlightFromPLGContent
          onboardingTaskId={"Checklist_BreachSight_Identity_Breaches"}
          highlightIndex={0}
          position={"top"}
          visible={idx === 0}
        >
          {breach.BreachType === BreachType.Company ? (
            <CompanyLogo name={breachName} domain={getBreachDomain(breach)} />
          ) : (
            <div className="breach-type-icon-container">
              <CircleImg
                imgSrc={
                  breach.BreachType === BreachType.Infostealer
                    ? BreachTypeIconMalware
                    : BreachTypeIconPaste
                }
                className="breach-type-icon"
              />
              <div className="breach-type-label">{breachName}</div>
            </div>
          )}
        </TourHighlightFromPLGContent>
      </XTableCell>
    );

    cells.push(
      <XTableCell key="data_classes" className="breach-data-classes">
        {breach.DataClasses && breach.DataClasses.length ? (
          <LabelList
            maxWidth={250}
            labels={getSortedDataClasses(breach).map((dc) => ({
              id: dc.Class,
              name: dc.Class,
              color: emailExposureSeverityNumberToLabelColor[dc.Severity],
            }))}
          />
        ) : (
          <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
        )}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="num_involved" className="num-involved">
        {NumberWithCommas(breach.OrgCount)}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="num_vips" className="num-vips">
        {NumberWithCommas(breach.VipCount)}
      </XTableCell>
    );
    cells.push(
      <XTableCell key="notified_at" className="notified-date">
        {notificationState.icon || <></>} {notificationState.label}
      </XTableCell>
    );

    cells.push(
      <XTableCell key="messages" className="shrink-cell">
        {breach.NumComments > 0 && (
          <div className="comments-icon-num">
            {breach.NumComments}
            <div className="cr-icon-chat-messages" />
          </div>
        )}
      </XTableCell>
    );

    const assigneeUser = breach.AssigneeUserID
      ? breachAssigneeSharedUsers?.[breach.AssigneeUserID]
      : undefined;
    cells.push(
      <XTableCell key="assignee" className="shrink-cell">
        {!!assigneeUser && (
          <SidePopupV2
            micro
            noWrap
            inline
            text={
              <>
                {assigneeUser.name}
                <br />
                <em>{assigneeUser.email}</em>
              </>
            }
          >
            <UserAvatar avatar={assigneeUser.avatar} name={assigneeUser.name} />
          </SidePopupV2>
        )}
      </XTableCell>
    );

    return {
      id: breach.ID,
      selected: selectedIDs.includes(breach.ID),
      className: classnames({ "archived-breach-row": breach.Archived }),
      cells: filterCells(cells),
      onClick: () => onClickedBreachRow(breach),
    };
  };

  let emptyState = <></>;
  if (filteredBreaches.length === 0) {
    if (searchText !== "" || filterState.filterActive) {
      emptyState = (
        <SearchEmptyCard
          searchItemText="breaches"
          clearText="Clear search and filter"
          onClear={() => {
            setSearchText("");
            setFilterState(IdentityBreachesFilterInitialState);
          }}
        />
      );
    } else {
      const emptyText =
        statusFilter === "archived"
          ? "There are no archived identity breaches."
          : "There are no active identity breaches.";

      let emptySubText =
        "When we detect an identity breach involving one of your domains it will appear here.";
      if (statusFilter === "archived") {
        emptySubText = userHasWriteEmailExposuresPermission
          ? "You have not archived any breaches yet. Breaches can be archived from the ‘Active’ tab."
          : "Archived identity breaches will appear here.";
      }

      emptyState = (
        <Card className="paged-table-card">
          <EmptyCardWithAction
            emptyText={emptyText}
            emptySubText={emptySubText}
          />
        </Card>
      );
    }
  }

  const title = isLoading
    ? "Identity breaches"
    : `Identity breaches (${filteredBreaches.length})`;

  const actionBarSubmitting = archivingBreaches || unarchivingBreaches;

  return (
    <>
      <div className="card-title-row">
        <div className="card-title">{title}</div>
      </div>
      <div className="search-row">
        <TabButtons
          tabs={[
            {
              id: "active",
              text: `Active`,
            },
            {
              id: "all",
              text: `All breaches`,
            },
            {
              id: "archived",
              text: `Archived`,
            },
          ]}
          activeTabId={statusFilter}
          onChangeTab={(tab) => {
            setStatusFilter(tab as StatusFilter);
            setSelectedIDs([]);
          }}
        />
        <SearchBox
          key={"breaches"}
          placeholder={"Search breaches"}
          value={searchText}
          onChanged={setSearchText}
          disabled={isLoading}
        />
        <FilterLabelButton
          onClick={() => setFilterPanelOpen(true)}
          isActive={filterState.filterActive}
        />
        <MultiSelectionButton
          primary={false}
          text={"Columns"}
          icon={"cr-icon-eye"}
          optionGroups={[
            columnHeaders
              .filter((c) => !c.displayLocked)
              .map((c) => ({
                label: c.text,
                value: c.id.toString(),
                selected: selectedHeaderIds.includes(c.id),
              })),
          ]}
          onSelectionChangedByValue={onSelectHeader}
          onResetDefault={resetHeaders}
        />
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && filteredBreaches.length === 0 && emptyState}
      {!isLoading && currentPageItems.length > 0 && (
        <XTable
          className={classnames("paged-table-card")}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          columnHeaders={selectedHeaders}
          rows={currentPageItems.map(getBreachRow)}
          stickyColumnHeaders
          pagination={{
            currentPage,
            totalPages,
            onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          selectable={userHasWriteEmailExposuresPermission}
          onSelectClick={(breachID) => {
            const newSelectedIDs = selectedIDs.includes(breachID as number)
              ? selectedIDs.filter((id) => id !== breachID)
              : [...selectedIDs, breachID as number];
            setSelectedIDs(newSelectedIDs);
          }}
          onSelectToggle={() => {
            // if some entries are un-selected then select all
            if (selectedIDs.length !== currentPageItems.length) {
              setSelectedIDs(currentPageItems.map((b) => b.ID));
            } else {
              setSelectedIDs([]);
            }
          }}
          onSelectAllClick={() =>
            setSelectedIDs(currentPageItems.map((b) => b.ID))
          }
          onSelectNoneClick={() => setSelectedIDs([])}
        />
      )}
      <ActionBar
        active={
          userHasWriteEmailExposuresPermission &&
          selectedIDs &&
          selectedIDs.length > 0
        }
      >
        <div className="selection-action-bar">
          <div className="action-bar-description">
            Select the breaches you wish to{" "}
            {statusFilter === "active" ? "archive" : "mark as active"}
          </div>
          <div className="action-bar-selection-counter">
            {NumberWithCommas(selectedIDs.length)}
            {selectedIDs.length === 1
              ? " breach selected"
              : " breaches selected"}
          </div>
          <div className="action-bar-buttons">
            <Button
              tertiary
              disabled={actionBarSubmitting}
              onClick={() => {
                setSelectedIDs([]);
              }}
            >
              Cancel
            </Button>
            {statusFilter !== "archived" && (
              <Button
                className="action-bar-submit"
                disabled={archivedBreachesSelected || actionBarSubmitting}
                loading={archivingBreaches}
                onClick={async () => await setArchivedBreaches(true)}
              >
                Archive breaches
              </Button>
            )}
            {statusFilter !== "active" && (
              <Button
                className="action-bar-submit"
                disabled={activeBreachesSelected || actionBarSubmitting}
                loading={unarchivingBreaches}
                onClick={async () => await setArchivedBreaches(false)}
              >
                Mark breaches active
              </Button>
            )}
          </div>
          <div
            className="action-bar-close"
            onClick={() => {
              setSelectedIDs([]);
            }}
          >
            <Icon name="x" />
          </div>
        </div>
      </ActionBar>
      <IdentityBreachesCardFilterPanel
        unfilteredBreaches={breaches}
        panelOpen={filterPanelOpen}
        onPanelClose={() => setFilterPanelOpen(false)}
      />
    </>
  );
};
