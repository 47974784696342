import { FC } from "react";
import classnames from "classnames";
import "../../style/components/navigation/ExpandableNavigationContainer.scss";
import IconButton from "../../components/IconButton";
import HeightAdjustableContainer from "./HeightAdjustableContainer";
import { SidePopupV2 } from "../DismissablePopup";

interface ExpandableNavigationContainer {
  className?: string;
  isOpen: boolean;
  onToggleNavigationOpen?: (isOpen: boolean) => void;
  children: React.ReactNode;
  disableFader?: boolean;
  fullContainerClickableWhenClosed?: boolean;
  expanderToolTip?: string;
}

const ExpandableNavigationContainer: FC<ExpandableNavigationContainer> = (
  props
) => {
  return (
    <div
      className={classnames(
        "expandable-navigation-container",
        props.className,
        {
          open: props.isOpen,
        },
        { clickable: !props.isOpen && props.fullContainerClickableWhenClosed }
      )}
      onClick={
        props.fullContainerClickableWhenClosed
          ? () => {
              if (props.onToggleNavigationOpen && !props.isOpen) {
                props.onToggleNavigationOpen(!props.isOpen);
              }
            }
          : undefined
      }
    >
      <HeightAdjustableContainer disableFader={props.disableFader}>
        {props.children}
      </HeightAdjustableContainer>
      <div
        className={classnames("navigation-bottom", {
          open: props.isOpen,
        })}
      >
        <SidePopupV2
          popupClassName={"navigation-expander-tooltip-popup"}
          text={props.expanderToolTip}
          position={"right"}
          noWrap={true}
          transitionTimeout={{
            appear: 250,
            enter: 0,
            exit: 0,
          }}
        >
          <IconButton
            className={"navigation-expander"}
            icon={
              <div
                className={classnames(
                  "cr-icon-expander",
                  "nav-expander-button",
                  {
                    "rotate-180": props.isOpen,
                  }
                )}
              />
            }
            onClick={() => {
              if (props.onToggleNavigationOpen) {
                props.onToggleNavigationOpen(!props.isOpen);
              }
            }}
          />
        </SidePopupV2>
      </div>
    </div>
  );
};

export default ExpandableNavigationContainer;
