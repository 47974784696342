import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useCallback, useState } from "react";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";
import VendorAssessmentAPI from "../../reducers/vendorAssessmentAPI";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import Button from "../../../_common/components/core/Button";
import "../../style/components/vendor_assessment/VendorAssessmentsV3VersionsSideBar.scss";

interface RenameAssessmentModalProps extends BaseModalProps {
  vendorId: number;
  assessmentId: number;
  currentName: string;
}

const RenameAssessmentModal: FC<RenameAssessmentModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const [name, nameValid, setName] = useTextWithValid(props.currentName, true);

  const [renameAssessment] = VendorAssessmentAPI.useRenameAssessmentMutation();
  const [loading, setLoading] = useState(false);
  const onRenameAssessment = useCallback(() => {
    setLoading(true);
    renameAssessment({
      vendorId: props.vendorId,
      assessmentId: props.assessmentId,
      name: name,
    })
      .unwrap()
      .then(() => {
        dispatch(addDefaultSuccessAlert("Successfully renamed assessment"));
        props.onClose();
      })
      .catch(() => {
        dispatch(addDefaultUnknownErrorAlert("Error renaming assessment"));
      })
      .finally(() => setLoading(false));
  }, [name, renameAssessment]);

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      headerContent={"Rename assessment"}
      footerContent={
        <div className={"buttons"}>
          <Button tertiary onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            primary
            onClick={onRenameAssessment}
            disabled={name == props.currentName || !nameValid}
            loading={loading}
          >
            Rename
          </Button>
        </div>
      }
    >
      <TextField
        value={name}
        onChanged={setName}
        minLength={2}
        maxLength={50}
        required
      />
    </ModalV2>
  );
};

export default RenameAssessmentModal;
