import { Component, FormEventHandler } from "react";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { validateEmail } from "../../../_common/helpers";

import {
  newOrg,
  fetchOrgs,
  fetchOrgsForParent,
} from "../../reducers/adminActions";
import DatePicker from "../../../_common/components/DatePicker";
import OrgConfig, {
  defaultFreeOrgConfig,
  defaultOrgConfig,
  UserLimitConfig,
  validateOrgConfig,
} from "../OrgConfig";

import "../../styles/NewOrganisationModal.scss";

import moment from "moment";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import {
  IOrgConfig,
  organisationAccountType,
  organisationUserLimitType,
} from "../../../_common/types/organisations";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
  clearMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { cloneDeep as _cloneDeep } from "lodash";

export const NewOrganisationModalName = "NewOrganisationModalName";
export function titleCase(str: string) {
  return str.replace(/^(.)(.*)/, function (_s, p1, p2) {
    return p1.toUpperCase() + p2;
  });
}

const TRIAL_LASTS_FOR_DAYS = 14;

interface INewOrganisationModalProps extends DefaultThunkDispatchProp {
  modalData: {
    parentID?: number;
    accountsFilter: {
      showEnabled: boolean;
      showDisabled: boolean;
      showAccountTypes: string[];
    };
    regionalBucketAliases: string[];
  };
}

interface INewOrganisationModalState {
  loading: boolean;
  selectedBucketAlias: string;
  accountType: string;
  accountPlan: string;
  trialExpiresOn?: string;
  regionalAliases: string[];
  isDistributor: boolean;
  mfaenabled: boolean;
  orgConfig: IOrgConfig;
}

/**
 * <NewOrganisationModal />
 * Modal form popped up from a vendor page to allow a user to resend an assessment to a new email address
 * within the same vendor.
 */
class NewOrganisationModal extends Component<
  INewOrganisationModalProps,
  INewOrganisationModalState
> {
  constructor(props: INewOrganisationModalProps) {
    super(props);

    const defaultTrialExpiresOn = new Date();
    defaultTrialExpiresOn.setDate(
      defaultTrialExpiresOn.getDate() + TRIAL_LASTS_FOR_DAYS
    );

    this.state = {
      loading: false,
      selectedBucketAlias: "default",
      accountType: organisationAccountType.trial,
      accountPlan: "",
      trialExpiresOn: this.formatDate(defaultTrialExpiresOn),
      regionalAliases: [],
      isDistributor: false,
      mfaenabled: false,
      orgConfig: _cloneDeep(defaultOrgConfig),
    };
  }

  name: HTMLInputElement | null = null;
  customerEmail: HTMLInputElement | null = null;

  formatDate = (date: string | Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  onChangeRegionalBucket = (bucketAlias: string) => {
    this.setState((state) => {
      const newState = {
        ...state,
        selectedBucketAlias: bucketAlias,
      };
      return newState;
    });
  };

  onOrgConfigChange = (orgConfig: IOrgConfig) => {
    this.setState({ orgConfig });
  };

  onSetTrial = () => {
    this.setState((state) => {
      let { trialExpiresOn } = state;
      if (trialExpiresOn == null) {
        const defaultTrialExpiresOn = new Date();
        defaultTrialExpiresOn.setDate(
          defaultTrialExpiresOn.getDate() + TRIAL_LASTS_FOR_DAYS
        );
        trialExpiresOn = this.formatDate(defaultTrialExpiresOn);
      }
      return {
        accountType: organisationAccountType.trial,
        accountPlan: "",
        trialExpiresOn: trialExpiresOn,
        isDistributor: false,
        // Reset the orgConfig if we're switching away from Free accounts
        orgConfig:
          state.accountType === organisationAccountType.free
            ? defaultOrgConfig
            : state.orgConfig,
      };
    });

    (
      document.getElementsByName("customerEmail")[0] as HTMLInputElement
    ).placeholder = "Email address (optional)";
  };

  onSetFree = () => {
    this.setState({
      accountType: organisationAccountType.free,
      accountPlan: "",
      isDistributor: false,
      orgConfig: defaultFreeOrgConfig,
    });
    (
      document.getElementsByName("customerEmail")[0] as HTMLInputElement
    ).placeholder = "Email address (optional)";
  };

  onSetDistributor = () => {
    this.setState((state) => ({
      accountType: organisationAccountType.distributor,
      accountPlan: "",
      isDistributor: true,
      // Reset the orgConfig if we're switching away from Free accounts
      orgConfig:
        state.accountType === organisationAccountType.free
          ? defaultOrgConfig
          : state.orgConfig,
    }));
    (
      document.getElementsByName("customerEmail")[0] as HTMLInputElement
    ).placeholder = "Email address (mandatory)";
  };
  onSetPaid = () => {
    this.setState((state) => ({
      accountType: organisationAccountType.paid,
      isDistributor: false,
      // Reset the orgConfig if we're switching away from Free accounts
      orgConfig:
        state.accountType === organisationAccountType.free
          ? defaultOrgConfig
          : state.orgConfig,
    }));
    (
      document.getElementsByName("customerEmail")[0] as HTMLInputElement
    ).placeholder = "Email address (optional)";
  };
  onSetPandemic = () => {
    // For pandemic accounts, we want to ensure Data Leaks and Fourth Party Risks are disabled by default
    let orgConfig = { ...this.state.orgConfig };
    if (this.state.accountType === organisationAccountType.free) {
      orgConfig = { ...defaultOrgConfig };
    }

    delete orgConfig.breachSight.dataExposures;
    delete orgConfig.breachSight.emailExposures;
    orgConfig.vendorRisk.vendorFourthParty.enabled = false;
    orgConfig.vendorRisk.riskAssessments.enabled = false;
    orgConfig.vendorRisk.numReports = 0;

    this.setState({
      accountType: organisationAccountType.paid,
      accountPlan: "pandemic",
      isDistributor: false,
      orgConfig,
    });
    (
      document.getElementsByName("customerEmail")[0] as HTMLInputElement
    ).placeholder = "Email address (optional)";
  };
  onSetTrialExpiresOn = (e: { target: { value: string } }) => {
    const dateVal = e.target.value;
    const expiry = dateVal ? this.formatDate(dateVal) : undefined;
    this.setState({
      trialExpiresOn: expiry,
    });
  };

  onToggleMFAEnabled = () => {
    this.setState((state) => {
      return {
        mfaenabled: !state.mfaenabled,
      };
    });
  };

  onChangeUserLimitType = (limitType: organisationUserLimitType) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        userLimitType: limitType,
      },
    }));

  onChangeUserLimit = (limit: number) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        userLimit: limit,
      },
    }));

  onSetInternalOnly = (isInternalOnly: boolean) =>
    this.setState(({ orgConfig }) => ({
      orgConfig: {
        ...orgConfig,
        isInternalOnly,
      },
    }));

  onSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    const name = this.name?.value ?? "";
    const customerEmail = this.customerEmail?.value.trim().toLowerCase();
    const mfaEnabled = this.state.mfaenabled;

    const { accountType } = this.state;
    let { trialExpiresOn } = this.state;

    if (
      accountType === organisationAccountType.distributor &&
      customerEmail === ""
    ) {
      this.props.dispatch(
        addSimpleErrorAlert(
          "An admin user must be entered for a distributor account",
          [],
          "validation-error"
        )
      );
      return;
    }

    // dont set a trial date unless the account type is correct
    if (accountType !== organisationAccountType.trial) {
      trialExpiresOn = undefined;
    }

    if (accountType === organisationAccountType.trial && !trialExpiresOn) {
      this.props.dispatch(
        addSimpleErrorAlert(
          "Trial accounts must have an expiry date defined",
          [],
          "validation-error"
        )
      );
      return;
    }

    if (!name) {
      this.props.dispatch(
        addSimpleErrorAlert(
          "One of the mandatory fields is empty",
          [],
          "validation-error"
        )
      );
      return;
    }

    if (customerEmail && !validateEmail(customerEmail)) {
      this.props.dispatch(
        addSimpleErrorAlert(
          "Must enter a valid email address for the customer",
          [],
          "validation-error"
        )
      );
      return;
    }

    if (!this.state.isDistributor) {
      const validation = validateOrgConfig(this.state.orgConfig);
      if (validation !== "") {
        this.props.dispatch(
          addSimpleErrorAlert(validation, [], "validation-error")
        );
        return;
      }
    }

    this.props.dispatch(clearMessageAlert("validation-error"));
    this.setState({ loading: true });
    let parentID: number | undefined = undefined;
    if (this.props.modalData && this.props.modalData.parentID) {
      parentID = this.props.modalData.parentID;
    }
    this.props
      .dispatch(
        newOrg(
          name,
          customerEmail,
          this.state.selectedBucketAlias,
          (parentID ?? 0) > 0,
          this.state.orgConfig,
          this.state.accountType,
          this.state.accountPlan,
          trialExpiresOn ? trialExpiresOn : null,
          mfaEnabled
        )
      )
      .then(() => {
        this.props.dispatch(
          parentID
            ? fetchOrgsForParent(
                this.props.modalData.accountsFilter.showEnabled,
                this.props.modalData.accountsFilter.showDisabled,
                this.props.modalData.accountsFilter.showAccountTypes
              )
            : fetchOrgs(
                this.props.modalData.accountsFilter.showEnabled,
                this.props.modalData.accountsFilter.showDisabled,
                this.props.modalData.accountsFilter.showAccountTypes
              )
        );
        this.props.dispatch(closeModal());
        this.props.dispatch(addDefaultSuccessAlert("Account created"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(err.message));
      });
  };

  render() {
    return (
      <div id="new-org-modal">
        <div className="modal-content">
          <h2>Create New Account</h2>
          <p />
          <form onSubmit={this.onSubmit}>
            <label htmlFor="name">Account Name</label>
            <input
              type="text"
              name="name"
              required
              ref={(ref) => (this.name = ref)}
            />
            <ColorCheckbox
              label="Internal only"
              className={"internal-check"}
              checked={this.state.orgConfig.isInternalOnly}
              onClick={() =>
                this.onSetInternalOnly(!this.state.orgConfig.isInternalOnly)
              }
            />

            <label htmlFor="accountType">Account Type</label>
            <div className="radiolist">
              <ColorCheckbox
                radio
                label="Demo/Trial"
                checked={
                  this.state.accountType === organisationAccountType.trial
                }
                onClick={this.onSetTrial}
              />
              <div className="spacer" />
              <ColorCheckbox
                radio
                label="Free"
                disabled
                checked={
                  this.state.accountType === organisationAccountType.free
                }
                onClick={this.onSetFree}
              />
              <div className="spacer" />
              <ColorCheckbox
                radio
                label="Paid"
                checked={
                  this.state.accountType === organisationAccountType.paid &&
                  this.state.accountPlan !== "pandemic"
                }
                onClick={this.onSetPaid}
              />
              <div className="spacer" />
              <ColorCheckbox
                radio
                label="Pandemic"
                checked={this.state.accountPlan === "pandemic"}
                onClick={this.onSetPandemic}
              />
              <div className="spacer" />
              <ColorCheckbox
                radio
                label="Distributor"
                checked={
                  this.state.accountType === organisationAccountType.distributor
                }
                onClick={this.onSetDistributor}
              />
              <div className="spacer" />
            </div>
            {this.state.accountType === organisationAccountType.trial && (
              <div>
                <label htmlFor="trialExpiresOn">Trial Expires On</label>
                <DatePicker
                  name="trialExpiresOn"
                  value={this.state.trialExpiresOn}
                  onChange={this.onSetTrialExpiresOn}
                />
                {this.state.trialExpiresOn &&
                  // @ts-ignore
                  this.state.trialExpiresOn < new Date() && (
                    <span className="red-text">NOTE: TRIAL HAS EXPIRED</span>
                  )}
                <div className="vertspacer" />
              </div>
            )}
            <div className="spacer" />

            {false && (
              <>
                <label htmlFor="mfaEnabled">Multi-factor Authentication</label>
                <div className={"radiolist"}>
                  <ColorCheckbox
                    label="MFA login enforced for all users (via Auth0 Guardian or Google Authenticator)"
                    checked={this.state.mfaenabled}
                    onClick={this.onToggleMFAEnabled}
                  />
                </div>
              </>
            )}

            <label htmlFor="customerEmail">Customer Admin</label>
            <input
              id="customerEmail"
              type="text"
              name="customerEmail"
              placeholder="Email address (optional)"
              ref={(ref) => (this.customerEmail = ref)}
            />

            {this.state.accountType &&
              this.state.accountType !==
                organisationAccountType.distributor && (
                <div className="paddedInputRow">
                  <label htmlFor="companyDomain">Company Domain</label>
                  <input
                    type="text"
                    name="companyDomain"
                    required
                    placeholder="companydomain.com"
                    value={this.state.orgConfig.mainHostname}
                    onChange={(e) => {
                      const val = e.target.value;
                      this.setState(({ orgConfig }) => {
                        return {
                          orgConfig: {
                            ...orgConfig,
                            mainHostname: val,
                          },
                        };
                      });
                    }}
                  />
                  {this.state.orgConfig.mainHostname && (
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <ColorCheckbox
                        label={
                          "Official Account of " +
                          this.state.orgConfig.mainHostname
                        }
                        checked={this.state.orgConfig.isVerifiedVendor}
                        onClick={() =>
                          this.setState((state) => {
                            return {
                              orgConfig: {
                                ...state.orgConfig,
                                isVerifiedVendor:
                                  !state.orgConfig.isVerifiedVendor,
                              },
                            };
                          })
                        }
                      />
                      {this.state.orgConfig.isVerifiedVendor && (
                        <div className="left-align">
                          {"Please double check that this is the official account for " +
                            this.state.orgConfig.mainHostname +
                            ". Any shared assessments published from this account will appear as though they are from " +
                            this.state.orgConfig.mainHostname +
                            ". There can only be one official account for a domain."}
                        </div>
                      )}
                    </div>
                  )}
                  <UserLimitConfig
                    userLimit={this.state.orgConfig.userLimit}
                    setUserLimit={this.onChangeUserLimit}
                    userLimitType={this.state.orgConfig.userLimitType}
                    setUserLimitType={this.onChangeUserLimitType}
                  />
                  <label htmlFor="productFeatures">
                    Configure Product Features
                  </label>
                  <OrgConfig
                    orgConfig={this.state.orgConfig}
                    onChange={this.onOrgConfigChange}
                    allowDistributor={!this.props.modalData.parentID}
                    isDistributor={this.state.isDistributor}
                    isFreeAccount={
                      this.state.accountType === organisationAccountType.free
                    }
                    isPaidAccount={
                      this.state.accountType === organisationAccountType.paid
                    }
                    enabled={true}
                  />
                  <label
                    htmlFor="breachSightCadence"
                    style={{ marginTop: "20px" }}
                  >
                    Regional File Storage
                  </label>
                  <div>
                    <div>
                      <ColorCheckbox
                        radio
                        label="Default (multi-region/us)"
                        onClick={() => this.onChangeRegionalBucket("default")}
                        checked={this.state.selectedBucketAlias === "default"}
                      />
                    </div>
                    {this.props.modalData.regionalBucketAliases.map((alias) => {
                      return (
                        <div key={"div_" + alias}>
                          <ColorCheckbox
                            radio
                            label={titleCase(alias)}
                            onClick={() => this.onChangeRegionalBucket(alias)}
                            checked={this.state.selectedBucketAlias === alias}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default NewOrganisationModal;
