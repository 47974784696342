import moment, { MomentInput } from "moment";

import "./ResultDate.scss";
import classnames from "classnames";

export interface ResultDateProps {
  date: string;
  className?: string;
}
export default function ResultDate({ date, className }: ResultDateProps) {
  return (
    <span className={classnames("tm-result-date", className)}>
      {moment(date).format("ll")}
      <span className="time">{moment(date).format("LT")}</span>
    </span>
  );
}

export function FeedDate({
  date,
  className,
}: {
  date: MomentInput;
  className?: string;
}) {
  return (
    <div className={classnames("tm-feed-datetime", className)}>
      <div className={"dpart"}>{moment(date).format("DD MMM, YYYY")}</div>
      <div className={"tpart"}>{moment(date).format("HH:mm")}</div>
    </div>
  );
}
