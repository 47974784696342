import DropdownV2, { DropdownItem } from "./core/DropdownV2";
import { FC } from "react";
import { TooltipButton } from "./core/Button";
import classnames from "classnames";
import "../style/components/DropdownButton.scss";

interface DropdownButtonProps {
  title: string;
  disable?: boolean;
  classname?: string;
  itemClassName?: string;
  dropdownPopup?: React.ReactNode;
  autoCloseOnMouseLeave?: boolean;

  items: DropdownItemContentProps[];
}

const DropdownButton: FC<DropdownButtonProps> = ({
  title,
  disable,
  dropdownPopup,
  autoCloseOnMouseLeave,
  classname,
  items,
}) => {
  const nonHiddenItems = items.filter((item) => !item.hide);
  if (nonHiddenItems.length === 0 && !disable) {
    return <></>;
  }

  return (
    <DropdownV2
      stopPropagation
      autoCloseOnMouseLeave={autoCloseOnMouseLeave}
      className={classnames("dropdown-button", classname)}
      popupItem={
        <TooltipButton
          primary
          disabled={disable}
          tooltipContent={dropdownPopup}
        >
          {title}
          <div className="cr-icon-chevron rot90" />
        </TooltipButton>
      }
    >
      {items.map((item) => (
        <DropdownItemContent
          key={item.headerText}
          {...item}
        ></DropdownItemContent>
      ))}
    </DropdownV2>
  );
};

export interface DropdownItemContentProps {
  headerText: string;
  description: string;
  hide?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const DropdownItemContent: FC<DropdownItemContentProps> = ({
  headerText,
  description,
  hide,
  onClick,
  disabled,
  className,
}) => {
  if (hide || !onClick) {
    return <></>;
  }

  return (
    <DropdownItem
      stopPropagation
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      <div
        className={classnames(
          "dropdown-button-dropdown-item-content",
          className
        )}
      >
        <div className={"header-text"}>{headerText}</div>
        <div className={"description"}>{description}</div>
      </div>
    </DropdownItem>
  );
};

export default DropdownButton;
