import { PageTitleSectionProps } from "../../../_common/components/pageHeader/PageTitleSection";
import UserBaseAPI from "../../api/userbase.api";
import { HeaderActionGroup } from "../../../_common/components/pageHeader/MainSection";
import PageHeaderV2, {
  PageHeaderV2Props,
} from "../../../_common/components/pageHeader/PageHeaderV2";
import { FC, ReactNode } from "react";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import {
  getPermissionCategoryDisplayInformation,
  getPermissionReadWriteDisplayInformation,
} from "../../helpers/scopes";

import "./UserBasePermissionHeader.scss";

interface UserBaseUserHeaderProps extends PageTitleSectionProps {
  scopeExternalId: string;
}

const UserBasePermissionHeader: FC<UserBaseUserHeaderProps> = ({
  scopeExternalId,
  ...restProps
}) => {
  const { data } = UserBaseAPI.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });

  const permission = data?.scope;
  const permissionName = permission?.description ?? "Permission Description";

  const metadataWidgets: ReactNode[] = [];
  const actionGroups: HeaderActionGroup[] = [];

  if (permission) {
    const permissionExposure = permission?.riskLevel ?? 3;
    const category = permission.category ?? "Category";
    const isReadWrite = permission.isReadWrite ?? true;

    const { displayString: scopeCategoryToDisplay, color: scopeCategoryColor } =
      getPermissionCategoryDisplayInformation(category);

    const {
      displayString: scopeReadWriteToDisplay,
      color: scopeReadWriteColor,
    } = getPermissionReadWriteDisplayInformation(isReadWrite);

    metadataWidgets.push(
      <>
        <div className={"metadata-header-text"}>Exposure level:</div>
        <ScopeRiskLevelDisplay riskLevel={permissionExposure} />
      </>,
      <>
        <div className={"metadata-header-text"}>Category:</div>
        <PillLabel color={scopeCategoryColor}>
          {scopeCategoryToDisplay}
        </PillLabel>
      </>,
      <>
        <PillLabel color={scopeReadWriteColor}>
          {scopeReadWriteToDisplay}
        </PillLabel>
      </>
    );
  }

  const pageHeaderV2Props: PageHeaderV2Props = {
    ...restProps,
    name: permissionName,
    className: "userbase-permission-header",
    actionGroups,
    metadataWidgets,
  };

  return (
    <>
      <PageHeaderV2 {...pageHeaderV2Props} />
    </>
  );
};

export default UserBasePermissionHeader;
