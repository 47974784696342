import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  conditionalRefreshActivityStreamForOrgUser,
  fetchSharedAssessmentsList,
} from "../../_common/reducers/commonActions";
import {
  fetchCustomerVendorsData,
  fetchVendorSummaryAndCloudscans,
} from "./cyberRiskActions";
import { DefaultAction } from "../../_common/types/redux";
import { IVerifiedVendorAccessItemNda } from "../types/sharedAssessment";
import {
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
} from "./reportsActions";
import { fetchVendorPortfolioRiskProfile } from "./vendorRiskPortfolio.actions";
import { clearDomains } from "./domains.actions";
import { fetchVendorRiskWaivers } from "./vendorRiskWaiver.actions";
import { Evidence } from "../types/evidence";
import { BadgeInfo } from "../types/scoreBadge";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../verifiedvendors/api/trustpage.api";

// Update an orgs shared profiles settings (actioned by a shared profile admin)
export const updateSharedAssessmentSharingSettings = (
  published?: boolean,
  publicAccess?: boolean,
  requireNda?: boolean,
  ndaText?: string
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      const opts = {} as any;
      if (published !== undefined) {
        opts.published = published;
      }
      if (publicAccess !== undefined) {
        opts.public_access = publicAccess;
      }
      if (requireNda !== undefined) {
        opts.require_nda = requireNda;
      }
      if (ndaText !== undefined && ndaText.length > 0) {
        opts.nda_text = ndaText;
      }

      await FetchCyberRiskUrl(
        "verifiedvendor/accesslevel/v1/",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating shared assessment settings", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
    );
  };
};

// Revoke an existing signed NDA OR an NDA override (actioned by a shared profile admin)
export const revokeVerifiedVendorAccessNda = (
  vendorId: number,
  verifiedVendorAccessId: number,
  ndaId?: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      const opts = {
        linked_vendor_id: vendorId,
        verified_vendor_access_id: verifiedVendorAccessId,
        nda_id: ndaId,
      };

      await FetchCyberRiskUrl(
        "verifiedvendor/ndas/v1/",
        opts,
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error revoking nda", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
    );
  };
};

// Add a manually approved NDA override to an existing access record (actioned by a shared profile admin)
export const manuallyApproveVerifiedVendorAccessNda = (
  vendorId: number,
  verifiedVendorAccessId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      const opts = {
        linked_vendor_id: vendorId,
        verified_vendor_access_id: verifiedVendorAccessId,
      };

      await FetchCyberRiskUrl(
        "verifiedvendor/ndas/v1/",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error manually approving nda", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
    );
  };
};

export interface SignVerifiedVendorNdaV1Response {
  signedNda: IVerifiedVendorAccessItemNda;
  requiresRequestApproval: boolean;
}

// Sign a specific nda version for the current user, with an optional message if also
// requesting access to a non-public profile
export const signVerifiedVendorNda = (
  vendorId: number,
  ndaId: number,
  message?: string
): DefaultAction<SignVerifiedVendorNdaV1Response> => {
  return async (dispatch, getState) => {
    let resp: SignVerifiedVendorNdaV1Response;
    try {
      const opts = {
        linked_vendor_id: vendorId,
        nda_id: ndaId,
        message: message,
      };

      resp = await FetchCyberRiskUrl<SignVerifiedVendorNdaV1Response>(
        "verifiedvendor/ndas/v1/",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error signing nda", e);
      console.error(e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // Fetch the list again in the background
    dispatch(fetchSharedAssessmentsList(true));

    // Re-get the access level, and wait for the response as we may need to still request access
    dispatch(TrustPageAPI.util.invalidateTags([TrustPageTagTypes.trustPage]));

    return resp;
  };
};

// Update an existing access request (for the current user)
export const updateAccessRequest = (
  vendorId: number,
  message?: string
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      const opts = {
        vendor_id: vendorId,
        message: message,
      };

      await FetchCyberRiskUrl(
        "verifiedvendor/requestaccess/v1/",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating access request message", e);
      console.error(e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // Fetch the list again in the background
    dispatch(fetchSharedAssessmentsList(true));

    // Re-get the access level, and wait for the response as we may need to still request access
    dispatch(
      TrustPageAPI.util.invalidateTags([
        {
          type: TrustPageTagTypes.trustPage,
          id: vendorId,
        },
      ])
    );
  };
};

export const revokeVerifiedVendorUserAccess = (
  accessId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/users/v1/",
        { access_id: accessId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error revoking user access", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
    );
  };
};

export const denyVerifiedVendorUserAccessRequest = (
  id: number,
  reason: string
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/accessrequests/v1/",
        { id: id, reason },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error denying user access", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPageAccess])
    );
  };
};

export const acceptPublicRiskWaiver = (
  vendorId: number,
  publicWaiverId: number
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "publicwaivers/accept/v1/",
        {
          datastore_vendor_id: vendorId,
          public_waiver_id: publicWaiverId,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error accepting public waiver", e);

      throw e;
    }

    await Promise.all([dispatch(fetchVendorRiskWaivers(vendorId, true))]);

    dispatch(conditionalRefreshActivityStreamForOrgUser());
    dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
    dispatch(fetchVendorRiskOverviewReport(true));
    dispatch(fetchVendorRiskBreakdownReport(true));
    dispatch(fetchVendorPortfolioRiskProfile());
    dispatch(fetchCustomerVendorsData(true));
    dispatch(clearDomains());
  };
};

export const ignorePublicRiskWaiver = (
  vendorId: number,
  publicWaiverId: number,
  ignore: boolean
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "publicwaivers/ignore/v1/",
        {
          datastore_vendor_id: vendorId,
          public_waiver_id: publicWaiverId,
          ignore: ignore,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error ignoring public waiver", e);

      throw e;
    }

    await dispatch(fetchVendorRiskWaivers(vendorId, true));
    dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const updateVerifiedVendorEvidence = (
  evidencePages: Evidence[]
): DefaultAction => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "sharedassessment/evidence_pages/v1",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            evidencePages,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating verified vendor evidence", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(
      TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
    );
  };
};

export const getScoreBadgeInfo = (): DefaultAction<BadgeInfo[]> => {
  return async (dispatch, getState) => {
    let resp: BadgeInfo[];
    try {
      resp = await FetchCyberRiskUrl<BadgeInfo[]>(
        "myscorebadge/v1/",
        {},
        {
          method: "GET",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting score badge info", e);
      throw e;
    }

    return resp;
  };
};
