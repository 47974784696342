import { useEffect, useState } from "react";
import { DefaultCannedExportConfig } from "../../../../_common/types/exportConfig";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../_common/types/reduxHooks";
import { fetchCannedExportConfigs } from "../../../reducers/cannedExports.actions";

// Load a DefaultCannedExportConfig for the given defaultID
// Useful for generating reports directly from a canned config in conjunction with ReportsLibraryModal
const useDefaultCannedExportConfig = (
  defaultID: string
): DefaultCannedExportConfig | undefined => {
  const dispatch = useAppDispatch();

  const [defaultCannedExportConfig, setDefaultCannedExportConfig] = useState<
    DefaultCannedExportConfig | undefined
  >(undefined);

  // Fetch canned export configs
  useEffect(() => {
    dispatch(fetchCannedExportConfigs(true));
  }, [dispatch]);

  // Retrieve the list of canned export configs from the Redux store
  const defaultCannedExportConfigs = useAppSelector(
    (state) => state.cyberRisk.cannedExportConfigs?.default
  );

  // Find the specific canned config by defaultID
  useEffect(() => {
    if (defaultCannedExportConfigs) {
      const config = defaultCannedExportConfigs.find(
        (config: DefaultCannedExportConfig) => config.defaultID === defaultID
      );

      setDefaultCannedExportConfig(config);
    }
  }, [defaultCannedExportConfigs, defaultID]);

  return defaultCannedExportConfig;
};

export default useDefaultCannedExportConfig;
