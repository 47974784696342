import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { ConfiguredExportConfig } from "../../../_common/types/exportConfig";
import { FC, useCallback, useState } from "react";
import Button from "../../../_common/components/core/Button";

import "../../style/components/reporting/CreateTemplateAfterGenerateModal.scss";
import {
  createCannedExportConfig,
  fetchCannedExportConfigs,
  updateCannedExportConfig,
} from "../../reducers/cannedExports.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  BadgeImageID,
  CustomImageRef,
  ReportTypeBadgeStyles,
} from "./ReportTypeBadge";
import TextField from "../../../_common/components/TextField";
import {
  getDefaultTemplateDescription,
  getNextUntitledTemplateName,
} from "../../views/reporting/ReportTemplateCreate";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { trackEvent } from "../../../_common/tracking";

interface ICreateTemplateAfterGenerateModalProps extends BaseModalProps {
  baseDefaultID?: string;
  baseUUID?: string;
  onDone: () => void;
  baseBadgeImageID: BadgeImageID;
  baseTemplateName: string;
  baseTemplateDescription: string;
  userCanEditCustomTemplate: boolean;
  config: ConfiguredExportConfig;
}

type stepID = "ask_to_create" | "set_name";

const CreateTemplateAfterGenerateModal: FC<
  ICreateTemplateAfterGenerateModalProps
> = ({
  active,
  onClose,
  onDone,
  baseDefaultID,
  baseUUID,
  baseTemplateName,
  baseBadgeImageID,
  baseTemplateDescription,
  userCanEditCustomTemplate,
  config,
}) => {
  const customCannedExportConfigs = useAppSelector(
    (state) => state.cyberRisk.cannedExportConfigs?.custom
  );

  const [templateName, setTemplateName] = useState(() =>
    getNextUntitledTemplateName(customCannedExportConfigs ?? [])
  );
  const [templateDesc, setTemplateDesc] = useState(() =>
    getDefaultTemplateDescription(baseTemplateName)
  );
  const [currentStepID, setCurrentStepID] = useState<stepID>("ask_to_create");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onClickCancel = useCallback(() => {
    onClose();
    onDone();
  }, [onClose, onDone]);

  const onClickUpdateExisting = useCallback(async () => {
    setLoading(true);

    try {
      await dispatch(
        updateCannedExportConfig({
          uuid: baseUUID ?? "",
          name: baseTemplateName,
          description: baseTemplateDescription,
          config,
        })
      );
      await dispatch(fetchCannedExportConfigs(true));
      dispatch(
        addDefaultSuccessAlert(
          `Report template "${baseTemplateName}" was successfully updated.`
        )
      );
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(addDefaultUnknownErrorAlert("Error updating template"));
      return;
    }

    onClose();
    onDone();
  }, [
    dispatch,
    onClose,
    onDone,
    baseUUID,
    baseTemplateName,
    baseTemplateDescription,
    config,
  ]);

  const onClickSaveNew = useCallback(async () => {
    setLoading(true);

    try {
      await dispatch(
        createCannedExportConfig({
          baseDefaultID: baseDefaultID,
          baseUUID: baseUUID,
          name: templateName,
          description: templateDesc,
          config,
        })
      );
      await dispatch(fetchCannedExportConfigs(true));
      dispatch(
        addDefaultSuccessAlert(
          `Report template "${templateName}" was successfully created.`
        )
      );
      trackEvent("CreateCustomReport");
    } catch (e) {
      console.error(e);
      setLoading(false);
      dispatch(addDefaultUnknownErrorAlert("Error updating template"));
      return;
    }

    onClose();
    onDone();
  }, [
    dispatch,
    onClose,
    onDone,
    baseDefaultID,
    baseUUID,
    templateName,
    templateDesc,
    config,
  ]);

  const badgeRef = CustomImageRef(
    baseBadgeImageID ? baseBadgeImageID : BadgeImageID.Custom
  );
  if (currentStepID === "ask_to_create") {
    return (
      <ModalV2
        active={active}
        onClose={onClose}
        disallowClose
        className="create-template-after-generate-modal"
        headerContent="Your report is on its way."
        footerClassName="create-template-after-generate-modal-footer"
        footerContent={
          <>
            <div className="btn-group">
              <Button tertiary onClick={onClickCancel}>
                No, just take me to my report
              </Button>
            </div>
            <div className="btn-group">
              {!!baseUUID && userCanEditCustomTemplate && (
                <Button loading={loading} onClick={onClickUpdateExisting}>
                  Update existing
                </Button>
              )}
              <Button
                primary
                arrow
                onClick={() => setCurrentStepID("set_name")}
              >
                Save new
              </Button>
            </div>
          </>
        }
      >
        <p>
          It looks like you made some edits to the{" "}
          <strong>{baseTemplateName}</strong> report template. We can save this
          updated version of the template so you can easily generate it from the
          Reports Library later on.
        </p>
        <p>Would you like to save this as a template?</p>
      </ModalV2>
    );
  } else {
    return (
      <ModalV2
        active={active}
        onClose={onClose}
        disallowClose
        className="create-template-after-generate-modal"
        headerContent="Custom report name"
        footerClassName="create-template-after-generate-modal-footer"
        footerContent={
          <>
            <div className="btn-group">
              <Button
                tertiary
                onClick={() => setCurrentStepID("ask_to_create")}
              >
                Back
              </Button>
            </div>
            <div className="btn-group">
              <Button
                primary
                loading={loading}
                onClick={onClickSaveNew}
                disabled={!templateName || !templateDesc}
              >
                Save new template
              </Button>
            </div>
          </>
        }
      >
        <div className="edit-name-step">
          <p>
            Name your custom report and add a brief description to help other
            people in your organization know when to use it. The name and
            description will appear alongside other reports in your
            organization’s Report Library.
          </p>
          <ReportTypeBadgeStyles>
            <div className="lhs">
              <div className="module">Custom Report</div>
              <TextField
                value={templateName}
                onChanged={(v) => setTemplateName(v)}
                maxLength={50}
              />
              <TextField
                multiLine
                value={templateDesc}
                onChanged={(v) => setTemplateDesc(v)}
                maxLength={200}
              />
            </div>
            <div className={`rhs ${badgeRef.className}`}>
              <img alt="Custom report thumbnail" src={badgeRef.image} />
            </div>
          </ReportTypeBadgeStyles>
        </div>
      </ModalV2>
    );
  }
};

export default CreateTemplateAfterGenerateModal;
