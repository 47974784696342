import { useHistory } from "react-router-dom";
import { locationState, useDefaultLocation } from "../types/router";
import { useMemo } from "react";
import { SidePopupV2 } from "./DismissablePopup";
import "../style/components/HistoryBackButton.scss";
import { LocationDescriptor } from "history";

export type HistoryBackButtonProps = {
  //backAction?: () => void;
  backText?: string;
  fallbackLocation?: LocationDescriptor<locationState>;
};

export function HistoryBackButton<
  LocationState extends locationState = locationState,
>({ backText, fallbackLocation }: HistoryBackButtonProps) {
  const history = useHistory();
  const location = useDefaultLocation<LocationState>();

  const backAction = useMemo(() => {
    // goBack flag is set, pop history
    if (location.state?.backContext?.goBack) {
      return history.goBack;
    }

    // backContext is set, go back by pushing the backContext route
    if (location.state?.backContext) {
      return () =>
        history.push(
          location.state.backContext?.backTo ?? backText ?? "",
          location.state.backContext?.backToContext
        );
    }

    // No state is set, we probably came here directly
    // go back to the fallback location if set
    if (fallbackLocation) {
      return () => history.push(fallbackLocation);
    }

    return undefined;
  }, [history, location.state?.backContext, backText]);

  //const backTextToShow = (backText ?? identity)(
  //location.state?.backContext?.backToText ?? undefined
  //);

  return (
    <div className="history-back-button">
      <SidePopupV2
        text={location.state?.backContext?.backToText ?? backText ?? "Back"}
        className="back-btn-popup"
        position={"right"}
        noWrap
      >
        <div className="back-btn" onClick={backAction} />
      </SidePopupV2>
    </div>
  );
}
