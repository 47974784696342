import { FC, useMemo, useState } from "react";

import {
  ISortedBy,
  IXTableColumnHeader,
  SortDirection,
} from "../../_common/components/core/XTable";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import {
  isIPAddress,
  NumberWithCommas,
  pluralise,
} from "../../_common/helpers";
import "../style/components/WebscanTreeView.scss";
import { scanHostname } from "../reducers/cyberRiskActions";
import LabelList from "./LabelList";
import PillLabel from "./PillLabel";
import ReportCard from "../../_common/components/ReportCard";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import {
  ILabel,
  LabelClassification,
  LabelColor,
} from "../../_common/types/label";
import { setLabelsForWebsites } from "../reducers/domainLabels.actions";
import { Domain } from "../../_common/types/domains";
import TreeTableWindowed, { NodeCell, TreeNode } from "./TreeTableWindowed";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import IconButton, { HoverColor } from "../../_common/components/IconButton";
import DomainIPActionBar from "./DomainIPActionBar";
import EllipsizedText from "../../_common/components/EllipsizedText";
import DomainSourceLabelList from "./domains/DomainSourceLabelList";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import domainsApi, { domainsSortBy } from "../reducers/domains.api";
import { getFiltersFromState } from "../reducers/filters.actions";
import { useManagedOrgID } from "../../_common/hooks";
import {
  OrgAccessDomainPortfolios,
  OrgMultiProductNavOptOut,
  useBasicPermissions,
  UserBreachsightWrite,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import { useOnRemoveCustomerCloudscan } from "./WebscansTableCardV2";
import memoize from "memoize-one";
import moment from "moment";
import { isUndefined as _isUndefined } from "lodash";
import "../style/components/WebscanTreeView.scss";

interface IWebscanTreeViewOwnProps {
  primaryHostname?: string;
  onOpenCloudscanPanel: (hostname: string) => void;
  filterText: string;
  dataIsHistorical?: boolean;
  // GT: note - this is a combination of BS and VR perms depending on context. it's calculated in the parent
  userHasWriteVendorInfoPerm?: boolean;
  isSubsidiary: boolean;
  disableLabelAdd?: boolean;
  userHasWritePermsInDomainPortfolios?: number[]; // Only include this if relevant, ie. this is a customer view.

  // Optional vendor id for labelling websites
  vendorId?: number;
  onFilterClear: () => void;
  activeOnly: boolean;
  inactiveOnly: boolean;
}

export interface IDomainWithChildren extends Domain {
  children: IDomainWithChildren[] | string[];
  isTopLevel: boolean;
}

// parses a list of scans, and returns an array of domains
// with subdomain scans nested below. For example:
// [
//   {
//     hostname: "upguard.com",
//
//     ...,
//
//     children: [
//       {
//         hostname: "cyber-risk.upguard.com",
//         children: [],
//       },
//     ],
//   },
//   {
//     hostname: "scriptrock.com",
//
//     ...,
//
//     children: [],
//   },
// ];
export const domainListAsTree = (
  originalHostsMap: { [hostname: string]: IDomainWithChildren },
  _: string[],
  filterText: string
) => {
  const hostsMap: { [hostname: string]: IDomainWithChildren } = {};

  // Next, run through each of the hosts and add any children, if they exist
  for (const host in originalHostsMap) {
    if (!originalHostsMap.hasOwnProperty(host)) {
      continue;
    }

    if (!hostsMap[host]) {
      hostsMap[host] = {
        ...originalHostsMap[host],
        children: [],
      };
    }

    // Break off pieces of the hostname until we find one in the map
    let truncatedHost = host;
    let hasParent = false;

    // IP addresses should always be considered top level
    if (!isIPAddress(host)) {
      while (true) {
        const dotIdx = truncatedHost.indexOf(".");
        if (dotIdx === -1) {
          break;
        }

        truncatedHost = truncatedHost.slice(dotIdx + 1);

        if (originalHostsMap[truncatedHost]) {
          if (!hostsMap[truncatedHost]) {
            hostsMap[truncatedHost] = {
              ...originalHostsMap[truncatedHost],
              children: [],
            };
          }

          const hostchildren = hostsMap[truncatedHost].children as string[];
          hostchildren.push(host);
          hasParent = true;
          break;
        }
      }
    }

    if (!hasParent) {
      hostsMap[host].isTopLevel = true;
    }
  }

  // findChildrenRecursive takes a list of hostnames and
  // recursively finds their scans and own children
  const findChildrenRecursive = (hostnames: string[]) => {
    const scans = [];
    for (let i = 0; i < hostnames.length; i++) {
      const scan = hostsMap[hostnames[i]];
      if (!scan) {
        continue;
      }

      if (scan.children.length > 0) {
        scan.children = findChildrenRecursive(scan.children as string[]);
      }

      const shouldBeFilteredOut =
        filterText && hostnames[i].indexOf(filterText) === -1;

      if (scan.children.length === 0 && shouldBeFilteredOut) {
        // if we don't have any children after the filtering, and this should be filtered out, get outta here
        continue;
      }

      scan.hostname = hostnames[i];

      scans.push(scan);
    }

    return scans;
  };

  // Now lets run through each of the top level domains and recursively find their children, and add to the final array
  const treeScans: IDomainWithChildren[] = [];
  for (const host in hostsMap) {
    if (!hostsMap.hasOwnProperty(host)) {
      continue;
    }

    const scan = hostsMap[host];
    if (!scan || !scan.isTopLevel) {
      continue;
    }

    const children = findChildrenRecursive(scan.children as string[]);
    if (
      children.length === 0 &&
      filterText &&
      host.indexOf(filterText) === -1
    ) {
      continue;
    }

    treeScans.push({
      ...scan,
      children,
    });
  }

  return treeScans;
};
// Memoised function so we only recompute everything if the data changes
export const domainListAsTreeMemoised = memoize(
  domainListAsTree,
  (newArgs, oldArgs) => {
    // if the filter has changed, this needs to recompute
    if (
      newArgs[2] !== oldArgs[2] ||
      newArgs[3] !== oldArgs[3] ||
      newArgs[4] !== oldArgs[4]
    ) {
      return false;
    }

    if (_isUndefined(newArgs[1]) && _isUndefined(oldArgs[1])) {
      return true;
    }

    if (_isUndefined(newArgs[1]) || _isUndefined(oldArgs[1])) {
      return false;
    }

    // Next we need to compare the string arrays
    if (newArgs[1].length !== oldArgs[1].length) {
      return false;
    }

    for (let i = 0; i < newArgs[1].length; i++) {
      if (newArgs[1][i] !== oldArgs[1][i]) {
        return false;
      }
    }

    return true;
  }
);
export const getSortedHostsTree = (
  hostsMap: { [hostname: string]: IDomainWithChildren },
  allHostnames: string[],
  filterText: string,
  primaryHostname?: string,
  sortedBy?: ISortedBy
) => {
  const hostsTree = [
    ...domainListAsTreeMemoised(hostsMap, allHostnames, filterText),
  ];

  return sortHostTree(hostsTree, hostsMap, primaryHostname, sortedBy);
};
const sortHostTree = (
  hostsTree: IDomainWithChildren[],
  hostsMap: { [hostname: string]: IDomainWithChildren },
  primaryHostname?: string,
  sortedBy?: ISortedBy
) => {
  switch ((sortedBy ?? {}).columnId) {
    case "url":
      // sort the hosts alphabetically, bringing the primary hostname to the top
      hostsTree.sort((a, b) => a.hostname.localeCompare(b.hostname));
      break;
    case "score":
      hostsTree.sort((a, b) => {
        const aHost = hostsMap[a.hostname] || { score: 0 };
        const bHost = hostsMap[b.hostname] || { score: 0 };
        const aScore = !aHost.enabled ? 0 : aHost.score ?? 0;
        const bScore = !bHost.enabled ? 0 : bHost.score ?? 0;

        return aScore - bScore;
      });
      break;
    case "date_scanned":
      hostsTree.sort((a, b) => {
        const aHost = hostsMap[a.hostname] || {};
        const bHost = hostsMap[b.hostname] || {};
        const aScanned = !aHost.enabled ? 0 : aHost.scanned_at;
        const bScanned = !bHost.enabled ? 0 : bHost.scanned_at;
        return moment(aScanned).isBefore(bScanned) ? -1 : 1;
      });
      break;
    default:
      // sort the hosts alphabetically, bringing the primary hostname to the top
      hostsTree.sort((a, b) => {
        if (a.hostname === primaryHostname) {
          return -1;
        }
        if (b.hostname === primaryHostname) {
          return 1;
        }

        return a.hostname.localeCompare(b.hostname);
      });
      break;
  }

  if (sortedBy && sortedBy.direction !== "asc") {
    hostsTree.reverse();
  }

  // Sort subdomains recursively
  hostsTree.forEach((host) => {
    if (host.children && host.children.length > 0) {
      sortHostTree(
        host.children as IDomainWithChildren[],
        hostsMap,
        primaryHostname,
        sortedBy
      );
    }
  });

  return hostsTree;
};
export const getChildrenRowIds = (
  parentId: string | number,
  hostsTree: IDomainWithChildren[],
  filterByPortfolioIds?: number[],
  field: "hostname" | "hostnameID" = "hostname"
): (string | number)[] => {
  const childrenIds: (string | number)[] = [];

  const getChildrenFromParent = (
    host: IDomainWithChildren,
    isTarget: boolean
  ) => {
    const children = host.children as IDomainWithChildren[];
    children.forEach((ch) => {
      if (
        isTarget &&
        (!filterByPortfolioIds ||
          filterByPortfolioIds.length === 0 ||
          ch.portfolios?.find((p) => filterByPortfolioIds?.includes(p.id)))
      ) {
        childrenIds.push(ch[field]);
      }

      if (ch.children) {
        getChildrenFromParent(ch, isTarget || ch[field] === parentId);
      }
    });
  };

  hostsTree.forEach((h) => {
    getChildrenFromParent(h, h[field] === parentId);
  });

  return childrenIds;
};
// V2 implementation of tree view as functional component and using domainsV2 API
const WebscanTreeViewV2: FC<IWebscanTreeViewOwnProps> = ({
  dataIsHistorical = false,
  disableLabelAdd,
  filterText,
  isSubsidiary,
  onFilterClear,
  onOpenCloudscanPanel,
  primaryHostname = "",
  userHasWritePermsInDomainPortfolios,
  userHasWriteVendorInfoPerm,
  vendorId,
  activeOnly,
  inactiveOnly,
}) => {
  const dispatch = useAppDispatch();
  const permissions = useBasicPermissions();
  const showDomainPortfolios =
    !vendorId &&
    !isSubsidiary &&
    !!permissions.orgPermissions[OrgAccessDomainPortfolios];

  const hasMultiProductNavigation =
    !permissions.orgPermissions[OrgMultiProductNavOptOut];

  const [sortedBy, setSortedBy] = useState<{
    columnId: domainsSortBy;
    direction: SortDirection;
  }>({
    columnId: "url",
    direction: SortDirection.ASC,
  });

  const [selectedHostnames, setSelectedHostnames] = useState([] as string[]);

  const onSortChange = (columnId: domainsSortBy, direction: SortDirection) => {
    setSortedBy({ columnId, direction });
  };

  const [
    _removingCustomerCloudscan,
    confirmationModal,
    onRemoveCustomerCloudscan,
  ] = useOnRemoveCustomerCloudscan(vendorId);

  const rescanHost = (hostname: string) => {
    dispatch(scanHostname(hostname, !!vendorId && !isSubsidiary, null));
  };

  const updateDomainLabels = (
    items: Domain[],
    _: ILabel[],
    addedLabelIds: number[],
    removedLabelIds: number[]
  ): Promise<void> =>
    dispatch(
      setLabelsForWebsites(
        items.map((i) => i.hostname),
        addedLabelIds,
        removedLabelIds,
        vendorId,
        isSubsidiary
      )
    );

  const getUpdateLabelsModalHeader = (items: Domain[]) => {
    if (!items || items.length === 0) {
      return undefined;
    } else if (items.length === 1) {
      return `Update labels for ${items[0].hostname}`;
    } else {
      return `Update labels for ${items.length} domains`;
    }
  };

  const selectClicked = (hostnames: string[], selected: boolean) => {
    setSelectedHostnames((prev) => {
      if (selected) {
        return [...prev, ...hostnames];
      } else {
        return prev.filter((h) => hostnames.indexOf(h) === -1);
      }
    });
  };

  const filters = useAppSelector((state) =>
    getFiltersFromState(state, vendorId, isSubsidiary)
  );
  const webscansData = useAppSelector((state) => state.cyberRisk.webscans);
  const isManagedVendorAnalyst = !!useManagedOrgID();
  const userHasWriteBreachSight =
    !!permissions.userPermissions[UserBreachsightWrite];
  const userHasVendorRiskWrite =
    !!permissions.userPermissions[UserVendorRiskWrite];

  const { data: activeDomains, isLoading: activeLoading } =
    domainsApi.useGetDomainsQuery({
      search_string: "", // filter client-side only in tree view
      vendor_id: vendorId,
      is_customer: !vendorId && !isSubsidiary,
      is_subsidiary: isSubsidiary,
      website_label_ids: filters.websiteLabelIds,
      website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: filters.websiteIncludeUnlabeled,
      website_portfolio_ids:
        !vendorId && !isSubsidiary ? filters.domainPortfolioIds : undefined,
      risk_ids: filters.riskIds,
      appguard_cloud_providers: filters.cloudConnectionProviderTypes,
      appguard_cloud_connection_uuids: filters.cloudConnectionUUIDs,
      active: true,
    });

  const { data: inactiveDomains, isLoading: inactiveLoading } =
    domainsApi.useGetDomainsQuery({
      search_string: "", // filter client-side only in tree view
      vendor_id: vendorId,
      is_customer: !vendorId && !isSubsidiary,
      is_subsidiary: isSubsidiary,
      website_label_ids: filters.websiteLabelIds,
      website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: filters.websiteIncludeUnlabeled,
      website_portfolio_ids:
        !vendorId && !isSubsidiary ? filters.domainPortfolioIds : undefined,
      risk_ids: filters.riskIds,
      appguard_cloud_providers: filters.cloudConnectionProviderTypes,
      appguard_cloud_connection_uuids: filters.cloudConnectionUUIDs,
      active: false,
    });

  const loading = activeOnly
    ? activeLoading
    : inactiveOnly
      ? inactiveLoading
      : activeLoading || inactiveLoading;

  const domainsToUse = useMemo(() => {
    if (activeOnly && activeDomains) {
      return activeDomains.domains;
    } else if (inactiveOnly && inactiveDomains) {
      return inactiveDomains.domains;
    } else if (!activeOnly && !inactiveOnly) {
      return [
        ...(activeDomains?.domains ?? []),
        ...(inactiveDomains?.domains ?? []),
      ];
    }

    return [];
  }, [activeOnly, inactiveOnly, activeDomains, inactiveDomains]);

  const allHostnames: string[] = [];
  const hostsMap =
    domainsToUse.reduce(
      (obj: { [hostname: string]: IDomainWithChildren }, scan) => {
        allHostnames.push(scan.hostname);
        obj[scan.hostname] = scan as IDomainWithChildren;
        return obj;
      },
      {}
    ) ?? {};

  allHostnames.sort((a, b) => a.localeCompare(b));

  const hostsTree = getSortedHostsTree(
    hostsMap,
    allHostnames,
    filterText,
    primaryHostname,
    sortedBy
  );

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "url",
      text: "Domain",
      sortable: true,
      startingSortDir: SortDirection.ASC,
    },
    { id: "score", text: "Score", sortable: true, className: "score-header" },
    {
      id: "date_scanned",
      text: dataIsHistorical ? "Scanned on" : "Most recent scan",
      sortable: true,
      className: "date-scanned-header",
    },
    ...(showDomainPortfolios
      ? [
          {
            id: "portfolio",
            text: "Portfolio",
            sortable: false,
            className: "portfolio-header",
          },
        ]
      : []),
    {
      id: "labels",
      text: "Labels",
      sortable: false,
      className: "labels-header",
    },
    {
      id: "icons",
      text: "",
      sortable: false,
      className: "icons-header",
    },
    {
      id: "options-icons",
      text: "",
      sortable: false,
      className: "options-icons-header",
    },
  ];

  const generateTreeNodes = (
    nodes: TreeNode[],
    hostname: string,
    children: IDomainWithChildren[]
  ) => {
    // First construct the node for the host
    const scan = hostsMap[hostname] || {};
    const isPrimaryHostname = primaryHostname === hostname;
    const unscanned = !scan.enabled || (!scan.score && !scan.scanned_at);
    const isRescanning = !!(
      webscansData[hostname] && webscansData[hostname]?.rescanning
    );
    const selected = selectedHostnames.indexOf(hostname) !== -1;
    const selectionDisabled =
      userHasWritePermsInDomainPortfolios &&
      userHasWritePermsInDomainPortfolios.length > 0 &&
      !scan.portfolios?.find(
        (p) => userHasWritePermsInDomainPortfolios?.includes(p.id)
      );

    const iconOptions = [];

    if (scan.removable && userHasWriteVendorInfoPerm) {
      iconOptions.push(
        <IconButton
          key="remove"
          icon={<div className="cr-icon-trash" />}
          hoverText="Remove"
          hoverColor={HoverColor.Red}
          onClick={() => onRemoveCustomerCloudscan(hostname)}
        />
      );
    }

    if (unscanned) {
      iconOptions.push(
        <IconButton
          key="rescan"
          icon={<div className="cr-icon-redo" />}
          hoverText="Rescan"
          disabled={isRescanning}
          onClick={() => rescanHost(hostname)}
        />
      );
    }

    const { sources } = scan;

    const labels = (scan.labels || []).map((label: ILabel) => ({
      id: label.id,
      name: label.name,
      color: label.colour,
      bordered: true,
      removeable: false,
    }));

    const node: TreeNode = {
      id: hostname,
      className: unscanned ? "inactive" : "",
      children: [],
      onClick: !unscanned ? () => onOpenCloudscanPanel(hostname) : undefined,
      selected,
      selectionDisabled,
      selectionDisabledHelpText: selectionDisabled
        ? `You have read-only access to this domain's ${
            scan.portfolios?.length === 1 ? "portfolio" : "portfolios"
          }.`
        : undefined,
      mainCellContent: (
        <div className="url-cell">
          <div className="url">
            <EllipsizedText
              text={scan.hostname}
              popupClassName="ellipsized-treeview-url"
            >
              <div>{scan.hostname}</div>
            </EllipsizedText>
            {children.length > 0 && (
              <span className="num-subdomains">
                {NumberWithCommas(children.length)}{" "}
                {pluralise(children.length, "subdomain", "subdomains")}
              </span>
            )}
          </div>
          {isPrimaryHostname && (
            <PillLabel color={LabelColor.Blue} bordered>
              Primary domain
            </PillLabel>
          )}
          {!!unscanned && (
            <PillLabel color={LabelColor.Grey} bordered>
              Inactive
            </PillLabel>
          )}
          <DomainSourceLabelList sources={sources} />
        </div>
      ),
      otherCells: (
        <>
          <NodeCell className="score-cell">
            {!unscanned && (
              <div className="grade-with-score">
                {scan.score !== undefined && scan.score >= 0 && (
                  <>
                    <ColorGrade
                      size={ColorGradeSize.Small}
                      score={scan.score}
                    />
                    {scan.score}
                  </>
                )}
              </div>
            )}
          </NodeCell>
          <NodeCell className="date-scanned-cell">
            {!unscanned && (
              <span className="caps-time">
                <DateTimeFormat dateTime={scan.scanned_at} dateOnly />
              </span>
            )}
          </NodeCell>
          {showDomainPortfolios && (
            <NodeCell className="portfolio-cell">
              {scan.portfolios?.map((p) => p.name).join(", ")}
            </NodeCell>
          )}
          <NodeCell className="labels-cell">
            <LabelList
              labels={labels}
              maxWidth={300}
              onLabelsUpdated={
                !disableLabelAdd &&
                userHasWriteVendorInfoPerm &&
                !isManagedVendorAnalyst
                  ? (availableLabels, addedLabelIds, removedLabelIds) =>
                      updateDomainLabels(
                        [scan],
                        availableLabels,
                        addedLabelIds,
                        removedLabelIds
                      )
                  : undefined
              }
              updateLabelsHeader={getUpdateLabelsModalHeader([scan])}
              classification={LabelClassification.WebsiteLabel}
            />
          </NodeCell>
          <NodeCell className="icons-cell">
            {isRescanning && <LoadingIcon />}
          </NodeCell>
          <NodeCell className="options-icons-cell">{iconOptions}</NodeCell>
        </>
      ),
    };

    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        generateTreeNodes(
          node.children,
          children[i].hostname,
          children[i].children as IDomainWithChildren[]
        );
      }
    }

    nodes.push(node);
  };

  const selectedItems = activeDomains?.domains
    ? activeDomains?.domains.filter(
        (d) => selectedHostnames.indexOf(d.hostname) !== -1
      )
    : [];

  const nodesTree: TreeNode[] = [];
  for (let i = 0; i < hostsTree.length; i++) {
    generateTreeNodes(
      nodesTree,
      hostsTree[i].hostname,
      hostsTree[i].children as IDomainWithChildren[]
    );
  }

  const canEdit = !vendorId
    ? userHasWriteBreachSight ||
      (userHasWritePermsInDomainPortfolios &&
        userHasWritePermsInDomainPortfolios.length > 0)
    : userHasVendorRiskWrite;

  return (
    <div className="webscan-tree-view">
      <ReportCard>
        {loading ? (
          <LoadingBanner />
        ) : nodesTree.length === 0 ? (
          <SearchEmptyCard searchItemText="domains" onClear={onFilterClear} />
        ) : (
          <TreeTableWindowed
            nodes={nodesTree}
            columnHeaders={columnHeaders}
            sortedBy={sortedBy}
            onSortChange={(columnId, newSortDir) =>
              onSortChange(columnId as domainsSortBy, newSortDir)
            }
            onSelectClick={(hostname, selected) =>
              selectClicked([hostname as string], selected)
            }
            childrenSelection={
              canEdit
                ? {
                    onSelectChildrenClick: (parentRowId, selected) => {
                      const childrenIds = getChildrenRowIds(
                        parentRowId,
                        hostsTree,
                        userHasWritePermsInDomainPortfolios
                      );
                      selectClicked(childrenIds as string[], selected);
                    },
                    selectAllText: "Select all subdomains",
                    selectNoneText: "Unselect all subdomains",
                  }
                : undefined
            }
            hasMultiProductNavigation={hasMultiProductNavigation}
          />
        )}
      </ReportCard>
      <DomainIPActionBar
        vendorId={vendorId}
        isSubsidiary={isSubsidiary}
        selectedDomains={selectedItems}
        onCancel={() => setSelectedHostnames([])}
        onDeselectAll={() => setSelectedHostnames([])}
      />
      {confirmationModal}
    </div>
  );
};

export default WebscanTreeViewV2;
