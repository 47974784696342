import { useState, useRef, useEffect } from "react";
import { SimpleSearchBox } from "../../_common/components/SearchBox";
import Button from "../../_common/components/core/Button";
import {
  DisplayableThreatMonitoringResult,
  ThreatMonitoringSourceType,
  getChatEntries,
} from "../api/types";
import { useArray, useParseSnippet } from "../funcs/useParseSnippet";
import "./ThreatDetailPreview.scss";
import { ThreadEntry, ThreadEntryType } from "./Thread";

interface ThreatPreviewProps {
  threat: DisplayableThreatMonitoringResult;
}

export function ThreatDetailPreview({ threat }: ThreatPreviewProps) {
  let previewHiddenText: string | undefined = undefined;
  if (threat.source === ThreatMonitoringSourceType.StealerLogs) {
    previewHiddenText =
      "Preview not available for threats originating from stealer logs.";
  }

  if (previewHiddenText) {
    return (
      <div className="threat-preview-control">
        <div className="search"></div>
        <div className="snippet-hidden">{previewHiddenText}</div>
      </div>
    );
  }

  return <SnippetPreview threat={threat} />;
}

export function SnippetPreview({ threat }: ThreatPreviewProps) {
  if (threat.additionalAttributes?.chat) {
    return (
      <ChatPreview
        threat={threat}
        entries={getChatEntries(threat.additionalAttributes?.chat)}
      />
    );
  }

  if (threat.additionalAttributes?.post) {
    return (
      <PostPreview
        threat={threat}
        snippet={threat.additionalAttributes.post.text}
      />
    );
  }

  return <PostPreview threat={threat} snippet={threat.snippet} />;
}

export function ChatPreview({
  threat,
  entries,
}: ThreatPreviewProps & { entries: ThreadEntryType[] }) {
  const [customKeyword, setCustomKeyword] = useState<string | null>(null);
  const keyword = customKeyword ? customKeyword : threat.keyword;

  const [currentHighlight, setCurrentHighlight] = useState<number>(-1);

  const [highlights, pushHighlight, _] = useArray<HTMLElement>();

  const snippetParent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlights.length <= 0) {
      setCurrentHighlight(-1);
      return;
    }

    if (highlights.length > 0 && currentHighlight < 0) {
      setCurrentHighlight(0);
      return;
    }

    if (
      snippetParent.current &&
      highlights.length > 0 &&
      highlights[currentHighlight]
    ) {
      snippetParent.current.scrollTop =
        highlights[currentHighlight].offsetTop -
        snippetParent.current.offsetTop -
        40;
    }
  }, [snippetParent, highlights, currentHighlight]);

  const next = () => {
    setCurrentHighlight((c) => (c + 1) % highlights.length);
  };
  const prev = () => {
    setCurrentHighlight((c) => (c - 1 + highlights.length) % highlights.length);
  };

  const snippetText = JSON.stringify(entries, null, 2);

  return (
    <PreviewShell
      keyword={keyword}
      customKeyword={customKeyword}
      setCustomKeyword={setCustomKeyword}
      currentHighlight={currentHighlight + 1}
      totalHighlights={highlights.length}
      prev={prev}
      next={next}
      snippetText={snippetText}
    >
      <div className="snippet-preview thread" ref={snippetParent}>
        {entries.map((entry, i) => (
          <ThreadEntry
            key={i}
            entry={entry}
            highlight={keyword}
            pushHighlight={pushHighlight}
          />
        ))}
      </div>
    </PreviewShell>
  );
}

interface PostPreviewProps {
  threat: DisplayableThreatMonitoringResult;
  snippet: string;
}
export function PostPreview({ threat, snippet }: PostPreviewProps) {
  const [customKeyword, setCustomKeyword] = useState<string | null>(null);
  const [currentHighlight, setCurrentHighlight] = useState<number>(-1);

  const keyword = customKeyword ? customKeyword : threat.keyword;

  const [lines, highlights] = useParseSnippet(snippet, keyword);
  const snippetParent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlights.length <= 0) {
      setCurrentHighlight(-1);
      return;
    }

    if (highlights.length > 0 && currentHighlight < 0) {
      setCurrentHighlight(0);
      return;
    }

    if (
      snippetParent.current &&
      highlights.length > 0 &&
      highlights[currentHighlight]
    ) {
      snippetParent.current.scrollTop =
        highlights[currentHighlight].offsetTop -
        snippetParent.current.offsetTop -
        40;
    }
  }, [snippetParent, highlights, currentHighlight]);

  const next = () => {
    setCurrentHighlight((c) => (c + 1) % highlights.length);
  };
  const prev = () => {
    setCurrentHighlight((c) => (c - 1 + highlights.length) % highlights.length);
  };

  return (
    <PreviewShell
      keyword={keyword}
      customKeyword={customKeyword}
      setCustomKeyword={setCustomKeyword}
      currentHighlight={currentHighlight + 1}
      totalHighlights={highlights.length}
      prev={prev}
      next={next}
      snippetText={snippet}
    >
      <div className="snippet-preview snippet" ref={snippetParent}>
        {lines.map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </div>
    </PreviewShell>
  );
}

interface PreviewShellProps {
  keyword: string;
  customKeyword: string | null;
  setCustomKeyword: (keyword: string | null) => void;
  currentHighlight: number;
  totalHighlights: number;
  prev: () => void;
  next: () => void;
  children: React.ReactNode;
  snippetText: string;
}
function PreviewShell({
  keyword,
  customKeyword,
  setCustomKeyword,
  currentHighlight,
  totalHighlights,
  prev,
  next,
  children,
  snippetText,
}: PreviewShellProps) {
  const downloadSnippet = async () => {
    try {
      const objectURL = URL.createObjectURL(
        new Blob([snippetText], {
          type: "text/plain",
        })
      );

      const a = document.createElement("a");
      a.href = objectURL;
      a.download = `snippet.txt`;

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(objectURL);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };

      a.addEventListener("click", clickHandler);
      a.click();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="threat-preview-control">
      <div className="search">
        <div className="left">
          <SimpleSearchBox
            value={keyword}
            onChanged={setCustomKeyword}
            onClear={() => setCustomKeyword(null)}
            showClear={!!customKeyword}
          />
          <div className="highlight-controls">
            <div className="counts">
              {currentHighlight}/{totalHighlights}
            </div>

            <div className="arrow" onClick={prev}>
              <div className={"cr-icon-chevron rot270"} />
            </div>
            <div className="arrow" onClick={next}>
              <div className={"cr-icon-chevron rot90"} />
            </div>
          </div>
        </div>
        <div className="right">
          <Button tertiary onClick={downloadSnippet}>
            <div className={"cr-icon-export-thin"} />
            Download
          </Button>
        </div>
      </div>

      {children}
    </div>
  );
}
