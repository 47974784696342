import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { FC, useEffect, useState } from "react";
import UserBaseAPI from "../../api/userbase.api";
import "./MonitoringRulesModal.scss";
import { MonitoringRuleType, Rule } from "../../api/types";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { SelectV2 } from "../../../_common/components/SelectV2";
import TextField, {
  MaxLengthType,
} from "../../../_common/components/TextField";
import { produce } from "immer";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import {
  NumberWithCommas,
  pluralise,
  validateEmail,
} from "../../../_common/helpers";
import { v4 as uuidv4 } from "uuid";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import { useDebounce } from "../../../_common/hooks";
import classnames from "classnames";

const monitoringRuleTypeText = (ruleType: MonitoringRuleType) => {
  switch (ruleType) {
    case MonitoringRuleType.EmailContainsRuleType:
      return "Email containing";
    case MonitoringRuleType.EmailMatchesRuleType:
      return "Specific email address";
    case MonitoringRuleType.EmailPrefixRuleType:
      return "Email prefix";
    case MonitoringRuleType.EmailSuffixRuleType:
      return "Email suffix";
  }
};

const monitoringRuleTypeDescription = (ruleType: MonitoringRuleType) => {
  switch (ruleType) {
    case MonitoringRuleType.EmailContainsRuleType:
      return "Do not monitor users with the email containing:";
    case MonitoringRuleType.EmailMatchesRuleType:
      return "Do not monitor users with the email:";
    case MonitoringRuleType.EmailPrefixRuleType:
      return "Do not monitor users whose email begins with:";
    case MonitoringRuleType.EmailSuffixRuleType:
      return "Do not monitor users whose email ends with:";
  }
};

interface MonitoringRule {
  uuid: string;
  type?: MonitoringRuleType;
  value?: string;
}

// the user can enter partial email addresses for a rule
// and these partial email addresses could contain
// a partial local part, a partial domain part or a partial
// of both. For that reason we can't validate the value
// using normal email validation rules so we limit ourselves
// to checking that there are no invalid characters.
const emailAllowedCharacters = /^[a-zA-Z0-9_.±*%-@]{1,64}$/i;

const isValidMonitoringRule = (rule: MonitoringRule) => {
  if (!rule.type || !rule.value) return false;
  switch (rule.type) {
    case MonitoringRuleType.EmailMatchesRuleType:
      return validateEmail(rule.value);
    case MonitoringRuleType.EmailSuffixRuleType:
    case MonitoringRuleType.EmailContainsRuleType:
    case MonitoringRuleType.EmailPrefixRuleType:
      return emailAllowedCharacters.test(rule.value);
  }
};

interface MonitoringRuleProp {
  index: number;
  rule: MonitoringRule;
  onRuleChange: (newRule: MonitoringRule) => void;
  onDeleteRule: () => void;
  duplicateRule: boolean;
}

const MonitoringRule: FC<MonitoringRuleProp> = ({
  index,
  rule,
  onRuleChange,
  onDeleteRule,
  duplicateRule = false,
}) => {
  let invalidValueError: string | undefined = undefined;
  if (rule.type && rule.value && !isValidMonitoringRule(rule)) {
    invalidValueError =
      rule.type === MonitoringRuleType.EmailMatchesRuleType
        ? "Invalid email address"
        : "Invalid value";
  }

  const typeSelectorError = !rule.type;

  return (
    <div className={"monitoring-rule-wrapper"}>
      <div className={"monitoring-rule"}>
        <div className={"rule-index"}>{index + 1}.</div>
        <div className={"rule-definition"}>
          <div className={"rule-type"}>
            <div className={"header"}>Exclude by:</div>
            <SelectV2
              className={classnames({ error: typeSelectorError })}
              isSearchable={false}
              value={
                rule.type
                  ? {
                      value: rule.type,
                      label: monitoringRuleTypeText(rule.type),
                    }
                  : undefined
              }
              onChange={(selectedOption) => {
                onRuleChange({
                  ...rule,
                  type: selectedOption?.value,
                });
              }}
              options={Object.values(MonitoringRuleType).map((rt) => ({
                value: rt,
                label: monitoringRuleTypeText(rt),
              }))}
              controlShouldRenderValue={true}
            />
            {typeSelectorError && (
              <div className={"error-text"}>
                <i className={"icon-info"} />A condition is required
              </div>
            )}
          </div>
          <div className={"rule-value"}>
            {rule.type && (
              <>
                <div className={"header"}>
                  {monitoringRuleTypeDescription(rule.type)}
                </div>
                <TextField
                  value={rule.value ?? ""}
                  onChanged={(val) => onRuleChange({ ...rule, value: val })}
                  placeholder="Enter value"
                  maxLength={MaxLengthType.reasonableLength}
                  required={true}
                  type={"text"}
                  renderErrorsOnStart={true}
                  requiredErrorText={
                    rule.type === MonitoringRuleType.EmailMatchesRuleType
                      ? "An email is required"
                      : "A value is required"
                  }
                  errorsTimeout={100}
                  errorTexts={
                    invalidValueError
                      ? [invalidValueError]
                      : duplicateRule
                        ? [`This condition is a duplicate`]
                        : undefined
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className={"delete-button"}>
          <i className="cr-icon-trash-2" onClick={onDeleteRule} />
        </div>
      </div>
    </div>
  );
};

const MonitoringRulesModal: FC<BaseModalProps> = ({ active, onClose }) => {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [monitoringRules, setMonitoringRules] = useState<MonitoringRule[]>([]);
  const [fetchingPreview, setFetchingPreview] = useState(false);
  const [newlyUnmonitoredUsers, setNewlyUnmonitoredUsers] = useState<
    number | undefined
  >(undefined);

  const { data, isFetching } =
    UserBaseAPI.useUserRiskGetMonitoringRulesV1Query();
  const [saveMonitoringRules] =
    UserBaseAPI.useUserRiskUpdateMonitoringRulesV1Mutation();
  const [deleteMonitoringRules] =
    UserBaseAPI.useUserRiskDeleteMonitoringRulesV1Mutation();
  const [previewMonitoringRules] =
    UserBaseAPI.useLazyUserRiskPreviewMonitoringRulesV1Query();

  useEffect(() => {
    const existingRules = data?.rules ?? [];
    if (existingRules.length) {
      setMonitoringRules(
        existingRules.map((r) => ({
          uuid: uuidv4(),
          type: r.type,
          value: r.rule.value,
        }))
      );
    }
  }, [data]);

  const monitoringRulesForApiReq = () => {
    const rules = monitoringRules
      .filter((r) => !!r.type && !!r.value)
      .map((r) => ({
        type: r.type,
        rule: { value: r.value },
      })) as Rule[];
    return { rules };
  };

  const onSave = () => {
    setIsSaving(true);
    const rulesToSave = monitoringRulesForApiReq();
    const mutation =
      rulesToSave.rules.length > 0
        ? saveMonitoringRules(rulesToSave)
        : deleteMonitoringRules();

    mutation
      .unwrap()
      .then(() => dispatch(addDefaultSuccessAlert(`Saved monitoring rule`)))
      .catch((e) => {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert(`Failed to save monitoring rule`));
      })
      .finally(() => onClose())
      .finally(() => setIsSaving(false));
  };

  const onAddCondition = () => {
    const newMonitoringRules = [...monitoringRules, { uuid: uuidv4() }];
    setMonitoringRules(newMonitoringRules);
  };

  const onRuleChange = (idx: number, newRule: MonitoringRule) => {
    const newMonitoringRules = produce(
      monitoringRules,
      (draftMonitoringRules) => {
        draftMonitoringRules[idx] = newRule;
      }
    );
    setMonitoringRules(newMonitoringRules);
    refreshCounts();
  };

  const onDeleteRule = (idx: number) => {
    const newMonitoringRules = produce(
      monitoringRules,
      (draftMonitoringRules) => {
        draftMonitoringRules.splice(idx, 1);
      }
    );
    setMonitoringRules(newMonitoringRules);
    refreshCounts();
  };

  const rulesAreValid = monitoringRules.every((r) => isValidMonitoringRule(r));
  const includeDuplicateRules =
    new Set(monitoringRules.map((r) => `${r.type}_${r.value?.toLowerCase()}`))
      .size !== monitoringRules.length;
  const canSave = rulesAreValid && !includeDuplicateRules;

  const refreshCounts = useDebounce(() => {
    if (!canSave) {
      // no point in making a preview request
      // if we know some of the rules are invalid
      return;
    }

    const apiRules = monitoringRulesForApiReq();
    if (apiRules.rules.length === 0) {
      setNewlyUnmonitoredUsers(0);
      setFetchingPreview(false);
      return;
    }

    setFetchingPreview(true);
    previewMonitoringRules(apiRules)
      .unwrap()
      .then((resp) =>
        setNewlyUnmonitoredUsers(
          resp.numMonitoredUsersBefore - resp.numMonitoredUsersAfter
        )
      )
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            `Failed to update the number of affected users`
          )
        );
      })
      .finally(() => setFetchingPreview(false));
  }, 1000);

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="monitoring-rules-modal"
      headerContent={`Manage monitoring rules`}
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!canSave}
            loading={isFetching || isSaving}
            onClick={onSave}
            primary
          >
            Apply changes
          </Button>
        </div>
      }
    >
      <div className={"description"}>
        Define rules to exclude specific accounts from being monitored. Rules
        will be applied automatically whenever UserRisk does a daily scan or
        when you run them manually against your directory.
      </div>
      {isFetching && <LoadingIcon />}
      {!isFetching && (
        <div className={"rules"}>
          <div className={"rules-inner"}>
            {monitoringRules.length > 1 && (
              <div className={"rules-operator"}>
                <div className={"horizontal-connector top"}></div>
                <div className={"vertical-connector"}></div>
                <PillLabel color={LabelColor.Blue}>OR</PillLabel>
                <div className={"vertical-connector"}></div>
                <div className={"horizontal-connector bottom"}></div>
              </div>
            )}
            <div className={"rules-list"}>
              {monitoringRules.length === 0 && (
                <Button onClick={onAddCondition}>
                  <div className="cr-icon-plus" />
                  Add condition
                </Button>
              )}
              {monitoringRules.length > 0 &&
                monitoringRules.map((r, idx) => {
                  return (
                    <MonitoringRule
                      key={r.uuid}
                      index={idx}
                      rule={r}
                      onRuleChange={(newRule) => onRuleChange(idx, newRule)}
                      onDeleteRule={() => onDeleteRule(idx)}
                      duplicateRule={
                        !!r.type &&
                        !!r.value &&
                        monitoringRules
                          .slice(0, idx)
                          .filter((other) => other.type && other.value)
                          .findIndex(
                            (other) =>
                              other.type === r.type && other.value === r.value
                          ) > -1
                      }
                    />
                  );
                })}
              {monitoringRules.length > 0 && (
                <div className={"add-btn-wrapper"}>
                  <div className="add-btn" onClick={onAddCondition} />
                </div>
              )}
            </div>
          </div>
          {newlyUnmonitoredUsers !== undefined &&
            monitoringRules.length > 0 && (
              <InfoBanner
                type={BannerType.INFO}
                message={
                  <>
                    <div className="cr-icon-lightning" />
                    {fetchingPreview && (
                      <div>Calculating the number of affected users</div>
                    )}
                    {!fetchingPreview && (
                      <div>{`These conditions will result in ${NumberWithCommas(
                        newlyUnmonitoredUsers
                      )} ${pluralise(
                        newlyUnmonitoredUsers,
                        "user",
                        "users"
                      )} being unmonitored.`}</div>
                    )}
                  </>
                }
                disableIcon={true}
              />
            )}
        </div>
      )}
    </ModalV2>
  );
};

export default MonitoringRulesModal;
