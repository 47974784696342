import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../_common/components/core/Button";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import { useVendorDataSelector } from "../../../_common/selectors/vendorSelectors";
import {
  fetchDomains,
  useDomainsSelector,
} from "../../reducers/domains.actions";
import {
  IXTableColumnHeader,
  SortDirection,
} from "../../../_common/components/core/XTable";
import { NumberWithCommas, pluralise } from "../../../_common/helpers";
import LabelList from "../LabelList";
import { LabelClassification, LabelColor } from "../../../_common/types/label";
import { fetchVendorIPAddresses } from "../../reducers/ipAddresses.actions";
import ColorGrade, { ColorGradeSize } from "../executive_summary/ColorGrade";
import TreeTableWindowed, { NodeCell, TreeNode } from "../TreeTableWindowed";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import "../../style/components/vendor_assessment/VendorAssessmentPickDomainsModal.scss";
import ColorCheckbox from "../ColorCheckbox";
import SearchBox from "../../../_common/components/SearchBox";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import { fetchAvailableLabels } from "../../reducers/cyberRiskActions";
import PillLabel from "../PillLabel";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import vendorAssessmentApi from "../../reducers/vendorAssessmentAPI";
import ToggleWithLabel from "../ToggleWithLabel";
import { VendorAssessmentHostnameSnapshot } from "../../types/vendorAssessments";
import InfoBanner, { BannerType } from "../InfoBanner";

import {
  getChildrenRowIds,
  getSortedHostsTree,
  IDomainWithChildren,
} from "../WebscanTreeViewV2";

interface VendorAssessmentPickDomainsModalProps extends BaseModalProps {
  vendorAssessmentID: number;
  vendorID: number;
  managedOrgID?: number;
  initialAllSelected: boolean;
  initialSelectedHostnames: number[];
  readOnly?: boolean;
  hostnameSnapshot?: VendorAssessmentHostnameSnapshot[];
}

const unlabeledID = "_UNLABLED_" as const;

const VendorAssessmentPickDomainsModal: FC<
  VendorAssessmentPickDomainsModalProps
> = (props) => {
  const dispatch = useAppDispatch();

  const allLabels = useAppSelector((state) => state.cyberRisk.availableLabels);
  const labels = allLabels.filter(
    (l) => l.classification == LabelClassification.WebsiteLabel
  );
  const [labelsFilter, setLabelsFilter] = useState<string[]>([
    ...labels.map((l) => l.name),
    unlabeledID,
  ]);

  // load data
  useEffect(() => {
    dispatch(
      fetchDomains({
        vendor_id: props.vendorID,
        active: true,
        website_include_unlabeled: false,
        website_label_ids_do_not_match: false,
        website_label_ids_match_all: false,
      })
    );
    dispatch(fetchVendorIPAddresses(props.vendorID));
    dispatch(fetchAvailableLabels()).then((data) =>
      setLabelsFilter([
        ...data
          .filter((l) => l.classification == LabelClassification.WebsiteLabel)
          .map((l) => l.name),
        unlabeledID,
      ])
    );
  }, []);

  const onSelectLabel = useCallback(
    (name: string) =>
      setLabelsFilter((prev) => {
        const draft = [...prev];

        const idx = draft.indexOf(name);
        if (idx == -1) {
          draft.push(name);
        } else if (idx > -1) {
          draft.splice(idx, 1);
        }

        return draft;
      }),
    [setLabelsFilter, labels]
  );

  const [showOnlySelected, setShowOnlySelected] = useState(
    props.readOnly ?? false
  );

  const ips = useVendorDataSelector(
    (vendorData) => vendorData.ipAddresses,
    props.vendorID
  );

  const domains = useDomainsSelector({
    vendor_id: props.vendorID,
    active: true,
    website_include_unlabeled: false,
    website_label_ids_do_not_match: false,
    website_label_ids_match_all: false,
    // website_label_ids: labelsFilter,
  });

  const loading =
    ((ips?.loading ?? true) || (domains?.loading ?? true)) &&
    !props.hostnameSnapshot;

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  const [allSelected, setAllSelected] = useState(props.initialAllSelected);
  const [selectedDomainsAndIPs, setSelectedDomainsAndIPs] = useState<
    Record<number, boolean>
  >(
    props.initialSelectedHostnames.reduce(
      (map, id) => {
        map[id] = true;
        return map;
      },
      {} as Record<number, boolean>
    )
  );

  const hostsMap = useMemo(() => {
    const hostsMap = !props.hostnameSnapshot
      ? {
          ...(domains?.domains
            ?.filter(
              (d) =>
                !showOnlySelected ||
                allSelected ||
                selectedDomainsAndIPs[d.hostnameID]
            )
            .reduce(
              (obj, scan) => {
                obj[scan.hostname] = scan as IDomainWithChildren;
                return obj;
              },
              {} as {
                [hostname: string]: IDomainWithChildren;
              }
            ) ?? {}),
          // we also want to convert our IPs to 'hostnames' so they can be inserted logically into the tree
          ...ips?.ipAddresses
            ?.filter(
              (ip) =>
                !!ip.score &&
                (!showOnlySelected ||
                  allSelected ||
                  selectedDomainsAndIPs[ip.hostnameID ?? -1])
            )
            .reduce(
              (map, ip) => {
                if (ip.hostnameID) {
                  map[ip.ip] = {
                    score: ip.score ?? undefined,
                    labels: ip.labels,
                    hostname: ip.ip,
                    scanned_at: "",
                    enabled: true,
                    isTopLevel: true,
                    children: [],
                    hostnameID: ip.hostnameID,
                  };
                }
                return map;
              },
              ({} as {
                [hostname: string]: IDomainWithChildren;
              }) ?? {}
            ),
        }
      : // or if we have been provided with a snapshot use that instead
        props.hostnameSnapshot.reduce(
          (map, s, i) => {
            map[s.hostname] = {
              score: s.score,
              labels:
                s.labels?.map((s) => ({
                  ...s,
                  id: 0,
                  classification: LabelClassification.WebsiteLabel,
                  organisationID: 0,
                  description: "",
                })) ?? [],
              hostname: s.hostname,
              scanned_at: s.scannedAt ?? "",
              enabled: true,
              children: [],
              hostnameID: i, // fake this as we'll use it to generate an ID later
              isTopLevel: false,
            };
            return map;
          },
          {} as { [hostname: string]: IDomainWithChildren }
        );

    return hostsMap;
  }, [
    domains,
    ips,
    showOnlySelected,
    allSelected,
    selectedDomainsAndIPs,
    props.hostnameSnapshot,
  ]);

  const [filteredHostMap, allHostnames] = useMemo(() => {
    const filtersLower = filterText.toLowerCase();
    const allHostnames: string[] = [];

    const filteredHostMap = Object.entries(hostsMap).reduce(
      (map, [hostname, scan]) => {
        if (
          (filterText == "" || scan.hostname.includes(filtersLower)) &&
          (((scan.labels?.length ?? 0) == 0 &&
            labelsFilter.includes(unlabeledID)) ||
            scan.labels?.some((l) => labelsFilter.includes(l.name)))
        ) {
          allHostnames.push(hostname);
          map[hostname] = scan;
        }
        return map;
      },
      {} as { [hostname: string]: IDomainWithChildren }
    );

    allHostnames.sort((a, b) => a.localeCompare(b));

    return [filteredHostMap, allHostnames];
  }, [hostsMap, filterText, labelsFilter]);

  const totalDomainsAndIPs =
    (ips?.ipAddresses?.filter((ip) => !!ip.score).length ?? 0) +
    (domains?.domains?.length ?? 0);
  const numDomainsAndIps = Object.keys(filteredHostMap).length;

  const allDomainsAndIpIDs = useMemo(() => {
    return {
      ...domains?.domains?.reduce(
        (map, d) => {
          map[d.hostnameID] = true;
          return map;
        },
        {} as Record<number, boolean>
      ),
      ...ips?.ipAddresses
        ?.filter((ip) => !!ip.score)
        ?.reduce(
          (map, ip) => {
            if (ip.hostnameID) {
              map[ip.hostnameID] = true;
            }
            return map;
          },
          {} as Record<number, boolean>
        ),
    };
  }, [domains, ips]);

  const onSetSelectAll = useCallback(
    (all: boolean) => {
      setAllSelected(all);
      setSelectedDomainsAndIPs(all ? allDomainsAndIpIDs : {});
    },
    [setAllSelected, setSelectedDomainsAndIPs, allDomainsAndIpIDs]
  );

  const onSelectDomains = useCallback(
    (hostnames: number[], select: boolean) =>
      setSelectedDomainsAndIPs((prev) => {
        if (select) {
          const next = {
            ...prev,
            ...hostnames.reduce(
              (map, hostname) => {
                map[hostname] = true;
                return map;
              },
              {} as Record<string, boolean>
            ),
          };

          if (Object.keys(next).length == totalDomainsAndIPs) {
            setAllSelected(true);
          }

          return next;
        } else {
          setAllSelected(false);
          if (allSelected) {
            const next = { ...allDomainsAndIpIDs };
            hostnames.forEach((hostname) => {
              delete next[hostname];
            });
            return next;
          }

          prev = { ...prev };
          hostnames.forEach((hostname) => {
            delete prev[hostname];
          });
          return prev;
        }
      }),
    [allDomainsAndIpIDs, allSelected]
  );

  const [sortBy, setSortBy] = useState("url");
  const [sortDir, setSortDir] = useState(SortDirection.ASC);
  const hostsTree = getSortedHostsTree(
    filteredHostMap,
    allHostnames,
    "",
    undefined,
    {
      columnId: sortBy,
      direction: sortDir,
    }
  );

  const generateTreeNodes = (
    nodes: TreeNode[],
    hostname: string,
    children: IDomainWithChildren[]
  ) => {
    const scan = hostsMap[hostname];

    const selected = allSelected || selectedDomainsAndIPs[scan.hostnameID];

    const node: TreeNode = {
      id: scan.hostnameID,
      children: [],
      selected,
      selectionDisabled: props.readOnly,
      onClick: props.readOnly
        ? undefined
        : () => onSelectDomains([scan.hostnameID], !selected),
      mainCellContent: (
        <div className="url-cell" title={scan.hostname}>
          <div className="url">
            {scan.hostname}
            {children.length > 0 && (
              <>
                <br />
                <span className="num-subdomains">
                  {NumberWithCommas(children.length)}{" "}
                  {pluralise(children.length, "subdomain", "subdomains")}
                </span>
              </>
            )}
          </div>
        </div>
      ),
      otherCells: (
        <>
          <NodeCell className="score-cell">
            {!!scan.score && (
              <div className="grade-with-score">
                {scan.score >= 0 && (
                  <>
                    <ColorGrade
                      size={ColorGradeSize.Small}
                      score={scan.score}
                    />
                    {scan.score}
                  </>
                )}
              </div>
            )}
          </NodeCell>
          <NodeCell className="date-scanned-cell">
            {!!scan.scanned_at && (
              <span className="caps-time">
                <DateTimeFormat dateTime={scan.scanned_at} dateOnly />
              </span>
            )}
          </NodeCell>
          <NodeCell className="labels-cell">
            <LabelList
              labels={scan.labels}
              maxWidth={160}
              classification={LabelClassification.WebsiteLabel}
            />
          </NodeCell>
        </>
      ),
    };

    children.forEach((child) =>
      generateTreeNodes(
        node.children,
        child.hostname,
        child.children as IDomainWithChildren[]
      )
    );

    nodes.push(node);
  };

  const nodesTree: TreeNode[] = [];
  hostsTree.forEach((treeNode) => {
    generateTreeNodes(
      nodesTree,
      treeNode.hostname,
      treeNode.children as IDomainWithChildren[]
    );
  });

  const columns: IXTableColumnHeader[] = [
    {
      id: "url",
      text: "Domain / IP address",
      sortable: true,
      startingSortDir: SortDirection.DESC,
    },
    {
      id: "score",
      text: "Score",
      sortable: true,
      startingSortDir: SortDirection.DESC,
      className: "score-header",
    },
    {
      id: "scan",
      text: "Most recent scan",
      sortable: true,
      startingSortDir: SortDirection.DESC,
      className: "date-scanned-header",
    },
    {
      id: "labels",
      text: "Labels",
      className: "labels-header",
    },
  ];

  const [updateDomainsMutator] =
    vendorAssessmentApi.useUpdateVendorAssessmentSelectedHostnamesMutation();
  const [startScoringJobMutator] =
    vendorAssessmentApi.useStartNewVendorAssessmentScoringJobMutation();

  const [saving, setSaving] = useState(false);
  const onSubmit = useCallback(
    (selectAll: boolean, selectedDomains: Record<number, boolean>) => {
      setSaving(true);

      updateDomainsMutator({
        vendorID: props.vendorID,
        versionID: props.vendorAssessmentID,
        selectAll,
        selectedDomains: Object.entries(selectedDomains)
          .filter(([, b]) => b)
          .map(([h]) => parseInt(h)),
      })
        .then(() => {
          dispatch(addDefaultSuccessAlert("Updated selected domains & IPs"));
          startScoringJobMutator({
            vendorID: props.vendorID,
            versionID: props.vendorAssessmentID,
          });
          props.onClose();
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert("Error updating selected domains & IPs")
          );
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [props.vendorID, props.vendorAssessmentID]
  );

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      className={"pick-domains-modal"}
      scroll="content"
      headerContent={
        props.readOnly
          ? `${
              allSelected
                ? totalDomainsAndIPs
                : Object.keys(selectedDomainsAndIPs).length
            }/${totalDomainsAndIPs} domains & IPs included`
          : "Change selection"
      }
      footerClassName={"pick-domains-footer"}
      footerContent={
        <>
          <div className={"footer-left"}>
            {!props.readOnly && (
              <ToggleWithLabel
                onClick={() => setShowOnlySelected((show) => !show)}
                label={"Show selection only"}
                selected={showOnlySelected}
                name={"show selected only"}
              />
            )}
          </div>
          {props.readOnly ? undefined : (
            <div className={"footer-right"}>
              <Button tertiary onClick={props.onClose} disabled={saving}>
                Cancel
              </Button>
              <Button
                primary
                onClick={() => onSubmit(allSelected, selectedDomainsAndIPs)}
                loading={saving}
                disabled={
                  Object.keys(selectedDomainsAndIPs).length == 0 && !allSelected
                }
              >
                Save changes
              </Button>
            </div>
          )}
        </>
      }
    >
      {!props.readOnly && (
        <div className={"sub-title"}>
          Select domains and IPs to include in the scope of your risk
          assessment:
        </div>
      )}
      <div className={"filter-section"}>
        <div className={"top-row"}>
          {!props.readOnly && (
            <ColorCheckbox
              checked={allSelected}
              indeterminate={
                !allSelected && Object.keys(selectedDomainsAndIPs).length > 0
              }
              onClick={() => onSetSelectAll(!allSelected)}
              disabled={loading || props.readOnly}
              label={
                loading
                  ? "Domains and IPs loading"
                  : `${
                      allSelected
                        ? totalDomainsAndIPs
                        : Object.keys(selectedDomainsAndIPs).length
                    }/${totalDomainsAndIPs} Domains and IPs included in assessment`
              }
            />
          )}
          <Button
            className={"search-btn"}
            onClick={() => {
              setFilterOpen(!filterOpen);
              if (filterOpen) {
                setFilterText("");
              }
            }}
            tertiary
          >
            {filterOpen ? (
              <>
                <i className={"cr-icon-cross"} /> Close
              </>
            ) : (
              <>
                <i className={"cr-icon-search"} /> Search
              </>
            )}
          </Button>
          <ButtonWithDropdownV2
            text={
              <>
                Labels <i className={"cr-icon-chevron rotate-90"} />
              </>
            }
            dropdownProps={{
              noCloseOnClickInside: true,
            }}
          >
            <DropdownItem
              onClick={() => onSelectLabel(unlabeledID)}
              selected={labelsFilter.includes(unlabeledID)}
              stopPropagation
            >
              <ColorCheckbox
                checked={labelsFilter.includes(unlabeledID)}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectLabel(unlabeledID);
                }}
                label={<PillLabel color={LabelColor.Grey}>Unlabeled</PillLabel>}
              />
            </DropdownItem>
            {labels.map((label) => (
              <DropdownItem
                key={label.id}
                onClick={() => onSelectLabel(label.name)}
                selected={labelsFilter.includes(label.name)}
                stopPropagation
              >
                <ColorCheckbox
                  checked={labelsFilter.includes(label.name)}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectLabel(label.name);
                  }}
                  label={
                    <PillLabel color={label.colour}>{label.name}</PillLabel>
                  }
                />
              </DropdownItem>
            ))}
          </ButtonWithDropdownV2>
        </div>
        <TransitionGroup>
          {filterOpen && (
            <CSSTransition timeout={250} classNames="fade-transition">
              <div className={"bottom-row"}>
                <SearchBox
                  onChanged={setFilterText}
                  value={filterText}
                  placeholder={"Search by domain or IP address..."}
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
      {Object.keys(selectedDomainsAndIPs).length > 5000 && !allSelected && (
        <InfoBanner
          type={BannerType.WARNING}
          message={
            <>
              Too many domains selected. When tailoring the domain and IP list,
              no more than 5000 can be selected.
              <Button
                link
                onClick={() => {
                  setSelectedDomainsAndIPs({});
                  setAllSelected(true);
                }}
              >
                Or select all domains and IPs
              </Button>
            </>
          }
        />
      )}
      <div className={"main-table"}>
        {loading && <LoadingBanner />}
        {!loading &&
          numDomainsAndIps == 0 &&
          (filterText != "" ||
            (labelsFilter && labelsFilter.length < labels.length + 1)) && (
            <SearchEmptyCard
              searchString={filterText}
              onClear={() => {
                setFilterText("");
                setLabelsFilter([...labels.map((l) => l.name), unlabeledID]);
              }}
            />
          )}
        {!loading && numDomainsAndIps > 0 && (
          <TreeTableWindowed
            height={500}
            overscanCount={10}
            nodes={nodesTree}
            columnHeaders={columns}
            onSelectClick={
              props.readOnly
                ? undefined
                : (hostnameID, selected) =>
                    onSelectDomains([hostnameID] as number[], selected)
            }
            onSortChange={(columnId, newSortDir) => {
              setSortBy(columnId);
              setSortDir(newSortDir);
            }}
            sortedBy={{
              columnId: sortBy,
              direction: sortDir,
            }}
            childrenSelection={
              props.readOnly
                ? undefined
                : {
                    onSelectChildrenClick: (parentNodeId, selected) => {
                      onSelectDomains(
                        getChildrenRowIds(
                          parentNodeId,
                          hostsTree,
                          undefined,
                          "hostnameID"
                        ) as number[],
                        selected
                      );
                    },
                    selectAllText: "Select all subdomains",
                    selectNoneText: "Unselect all subdomains",
                  }
            }
          />
        )}
      </div>
    </ModalV2>
  );
};

export default VendorAssessmentPickDomainsModal;
