import "./EllipsisedURL.scss";
import EllipsizedText from "../../_common/components/EllipsizedText";
import { FreeFormExternalLink } from "../../_common/components/ExternalLink";

export interface EllipsisedURLProps {
  url: string;
}

export default function EllipsisedURL({ url }: { url: string }) {
  return (
    <FreeFormExternalLink url={url} className="ellipsised-url">
      <div className="ellipsised-url-row">
        <EllipsizedText text={url}>{url}</EllipsizedText>
        <div className="ext-icon cr-icon-external-link" />
      </div>
    </FreeFormExternalLink>
  );
}
