import { useCallback, useEffect, useState } from "react";

// useArray returns a reactive array.
// Only add elements to the array using pushRef.
export function useArray<E extends HTMLElement>() {
  const [refs, setRefs] = useState<E[]>([]);

  const pushRef = useCallback((el: E | null) => {
    if (el) {
      setRefs((rs) => [...rs, el]);
    }
  }, []);
  const resetRefs = useCallback(() => setRefs([]), []);

  return [refs, pushRef, resetRefs] as const;
}

// useParseSnippet parses a snippet of text into an array of Line and an array of highlight mark DOM nodes.
// The input text is
// - split by newline characters
// - split by the keyword (via case-insensitive regex)
// - transformed into Line objects as JSX elements
// - wrapped in a mark element if the line contains the keyword
// - a ref to each mark element is pushed into the highlights array
export function useParseSnippet(text: string, keyword: string) {
  const [highlights, pushHighlight, resetHighlights] = useArray<HTMLElement>();

  const [lines, setLines] = useState<JSX.Element[]>([]);

  useEffect(() => {
    resetHighlights();

    if (!text) {
      setLines([]);
      return;
    }

    if (!keyword) {
      setLines([<>{text}</>]);
      return;
    }

    const parsedLines = parseSnippet(text, keyword, pushHighlight);

    setLines(parsedLines);
  }, [text, keyword, pushHighlight, resetHighlights]);

  return [lines, highlights] as const;
}

export type PushHighlight = ReturnType<typeof useArray>[1];
export function parseSnippet(
  text: string,
  keyword?: string,
  pushHighlight?: PushHighlight
) {
  if (!keyword) {
    return [<>{text}</>];
  }

  const lines = text.split("\n");
  const kwRegex = new RegExp(`(${keyword})`, "ig");

  return lines.map((line, lineI) => {
    const parts = line.split(kwRegex);
    return (
      <>
        {parts.map((part, i) => {
          return i % 2 === 1 ? (
            <mark key={`${keyword}_${lineI}_${i}`} ref={pushHighlight}>
              {part}
            </mark>
          ) : (
            part
          );
        })}
      </>
    );
  });
}
