import Search from "../images/search.svg";
import Button from "./core/Button";

import "../style/components/SearchEmptyCard.scss";

export interface ISearchEmptyCardProps {
  /* Text string representing item for use in feedback text */
  searchItemText: string;
  /* Callback for click of 'Clear Search' button */
  onClear?: () => void;
  /* Optional text to display for clear button */
  clearText?: string;
  /* Optionally hide search-related UI*/
  hideSearch?: boolean;
  /* Optionally show in place of the search string used*/
  searchString?: string;
  /* Optional text override */
  searchTextOverride?: string;
  /* Optional sub text override */
  subTextOverride?: string;
  /* Optionally hide the sub text*/
  hideSubText?: boolean;

  additionalButtonText?: string;
  additionalButtonAction?: () => void;
}

/* Displays an empty search card with a button to trigger a clear callback. */
const SearchEmptyCard = (props: ISearchEmptyCardProps) => {
  const searchText = props.searchTextOverride
    ? props.searchTextOverride
    : `No ${props.searchItemText} found`;

  const subText = props.subTextOverride
    ? props.subTextOverride
    : `Your search ${
        props.searchString ? `"${props.searchString}"` : " or filter"
      } did not match any ${props.searchItemText}. Please try again.`;

  return (
    <div className="search-empty-card">
      <img src={Search} alt="search" className={"search-empty-icon"} />
      <div className="empty-search-text">{searchText}</div>
      {!props.hideSearch && (
        <>
          {!props.hideSubText && (
            <div className="empty-search-subtext">{subText}</div>
          )}
          {!!props.onClear && (
            <Button onClick={props.onClear}>{props.clearText}</Button>
          )}
          {!!props.additionalButtonText && !!props.additionalButtonAction && (
            <Button primary onClick={props.additionalButtonAction}>
              {props.additionalButtonText}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

SearchEmptyCard.defaultProps = {
  searchItemText: "items",
  clearText: "Clear search",
  hideSearch: false,
};

export default SearchEmptyCard;
