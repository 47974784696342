import { FC, useEffect, useState } from "react";

import Button from "../../_common/components/core/Button";

import "./CreateRemediationModal.scss";
import TextField from "../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import { DisplayableThreatMonitoringResult } from "../api/types";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import DatePicker from "../../_common/components/DatePicker";
import moment from "moment";
import { useSchedule } from "../../_common/components/remediationDetails/UpdateDueDateModal";
import ContactSelectV2, {
  useSelectedContacts,
} from "../../vendorrisk/components/contacts/ContactSelectV2";
import SeveritySelector from "../../vendorrisk/components/SeveritySelector";
import { createNewThreatMonitoringRemediationRequest } from "../../vendorrisk/reducers/remediation.actions";
import {
  addDefaultSuccessAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { getOrgUsers } from "../../vendorrisk/reducers/cyberRiskActions";
import { IOrgUserWithFree } from "../../_common/types/user";

interface CreateRemediationModalProps extends BaseModalProps {
  threat: DisplayableThreatMonitoringResult;
  onCreateRemediationRequest: (remediationId: number) => void;
}

const stableEmptyArray: IOrgUserWithFree[] = [];

const CreateRemediationdModal: FC<CreateRemediationModalProps> = (props) => {
  const { threat } = props;

  const [remediationTitle, setRemediationTitle] = useState(threat.title);
  const [severity, setSeverity] = useState(threat.severity);
  const [emailMessage, setEmailMessage] = useState("");
  const [showComments, setShowComments] = useState(true);
  const [loading, setLoading] = useState(false);

  const orgUserEmails = useAppSelector(
    (state) => state.cyberRisk.orgUsers.data ?? stableEmptyArray
  );

  const [selectedContacts, newContacts, setContacts] = useSelectedContacts([]);

  const fmt = "YYYY-MM-DD";

  const [
    dueDate,
    reminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelection,
  ] = useSchedule(moment().add(7, "days").format(fmt));

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getOrgUsers());
  }, []);

  const submit = () => {
    setLoading(true);
    dispatch(
      createNewThreatMonitoringRemediationRequest({
        isDraft: false,
        threatFindingUUID: threat.uuid,
        title: remediationTitle,
        severity: severity,
        inviteEmails: selectedContacts.map((c) => c.emailAddress),
        emailMessage: emailMessage,
        dueDate: dueDate ? moment(dueDate).utc(true).format() : undefined,
        reminderDate: reminderDate
          ? moment(reminderDate).utc(true).format()
          : undefined,
      })
    )
      .then((resp) => {
        dispatch(
          resp.emailAddressInactive
            ? addDefaultWarningAlert(
                "Remediation request sent. One or more recipient email addresses appear to be inactive and may not have received an email."
              )
            : addDefaultSuccessAlert("Remediation request sent")
        );
        props.onCreateRemediationRequest(resp.newId);
        props.onClose();
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      disallowClose={true}
      scroll={"content"}
      headerContent={`Remediation request: "${threat.title}"`}
      headerClassName={"request-remediation-modal-header"}
      className={"request-remediation-modal"}
      footerClassName={"request-remediation-modal-footer"}
      footerContent={
        <>
          <Button tertiary onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            primary
            loading={loading}
            onClick={submit}
            disabled={remediationTitle === "" || emailMessage === ""}
          >
            Submit request
          </Button>
        </>
      }
    >
      <div className="report-card-sections">
        <div className="report-card-section request-name-section">
          <div className="section-label">Request name</div>
          <div className="section-content">
            <TextField
              value={remediationTitle}
              onChanged={(t) => setRemediationTitle(t)}
              required
              requiredErrorText="Enter a request name"
            />
          </div>
        </div>
        <div className="report-card-section">
          <div className="section-label">Severity</div>
          <div className="section-content severity-section">
            <div className="section-content">
              <SeveritySelector
                severity={severity}
                onChange={(s) => {
                  setSeverity(s);
                }}
              />
            </div>
          </div>
        </div>
        <div className="report-card-section">
          <div className="section-label">Recipients</div>
          <div className="section-content">
            <ContactSelectV2
              orgContacts={orgUserEmails}
              newContacts={newContacts}
              selectContacts={setContacts}
              existingSelectedContacts={selectedContacts}
              readonly={false}
              allowNewContact={true}
            />
          </div>
        </div>
        <div className="report-card-section schedule-section">
          <div className="section-label">Schedule</div>
          <div className="section-content">
            <div className={"due-date"}>
              <ColorCheckbox
                label={"Set a due date"}
                checked={!!dueDate}
                onClick={() => {
                  if (!dueDate) {
                    setDueDate(moment().add(7, "days").format(fmt));
                  } else {
                    setDueDate(undefined);
                  }
                }}
              />
              <p className={!!dueDate ? "upper-indent" : "indent"}>
                Select a date you’d like the remediation completed by.
              </p>
              {!!dueDate && (
                <div className={"due-date-inner indent"}>
                  <DatePicker
                    className={"due-date-picker"}
                    onChange={(evt) => setDueDate(evt.target.value)}
                    min={moment().format(fmt)}
                    disabled={!dueDate}
                    value={dueDate}
                    placeholder={"Select due date"}
                  />
                </div>
              )}
            </div>
            {!!dueDate && (
              <div className={"reminder"}>
                <ColorCheckbox
                  label={"Set a reminder"}
                  checked={reminderSelection !== "none"}
                  onClick={() =>
                    reminderSelection === "none"
                      ? setReminderSelection("30b")
                      : setReminderSelection("none")
                  }
                  disabled={!dueDate}
                />
                <p className={"upper-indent"}>
                  We’ll remind the recipient of your request on the specified
                  date.
                </p>
                {reminderSelection !== "none" && (
                  <div className={"reminder-inner indent"}>
                    <ColorCheckbox
                      label={"30 days before due date"}
                      checked={reminderSelection === "30b"}
                      onClick={() => setReminderSelection("30b")}
                      radio
                    />
                    <ColorCheckbox
                      label={"7 days before due date"}
                      checked={reminderSelection === "7b"}
                      onClick={() => setReminderSelection("7b")}
                      radio
                    />
                    <ColorCheckbox
                      label={"Choose custom reminder date"}
                      checked={reminderSelection === "custom"}
                      onClick={() => setReminderSelection("custom")}
                      radio
                    />
                    <DatePicker
                      className={"reminder-picker lower-indent"}
                      onChange={(evt) => setReminderDate(evt.target.value)}
                      min={moment().format(fmt)}
                      disabled={reminderSelection !== "custom"}
                      value={reminderDate}
                      placeholder={"Select reminder date"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="report-card-section comments-section">
          <div className="section-label">Comments</div>
          <div className="section-content">
            <div className={"due-date"}>
              <ColorCheckbox
                label={"Show <x> comments"}
                checked={!!showComments}
                onClick={() => setShowComments(!showComments)}
              />
              <p className={"indent"}>
                Make comments on this threat visible to remediation request
                collaborators.
              </p>
            </div>
          </div>
        </div>
        <div className="report-card-section">
          <div className="section-label">Message</div>
          <div className="section-content">
            <div className="description">
              Include a custom message with your request. We also recommend
              telling the recipient a request is coming before it’s sent.
            </div>
            <RichTextEditV2
              className="description-editor"
              value={emailMessage}
              onChange={(v) => setEmailMessage(v)}
            />
          </div>
        </div>
      </div>
    </ModalV2>
  );
};

export default CreateRemediationdModal;
