import React, { useEffect, useMemo, useState } from "react";
import {
  clearedVendorFilters,
  Filters,
  FilterTypes,
  WatchlistFilter,
} from "../../vendorrisk/components/filter/types";
import {
  IVendorSearchVendorAndWatched,
  IWatchlistPaging,
} from "../types/vendor";
import { DefaultRootState } from "react-redux";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import {
  runFetchVendorSearchResults,
  setCustomerDataFiltersAndRefreshData,
  setVendorSearch,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { isFilterActive } from "../../vendorrisk/components/filter";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import { get as _get, debounce as _debounce } from "lodash";
import SearchBox from "../../_common/components/SearchBox";

import "../style/components/ReportVendorSelector.scss";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import { getFiltersFromState } from "../../vendorrisk/reducers/filters.actions";
import { fetchVendorsNoCache } from "../../vendorrisk/reducers/vendors.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import VendorTierBadge from "../../vendorrisk/components/vendor_tiers/VendorTierBadge";
import {
  OrgAccessVendorPortfolios,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import Score from "../../vendorrisk/components/Score";
import { IVendorWords } from "../constants";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../types/label";
import { AssuranceType } from "../types/organisations";
import { validateUrl } from "../helpers";
import Button from "./core/Button";
import SearchEmptyCardWithDomainScan from "./SearchEmptyCardWithDomainScan";
import { ExportType } from "../types/exportReport";
import { shouldBypassSearch } from "../../vendorrisk/helpers/util";
import { getDefaultFilters } from "../../vendorrisk/reducers/defaults";
import { appConnect } from "../types/reduxHooks";

// the number of vendors shown in each page of the unwatched vendor search results table.
const VENDORS_PAGESIZE = 5;

const supportedFilters = [
  FilterTypes.VENDOR_ASSESSMENT_STATUS,
  FilterTypes.VENDOR_ASSESSMENT_AUTHOR,
  FilterTypes.VENDOR_REASSESSMENT_DATE,
  FilterTypes.VENDOR_TIER,
  FilterTypes.VENDOR_LABEL,
  FilterTypes.SCORE,
];

interface IReportVendorSelectorOwnProps {
  selectedVendorIDs: number[];
  onSelectVendor: (
    selected: boolean,
    vendorId: number,
    vendorName: string
  ) => void;
  onPreselectVendor?: (details: IVendorSearchVendorAndWatched) => void;
  onClearSelection?: () => void;
  className?: string;
  loading?: boolean;
  loadingReportGen?: number;
  setFiltersActive?: (active: boolean) => void;
  writablePortfoliosOnly?: boolean;
  pageSize?: number;
  defaultFilterText?: string;
  assessedVendorsOnly?: boolean;
  vendorWords: IVendorWords;
  assuranceType: AssuranceType;
  supportUnwatchedVendors?: boolean;
  onNext?: (steps: number) => void;
  setImmediateMoveOnRecalc?: (vendorID: number) => void;
  reportType?: ExportType;
  allVendorsSearchResult: IVendorSearchVendorAndWatched[];
  setAllVendorsSearchResult: (results: IVendorSearchVendorAndWatched[]) => void;
  filterText: string;
  setFilterText: (txt: string) => void;
  useStateFilters: boolean;
}

interface IReportVendorSelectorConnectedProps {
  filters?: Filters;
  vendorPortfoliosSupported?: boolean;
  enabledPortfolioIDs?: number[];
  disabledPortfolioIDs?: number[];
  vendorWatchCount?: number;
  vendorWatchLimit?: number;
}

type IReportVendorSelectorProps = IReportVendorSelectorOwnProps &
  IReportVendorSelectorConnectedProps &
  DefaultThunkDispatchProp;

//
// ReportVendorSelectorTable
// Used by the report configuration modal (launched by the reports library page) to allow the user to select a single
// vendor from those vendors being watched. The vendor search supports a switch to restrict vendors to only those that
// have at least one published assessment (to support assessment-based reports).
//
export const ReportVendorSelectorTable = (
  props: IReportVendorSelectorProps
) => {
  const [pageOpts, setPageOpts] = useState<Partial<IWatchlistPaging>>({
    pageNum: 1,
    pageSize: props.pageSize ? props.pageSize : 30,
  });
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorResult, setVendorResult] = useState(
    [] as IVendorSearchVendorAndWatched[]
  );

  const [domainScanning, setDomainScanning] = useState(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  // fetchAllVendorsNoCache
  // Uses existing function to run a vendor search over all vendors (watched and unwatched). the
  // results of this search aren't paged at the server-side, and are capped at approx 30 elements.
  // To improve the user experience we invariably page these ~30 results ourselves here.
  const fetchAllVendorsNoCache = (searchTerm: string, filters: any) => {
    return async (
      dispatch: DefaultThunkDispatch,
      getState: () => DefaultRootState
    ) => {
      dispatch(
        setVendorSearch({
          loading: true,
          query: searchTerm,
          results: {},
        })
      );
      let res;
      await runFetchVendorSearchResults(
        searchTerm,
        getState,
        dispatch,
        filters,
        30
      ).then((r) => {
        res = r;
        if (searchTerm === getState().cyberRisk.vendorSearch.query) {
          dispatch(
            setVendorSearch({
              loading: false,
              query: "",
            })
          );
        }
      });
      return res;
    };
  };

  // fetchVendors
  // Grab our vendors. Depending on the prop supportUnwatchedVendors, we either do a search over
  // watched vendors (the default), or over ALL vendors. The later results have no paging due to the way we implement
  // our performant (filtered) vendor search (see fetchAllVendorsNoCache above). Note that this option is typically set for free trial accounts
  // for whom many of which are really only there to generate one or more security reports (and will have no watched vendors).
  const fetchVendors = async (opts?: {
    namePrefix?: string;
    pageOpts?: Partial<IWatchlistPaging>;
  }) => {
    const fetchVendorsFilter = {
      ...props.filters,
      assessedVendorsOnly:
        !props.supportUnwatchedVendors && props.assessedVendorsOnly,
    };

    if (
      props.vendorPortfoliosSupported &&
      props.writablePortfoliosOnly &&
      props.enabledPortfolioIDs &&
      props.enabledPortfolioIDs.length > 0
    ) {
      // Filter to only the portfolios the user has write access to.
      if (
        fetchVendorsFilter.portfolioIds &&
        fetchVendorsFilter.portfolioIds.length > 0
      ) {
        // A filter has been specified, make sure to only include writable portfolios
        fetchVendorsFilter.portfolioIds =
          fetchVendorsFilter.portfolioIds.filter((pId) =>
            (props.enabledPortfolioIDs ?? []).includes(pId)
          );
      }

      if (
        !fetchVendorsFilter.portfolioIds ||
        fetchVendorsFilter.portfolioIds.length === 0
      ) {
        fetchVendorsFilter.portfolioIds = props.enabledPortfolioIDs;
      }
    }

    const finalVendors: IVendorSearchVendorAndWatched[] = [];
    const searchText = opts?.namePrefix ?? props.filterText;
    if (props.supportUnwatchedVendors) {
      // search over ALL vendors, not just watched vendors.
      if (!searchText || shouldBypassSearch(searchText)) {
        props.setAllVendorsSearchResult([] as IVendorSearchVendorAndWatched[]);
        setVendorResult([] as IVendorSearchVendorAndWatched[]);
      } else {
        setVendorsLoading(true);
        props
          .dispatch(fetchAllVendorsNoCache(searchText, getDefaultFilters()))
          .then((result: any) => {
            const numVendors = result?.vendors?.length;
            const pageSize = props.pageSize ?? VENDORS_PAGESIZE;
            const numPages = Math.ceil(numVendors / pageSize);
            setPageOpts({
              pageNum: 1,
              pageSize: pageSize,
              totalPages: numPages,
              totalItems: result?.numVendors,
              sortBy: "type",
              sortDir: SortDirection.ASC,
            } as IWatchlistPaging);

            const watchedVendorIndex: { [hostname: string]: any } = {};
            result?.watchedVendors?.forEach((v: any) => {
              watchedVendorIndex[v.primary_hostname] = v;
              finalVendors.push({
                isWatched: true,
                ...v,
              });
            });
            result?.vendors?.forEach((v: any) => {
              if (!watchedVendorIndex[v.primaryHostname]) {
                finalVendors.push({
                  isWatched: false,
                  id: v.id,
                  name: v.name,
                  display_name:
                    v.legalName.length > 0 ? v.legalName : undefined,
                  cstar_score: v.cstarScore,
                  primary_hostname: v.primaryHostname,
                } as IVendorSearchVendorAndWatched);
              }
            });
            finalVendors.sort((a, b) => {
              const aw = a.isWatched ?? "M";
              const bw = b.isWatched ?? "U";
              //const aname = a.display_name ?? a.name;
              //const bname = b.display_name ?? b.name;
              return aw > bw ? -1 : aw < bw ? 1 : 0;
              /*aname > bname
                    ? -1
                    : aname < bname
                    ? 1
                    : 0;*/
            });
            props.setAllVendorsSearchResult(finalVendors);
            setVendorResult(
              finalVendors.slice(0, Math.min(pageSize, finalVendors.length))
            );
            setVendorsLoading(false);
          })
          .catch((e) => {
            setVendorsLoading(false);
            props.dispatch(
              addDefaultUnknownErrorAlert(
                `error fetching (1) ${props.vendorWords.plural}: ${e}`
              )
            );
          });
      }
    } else {
      setVendorsLoading(true);
      fetchVendorsFilter.namePrefix = searchText;
      props
        .dispatch(
          fetchVendorsNoCache(
            opts?.pageOpts ?? pageOpts,
            fetchVendorsFilter as Partial<WatchlistFilter>
          )
        )
        .then((vendorResult) => {
          if (vendorResult) {
            setPageOpts(vendorResult?.paging);
            vendorResult?.vendors?.forEach((v) => {
              finalVendors.push({
                isWatched: true,
                ...v,
              });
            });
            setVendorResult(finalVendors);
          }
          setVendorsLoading(false);
        })
        .catch((e) => {
          setVendorsLoading(false);
          props.dispatch(
            addDefaultUnknownErrorAlert(
              `error fetching (2) ${props.vendorWords.plural}: ${e}`
            )
          );
        });
    }
  };

  // load vendors on both load and filter change
  useEffect(() => {
    fetchVendors();
  }, [props.filters, props.assessedVendorsOnly, props.supportUnwatchedVendors]);

  const headers: IXTableColumnHeader[] = useMemo(() => {
    const headers = [
      {
        id: "name",
        text: props.vendorWords.singularTitleCase,
        sortable: true,
      },
      {
        id: "score",
        text: "Score",
        sortable: true,
      },
      {
        id: "tier",
        text: "Tier",
        sortable: true,
      },
    ];
    if (props.supportUnwatchedVendors) {
      headers.push({
        id: "type",
        text: "Type",
        sortable: true,
      });
      headers.push({
        id: "button",
        text: "",
        sortable: false,
      });
    }
    return headers;
  }, [props.supportUnwatchedVendors]);

  const getRows = () => {
    const rows = [] as IXTableRow[];
    const addRow = (c: IVendorSearchVendorAndWatched) => {
      const cells: React.ReactNode[] = [
        <XTableCell key={"name"} className={"vendor-cell"}>
          <div className={"name"}>{c.display_name ?? c.name}</div>
          <div className={"domain"}>{c.primary_hostname}</div>
        </XTableCell>,
        <XTableCell key={"score"} className={"score-cell"}>
          <div className={"score-div"}>
            <ColorGrade score={c.cstar_score} size={ColorGradeSize.Small} />
            <Score colored small score={c.cstar_score} />
          </div>
        </XTableCell>,
        <XTableCell key={"tier"} className={"tier-cell"}>
          {c.vendorTier && c.vendorTierName && (
            <VendorTierBadge tier={c.vendorTier} name={c.vendorTierName} />
          )}
        </XTableCell>,
      ];

      const onClick = (evt: any) => {
        if (evt) {
          evt.stopPropagation();
        }
        props.onPreselectVendor && props.onPreselectVendor(c);
        if (
          props.supportUnwatchedVendors &&
          c.isWatched &&
          props.setImmediateMoveOnRecalc
        ) {
          props.setImmediateMoveOnRecalc(c.id);
        }
        if (c.isWatched) {
          props.onSelectVendor(true, c.id, c.name);
        }
        if (props.supportUnwatchedVendors) {
          // make sure the vendors page doesn't inherit the search terms that are used here.
          props.dispatch(
            setVendorSearch({
              query: "",
              results: {},
            })
          );
        }
        if (props.supportUnwatchedVendors && !c.isWatched && props.onNext) {
          props.onNext(1);
        }
      };

      // only show the vendor type (Monitored/Unmonitored) if this is a free-trial account, since
      // only under this circumstance do we do a full vendor search (rather than just a monitored search)
      if (props.supportUnwatchedVendors) {
        cells.push(
          <XTableCell key={"type"} className={"type-cell"}>
            {c.isWatched && (
              <PillLabel color={LabelColor.Blue}>Monitored</PillLabel>
            )}
            {!c.isWatched && (
              <PillLabel color={LabelColor.Red}>Unmonitored</PillLabel>
            )}
          </XTableCell>
        );
        cells.push(
          <XTableCell key={"button"} className={"button-cell"}>
            {props.onNext && (
              <Button
                primary
                onClick={(evt) => onClick(evt)}
                loading={props.loadingReportGen == c.id}
              >
                Generate report
              </Button>
            )}
          </XTableCell>
        );
      }

      rows.push({
        id: c.id,
        cells: cells,
        selectionDisabled:
          props.assessedVendorsOnly &&
          !c.assessments?.some((a) => !!a.lastPublishedDate),
        selected: props.selectedVendorIDs.includes(c.id),
        onClick: () => onClick(undefined),
      });
    };

    vendorResult.map((v) => {
      addRow(v);
    });
    return rows;
  };

  const currentPage = pageOpts.pageNum;
  const totalPages = pageOpts.totalPages;

  const onPageChange = (pageNum: number) => {
    setPageOpts((opts) => ({ ...opts, pageNum }));
    if (!props.supportUnwatchedVendors) {
      fetchVendors({ pageOpts: { ...pageOpts, pageNum } });
    } else {
      const pageSize = pageOpts?.pageSize ?? VENDORS_PAGESIZE;
      setVendorResult(
        props.allVendorsSearchResult.slice(
          (pageNum - 1) * pageSize,
          Math.min(pageNum * pageSize, props.allVendorsSearchResult.length)
        )
      );
    }
  };

  const { sortDir, sortBy } = pageOpts;

  const onSortChange = (columnId: string, newSortDir: SortDirection) => {
    setPageOpts((opts) => ({ ...opts, sortBy: columnId, sortDir: newSortDir }));
    if (!props.supportUnwatchedVendors) {
      fetchVendors({
        pageOpts: { ...pageOpts, sortBy: columnId, sortDir: newSortDir },
      });
    } else {
      // this is a set of 'all-vendors' search  results. sort the allVendorsResult list (where our complete set of vendor search results are stored)
      // and then re-extract the current page
      const sortedAllVendors = [...props.allVendorsSearchResult];
      switch (columnId) {
        case "name":
          sortedAllVendors.sort((a, b) => {
            const aname = a.display_name ?? a.name;
            const bname = b.display_name ?? b.name;
            return aname < bname ? -1 : aname > bname ? 1 : 0;
          });
          break;
        case "score":
          sortedAllVendors.sort((a, b) => {
            return a.cstar_score < b.cstar_score
              ? -1
              : a.cstar_score > b.cstar_score
                ? 1
                : 0;
          });
          break;
        case "tier":
          sortedAllVendors.sort((a, b) => {
            return (a.vendorTier ?? Number.MAX_VALUE) <
              (b.vendorTier ?? Number.MAX_VALUE)
              ? -1
              : (a.vendorTier ?? Number.MAX_VALUE) >
                  (b.vendorTier ?? Number.MAX_VALUE)
                ? 1
                : 0;
          });
          break;
        case "type":
          sortedAllVendors.sort((a, b) => {
            const aw = a.isWatched ?? "M";
            const bw = b.isWatched ?? "U";
            return aw < bw ? -1 : aw > bw ? 1 : 0;
          });
          break;
      }
      if (newSortDir == SortDirection.DESC) {
        sortedAllVendors.reverse();
      }
      const pageNum = pageOpts.pageNum ? pageOpts.pageNum - 1 : 0;
      const pageSize = pageOpts.pageSize ?? VENDORS_PAGESIZE;
      const vendorResult = sortedAllVendors.slice(
        pageNum * pageSize,
        Math.min((pageNum + 1) * pageSize, props.allVendorsSearchResult.length)
      );
      setVendorResult(vendorResult);
      //setAllVendorsResult(sortedAllVendors);
      props.setAllVendorsSearchResult(sortedAllVendors);
    }
  };

  const _onSearchChange = _debounce((searchString: string) =>
    fetchVendors({ namePrefix: searchString })
  );

  const onSearchChange = (searchString: string) => {
    if (searchString.length == 0 || searchString.length > 2) {
      _onSearchChange(searchString);
    }
    props.setFilterText(searchString);
  };

  if (props.vendorPortfoliosSupported) {
    supportedFilters.push(FilterTypes.VENDOR_PORTFOLIO);
  }

  useEffect(() => {
    props.setFiltersActive &&
      props.filters &&
      props.setFiltersActive(isFilterActive(props.filters, supportedFilters));
  }, [props.filters]);

  const looksLikeDomain = validateUrl(props.filterText);
  const rows = getRows();

  const buildTable = () => {
    return (
      <XTable
        rows={rows}
        columnHeaders={headers}
        stickyColumnHeaders
        className={"vendor-table"}
        numLoadingRows={5}
        selectable={!props.supportUnwatchedVendors}
        onSelectClick={(id) => {
          props.onSelectVendor(
            true,
            id as number,
            vendorResult.find((v) => v.id === (id as number))?.name ?? ""
          );
        }}
        radioSelector={true}
        loading={vendorsLoading || props.loading}
        pagination={{
          totalPages: totalPages ?? 0,
          currentPage: currentPage ?? 1,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
        sortedBy={
          sortBy && sortDir
            ? { columnId: sortBy, direction: sortDir }
            : undefined
        }
        onSortChange={onSortChange}
      />
    );
  };

  return (
    <>
      <div id={"report-vendor-selector"}>
        <div className={"search"}>
          <SearchBox
            autoFocus
            onChanged={onSearchChange}
            inputRef={searchInputRef}
            returnFocusOnClear
            value={props.filterText}
            placeholder={`Search for a ${props.vendorWords.singular} by name or URL`}
            disabled={domainScanning}
          />
        </div>
        {(rows.length > 0 || vendorsLoading) && <>{buildTable()}</>}
        {rows.length === 0 &&
          props.filterText &&
          !props.loading &&
          !vendorsLoading && (
            <>
              {props.supportUnwatchedVendors ? (
                !looksLikeDomain ? (
                  <SearchEmptyCard
                    searchString={props.filterText}
                    searchTextOverride={`No ${props.vendorWords.plural} found`}
                    onClear={() => {
                      onSearchChange("");
                      searchInputRef.current?.focus();
                    }}
                    searchItemText={props.vendorWords.plural}
                    subTextOverride={`Try searching by URL to identify the ${props.vendorWords.singular}.`}
                  />
                ) : (
                  <SearchEmptyCardWithDomainScan
                    dispatch={props.dispatch}
                    fetchVendors={fetchVendors}
                    pageOpts={pageOpts}
                    searchString={props.filterText}
                    vendorWords={props.vendorWords}
                    searchItemText={props.vendorWords.plural}
                    onScanStartStop={(state: boolean) => {
                      setDomainScanning(state);
                    }}
                    onClear={() => {
                      onSearchChange("");
                      searchInputRef.current?.focus();
                    }}
                  />
                )
              ) : (
                <SearchEmptyCard
                  searchString={props.filterText}
                  onClear={() => {
                    onSearchChange("");
                    searchInputRef.current?.focus();
                  }}
                  searchItemText={props.vendorWords.plural}
                  subTextOverride={
                    props.assessedVendorsOnly
                      ? `Can't find the ${props.vendorWords.singular} you are looking for? Complete a risk assessment for that ${props.vendorWords.singular} to generate a risk assessment report.`
                      : undefined
                  }
                />
              )}
            </>
          )}
        {rows.length === 0 &&
          !props.loading &&
          !vendorsLoading &&
          props.filters &&
          isFilterActive(props.filters, supportedFilters) &&
          props.filterText === "" && (
            <>
              {props.supportUnwatchedVendors ? (
                <SearchEmptyCard
                  onClear={() => {
                    props.dispatch(
                      setCustomerDataFiltersAndRefreshData({
                        ...clearedVendorFilters,
                      })
                    );
                    fetchVendors({ namePrefix: "", pageOpts: { ...pageOpts } });
                    searchInputRef.current?.focus();
                  }}
                  hideSubText
                  searchItemText={props.vendorWords.plural}
                  clearText={"Clear filters"}
                />
              ) : (
                <SearchEmptyCard
                  onClear={() => {
                    props.dispatch(
                      setCustomerDataFiltersAndRefreshData({
                        ...clearedVendorFilters,
                      })
                    );
                    fetchVendors({ namePrefix: "", pageOpts: { ...pageOpts } });
                    searchInputRef.current?.focus();
                  }}
                  hideSubText
                  searchItemText={props.vendorWords.plural}
                  clearText={"Clear filters"}
                />
              )}
            </>
          )}
        {rows.length === 0 &&
          !props.filterText &&
          !props.loading &&
          !vendorsLoading && (
            <>
              {props.supportUnwatchedVendors ? (
                <SearchEmptyCard
                  searchTextOverride={`Search for a ${props.vendorWords.singular}`}
                  searchItemText={props.vendorWords.plural}
                  subTextOverride={`Enter a company's name or URL to start your search.`}
                />
              ) : (
                <SearchEmptyCard
                  searchItemText={props.vendorWords.plural}
                  subTextOverride={
                    props.assessedVendorsOnly
                      ? `Sorry, you currently have no ${props.vendorWords.plural} with a published assessment. Complete a risk assessment for any ${props.vendorWords.singular} to generate a risk assessment report.`
                      : `Sorry, you are not watching any ${props.vendorWords.plural}. Add ${props.vendorWords.plural} to your account to generate a ${props.vendorWords.singular}-based risk report.`
                  }
                />
              )}
            </>
          )}
      </div>
    </>
  );
};

const emptyArray: number[] = [];

export default appConnect<
  IReportVendorSelectorConnectedProps,
  never,
  IReportVendorSelectorOwnProps
>((state: DefaultRootState, props: IReportVendorSelectorOwnProps) => {
  const vendorPortfoliosSupported =
    state.common.userData.orgPermissions.includes(OrgAccessVendorPortfolios);

  const enabledPortfolioIDs: number[] = [];
  const disabledPortfolioIDs: number[] = [];

  if (
    vendorPortfoliosSupported &&
    !state.common.userData.userPermissions.includes(UserVendorRiskWrite)
  ) {
    Object.entries(
      state.common.userData.vendorPortfolioSpecificPermissions
    ).forEach(([portfolioId, perms]) => {
      if (perms?.includes(UserVendorRiskWrite)) {
        enabledPortfolioIDs.push(parseInt(portfolioId));
      } else {
        disabledPortfolioIDs.push(parseInt(portfolioId));
      }
    });
  }

  const vendorWatchLimit = state.cyberRisk.customerData.vendorWatchLimit;
  const vendorWatchCount = state.cyberRisk.customerData.vendorWatchCount;

  const ret: IReportVendorSelectorConnectedProps = {
    filters: props.useStateFilters ? getFiltersFromState(state) : undefined,
    vendorPortfoliosSupported,
    enabledPortfolioIDs:
      enabledPortfolioIDs.length > 0 ? enabledPortfolioIDs : emptyArray,
    disabledPortfolioIDs:
      disabledPortfolioIDs.length > 0 ? disabledPortfolioIDs : emptyArray,
    vendorWatchCount: vendorWatchCount,
    vendorWatchLimit: vendorWatchLimit,
  };

  return ret;
})(ReportVendorSelectorTable);
