import { FC } from "react";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Doughnut, { DoughnutDataPoint } from "../Doughnut";
import { Grades } from "../../../_common/helpers";

import "./GradedUsersOverview.scss";
import classnames from "classnames";

interface GradedUsersOverviewProps {
  // The uids of the users to display
  userUUIDs: string[];

  // Allow for custom class names
  className?: string;

  // onClick handler for the view all button
  onClick?: () => void;
}

const GradedUsersOverview: FC<GradedUsersOverviewProps> = ({
  userUUIDs,
  className,
  onClick,
}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUsersV1Query({
    userUUIDs: userUUIDs,
  });

  if (!isLoading && !data?.users) {
    return <></>;
  }

  const users = data?.users ?? [];

  // Group all the users into their respective grades
  const gradedUserCount = Grades.map((grade) => {
    // Cound the number of users that meet this grade
    const count = users.filter(
      (user) => user.rating >= grade.min && user.rating <= grade.max
    ).length;

    return {
      grade,
      count,
    };
  });

  // Remove any grades that have no users
  const gradedUserCountsToDisplay = gradedUserCount.filter(
    (gradedUserCount) => gradedUserCount.count > 0
  );

  // Map this into dougnut data points
  const doughnutData: DoughnutDataPoint[] = gradedUserCountsToDisplay.map(
    (gradedUserCount) => {
      return {
        label: `User rating ${gradedUserCount.grade.label}`,
        count: gradedUserCount.count,
        hexColor: gradedUserCount.grade.color,
      };
    }
  );

  // Sort the data points by grade
  doughnutData.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div
      className={classnames("graded-users-overview", className)}
      onClick={onClick}
    >
      {isLoading && <LoadingBanner />}
      {!isLoading && data && (
        <div className={"content"}>
          <Doughnut data={doughnutData} totalLabel={"Total users"} />
        </div>
      )}
    </div>
  );
};

export default GradedUsersOverview;
