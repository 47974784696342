import { DefaultRootState } from "react-redux";
import { Dispatch } from "redux";
import { vsaqQuestion } from "../../survey_builder/vsaq/vsaqTypes";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { SurveyUsageType } from "../../_common/types/surveyTypes";
import {
  ISurveyVersion,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../../_common/types/survey";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";

export interface Answers {
  [nodeOrAnswerId: string]: string;
}

export interface QuestionnaireAndAnswers {
  structure: vsaqQuestion[];
  hasRequiredAttachments: boolean;
  includeTOC: boolean;
  usageType: SurveyUsageType;
  userMessage?: string;

  logoUrl: string;
  issuingOrgName: string;

  answers: Answers;
  locked: boolean;
  lockedBy: string;
  message: string;
  status: string;
  id: number;
  vendorName: string;
  vendorId: number;
  datastoreVendorId: number;
  privateSharedWithOrg: boolean;
  isArchived: boolean;
  versions: ISurveyVersion[];
  isExported: boolean;
  isSystemSurvey: boolean;
  isGapSurvey: boolean;
  riskVisibility: SurveyRiskVisibility;
  alwaysVisibleRiskIDs: string[];
  editLocked: boolean;
  surveyImportUUID?: string;
  managedAssessmentId?: number;
  customRiskIDs?: number[];
  riskAdjustments?: IVendorRiskWaiver[];
  isCollaborator: boolean;
  surveyStatus: SurveyStatus;
}

export const SET_QUESTIONNAIRE_ANSWERS = "SET_QUESTIONNAIRE_ANSWERS";
export const setQuestionnaireAnswers = (
  id: number,
  version: number,
  questionnaire: QuestionnaireAndAnswers | undefined
) => {
  return {
    type: SET_QUESTIONNAIRE_ANSWERS,
    id,
    version,
    questionnaire,
  };
};

// clearQuestionnaire
// clears all questionnaire answers for a given survey
export const clearQuestionnaire =
  (surveyId: number) =>
  async (dispatch: Dispatch, getState: () => DefaultRootState) => {
    const { questionnaireAnswers } = getState().cyberRisk;
    Object.keys(questionnaireAnswers).forEach((k) => {
      if (k.startsWith(`${surveyId}_`)) {
        const parts = k.split("_");
        if (parts.length < 2) {
          return;
        }
        dispatch(
          setQuestionnaireAnswers(surveyId, parseInt(parts[1]), undefined)
        );
      }
    });
  };

export const fetchQuestionnaire = (
  surveyId: number,
  editing: boolean,
  answerID = 0,
  force = false
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<QuestionnaireAndAnswers> => {
    if (
      !force &&
      !editing &&
      getState().cyberRisk.questionnaireAnswers[`${surveyId}_${answerID}`]
    ) {
      // If not refreshing or editing, we can use the cached version
      return getState().cyberRisk.questionnaireAnswers[
        `${surveyId}_${answerID}`
      ];
    }

    if (!editing) {
      dispatch(setQuestionnaireAnswers(surveyId, answerID, undefined));
    }

    let resp: QuestionnaireAndAnswers;
    try {
      resp = await FetchCyberRiskUrl(
        "survey/v1/",
        {
          survey_id: surveyId,
          editing: editing,
          answer_id: answerID || undefined,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching questionnaire`, e);
      throw e;
    }

    if (!editing) {
      dispatch(setQuestionnaireAnswers(surveyId, answerID, resp));
    }

    return resp;
  };
};

export interface SurveyPreviewRespV2 {
  structure: vsaqQuestion[];
  hasRequiredAttachments: boolean;
  includeTOC: boolean;
  usageType: SurveyUsageType;
  issuingOrgName: string;
  logoUrl: string;
  riskVisibility: SurveyRiskVisibility;
}

export const fetchQuestionnairePreview = (
  typeId: string,
  sectionId?: string,
  sectionName?: string,
  draft?: boolean,
  riskVisibility?: SurveyRiskVisibility
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<SurveyPreviewRespV2> => {
    let resp: SurveyPreviewRespV2;
    try {
      resp = await FetchCyberRiskUrl(
        "previewsurvey/v2/",
        {
          type_id: typeId,
          section_id: sectionId,
          section_name: sectionName,
          draft: draft,
          risk_visibility: riskVisibility,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching questionnaire preview`, e);
      throw e;
    }

    return resp;
  };
};

export const fetchPublicQuestionnaire = (
  surveyId: number,
  editing: boolean,
  answerId?: number
) => {
  return async (
    dispatch: Dispatch,
    getState: () => DefaultRootState
  ): Promise<QuestionnaireAndAnswers> => {
    let resp: QuestionnaireAndAnswers;
    try {
      resp = await FetchCyberRiskUrl(
        "prefilledsurvey/v1/",
        {
          survey_id: surveyId,
          answer_id: answerId,
          editing: editing,
        },
        undefined,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching questionnaire`, e);
      throw e;
    }

    return resp;
  };
};
