import { FC, ReactElement } from "react";
import "../style/components/TwoColumnDisplay.scss";
import classnames from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../types/label";
import classNames from "classnames";
import Asterisk from "./Asterisk";
import { SidePopupV2 } from "./DismissablePopup";

export interface TwoColumnDisplayRowProps {
  label?: React.ReactNode;
  required?: boolean; // adds a required asterisk to the label
  description?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  singleColumn?: boolean;
  hide?: boolean;
  isNewFeature?: boolean;
  noBottomBorder?: boolean; // this row will sit flush with the one below it if true
  tooltip?: React.ReactNode;
}

export const TwoColumnDisplayRow: FC<TwoColumnDisplayRowProps> = ({
  label,
  required,
  description,
  content,
  children,
  className,
  singleColumn,
  isNewFeature,
  noBottomBorder,
  tooltip,
}) => {
  const classes = classnames("two-column-display-row", className, {
    "single-column": singleColumn,
    "no-bottom-border": noBottomBorder,
  });

  let descriptionContent: React.ReactNode = <></>;

  if (
    description &&
    (typeof description === "string" || description instanceof String)
  ) {
    descriptionContent = (
      <p className={classNames({ "no-label": !label })}>{description}</p>
    );
  } else if (description) {
    descriptionContent = <>{description}</>;
  }

  return (
    <div className={classes}>
      <div className={"label-container"}>
        {label && (
          <div className={"label"}>
            <div className={"label-content"}>
              {label}
              {required && <Asterisk />}
              {tooltip && (
                <SidePopupV2 text={tooltip} popupHoverable>
                  <span className={"cr-icon-info"} />{" "}
                </SidePopupV2>
              )}
            </div>
            {!!isNewFeature && (
              <PillLabel color={LabelColor.Blue}>New Feature</PillLabel>
            )}
          </div>
        )}
        {descriptionContent}
      </div>
      <div>
        {content}
        {children}
      </div>
    </div>
  );
};

export interface TwoColumnDisplayProps {
  classname?: string;
  children:
    | (ReactElement<TwoColumnDisplayRowProps> | null | undefined | boolean)[]
    | ReactElement<TwoColumnDisplayRowProps>
    | null
    | undefined
    | boolean;
}

// Simple two column display
const TwoColumnDisplay: FC<TwoColumnDisplayProps> = ({
  classname,
  children,
}) => {
  const childrenAsArray = Array.isArray(children) ? children : [children];

  // This type predicate allows us to filter out falsy values below while keeping types.
  // Once we hit TS 5.5+ we can just do .filter(c => !!c)
  const eltPredicate = (
    c: ReactElement<TwoColumnDisplayRowProps> | null | undefined | boolean
  ): c is ReactElement<TwoColumnDisplayRowProps> => !!c;

  // If the react node is a string or number, use it as the list-component key. Otherwise, use the supplied index.
  const nodeOrIdx = (node: React.ReactNode, idx: number): React.Key => {
    if (typeof node === "string" || typeof node === "number") {
      return node;
    }

    return idx;
  };

  const visibleChildren = childrenAsArray
    .filter(eltPredicate)
    .filter((c) => !c.props.hide)
    .map((c, i) => (
      <CSSTransition
        key={nodeOrIdx(c.props.label, i)}
        timeout={250}
        classNames={"expand"}
      >
        {c}
      </CSSTransition>
    ));

  if (visibleChildren.length === 0) {
    return <></>;
  }

  const classes = classnames("two-column-display", classname);

  return (
    <div className={classes}>
      <TransitionGroup component={null}>{visibleChildren}</TransitionGroup>
    </div>
  );
};

export default TwoColumnDisplay;
