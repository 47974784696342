import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import { ISurveyMini } from "../../../_common/types/survey";
import { reminderSelectionType } from "../../../_common/components/remediationDetails/UpdateDueDateModal";
import InfoBanner, {
  BannerType,
  SubItemType,
} from "../../components/InfoBanner";
import ReportCard from "../../../_common/components/ReportCard";
import { formatDateAsLocal } from "../../../_common/helpers";
import ColorCheckbox from "../../components/ColorCheckbox";
import TextField from "../../../_common/components/TextField";
import moment from "moment";
import DatePicker from "../../../_common/components/DatePicker";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import ContactSelect, {
  ContactDisplay,
} from "../../components/contacts/ContactSelect";
import { UserEmailAddress } from "../../../_common/types/user";

interface SettingsStepOwnProps {
  existingUsers: IVendorContactResponse[];
  onContactSelectionChanged: (contacts: ContactDisplay[]) => void;
  selectedContacts: ContactDisplay[];
  hasInvalidVerifiedVendorContact?: boolean;
  emailMessage: string;
  setEmailMessage: (message: string) => void;
  title: string;
  setTitle: (title: string) => void;
  isSelfRemediation: boolean;
  isSubsidiary: boolean;
  isUserRisk?: boolean;
  dueDate?: string;
  setDueDate: (dueDate?: string) => void;
  reminderDate?: string;
  setReminderDate: (reminderDate?: string) => void;
  reminderSelection: reminderSelectionType;
  setReminderSelectionType: (selection: reminderSelectionType) => void;
  allSurveys: ISurveyMini[];
  selectedSurveyIds: number[];
  onClickToAddToExistingRequest?: () => void;
  vendorName?: string;
  selectedPublicSurveyIds?: number[];
  vendorId?: number;
  orgUsers?: UserEmailAddress[];
}

export const SettingsStep = ({
  onContactSelectionChanged,
  selectedContacts,
  hasInvalidVerifiedVendorContact,
  emailMessage,
  existingUsers,
  setEmailMessage,
  setTitle,
  title,
  isSelfRemediation,
  isSubsidiary,
  dueDate,
  setDueDate,
  reminderDate,
  setReminderDate,
  reminderSelection,
  setReminderSelectionType,
  allSurveys,
  selectedSurveyIds,
  onClickToAddToExistingRequest,
  vendorName,
  selectedPublicSurveyIds,
  vendorId,
  orgUsers,
  isUserRisk = false,
}: SettingsStepOwnProps) => {
  const fmt = "YYYY-MM-DD";

  const isPublicSurveySelected =
    selectedPublicSurveyIds && selectedPublicSurveyIds.length > 0;

  return (
    <div>
      {onClickToAddToExistingRequest && (
        <InfoBanner
          className={"edit-existing-suggestion"}
          type={BannerType.INFO}
          message={
            <>
              <div className={"info-header"}>
                Amending existing remediation requests is now supported
              </div>
              <div>{`There are existing remediation requests${
                isSubsidiary
                  ? " for this subsidiary"
                  : isSelfRemediation
                    ? ""
                    : " for this vendor"
              }. If you like, the selected risks can be added to an existing request rather than creating a new one.`}</div>
              <a onClick={onClickToAddToExistingRequest}>
                Add to existing request
                <div className={"cr-icon-arrow-right"} />
              </a>
            </>
          }
        />
      )}
      <ReportCard newStyles className={"settings-card"}>
        <div className="header">Settings</div>
        <div className="report-card-sections">
          <div className="report-card-section request-name-section">
            <div className="section-label">Request name</div>
            <div className="section-content">
              <TextField
                value={title}
                onChanged={setTitle}
                required
                requiredErrorText="Enter a request name"
              />
            </div>
          </div>
          <div className="report-card-section">
            <div className="section-label">Recipients</div>
            <div className="section-content">
              {selectedSurveyIds.length > 0 && (
                <InfoBanner
                  type={BannerType.INFO}
                  message={
                    "Please note, any contacts that you’ve added to this request will also be added as collaborators to the following questionnaires:"
                  }
                  subItemType={SubItemType.BULLET}
                  subItems={allSurveys
                    .filter((s) => selectedSurveyIds.includes(s.id))
                    .map(
                      (s) =>
                        `${s.name} (last updated ${formatDateAsLocal(
                          s.dateCompleted
                        )})`
                    )}
                />
              )}
              <ContactSelect
                onSelectionChanged={onContactSelectionChanged}
                initiallySelectedEmailAddresses={selectedContacts
                  .filter((c) => !c.isNewContact)
                  .map((c) => c.emailAddress)}
                initialNewContacts={selectedContacts.filter(
                  (c) => c.isNewContact
                )}
                vendorContacts={existingUsers}
                orgUsers={
                  isSelfRemediation || isUserRisk ? orgUsers : undefined
                }
                validateVerifiedVendorID={
                  isPublicSurveySelected ? vendorId : undefined
                }
                vendorName={vendorName}
                hideExistingContacts={isSubsidiary}
              />
              {isPublicSurveySelected && hasInvalidVerifiedVendorContact && (
                <InfoBanner
                  type={BannerType.ERROR}
                  message={`Email domain must be one of ${vendorName}'s domains`}
                />
              )}
            </div>
          </div>
          <div className="report-card-section schedule-section">
            <div className="section-label">Schedule</div>
            <div className="section-content">
              <div className={"due-date"}>
                <ColorCheckbox
                  label={"Set a due date"}
                  checked={!!dueDate}
                  onClick={() => {
                    if (!dueDate) {
                      setDueDate(moment().add(60, "days").format(fmt));
                    } else {
                      setDueDate(undefined);
                    }
                  }}
                />
                <p className={"indent"}>
                  Select a date you’d like the remediation completed by.
                </p>
                {!!dueDate && (
                  <div className={"due-date-inner indent"}>
                    <DatePicker
                      className={"due-date-picker"}
                      onChange={(evt) => setDueDate(evt.target.value)}
                      min={moment().format(fmt)}
                      disabled={!dueDate}
                      value={dueDate}
                      placeholder={"Select due date"}
                    />
                  </div>
                )}
              </div>
              {!!dueDate && (
                <div className={"reminder"}>
                  <ColorCheckbox
                    label={"Set a reminder"}
                    checked={reminderSelection !== "none"}
                    onClick={() =>
                      reminderSelection === "none"
                        ? setReminderSelectionType("30b")
                        : setReminderSelectionType("none")
                    }
                    disabled={!dueDate}
                  />
                  <p className={"indent"}>
                    We’ll remind the recipient of your request on the specified
                    date.
                  </p>
                  {reminderSelection !== "none" && (
                    <div className={"reminder-inner indent"}>
                      <ColorCheckbox
                        label={"30 days before due date"}
                        checked={reminderSelection === "30b"}
                        onClick={() => setReminderSelectionType("30b")}
                        radio
                      />
                      <ColorCheckbox
                        label={"7 days before due date"}
                        checked={reminderSelection === "7b"}
                        onClick={() => setReminderSelectionType("7b")}
                        radio
                      />
                      <ColorCheckbox
                        label={"Choose custom reminder date"}
                        checked={reminderSelection === "custom"}
                        onClick={() => setReminderSelectionType("custom")}
                        radio
                      />
                      <DatePicker
                        className={"reminder-picker indent"}
                        onChange={(evt) => setReminderDate(evt.target.value)}
                        min={moment().format(fmt)}
                        disabled={reminderSelection !== "custom"}
                        value={reminderDate}
                        placeholder={"Select reminder date"}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="report-card-section">
            <div className="section-label">Message</div>
            <div className="section-content">
              <div className="description">
                Include a custom message with your request. We also recommend
                telling the recipient a request is coming before it’s sent.
              </div>
              <RichTextEditV2
                className="description-editor"
                value={emailMessage}
                onChange={setEmailMessage}
              />
            </div>
          </div>
        </div>
      </ReportCard>
    </div>
  );
};
