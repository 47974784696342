import "./ThreatCommentsButton.scss";

import { CorrespondenceIconButton } from "../../vendorrisk/components/CorrespondenceIconButton";

interface ThreatCommentsButtonProps {
  totalMessages: number;
  haveUnreadMessages: boolean;
  onClick: () => void;
  disabled?: boolean;
  hiddenVisibilityAttentionLeft?: boolean;
}

const ThreatCommentsButton = (props: ThreatCommentsButtonProps) => {
  return (
    <div className="threat-monitoring-threat-comments-button">
      <CorrespondenceIconButton
        totalMessages={props.totalMessages}
        haveUnreadMessages={props.haveUnreadMessages}
        onClick={props.onClick}
        disabled={props.disabled}
        hiddenVisibilityAttentionLeft={props.hiddenVisibilityAttentionLeft}
        emptyDivOnNoMessages
      />
    </div>
  );
};

export default ThreatCommentsButton;
