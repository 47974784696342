import Modal, { BaseModalProps } from "../ModalV2";
import { useState } from "react";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import Button from "../core/Button";
import { DefaultThunkDispatchProp } from "../../types/redux";
import TextField, { MaxLengthType } from "../TextField";
import "../../style/components/remediationDetails/MarkAsRemediatedModal.scss";

export interface MarkRiskAsRemediatedModalOwnProps {
  onMarkAsRemediated: (justification?: string) => Promise<any>;
  riskName: string;
}

export type MarkRiskAsRemediatedModalProps = MarkRiskAsRemediatedModalOwnProps &
  DefaultThunkDispatchProp &
  BaseModalProps;

export const MarkRiskAsRemediatedModal = (
  props: MarkRiskAsRemediatedModalProps
) => {
  const [isMarkingAsRemediated, setIsMarkingAsRemediated] = useState(false);
  const [justification, setJustification] = useState<string | undefined>(
    undefined
  );

  const markAsRemediated = () => {
    setIsMarkingAsRemediated(true);
    props
      .dispatch(() => props.onMarkAsRemediated(justification))
      .then(() =>
        props.dispatch(
          addDefaultSuccessAlert(`"${props.riskName}" marked as remediated`)
        )
      )
      .then(() => props.onClose())
      .catch((e) => props.dispatch(addDefaultUnknownErrorAlert(e.message)))
      .finally(() => setIsMarkingAsRemediated(false));
  };

  return (
    <Modal
      headerContent={
        <h2>Are you sure you want to mark this risk as remediated?</h2>
      }
      headerClassName={"mark-risk-remediated-modal-header"}
      className={"mark-risk-remediated-modal"}
      active={props.active}
      onClose={() => {
        props.onClose();
      }}
      footerContent={
        <>
          <Button
            tertiary
            onClick={() => {
              props.onClose();
            }}
            disabled={isMarkingAsRemediated}
          >
            Close
          </Button>
          <Button
            primary
            onClick={markAsRemediated}
            loading={isMarkingAsRemediated}
            disabled={isMarkingAsRemediated}
          >
            Yes, mark as remediated
          </Button>
        </>
      }
    >
      <p>
        This will mark this risk as remediated and remove the identified risk
        from additional evidence for this vendor.
      </p>
      <div className={"input-label"}>Add justification</div>
      <TextField
        className={"justification-input"}
        value={justification ?? ""}
        onChanged={(val) => setJustification(val)}
        multiLine
        placeholder="Add justification"
        maxLength={MaxLengthType.message}
      />
    </Modal>
  );
};
