import { FC } from "react";
import RiskUserSelection from "../risks/RiskUserSelection";

interface SaaSRiskWaiverUsersSelectionProps {
  riskId: string;
  selectedUserUUIDs: string[];
  onSelectionChange: (selectedUUIDs: string[]) => void;
}

const SaaSRiskWaiverUsersSelection: FC<SaaSRiskWaiverUsersSelectionProps> = ({
  riskId,
  selectedUserUUIDs,
  onSelectionChange,
}) => {
  return (
    <RiskUserSelection
      riskId={riskId}
      selectedUserUUIDs={selectedUserUUIDs}
      onSelectionChange={onSelectionChange}
      headerTitle={"Select users to waive"}
      className={"userrisk-risk-waiver"}
    />
  );
};

export default SaaSRiskWaiverUsersSelection;
