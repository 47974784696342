import { FC, ReactNode, useState } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import { formatDateAsLocal, formatTimeAsLocal } from "../../_common/helpers";
import ReportCard from "../../_common/components/ReportCard";
import Icon from "../../_common/components/core/Icon";
import classnames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import { useLocation } from "react-router-dom";
import "./Timeline.scss";

const DATE_AND_TIME_COL = "date_and_time_col";
const ACTION_COL = "action_col";

const PAGE_SIZE = 50;

export interface TimelineEvent {
  id?: string;
  at: string;
  cell: ReactNode;
}

interface TimelineProps {
  events: TimelineEvent[];
  isLoading: boolean;
  classname?: string;
  title?: string;
  emptyText: string;
  emptySubtext: string;
  emptyIcon?: unknown;
}

const Timeline: FC<TimelineProps> = ({
  events,
  isLoading,
  classname,
  title,
  emptyText,
  emptySubtext,
  emptyIcon,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { pathname } = useLocation();

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: DATE_AND_TIME_COL,
      text: "Date and time",
      sortable: true,
      className: "date-and-time-col",
    },
    {
      id: ACTION_COL,
      text: "Action",
      sortable: false,
    },
  ];

  // Sort and Paginate the users
  const [
    eventsToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    TimelineEvent,
    typeof DATE_AND_TIME_COL | typeof ACTION_COL
  >(
    events,
    DATE_AND_TIME_COL,
    SortDirection.ASC,
    {
      [DATE_AND_TIME_COL]: {
        orderFuncs: [(e) => e.at],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ACTION_COL]: {
        orderFuncs: [(e) => e.cell?.toLocaleString()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const rows = eventsToDisplay.map<IXTableRow>((e) => {
    return {
      id: e.id ?? e.at,
      cells: [
        <XTableCell key={"date"}>
          {formatDateAsLocal(e.at)} {formatTimeAsLocal(e.at)}
        </XTableCell>,
        e.cell,
      ],
    };
  });

  const hasNoEvents = !isLoading && events.length === 0;

  return (
    <ReportCard
      newStyles
      className={classnames("userbase-event-timeline", classname)}
    >
      <div className="header" onClick={() => setIsCollapsed(!isCollapsed)}>
        {title ?? "Timeline"}
        <div className={"header-chevron"}>
          <Icon name="chevron" direction={isCollapsed ? 180 : 0} />
        </div>
      </div>
      <div className={"timeline-container"}>
        <TransitionGroup component={null}>
          {!isCollapsed && (
            <CSSTransition
              key={"expand-timeline"}
              timeout={250}
              classNames="expand"
            >
              {hasNoEvents ? (
                <EmptyCardWithAction
                  emptyText={emptyText}
                  emptySubText={emptySubtext}
                  iconSrc={emptyIcon}
                />
              ) : (
                <XTable
                  className={"event-timeline-table"}
                  loading={isLoading}
                  sortedBy={sortedBy}
                  onSortChange={onSortChange}
                  stickyColumnHeaders={false}
                  numLoadingRows={3}
                  rows={rows}
                  pagination={{
                    currentPage,
                    totalPages,
                    onPageChange,
                    hidePaginationIfSinglePage: true,
                  }}
                  columnHeaders={columnHeaders}
                />
              )}
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </ReportCard>
  );
};

export default Timeline;
