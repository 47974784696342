export enum LabelClassification {
  SystemLabel = "S",
  VendorLabel = "V",
  WebsiteLabel = "W",
}

export enum LabelColor {
  Grey = "grey",
  LightGrey = "lightgrey",
  MediumGrey = "mediumgrey",
  DarkGrey = "darkgrey",
  Blue = "blue",
  LightBlue = "lightblue",
  Green = "green",
  Red = "red",
  Yellow = "yellow",
  Orange = "orange",
  Black = "black",
  Gimblet = "gimblet",
  PersianGreen = "persiangreen",
  Violet = "violet",
  Fuchsia = "fuchsia",
  TrendyPink = "trendypink",
  PictonBlue = "pictonblue",
  MountainMeadow = "mountainmeadow",
  Bahia = "bahia",
  Grey500 = "grey500",
}

export interface ILabel {
  id: number;
  organisationID: number;
  name: string;
  colour: LabelColor;
  classification: LabelClassification;
  description: string;
}
