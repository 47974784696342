import { FC } from "react";
import { useLocation, useHistory } from "react-router-dom";

import SlidePanelKVTable from "../SlidePanelKVTable";
import SecurityRatingDisplay, { RatingSize } from "../SecurityRatingDisplay";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import userbaseApi from "../../api/userbase.api";
import { UserApp } from "../../api/types";
import "./SaaSUserRiskPanel.scss";
import { userSummaryUrl, appSummaryUrl } from "../../UserBaseAppRouter";

import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../../_common/hooks";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import ScrollableDiv from "../../../vendorrisk/components/ScrollableDiv";
import SlidePanelSection from "../../../vendorrisk/components/filter/SlidePanelSection";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import Button from "../../../_common/components/core/Button";
import Score from "../../../vendorrisk/components/Score";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";

const PAGE_SIZE = 50;

const APP_NAME_COL = "name_col";
const SECURITY_RATING_COL = "rating_col";
const USER_EXPOSURE_COL = "exposure_col";

interface AppsTableProps {
  isLoading: boolean;
  apps: UserApp[];
}

const AppsTable: FC<AppsTableProps> = ({ isLoading, apps }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: APP_NAME_COL,
      text: "App name",
      sortable: true,
    },
    {
      id: SECURITY_RATING_COL,
      text: "Security rating",
      sortable: true,
    },
    {
      id: USER_EXPOSURE_COL,
      text: "User exposure",
      sortable: true,
    },
  ];

  const [
    sortedApps,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserApp,
    typeof APP_NAME_COL | typeof SECURITY_RATING_COL | typeof USER_EXPOSURE_COL
  >(
    apps,
    APP_NAME_COL,
    SortDirection.ASC,
    {
      [APP_NAME_COL]: {
        orderFuncs: [(a) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(a) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [USER_EXPOSURE_COL]: {
        orderFuncs: [(a) => a.highestScopeRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const rows = sortedApps.map((userApp: UserApp): IXTableRow<string> => {
    return {
      id: userApp.name,
      onClick: () => {
        history.push(appSummaryUrl(userApp.name));
      },
      cells: [
        <XTableCell key={APP_NAME_COL}>
          <CompanyLogo
            domain={userApp.domain}
            name={userApp.name}
            displayCategory={true}
            category={userApp.industry}
          />
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <SecurityRatingDisplay
            rating={userApp.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={USER_EXPOSURE_COL}>
          <ScopeRiskLevelDisplay riskLevel={userApp.highestScopeRiskLevel} />
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => {
            history.push(appSummaryUrl(userApp.name));
          },
        },
      ],
    };
  });

  return (
    <XTable
      className="saas-user-risk-panel-apps-table"
      stickyColumnHeaders={false}
      numLoadingRows={3}
      columnHeaders={columnHeaders}
      rows={rows}
      loading={isLoading}
      iconOptions
      sortedBy={sortedBy}
      onSortChange={onSortChange}
      pagination={{
        currentPage: currentPage,
        totalPages: totalPages,
        onPageChange: onPageChange,
        hidePaginationIfSinglePage: true,
      }}
    />
  );
};

interface SaaSUserRiskPanelProps {
  userUUID: string;
}

const SaaSUserRiskPanel: FC<SaaSUserRiskPanelProps> = ({ userUUID }) => {
  const { data: userData, isLoading: userDataIsLoading } =
    userbaseApi.useGetUserBaseUsersV1Query({
      userUUIDs: [userUUID],
    });

  const { data: userApps, isLoading: userAppsIsLoading } =
    userbaseApi.useGetUserBaseUserAppsV1Query({
      userUUID: userUUID,
    });

  const user = userData?.users[0];

  const history = useHistory();

  return (
    <div className="saas-user-risk-panel">
      <div className="title-and-buttons">
        <div className="saas-user-title">{user?.name ?? "No name"}</div>
        <div className="saas-user-title-buttons">
          <Button
            arrow
            onClick={() => history.push(userSummaryUrl(user?.uuid ?? ""))}
          >
            View user profile
          </Button>
        </div>
      </div>

      <ScrollableDiv newStyles isLoading={userDataIsLoading}>
        <SlidePanelSection title="User overview">
          <SlidePanelKVTable
            rows={[
              { key: "Role", value: user?.roles?.at(0) ?? "-" },
              {
                key: "Team",
                value: <PillLabel>{user?.teams?.at(0) ?? "-"}</PillLabel>,
              },
              {
                key: "User Rating",
                value: (
                  <div className="score-container">
                    <ColorGrade
                      score={user?.rating}
                      size={ColorGradeSize.Small}
                    />
                    <Score score={user?.rating} small />
                  </div>
                ),
              },
              { key: "Total Apps", value: user?.totalApps },
              { key: "Not Approved Apps", value: user?.notApprovedApps },
            ]}
          />
        </SlidePanelSection>

        <SlidePanelSection title="Detected apps">
          <AppsTable
            isLoading={userAppsIsLoading}
            apps={userApps?.apps ?? []}
          />
        </SlidePanelSection>
      </ScrollableDiv>
    </div>
  );
};

export default SaaSUserRiskPanel;
