import { DefaultRouteProps } from "../../_common/types/router";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import PageHeader from "../../_common/components/PageHeader";
import ContentLibraryAPI, {
  updateDocumentV1Req,
} from "../api/contentLibraryAPI";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { ErrorCardWithAction } from "../../_common/components/EmptyCardWithAction";
import ReportCard from "../../_common/components/ReportCard";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import {
  useBasicPermissions,
  UserWriteContentLibrary,
  UserWritePrefilledSurveys,
} from "../../_common/permissions";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../_common/components/InfoTable";
import "../styles/ContentLibraryDocument.scss";
import TextField from "../../_common/components/TextField";
import moment from "moment/moment";
import DatePicker from "../../_common/components/DatePicker";
import ToggleWithLabel from "../../vendorrisk/components/ToggleWithLabel";
import { UserAvatarAndName } from "../../_common/components/UserAvatar";
import Button from "../../_common/components/core/Button";
import { downloadContentLibraryDocumentFile } from "../api/downloadDocument";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  AcceptedFileTypes,
  MaxFileSizeB,
} from "../../_common/types/fileRestrictions";
import FileDropzone from "../../_common/components/FileDropzone";
import ContentLibraryDocumentVersionsSection from "../components/DocumentVersionsSection";
import DocumentHistoryCard from "../components/DocumentHistoryCard";
import {
  useDeleteDocuments,
  useSetDocumentsArchived,
} from "../types/contentLibraryHooks";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import { chatGPTAccessSelector } from "../../_common/chatgpt";
import { useModalV2 } from "../../_common/components/ModalV2";
import GptAutofillConfigureSourceModal from "../../survey_viewer/components/modals/GptAutofillConfigureSourceModal";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import {
  ContentLibraryDocument,
  ContentLibraryQuestionnaireDocumentType,
  fileEligibleForQAColumnStep,
} from "../types/contentLibrary.types";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import Dot from "../../_common/components/Dot";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import DocumentTypeSelector from "../components/DocumentTypeSelector";
import { usePrevious } from "../../vendorrisk/helpers/util";
import { LogError } from "../../_common/helpers";
import TrackedButton from "../../_common/components/TrackedButton";

export const useToggleDocumentInSharedProfile = (
  defaultDoc?: ContentLibraryDocument
) => {
  const dispatch = useAppDispatch();
  const [updateIncludeInSharedProfile] =
    ContentLibraryAPI.useUpdateContentLibraryDocumentSharedProfileMutation();

  return useCallback(
    async (doc?: ContentLibraryDocument) => {
      const useDoc = doc ?? defaultDoc;
      if (!useDoc) {
        return;
      }

      try {
        const include = !useDoc.includeInSharedProfile;
        await updateIncludeInSharedProfile({
          uuid: useDoc.uuid,
          includeInSharedProfile: include,
        }).unwrap();
        if (include) {
          dispatch(
            addDefaultSuccessAlert(
              `${useDoc.name} now included in your Trust Page`
            )
          );
        } else {
          dispatch(
            addDefaultSuccessAlert(
              `${useDoc.name} has been removed from your Trust Page`
            )
          );
        }
      } catch (e) {
        dispatch(addDefaultUnknownErrorAlert("Error updating document"));
      }
    },
    [dispatch, updateIncludeInSharedProfile, defaultDoc]
  );
};

const useDocumentTextFieldUpdater = (
  docUUID: string | undefined,
  fieldName: keyof Pick<
    updateDocumentV1Req,
    "name" | "description" | "internalNotes"
  >,
  persistedValue: string,
  minLength = 0
): [string, (newVal: string) => void, () => void] => {
  const [updateDocument] =
    ContentLibraryAPI.useUpdateContentLibraryDocumentMutation();

  const [tempVal, setTempVal] = useState<string | undefined>(undefined);

  const setFieldValue = useCallback((newVal: string) => {
    setTempVal(newVal);
  }, []);

  useEffect(() => {
    if (tempVal === persistedValue) {
      setTempVal(undefined);
    }
  }, [tempVal, persistedValue]);

  const persistField = useCallback(() => {
    if (typeof tempVal === "undefined" || typeof docUUID === "undefined") {
      return;
    }

    if (minLength === 0 || tempVal.length >= minLength) {
      updateDocument({
        uuid: docUUID,
        [fieldName]: tempVal,
      });
    }

    setTempVal(undefined);
  }, [minLength, docUUID, updateDocument, fieldName, tempVal]);

  return [
    typeof tempVal === "undefined" ? persistedValue : tempVal,
    setFieldValue,
    persistField,
  ];
};

export interface ContentLibraryDocumentLocationState {
  isNewDocument?: boolean;
}

type IContentLibraryDocumentProps = DefaultRouteProps<
  {
    uuid: string;
  },
  ContentLibraryDocumentLocationState
>;

const ContentLibraryDocumentView: FC<IContentLibraryDocumentProps> = ({
  history,
  location: { state: locationState },
  match: {
    params: { uuid },
  },
}) => {
  const dispatch = useAppDispatch();

  const { userPermissions } = useBasicPermissions();
  const userHasContentLibraryWrite = !!userPermissions[UserWriteContentLibrary];
  const userHasSharedProfileWrite =
    !!userPermissions[UserWritePrefilledSurveys];
  const currentOrg = useCurrentOrg();
  const { shouldDisplayChatGPT } = useAppSelector(chatGPTAccessSelector);
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  const backAction = useCallback(() => {
    history.location.state?.backContext?.goBack
      ? history.goBack()
      : history.push(
          history.location.state?.backContext?.backTo ?? "/contentlibrary"
        );
  }, [history]);

  const [deleteDocuments, deleteDocumentsModal, isDeleted] =
    useDeleteDocuments(backAction);

  // We'll poll the endpoint every 3 seconds until we know the latest version of the document has been virus scanned.
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(
    3000
  );
  const { data, error, isLoading, refetch } =
    ContentLibraryAPI.useGetContentLibraryDocumentQuery(
      {
        uuid,
      },
      {
        pollingInterval,
        skip: isDeleted.current, // Don't try and refetch if we've just deleted the doc
      }
    );

  const [setDocumentsArchived, setDocumentsArchivedModal] =
    useSetDocumentsArchived(data?.document);

  // Q&A columns are editable when this is a Questionnaire and the latest version has a link to
  // an autofill external document record.
  const canEditQAColumnsFunc = useCallback(
    (docType: string) => {
      return (
        shouldDisplayChatGPT &&
        userHasContentLibraryWrite &&
        data?.document &&
        !data.document.archived &&
        docType === ContentLibraryQuestionnaireDocumentType &&
        fileEligibleForQAColumnStep(data.document.versions[0].filename) &&
        !!data.document.versions[0].qrdExternalDocsUUID
      );
    },
    [shouldDisplayChatGPT, userHasContentLibraryWrite, data?.document]
  );

  const canEditQAColumns = canEditQAColumnsFunc(
    data?.document.documentType ?? ""
  );

  const qaColumnsProcessing =
    canEditQAColumns && !!data?.document.versions[0].qrdExternalDocProcessing;
  const qaColumnsUnconfigured =
    canEditQAColumns && !data?.document.versions[0].qrdExternalDocProcessed;

  useEffect(() => {
    if (data) {
      if (
        qaColumnsProcessing ||
        data.document.versions.find((v) => !v.virusScanned)
      ) {
        // If any document versions have not been virus scanned, or we're waiting for the Q&A columns to be processed,
        // keep polling for results
        setPollingInterval(3000);
      } else {
        // We can stop polling now as the virus scan was successful
        setPollingInterval(undefined);
      }
    }
  }, [data, qaColumnsProcessing]);

  const [documentName, setDocumentName, persistDocumentName] =
    useDocumentTextFieldUpdater(
      data?.document.uuid,
      "name",
      data?.document.name ?? "",
      2
    );
  const [description, setDescription, persistDescription] =
    useDocumentTextFieldUpdater(
      data?.document.uuid,
      "description",
      data?.document.description ?? ""
    );
  const [internalNotes, setInternalNotes, persistInternalNotes] =
    useDocumentTextFieldUpdater(
      data?.document.uuid,
      "internalNotes",
      data?.document.internalNotes ?? ""
    );

  const [updateDocument] =
    ContentLibraryAPI.useUpdateContentLibraryDocumentMutation();

  const onChangeExpiryDate = useCallback(
    (newDate: string) => {
      const updateParams: updateDocumentV1Req = { uuid };
      if (newDate) {
        updateParams.expiresAt = newDate;
      } else {
        updateParams.clearExpiresAt = true;
      }

      updateDocument(updateParams);
    },
    [updateDocument, uuid]
  );

  const updatedByUser = useMemo(() => {
    return data ? data.sharedUsers[data.document.updatedBy] : undefined;
  }, [data]);

  const [downloadingVersion, setDownloadingVersion] = useState<
    number | undefined
  >(undefined);
  const downloadDocumentVersion = useCallback(
    async (version: number) => {
      // Version 0 is the 'latest' version
      setDownloadingVersion(version);

      try {
        await dispatch(
          downloadContentLibraryDocumentFile(
            uuid,
            version > 0 ? version : undefined
          )
        );
      } catch (e) {
        LogError("error downloading document", e);
        dispatch(addDefaultUnknownErrorAlert("Error downloading document"));
      }

      setDownloadingVersion(undefined);
    },
    [dispatch, uuid]
  );

  // Shared profile toggling
  const toggleIncludeInSharedProfile = useToggleDocumentInSharedProfile(
    data?.document
  );

  // File uploading stuff
  const [uploadDocumentVersion] =
    ContentLibraryAPI.useUploadContentLibraryDocumentVersionMutation();
  const onFileDrop: DropzoneOptions["onDrop"] = useCallback(
    async (acceptedFiles: File[], _rejectedFiles: File[]) => {
      if (acceptedFiles.length === 1) {
        try {
          await uploadDocumentVersion({
            uuid: uuid,
            file: acceptedFiles[0],
          }).unwrap();

          dispatch(addDefaultSuccessAlert("Successfully uploaded new version"));
        } catch (e) {
          LogError("error uploading document", e);
          dispatch(addDefaultUnknownErrorAlert("Error uploading file"));
        }
      } else {
        dispatch(
          addDefaultWarningAlert("Please upload a single file at a time.")
        );
      }
    },
    [uploadDocumentVersion, uuid, dispatch]
  );

  // Set up a dropzone for uploading files anywhere in the card.
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openUploadDialog,
  } = useDropzone({
    maxSize: MaxFileSizeB,
    accept: AcceptedFileTypes,
    onDrop: onFileDrop,
    disabled: !userHasContentLibraryWrite || data?.document.archived,
    noClick: true,
  });

  const [openGptAutofillConfigureSourceModal, gptAutofillConfigureSourceModal] =
    useModalV2(GptAutofillConfigureSourceModal);
  const editQAColumns = useCallback(
    (showSkipButton?: boolean) => {
      const externalDocUUID = data?.document.versions[0].qrdExternalDocsUUID;
      if (!externalDocUUID) {
        return;
      }

      const openConfigureModal = () =>
        openGptAutofillConfigureSourceModal({
          externalDocUUID,
          cancelText: showSkipButton ? "Skip" : undefined,
          onExtractedAnswers: () => {
            dispatch(
              addDefaultSuccessAlert("Successfully updated Q&A columns")
            );
          },
        });

      openConfigureModal();
    },
    [openGptAutofillConfigureSourceModal, data, dispatch]
  );

  const openPreEditQAColumnsModal = useCallback(
    () =>
      openConfirmationModal({
        title: "Set up document for AI Autofill?",
        description: (
          <>
            Select the question and answer columns to use this document as a
            source for AI Autofill when answering questionnaires.
          </>
        ),
        buttonText: "Set Q&A columns",
        primaryAction: true,
        cancelText: "Skip",
        buttonAction: () => {
          editQAColumns(true);
        },
      }),
    [editQAColumns, openConfirmationModal]
  );

  const prevVersions = usePrevious(data?.document.versions);
  useEffect(() => {
    // If a new document version has been uploaded, check if we want to open the Q&A columns modal
    if (
      prevVersions &&
      data?.document.versions &&
      prevVersions.length < data.document.versions.length &&
      canEditQAColumnsFunc(data.document.documentType)
    ) {
      openPreEditQAColumnsModal();
    }
  }, [
    canEditQAColumnsFunc,
    prevVersions,
    data?.document,
    openPreEditQAColumnsModal,
  ]);

  // Keep track of whether we've auto opened the modal already - don't want to trigger this multiple times
  const newDocumentQaColumnsModalAutoOpened = useRef(false);
  useEffect(() => {
    // If this is a new document and it needs its Q&A columns configured, open the modal
    if (
      locationState?.isNewDocument &&
      qaColumnsUnconfigured &&
      !qaColumnsProcessing &&
      !newDocumentQaColumnsModalAutoOpened.current
    ) {
      newDocumentQaColumnsModalAutoOpened.current = true;
      editQAColumns(true);
    }
  }, [
    locationState?.isNewDocument,
    qaColumnsUnconfigured,
    qaColumnsProcessing,
    editQAColumns,
  ]);

  const onChangeDocumentType = useCallback(
    (documentType: string) => {
      if (data?.document.documentType === documentType) {
        return;
      }

      const doUpdate = () => {
        updateDocument({
          uuid,
          documentType,
        });
      };

      // If the current document type is Questionnaire and the file is an excel file, warn that
      // changing the type will mean you can no longer use this in AI Autofill
      if (canEditQAColumnsFunc(data?.document.documentType ?? "")) {
        openConfirmationModal({
          title: "Change document type?",
          description: `Changing the document type away from "${ContentLibraryQuestionnaireDocumentType}" will mean you can no longer use this document in AI Autofill.`,
          buttonText: "Change document type",
          buttonAction: doUpdate,
        });
      } else {
        doUpdate();

        if (canEditQAColumnsFunc(documentType)) {
          openPreEditQAColumnsModal();
        }
      }
    },
    [
      uuid,
      updateDocument,
      data,
      openConfirmationModal,
      canEditQAColumnsFunc,
      openPreEditQAColumnsModal,
    ]
  );

  return (
    <div id="content_library_document">
      <PageHeader
        history={history}
        title="Content properties"
        breadcrumbs={[
          {
            text: "Content Library",
            to: "/contentlibrary",
          },
          {
            text: data?.document.name ?? "Document",
          },
        ]}
        backAction={backAction}
        backText={
          history.location.state?.backContext?.backToText ??
          "Back to Content Library"
        }
        rightSection={
          data ? (
            <>
              <SidePopupV2
                text={
                  !userHasContentLibraryWrite ||
                  (!userHasSharedProfileWrite &&
                    data.document.includeInSharedProfile)
                    ? "You do not have permission to archive this document."
                    : undefined
                }
              >
                <TrackedButton
                  eventName="ContentLibraryArchive"
                  eventProperties={{ uuid: data.document.uuid }}
                  disabled={
                    !userHasContentLibraryWrite ||
                    (!userHasSharedProfileWrite &&
                      data.document.includeInSharedProfile)
                  }
                  onClick={() =>
                    setDocumentsArchived(
                      [data.document.uuid],
                      !data.document.archived
                    )
                  }
                >
                  <div className="cr-icon-archive" />
                  {data.document.archived ? "Unarchive" : "Archive"}
                </TrackedButton>
              </SidePopupV2>
              <SidePopupV2
                text={
                  !userHasContentLibraryWrite
                    ? "You do not have permission to delete this document."
                    : data.document.usageCount > 0
                      ? "This document has been shared and cannot be deleted."
                      : undefined
                }
                position="left"
              >
                <TrackedButton
                  eventName="ContentLibraryDelete"
                  eventProperties={{ uuid: data.document.uuid }}
                  danger
                  disabled={
                    !userHasContentLibraryWrite || data.document.usageCount > 0
                  }
                  onClick={() => deleteDocuments([data.document.uuid])}
                >
                  <div className="cr-icon-trash" />
                  Delete
                </TrackedButton>
              </SidePopupV2>
            </>
          ) : undefined
        }
      />
      <ReportCard newStyles className="content-library-document-card">
        {isLoading ? (
          <LoadingBanner />
        ) : error ? (
          <ErrorCardWithAction action={refetch} />
        ) : data ? (
          <>
            <div className="content-library-document-card-main">
              <div className="header">
                <img
                  className="header-icon"
                  alt="File type icon"
                  src={GetIconForFilename(data.document.versions[0].filename)}
                />
                <div className="header-title">
                  {documentName}
                  {data.document.archived && (
                    <PillLabel color={LabelColor.Orange}>Archived</PillLabel>
                  )}
                </div>
                <div className="header-right">
                  {canEditQAColumns && (
                    <SidePopupV2
                      text={
                        qaColumnsProcessing
                          ? `We are currently extracting information from this questionnaire based on the Q&A columns you provided.`
                          : qaColumnsUnconfigured
                            ? "Set question and answer columns to use this questionnaire in AI autofill."
                            : undefined
                      }
                      position="top"
                    >
                      <Button
                        disabled={qaColumnsProcessing}
                        onClick={() => editQAColumns()}
                      >
                        <div className="cr-icon-pencil" /> Edit Q&A columns
                        {qaColumnsUnconfigured && !qaColumnsProcessing && (
                          <>
                            {" "}
                            <Dot color="orange" />
                          </>
                        )}
                      </Button>
                    </SidePopupV2>
                  )}
                  <FileDropzone
                    getRootProps={getRootProps}
                    getInputProps={getInputProps}
                    isDragActive={isDragActive}
                  >
                    <Button
                      onClick={openUploadDialog}
                      disabled={
                        !userHasContentLibraryWrite || data.document.archived
                      }
                    >
                      <div className="cr-icon-upload" />
                      Upload new version
                    </Button>
                  </FileDropzone>
                  <SidePopupV2
                    micro
                    width={180}
                    text={
                      !data.document.versions[0].virusSafe
                        ? "We're still scanning this document. It will be available to download soon."
                        : undefined
                    }
                  >
                    <Button
                      onClick={() => downloadDocumentVersion(0)}
                      loading={downloadingVersion === 0}
                      disabled={
                        typeof downloadingVersion !== "undefined" ||
                        !data.document.versions[0].virusSafe
                      }
                    >
                      <div className="cr-icon-export-thin" />
                      Download
                    </Button>
                  </SidePopupV2>
                </div>
              </div>
              <div className="main-panes">
                <div>
                  <InfoTable styling={InfoTableStyling.BoldLabels}>
                    <InfoTableRow
                      label="Name"
                      value={
                        userHasContentLibraryWrite &&
                        !data.document.archived ? (
                          <TextField
                            value={documentName}
                            onChanged={setDocumentName}
                            placeholder="Add a name for this document"
                            onBlur={persistDocumentName}
                            minLength={2}
                            maxLength={500}
                            enterKeyShouldBlur
                            staticEditable
                          />
                        ) : (
                          documentName
                        )
                      }
                    />
                    <InfoTableRow
                      label="Description"
                      value={
                        userHasContentLibraryWrite &&
                        !data.document.archived ? (
                          <TextField
                            value={description}
                            onChanged={setDescription}
                            placeholder="Add a description of this document"
                            onBlur={persistDescription}
                            maxLength={5000}
                            multiLine
                            staticEditable
                          />
                        ) : (
                          description
                        )
                      }
                    />
                    <InfoTableRow
                      label="Internal notes"
                      value={
                        userHasContentLibraryWrite &&
                        !data.document.archived ? (
                          <TextField
                            value={internalNotes}
                            onChanged={setInternalNotes}
                            placeholder="Add any internal notes here"
                            onBlur={persistInternalNotes}
                            maxLength={5000}
                            multiLine
                            staticEditable
                          />
                        ) : (
                          internalNotes
                        )
                      }
                    />
                    <InfoTableRow
                      label="Expiry date"
                      value={
                        userHasContentLibraryWrite &&
                        !data.document.archived ? (
                          <DatePicker
                            value={
                              data.document.expiresAt
                                ? moment(data.document.expiresAt).format(
                                    "YYYY-MM-DD"
                                  )
                                : undefined
                            }
                            min={moment().format("YYYY-MM-DD")}
                            placeholder="Add expiry date"
                            onChange={(e) => onChangeExpiryDate(e.target.value)}
                            displayFormat="LL"
                            canClear
                            staticEditable
                          />
                        ) : (
                          <>
                            {data.document.expiresAt
                              ? moment(data.document.expiresAt).format("LLL")
                              : undefined}
                          </>
                        )
                      }
                    />
                    <InfoTableRow
                      label="Document type"
                      value={
                        userHasContentLibraryWrite &&
                        !data.document.archived ? (
                          <DocumentTypeSelector
                            selectedDocumentType={data.document.documentType}
                            setSelectedDocumentType={onChangeDocumentType}
                            staticEditable
                          />
                        ) : (
                          data.document.documentType
                        )
                      }
                    />
                    {currentOrg?.isVerifiedVendor && (
                      <InfoTableRow
                        label="Access"
                        value={
                          <SidePopupV2
                            text={
                              !userHasSharedProfileWrite
                                ? `You do not have permission to edit the Trust Page. Contact an account administrator for access.`
                                : data.document.archived
                                  ? `Archived documents cannot be included in the Trust Page.`
                                  : undefined
                            }
                          >
                            <ToggleWithLabel
                              name="include_shared_profile"
                              label="Include in Trust Page"
                              selected={data.document.includeInSharedProfile}
                              onClick={() => toggleIncludeInSharedProfile()}
                              disabled={
                                !userHasContentLibraryWrite ||
                                !userHasSharedProfileWrite ||
                                data.document.archived
                              }
                            />
                          </SidePopupV2>
                        }
                      />
                    )}
                  </InfoTable>
                </div>
                <div>
                  <InfoTable styling={InfoTableStyling.BoldLabels}>
                    <InfoTableRow
                      label="Used count"
                      value={data.document.usageCount}
                    />
                    <InfoTableRow
                      label="Current version"
                      value={<>V{data.document.versions[0].version}</>}
                    />
                    <InfoTableRow
                      label="Created"
                      value={moment(data.document.createdAt).format("LLL")}
                    />
                    <InfoTableRow
                      label="Last updated"
                      value={moment(data.document.updatedAt).format("LLL")}
                    />
                    {updatedByUser ? (
                      <InfoTableRow
                        label="Updated by"
                        value={
                          <UserAvatarAndName
                            large
                            name={updatedByUser.name}
                            avatar={updatedByUser.avatar}
                            email={updatedByUser.email}
                          />
                        }
                      />
                    ) : undefined}
                  </InfoTable>
                </div>
              </div>
              <ContentLibraryDocumentVersionsSection
                document={data.document}
                sharedUsers={data.sharedUsers}
                downloadingVersion={downloadingVersion}
                downloadDocumentVersion={downloadDocumentVersion}
              />
            </div>
          </>
        ) : undefined}
      </ReportCard>
      <DocumentHistoryCard uuid={uuid} isDeleted={isDeleted} />
      {setDocumentsArchivedModal}
      {gptAutofillConfigureSourceModal}
      {confirmationModal}
      {deleteDocumentsModal}
    </div>
  );
};

export default ContentLibraryDocumentView;
