import LoadingBanner from "../../_common/components/core/LoadingBanner";
import classnames from "classnames";
import { get as _get } from "lodash";
import { PureComponent, ComponentType } from "react";
import { ConnectedComponent } from "react-redux";
import { getCurrentOrgFromUserData } from "../../_common/helpers";
import VendorOverlay from "../components/VendorOverlay";
import {
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
  updateVendorWatchStatusWithRefresh,
  fetchCustomerLookupsData,
  refreshVendorSearchResults,
} from "../reducers/cyberRiskActions";
import "../style/views/VendorOverlayWrapper.scss";
import moment from "moment";
import {
  DefaultThunkDispatch,
  ICyberRiskState,
  ISingleVendorData,
} from "../../_common/types/redux";
import { RouteComponentProps } from "react-router-dom";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { ILabel } from "../../_common/types/label";
import { AssuranceType } from "../../_common/types/organisations";
import { Diff } from "utility-types";
import { appConnect } from "../../_common/types/reduxHooks";
import { addMessageAlert } from "../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../components/InfoBanner";

export const getVendorDataFromState = (
  state: ICyberRiskState,
  vendorId: number,
  managedOrgId?: number
): ISingleVendorData => {
  if (managedOrgId) {
    return state.managedVendorData[managedOrgId]?.[vendorId];
  } else {
    return state.vendors[vendorId];
  }
};

interface IVendorOverlayMatchParams {
  vendorId?: string;
  orgId?: string;
}

interface IVendorOverlayLocationState extends locationState {
  selection?: string;
}

interface IVendorOverlayOwnProps
  extends RouteComponentProps<
    IVendorOverlayMatchParams,
    never,
    IVendorOverlayLocationState
  > {
  dispatch: DefaultThunkDispatch;
  isVendorPortal?: boolean;
}

interface IVendorOverlayConnectedProps {
  vendorId?: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  vendorVerified: boolean;
  vendorImmutable: boolean;
  loading: boolean;
  vendorSummaryLoading?: boolean;
  watching: boolean;
  vendorIsManaged: boolean;
  canView: boolean;
  couldUseLookup?: boolean;
  couldWatch?: boolean;
  lookupsAvailable?: number;
  watchSlotsAvailable?: number;
  availableLabels?: ILabel[];
  assuranceType: AssuranceType;
  childComponent: ComponentType<any>;
  isSubsidiary: boolean;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  vendorManagementContractEndDate?: string;
  customForOrgId: number;
  passThroughProps: any;
}

// Props injected from VendorOverlayWrapper HOC into child component
export interface VendorOverlayInjectedProps<
  RouteParams = unknown,
  ExtraLocationState = unknown,
> extends DefaultRouteProps<
    IVendorOverlayMatchParams & RouteParams,
    ExtraLocationState
  > {
  vendorId?: number;
  vendorName: string;
  vendorPrimaryHostname: string;
  vendorVerified: boolean;
  vendorImmutable: boolean;
  isSubsidiary: boolean;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  isVendorPortal?: boolean;
  watching: boolean;
  vendorIsManaged: boolean;
  vendorManagementContractEndDate?: string;
  newlyWatched: boolean;
  canView: boolean;
  customForOrgId: number;
  vendorOverlayLoading: boolean;
  vendorSummaryLoading?: boolean;
  assuranceType: AssuranceType;
}

type IVendorOverlayWrapperProps = IVendorOverlayConnectedProps &
  IVendorOverlayOwnProps;

interface IVendorOverlayState {
  adhocLookupLoading: boolean;
  newlyWatched: boolean;
}

class VendorOverlayWrapper extends PureComponent<
  IVendorOverlayWrapperProps,
  IVendorOverlayState
> {
  static defaultProps = {
    vendorId: undefined,
    couldUseLookup: false,
    couldWatch: false,
    lookupsAvailable: 0,
    watchSlotsAvailable: 0,
    isManagementAnalystSession: false,
    managedOrgId: 0,
    isVendorPortal: false,
    vendorSummaryLoading: false,
    customForOrgId: 0,
  };

  constructor(props: IVendorOverlayWrapperProps) {
    super(props);

    this.state = {
      adhocLookupLoading: false,
      newlyWatched: false,
    };
  }

  componentDidMount() {
    if (!this.props.isSubsidiary && this.props.vendorId) {
      this.fetchWatchedStatus(this.props.dispatch, this.props.vendorId);
    }
  }

  componentDidUpdate(prevProps: IVendorOverlayWrapperProps) {
    if (
      !this.props.isSubsidiary &&
      this.props.vendorId &&
      (prevProps.vendorId !== this.props.vendorId ||
        prevProps.managedOrgId !== this.props.managedOrgId)
    ) {
      this.fetchWatchedStatus(this.props.dispatch, this.props.vendorId);
    }
  }

  fetchWatchedStatus = (dispatch: DefaultThunkDispatch, vendorId: number) => {
    dispatch(fetchVendorWatchStatus(vendorId, false));
  };

  onAdhocLookup = () => {
    this.setState({ adhocLookupLoading: true });
    this.setState({ newlyWatched: true });

    this.props
      .dispatch(
        fetchVendorSummaryAndCloudscans(this.props.vendorId ?? 0, true, true)
      )
      .then(() =>
        this.props.dispatch(fetchVendorWatchStatus(this.props.vendorId, true))
      )
      .then(() => this.setState({ adhocLookupLoading: false }))
      .then(() => {
        this.props.dispatch(fetchCustomerLookupsData(true));
        this.props.dispatch(refreshVendorSearchResults());
      })
      .then(() => {
        this.props.dispatch(
          addMessageAlert({
            message: `${this.props.vendorName} has been added to your snapshots.`,
            type: BannerType.SUCCESS,
          })
        );
      });
  };

  onWatchVendor = (systemLabelIds: number[], tier?: number) => {
    this.setState({ newlyWatched: true });
    return this.props.dispatch(
      updateVendorWatchStatusWithRefresh(
        this.props.vendorId,
        true,
        this.props.history,
        systemLabelIds,
        this.props.vendorName,
        this.props.vendorPrimaryHostname,
        tier
      )
    );
  };

  render() {
    const Component = this.props.childComponent;
    const loading = this.props.loading || this.state.adhocLookupLoading;
    const wantsToMonitorVendor =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.selection === "monitor";
    const restrictedView =
      !this.props.canView || (wantsToMonitorVendor && !this.props.watching);

    // Props to inject into the child component
    // Listed out here for type safety
    const componentPropsToInject: VendorOverlayInjectedProps = {
      vendorId: this.props.vendorId,
      vendorName: this.props.vendorName,
      vendorPrimaryHostname: this.props.vendorPrimaryHostname,
      vendorVerified: this.props.vendorVerified,
      vendorImmutable: this.props.vendorImmutable,
      isSubsidiary: this.props.isSubsidiary,
      isManagementAnalystSession: this.props.isManagementAnalystSession,
      managedOrgId: this.props.managedOrgId,
      isVendorPortal: this.props.isVendorPortal,
      match: this.props.match,
      history: this.props.history,
      location: this.props.location,
      watching: this.props.watching,
      vendorIsManaged: this.props.vendorIsManaged,
      vendorManagementContractEndDate:
        this.props.vendorManagementContractEndDate,
      newlyWatched: this.state.newlyWatched,
      canView: this.props.canView,
      customForOrgId: this.props.customForOrgId,
      vendorOverlayLoading: loading,
      vendorSummaryLoading: this.props.vendorSummaryLoading,
      assuranceType: this.props.assuranceType,
    };

    return (
      <div
        className={classnames("vendor-overlay-wrapper", {
          // only show the loading spinner here if the vendor summary is not also loading (prevent double spinners)
          loading: loading && !this.props.vendorSummaryLoading,
        })}
      >
        <div className="vendor-watched-loading-overlay">
          <LoadingBanner />
        </div>
        {restrictedView && !loading && (
          <VendorOverlay
            access={{
              couldUseLookup: this.props.couldUseLookup,
              couldWatch: this.props.couldWatch,
              lookupsAvailable: this.props.lookupsAvailable,
              watchSlotsAvailable: this.props.watchSlotsAvailable,
            }}
            onAdhocLookup={this.onAdhocLookup}
            onWatchVendor={this.onWatchVendor}
            assuranceType={this.props.assuranceType}
            location={this.props.location}
            history={this.props.history}
            vendorName={this.props.vendorName}
            vendorPrimaryHostname={this.props.vendorPrimaryHostname}
            vendorId={this.props.vendorId}
          />
        )}
        <div
          className={classnames("vendor-overlay-component-wrapper", {
            restricted: loading || restrictedView,
          })}
        >
          <Component
            {...this.props.passThroughProps}
            {...componentPropsToInject}
          />
        </div>
      </div>
    );
  }
}

export const wrapInVendorOverlay = <BaseProps extends Record<never, never>>(
  childComponent:
    | ComponentType<BaseProps>
    | ConnectedComponent<
        ComponentType<Diff<BaseProps, VendorOverlayInjectedProps>>,
        any
      >
) => {
  return appConnect<
    IVendorOverlayConnectedProps,
    undefined,
    IVendorOverlayOwnProps & Diff<BaseProps, VendorOverlayInjectedProps>
  >((state, props) => {
    const {
      dispatch,
      isVendorPortal,
      history,
      location,
      match,
      ...passThroughProps
    } = props;

    const { userData } = state.common;
    const orgPermissions: { [perm: string]: boolean } = {};
    if (userData.orgPermissions) {
      for (const perm of userData.orgPermissions) {
        orgPermissions[perm] = true;
      }
    }

    const isSubsidiary = match.path.startsWith("/subsidiaries");
    const userIsManagedVendorAnalyst =
      state.common.userData.system_roles &&
      state.common.userData.system_roles.includes("VendorManagementAnalyst");

    const isAnalystManagedVendor =
      userIsManagedVendorAnalyst && match.path.startsWith("/analysts/tpvm");

    const currentOrg = getCurrentOrgFromUserData(state.common.userData);
    const vendorManagementContractEndDate = currentOrg
      ? moment(currentOrg.tpvmContractRollover).format("YYYY-MM-DD")
      : undefined;

    if (!isSubsidiary && !isAnalystManagedVendor && match.params.vendorId) {
      const vendorId = parseInt(match.params.vendorId);
      if (Number.isNaN(vendorId)) {
        throw new Error("match.params.vendorId could not be parsed as int");
      }

      const vendorData = state.cyberRisk.vendors[vendorId];
      const vendorWatchResult = _get(vendorData, "watching.result", null);
      const vendorWatchLoading = _get(vendorData, "watching.loading", true);
      const verified = _get(vendorData, "verified", false);
      const immutable = _get(vendorData, "immutable", false);
      const vendorLoading = !vendorWatchResult || vendorWatchLoading;

      if (vendorLoading) {
        return {
          vendorId,
          vendorName: _get(vendorData, "display_name", ""),
          vendorPrimaryHostname: _get(vendorData, "primary_hostname", ""),
          vendorVerified: verified,
          childComponent,
          canView: false,
          watching: false,
          vendorIsManaged: false,
          vendorManagementContractEndDate,
          loading: true,
          vendorSummaryLoading: _get(vendorData, "summary.loading", true),
          vendorImmutable: immutable,
          assuranceType: userData.assuranceType,
          isSubsidiary: false,
          isManagementAnalystSession: false,
          customForOrgId: _get(vendorData, "customForOrgId", 0),
          passThroughProps: passThroughProps,
        };
      }

      return {
        vendorId,
        vendorName: _get(vendorData, "display_name", ""),
        vendorPrimaryHostname: _get(vendorData, "primary_hostname", ""),
        vendorVerified: verified,
        childComponent,
        loading: false,
        vendorSummaryLoading: _get(vendorData, "summary.loading", false),
        watching: vendorWatchResult.watching,
        vendorIsManaged: vendorWatchResult.managed,
        vendorManagementContractEndDate,
        canView: vendorWatchResult.canView,
        couldUseLookup: vendorWatchResult.couldUseLookup,
        couldWatch: vendorWatchResult.couldWatch,
        lookupsAvailable: vendorWatchResult.lookupsAvailable,
        watchSlotsAvailable: vendorWatchResult.watchSlotsAvailable,
        vendorImmutable: immutable,
        assuranceType: userData.assuranceType,
        isSubsidiary: false,
        isManagementAnalystSession: false,
        customForOrgId: _get(vendorData, "customForOrgId", 0),
        passThroughProps: passThroughProps,
      };
    }

    if (isSubsidiary) {
      const vendorId = match.params.vendorId
        ? parseInt(match.params.vendorId)
        : undefined;

      if (!vendorId || Number.isNaN(vendorId)) {
        throw new Error("match.params.vendorId could not be parsed as int");
      }

      const vendorData = state.cyberRisk.subsidiaries[vendorId];
      const verified = _get(vendorData, "verified", false);

      return {
        vendorId,
        vendorName: _get(vendorData, "display_name", ""),
        vendorPrimaryHostname: _get(vendorData, "primary_hostname", ""),
        vendorVerified: verified,
        vendorIsManaged: _get(vendorData, "managed", false),
        vendorManagementContractEndDate,
        childComponent,
        loading: false,
        watching: false,
        canView: true,
        vendorImmutable: false,
        assuranceType: userData.assuranceType,
        isSubsidiary: true,
        isManagementAnalystSession: false,
        customForOrgId: 0,
        passThroughProps: passThroughProps,
      };
    }

    if (isAnalystManagedVendor) {
      const vendorId = match.params.vendorId
        ? parseInt(match.params.vendorId)
        : undefined;

      if (!vendorId || Number.isNaN(vendorId)) {
        throw new Error("match.params.vendorId could not be parsed as int");
      }

      const orgId = match.params.orgId
        ? parseInt(match.params.orgId)
        : undefined;

      if (!orgId || Number.isNaN(orgId)) {
        throw new Error("match.params.orgId could not be parsed as int");
      }

      const vendorData = _get(
        state.cyberRisk.managedVendorData,
        `[${orgId}][${vendorId}]`,
        {}
      );
      const verified = _get(vendorData, "verified", false);
      const data = {
        vendorId,
        vendorName: _get(vendorData, "display_name", ""),
        vendorPrimaryHostname: _get(vendorData, "primary_hostname", ""),
        vendorVerified: verified,
        childComponent,
        loading: false,
        watching: true,
        canView: true,
        vendorIsManaged: true,
        vendorManagementContractEndDate,
        vendorImmutable: false,
        assuranceType: AssuranceType.None,
        isSubsidiary: false,
        managedOrgId: orgId,
        isManagementAnalystSession: true,
        customForOrgId: _get(vendorData, "customForOrgId", 0),
        passThroughProps: passThroughProps,
      };
      return data;
    }
    const verifiedImpersonation = _get(
      userData,
      "impersonatingOrgVerified",
      false
    );
    return {
      vendorId: undefined,
      vendorName: currentOrg ? currentOrg.name : "",
      vendorPrimaryHostname: currentOrg ? currentOrg.mainHostname : "",
      vendorVerified:
        verifiedImpersonation ||
        (currentOrg ? currentOrg.isVerifiedVendor : false),
      canView: true,
      loading: false,
      watching: false,
      vendorIsManaged: false,
      vendorManagementContractEndDate,
      childComponent,
      vendorImmutable: false,
      assuranceType: userData.assuranceType,
      isSubsidiary: false,
      isManagementAnalystSession: false,
      customForOrgId: 0,
      passThroughProps: passThroughProps,
    };
  })(VendorOverlayWrapper);
};
