import { FC } from "react";
import userbaseApi from "../../api/userbase.api";
import ListFilter from "./ListFilter";
import {
  OrgAccessUserBase,
  UserUserBaseEnabled,
} from "../../../_common/permissions";
import withRequireEntitlementAndPermission from "../../../_common/hocs/WithRequireEntitlementAndPermission";
import { OrgScope } from "../../api/types";

interface UserRisksScopeCategoryFilterProps {
  selectedCategories: string[];
  excluded: boolean;
  onChange: (selectedCategories: string[], exclude: boolean) => void;
}

const UserRiskScopeCategoryFilter: FC<UserRisksScopeCategoryFilterProps> = ({
  selectedCategories,
  excluded,
  onChange,
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseScopesV1Query({});

  // get the sorted, unique list of risk categories to display to the user
  const allScopes: OrgScope[] = data?.scopes ?? [];

  const categorySet: Set<string> = new Set();
  allScopes.forEach((scope) => {
    const scopeCategory = scope.category ?? "";
    if (scopeCategory.length > 0) {
      categorySet.add(scopeCategory);
    }
  });

  const categories: string[] = Array.from(categorySet);
  const sortedCategories = categories.sort();

  return (
    <ListFilter
      title={"Permission categories"}
      isLoading={isLoading}
      options={
        sortedCategories.map((category) => {
          return {
            value: category,
            label: category,
          };
        }) ?? []
      }
      selectedValues={selectedCategories}
      excluded={excluded}
      onChange={(selectedValues, exclude) => onChange(selectedValues, exclude)}
    />
  );
};

export default withRequireEntitlementAndPermission(
  UserRiskScopeCategoryFilter,
  OrgAccessUserBase,
  UserUserBaseEnabled
);
