import { useEffect, useMemo, useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import { Steps } from "../../../_common/components/StepsWithSections";

import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import DragDropUpload from "../../../_common/components/DragDropUpload";
import { formatDateAsLocal } from "../../../_common/helpers";
import Button from "../../../_common/components/core/Button";
import IconButton from "../../../_common/components/IconButton";
import {
  AdditionalEvidenceType,
  SortAdditionalEvidenceTypes,
} from "../../types/additionalEvidence";
import { SelectV2 } from "../../../_common/components/SelectV2";
import "../../style/components/UploadAdditionalEvidenceModal.scss";
import moment from "moment";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import {
  addRiskToAdditionalEvidence,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import {
  fetchAdditionalEvidenceForVendor,
  fetchAdditionalEvidenceTypes,
  uploadAndCreateNewAdditionalEvidenceDocument,
} from "../../reducers/additionalEvidence.actions";
import SeverityIcon from "../../../_common/components/SeverityIcon";
import { Severity, SeverityAsString } from "../../../_common/types/severity";
import SeveritySelector from "../SeveritySelector";
import TextField from "../../../_common/components/TextField";
import excelIcon from "../../../_common/images/file-type-icon-excel.svg";
import wordIcon from "../../../_common/images/file-type-icon-word.svg";
import pngIcon from "../../../_common/images/file-type-icon-image.svg";
import jpgIcon from "../../../_common/images/file-type-icon-image.svg";
import pdfIcon from "../../../_common/images/file-type-icon-pdf.svg";
import ppIcon from "../../../_common/images/file-type-icon-pptx.svg";
import ColorCheckbox from "../ColorCheckbox";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import { appConnect, useAppDispatch } from "../../../_common/types/reduxHooks";
import managedVendorsAPI from "../../reducers/managedVendorsAPI";
import analystManagedVendorsAPI from "../../../analyst_portal/reducers/analystManagedVendorsAPI";
import { useManagedOrgID } from "../../../_common/hooks";
import ManualRisksAPI, { ManualRisk } from "../../reducers/manualRisksAPI";
import {
  AcceptedDocumentFileExtensions,
  AcceptedDocumentMimeTypes,
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../../_common/types/fileRestrictions";

const MAX_COMMENTS_LENGTH = 10000;

interface IAdditionalEvidenceUpload {
  file: File;
  onDelete: () => void;
}

const AdditionalEvidenceUpload = ({
  file,
  onDelete,
}: IAdditionalEvidenceUpload) => {
  let icon;
  switch (file.type) {
    case ".docx":
    case ".txt":
    case "text/plain":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      icon = wordIcon;
      break;
    case ".xlsx":
    case ".csv":
    case "text/csv":
    case "text/x-csv":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      icon = excelIcon;
      break;
    case ".png":
    case "image/png":
      icon = pngIcon;
      break;
    case ".jpg":
    case ".jpeg":
    case "image/jpeg":
      icon = jpgIcon;
      break;
    case ".pdf":
    case "application/pdf":
      icon = pdfIcon;
      break;
    case ".pptx":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      icon = ppIcon;
      break;
    default:
      icon = wordIcon;
  }

  return (
    <div className={"additional-evidence-upload"}>
      <div className={"file-type-icon"}>
        <img src={icon} />
      </div>
      <div className={"text-section"}>
        <div className={"filename"}>{file.name}</div>
        <div className={"date"}>
          {formatDateAsLocal(moment().toISOString())}
        </div>
      </div>
      <div className={"icon-section"}>
        <IconButton
          onClick={onDelete}
          icon={<span className="cr-icon-trash" />}
        />
        <IconButton
          onClick={() => {
            const url = URL.createObjectURL(file);
            window.open(url);
          }}
          icon={<span className="cr-icon-download" />}
        />
      </div>
    </div>
  );
};

export interface NewAdditionalEvidenceRisk {
  tempId: string;
  severity?: Severity;
  name: string;
  impact: string;
  selected: boolean;
}

export const useNewAdditionalEvidenceRisks = () => {
  const [state, setState] = useState([] as NewAdditionalEvidenceRisk[]);

  const NewRisk = () => {
    const newState = [...state];
    newState.push({
      tempId: moment().format(),
      name: "",
      impact: "",
      selected: true,
    });
    setState(newState);
  };

  const DeleteRisk = (tempId: string) => {
    const newState = [...state];
    const idx = newState.findIndex((r) => r.tempId === tempId);
    if (idx >= 0) {
      newState.splice(idx, 1);
    }
    setState(newState);
  };

  // can also be used to select/unselect risk
  const ModifyRisk = (
    tempId: string,
    risk: Partial<NewAdditionalEvidenceRisk>
  ) => {
    const newState = [...state];
    const idx = newState.findIndex((r) => r.tempId === tempId);
    if (idx >= 0) {
      newState[idx] = { ...newState[idx], ...risk };
    }
    setState(newState);
  };

  const ResetRisks = () => setState([]);

  return [state, NewRisk, DeleteRisk, ModifyRisk, ResetRisks] as const;
};

export interface IAdditionalEvidenceRiskSelectorProps {
  availableAdditionalEvidenceRisks: ManualRisk[];
  selectedAdditionalEvidenceRisks: boolean[];
  onSelectAdditionalEvidenceRisk: (idx: number, selected: boolean) => void;
  newRisks: NewAdditionalEvidenceRisk[];
  onNewRisk: () => void;
  onDeleteRisk: (tempId: string) => void;
  onModifyRisk: (
    tempId: string,
    risk: Partial<NewAdditionalEvidenceRisk>
  ) => void;
  riskNameIsDuplicate: (name: string, idx: number) => boolean;
  loading: boolean;
}

export const AdditionalEvidenceRiskSelector = ({
  availableAdditionalEvidenceRisks,
  newRisks,
  onDeleteRisk,
  onModifyRisk,
  onNewRisk,
  onSelectAdditionalEvidenceRisk,
  selectedAdditionalEvidenceRisks,
  riskNameIsDuplicate,
  loading,
}: IAdditionalEvidenceRiskSelectorProps) => {
  return (
    <div className={"additional-evidence-risk-selector"}>
      <div className={"available-risks"}>
        <p>Select the risks that apply to this piece of additional evidence</p>
        {availableAdditionalEvidenceRisks
          .sort((a, b) => {
            if (a.severity !== b.severity) {
              return a.severity > b.severity ? -1 : 1;
            }

            return a.name.localeCompare(b.name);
          })
          .map((r, i) => (
            <ColorCheckbox
              className={"available-risk-check"}
              key={r.id}
              disabled={loading}
              checked={selectedAdditionalEvidenceRisks[i]}
              onClick={() =>
                onSelectAdditionalEvidenceRisk(
                  i,
                  !selectedAdditionalEvidenceRisks[i]
                )
              }
              label={
                <div className={"available-risk-label"}>
                  <SeverityIcon
                    severity={SeverityAsString(r.severity)}
                    label={false}
                  />
                  <div className={"available-risk-text"}>
                    <div className={"risk-name"}>{r.name}</div>
                    <div className={"risk-subtext"}>{r.impact}</div>
                  </div>
                </div>
              }
            />
          ))}
      </div>
      {newRisks.length > 0 && <div className={"horz-line"} />}
      <div className={"new-risks"}>
        {newRisks.map((r, i) => (
          <div className={"new-risk-check"} key={r.tempId}>
            <ColorCheckbox
              className={"new-risk-checkbox"}
              checked={r.selected}
              onClick={() => onModifyRisk(r.tempId, { selected: !r.selected })}
              disabled={loading}
            />
            <div className={"new-risk-fields"}>
              <SeveritySelector
                isDisabled={false}
                onChange={(s) => onModifyRisk(r.tempId, { severity: s })}
                severity={r.severity}
                placeholder={"Select severity level"}
              />
              <label>Name your risk</label>
              <TextField
                required
                value={r.name}
                onChanged={(val) => onModifyRisk(r.tempId, { name: val })}
                maxLength={120}
                minLength={3}
                disabled={loading}
                errorTexts={
                  riskNameIsDuplicate(r.name, i)
                    ? ["Risk name must be unique"]
                    : undefined
                }
              />
              <label>Add impact / consequences</label>
              <TextField
                value={r.impact}
                onChanged={(val) => onModifyRisk(r.tempId, { impact: val })}
                multiLine
                minLength={1}
                disabled={loading}
                required
              />
            </div>
            <IconButton
              icon={<span className={"cr-icon-trash"} />}
              onClick={() => onDeleteRisk(r.tempId)}
              disabled={loading}
            />
          </div>
        ))}
        <Button link onClick={onNewRisk} disabled={loading}>
          <span className={"cr-icon-plus"} />
          Add new risk
        </Button>
      </div>
    </div>
  );
};

interface IUploadAdditionalEvidenceModalOwnProps {
  active: boolean;
  onClose: () => void;
  vendorID: number;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
  refreshData?: (evidenceID: number) => Promise<unknown>;
  skipAddRisk?: boolean;
  forManagementRequest?: boolean;
}

interface IUploadAdditionalEvidenceConnectedProps {
  types: AdditionalEvidenceType[];
}

type IUploadAdditionalEvidenceModalProps =
  IUploadAdditionalEvidenceModalOwnProps &
    IUploadAdditionalEvidenceConnectedProps;

const UploadAdditionalEvidenceModal = (
  props: IUploadAdditionalEvidenceModalProps
) => {
  const dispatch: DefaultThunkDispatch = useAppDispatch();

  const { data: _availableAdditionalEvidenceRisks } =
    ManualRisksAPI.useGetAllManualRisksQuery();
  // have to make a copy of the returned data as sort will attempt to sort it in place and the returned array is read only
  const availableAdditionalEvidenceRisks = useMemo(
    () =>
      _availableAdditionalEvidenceRisks
        ? [..._availableAdditionalEvidenceRisks]
        : [],
    [_availableAdditionalEvidenceRisks]
  );

  // fetch data
  useEffect(() => {
    dispatch(fetchAdditionalEvidenceTypes());
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [documentType, setDocumentType] = useState<
    { value: string; label: string } | undefined
  >(undefined);
  const [comments, setComments] = useState("");
  const [commentsError, setCommentsError] = useState("");
  const [file, _setFile] = useState<File | undefined>(undefined);

  // setCommentsToSize
  // make sure we dont burst the bounds of our comments field, by truncating anything the user enters.
  const setCommentsToSize = (str: string) => {
    if (str.length > MAX_COMMENTS_LENGTH) {
      setCommentsError("Comments must be less than 10,000 characters");
    } else {
      setCommentsError("");
    }
    setComments(str.substring(0, MAX_COMMENTS_LENGTH));
  };

  const [newRisks, newRisk, deleteRisk, modifyRisk, resetRisks] =
    useNewAdditionalEvidenceRisks();
  const [selectedRisks, setSelectedRisks] = useState([] as boolean[]);
  useEffect(() => {
    if (availableAdditionalEvidenceRisks.length > 0) {
      setSelectedRisks(availableAdditionalEvidenceRisks.map(() => false));
    }
  }, [availableAdditionalEvidenceRisks]);

  // verifies if the file is valid and gives and error if not
  const setFile = (file: File) => {
    if (file.size > MaxFileSizeB) {
      dispatch(
        addSimpleErrorAlert(
          `Please upload a file that is ${MaxFileSizeDisplay} or less`
        )
      );
    } else if (!AcceptedDocumentMimeTypes.includes(file.type)) {
      dispatch(addSimpleErrorAlert("Unsupported file type"));
    } else {
      _setFile(file);
      setCurrentStep(2);
    }
  };

  const fileComponent = file ? (
    <AdditionalEvidenceUpload
      file={file}
      onDelete={() => {
        _setFile(undefined);
        setCurrentStep(1);
      }}
    />
  ) : undefined;

  const [filename, setFilename] = useState("");
  const [filenameValid, setFilenameValid] = useState(false);
  useEffect(() => {
    if (!!file) {
      const fileparts = file.name.split(".");
      fileparts.pop();
      setFilename(fileparts.join("."));
      setFilenameValid(true);
    }
  }, [file]);

  const managedOrgID = useManagedOrgID();

  const [managedAssessmentUpload] = managedOrgID
    ? analystManagedVendorsAPI.useUploadAnalystAdditionalEvidenceForRequestMutation()
    : managedVendorsAPI.useUploadAdditionalEvidenceForRequestMutation();

  const [doingUpload, setDoingUpload] = useState(false);
  const doUpload = async () => {
    if (!file) {
      return; // should be impossible but get ts to behave
    }
    setDoingUpload(true);
    const name = filename;

    let newId: number;

    const evidenceType = props.types.find((t) => t.name == documentType?.value);
    if (!evidenceType) {
      dispatch(addSimpleErrorAlert("Invalid document type"));
      return;
    }

    const promise = !props.forManagementRequest
      ? dispatch(
          uploadAndCreateNewAdditionalEvidenceDocument(
            file,
            props.vendorID,
            undefined,
            evidenceType.id,
            comments,
            name,
            selectedRisks && selectedRisks.length > 0,
            true
          )
        )
      : managedAssessmentUpload({
          name,
          commentary: comments,
          file,
          vendorID: props.vendorID,
          documentTypeID: evidenceType.id,
        }).unwrap();

    try {
      await promise
        .then(({ id: newEvidenceId }) => {
          newId = newEvidenceId;
          let proms: Promise<void>[] = [];
          if (!props.skipAddRisk) {
            proms = availableAdditionalEvidenceRisks
              .filter((_, i) => selectedRisks[i])
              .map((a) =>
                dispatch(addRiskToAdditionalEvidence(newEvidenceId, a.id))
              );

            proms.push(
              ...newRisks
                .filter((r) => r.selected)
                .map((r) =>
                  dispatch(
                    addRiskToAdditionalEvidence(
                      newEvidenceId,
                      undefined,
                      r.name,
                      r.impact,
                      r.severity
                    )
                  )
                )
            );
          }

          return Promise.all(proms);
        })
        .then(() => {
          props.refreshData?.(newId);
          if (!props.forManagementRequest) {
            dispatch(fetchAdditionalEvidenceForVendor(props.vendorID, true));
            dispatch(
              fetchVendorSummaryAndCloudscans(
                props.vendorID,
                true,
                false,
                false,
                false
              )
            );
          }
          dispatch(
            addDefaultSuccessAlert(
              `We've added "${name}" as additional evidence`
            )
          );
          setDoingUpload(false);
          doClose();
        });
    } catch (e) {
      setDoingUpload(false);
      dispatch(
        addDefaultUnknownErrorAlert("Error creating additional evidence")
      );
    }
  };

  const doClose = () => {
    // reset state when closing
    setCurrentStep(1);
    _setFile(undefined);
    resetRisks();
    setSelectedRisks([]);
    setComments("");
    setDocumentType(undefined);
    props.onClose();
  };

  const riskNameIsDuplicate = (name: string, index: number) =>
    [
      ...availableAdditionalEvidenceRisks.map((r) => r.name),
      ...newRisks.filter((r, i) => r.selected && i != index).map((r) => r.name),
    ].some((n) => n == name);

  const canGoNext = (): boolean => {
    switch (currentStep) {
      case 1:
        return !!file;
      case 2:
        return (
          !!documentType &&
          filenameValid &&
          (!props.skipAddRisk || commentsError === "")
        );
      case 3:
        // can choose not to select a risk, but if creating one all fields must be completed
        // also make sure all risks name are unique
        return (
          commentsError === "" &&
          (newRisks.every(
            (r) =>
              (r.name.length > 2 && r.impact.length > 0 && !!r.severity) ||
              !r.selected
          ) ||
            (newRisks.length === 0 && selectedRisks.some((s) => s))) &&
          !newRisks.some((r, i) => riskNameIsDuplicate(r.name, i))
        );
      default:
        return false;
    }
  };

  return (
    <ModalV2
      className={"upload-additional-evidence-modal"}
      active={props.active}
      onClose={doClose}
      headerClassName={"upload-additional-evidence-modal-header"}
      headerContent={
        <>
          <h2>Upload additional evidence</h2>
          <p>
            Use additional evidence to upload documents, such as audit reports
            or completed security questionnaires, and capture identified risks.
            Once identified, risks can be included in the vendor&apos;s risk
            profile and used in the risk assessment process.
          </p>
        </>
      }
      footerClassName={"upload-additional-evidence-modal-footer"}
      footerContent={
        <>
          {currentStep != 1 && (
            <Button
              className={"left-button back-button"}
              disabled={doingUpload}
              leftArrow
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          <Button tertiary disabled={doingUpload} onClick={doClose}>
            Cancel
          </Button>
          <Button
            arrow={currentStep !== 3}
            primary
            onClick={() =>
              currentStep === 3 || (currentStep === 2 && props.skipAddRisk)
                ? doUpload()
                : setCurrentStep(currentStep + 1)
            }
            loading={doingUpload}
            disabled={!canGoNext()}
          >
            {currentStep === 3 || (currentStep === 2 && props.skipAddRisk)
              ? "Save evidence "
              : "Next "}
          </Button>
        </>
      }
      disallowClose={doingUpload}
    >
      <Steps
        steps={[
          ...[
            {
              id: "upload",
              text: "Upload",
            },
            {
              id: "details",
              text: "Add details",
            },
          ],
          ...(!props.skipAddRisk
            ? [
                {
                  id: "risk",
                  text: "Add risk",
                },
              ]
            : []),
        ]}
        currentStep={currentStep}
      />
      {currentStep === 1 && !file && (
        <>
          <DragDropUpload
            onFileSelected={setFile}
            onFileRejected={() =>
              dispatch(
                addSimpleErrorAlert("Error uploading file", [
                  "The file is either too large or an unsupported type",
                  `Supported types are: jpg, png, pdf, docx, xls, csv and txt. Max ${MaxFileSizeDisplay}`,
                ])
              )
            }
            maxFileSize={MaxFileSizeB}
            acceptedFileTypeFilters={[
              ...AcceptedDocumentFileExtensions,
              ...AcceptedDocumentMimeTypes,
            ]}
            clickText={"Click to upload a new document"}
          />
          <div className={"help-text"}>
            <span className={"cr-icon-info icon"} />
            <p>
              Supports jpg, png, pdf, docx, xls, csv, txt. Max{" "}
              {MaxFileSizeDisplay}.
            </p>
          </div>
        </>
      )}
      {currentStep === 1 && file && fileComponent}
      {currentStep === 2 && (
        <>
          {fileComponent}
          <div className={"document-details-form"}>
            <h3 className={"label"}>Document name</h3>
            <TextField
              value={filename}
              onChanged={(val, valid) => {
                setFilename(val);
                setFilenameValid(valid);
              }}
              required
              minLength={4}
              maxLength={120}
            />
            <h3 className={"label"}>Document type</h3>
            <SelectV2
              placeholder={"Select document type"}
              options={SortAdditionalEvidenceTypes(props.types).map((t) => ({
                value: t.name,
                label: t.name,
              }))}
              value={documentType}
              // @ts-ignore
              onChange={setDocumentType}
              isLoading={props.types.length == 0}
            />
            <h3 className={"label"}>Add comments</h3>
            <RichTextEditV2
              value={comments}
              onChange={(val) => setCommentsToSize(val)}
              placeholder={"Optionally add a comment about this evidence"}
              className={"comments-rte"}
            />
            {commentsError !== "" && (
              <div className={"error-text"}>
                <i className={"icon-info"} />
                {commentsError}
              </div>
            )}
          </div>
        </>
      )}
      {currentStep === 3 && (
        <div className={"add-risk-container"}>
          <AdditionalEvidenceRiskSelector
            availableAdditionalEvidenceRisks={availableAdditionalEvidenceRisks}
            selectedAdditionalEvidenceRisks={selectedRisks}
            onSelectAdditionalEvidenceRisk={(idx, selected) => {
              setSelectedRisks((risks) => {
                const newRisks = [...risks];
                newRisks[idx] = selected;
                return newRisks;
              });
            }}
            newRisks={newRisks}
            onNewRisk={newRisk}
            onDeleteRisk={deleteRisk}
            onModifyRisk={modifyRisk}
            loading={doingUpload}
            riskNameIsDuplicate={riskNameIsDuplicate}
          />
        </div>
      )}
    </ModalV2>
  );
};

export default appConnect<
  IUploadAdditionalEvidenceConnectedProps,
  unknown,
  IUploadAdditionalEvidenceModalOwnProps
>((state) => {
  return {
    types: state.cyberRisk.additionalEvidenceTypes ?? [],
  };
})(UploadAdditionalEvidenceModal);
