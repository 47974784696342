import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import "./AboutUserDirectory.scss";
import { UserDirectoryType } from "../../api/types";
import { NumberWithCommas } from "../../../_common/helpers";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";

const AboutUserDirectory: FC = ({}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserDirectoryStatsV1Query();
  const stats = data?.stats;

  // Stats might not be available yet so set some defaults
  let dirTypeToDisplay = "Directory information";
  let numUsersInDirDisplay = "-";
  let numUsersNoEmailDisplay = "-";
  let numAdminAppsDisplay = "-";

  if (stats) {
    // We have stats so change what we display
    dirTypeToDisplay =
      stats?.origin === UserDirectoryType.Google
        ? "Google Directory"
        : "Microsoft 365 Directory" ?? "-";

    numUsersInDirDisplay = NumberWithCommas(stats?.numUsersInDir ?? 0);
    numUsersNoEmailDisplay = NumberWithCommas(
      stats?.numUsersInDirWithoutEmail ?? 0
    );
    numAdminAppsDisplay = NumberWithCommas(stats?.numAdminApprovedApps ?? 0);
  }

  return (
    <ReportCard newStyles className="userbase-about-user-directory">
      <div className="header">
        <span>{dirTypeToDisplay}</span>
      </div>
      {isLoading && <LoadingBanner />}

      {!isLoading && stats === null && (
        <EmptyCard text="No information available" />
      )}

      {!isLoading && stats && (
        <div className={"content"}>
          <InfoTable>
            <InfoTableRow
              rowClass={"no-max-height"}
              label="# Directory accounts"
              value={numUsersInDirDisplay}
            />
            <InfoTableRow
              label="# Accounts without email"
              rowClass={"no-max-height"}
              value={numUsersNoEmailDisplay}
            />

            {/* Only show admin apps for Microsoft 365 */}

            {stats?.origin === UserDirectoryType.Microsoft365 && (
              <InfoTableRow
                label="# Admin approved apps"
                rowClass={"no-max-height"}
                value={numAdminAppsDisplay}
              />
            )}
          </InfoTable>
        </div>
      )}
    </ReportCard>
  );
};

export default AboutUserDirectory;
