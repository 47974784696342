import { FC, ReactNode } from "react";

import "./SlidePanelKVTable.scss";

type SlidePanelKVTableRow = {
  key: string;
  value: ReactNode;
};

type SlidePanelKVTableProps = {
  rows: Array<SlidePanelKVTableRow>;
};

const SlidePanelKVTable: FC<SlidePanelKVTableProps> = ({ rows }) => {
  const toRow = (row: SlidePanelKVTableRow) => {
    return (
      <div className="slide-panel-table-row" key={row.key}>
        <div className="slide-panel-table-title">{row.key}</div>
        <div className="slide-panel-table-data">{row.value}</div>
      </div>
    );
  };

  return <div className="slide-panel-kv-table">{rows.map(toRow)}</div>;
};

export default SlidePanelKVTable;
