import {
  FixedSizeNodeComponentProps,
  FixedSizeNodeData,
  FixedSizeTree,
} from "react-vtree";
import classnames from "classnames";
import { throttle } from "lodash";

import "../style/components/TreeTableWindowed.scss";
import {
  Fragment,
  HTMLProps,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  ISortedBy,
  IXTableColumnHeader,
  OnSortChange,
  SortDirection,
} from "../../_common/components/core/XTable";
import Icon from "../../_common/components/core/Icon";
import DropDown from "../../_common/components/core/DropDown";
import ColorCheckbox from "./ColorCheckbox";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { IsInsideModalContext } from "../../_common/components/ModalV2";

export interface TreeNode {
  id: number | string;
  // Array of children for this node.
  children: TreeNode[];
  // Extra class name if needed.
  className?: string;
  // This will be placed WITHIN the first cell, which will expand to
  // fill available space in the table.
  mainCellContent: ReactNode;
  // Each of the remaining cells must use the NodeCell component and specify a width
  // in pixels or % via CSS classes. If multiple, pass in within a fragment.
  otherCells: ReactNode;
  // Optional click handler for the row.
  onClick?: () => void;
  // Selected state, if table is selectable
  selected?: boolean;
  selectionDisabled?: boolean;
  selectionDisabledHelpText?: string;
}

type StackElement = Readonly<{
  nestingLevel: number;
  upperLineArr: boolean[];
  lowerLineArr: boolean[];
  node: TreeNode;
}>;

type TreeData = FixedSizeNodeData &
  Readonly<{
    isLeaf: boolean;
    nestingLevel: number;
    upperLineArr: boolean[];
    lowerLineArr: boolean[];
    node: TreeNode;
    treeTableProps: ITreeTableWindowedProps;
  }>;

// NodeCell should be used in the otherCells prop for a TreeNode.
export const NodeCell = ({ className, ...rest }: HTMLProps<HTMLDivElement>) => (
  <div className={`node-cell ${className}`} {...rest} />
);

// Node component receives all the data we created in the `treeWalker` +
// internal openness state (`isOpen`), function to change internal openness
// state (`setOpen`) and `style` parameter that should be added to the root div.
const Node = ({
  data: {
    isLeaf,
    node,
    nestingLevel,
    upperLineArr,
    lowerLineArr,
    treeTableProps,
  },
  isOpen,
  style,
  toggle,
}: FixedSizeNodeComponentProps<TreeData>) => {
  return (
    <div
      className={classnames("tree-node", node.className, {
        clickable: !!node.onClick,
        selected: !!node.selected,
      })}
      onClick={node.onClick}
      style={{
        ...style,
      }}
    >
      {treeTableProps.childrenSelection && (
        <NodeCell className="selection-box">
          {node.children.length > 0 ? (
            <DropDown
              right
              stopPropagationOnOpen
              action={
                <div className="selection-dropdown">
                  <SidePopupV2
                    text={node.selectionDisabledHelpText}
                    position="top"
                  >
                    <ColorCheckbox
                      disabled={!!node.selectionDisabled}
                      checked={!!node.selected}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        if (treeTableProps.onSelectClick) {
                          treeTableProps.onSelectClick(node.id, !node.selected);
                        }
                      }}
                    />
                  </SidePopupV2>
                  <Icon name="chevron" />
                </div>
              }
            >
              <div
                className={"actions-opt"}
                key={"select-all"}
                onClick={(ev) => {
                  ev.stopPropagation();
                  // Make sure we open up the row as well, so they can see everything that's been selected.
                  if (!isOpen) {
                    toggle();
                  }
                  treeTableProps.childrenSelection?.onSelectChildrenClick(
                    node.id,
                    true
                  );
                }}
              >
                {treeTableProps.childrenSelection.selectAllText}
              </div>
              <div
                className={"actions-opt"}
                key={"select-none"}
                onClick={(ev) => {
                  ev.stopPropagation();
                  treeTableProps.childrenSelection?.onSelectChildrenClick(
                    node.id,
                    false
                  );
                }}
              >
                {treeTableProps.childrenSelection.selectNoneText}
              </div>
            </DropDown>
          ) : (
            <SidePopupV2 text={node.selectionDisabledHelpText} position="top">
              <ColorCheckbox
                disabled={!!node.selectionDisabled}
                checked={!!node.selected}
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (treeTableProps.onSelectClick) {
                    treeTableProps.onSelectClick(node.id, !node.selected);
                  }
                }}
              />
            </SidePopupV2>
          )}
        </NodeCell>
      )}
      <NodeCell className="main">
        {(!isLeaf || nestingLevel > 0) && (
          <div className="tree-toggle">
            {nestingLevel > 0 && (
              <>
                <div className="indent-blank half" />
                {new Array(nestingLevel).fill(null).map((_v, i) => (
                  <Fragment key={i}>
                    <div className="line-container">
                      {upperLineArr[i] && <div className="upper-line" />}
                      {lowerLineArr[i] && <div className="lower-line" />}
                    </div>
                    {i === nestingLevel - 1 ? (
                      <div className="indent-line" />
                    ) : (
                      <>
                        <div className="indent-blank" />
                        <div className="indent-blank half" />
                      </>
                    )}
                  </Fragment>
                ))}
              </>
            )}
            {!isLeaf && (
              <div
                className={`toggle-circle clickable`}
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                <div className="toggle-circle-circle">
                  <div className="toggle-circle-text">{isOpen ? "-" : "+"}</div>
                </div>
                {isOpen && (
                  <div className="line-container">
                    <div className="lower-line" />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {node.mainCellContent}
      </NodeCell>
      {node.otherCells}
    </div>
  );
};

export interface ITreeTableWindowedProps {
  nodes: TreeNode[];
  // Every column header except the first must have a className
  // that sets a width in px or %, matching the cells in the table.
  columnHeaders: IXTableColumnHeader[];
  // Click handler for when a row is selected, if the table is selectable.
  onSelectClick?: (rowId: number | string, selected: boolean) => void;
  // Optional children selection. Will render checkbox with a dropdown.
  // Must be specified for selection to be available.
  childrenSelection?: {
    selectAllText: string;
    selectNoneText: string;
    onSelectChildrenClick: (
      parentNodeId: number | string,
      selected: boolean
    ) => void;
  };
  sortedBy?: ISortedBy;
  onSortChange?: OnSortChange;
  // Override the default height of an individual item.
  itemHeight?: number;
  // Override the overall height of the tree table
  height?: number;
  overscanCount?: number;
  hasMultiProductNavigation?: boolean; // Temporary prop to handle multi-product navigation removing window scroll
}

const getScrollPosition = (scrollElement?: HTMLElement | null) => {
  if (scrollElement) {
    return scrollElement.scrollTop;
  }

  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
};

const fixedSizeTreeStyle = {
  width: "100%",
  height: "100%",
  display: "inline-block",
};

const TreeTableWindowed = (props: ITreeTableWindowedProps) => {
  function* treeWalker(
    refresh: boolean
  ): Generator<TreeData | string | symbol, void, boolean> {
    const stack: StackElement[] = [];

    // Add our first level of nodes to the stack
    for (let i = props.nodes.length - 1; i >= 0; i--) {
      stack.push({
        nestingLevel: 0,
        upperLineArr: [],
        lowerLineArr: [],
        node: props.nodes[i],
      });
    }

    while (stack.length !== 0) {
      const { node, nestingLevel, upperLineArr, lowerLineArr } =
        stack.pop() as StackElement;
      const id = node.id.toString();

      const isOpened = yield refresh
        ? {
            id,
            isLeaf: node.children.length === 0,
            isOpenByDefault: false,
            nestingLevel,
            upperLineArr,
            lowerLineArr,
            node,
            treeTableProps: props,
          }
        : id;

      if (node.children.length !== 0 && isOpened) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          const newUpperLineArr = [...upperLineArr];
          const newLowerLineArr = [...lowerLineArr];

          if (
            nestingLevel > 0 &&
            newUpperLineArr[nestingLevel - 1] &&
            !newLowerLineArr[nestingLevel - 1]
          ) {
            newUpperLineArr[nestingLevel - 1] = false;
          }

          // Children will always start off with a line going up to their parent.
          newUpperLineArr.push(true);

          // This will only have a lower line if it's not the last sibling at this level.
          if (i < node.children.length - 1) {
            newLowerLineArr.push(true);
          } else {
            newLowerLineArr.push(false);
          }

          stack.push({
            nestingLevel: nestingLevel + 1,
            upperLineArr: newUpperLineArr,
            lowerLineArr: newLowerLineArr,
            node: node.children[i],
          });
        }
      }
    }
  }

  const ref = useRef();
  const outerRef = useRef<HTMLDivElement>();
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const mainContentAreaRef = useRef<HTMLElement>(
    document.getElementById("main-content-area")
  );

  const inModal = useContext(IsInsideModalContext);

  // Add handlers to bind the window scrolling to the fixed size tree scrolling.
  // This helps to avoid the 'double scrolling' effect.
  // This logic is copied from https://github.com/FedericoDiRosa/react-window-scroller.

  // Add a window scroll handler to ensure we scroll the fixed size tree when scrolling the window.
  useEffect(() => {
    const handleWindowScroll = throttle(() => {
      const distanceFromTop =
        outerRef.current?.getBoundingClientRect().top || 0;
      if (ref.current) {
        (ref.current as any).scrollTo(-distanceFromTop);
      }
    }, 10);

    // if we are in a modal we want to use the inner container ref for scrolling
    if (inModal) {
      if (innerContainerRef.current) {
        innerContainerRef.current.addEventListener(
          "scroll",
          handleWindowScroll
        );
        return () => {
          handleWindowScroll.cancel();
          if (innerContainerRef.current) {
            innerContainerRef.current.removeEventListener(
              "scroll",
              handleWindowScroll
            );
          }
        };
      } else {
        return handleWindowScroll.cancel;
      }
    } else {
      if (props.hasMultiProductNavigation) {
        // With multi product navigation, the window should never scroll.  Instead, the main-content-area div will.
        mainContentAreaRef.current?.addEventListener(
          "scroll",
          handleWindowScroll
        );

        return () => {
          handleWindowScroll.cancel();
          mainContentAreaRef.current?.removeEventListener(
            "scroll",
            handleWindowScroll
          );
        };
      } else {
        // For old nav, the window scrolls
        window.addEventListener("scroll", handleWindowScroll);
        return () => {
          handleWindowScroll.cancel();
          window.removeEventListener("scroll", handleWindowScroll);
        };
      }
    }
  }, [inModal, innerContainerRef]);

  // Add a scroll handler on the fixed size tree to update the window scroll position as well.
  const onScroll = useCallback(
    ({
      scrollOffset,
      scrollUpdateWasRequested,
    }: {
      scrollOffset: number;
      scrollUpdateWasRequested: boolean;
    }) => {
      if (!scrollUpdateWasRequested) {
        return;
      }
      const top = getScrollPosition(
        props.hasMultiProductNavigation ? mainContentAreaRef.current : undefined
      );
      const offsetTop =
        (outerRef.current?.getBoundingClientRect().top || 0) + top;

      scrollOffset += Math.min(top, offsetTop);

      if (scrollOffset !== top) {
        if (props.hasMultiProductNavigation) {
          // With multi product navigation, the main-content-area div should scroll.
          if (
            mainContentAreaRef.current &&
            mainContentAreaRef.current?.scrollTop
          ) {
            mainContentAreaRef.current.scrollTop = scrollOffset;
          }
        } else {
          window.scrollTo(0, scrollOffset);
        }
      }
    },
    []
  );

  let scrollElementHeight = window.innerHeight;

  if (innerContainerRef.current) {
    if (mainContentAreaRef.current) {
      scrollElementHeight =
        mainContentAreaRef.current?.getBoundingClientRect().height;
    }
  }

  return (
    <div className="tree-table-windowed-container">
      <div className="tree-table-col-headers">
        {props.childrenSelection && (
          <div className="col-header selection-col" />
        )}
        {props.columnHeaders.map((c, i) => (
          <div
            key={c.id}
            className={classnames("col-header", c.className, {
              main: i === 0,
              clickable: !!c.sortable && !!props.onSortChange,
              "sort-active": props.sortedBy?.columnId === c.id,
            })}
            onClick={
              !!c.sortable && !!props.onSortChange
                ? () => {
                    if (!props.onSortChange) {
                      return;
                    }

                    let newSortDir = c.startingSortDir || SortDirection.DESC;
                    if (props.sortedBy?.columnId === c.id) {
                      newSortDir =
                        props.sortedBy.direction === SortDirection.DESC
                          ? SortDirection.ASC
                          : SortDirection.DESC;
                    }

                    props.onSortChange(c.id.toString(), newSortDir);
                  }
                : undefined
            }
          >
            {c.text}
            {c.sortable && (
              <Icon
                name="arrow"
                direction={
                  (props.sortedBy?.columnId === c.id
                    ? props.sortedBy.direction
                    : c.startingSortDir || SortDirection.DESC) ===
                  SortDirection.DESC
                    ? 180
                    : 0
                }
              />
            )}
          </div>
        ))}
      </div>
      <div ref={innerContainerRef} className={"tree-table-inner-container"}>
        <FixedSizeTree
          className="tree-table-windowed"
          treeWalker={treeWalker}
          itemSize={props.itemHeight || 72}
          height={
            props.height ?? inModal
              ? innerContainerRef.current?.getBoundingClientRect().height ??
                scrollElementHeight
              : scrollElementHeight
          }
          style={fixedSizeTreeStyle}
          width={"100%"}
          onScroll={!inModal ? onScroll : undefined}
          // @ts-ignore
          ref={ref}
          outerRef={outerRef}
          overscanCount={props.overscanCount}
        >
          {Node}
        </FixedSizeTree>
      </div>
    </div>
  );
};

export default TreeTableWindowed;
