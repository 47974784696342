import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import UserBaseAPI from "../../api/userbase.api";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Doughnut from "../Doughnut";
import Button from "../../../_common/components/core/Button";
import { useDefaultHistory } from "../../../_common/types/router";
import { userApplicationsUrl, userSummaryUrl } from "../../UserBaseAppRouter";
import { pluralise } from "../../../_common/helpers";
import "./UserAppsOverview.scss";
import { UserAppStatusColor } from "../applications/AppUsersOverview";

interface UserAppsOverviewProps {
  userUUID: string;
}

const UserAppsOverview: FC<UserAppsOverviewProps> = ({ userUUID }) => {
  const history = useDefaultHistory();

  const { data: userSummary } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: userUUID,
  });

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserAppsV1Query({
    userUUID: userUUID,
  });

  const onViewApps = () => {
    history.push(userApplicationsUrl(userUUID), {
      backContext: {
        backTo: userSummaryUrl(userUUID),
        backToText: `Back to ${userSummary?.user?.name}`,
      },
    });
  };

  const appsToUse = data?.apps ?? [];

  const doughnutData = [
    {
      label: "Approved",
      count: appsToUse.filter((a) => a.userIsApproved === true).length,
      hexColor: UserAppStatusColor.Approved,
    },
    {
      label: "Not Approved",
      count: appsToUse.filter(
        (a) => a.userIsApproved === false && a.userIsWaived === false
      ).length,
      hexColor: UserAppStatusColor.NotApproved,
    },
    {
      label: "Waived",
      count: appsToUse.filter((a) => a.userIsWaived === true).length,
      hexColor: UserAppStatusColor.Waived,
    },
  ].filter((d) => d.count > 0); // only display non-empty entries

  return (
    <ReportCard newStyles className="userbase-user-apps-overview">
      <div className="header">
        <span>Apps</span>
        <div>
          <Button tertiary onClick={onViewApps}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <Doughnut
            data={doughnutData}
            totalLabel={pluralise(appsToUse.length, "App", "Apps")}
          />
        </div>
      )}
    </ReportCard>
  );
};

export default UserAppsOverview;
