import { useState } from "react";
import { DefaultThunkDispatch } from "../../types/redux";
import ModalV2 from "../ModalV2";
import { contactSupport } from "../../helpers";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import { markOnboardingMeetingBooked } from "../../reducers/freeTrial.actions";
import { HubspotMeeting } from "../../../registration/HubspotMeeting";
import "../../style/components/BookTrialUpgradeCallModal.scss";
import classnames from "classnames";

interface IBookTrialUpgradeCallModalProps {
  dispatch: DefaultThunkDispatch;
  active: boolean;
  onClose: () => void;
  emailAddress: string;
  firstName: string;
  lastName: string;
  calendar?: string;
}

// event we need to report on via slack
export const EVENT_BOOK_TRIAL_PRESSED = "BOOK_TRIAL_PRESS";

export const BookTrialUpgradeCallModal = (
  props: IBookTrialUpgradeCallModalProps
) => {
  const {
    dispatch,
    onClose,
    active,
    emailAddress,
    firstName,
    lastName,
    calendar,
  } = props;
  const [inError, setInError] = useState(false);

  const onMeetingBooked = async () => {
    setInError(false);

    try {
      await dispatch(markOnboardingMeetingBooked());
      return;
    } catch (e) {
      console.error(e);
    }
    dispatch(
      addDefaultUnknownErrorAlert(
        "An unknown error occurred. Please contact support@upguard.com."
      )
    );
    setInError(true);
  };

  return (
    <ModalV2
      className={classnames("free-trial-meeting-modal", {
        "free-trial-meeting-modal-shrunk": inError,
      })}
      headerClassName={"free-trial-meeting-modal-header"}
      active={active}
      onClose={() => {
        onClose();
        setInError(false);
      }}
    >
      <div className="simple-modal-form">
        <div>
          {inError && (
            <>
              <h2>Booking failed</h2>
              <p className={"thankyou"}>
                {`Sorry, it looks like something went wrong with our booking
                system. Please `}
                <a href="#" onClick={contactSupport}>
                  contact support
                </a>
                to arrange your onboarding call, or you can close this window
                and try again.
              </p>
            </>
          )}
          {!inError && (
            <>
              <h4>Schedule your free onboarding call</h4>
              <div className="description">
                Connect with one of our technical experts and get a personalized
                onboarding experience. We&apos;ll answer any questions you have,
                outline best practices, and ensure you get the most out of your
                trial.
              </div>
              <div className={"calendar-container"}>
                {calendar && (
                  <HubspotMeeting
                    calendarId={calendar}
                    emailAddress={emailAddress}
                    firstName={firstName}
                    lastName={lastName}
                    onMeetingBooked={onMeetingBooked}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </ModalV2>
  );
};
