import { IReportsLibraryItem } from "./ReportsLibrary";
import {
  CannedExportConfig,
  CustomCannedExportConfig,
  StarredExportType,
} from "../../../_common/types/exportConfig";
import { FC, useMemo, useState } from "react";
import "../../style/components/reporting/ReportsLibraryTable.scss";
import { useSorting } from "../../../_common/hooks";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import moment from "moment/moment";
import classnames from "classnames";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import Button from "../../../_common/components/core/Button";
import IconButton from "../../../_common/components/IconButton";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { UserAvatarAndName } from "../../../_common/components/UserAvatar";
import ButtonGroup from "../../../_common/components/ButtonGroup";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";

interface IReportsLibraryTableProps {
  libraryItems: IReportsLibraryItem[];
  onCustomizeAndGenerate: (conf: CannedExportConfig) => void;
  onQuickGenerate: (conf: CannedExportConfig) => void;
  onCloneTemplate: (conf: CannedExportConfig) => void;
  onEditTemplate: (conf: CustomCannedExportConfig) => void;
  onDeleteTemplate: (conf: CustomCannedExportConfig) => void;
  onStarTemplate: (
    starredExportType: StarredExportType,
    starred: boolean
  ) => void;
  canCreateEditOwnTemplates: boolean;
  canCreateEditOrgTemplates: boolean;
  currentUserId: number;
  orgSupportsCustomTemplates: boolean;
}

type columnId =
  | "default_sort"
  | "report_name"
  | "report_type"
  | "created_by"
  | "date_created";

const ReportsLibraryTable: FC<IReportsLibraryTableProps> = ({
  libraryItems,
  onCustomizeAndGenerate,
  onQuickGenerate,
  onCloneTemplate,
  onEditTemplate,
  onDeleteTemplate,
  onStarTemplate,
  canCreateEditOwnTemplates,
  canCreateEditOrgTemplates,
  currentUserId,
  orgSupportsCustomTemplates,
}) => {
  // Keep track of which row ID has a currently active dropdown. We need to do this as
  // the buttons and dropdowns only show when the row is hovered, but when the dropdown is open,
  // we want to keep treating the row as if it is still hovered.
  const [rowIdWithActiveButtonDropdown, setRowIdWithActiveButtonDropdown] =
    useState<string | undefined>(undefined);
  const [rowIdWithActiveOtherDropdown, setRowIdWithActiveOtherDropdown] =
    useState<string | undefined>(undefined);

  const [sortedItems, sortedBy, onSortChange] = useSorting<
    IReportsLibraryItem,
    columnId
  >(libraryItems, "default_sort", SortDirection.DESC, {
    default_sort: {
      // By default, sort items the same way we do on the grid
      orderFuncs: [() => 0],
    },
    report_name: {
      orderFuncs: [(item) => item.name],
    },
    report_type: {
      orderFuncs: [(item) => item.reportType],
    },
    created_by: {
      orderFuncs: [
        (item) => item.customCannedConfig?.createdByUser.name ?? "UpGuard",
      ],
    },
    date_created: {
      orderFuncs: [(item) => item.customCannedConfig?.createdAt ?? ""],
    },
  });

  const rows = useMemo(() => {
    const rows: IXTableRow[] = sortedItems.map((item) => {
      const cells = [
        <XTableCell key="name">
          <div className="main-cell">
            <div
              className="report-thumb"
              style={{ backgroundColor: item.badgeImageRef.backgroundColor }}
            >
              <img
                className={classnames("item-bg", {
                  landscape: item.badgeImageRef.landscape,
                })}
                alt={item.name}
                src={item.badgeImageRef.image}
              />
            </div>
            <div className="report-type-and-name">
              <div className="report-type">
                <div className="report-type-type">{item.module}</div>
              </div>
              <div className="report-name">{item.name}</div>
            </div>
          </div>
        </XTableCell>,
        <XTableCell key="type" className="shrink-cell">
          {item.reportType}
        </XTableCell>,
        <XTableCell key="createdBy" className="shrink-cell">
          {item.customCannedConfig ? (
            <UserAvatarAndName
              name={item.customCannedConfig.createdByUser.name}
              avatar={item.customCannedConfig.createdByUser.avatar}
            />
          ) : (
            "UpGuard"
          )}
        </XTableCell>,
      ];
      if (orgSupportsCustomTemplates) {
        cells.push(
          <XTableCell key="createdAt" className="created-at-cell">
            {item.customCannedConfig
              ? moment(item.customCannedConfig.createdAt).format("L")
              : undefined}
          </XTableCell>
        );
      }

      cells.push(
        <XTableCell key="actions" className="shrink-cell">
          <div className="actions-cell">
            {item.defaultCannedConfig ?? item.customCannedConfig ? (
              <>
                <ButtonGroup className="main-btn">
                  <Button
                    primary
                    onClick={() =>
                      onQuickGenerate(
                        item.defaultCannedConfig ?? item.customCannedConfig!
                      )
                    }
                  >
                    Generate
                  </Button>
                  <DropdownV2
                    popupItem={
                      <Button primary iconOnly>
                        <div className="cr-icon-chevron rot90" />
                      </Button>
                    }
                    onActiveChange={(active: boolean) => {
                      // When this dropdown is open, set this row as actively hovered,
                      // so the buttons don't disappear
                      if (
                        rowIdWithActiveButtonDropdown === item.id &&
                        !active
                      ) {
                        setRowIdWithActiveButtonDropdown(undefined);
                      } else if (active) {
                        setRowIdWithActiveButtonDropdown(item.id);
                      }
                    }}
                  >
                    <DropdownItem
                      onClick={() =>
                        onQuickGenerate(
                          item.defaultCannedConfig ?? item.customCannedConfig!
                        )
                      }
                    >
                      Quick generate
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        onCustomizeAndGenerate(
                          item.defaultCannedConfig ?? item.customCannedConfig!
                        )
                      }
                    >
                      Customize and generate
                    </DropdownItem>
                  </DropdownV2>
                </ButtonGroup>
              </>
            ) : (
              <Button
                className="main-btn"
                primary
                onClick={item.adhocGenerateAction}
              >
                Generate
              </Button>
            )}
            <SidePopupV2
              title={item.starred ? undefined : "Add to favorites"}
              text={
                item.starred
                  ? "Remove from favorites"
                  : "Your favorites will be shown first in this list."
              }
              position="top"
              className="star"
              micro
              noWrap={item.starred}
              width={item.starred ? undefined : 150}
              popupDelay={600}
            >
              <IconButton
                onClick={() =>
                  onStarTemplate(
                    {
                      customCannedExportUUID: item.customCannedConfig?.uuid,
                      defaultCannedConfigID:
                        item.defaultCannedConfig?.defaultID,
                      otherReportID: item.otherReportID,
                    },
                    !item.starred
                  )
                }
                icon={
                  <div
                    className={
                      item.starred
                        ? "cr-icon-star-filled"
                        : "cr-icon-star-outline"
                    }
                  />
                }
              />
            </SidePopupV2>
            <DropdownV2
              className={classnames("actions-dropdown", {
                visible:
                  canCreateEditOwnTemplates &&
                  !!(item.defaultCannedConfig ?? item.customCannedConfig),
              })}
              popupItem={
                <IconButton icon={<div className="cr-icon-dots-menu" />} />
              }
              onActiveChange={(active: boolean) => {
                // When this dropdown is open, set this row as actively hovered,
                // so the buttons don't disappear
                if (rowIdWithActiveOtherDropdown === item.id && !active) {
                  setRowIdWithActiveOtherDropdown(undefined);
                } else if (active) {
                  setRowIdWithActiveOtherDropdown(item.id);
                }
              }}
            >
              {canCreateEditOwnTemplates &&
              !!(item.defaultCannedConfig ?? item.customCannedConfig) ? (
                <>
                  <DropdownItem
                    onClick={() =>
                      onCloneTemplate(
                        item.defaultCannedConfig ?? item.customCannedConfig!
                      )
                    }
                  >
                    <div className="cr-icon-copy" /> Copy to new template
                  </DropdownItem>
                  {!!item.customCannedConfig &&
                    (canCreateEditOrgTemplates ||
                      item.customCannedConfig?.createdBy === currentUserId) && (
                      <>
                        <DropdownItem
                          onClick={() =>
                            onEditTemplate(item.customCannedConfig!)
                          }
                        >
                          <div className="cr-icon-edit-doc" /> Edit template
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            onDeleteTemplate(item.customCannedConfig!)
                          }
                        >
                          <div className="cr-icon-trash" /> Delete template
                        </DropdownItem>
                      </>
                    )}
                </>
              ) : (
                <></>
              )}
            </DropdownV2>
          </div>
        </XTableCell>
      );

      return {
        id: item.id,
        className: classnames("clickable", {
          hovered:
            rowIdWithActiveButtonDropdown === item.id ||
            rowIdWithActiveOtherDropdown === item.id,
        }),
        cells: cells,
      };
    });

    return rows;
  }, [
    sortedItems,
    canCreateEditOwnTemplates,
    canCreateEditOrgTemplates,
    currentUserId,
    rowIdWithActiveButtonDropdown,
    rowIdWithActiveOtherDropdown,
    onQuickGenerate,
    onCustomizeAndGenerate,
    onStarTemplate,
    onCloneTemplate,
    onEditTemplate,
    onDeleteTemplate,
  ]);

  const columnHeaders = useMemo(() => {
    const columnHeaders: IXTableColumnHeader[] = [
      {
        id: "report_name",
        text: "Report",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "report_type",
        text: "Report Type",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "created_by",
        text: "Created By",
        sortable: true,
        startingSortDir: SortDirection.ASC,
        className: "shrink-cell",
      },
    ];
    if (orgSupportsCustomTemplates) {
      columnHeaders.push({
        id: "date_created",
        text: "Date created",
        sortable: true,
        startingSortDir: SortDirection.DESC,
        className: "shrink-cell",
      });
    }
    columnHeaders.push({
      id: "actions",
      text: "",
      sortable: false,
    });
    return columnHeaders;
  }, []);

  return rows.length === 0 ? (
    <SearchEmptyCard searchItemText="templates" />
  ) : (
    <XTable
      className="reports-library-table"
      rows={rows}
      columnHeaders={columnHeaders}
      sortedBy={sortedBy}
      onSortChange={onSortChange}
    />
  );
};

export default ReportsLibraryTable;
