import { Tab } from "../views/ThreatMonitoringView";
import "./ThreatMonitoringTabs.scss";
import classnames from "classnames";

export interface ThreatMonitoringTabsProps {
  selectedIdx?: Tab;
  onSelect: (idx: Tab) => void;
  totalOpen?: number | undefined;
  totalInvestigating?: number | undefined;
  totalClosed?: number | undefined;
  totalArchived?: number | undefined;
  totalRemediating?: number | undefined;
}

const ThreatMonitoringTabs = (props: ThreatMonitoringTabsProps) => {
  const {
    selectedIdx,
    onSelect,
    totalOpen,
    totalInvestigating,
    totalClosed,
    totalRemediating,
  } = props;

  return (
    <div className={"threat-monitoring-tabs"}>
      <div
        className={classnames("tab", { selected: selectedIdx == Tab.Open })}
        onClick={() => onSelect(Tab.Open)}
      >
        <div className={"label bracL"}>{`(`}</div>
        <div className={"cr-icon-risk"} />
        <div className={"label bracR"}>{`)`}</div>
        <div className={"label"}>
          {totalOpen === undefined ? `Open` : `Open (${totalOpen})`}
        </div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Investigating,
        })}
        onClick={() => onSelect(Tab.Investigating)}
      >
        <div className={"cr-icon-bookmark"} />
        <div className={"label"}>
          {totalInvestigating === undefined
            ? `Investigating`
            : `Investigating (${totalInvestigating})`}
        </div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Remediating,
        })}
        onClick={() => onSelect(Tab.Remediating)}
      >
        <div className={"cr-icon-spanner-outline"} />
        <div className={"label"}>
          {totalRemediating === undefined
            ? `Remediating`
            : `Remediating (${totalRemediating})`}
        </div>
      </div>
      <div className={"sep"} />
      <div
        className={classnames("tab", {
          selected: selectedIdx == Tab.Closed,
        })}
        onClick={() => onSelect(Tab.Closed)}
      >
        <div className={"cr-icon-cancel"} />
        <div className={"label"}>
          {totalClosed === undefined ? `Closed` : `Closed (${totalClosed})`}
        </div>
      </div>
      <div className={"sep all"} />
    </div>
  );
};

export default ThreatMonitoringTabs;
