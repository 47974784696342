import { FC, HTMLProps, ReactNode, useState } from "react";
import ScrollableDiv from "../ScrollableDiv";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  clearedCloudConnectionsFilters,
  clearedDataLeaksFilters,
  clearedSubsidiaryFilters,
  clearedSurveyStatusFilters,
  clearedTyposquatFilters,
  clearedUserRiskFilters,
  clearedVendorFilters,
  clearedVulnsFilters,
  clearedWebsiteFilters,
  Filters,
  FilterTypes,
} from "./types";
import ScoreFilter from "./ScoreFilter";
import { LabelsFilterContainer } from "./LabelsFilter";
import TyposquatTypesFilter from "./TyposquatTypesFilter";
import TyposquatReasonsFilter from "./TyposquatReasonsFilter";
import PortfolioFilter from "./PortfolioFilter";
import VendorTierFilter from "./VendorTierFilter";
import { RiskFilterContainer } from "./RiskFilter";
import RiskCategoryFilter from "./RiskCategoryFilter";
import VendorFilter from "./VendorFilter";
import DataLeaksClassificationFilter from "./DataLeaksClassificationFilter";
import DataLeaksSourceFilter from "./DataLeaksSourceFilter";
import DataLeaksKeywordFilter from "./DataLeaksKeywordFilter";
import VulnsVerifiedStatusFilter from "./VulnsVerifiedStatusFilter";
import VulnsCVENameFilter from "./VulnsCVENameFilter";
import VulnsSoftwareFilter from "./VulnsSoftwareFilter";
import SeverityFilter from "./SeverityFilter";
import SubsidiaryFilter from "./SubsidiaryFilter";
import IPCountryFilter from "./IPCountryFilter";
import Button from "../../../_common/components/core/Button";
import Icon from "../../../_common/components/core/Icon";
import { LabelClassification } from "../../../_common/types/label";
import {
  getFiltersFromState,
  setVendorDataFiltersAndRefreshData,
} from "../../reducers/filters.actions";
import { isEqual } from "lodash";
import { setVendorPortfolioRiskProfile } from "../../reducers/vendorRiskPortfolio.actions";
import { setCustomerDataFiltersAndRefreshData } from "../../reducers/cyberRiskActions";

import {
  OrgAccessAdditionalEvidence,
  OrgAccessSurveys,
  UserSystemRoleVendorManagementAnalyst,
} from "../../../_common/permissions";

import "../../style/components/filter/FilterPanel.scss";
import SurveyStatusFilter from "./SurveyStatusFilter";
import { VendorAttributesFilterContainer } from "./VendorAttributesFilter";
import DataLeaksReportedDateFilter from "./DataLeaksReportedDateFilter";
import { AssuranceType } from "../../../_common/types/organisations";
import VendorAssessmentClassificationFilter from "./VendorAssessmentClassificationFilter";
import VendorAssessmentAuthorFilter, {
  AuthorFilterChangeObj,
} from "./VendorAssessmentAuthorFilter";
import { getVendorWords } from "../../../_common/constants";
import { VulnsAvailableFilters } from "../../types/vulns";
import { PortfolioType } from "../../reducers/portfolios.actions";
import VendorSurveyTypesFilter from "./VendorSurveyTypesFilter";
import VendorEvidenceTypesFilter from "./VendorEvidenceTypesFilter";
import DateFilter, { IDateFilterState } from "./DateFilter";
import { appConnect } from "../../../_common/types/reduxHooks";
import TyposquatMaliciousActivitiesFilter from "./TyposquatMaliciousActivitiesFilter";
import RangeFilter from "./RangeFilter";
import FourthPartyProductFilter from "./FourthPartyProductFilter";
import CloudProviderFilter from "../../../appguard/components/filters/CloudProviderFilter";
import CloudConnectionFilter from "../../../appguard/components/filters/CloudConnectionFilter";
import UserRiskUsersFilter from "../../../userbase/components/filters/UserRiskUsersFilter";
import UserRiskAppsFilter from "../../../userbase/components/filters/UserRiskAppsFilter";
import UserRiskTeamsFilter from "../../../userbase/components/filters/UserRiskTeamsFilter";
import UserRiskRolesFilter from "../../../userbase/components/filters/UserRiskRolesFilter";
import UserRisksAppStatusesFilter from "../../../userbase/components/filters/UserRiskAppStatusesFilter";
import { ScopeRiskLevel } from "../../../userbase/api/types";
import UserRiskExposureLevelFilter from "../../../userbase/components/filters/UserRiskExposureLevelFilter";
import UserRiskScopeReadWriteFilter from "../../../userbase/components/filters/UserRiskScopeReadWriteFilter";
import UserRiskScoreFilter, {
  UserRiskScoreFilterNoun,
} from "../../../userbase/components/filters/UserRiskScoreFilter";
import UserRiskScopeCategoryFilter from "../../../userbase/components/filters/UserRiskScopeCategoryFilter";

interface IFilterPanelStylesProps extends HTMLProps<HTMLDivElement> {
  buttons?: ReactNode;
}

export const FilterPanelStyles: FC<IFilterPanelStylesProps> = ({
  children,
  className = "",
  buttons,
  ...otherDivProps
}) => (
  <div className={`filter-panel ${className}`} {...otherDivProps}>
    <ScrollableDiv newStyles leaveSpaceAtBottom>
      {children}
    </ScrollableDiv>
    {buttons && (
      <div className="slide-panel-section button-group">{buttons}</div>
    )}
  </div>
);

const getFetchableVendorPortfolioRiskProfileFilters = ({
  includeUnlabeled,
  vendorLabelIds,
  vendorLabelIdsMatchAll,
  vendorIds,
}: Filters) => ({
  includeUnlabeled,
  vendorLabelIds,
  vendorLabelIdsMatchAll,
  vendorIds,
});

export const getClearedFilters = (
  supportedFilters: FilterTypes[]
): Partial<Filters> => {
  const anySupported = (...filterTypes: FilterTypes[]) =>
    filterTypes.some((filterType) => supportedFilters.includes(filterType));

  let clearedFilters = {
    riskCategories: [] as string[],
    vendorIds: [] as number[],
    riskIds: [] as string[],
  };
  if (
    anySupported(
      FilterTypes.WEBSITE_LABEL,
      FilterTypes.DOMAIN_PORTFOLIO,
      FilterTypes.WEBSITE_LABEL_ADVANCED,
      FilterTypes.RISK
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedWebsiteFilters };
  }

  if (
    anySupported(
      FilterTypes.SUBSIDIARY_LABEL,
      FilterTypes.SUBSIDIARY_COUNTRY,
      FilterTypes.SUBSIDIARY,
      FilterTypes.SUBSIDIARY_SCORE
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedSubsidiaryFilters };
  }
  if (
    anySupported(
      FilterTypes.SCORE,
      FilterTypes.VENDOR_LABEL,
      FilterTypes.VENDOR_TIER,
      FilterTypes.VENDOR_ASSESSMENT_STATUS,
      FilterTypes.VENDOR_ASSESSMENT_AUTHOR,
      FilterTypes.VENDOR_REASSESSMENT_DATE,
      FilterTypes.VENDOR_PORTFOLIO,
      FilterTypes.VENDOR_DATE_ADDED
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedVendorFilters };
  }
  if (
    anySupported(
      FilterTypes.DATALEAKS_SOURCE,
      FilterTypes.DATALEAKS_KEYWORD,
      FilterTypes.DATALEAKS_CLASSIFICATION,
      FilterTypes.DATALEAKS_REPORTED_DATE
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedDataLeaksFilters };
  }
  if (
    anySupported(
      FilterTypes.VULNS_CVE_NAME,
      FilterTypes.VULNS_SOFTWARE,
      FilterTypes.VULNS_VERIFIED_STATUS,
      FilterTypes.VULNS_CVSS_SEVERITY,
      FilterTypes.VULNS_EPSS_SEVERITY
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedVulnsFilters };
  }
  if (
    anySupported(
      FilterTypes.TYPOSQUAT_REASONS,
      FilterTypes.TYPOSQUAT_TYPES,
      FilterTypes.TYPOSQUAT_MALICIOUS_ACTIVITIES
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedTyposquatFilters };
  }
  if (anySupported(FilterTypes.SURVEY_STATUS)) {
    clearedFilters = { ...clearedFilters, ...clearedSurveyStatusFilters };
  }

  if (
    anySupported(
      FilterTypes.APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE,
      FilterTypes.APPGUARD_CLOUD_CONNECTION_UUID
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedCloudConnectionsFilters };
  }

  if (
    anySupported(
      FilterTypes.USERRISK_USER_UUIDS,
      FilterTypes.USERRISK_APPS,
      FilterTypes.USERRISK_TEAMS,
      FilterTypes.USERRISK_ROLES,
      FilterTypes.USERRISK_APP_STATUS,
      FilterTypes.USERRISK_APP_APPROVED_TEAMS,
      FilterTypes.USERRISK_APP_APPROVED_ROLES,
      FilterTypes.USERRISK_SCOPE_RISK_LEVEL,
      FilterTypes.USERRISK_SCOPE_READWRITE,
      FilterTypes.USERRISK_SCOPE_CATEGORIES
    )
  ) {
    clearedFilters = { ...clearedFilters, ...clearedUserRiskFilters };
  }

  return clearedFilters;
};

interface IFilterPanelOwnProps {
  closePanel: () => void;
  disabledVendorPortfolioIds?: number[];
  supportedFilters: FilterTypes[];
  onFilterChanged?: () => void;
  availableVulnsFilterOptions?: VulnsAvailableFilters;
  scanningRisksOnly?: boolean; // used with the findings filter to only show scanning risks
  vendorId?: number;
  isCustomer?: boolean;
  isUserRisk?: boolean;
  isSubsidiary?: boolean;
  ipCountries?: string[];
  includeRootVendorInSubsidiaryFilter?: boolean;
  showRootVendorAsDefault?: boolean;
  isManagementAnalystSession?: boolean;
  managedOrgId?: number;
  startAllExpanded?: boolean;
}

interface IFilterPanelConnectedProps {
  filters: Filters;
  assuranceType: AssuranceType;
  dataLeaksAvailableSources?: Record<string, string>;
  dataLeaksAvailableKeywords?: string[];
  accountHasSurveysEnabled: boolean;
  accountHasAdditionalEvidenceEnabled: boolean;
}

type IFilterPanelProps = IFilterPanelOwnProps &
  IFilterPanelConnectedProps &
  DefaultThunkDispatchProp;

export const FilterPanel: FC<IFilterPanelProps> = ({
  filters,
  assuranceType,
  availableVulnsFilterOptions,
  ipCountries: allIPCountries,
  includeRootVendorInSubsidiaryFilter = false,
  showRootVendorAsDefault = true,
  disabledVendorPortfolioIds,
  supportedFilters,
  accountHasSurveysEnabled,
  accountHasAdditionalEvidenceEnabled,
  dataLeaksAvailableSources,
  dataLeaksAvailableKeywords,
  vendorId,
  isCustomer,
  isUserRisk = false,
  isSubsidiary = false,
  scanningRisksOnly,
  dispatch,
  onFilterChanged,
  closePanel,
  startAllExpanded = false,
}) => {
  const vendorWords = getVendorWords(assuranceType);
  const [filtersState, _setFiltersStateActual] = useState<Filters>({
    includeUnlabeled: filters.includeUnlabeled,
    vendorLabelIds: filters.vendorLabelIds,
    vendorLabelIdsMatchAll: filters.vendorLabelIdsMatchAll,
    vendorLabelIdsDoNotMatch: filters.vendorLabelIdsDoNotMatch,
    websiteLabelIds: filters.websiteLabelIds,
    websiteLabelIdsMatchAll: filters.websiteLabelIdsMatchAll,
    websiteLabelIdsDoNotMatch: filters.websiteLabelIdsDoNotMatch,
    websiteIncludeUnlabeled: filters.websiteIncludeUnlabeled,
    domainPortfolioIds: filters.domainPortfolioIds,
    minScore: filters.minScore,
    maxScore: filters.maxScore,
    riskCategories: filters.riskCategories || [],
    vendorIds: filters.vendorIds || [],
    riskIds: filters.riskIds || [],
    dataLeaksClassifications: filters.dataLeaksClassifications || [],
    dataLeaksSources: filters.dataLeaksSources || [],
    dataLeaksKeywords: filters.dataLeaksKeywords || [],
    dataLeaksReportedDate: filters.dataLeaksReportedDate ?? undefined,
    vulnsCveNames: filters.vulnsCveNames || [],
    vulnsSoftware: filters.vulnsSoftware || [],
    vulnsExcludeVerified: filters.vulnsExcludeVerified || false,
    vulnsExcludeUnverified: filters.vulnsExcludeUnverified || false,
    vulnsOnlyKEV: filters.vulnsOnlyKEV || false,
    vulnsExcludedSeverities: filters.vulnsExcludedSeverities || [],
    vulnsEPSSScores: filters.vulnsEPSSScores || {},
    typosquatReasonIds: filters.typosquatReasonIds || [],
    typosquatReasonIdsDoNotMatch: filters.typosquatReasonIdsDoNotMatch || false,
    typosquatTypeIds: filters.typosquatTypeIds || [],
    typosquatTypeIdsDoNotMatch: filters.typosquatTypeIdsDoNotMatch || false,
    typosquatMaliciousActivities: filters.typosquatMaliciousActivities || [],
    typosquatMaliciousActivitiesDoNotMatch:
      filters.typosquatMaliciousActivitiesDoNotMatch || false,
    subsidiaryLabelIds: filters.subsidiaryLabelIds,
    subsidiaryIds: filters.subsidiaryIds || [],
    subsidiaryMinScore: filters.subsidiaryMinScore,
    subsidiaryMaxScore: filters.subsidiaryMaxScore,
    subsidiaryCountries: filters.subsidiaryCountries,
    vendorTiers: filters.vendorTiers || [],
    vendorAssessmentClassifications:
      filters.vendorAssessmentClassifications || [],
    vendorAssessmentAuthors: filters.vendorAssessmentAuthors || [],
    vendorAssessmentAuthorDoNotMatch:
      filters.vendorAssessmentAuthorDoNotMatch || false,
    vendorReassessmentStartDate: filters.vendorReassessmentStartDate || "",
    vendorReassessmentEndDate: filters.vendorReassessmentEndDate || "",
    vendorReassessmentDateBefore: filters.vendorReassessmentDateBefore || false,
    vendorReassessmentDateBetween:
      filters.vendorReassessmentDateBetween || false,
    portfolioIds: filters.portfolioIds || [],
    selectedSurveyStatuses: filters.selectedSurveyStatuses ?? [],
    selectedAttributes: filters.selectedAttributes ?? {},
    assessedVendorsOnly: filters.assessedVendorsOnly || false,
    selectedSurveyAttributes: filters.selectedSurveyAttributes ?? [],
    vendorEvidenceTypes: filters.vendorEvidenceTypes ?? [],
    vendorSurveyTypes: filters.vendorSurveyTypes ?? [],
    orgNames: filters.orgNames ?? [],
    excludedServiceLevels: filters.excludedServiceLevels ?? undefined,
    includedServiceStatuses: filters.includedServiceStatuses ?? [],
    analystUsers: filters.analystUsers ?? [],
    detectedProductCategory: filters.detectedProductCategory ?? [],
    detectedProductVendorMonitored:
      filters.detectedProductVendorMonitored ?? false,
    detectedProductVendorUnmonitored:
      filters.detectedProductVendorUnmonitored ?? false,
    fourthPartyProductUUIDs: filters.fourthPartyProductUUIDs ?? [],
    cloudConnectionProviderTypes: filters.cloudConnectionProviderTypes ?? [],
    cloudConnectionUUIDs: filters.cloudConnectionUUIDs ?? [],
    vendorDateAddedStartDate: filters.vendorDateAddedStartDate || "",
    vendorDateAddedEndDate: filters.vendorDateAddedEndDate || "",
    vendorDateAddedDateBefore: filters.vendorDateAddedDateBefore || false,
    vendorDateAddedDateBetween: filters.vendorDateAddedDateBetween || false,
    userRiskUserUUIDs: filters.userRiskUserUUIDs ?? [],
    userRiskExcludeUsers: filters.userRiskExcludeUsers ?? false,
    userRiskAppNames: filters.userRiskAppNames ?? [],
    userRiskExcludeApps: filters.userRiskExcludeApps ?? false,
    userRiskTeams: filters.userRiskTeams ?? [],
    userRiskExcludeTeams: filters.userRiskExcludeTeams ?? false,
    userRiskRoles: filters.userRiskRoles ?? [],
    userRiskExcludeRoles: filters.userRiskExcludeRoles ?? false,
    userRiskAppStatuses: filters.userRiskAppStatuses ?? [],
    userRiskExcludeStatuses: filters.userRiskExcludeStatuses ?? false,
    userRiskMinFirstDetected: filters.userRiskMinFirstDetected ?? undefined,
    userRiskMaxFirstDetected: filters.userRiskMaxFirstDetected ?? undefined,
    userRiskFirstDetectedBefore: filters.userRiskFirstDetectedBefore ?? false,
    userRiskFirstDetectedBetween: filters.userRiskFirstDetectedBetween ?? false,
    userRiskMinRiskLevel:
      filters.userRiskMinRiskLevel ?? ScopeRiskLevel.LowScopeRiskLevel,
    userRiskMaxRiskLevel:
      filters.userRiskMaxRiskLevel ?? ScopeRiskLevel.HighScopeRiskLevel,
    userRiskScopeMinRiskLevel:
      filters.userRiskScopeMinRiskLevel ?? ScopeRiskLevel.LowScopeRiskLevel,
    userRiskScopeMaxRiskLevel:
      filters.userRiskScopeMaxRiskLevel ?? ScopeRiskLevel.HighScopeRiskLevel,
    userRiskScopeReadOnly: filters.userRiskScopeReadOnly ?? true,
    userRiskScopeReadWrite: filters.userRiskScopeReadWrite ?? true,
    userRiskScopeCategories: filters.userRiskScopeCategories ?? [],
    userRiskExcludeScopeCategories:
      filters.userRiskExcludeScopeCategories ?? false,
    userRiskUserMinScore: filters.userRiskUserMinScore ?? 0,
    userRiskUserMaxScore: filters.userRiskUserMaxScore ?? 0,
    userRiskAppMinScore: filters.userRiskAppMinScore ?? 0,
    userRiskAppMaxScore: filters.userRiskAppMaxScore ?? 0,
  });

  const setFiltersState = (newFilters: Partial<Filters>) =>
    _setFiltersStateActual((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));

  const isSupported = (filterType: FilterTypes) =>
    supportedFilters.includes(filterType);

  const updateFilter = () => {
    const newFilters = { ...filtersState };

    if (vendorId) {
      dispatch(
        setVendorDataFiltersAndRefreshData(vendorId, isSubsidiary, newFilters)
      );
    } else if (!isUserRisk) {
      // Check if we should re-fetch the portfolio risk profile
      if (
        !isEqual(
          getFetchableVendorPortfolioRiskProfileFilters(filters),
          getFetchableVendorPortfolioRiskProfileFilters(newFilters)
        )
      ) {
        dispatch(setVendorPortfolioRiskProfile(false, undefined, null));
      }

      dispatch(setCustomerDataFiltersAndRefreshData(newFilters));
    }

    if (onFilterChanged) {
      onFilterChanged();
    }

    closePanel();
  };

  return (
    <div className="filter-panel">
      <ScrollableDiv newStyles leaveSpaceAtBottom>
        {isSupported(FilterTypes.SCORE) && (
          <ScoreFilter
            minScore={filtersState.minScore}
            maxScore={filtersState.maxScore}
            assuranceType={assuranceType}
            onChange={({ minScore, maxScore }) =>
              setFiltersState({ minScore, maxScore })
            }
            title={`${vendorWords.singularTitleCase} score`}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.DOMAIN_PORTFOLIO) && (
          <PortfolioFilter
            portfolioType={PortfolioType.Domain}
            selectedPortfolioIds={filtersState.domainPortfolioIds}
            onChange={(domainPortfolioIds) =>
              setFiltersState({ domainPortfolioIds })
            }
            title={"Portfolio"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.WEBSITE_LABEL) && (
          <LabelsFilterContainer
            selectedOptions={filtersState.websiteLabelIds}
            onChange={({ selectedOptions }) =>
              setFiltersState({ websiteLabelIds: selectedOptions })
            }
            supportedClassifications={[LabelClassification.WebsiteLabel]}
            title={"Label"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.WEBSITE_LABEL_ADVANCED) && (
          <LabelsFilterContainer
            selectedOptions={filtersState.websiteLabelIds}
            matchAll={filtersState.websiteLabelIdsMatchAll}
            doNotMatch={filtersState.websiteLabelIdsDoNotMatch}
            matchAllSupported
            onChange={({
              selectedOptions,
              includeUnlabeled,
              matchAll,
              doNotMatch,
            }) => {
              const newState: Partial<Filters> = {};
              if (typeof selectedOptions !== "undefined") {
                newState.websiteLabelIds = selectedOptions;
              }
              if (typeof includeUnlabeled !== "undefined") {
                newState.websiteIncludeUnlabeled = includeUnlabeled;
              }
              if (typeof matchAll !== "undefined") {
                newState.websiteLabelIdsMatchAll = matchAll;
              }
              if (typeof doNotMatch !== "undefined") {
                newState.websiteLabelIdsDoNotMatch = doNotMatch;
              }
              setFiltersState(newState);
            }}
            supportedClassifications={[LabelClassification.WebsiteLabel]}
            includeUnlabeled={filtersState.websiteIncludeUnlabeled}
            unlabeledSupported
            title={"Label"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.TYPOSQUAT_TYPES) && (
          <TyposquatTypesFilter
            selectedOptions={filtersState.typosquatTypeIds}
            onChange={({ selectedOptions, doNotMatch }) => {
              const newState: Partial<Filters> = {};
              if (typeof selectedOptions !== "undefined") {
                newState.typosquatTypeIds = selectedOptions;
              }
              if (typeof doNotMatch !== "undefined") {
                newState.typosquatTypeIdsDoNotMatch = doNotMatch;
              }
              setFiltersState(newState);
            }}
            doNotMatch={filtersState.typosquatTypeIdsDoNotMatch}
            title={"Permutation type"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.TYPOSQUAT_REASONS) && (
          <TyposquatReasonsFilter
            selectedOptions={filtersState.typosquatReasonIds}
            onChange={({ selectedOptions, doNotMatch }) => {
              const newState: Partial<Filters> = {};
              if (typeof selectedOptions !== "undefined") {
                newState.typosquatReasonIds = selectedOptions;
              }
              if (typeof doNotMatch !== "undefined") {
                newState.typosquatReasonIdsDoNotMatch = doNotMatch;
              }
              setFiltersState(newState);
            }}
            doNotMatch={filtersState.typosquatReasonIdsDoNotMatch}
            title={"Ignored reason"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.TYPOSQUAT_MALICIOUS_ACTIVITIES) && (
          <TyposquatMaliciousActivitiesFilter
            selectedOptions={filtersState.typosquatMaliciousActivities}
            onChange={({ selectedOptions, doNotMatch }) => {
              const newState: Partial<Filters> = {};
              if (typeof selectedOptions !== "undefined") {
                newState.typosquatMaliciousActivities = selectedOptions;
              }
              if (typeof doNotMatch !== "undefined") {
                newState.typosquatMaliciousActivitiesDoNotMatch = doNotMatch;
              }
              setFiltersState(newState);
            }}
            doNotMatch={filtersState.typosquatMaliciousActivitiesDoNotMatch}
            title={"Malicious Activities"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR_PORTFOLIO) && (
          <PortfolioFilter
            portfolioType={PortfolioType.Vendor}
            selectedPortfolioIds={filtersState.portfolioIds}
            disabledPortfolioIds={disabledVendorPortfolioIds}
            onChange={(portfolioIds) => setFiltersState({ portfolioIds })}
            title={"Portfolio"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR_TIER) && (
          <VendorTierFilter
            assuranceType={assuranceType}
            selectedTiers={filtersState.vendorTiers}
            onChange={(selectedTiers) =>
              setFiltersState({
                vendorTiers: selectedTiers,
              })
            }
            title={"Tier"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR_DATE_ADDED) && (
          <DateFilter
            title="Date added"
            startExpanded={startAllExpanded}
            state={{
              startDate: filtersState.vendorDateAddedStartDate,
              endDate: filtersState.vendorDateAddedEndDate,
              before: filtersState.vendorDateAddedDateBefore,
              between: filtersState.vendorDateAddedDateBetween,
            }}
            onChange={(changeObj: IDateFilterState) => {
              setFiltersState({
                vendorDateAddedStartDate: changeObj.startDate,
                vendorDateAddedEndDate: changeObj.endDate,
                vendorDateAddedDateBefore: changeObj.before,
                vendorDateAddedDateBetween: changeObj.between,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.FOURTH_PARTY_PRODUCT) && (
          <FourthPartyProductFilter
            selectedValues={filtersState.fourthPartyProductUUIDs}
            onChange={(selected) =>
              setFiltersState({
                fourthPartyProductUUIDs: selected,
              })
            }
          />
        )}
        {isSupported(FilterTypes.VENDOR_ASSESSMENT_STATUS) && (
          <VendorAssessmentClassificationFilter
            assuranceType={assuranceType}
            selectedAssessmentClassifications={
              filtersState.vendorAssessmentClassifications
            }
            onChange={(selected) =>
              setFiltersState({
                vendorAssessmentClassifications: selected,
              })
            }
            title={"Assessment status"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR_ASSESSMENT_AUTHOR) && (
          <VendorAssessmentAuthorFilter
            dispatch={dispatch}
            selectedValues={filtersState.vendorAssessmentAuthors}
            doNotMatch={filtersState.vendorAssessmentAuthorDoNotMatch}
            onChange={(changeObj: AuthorFilterChangeObj) =>
              setFiltersState({
                vendorAssessmentAuthors: changeObj.selectedValues,
                vendorAssessmentAuthorDoNotMatch: changeObj.doNotMatch,
              })
            }
            startExpanded={startAllExpanded}
            vendorWords={vendorWords}
          />
        )}
        {isSupported(FilterTypes.VENDOR_REASSESSMENT_DATE) && (
          <DateFilter
            title="Reassessment date"
            startExpanded={startAllExpanded}
            state={{
              startDate: filtersState.vendorReassessmentStartDate,
              endDate: filtersState.vendorReassessmentEndDate,
              before: filtersState.vendorReassessmentDateBefore,
              between: filtersState.vendorReassessmentDateBetween,
            }}
            onChange={(changeObj: IDateFilterState) => {
              setFiltersState({
                vendorReassessmentStartDate: changeObj.startDate,
                vendorReassessmentEndDate: changeObj.endDate,
                vendorReassessmentDateBefore: changeObj.before,
                vendorReassessmentDateBetween: changeObj.between,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.VENDOR_LABEL) && (
          <LabelsFilterContainer
            selectedOptions={filtersState.vendorLabelIds}
            matchAll={filtersState.vendorLabelIdsMatchAll}
            doNotMatch={filtersState.vendorLabelIdsDoNotMatch}
            matchAllSupported
            onChange={({
              selectedOptions,
              includeUnlabeled,
              matchAll,
              doNotMatch,
            }) => {
              const newState: Partial<Filters> = {};
              if (typeof selectedOptions !== "undefined") {
                newState.vendorLabelIds = selectedOptions;
              }
              if (typeof includeUnlabeled !== "undefined") {
                newState.includeUnlabeled = includeUnlabeled;
              }
              if (typeof matchAll !== "undefined") {
                newState.vendorLabelIdsMatchAll = matchAll;
              }
              if (typeof doNotMatch !== "undefined") {
                newState.vendorLabelIdsDoNotMatch = doNotMatch;
              }

              setFiltersState(newState);
            }}
            supportedClassifications={[
              LabelClassification.SystemLabel,
              LabelClassification.VendorLabel,
            ]}
            includeUnlabeled={filtersState.includeUnlabeled}
            unlabeledSupported
            title={"Label"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.RISK) && (
          <RiskFilterContainer
            selectedOptions={filtersState.riskIds}
            onChange={(riskIds) =>
              setFiltersState({
                riskIds,
              })
            }
            riskCategories={filtersState.riskCategories}
            customerRisks={isCustomer}
            vendorId={vendorId}
            isSubsidiary={isSubsidiary}
            scanningRisksOnly={scanningRisksOnly}
            title={"Finding"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.RISK_CATEGORY) && (
          <RiskCategoryFilter
            selectedOptions={filtersState.riskCategories}
            accountHasSurveysEnabled={accountHasSurveysEnabled}
            accountHasAdditionalEvidenceEnabled={
              accountHasAdditionalEvidenceEnabled
            }
            onChange={(riskCategories) =>
              setFiltersState({
                riskCategories,
              })
            }
            title={"Risk category"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR) && (
          <VendorFilter
            selectedOptions={filtersState.vendorIds}
            onChange={(vendorIds) =>
              setFiltersState({
                vendorIds,
              })
            }
            title={"Vendor"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.DATALEAKS_CLASSIFICATION) && (
          <DataLeaksClassificationFilter
            selected={filtersState.dataLeaksClassifications}
            onChange={(selectedClassifications) =>
              setFiltersState({
                dataLeaksClassifications: selectedClassifications,
              })
            }
            title={"Classification"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.DATALEAKS_SOURCE) && (
          <DataLeaksSourceFilter
            selected={filtersState.dataLeaksSources}
            onChange={(selectedSources) =>
              setFiltersState({ dataLeaksSources: selectedSources })
            }
            availableSources={dataLeaksAvailableSources}
            title={"Source"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.DATALEAKS_KEYWORD) && (
          <DataLeaksKeywordFilter
            selected={filtersState.dataLeaksKeywords}
            onChange={(selectedKeywords) =>
              setFiltersState({ dataLeaksKeywords: selectedKeywords })
            }
            availableKeywords={dataLeaksAvailableKeywords}
            title={"Keyword"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.DATALEAKS_REPORTED_DATE) && (
          <DataLeaksReportedDateFilter
            startDate={filtersState.dataLeaksReportedDate?.startDate}
            endDate={filtersState.dataLeaksReportedDate?.endDate}
            onChange={(selectedReportedDates) =>
              setFiltersState({ dataLeaksReportedDate: selectedReportedDates })
            }
            title={"Created date"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VULNS_VERIFIED_STATUS) && (
          <VulnsVerifiedStatusFilter
            excludeVerified={filtersState.vulnsExcludeVerified}
            excludeUnverified={filtersState.vulnsExcludeUnverified}
            onlyKEV={filtersState.vulnsOnlyKEV}
            onChange={(excludeVerified, excludeUnverified) =>
              setFiltersState({
                vulnsExcludeVerified: excludeVerified,
                vulnsExcludeUnverified: excludeUnverified,
              })
            }
            onChangeKEV={(onlyKEV) =>
              setFiltersState({
                vulnsOnlyKEV: onlyKEV,
              })
            }
            title={"Verified status"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VULNS_CVE_NAME) && (
          <VulnsCVENameFilter
            selectedOptions={filtersState.vulnsCveNames}
            availableOptions={availableVulnsFilterOptions?.vulnsCveNames}
            loading={availableVulnsFilterOptions?.loading}
            onChange={(vulnsCveNames) =>
              setFiltersState({
                vulnsCveNames,
              })
            }
            title={"CVE ID"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VULNS_SOFTWARE) && (
          <VulnsSoftwareFilter
            selectedOptions={filtersState.vulnsSoftware}
            availableOptions={availableVulnsFilterOptions?.vulnsSoftware}
            loading={availableVulnsFilterOptions?.loading}
            onChange={(vulnsSoftware) =>
              setFiltersState({
                vulnsSoftware,
              })
            }
            title={"Software"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VULNS_CVSS_SEVERITY) && (
          <SeverityFilter
            excludedSeverities={filtersState.vulnsExcludedSeverities}
            onChange={(vulnsExcludedSeverities) =>
              setFiltersState({ vulnsExcludedSeverities })
            }
            title={"CVSS severity"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VULNS_EPSS_SEVERITY) && (
          <RangeFilter
            onChange={(vulnsEPSSScores) => setFiltersState({ vulnsEPSSScores })}
            min={0}
            max={100}
            title={"EPSS Score"}
            startExpanded={startAllExpanded}
            lowerVal={filtersState.vulnsEPSSScores.lowerVal}
            upperVal={filtersState.vulnsEPSSScores.upperVal}
          />
        )}
        {isSupported(FilterTypes.SUBSIDIARY) && (
          <SubsidiaryFilter
            selectedOptions={filtersState.subsidiaryIds}
            onChange={(subsidiaryIds) =>
              setFiltersState({
                subsidiaryIds,
              })
            }
            includeRootVendor={includeRootVendorInSubsidiaryFilter}
            showRootVendorAsDefault={showRootVendorAsDefault}
            title={"Organization"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.SUBSIDIARY_LABEL) && (
          <LabelsFilterContainer
            selectedOptions={filtersState.subsidiaryLabelIds}
            onChange={({ selectedOptions }) =>
              setFiltersState({ subsidiaryLabelIds: selectedOptions })
            }
            supportedClassifications={[LabelClassification.VendorLabel]}
            title={"Organization label"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.SUBSIDIARY_SCORE) && (
          <ScoreFilter
            minScore={filtersState.subsidiaryMinScore}
            maxScore={filtersState.subsidiaryMaxScore}
            assuranceType={assuranceType}
            onChange={({ minScore, maxScore }) =>
              setFiltersState({
                subsidiaryMinScore: minScore,
                subsidiaryMaxScore: maxScore,
              })
            }
            isSubsidiaries
            title={"Subsidiary score"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.SUBSIDIARY_COUNTRY) && (
          <IPCountryFilter
            ipCountries={allIPCountries}
            selectedCountries={filtersState.subsidiaryCountries}
            onChange={(selected) =>
              setFiltersState({ subsidiaryCountries: selected })
            }
            title={"IP country"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.SURVEY_STATUS) && (
          <SurveyStatusFilter
            selectedStatuses={filtersState.selectedSurveyStatuses}
            selectedAttributes={filtersState.selectedSurveyAttributes}
            toggleStatus={(s) => {
              const selected = [...filtersState.selectedSurveyStatuses];
              const idx = selected.indexOf(s);
              if (idx < 0) {
                selected.push(s);
              } else {
                selected.splice(idx, 1);
              }
              setFiltersState({ selectedSurveyStatuses: selected });
            }}
            toggleAttribute={(attribute) => {
              const selected = [...filtersState.selectedSurveyAttributes];
              const idx = selected.indexOf(attribute);
              if (idx < 0) {
                selected.push(attribute);
              } else {
                selected.splice(idx, 1);
              }
              setFiltersState({ selectedSurveyAttributes: selected });
            }}
            title={"Questionnaire status"}
            startExpanded={startAllExpanded}
          />
        )}
        {isSupported(FilterTypes.VENDOR_ATTRIBUTES) && (
          <VendorAttributesFilterContainer
            startExpanded={startAllExpanded}
            selectedAttributes={filtersState.selectedAttributes}
            vendorWords={vendorWords}
            onChange={({
              selectedValues,
              attributeDefinitionId,
              doNotMatch,
              matchAll,
              startDate,
              endDate,
              before,
              between,
            }) =>
              setFiltersState({
                selectedAttributes: {
                  ...filtersState.selectedAttributes,
                  [attributeDefinitionId]: {
                    selectedValues,
                    doNotMatch,
                    matchAll,
                    startDate,
                    endDate,
                    before,
                    between,
                  },
                },
              })
            }
          />
        )}
        {isSupported(FilterTypes.VENDOR_EVIDENCE_TYPES) && (
          <VendorEvidenceTypesFilter
            selected={filtersState.vendorEvidenceTypes}
            onChanged={(selected) =>
              setFiltersState({
                vendorEvidenceTypes: selected,
              })
            }
          />
        )}
        {isSupported(FilterTypes.VENDOR_SURVEY_TYPES) && (
          <VendorSurveyTypesFilter
            selected={filtersState.vendorSurveyTypes}
            onChanged={(selected) =>
              setFiltersState({
                vendorSurveyTypes: selected,
              })
            }
          />
        )}
        {isSupported(FilterTypes.APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE) && (
          <CloudProviderFilter
            selected={filtersState.cloudConnectionProviderTypes}
            onChange={(selectedValues) => {
              setFiltersState({ cloudConnectionProviderTypes: selectedValues });
            }}
          />
        )}
        {isSupported(FilterTypes.APPGUARD_CLOUD_CONNECTION_UUID) && (
          <CloudConnectionFilter
            selectedUUIDs={filtersState.cloudConnectionUUIDs}
            onChange={(selectedUUIDs) => {
              setFiltersState({ cloudConnectionUUIDs: selectedUUIDs });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_USER_UUIDS) && (
          <UserRiskUsersFilter
            selectedUUIDs={filtersState.userRiskUserUUIDs}
            excluded={filtersState.userRiskExcludeUsers}
            onChange={(selectedUUIDs, exclude) => {
              setFiltersState({
                userRiskUserUUIDs: selectedUUIDs,
                userRiskExcludeUsers: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APPS) && (
          <UserRiskAppsFilter
            selectedAppNames={filtersState.userRiskAppNames}
            excluded={filtersState.userRiskExcludeApps}
            onChange={(selectedAppNames, exclude) => {
              setFiltersState({
                userRiskAppNames: selectedAppNames,
                userRiskExcludeApps: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_TEAMS) && (
          <UserRiskTeamsFilter
            selectedTeams={filtersState.userRiskTeams}
            excluded={filtersState.userRiskExcludeTeams}
            onChange={(selectedTeams, exclude) => {
              setFiltersState({
                userRiskTeams: selectedTeams,
                userRiskExcludeTeams: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_ROLES) && (
          <UserRiskRolesFilter
            selectedRoles={filtersState.userRiskRoles}
            excluded={filtersState.userRiskExcludeRoles}
            onChange={(selectedRoles, exclude) => {
              setFiltersState({
                userRiskRoles: selectedRoles,
                userRiskExcludeRoles: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_STATUS) && (
          <UserRisksAppStatusesFilter
            selectedAppStatuses={filtersState.userRiskAppStatuses}
            excluded={filtersState.userRiskExcludeStatuses}
            onChange={(selectedStatuses, exclude) => {
              setFiltersState({
                userRiskAppStatuses: selectedStatuses,
                userRiskExcludeStatuses: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_APPROVED_TEAMS) && (
          <UserRiskTeamsFilter
            title={"Approved teams"}
            selectedTeams={filtersState.userRiskTeams}
            excluded={filtersState.userRiskExcludeTeams}
            onChange={(selectedTeams, exclude) => {
              setFiltersState({
                userRiskTeams: selectedTeams,
                userRiskExcludeTeams: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_APPROVED_ROLES) && (
          <UserRiskRolesFilter
            title={"Approved roles"}
            selectedRoles={filtersState.userRiskRoles}
            excluded={filtersState.userRiskExcludeRoles}
            onChange={(selectedRoles, exclude) => {
              setFiltersState({
                userRiskRoles: selectedRoles,
                userRiskExcludeRoles: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_FIRST_DETECTED) && (
          <DateFilter
            title={"First detected"}
            startExpanded={true}
            state={{
              startDate: filtersState.userRiskMinFirstDetected,
              endDate: filtersState.userRiskMaxFirstDetected,
              before: filtersState.userRiskFirstDetectedBefore,
              between: filtersState.userRiskFirstDetectedBetween,
            }}
            onChange={({ startDate, endDate, before, between }) => {
              setFiltersState({
                userRiskMinFirstDetected: startDate,
                userRiskMaxFirstDetected: endDate,
                userRiskFirstDetectedBefore: before,
                userRiskFirstDetectedBetween: between,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_RISK_LEVEL) && (
          <UserRiskExposureLevelFilter
            minRiskLevel={filtersState.userRiskMinRiskLevel}
            maxRiskLevel={filtersState.userRiskMaxRiskLevel}
            onChange={(newMinRiskLevel, newMaxRiskLevel) => {
              setFiltersState({
                userRiskMinRiskLevel: newMinRiskLevel,
                userRiskMaxRiskLevel: newMaxRiskLevel,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_SCOPE_CATEGORIES) && (
          <UserRiskScopeCategoryFilter
            selectedCategories={filtersState.userRiskScopeCategories}
            excluded={filtersState.userRiskExcludeScopeCategories}
            onChange={(selectedCategories, exclude) => {
              setFiltersState({
                userRiskScopeCategories: selectedCategories,
                userRiskExcludeScopeCategories: exclude,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_SCOPE_RISK_LEVEL) && (
          <UserRiskExposureLevelFilter
            minRiskLevel={filtersState.userRiskScopeMinRiskLevel}
            maxRiskLevel={filtersState.userRiskScopeMaxRiskLevel}
            onChange={(newMinRiskLevel, newMaxRiskLevel) => {
              setFiltersState({
                userRiskScopeMinRiskLevel: newMinRiskLevel,
                userRiskScopeMaxRiskLevel: newMaxRiskLevel,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_SCOPE_READWRITE) && (
          <UserRiskScopeReadWriteFilter
            readOnly={filtersState.userRiskScopeReadOnly}
            readWrite={filtersState.userRiskScopeReadWrite}
            onChange={(newReadOnly, newReadWrite) => {
              setFiltersState({
                userRiskScopeReadOnly: newReadOnly,
                userRiskScopeReadWrite: newReadWrite,
              });
            }}
          />
        )}

        {isSupported(FilterTypes.USERRISK_USER_SCORES) && (
          <UserRiskScoreFilter
            title={"Security rating"}
            noun={UserRiskScoreFilterNoun.User}
            minScore={filtersState.userRiskUserMinScore}
            maxScore={filtersState.userRiskUserMaxScore}
            onChange={({ minScore, maxScore }) => {
              setFiltersState({
                userRiskUserMinScore: minScore,
                userRiskUserMaxScore: maxScore,
              });
            }}
          />
        )}
        {isSupported(FilterTypes.USERRISK_APP_SCORES) && (
          <UserRiskScoreFilter
            title={"Security rating"}
            noun={UserRiskScoreFilterNoun.Application}
            minScore={filtersState.userRiskAppMinScore}
            maxScore={filtersState.userRiskAppMaxScore}
            onChange={({ minScore, maxScore }) => {
              setFiltersState({
                userRiskAppMinScore: minScore,
                userRiskAppMaxScore: maxScore,
              });
            }}
          />
        )}
      </ScrollableDiv>
      <div className="slide-panel-section buttons">
        <Button
          onClick={() => setFiltersState(getClearedFilters(supportedFilters))}
          danger
        >
          <Icon name="refresh" />
          Reset
        </Button>
        <Button onClick={updateFilter}>
          <Icon name="checkmark" />
          Apply
        </Button>
      </div>
    </div>
  );
};

const emptyArr: any[] = [];
const emptyObj = {};

export default appConnect<
  IFilterPanelConnectedProps,
  never,
  IFilterPanelOwnProps
>((state, props) => {
  const filters = getFiltersFromState(
    state,
    props.vendorId,
    props.isSubsidiary
  );

  const userSystemRoles = state.common.userData.system_roles.reduce(
    (prev: Record<string, true | undefined>, perm) => {
      prev[perm] = true;
      return prev;
    },
    {}
  );

  const userIsManagedVendorAnalyst =
    !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

  const isManagedVendorAnalyst =
    userIsManagedVendorAnalyst &&
    props.isManagementAnalystSession &&
    (props.managedOrgId ?? 0) > 0;

  let orgPerms;
  if (isManagedVendorAnalyst) {
    const managedOrgEntitlements = state.cyberRisk.managedVendorData[
      props.managedOrgId as number
    ]
      ? state.cyberRisk.managedVendorData[props.managedOrgId as number]
          .entitlements
      : [];
    orgPerms = managedOrgEntitlements
      ? managedOrgEntitlements.reduce(
          (prev: Record<string, true | undefined>, perm: string) => {
            prev[perm] = true;
            return prev;
          },
          {}
        )
      : emptyArr;
  } else {
    orgPerms = state.common.userData.orgPermissions
      ? state.common.userData.orgPermissions.reduce(
          (prev: Record<string, true | undefined>, perm) => {
            prev[perm] = true;
            return prev;
          },
          {}
        )
      : emptyArr;
  }

  return {
    filters,
    assuranceType: state.common.userData.assuranceType,
    dataLeaksAvailableSources:
      (state.cyberRisk as any).dataLeakScanResults?.lookupData?.services ??
      emptyObj,
    dataLeaksAvailableKeywords:
      (state.cyberRisk as any).dataLeakScanResults?.lookupData?.keywords ??
      emptyArr,
    accountHasSurveysEnabled: orgPerms[OrgAccessSurveys] === true,
    accountHasAdditionalEvidenceEnabled:
      orgPerms[OrgAccessAdditionalEvidence] === true,
  };
})(FilterPanel);
