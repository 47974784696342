import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import {
  ThreatMonitoringModule,
  ThreatMonitoringSourceType,
  ThreatMonitoringVendorNetworkType,
} from "../api/types";
import DarkWebSourceIcon from "../images/darkweb_icon.svg";
import TelegramSourceIcon from "../images/telegram_icon.svg";
import DiscordSourceIcon from "../images/discord_icon.svg";
import RansomwareBlogsSourceIcon from "../images/ransomware_blogs_icon.svg";
import MarketplaceSourceIcon from "../images/marketplace_icon.svg";
import ForumsSourceIcon from "../images/forums_icon.svg";
import StealerLogsSourceIcon from "../images/stealerlogs_icon.svg";
import "./ThreatSource.scss";
import {
  moduleToString,
  sourceTypeToString,
  vendorNetworkTypeIsDarkWeb,
} from "../funcs/domain";

interface ModuleDefn {
  icon: any;
  popupWidth: number;
  popupText?: string;
  moduleText?: string;
}

interface ThreatSourceProps {
  module: ThreatMonitoringModule;
  source: ThreatMonitoringSourceType;
  network?: ThreatMonitoringVendorNetworkType;
}

export default function ThreatSource({
  module: moduleType,
  source,
  network,
}: ThreatSourceProps) {
  let module: ModuleDefn;
  if (vendorNetworkTypeIsDarkWeb(network)) {
    module = {
      icon: DarkWebSourceIcon,
      popupWidth: 90,
      popupText: moduleToString(moduleType),
      moduleText: moduleToString(moduleType),
    };
  } else {
    switch (source) {
      case ThreatMonitoringSourceType.Telegram:
        module = {
          icon: TelegramSourceIcon,
          popupWidth: 90,
          popupText: "Telegram",
          moduleText: "Messaging platforms",
        };
        break;
      case ThreatMonitoringSourceType.Discord:
        module = {
          icon: DiscordSourceIcon,
          popupWidth: 90,
          popupText: "Discord",
          moduleText: "Messaging platforms",
        };
        break;
      case ThreatMonitoringSourceType.Forum:
        module = {
          icon: ForumsSourceIcon,
          popupWidth: 70,
          popupText: "Forum",
        };
        break;
      case ThreatMonitoringSourceType.Marketplace:
        module = {
          icon: MarketplaceSourceIcon,
          popupWidth: 110,
          popupText: "Illicit marketplace",
        };
        break;
      case ThreatMonitoringSourceType.StealerLogs:
        module = {
          icon: StealerLogsSourceIcon,
          popupWidth: 90,
          popupText: "Stealer log",
        };
        break;
      case ThreatMonitoringSourceType.RansomwareBlog:
        module = {
          icon: RansomwareBlogsSourceIcon,
          popupWidth: 110,
          popupText: "Ransomware blog",
        };
        break;
      default:
        module = {
          icon: ForumsSourceIcon,
          popupWidth: 0,
        };
    }
  }

  return (
    <div className="threat-source">
      <SidePopupV2
        text={module?.popupText}
        position="top"
        width={module?.popupWidth}
      >
        <img src={module?.icon} alt={module?.popupText} />
      </SidePopupV2>
      <div className="sources">
        <span className="source">{sourceTypeToString(source)}</span>
        <span className="module">{module?.moduleText}</span>
      </div>
    </div>
  );
}
