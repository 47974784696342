import {
  AvailableExportConfig,
  AvailableOption,
  AvailableSection,
  ConfiguredExportConfig,
  getOptionByID,
  isOptionHidden,
} from "../../../_common/types/exportConfig";
import {
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ColorCheckbox from "../ColorCheckbox";

import "../../style/components/reporting/ExportConfiguration.scss";
import classnames from "classnames";
import TextField from "../../../_common/components/TextField";
import { configuredExportConfigReducerAction } from "./exportConfigReducer";
import ExportConfigurationSectionsList from "./ExportConfigurationSectionsList";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { useMergeTagsModal } from "./MergeTagsModal";
import { SelectV2 } from "../../../_common/components/SelectV2";
import { ValueType } from "react-select";

interface IExportConfigurationOptionProps {
  availableOption: AvailableOption;
  configuredExportConfig: ConfiguredExportConfig;
  configuredExportConfigDispatch: Dispatch<configuredExportConfigReducerAction>;
  openMergeTagsModal?: () => void;
}

const ExportConfigurationOption: FC<IExportConfigurationOptionProps> = ({
  availableOption,
  configuredExportConfig,
  configuredExportConfigDispatch,
  openMergeTagsModal,
}) => {
  const configuredOption = getOptionByID(
    availableOption.id,
    configuredExportConfig
  );

  const hidden = isOptionHidden(availableOption, configuredExportConfig);

  const disabled = !!(
    availableOption.checkboxOpts?.disabled ||
    availableOption.radioOpts?.disabled
  );

  const onClickCheckboxOrRadio = useCallback(() => {
    const newVal = availableOption.radioOpts
      ? true
      : !configuredOption?.valueBool;
    if (newVal === configuredOption?.valueBool) {
      return;
    }

    configuredExportConfigDispatch({
      type: "SET_OPTION",
      id: availableOption.id,
      isRadio: !!availableOption.radioOpts,
      valueBool: newVal,
      valueText: "",
    });
  }, [configuredExportConfigDispatch, availableOption, configuredOption]);

  const onEditTextbox = useCallback(
    (val: string) => {
      configuredExportConfigDispatch({
        type: "SET_OPTION",
        id: availableOption.id,
        isRadio: false,
        valueBool: false,
        valueText: val,
      });
    },
    [availableOption.id, configuredExportConfigDispatch]
  );

  const onSelectOption = useCallback(
    (selectedOption: ValueType<any, any>) => {
      configuredExportConfigDispatch({
        type: "SET_OPTION",
        id: availableOption.id,
        isRadio: false,
        valueBool: false,
        valueText: selectedOption?.value.toString() ?? "",
      });
    },
    [availableOption.id, configuredExportConfigDispatch]
  );

  const getSelectListItems = () => {
    const items = availableOption.selectListOpts?.items ?? [];
    return items.map((s) => {
      return {
        value: s.itemID.toString(),
        label: s.itemText,
        isDefault: s.isDefault,
      };
    });
  };

  const getSelectedItem = () => {
    const items = getSelectListItems();
    if (items.length == 0) {
      return undefined;
    }

    const selectedItem = items.find((i) => {
      return i.value === configuredOption?.valueText;
    });

    if (selectedItem) {
      return selectedItem;
    } else {
      // Take the default item if it exists, otherwise the first item is selected by default.
      let defaultItem = items.find((i) => {
        return i.isDefault;
      });
      if (!defaultItem) {
        defaultItem = items[0];
      }
      // make sure we set our default item to selected if we pick it by default
      if (configuredOption) {
        onSelectOption(defaultItem);
      }

      return defaultItem;
    }
  };

  let children;
  if (availableOption.optionSectionOpts) {
    children = availableOption.optionSectionOpts.children;
  } else if (availableOption.checkboxOpts && configuredOption?.valueBool) {
    children = availableOption.checkboxOpts.children;
  }

  let selectedItem;
  if (availableOption.selectListOpts) {
    selectedItem = getSelectedItem();
  }

  if (hidden) {
    return null;
  }

  return (
    <>
      <div className={`export-config-option`} key={availableOption.id}>
        {((availableOption.optionSectionOpts &&
          !availableOption.optionSectionOpts.hideOptionTitle) ||
          (availableOption.textOpts && availableOption.title)) && (
          <div className="title-desc">
            {availableOption.title}
            {availableOption.textOpts?.required && (
              <SidePopupV2 inline text="This is a required field.">
                *
              </SidePopupV2>
            )}
            {availableOption.description && (
              <div className="desc">{availableOption.description}</div>
            )}
          </div>
        )}
        {availableOption.checkboxOpts || availableOption.radioOpts ? (
          <div className="checkbox-radio-opt-container">
            <SidePopupV2
              position="left"
              text={
                disabled
                  ? availableOption.checkboxOpts?.disabledReason ??
                    availableOption.radioOpts?.disabledReason
                  : undefined
              }
            >
              <div
                className={classnames("checkbox-radio-opt", {
                  clickable: !disabled,
                  disabled,
                })}
                onClick={disabled ? undefined : onClickCheckboxOrRadio}
              >
                <ColorCheckbox
                  color="blue"
                  radio={!!availableOption.radioOpts}
                  checked={configuredOption?.valueBool ?? false}
                  disabled={disabled}
                />
                <div className="checkbox-label">
                  <div className="title">{availableOption.title}</div>
                  {availableOption.description && (
                    <div className="desc">{availableOption.description}</div>
                  )}
                </div>
              </div>
            </SidePopupV2>
            {!availableOption.optionSectionOpts &&
              children &&
              children.length > 0 && (
                <div className="export-config-option-children">
                  {children.map((opt) => (
                    <ExportConfigurationOption
                      key={opt.id}
                      availableOption={opt}
                      configuredExportConfig={configuredExportConfig}
                      configuredExportConfigDispatch={
                        configuredExportConfigDispatch
                      }
                      openMergeTagsModal={openMergeTagsModal}
                    />
                  ))}
                </div>
              )}
          </div>
        ) : availableOption.textOpts ? (
          <div className="text-field-container">
            <TextField
              placeholder={availableOption.textOpts.placeholder}
              multiLine={availableOption.textOpts.multiline}
              maxLength={availableOption.textOpts.maxLength}
              value={configuredOption?.valueText ?? ""}
              onChanged={onEditTextbox}
            />
            {openMergeTagsModal &&
              availableOption.textOpts.mergeTagsSupported && (
                <div className="merge-tags-link" onClick={openMergeTagsModal}>
                  Supported merge tags
                </div>
              )}
          </div>
        ) : availableOption.selectListOpts ? (
          <div className={"select-list-container"}>
            <SelectV2
              className={"select-list"}
              options={getSelectListItems()}
              onChange={onSelectOption}
              value={selectedItem}
              usePortal
            />
          </div>
        ) : (
          availableOption.optionSectionOpts &&
          children &&
          children.length > 0 && (
            <div className="export-config-option-children">
              {children.map((opt) => (
                <ExportConfigurationOption
                  key={opt.id}
                  availableOption={opt}
                  configuredExportConfig={configuredExportConfig}
                  configuredExportConfigDispatch={
                    configuredExportConfigDispatch
                  }
                  openMergeTagsModal={openMergeTagsModal}
                />
              ))}
            </div>
          )
        )}
      </div>
    </>
  );
};

// Importable style container to ensure CSS is imported
export const ExportConfigurationOptionsStyles: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className = "", children }) => (
  <div className={"export-config-options " + className}>{children}</div>
);

interface IExportConfigurationOptionsProps {
  availableOptions: AvailableOption[];
  configuredExportConfig: ConfiguredExportConfig;
  configuredExportConfigDispatch: Dispatch<configuredExportConfigReducerAction>;
  openMergeTagsModal?: () => void;
}

export const ExportConfigurationOptions: FC<
  IExportConfigurationOptionsProps
> = ({
  availableOptions,
  configuredExportConfig,
  configuredExportConfigDispatch,
  openMergeTagsModal,
}) => {
  return (
    <ExportConfigurationOptionsStyles>
      {availableOptions.map((opt) => (
        <ExportConfigurationOption
          key={opt.id}
          availableOption={opt}
          configuredExportConfig={configuredExportConfig}
          configuredExportConfigDispatch={configuredExportConfigDispatch}
          openMergeTagsModal={openMergeTagsModal}
        />
      ))}
    </ExportConfigurationOptionsStyles>
  );
};

interface IExportSectionsConfigurationProps {
  availableExportConfig: AvailableExportConfig;
  configuredExportConfig: ConfiguredExportConfig;
  configuredExportConfigDispatch: Dispatch<configuredExportConfigReducerAction>;
}

export const ExportSectionsConfiguration: FC<
  IExportSectionsConfigurationProps
> = ({
  availableExportConfig,
  configuredExportConfig,
  configuredExportConfigDispatch,
}) => {
  const [selectedSectionID, setSelectedSectionID] = useState(() => {
    // Default to the first enabled section.
    for (let i = 0; i < configuredExportConfig.sections.length; i++) {
      if (configuredExportConfig.sections[i].value) {
        return configuredExportConfig.sections[i].id;
      }
    }

    return undefined;
  });

  useEffect(() => {
    // If the selected section is no longer in the available config, reset it to the first
    // section or undefined.
    if (
      !availableExportConfig.sections ||
      availableExportConfig.sections.length === 0
    ) {
      if (selectedSectionID) {
        setSelectedSectionID(undefined);
      }
      return;
    }

    if (
      selectedSectionID &&
      availableExportConfig.sections?.find((s) => s.id === selectedSectionID)
    ) {
      // Leave as is
      return;
    }

    // Otherwise set to first section.
    setSelectedSectionID(availableExportConfig.sections[0].id);
  }, [availableExportConfig, selectedSectionID]);

  const availableSectionsMap = useMemo(
    () =>
      (availableExportConfig.sections ?? []).reduce(
        (prev: Record<string, AvailableSection | undefined>, next) => {
          prev[next.id] = next;
          return prev;
        },
        {}
      ),
    [availableExportConfig]
  );

  const selectedSection = selectedSectionID
    ? availableSectionsMap[selectedSectionID]
    : undefined;

  const [openMergeTagsModal, mergeTagsModal] = useMergeTagsModal(
    availableExportConfig.supportedMergeTags
  );

  const imageComponent = selectedSection?.imageURL && (
    <>
      <img className="content-sample-img" src={selectedSection.imageURL} />
      <div className="content-sample-img-caption">
        {selectedSection.title} example (showing sample data)
      </div>
    </>
  );

  const optionsComponent = selectedSection?.options &&
    selectedSection.options.length > 0 && (
      <ExportConfigurationOptions
        availableOptions={selectedSection.options}
        configuredExportConfig={configuredExportConfig}
        configuredExportConfigDispatch={configuredExportConfigDispatch}
        openMergeTagsModal={openMergeTagsModal}
      />
    );

  return (
    <div className="export-sections-config">
      <div className="sections-pane">
        <div className="pane-head">Report sections</div>
        <ExportConfigurationSectionsList
          availableSectionsMap={availableSectionsMap}
          configuredExportConfig={configuredExportConfig}
          configuredExportConfigDispatch={configuredExportConfigDispatch}
          selectedSectionID={selectedSectionID}
          setSelectedSectionID={setSelectedSectionID}
        />
      </div>
      <div className="content-pane">
        {selectedSection && (
          <>
            <div className="pane-head">Content</div>
            <div
              className={classnames("content-pane-content", {
                disabled: !configuredExportConfig.sections.find(
                  (s) => s.id === selectedSection.id
                )?.value,
              })}
            >
              <div className="content-title">{selectedSection.title}</div>
              {selectedSection.description && (
                <div className="content-description">
                  {selectedSection.description}
                </div>
              )}
              {selectedSection.positionImageBelowOptions ? (
                <>
                  {optionsComponent}
                  {imageComponent}
                </>
              ) : (
                <>
                  {imageComponent}
                  {optionsComponent}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {mergeTagsModal}
    </div>
  );
};
