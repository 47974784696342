import { FC } from "react";

import UserBaseAPI from "../../api/userbase.api";

import UserAppsTable from "./UserAppsTable";
import { useBack, useDefaultHistory } from "../../../_common/types/router";
import { appUrl, userPermissionsUrl } from "../../UserBaseAppRouter";

import { UserApp } from "../../api/types";
import "./UserPermissionsTable.scss";

interface UserPermissionsTableProps {
  userUUID: string;
  appNames: string[];
}

const UserPermissionsTable: FC<UserPermissionsTableProps> = ({
  userUUID,
  appNames,
}) => {
  const history = useDefaultHistory();
  const back = useBack();

  // Get the passed in apps for this user
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserAppsV1Query({
    userUUID,
    appNames,
  });

  const appsToShow = data?.apps ?? [];

  const onViewApplication = (app: UserApp) => {
    history.push(appUrl(app.name), {
      backContext: {
        backTo: userPermissionsUrl(userUUID),
        backToText: back.backText,
      },
    });
  };

  return (
    <UserAppsTable
      isLoading={isLoading}
      appsToShow={appsToShow}
      onClick={onViewApplication}
      className="expanded-app-list-style"
    />
  );
};

export default UserPermissionsTable;
