import { ExportSection } from "../../../_common/types/exportReport";
import {
  AvailableExportConfig,
  CannedExportConfig,
  CustomCannedExportConfig,
  DefaultCannedExportConfig,
} from "../../../_common/types/exportConfig";
import { DefaultRouteProps } from "../../../_common/types/router";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useConfiguredExportConfigReducer } from "../../components/reporting/exportConfigReducer";
import { fetchExportSections } from "../../reducers/export.actions";
import { crumb } from "../../../_common/components/Breadcrumbs";
import PageHeader from "../../../_common/components/PageHeader";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import ReportCard from "../../../_common/components/ReportCard";
import {
  ExportConfigurationOptions,
  ExportSectionsConfiguration,
} from "../../components/reporting/ExportConfiguration";

import { getExportSections } from "../../reducers/cyberRiskSelectors";
import ActionBar from "../../../_common/components/ActionBar";
import Button from "../../../_common/components/core/Button";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import {
  createCannedExportConfig,
  fetchCannedExportConfigs,
  updateCannedExportConfig,
} from "../../reducers/cannedExports.actions";

import "../../style/views/reporting/ReportTemplateCreate.scss";
import StepsWithSections, {
  IStep,
  Steps,
} from "../../../_common/components/StepsWithSections";
import ReportTypeBadge, {
  BadgeImageRefs,
} from "../../components/reporting/ReportTypeBadge";
import { sortBy as _sortBy } from "lodash";
import { AssuranceType } from "../../../_common/types/organisations";
import TextField from "../../../_common/components/TextField";
import classnames from "classnames";
import { useConfirmationModalV2 } from "../../../_common/components/modals/ConfirmationModalV2";
import { trackEvent } from "../../../_common/tracking";
import { appConnect } from "../../../_common/types/reduxHooks";

export const getNextUntitledTemplateName = (
  customCannedExportConfigs: CustomCannedExportConfig[]
) => {
  // Run through our existing custom templates. If any "Untitled" ones exist already, make
  // sure we use the next number in the sequence.
  let lowestUntitledNum = 0;

  for (let i = 0; i < customCannedExportConfigs.length; i++) {
    const regexResult = /Untitled Template \(([0-9]+)\)/.exec(
      customCannedExportConfigs[i].name
    );
    if (regexResult && regexResult.length === 2) {
      const nextNum = parseInt(regexResult[1]);
      if (nextNum > lowestUntitledNum) {
        lowestUntitledNum = nextNum;
      }
    }
  }

  return `Untitled Template (${lowestUntitledNum + 1})`;
};

export const getDefaultTemplateDescription = (baseTemplateName: string) =>
  `This is a custom template based on "${baseTemplateName}".`;

export interface IReportTemplateCreateLocationState {
  baseTemplate?: CannedExportConfig;
  editingUUID?: string;
}

type IReportTemplateCreatePropsProviderProps = DefaultRouteProps<
  never,
  IReportTemplateCreateLocationState
>;

interface IReportTemplateCreateOwnProps
  extends IReportTemplateCreatePropsProviderProps {
  preSelectedBaseTemplate: boolean;
  selectedBaseTemplate?: CannedExportConfig;
  editingUUID?: string;
  setSelectedBaseTemplate: (conf: CannedExportConfig) => void;
}

interface IReportTemplateCreateConnectedProps {
  defaultCannedExportConfigs?: DefaultCannedExportConfig[];
  customCannedExportConfigs?: CustomCannedExportConfig[];
  unfilteredAvailableConfig?: AvailableExportConfig;
  assuranceType: AssuranceType;
}

type IReportTemplateCreateProps = IReportTemplateCreateOwnProps &
  IReportTemplateCreateConnectedProps &
  DefaultThunkDispatchProp;

type stepID = "select_report_type" | "configure_options" | "configure_sections";

const ReportTemplateCreate: FC<IReportTemplateCreateProps> = ({
  dispatch,
  history,
  location,
  defaultCannedExportConfigs,
  customCannedExportConfigs,
  preSelectedBaseTemplate,
  selectedBaseTemplate,
  setSelectedBaseTemplate,
  editingUUID,
  unfilteredAvailableConfig,
  assuranceType,
}) => {
  const [configuredExportConfigState, configuredExportConfigDispatch] =
    useConfiguredExportConfigReducer(
      unfilteredAvailableConfig,
      selectedBaseTemplate?.config
    );

  const [templateName, setTemplateName] = useState("");
  const [templateNameEdited, setTemplateNameEdited] = useState(false);
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateDescriptionEdited, setTemplateDescriptionEdited] =
    useState(false);
  const [saving, setSaving] = useState(false);
  const [currentStepID, setCurrentStepID] = useState<stepID>(() =>
    preSelectedBaseTemplate ? "configure_options" : "select_report_type"
  );
  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();

  useEffect(() => {
    if (selectedBaseTemplate && customCannedExportConfigs) {
      if (editingUUID) {
        // Editing an existing template - keep the name and description that are already set
        setTemplateName(selectedBaseTemplate.name);
        setTemplateDescription(selectedBaseTemplate.description);
      } else {
        setTemplateName(getNextUntitledTemplateName(customCannedExportConfigs));

        // Description should just be based on the base template name
        setTemplateDescription(
          getDefaultTemplateDescription(selectedBaseTemplate.name)
        );
      }

      setTemplateNameEdited(false);
      setTemplateDescriptionEdited(false);
    }
  }, [selectedBaseTemplate, editingUUID, customCannedExportConfigs]);

  useEffect(() => {
    if (!defaultCannedExportConfigs) {
      dispatch(fetchCannedExportConfigs(false));
    }
  }, [dispatch, defaultCannedExportConfigs]);

  useEffect(() => {
    if (!selectedBaseTemplate) {
      return;
    }

    if (!unfilteredAvailableConfig) {
      dispatch(
        fetchExportSections({
          exportType: selectedBaseTemplate.isDefault
            ? selectedBaseTemplate.exportType
            : selectedBaseTemplate.baseDefaultConfig.exportType,
          exportOptions: {},
          applyFilters: false,
        })
      );
    }
  }, [dispatch, unfilteredAvailableConfig, selectedBaseTemplate]);

  useEffect(() => {
    // If we're on the configure_options step but there are no options to configure, skip ahead
    if (
      currentStepID === "configure_options" &&
      unfilteredAvailableConfig &&
      (unfilteredAvailableConfig.globalOptions ?? []).length === 0
    ) {
      setCurrentStepID("configure_sections");
    }
  }, [currentStepID, unfilteredAvailableConfig]);

  const onSaveTemplate = useCallback(async () => {
    setSaving(true);
    try {
      if (editingUUID) {
        await dispatch(
          updateCannedExportConfig({
            uuid: editingUUID,
            name: templateName,
            description: templateDescription,
            config: configuredExportConfigState.configuredExportConfig,
          })
        );
      } else {
        await dispatch(
          createCannedExportConfig({
            baseDefaultID: selectedBaseTemplate?.isDefault
              ? selectedBaseTemplate.defaultID
              : undefined,
            baseUUID:
              selectedBaseTemplate?.isDefault === false
                ? selectedBaseTemplate.uuid
                : undefined,
            name: templateName,
            description: templateDescription,
            config: configuredExportConfigState.configuredExportConfig,
          })
        );
      }

      await dispatch(fetchCannedExportConfigs(true));

      if (editingUUID) {
        dispatch(addDefaultSuccessAlert("Successfully updated template"));
      } else {
        dispatch(addDefaultSuccessAlert("Successfully saved new template"));
        trackEvent("CreateCustomReport");
      }

      history.replace("/reportexports", {
        noRemoveWhispers: true,
      });
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "An error occurred saving your new template"
        )
      );
      setSaving(false);
    }
  }, [
    dispatch,
    history,
    selectedBaseTemplate,
    editingUUID,
    templateName,
    templateDescription,
    configuredExportConfigState.configuredExportConfig,
  ]);

  const onSaveTemplateWithConfirmation = useCallback(
    () =>
      openConfirmationModal({
        title: `Save as "${templateName}"?`,
        description:
          "You haven't edited your new template's name yet. Do you want to save with this name?",
        buttonText: "Save anyway",
        cancelText: "Go back and edit",
        buttonAction: onSaveTemplate,
        primaryAction: true,
      }),
    [openConfirmationModal, templateName, onSaveTemplate]
  );

  const breadcrumbs = useMemo<crumb[]>(
    () => [
      { text: "Reports", to: "/reportexports" },
      { text: "Create template" },
    ],
    []
  );

  const goBack = useCallback(
    () =>
      location.state?.backContext?.goBack
        ? history.goBack()
        : location.state && location.state.backContext
          ? history.push(location.state.backContext?.backTo || "")
          : history.push("/reportexports"),
    [location, history]
  );

  const steps = useMemo<IStep<stepID>[]>(() => {
    let steps: IStep<stepID>[] = [];

    if (!preSelectedBaseTemplate) {
      steps.push({
        id: "select_report_type",
        text: "Select a report type to base your template on",
      });
    }

    if (unfilteredAvailableConfig?.globalOptions) {
      steps.push({
        id: "configure_options",
        text: "Configure options",
      });
    }

    steps.push({
      id: "configure_sections",
      text: "Configure sections",
    });

    // dont bother having just one step
    if (steps.length == 1) {
      steps = [] as IStep<stepID>[];
    }
    return steps;
  }, [preSelectedBaseTemplate, unfilteredAvailableConfig]);

  const [goNext, goPrev] = useMemo(() => {
    let goNext: ReactNode | undefined;
    let goPrev: ReactNode | undefined;

    if (currentStepID === "select_report_type") {
      goNext = (
        <Button
          primary
          arrow
          disabled={!selectedBaseTemplate}
          onClick={() => setCurrentStepID("configure_options")}
        >
          Next
        </Button>
      );
    } else if (currentStepID === "configure_options") {
      goNext = (
        <SidePopupV2 text={configuredExportConfigState.globalOptsValidationErr}>
          <Button
            primary
            arrow
            disabled={!configuredExportConfigState.globalOptsValid}
            onClick={() => setCurrentStepID("configure_sections")}
          >
            Next
          </Button>
        </SidePopupV2>
      );
    } else if (currentStepID === "configure_sections") {
      goNext = (
        <SidePopupV2 text={configuredExportConfigState.validationErr}>
          <Button
            primary
            loading={saving}
            disabled={
              !configuredExportConfigState.valid ||
              !templateName ||
              !templateDescription
            }
            onClick={
              templateNameEdited || editingUUID
                ? onSaveTemplate
                : onSaveTemplateWithConfirmation
            }
          >
            {editingUUID ? "Update report template" : "Save report template"}
          </Button>
        </SidePopupV2>
      );
    }

    const curStepIdx = steps.findIndex((s) => s.id === currentStepID);
    if (curStepIdx > 0) {
      goPrev = (
        <Button
          tertiary
          leftArrow
          onClick={() => setCurrentStepID(steps[curStepIdx - 1].id)}
        >
          Back
        </Button>
      );
    }

    return [goNext, goPrev];
  }, [
    configuredExportConfigState,
    selectedBaseTemplate,
    currentStepID,
    onSaveTemplate,
    onSaveTemplateWithConfirmation,
    saving,
    steps,
    templateDescription,
    templateName,
    templateNameEdited,
    editingUUID,
  ]);

  const availableReportTypes = useMemo(() => {
    if (!defaultCannedExportConfigs) {
      return undefined;
    }

    return defaultCannedExportConfigs.filter(
      (conf) => conf.showInCreateTemplateView
    );
  }, [defaultCannedExportConfigs]);

  const editableReportCardHeader = (
    <div className="header input-header">
      <TextField
        className={classnames("editable-template-name", {
          unedited: !templateNameEdited,
        })}
        placeholder="Enter a report name"
        value={templateName}
        onChanged={(v) => {
          setTemplateName(v);
          setTemplateNameEdited(true);
        }}
        staticEditable
        required
        maxLength={50}
      />
      <TextField
        className={classnames("editable-template-desc", {
          unedited: !templateDescriptionEdited,
        })}
        placeholder="Enter a report description"
        value={templateDescription}
        onChanged={(v) => {
          setTemplateDescription(v);
          setTemplateDescriptionEdited(true);
        }}
        staticEditable
        multiLine
        required
        maxLength={200}
        maxRows={5}
      />
    </div>
  );

  return (
    <StepsWithSections id="report_template_create">
      <PageHeader
        history={history}
        title={
          editingUUID ? "Edit report template" : "Create new report template"
        }
        backAction={goBack}
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Back to Reports"
        }
        breadcrumbs={breadcrumbs}
        infoSection={
          editingUUID ? (
            <p>
              You&apos;re currently editing the report template named{" "}
              <strong>{selectedBaseTemplate?.name ?? ""}</strong>. Once saved,
              you&apos;ll be able to Quick Generate this template to use these
              defaults, or Customize and Generate to tweak again before
              exporting.
            </p>
          ) : (
            <p>
              Here, you can create a new report template for you and others in
              your organization.{" "}
              {!preSelectedBaseTemplate && (
                <>
                  Select a report type to base this on, then configure its
                  options.{" "}
                </>
              )}
              Once saved, you&apos;ll be able to Quick Generate this template to
              use these defaults, or Customize and Generate to tweak again
              before exporting.
            </p>
          )
        }
        infoSectionPageKey="infoSection_createReportTemplate"
      />
      <Steps
        steps={steps}
        currentStep={steps.findIndex((s) => s.id === currentStepID) + 1}
      />
      {currentStepID === "select_report_type" ? (
        availableReportTypes ? (
          <div className="report-type-selector">
            {availableReportTypes.map((conf) => (
              <ReportTypeBadge
                key={conf.defaultID}
                selected={
                  selectedBaseTemplate?.isDefault &&
                  selectedBaseTemplate.defaultID === conf.defaultID
                }
                reportDef={{
                  module: conf.module,
                  imageRef: BadgeImageRefs[conf.badgeImageID],
                  title: conf.reportTypeName,
                  subtext: conf.description,
                }}
                assuranceType={assuranceType}
                onClick={() => setSelectedBaseTemplate(conf)}
              />
            ))}
          </div>
        ) : (
          <LoadingBanner />
        )
      ) : currentStepID === "configure_options" ? (
        !unfilteredAvailableConfig ? (
          <LoadingBanner />
        ) : (
          <ReportCard
            newStyles
            className={classnames("report-template-create", {
              "no-top-margin": steps.length == 0,
            })}
          >
            {editableReportCardHeader}
            {unfilteredAvailableConfig.globalOptions &&
              unfilteredAvailableConfig.globalOptions.length > 0 && (
                <div className="config-opts">
                  <ExportConfigurationOptions
                    availableOptions={unfilteredAvailableConfig.globalOptions}
                    configuredExportConfig={
                      configuredExportConfigState.configuredExportConfig
                    }
                    configuredExportConfigDispatch={
                      configuredExportConfigDispatch
                    }
                  />
                </div>
              )}
          </ReportCard>
        )
      ) : currentStepID === "configure_sections" ? (
        !unfilteredAvailableConfig ? (
          <LoadingBanner />
        ) : (
          <ReportCard
            newStyles
            className={classnames("report-template-create", {
              "no-top-margin": steps.length == 0,
            })}
          >
            {editableReportCardHeader}
            <div className="config-sections">
              <ExportSectionsConfiguration
                availableExportConfig={unfilteredAvailableConfig}
                configuredExportConfig={
                  configuredExportConfigState.configuredExportConfig
                }
                configuredExportConfigDispatch={configuredExportConfigDispatch}
              />
            </div>
          </ReportCard>
        )
      ) : undefined}
      <ActionBar active newStyles>
        <div className="left-content">{goPrev}</div>
        <div className="right-content">
          <Button tertiary onClick={goBack}>
            Cancel
          </Button>
          {goNext}
        </div>
      </ActionBar>
      {confirmationModal}
    </StepsWithSections>
  );
};

const ConnectedReportTemplateCreate = appConnect<
  IReportTemplateCreateConnectedProps,
  never,
  IReportTemplateCreateOwnProps
>((state, props) => {
  const newProps: IReportTemplateCreateConnectedProps = {
    defaultCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.default,
    customCannedExportConfigs: state.cyberRisk.cannedExportConfigs?.custom,
    unfilteredAvailableConfig: props.selectedBaseTemplate
      ? (
          getExportSections(
            state,
            props.selectedBaseTemplate.isDefault
              ? props.selectedBaseTemplate.exportType
              : props.selectedBaseTemplate.baseDefaultConfig.exportType,
            false,
            {},
            undefined,
            false,
            undefined
          ) as ExportSection
        ).data?.availableConfigByFiletype?.[
          props.selectedBaseTemplate.isDefault
            ? props.selectedBaseTemplate.filetype
            : props.selectedBaseTemplate.baseDefaultConfig.filetype
        ]
      : undefined,
    assuranceType: state.common.userData.assuranceType,
  };

  return newProps;
})(ReportTemplateCreate);

const ReportTemplateCreatePropsProvider: FC<
  IReportTemplateCreatePropsProviderProps
> = ({ location, ...rest }) => {
  const [selectedBaseTemplate, setSelectedBaseTemplate] = useState(
    location.state?.baseTemplate
  );
  return (
    <ConnectedReportTemplateCreate
      location={location}
      editingUUID={location.state?.editingUUID}
      preSelectedBaseTemplate={!!location.state?.baseTemplate}
      selectedBaseTemplate={selectedBaseTemplate}
      setSelectedBaseTemplate={setSelectedBaseTemplate}
      {...rest}
    />
  );
};

export default ReportTemplateCreatePropsProvider;
