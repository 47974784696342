import { LabelColor } from "../../_common/types/label";
import { App, AppStatus } from "../api/types";

interface ColorStyles {
  labelColor: LabelColor;
  hexColor: string;
}

// NOTE: Hex colors source from _variables.scss
const appStatusColors: Map<AppStatus, ColorStyles> = new Map([
  [
    AppStatus.ApprovedAppStatus,
    {
      labelColor: LabelColor.Green,
      hexColor: "#20af5d",
    },
  ],
  [
    AppStatus.NotApprovedAppStatus,
    {
      labelColor: LabelColor.Red,
      hexColor: "#ef1700",
    },
  ],
  [
    AppStatus.InReviewAppStatus,
    {
      labelColor: LabelColor.Grey,
      hexColor: "#595c62",
    },
  ],
  [
    AppStatus.NeedsReviewAppStatus,
    {
      labelColor: LabelColor.Orange,
      hexColor: "#ff9901",
    },
  ],
]);

export function labelColorForAppStatus(status: AppStatus): LabelColor {
  return appStatusColors.get(status)?.labelColor ?? LabelColor.Black;
}

export function hexColorForAppStatus(status: AppStatus): string {
  return appStatusColors.get(status)?.hexColor ?? "#FFFFFF";
}

export const statusFilterDisplayText = (status: AppStatus) => {
  switch (status) {
    case AppStatus.NotApprovedAppStatus:
      return "Not approved";
    case AppStatus.NeedsReviewAppStatus:
      return "Need initial review";
    case AppStatus.InReviewAppStatus:
      return "In review";
    case AppStatus.ApprovedAppStatus:
      return "Approved";
  }
};

export const statusDisplayText = (app: App): string => {
  switch (app.status) {
    case AppStatus.NotApprovedAppStatus:
      return "Not approved";
    case AppStatus.NeedsReviewAppStatus:
      return "Need initial review";
    case AppStatus.InReviewAppStatus:
      return "In review";
    case AppStatus.ApprovedAppStatus:
      if (app.approvedForAll) {
        return "Approved for all";
      } else {
        return "Partially approved";
      }
  }
};

export const statusIcon = (app: App): string => {
  switch (app.status) {
    case AppStatus.NotApprovedAppStatus:
      return "cr-icon-cancel";
    case AppStatus.NeedsReviewAppStatus:
      return "cr-icon-risk";
    case AppStatus.InReviewAppStatus:
      return "cr-icon-unclassified";
    case AppStatus.ApprovedAppStatus:
      if (app.approvedForAll) {
        return "cr-icon-accepted";
      } else {
        return "cr-icon-partialaccepted";
      }
  }
};

export const statusColor = (app: App): LabelColor => {
  if (app.status == AppStatus.ApprovedAppStatus && !app.approvedForAll) {
    return LabelColor.Bahia;
  }
  return labelColorForAppStatus(app.status);
};
