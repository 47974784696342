import classnames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import "../style/components/PasswordRevealer.scss";
import { PopupPosition, SidePopupV2 } from "./DismissablePopup";

interface PasswordRevealerProps {
  password: string;
  className?: string;
}

interface BasePasswordRevealerProps extends PasswordRevealerProps {
  revealed: boolean;
  setRevealed: Dispatch<SetStateAction<boolean>>;
}

// BasePasswordRevealer is a component that reveals a password when clicked.
// It has no state management.
export function BasePasswordRevealer({
  className,
  password: inputPassword,
  revealed,
  setRevealed,
}: BasePasswordRevealerProps) {
  const password = revealed ? inputPassword : "••••••••••••••";

  return (
    <div
      className={classnames("password-revealer", className)}
      onClick={() => setRevealed((r) => !r)}
    >
      <div className="password">{password}</div>
      <div
        className={classnames("eye-btn", {
          "cr-icon-noeye": revealed,
          "cr-icon-eye": !revealed,
        })}
      />
    </div>
  );
}

// PasswordRevealer is a component that reveals a password when clicked.
export function PasswordRevealer(props: PasswordRevealerProps) {
  const [revealed, setRevealed] = useState(false);

  return (
    <BasePasswordRevealer
      {...props}
      revealed={revealed}
      setRevealed={setRevealed}
    />
  );
}

export interface PasswordRevealerWithTooltipProps
  extends PasswordRevealerProps {
  position?: PopupPosition;
}

// PasswordRevealerWithTooltip is a component that reveals a password when clicked.
// It also has a tooltip that shows when hovered.
export function PasswordRevealerWithTooltip({
  position,
  ...props
}: PasswordRevealerWithTooltipProps) {
  const [revealed, setRevealed] = useState(false);

  const tooltip = revealed ? "Hide password" : "Show password";

  return (
    <SidePopupV2 text={tooltip} position={position || "top"} noWrap inline>
      <BasePasswordRevealer
        {...props}
        revealed={revealed}
        setRevealed={setRevealed}
      />
    </SidePopupV2>
  );
}
