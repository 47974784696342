import { FC } from "react";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import LabelList from "../../../vendorrisk/components/LabelList";
import { permissionsUrl } from "../../UserBaseAppRouter";
import { UserRiskFilters } from "../../../vendorrisk/components/filter/types";
import { useNavigateWithUserRiskFilters } from "../../helpers/hooks";
import { supportedFilters } from "../../views/permissions/PermissionsView";

interface OrgScopeCategoriesProps {
  categories: string[];
  maxWidth?: number;
}

const OrgScopeCategories: FC<OrgScopeCategoriesProps> = ({
  maxWidth,
  categories,
}) => {
  const navigateWithUserRiskFilter =
    useNavigateWithUserRiskFilters(supportedFilters);

  return (
    <div>
      {categories.length === 0 ? (
        <PillLabel color={LabelColor.Grey}>Unknown</PillLabel>
      ) : (
        <LabelList
          maxWidth={maxWidth}
          labels={categories.slice().map((category) => ({
            id: category,
            name: category,
            color: LabelColor.Grey,
            onClick: () => {
              const filters: Partial<UserRiskFilters> = {
                userRiskScopeCategories: [category],
              };
              navigateWithUserRiskFilter(
                permissionsUrl,
                "Back to Dashboard",
                filters
              );
            },
          }))}
        />
      )}
    </div>
  );
};

export default OrgScopeCategories;
