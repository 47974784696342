import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import React, { FC, FormEventHandler, useCallback, useState } from "react";
import Button from "../../../_common/components/core/Button";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";

export interface ItemIdName {
  id: number;
  name: string;
  orig?: string;
}

interface IRenameReportModalProps extends BaseModalProps {
  items: ItemIdName[] | undefined;
  update(items: ItemIdName[] | undefined): void;
}

const RenameReportModal: FC<IRenameReportModalProps> = (
  props: IRenameReportModalProps
) => {
  const [filename, nameValid, setFilename] = useTextWithValid();
  const [initialFilename, setInitialFilename] = useState("");
  const [id, setId] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  React.useEffect(() => {
    if (!!(props.items && props.items.length > 0 && props.items[0])) {
      const name =
        props.items[0].name.lastIndexOf(".") > 0
          ? props.items[0].name.substring(
              0,
              props.items[0].name.lastIndexOf(".")
            )
          : props.items[0].name;

      setFilename(name, true);
      setInitialFilename(name);
      const suffix =
        props.items[0].name.lastIndexOf(".") > 0
          ? props.items[0].name.substring(props.items[0].name.lastIndexOf("."))
          : "";

      setSuffix(suffix);
      setId(props.items[0].id);
    }
  }, [props.items]);

  const textFieldSetFilename = useCallback((name: string, valid: boolean) => {
    setFilename(name, valid);
  }, []);

  const canSubmit =
    filename &&
    filename.length > 0 &&
    filename !== "" &&
    filename !== initialFilename &&
    nameValid;

  const onSubmit = () => {
    updateCB([{ id, name: filename + suffix, orig: initialFilename + suffix }]);
  };

  const onFormSubmit: FormEventHandler = useCallback(
    (e: React.FormEvent<Element>) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const updateCB = async (items: ItemIdName[]) => {
    setIsSaving(true);
    try {
      await props.update(items);
    } catch (e) {
      throw e;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      className="rename-report-modal"
      onFormDefaultSubmit={onFormSubmit}
      headerContent="Rename report"
      disallowClose={isSaving}
      width={600}
      footerContent={
        <>
          <Button onClick={props.onClose} tertiary disabled={isSaving}>
            Cancel
          </Button>
          <Button
            primary
            disabled={!canSubmit}
            onClick={onSubmit}
            loading={isSaving}
          >
            Save
          </Button>
        </>
      }
    >
      <TextField
        name="filename"
        value={filename}
        suffix={suffix}
        placeholder="Report Name"
        onChanged={textFieldSetFilename}
        required
        minLength={2}
        maxLength={120}
      />
    </ModalV2>
  );
};

export default RenameReportModal;
