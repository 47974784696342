import { useAppSelector } from "../types/reduxHooks";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../types/reduxStore";

const currentOrgSelector = createSelector(
  [
    (state: RootState) => state.common.userData.currentOrgID,
    (state: RootState) => state.common.userData.orgList,
  ],
  (currentOrgID, orgList) => orgList?.find((o) => o.id === currentOrgID)
);
export const useCurrentOrg = () => useAppSelector(currentOrgSelector);

export const useCurrentUser = () =>
  useAppSelector((state) => state.common.userData);

// useCurrentUserTasks - gets the task maps for the current user (remediation requests, risk waivers etc)
// If the result is undefined, the data is still loading
export const useCurrentUserTasks = () =>
  useAppSelector((state) => {
    if (!state.common?.userTaskDataInitialLoadComplete) {
      return undefined;
    }

    return state.common.userTaskData;
  });
