import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { useState } from "react";
import QuestionnaireProgressBar from "../QuestionnaireProgressBar";
import "../../style/IncompleteSubmissionModal.scss";
import {
  AnswersForNodes,
  getNodeTitleDisplay,
  isQuestionAnswered,
  isQuestionNode,
  NodeSummaryAndNode,
  QuestionAnswerState,
  SectionNodeAnswerState,
  VisiblityForNodes,
} from "../../surveyViewer.helpers";
import SurveyViewerItemIcon from "../SurveyViewerItemIcon";
import { SurveyUsageType } from "../../../_common/types/surveyTypes";
import { GptSuggestions } from "../../reducers/autofill.actions";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";

interface IncompleteSubmissionModalProps extends BaseModalProps {
  orgName: string;
  vendorName?: string;
  rootNodeAnswerState: SectionNodeAnswerState;
  nodeTree: NodeSummaryAndNode;
  answers: AnswersForNodes;
  nodeVisibilities: VisiblityForNodes;
  usageType: SurveyUsageType;
  onSubmit: () => Promise<void>;
  onGoToQuestion: (nodeId: string) => void;
  suggestions?: GptSuggestions;
  isPublicSurvey: boolean;
  surveyImportUUID?: string;
}

const IncompleteSubmissionModal = ({
  orgName,
  vendorName,
  rootNodeAnswerState,
  nodeTree,
  answers,
  nodeVisibilities,
  active,
  usageType,
  onClose,
  onSubmit,
  onGoToQuestion,
  suggestions,
  isPublicSurvey,
  surveyImportUUID,
}: IncompleteSubmissionModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Get the first unanswered questions, then count the remainder
  const questionsToDisplay: JSX.Element[] = [];
  let extraQuestionCount = 0;
  const numSuggestions =
    rootNodeAnswerState.suggested + rootNodeAnswerState.optionalSuggested;
  if (active) {
    const deriveUnansweredQuestions = (nodeSummary: NodeSummaryAndNode) => {
      const answerState = isQuestionAnswered(nodeSummary, answers, suggestions);
      if (
        isQuestionNode(nodeSummary) &&
        nodeVisibilities[nodeSummary.nodeId] &&
        answerState !== QuestionAnswerState.Answered &&
        answerState !== QuestionAnswerState.PrefillAnswered &&
        answerState !== QuestionAnswerState.Optional &&
        answerState !== QuestionAnswerState.OptionalAnswered
      ) {
        if (questionsToDisplay.length < 5) {
          const nodeName = getNodeTitleDisplay(nodeSummary, true, true);
          questionsToDisplay.push(
            <tr
              key={nodeSummary.nodeId}
              className={"unanswered-question-row"}
              onClick={() => {
                onGoToQuestion(nodeSummary.nodeId);
                onClose();
              }}
            >
              <td className={"item-icon-cell"}>
                <SurveyViewerItemIcon nodeSummary={nodeSummary} />
              </td>
              <td className={"node-name-cell"}>
                <div
                  className={"node-name"}
                  dangerouslySetInnerHTML={{ __html: nodeName }}
                />
              </td>
              <td>
                <PillLabel
                  color={
                    answerState == QuestionAnswerState.Unanswered
                      ? LabelColor.Blue
                      : LabelColor.Orange
                  }
                >
                  {answerState == QuestionAnswerState.Unanswered
                    ? "Unanswered"
                    : "Suggestion available"}
                </PillLabel>
              </td>
              <td>
                <i className={"cr-icon-chevron"} />
              </td>
            </tr>
          );
        } else {
          extraQuestionCount = extraQuestionCount + 1;
        }
      }

      nodeSummary.children?.forEach((n) => deriveUnansweredQuestions(n));
    };

    deriveUnansweredQuestions(nodeTree);
  }

  return (
    <ModalV2
      className="incomplete-submission-modal"
      active={active}
      onClose={onClose}
      headerContent={
        surveyImportUUID
          ? "Continue export with unanswered questions?"
          : "Are you ready to submit this questionnaire?"
      }
      footerContent={
        <>
          <Button tertiary disabled={isSubmitting} onClick={onClose}>
            {surveyImportUUID ? "Continue answering" : "Cancel"}
          </Button>
          <Button
            primary
            loading={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              onSubmit().then(() => {
                setIsSubmitting(false);
                if (onClose) {
                  onClose();
                }
              });
            }}
          >
            {surveyImportUUID ? "Export" : "Submit questionnaire"}
          </Button>
        </>
      }
    >
      {usageType === SurveyUsageType.Security && (
        <p>
          {isPublicSurvey ? (
            <>
              You have unanswered questions. Please review before submitting to
              your Trust Page.
            </>
          ) : surveyImportUUID ? (
            <>There are still some questions that have not been answered.</>
          ) : (
            <>
              You have unanswered questions which will affect your questionnaire
              score. Please review before submitting to {orgName}.
            </>
          )}
        </p>
      )}
      {usageType === SurveyUsageType.Relationship && (
        <p>
          The following questions have not yet been answered. It&apos;s
          recommended that you provide an answer to all questions so that your
          organization can best determine how to assess{" "}
          {vendorName ?? "your vendor"}&apos;s security posture.
        </p>
      )}
      {numSuggestions > 0 && (
        <InfoBanner
          type={BannerType.QUESTION}
          message={
            <>
              You have {numSuggestions} suggestions available for review. When
              you submit your questionnaire, ALL suggestions will be accepted.
            </>
          }
        />
      )}
      <div className={"unanswered-questions"}>
        <QuestionnaireProgressBar rootNodeAnswerState={rootNodeAnswerState} />
        <table className={"question-table"}>
          <tbody>{questionsToDisplay}</tbody>
        </table>
        {extraQuestionCount > 0 && (
          <p className={"extra-question-count"}>
            + {extraQuestionCount} more...
          </p>
        )}
      </div>
    </ModalV2>
  );
};

export default IncompleteSubmissionModal;
