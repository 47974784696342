import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import { DefaultTextType } from "../../_common/types/orgDefaultTexts";
import { useEffect, useMemo } from "react";
import { fetchOrganisationDefaultTexts } from "./orgDefaultTexts.actions";
import { useManagedOrgID } from "../../_common/hooks";

// useOrgDefaultTexts returns an array of organisation default texts for the given text types.
// Dispatches the fetch if required.
export const useOrgDefaultTexts = (textTypes: DefaultTextType[]): string[] => {
  const dispatch = useAppDispatch();
  const managedOrgId = useManagedOrgID();

  const orgDefaultTexts = useAppSelector(
    (state) => state.cyberRisk.orgDefaultTexts
  );

  useEffect(() => {
    if (!orgDefaultTexts) {
      const fetchText = async () => {
        try {
          await dispatch(
            fetchOrganisationDefaultTexts(
              false,
              managedOrgId !== undefined,
              managedOrgId
            )
          );
        } catch (error) {
          console.error(error);
        }
      };
      fetchText();
    }
  }, [orgDefaultTexts, dispatch, managedOrgId]);

  const results = useMemo(() => {
    return textTypes.map(
      (textType) => orgDefaultTexts?.[textType]?.defaultText ?? ""
    );
  }, [orgDefaultTexts, textTypes]);

  return results;
};
