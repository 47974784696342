import "./Thread.scss";
import { LabelColor } from "../../_common/types/label";
import { hashPick } from "../../vendorrisk/helpers/util";
import classnames from "classnames";
import { formatDateTimeAsLocal } from "../../_common/helpers";
import { PushHighlight, parseSnippet } from "../funcs/useParseSnippet";

// This list has a matching list of classes in the css
export const authorColors = [
  LabelColor.Blue,
  LabelColor.LightBlue,
  LabelColor.Green,
  LabelColor.Yellow,
  LabelColor.Gimblet,
  LabelColor.PersianGreen,
  LabelColor.Violet,
  LabelColor.Fuchsia,
  LabelColor.DarkGrey,
  LabelColor.TrendyPink,
  LabelColor.PictonBlue,
  LabelColor.MountainMeadow,
  LabelColor.Bahia,
];

// slimmed down version of the ThreadEntry from the API
export interface ThreadEntryType {
  user: string;
  date: string;
  text: string;
}

export interface ThreadEntryProps {
  entry: ThreadEntryType;
  highlight?: string;
  pushHighlight?: PushHighlight;
}
export function ThreadEntry({
  entry,
  highlight,
  pushHighlight,
}: ThreadEntryProps) {
  const authorColor = hashPick(entry.user, authorColors);
  const parsedLines = parseSnippet(entry.text, highlight, pushHighlight);

  if (!entry.text) {
    return null;
  }

  return (
    <div className="tm-thread-entry">
      <div className={classnames("attribution", authorColor)}>
        {entry.user}{" "}
        <div className="date">[{formatDateTimeAsLocal(entry.date)}]:</div>
      </div>
      <div className="text">{parsedLines}</div>
    </div>
  );
}
