import { memo, useState } from "react";
import {
  RemediationRequestSharedEvidence,
  RemediationRequestStatus,
  RemediationRequestUsers,
  RemediationRequestWithMeta,
} from "../../types/remediation";
import ReportCard from "../ReportCard";
import InfoTable, { InfoTableRow } from "../InfoTable";
import { remediationStatusToPill } from "./helpers";
import { UserAvatarAndName, UserAvatarUser } from "../UserAvatar";
import { createUUID } from "../../../survey_builder/helpers";
import { formatDateAsLocal, getGravatarURL } from "../../helpers";
import Button from "../core/Button";
import IconButton from "../IconButton";
import UpdateDueDateModal from "./UpdateDueDateModal";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import Icon from "../core/Icon";
import "../../style/components/remediationDetails/RemediationDetailsSummaryCard.scss";
import { RiskSource } from "../../types/risks";
import { DocumentLink } from "../../../vendorrisk/components/DocumentLink";
import { ButtonWithDropdownV2, DropdownItem } from "../core/DropdownV2";
import TextField from "../TextField";
import classnames from "classnames";
import { CorrespondenceIconButton } from "../../../vendorrisk/components/CorrespondenceIconButton";
import { SidePopupV2 } from "../DismissablePopup";

interface IRemediationDetailsSummaryCardProps {
  details: RemediationRequestWithMeta;
  users: RemediationRequestUsers;
  setMessagesOpen: () => void;
  setArchiveModalOpen: () => void;
  setCloseModalOpen: () => void;
  setAddRecipientsModalOpen: () => void;
  setSubmitModalOpen: () => void;
  progress: number;
  onUpdateTitle: (title: string) => Promise<any>;
  userHasWriteRemediationPermission: boolean;
  userHasWritePermsInAnyPortfolio: boolean;
  onUpdateDueDate: (dueDate?: string, reminderDate?: string) => Promise<any>;
  isVendorPortal: boolean;
  riskAssessmentStarted?: string;
  unreadMessages: number;
  totalMessages: number;
  isArchived: boolean;
  goToRiskAssessment: () => void;
  sharedDocuments: RemediationRequestSharedEvidence[];
  downloadDocument: (doc: RemediationRequestSharedEvidence) => void;
}

const RemediationDetailsSummaryCard = ({
  details,
  setMessagesOpen,
  setArchiveModalOpen,
  setCloseModalOpen,
  setAddRecipientsModalOpen,
  setSubmitModalOpen,
  users,
  progress,
  onUpdateTitle,
  userHasWriteRemediationPermission,
  userHasWritePermsInAnyPortfolio,
  onUpdateDueDate,
  isVendorPortal,
  riskAssessmentStarted,
  goToRiskAssessment,
  unreadMessages,
  totalMessages,
  isArchived,
  downloadDocument,
  sharedDocuments,
}: IRemediationDetailsSummaryCardProps) => {
  const usersForDisplay: UserAvatarUser[] = [
    ...(users.sharedUsers?.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      avatar: user.avatar,
    })) ?? []),
    ...(users.invitedEmails?.map((email) => ({
      id: createUUID(),
      name: "",
      email: email,
      avatar: getGravatarURL(email),
    })) ?? []),
  ];

  const [updateDatesModalOpen, setUpdateDatesModalOpen] = useState(false);

  const [title, setTitle] = useState(details.title ?? "");
  const [titleChanged, setTitleChanged] = useState(false);
  const canEditTitle = userHasWriteRemediationPermission && !isVendorPortal;

  const haveAdditionalEvidenceRisks = details.risks.some(
    (r) => r.source === RiskSource.AdditionalEvidence
  );
  const isThreatMonitoringRemediation =
    details.risks.length === 1 &&
    details.risks[0].source === RiskSource.ThreatMonitoring;

  const showDocuments =
    haveAdditionalEvidenceRisks &&
    (sharedDocuments.length > 0 || !isVendorPortal);

  const meatballOptions: { label: string; onClick: () => void }[] = [];
  if (!isVendorPortal && userHasWriteRemediationPermission) {
    meatballOptions.push({
      label: isArchived ? "Unarchive" : "Archive",
      onClick: setArchiveModalOpen,
    });
  }
  if (
    !details?.deletedAt &&
    (isVendorPortal || userHasWriteRemediationPermission)
  ) {
    meatballOptions.push({
      label: "Manage collaborators",
      onClick: setAddRecipientsModalOpen,
    });
  }

  return (
    <>
      <ReportCard newStyles className={"remediation-details-summary-card"}>
        <div
          className={classnames("header", { "editable-title": canEditTitle })}
        >
          {canEditTitle ? (
            <TextField
              value={title}
              onChanged={(val) => {
                setTitle(val);
                setTitleChanged(val !== title);
              }}
              onBlur={() => {
                if (titleChanged) {
                  onUpdateTitle(title);
                  setTitleChanged(false);
                }
              }}
            />
          ) : (
            title
          )}
          <div className="header-right">
            <CorrespondenceIconButton
              totalMessages={totalMessages}
              haveUnreadMessages={unreadMessages > 0}
              onClick={setMessagesOpen}
            />
            {!isVendorPortal && userHasWriteRemediationPermission && (
              <SidePopupV2
                noWrap={isArchived}
                text={
                  isArchived
                    ? "Archived requests cannot be reopened"
                    : isThreatMonitoringRemediation
                      ? "Threat monitoring requests cannot be reopened"
                      : undefined
                }
              >
                <Button
                  primary
                  onClick={setCloseModalOpen}
                  disabled={
                    isArchived ||
                    !details ||
                    (details &&
                      details.status === RemediationRequestStatus.Closed &&
                      isThreatMonitoringRemediation)
                  }
                >
                  {details && details.status !== RemediationRequestStatus.Closed
                    ? "Mark as completed"
                    : "Reopen"}
                </Button>
              </SidePopupV2>
            )}
            {isVendorPortal &&
              details?.status === RemediationRequestStatus.Open && (
                <Button primary onClick={setSubmitModalOpen}>
                  Submit for review
                </Button>
              )}
            {meatballOptions.length > 0 && (
              <ButtonWithDropdownV2
                icon={<i className={"cr-icon-dots-menu"} />}
              >
                {meatballOptions.map(({ label, onClick }) => (
                  <DropdownItem key={label} onClick={onClick}>
                    {label}
                  </DropdownItem>
                ))}
              </ButtonWithDropdownV2>
            )}
          </div>
        </div>
        {!isVendorPortal &&
          userHasWritePermsInAnyPortfolio &&
          !userHasWriteRemediationPermission && (
            <div className="info-banner-wrapper">
              <InfoBanner
                type={BannerType.INFO}
                message={
                  "This request is read-only as it includes domains in portfolios you have limited or no access to."
                }
              />
            </div>
          )}
        {!isVendorPortal && !!riskAssessmentStarted && (
          <div className="info-banner-wrapper">
            <InfoBanner
              type={BannerType.INFO}
              message={
                <p className={"assessment-info"} onClick={goToRiskAssessment}>
                  This remediation request was created as part of a{" "}
                  <span className={"bold"}>
                    Risk Assessment started on{" "}
                    {formatDateAsLocal(riskAssessmentStarted)}
                  </span>
                  <Icon name={"arrow"} direction={90} />
                </p>
              }
            />
          </div>
        )}
        <div className="content">
          <InfoTable className={"details-table"}>
            <InfoTableRow
              label={"Status:"}
              value={remediationStatusToPill(details.status, details.archived)}
              rowClass={"status-row"}
            />
            <InfoTableRow
              label={"Progress:"}
              value={`${progress}% complete`}
              rowClass={"progress-row"}
            />
            <InfoTableRow
              label={"Date sent:"}
              value={`${formatDateAsLocal(
                details.createdAt
              )} (updated ${formatDateAsLocal(details.updatedAt)})`}
            />
            <InfoTableRow
              rowClass={"label-middle"}
              label={"Date due:"}
              value={
                <>
                  {details.dueDate ? (
                    formatDateAsLocal(details.dueDate)
                  ) : (
                    <em>Not set</em>
                  )}
                  {userHasWriteRemediationPermission && !isVendorPortal && (
                    <IconButton
                      className="edit-date-button"
                      icon={<span className={"cr-icon-pencil-2"} />}
                      onClick={() => setUpdateDatesModalOpen(true)}
                    />
                  )}
                </>
              }
            />
            <InfoTableRow
              rowClass={"label-middle"}
              label={"Reminder set:"}
              value={
                <>
                  {details.reminderDate ? (
                    formatDateAsLocal(details.reminderDate)
                  ) : (
                    <em>Not set</em>
                  )}
                  {userHasWriteRemediationPermission && !isVendorPortal && (
                    <IconButton
                      className="edit-date-button"
                      icon={<span className={"cr-icon-pencil-2"} />}
                      onClick={() => setUpdateDatesModalOpen(true)}
                    />
                  )}
                </>
              }
            />
          </InfoTable>
          <InfoTable className={"details-table"}>
            <InfoTableRow
              label={"Sent by:"}
              value={
                <UserAvatarAndName
                  name={details.sender.name}
                  email={details.sender.email}
                  avatar={details.sender.avatar}
                />
              }
            />
            <InfoTableRow
              label="Sent to:"
              rowClass="shared-with-row"
              value={
                <>
                  <div className={`shared-with-list`}>
                    {(usersForDisplay || []).map((u) => (
                      <UserAvatarAndName
                        key={u.id}
                        avatar={u.avatar}
                        name={u.name}
                        email={u.email}
                      />
                    ))}
                  </div>
                  {!details.deletedAt &&
                    (isVendorPortal || userHasWriteRemediationPermission) && (
                      <Button tertiary onClick={setAddRecipientsModalOpen}>
                        Manage collaborators
                      </Button>
                    )}
                </>
              }
            />
            {showDocuments && (
              <InfoTableRow
                label="Shared documents:"
                rowClass="shared-documents"
                value={
                  sharedDocuments.length > 0 ? (
                    sharedDocuments.map((doc) => (
                      <DocumentLink
                        key={doc.id}
                        evidenceName={doc.name}
                        filename={doc.filename ?? ""}
                        virusSafe={doc.virusSafe ?? false}
                        onDownload={() => downloadDocument(doc)}
                      />
                    ))
                  ) : (
                    <em>None shared</em>
                  )
                }
              />
            )}
          </InfoTable>
        </div>
      </ReportCard>
      <UpdateDueDateModal
        active={updateDatesModalOpen}
        onClose={() => setUpdateDatesModalOpen(false)}
        updateDueDate={onUpdateDueDate}
        dueDate={details.dueDate}
        reminderDate={details.reminderDate}
      />
    </>
  );
};

export default memo(RemediationDetailsSummaryCard);
