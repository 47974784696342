import { FC, useState } from "react";
import UserBaseAPI from "../../api/userbase.api";

import SlidePanel from "../../../_common/components/SlidePanel";
import UserAppPermissionsPanel from "./UserAppPermissionsPanel";
import UserAppsTable from "./UserAppsTable";
import { UserApp } from "../../api/types";

import "./UserApps.scss";
import SearchBox from "../../../_common/components/SearchBox";
import ReportCard from "../../../_common/components/ReportCard";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";

interface UserAppsProps {
  userUUID: string;
}

const UserApps: FC<UserAppsProps> = ({ userUUID }) => {
  // Get all the apps for the user
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserAppsV1Query({
    userUUID,
  });

  const [slidePanelApp, setSlidePanelApp] = useState<UserApp | undefined>(
    undefined
  );

  const [searchText, setSearchText] = useState("");

  const onViewAppPermissions = (app: UserApp) => {
    setSlidePanelApp(app);
  };

  const apps = data?.apps ?? [];
  const filteredApps = apps.filter(
    (a) =>
      !searchText ||
      a.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  const isAppsEmpty = !isLoading && !searchText && filteredApps.length === 0;
  const isSearchEmpty = !isLoading && !!searchText && filteredApps.length === 0;

  return (
    <div className="user-apps">
      <ReportCard newStyles>
        <SearchBox
          placeholder={"Search applications"}
          onChanged={(q) => setSearchText(q)}
          value={searchText}
        />
        {isAppsEmpty && (
          <EmptyCard text={"There are no applications for this user."} />
        )}
        {isSearchEmpty && (
          <SearchEmptyCard
            clearText={"Clear filter"}
            onClear={() => {
              setSearchText("");
            }}
            searchItemText={"applications"}
          />
        )}
        {isLoading ||
          (filteredApps.length > 0 && (
            <UserAppsTable
              isLoading={isLoading}
              appsToShow={filteredApps}
              onClick={onViewAppPermissions}
            />
          ))}
      </ReportCard>

      <SlidePanel
        newStyles
        dimContent
        // TODO: Removing this title for now until we decide whether we will standardise on this
        // title={"Permissions"}
        active={!!slidePanelApp}
        className={"user-apps-slide-panel"}
        onClose={() => {
          setSlidePanelApp(undefined);
        }}
      >
        {!!slidePanelApp && (
          <UserAppPermissionsPanel userUUID={userUUID} app={slidePanelApp} />
        )}
      </SlidePanel>
    </div>
  );
};

export default UserApps;
