import Button from "./core/Button";
import "../style/components/EmptyCardWithAction.scss";
import classnames from "classnames";
import errorIconSrc from "../../vendorrisk/images/circle_exclaim_red.svg";
import infoIconSrc from "../../vendorrisk/images/circle_exclaim.svg";
import { contactSupport } from "../helpers";

interface IEmptyCardProps {
  className?: string;

  // Optional icon
  iconSrc?: any;
  iconJSX?: JSX.Element;

  // Text for EmptyCardWithAction header
  emptyText?: string;

  // Text for EmptyCardWithAction paragraph
  emptySubText?: React.ReactNode;

  // Text for default action button
  actionButtonText?: string;
  onActionClick?: () => void;
  actionButtonIconClass?: string;
  actionDisabled?: boolean;
  actionButtonLoading?: boolean;

  // Optional render override for default action button
  actionButtonRenderOverride?: JSX.Element;

  // Optional make the button the (filled) primary styling
  buttonPrimary?: boolean;

  // Optional link for support article. If omitted, support link will not be shown
  supportLinkHref?: string;

  // Optional support link text. Defaults to 'View support article'
  supportLinkText: string;

  // Optional to use filled styling
  filledStyling?: boolean;
}

/* Display an empty with a button for default action and an optional support link */
const EmptyCardWithAction = (props: IEmptyCardProps) => {
  const classes = classnames("empty-card-with-action", props.className, {
    "filled-styling": props.filledStyling,
  });

  return (
    <div className={classes}>
      {props.iconSrc && <img src={props.iconSrc} className="empty-card-icon" />}
      {props.iconJSX}
      {props.emptyText && <div className="empty-text">{props.emptyText}</div>}
      {props.emptySubText && (
        <div className="empty-subtext">{props.emptySubText}</div>
      )}
      {(props.onActionClick || props.actionButtonRenderOverride) && (
        <div className="btn-container">
          {props.actionButtonRenderOverride && props.actionButtonRenderOverride}
          {!props.actionButtonRenderOverride && (
            <Button
              onClick={props.onActionClick}
              disabled={props.actionDisabled}
              loading={props.actionButtonLoading}
              primary={props.buttonPrimary}
            >
              <div className={props.actionButtonIconClass ?? "icon-x"} />
              {props.actionButtonText}
            </Button>
          )}
        </div>
      )}
      {props.supportLinkHref && (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={props.supportLinkHref}
          className="support-link"
        >
          {props.supportLinkText}
          <div className={"cr-icon-arrow-right"} />
        </a>
      )}
    </div>
  );
};

EmptyCardWithAction.defaultProps = {
  supportLinkText: "View support article",
};

export default EmptyCardWithAction;

interface ErrorCardWithActionProps {
  errorText?: string;
  errorSubText?: string;
  extraContent?: React.ReactNode;
  actionText?: string;
  action?: () => void;
}

// A default EmptyCardWithAction for unknown errors, with an intercom link and optional extra content
export const ErrorCardWithAction = (props: ErrorCardWithActionProps) => {
  return (
    <EmptyCardWithAction
      iconSrc={errorIconSrc}
      emptyText={props.errorText ?? "An unknown error occurred"}
      emptySubText={
        props.errorSubText ??
        "Please try again or contact support if the issue persists."
      }
      actionButtonRenderOverride={
        <div className={"error-card-content"}>
          {props.extraContent && props.extraContent}
          <Button
            tertiary
            arrow
            onClick={props.action ?? contactSupport}
            className={"btn-contact-support"}
          >
            {props.actionText ?? "Contact support"}
          </Button>
        </div>
      }
    />
  );
};

interface InfoCardWithActionProps {
  infoText: string;
  infoSubText?: string;
  extraContent?: React.ReactNode;
  hideContactSupport?: boolean;
}

export const InfoCardWithAction = (props: InfoCardWithActionProps) => {
  return (
    <EmptyCardWithAction
      iconSrc={infoIconSrc}
      emptyText={props.infoText}
      emptySubText={props.infoSubText}
      actionButtonRenderOverride={
        <div className={"info-card-content"}>
          {props.extraContent && props.extraContent}
          {!props.hideContactSupport && (
            <Button
              tertiary
              arrow
              onClick={contactSupport}
              className={"btn-contact-support"}
            >
              Contact support
            </Button>
          )}
        </div>
      }
    />
  );
};
