import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../InfoBanner";
import TrustPageAPI from "../../../verifiedvendors/api/trustpage.api";

export const VerifiedVendorInviteUserModalName =
  "VerifiedVendorInviteUserModalName";

class VerifiedVendorInviteUserModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    modalData: PropTypes.shape({
      existingEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  };

  state = {
    loading: false,
    email: "",
  };

  onSubmit = (e) => {
    e.preventDefault();

    const email = this.state.email.trim().toLowerCase();
    if (this.props.modalData.existingEmails.indexOf(email) > -1) {
      this.props.dispatch(
        addMessageAlert({
          message: `${email} has already been invited to view your Trust Page.`,
          type: BannerType.WARNING,
        })
      );
      return;
    }

    this.setState({ loading: true });

    TrustPageAPI.useInviteUserToTrustPageV1Mutation({ email: email })
      .unwrap()
      .then(() =>
        this.props.dispatch(
          addDefaultSuccessAlert(
            `${email} has been invited to view your Trust Page`
          )
        )
      )
      .then(() => this.props.dispatch(closeModal()))
      .catch((e) => {
        console.error(e);
        this.props.dispatch(addDefaultUnknownErrorAlert("Error inviting user"));
      });
  };

  render() {
    return (
      <div className="modal-content verified-vendor-user-modal">
        <h2>
          Invite a user to view
          <br />
          your Trust Page
        </h2>
        <form onSubmit={this.onSubmit}>
          <input
            type="email"
            disabled={this.state.loading}
            required
            placeholder="Email address"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <div className="btn-group">
            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Invite
            </Button>
            <Button tertiary onClick={() => this.props.dispatch(closeModal())}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default VerifiedVendorInviteUserModal;
