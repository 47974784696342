import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { useState, useMemo } from "react";
import QuestionnaireProgressBar from "../QuestionnaireProgressBar";
import "../../style/SendQuestionnaireToVendorModal.scss";
import {
  AnswersForNodes,
  getNodeTitleDisplay,
  isQuestionAnswered,
  isQuestionNode,
  NodeSummaryAndNode,
  QuestionAnswerState,
  SectionNodeAnswerState,
  VisiblityForNodes,
} from "../../surveyViewer.helpers";
import SurveyViewerItemIcon from "../SurveyViewerItemIcon";
import { GptSuggestions } from "../../reducers/autofill.actions";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { IStep, Steps } from "../../../_common/components/StepsWithSections";
import QuestionnaireRiskVisibilitySelector, {
  getMessageForQuestionnaireRiskVisibilityInfoBanner,
} from "../../../vendorrisk/components/surveys/QuestionnaireRiskVisibilitySelector";
import { SurveyRiskVisibility } from "../../../_common/types/survey";
import Icon from "../../../_common/components/core/Icon";
import TwoColumnDisplay, {
  TwoColumnDisplayRow,
} from "../../../_common/components/TwoColumnDisplay";
import DatePicker from "../../../_common/components/DatePicker";
import CalIcon from "../../images/cal-icon.svg";
import moment from "moment";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import TextField from "../../../_common/components/TextField";
import ContactSelectV2, {
  useSelectedContacts,
} from "../../../vendorrisk/components/contacts/ContactSelectV2";
import { IVendorContactResponse } from "../../../_common/types/vendorContact";
import { ContactDisplayExisting } from "../../../vendorrisk/components/contacts/ContactSelect";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";

interface SendQuestionnaireToVendorModalProps extends BaseModalProps {
  vendorName?: string;
  vendorContacts?: IVendorContactResponse[];
  rootNodeAnswerState: SectionNodeAnswerState;
  nodeTree: NodeSummaryAndNode;
  answers: AnswersForNodes;
  nodeVisibilities: VisiblityForNodes;
  onSubmit: (
    selectedVendorContacts: ContactDisplayExisting[],
    dueDate: string,
    reminderDate: string,
    message: string,
    subject: string,
    riskVisibility: SurveyRiskVisibility
  ) => Promise<void>;
  onGoToQuestion: (nodeId: string) => void;
  suggestions?: GptSuggestions;
}

// note: salutation is added as part of the email template. It should not be entered as part of the email body template.
const DefaultAnalystEmailBody = `I’m {{AnalystName}}, an analyst from UpGuard. We’ve been asked by {{AccountContact}} at {{AccountName}} to conduct a risk assessment on your company, and you’ve been nominated as the best contact to help with this.

I’ve completed a security questionnaire based on supplied documentation and it’s ready for you to review. Please also complete any unanswered questions, respond to identified risks, and add any relevant documentation.  

If you’re not the right person for this task, please use the UpGuard platform to invite a colleague to help instead.`;

const DefaultAnalystEmailSubject = `{{AccountName}} Third Party Risk Assessment on {{VendorName}}`;

const SendQuestionnaireToVendorModal = ({
  vendorName,
  vendorContacts,
  rootNodeAnswerState,
  nodeTree,
  answers,
  nodeVisibilities,
  active,
  onClose,
  onSubmit,
  onGoToQuestion,
  suggestions,
}: SendQuestionnaireToVendorModalProps) => {
  const [isSending, setIsSending] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [questionsExpanded, setQuestionsExpanded] = useState(true);
  const [dueDate, setDueDate] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState(DefaultAnalystEmailBody);
  const [subject, setSubject] = useState(DefaultAnalystEmailSubject);
  const [reminderDateSet, setReminderDateSet] = useState(false);
  const [reminderDate, setReminderDate] = useState<string | undefined>(
    undefined
  );
  const [riskVisibility, setRiskVisibility] = useState(
    SurveyRiskVisibility.Visible
  );

  const [selectedVendorContacts, newVendorContacts, setVendorContacts] =
    useSelectedContacts([]);

  const toggleQuestionsExpanded = () => {
    setQuestionsExpanded(!questionsExpanded);
  };

  const [questionsToDisplay, extraQuestionCount] = useMemo(() => {
    // Get the first unanswered questions, then count the remainder
    const questions: JSX.Element[] = [];
    let extraQCount = 0;
    if (active) {
      const deriveUnansweredQuestions = (nodeSummary: NodeSummaryAndNode) => {
        const answerState = isQuestionAnswered(
          nodeSummary,
          answers,
          suggestions
        );
        if (
          isQuestionNode(nodeSummary) &&
          nodeVisibilities[nodeSummary.nodeId] &&
          answerState !== QuestionAnswerState.Answered &&
          answerState !== QuestionAnswerState.PrefillAnswered &&
          answerState !== QuestionAnswerState.Optional &&
          answerState !== QuestionAnswerState.OptionalAnswered
        ) {
          if (questions.length < 5) {
            const nodeName = getNodeTitleDisplay(nodeSummary, true, true);
            questions.push(
              <tr
                key={nodeSummary.nodeId}
                className={"unanswered-question-row"}
                onClick={() => {
                  onGoToQuestion(nodeSummary.nodeId);
                  onClose();
                }}
              >
                <td className={"item-icon-cell"}>
                  <SurveyViewerItemIcon nodeSummary={nodeSummary} />
                </td>
                <td className={"node-name-cell"}>
                  <div
                    className={"node-name"}
                    dangerouslySetInnerHTML={{ __html: nodeName }}
                  />
                </td>
                <td>
                  <PillLabel
                    color={
                      answerState == QuestionAnswerState.Unanswered
                        ? LabelColor.Blue
                        : LabelColor.Orange
                    }
                  >
                    {answerState == QuestionAnswerState.Unanswered
                      ? "Unanswered"
                      : "Suggestion available"}
                  </PillLabel>
                </td>
                <td>
                  <i className={"cr-icon-chevron"} />
                </td>
              </tr>
            );
          } else {
            extraQCount = extraQCount + 1;
          }
        }

        nodeSummary.children?.forEach((n) => deriveUnansweredQuestions(n));
      };

      deriveUnansweredQuestions(nodeTree);
    }
    return [questions, extraQCount];
  }, [nodeTree, answers, suggestions, nodeVisibilities, active]);

  const numSuggestions = useMemo(() => {
    return (
      rootNodeAnswerState.suggested + rootNodeAnswerState.optionalSuggested
    );
  }, [rootNodeAnswerState]);

  const canGoStep = (step: number): boolean => {
    switch (step) {
      case 3:
        return (
          (!!dueDate && !reminderDateSet && reminderDate == undefined) ||
          (reminderDateSet && !!reminderDate)
        );
      default:
        return true;
    }
  };

  const canSubmit = () => {
    return (
      selectedVendorContacts.length > 0 && !!dueDate && !!message && !!subject
    );
  };

  const steps: IStep[] = [
    {
      id: "review",
      text: `Review questionnaire`,
      onClick: () => setCurrentStep(1),
      disabled: !canGoStep(1),
    },
    {
      id: "settings",
      text: "Settings",
      disabled: !canGoStep(2),
      onClick: () => setCurrentStep(2),
    },
    {
      id: "recipients",
      text: "Recipients",
      disabled: !canGoStep(3),
      onClick: () => setCurrentStep(3),
    },
  ];

  const goNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const renderStep1 = () => {
    return (
      <>
        {numSuggestions > 0 && (
          <InfoBanner
            type={BannerType.QUESTION}
            message={
              <>
                {numSuggestions == 1
                  ? `You have 1 suggestion`
                  : `You have ${numSuggestions} suggestions`}
                {` still available for review. `}
                {`When the questionnaire is sent to ${vendorName}, ALL outstanding suggestions will be accepted.`}
              </>
            }
          />
        )}
        {questionsToDisplay.length > 0 && (
          <div
            className={"prompt"}
          >{`Review the unanswered questions you want ${vendorName} to complete.`}</div>
        )}
        <div>
          <QuestionnaireProgressBar rootNodeAnswerState={rootNodeAnswerState} />
        </div>
        {questionsToDisplay.length > 0 && (
          <div className={"unanswered-questions"}>
            <div className={"expando-h3"}>
              <div
                className={"title"}
                onClick={() => toggleQuestionsExpanded()}
              >
                {`Unanswered questions (${
                  extraQuestionCount + questionsToDisplay.length
                })`}{" "}
              </div>
              <Icon
                name="chevron"
                direction={questionsExpanded ? 0 : 180}
                onClick={() => toggleQuestionsExpanded()}
              />
            </div>
            {questionsExpanded && (
              <>
                <table className={"question-table"}>
                  <tbody>{questionsToDisplay}</tbody>
                </table>
                {extraQuestionCount > 0 && (
                  <p className={"extra-question-count"}>
                    + {extraQuestionCount} more...
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const renderStep2 = () => {
    const fmt = "YYYY-MM-DD";
    const datePickerFmt = "Y-m-d";
    return (
      <TwoColumnDisplay>
        <TwoColumnDisplayRow
          label={"Risk information visibility"}
          description={
            "Choose whether to show or hide risk information from the vendor."
          }
          content={
            <>
              <QuestionnaireRiskVisibilitySelector
                value={riskVisibility}
                onChange={setRiskVisibility}
              />
              <br />
              {getMessageForQuestionnaireRiskVisibilityInfoBanner(
                riskVisibility
              )}
            </>
          }
        />
        <TwoColumnDisplayRow
          label={"Due date"}
          description={
            "Set a date you would like this questionnaire completed by."
          }
          content={
            <>
              <div className={"date-ctrl"}>
                <DatePicker
                  onChange={(evt) => setDueDate(evt.target.value)}
                  min={moment().format(fmt)}
                  value={dueDate}
                  placeholder={dueDate}
                  displayFormat={datePickerFmt}
                />
                <img src={CalIcon} alt={"cal"} className={"calicon"} />
              </div>
            </>
          }
        />
        <TwoColumnDisplayRow
          label={"Reminders"}
          description={
            "Choose whether to send reminders to complete the questionnaire."
          }
          content={
            <>
              <ColorCheckbox
                checked={reminderDateSet}
                onClick={() => {
                  if (reminderDateSet) {
                    setReminderDate(undefined);
                  }
                  setReminderDateSet(!reminderDateSet);
                }}
                label={"Add a reminder"}
              />
              {reminderDateSet && (
                <div className={"date-ctrl"}>
                  <DatePicker
                    onChange={(evt) => setReminderDate(evt.target.value)}
                    min={moment().format(fmt)}
                    value={reminderDate ?? ""}
                    placeholder={reminderDate ?? ""}
                    displayFormat={datePickerFmt}
                  />
                  <img src={CalIcon} alt={"cal"} className={"calicon"} />
                </div>
              )}
            </>
          }
        />
      </TwoColumnDisplay>
    );
  };
  const renderStep3 = () => {
    const SubjectCharLimit = 120;

    return (
      <TwoColumnDisplay>
        <TwoColumnDisplayRow
          label={"Assign recipients"}
          description={"Who do we send the questionnaire to?"}
          content={
            <div className={"contact"}>
              <ContactSelectV2
                orgContacts={[]}
                maxSelectedContacts={1}
                selectContacts={setVendorContacts}
                newContacts={newVendorContacts}
                existingSelectedContacts={selectedVendorContacts}
                vendorContacts={vendorContacts}
                readonly={false}
              />
            </div>
          }
        />
        <TwoColumnDisplayRow
          label={"Email"}
          description={
            "This will be emailed to the recipient and displayed on the questionnaire."
          }
          content={
            <>
              <div className={"email-fields"}>
                <div className={"title"}>{"Subject line"}</div>
                <TextField
                  value={subject}
                  onChanged={setSubject}
                  minLength={3}
                  maxLength={SubjectCharLimit}
                  placeholder={"Add email subject"}
                  className={"subject"}
                />
                <div className={"title with-gap"}>{"Message"}</div>
                <div className={"message"}>
                  <RichTextEditV2 value={message} onChange={setMessage} />
                </div>
                <div className={"template-prompt"}>
                  <InfoBanner
                    type={BannerType.INFO}
                    message={
                      <div>
                        Insert{" "}
                        <a
                          href="https://help.upguard.com/en/articles/4790489-how-to-set-up-templates"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          merge tags
                        </a>{" "}
                        to prefill data in the subject line or message.
                        <ul>
                          <li key={"vendor-name"}>
                            {"Vendor name {{VendorName}}"}
                          </li>
                          <li key={"vendor-contact"}>
                            {"Vendor contact {{VendorContact}}"}
                          </li>
                          <li key={"account-name"}>
                            {"Account name {{AccountName}}"}
                          </li>
                          <li key={"account-contact"}>
                            {"Account contact {{AccountContact}}"}
                          </li>
                          <li key={"analyst-name"}>
                            {"Analyst name {{AnalystName}}"}
                          </li>
                          <li key={"qn-name"}>
                            {"Questionnaire type {{QuestionnaireType}}"}
                          </li>
                        </ul>
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          }
        />
      </TwoColumnDisplay>
    );
  };

  return (
    <ModalV2
      className="send-questionnaire-to-vendor-modal"
      active={active}
      onClose={onClose}
      headerContent={`Are you ready to send this questionnaire to ${vendorName}?`}
      footerContent={
        <>
          <Button tertiary disabled={isSending} onClick={onClose}>
            {"Cancel"}
          </Button>
          <Button
            arrow
            primary
            loading={isSending}
            disabled={
              currentStep < steps.length
                ? !canGoStep(currentStep + 1)
                : !canSubmit()
            }
            onClick={() => {
              if (currentStep === steps.length) {
                setIsSending(true);
                onSubmit(
                  selectedVendorContacts,
                  dueDate ?? "",
                  reminderDate ?? "",
                  message,
                  subject,
                  riskVisibility
                ).then(() => {
                  if (onClose) {
                    onClose();
                  }
                  setIsSending(false);
                });
              } else {
                goNext();
              }
            }}
          >
            {currentStep === steps.length ? "Send questionnaire" : "Next"}
          </Button>
        </>
      }
    >
      <Steps steps={steps} currentStep={currentStep} />
      <div className={"step-body"}>
        {currentStep === 1 && renderStep1()}
        {currentStep === 2 && renderStep2()}
        {currentStep === 3 && renderStep3()}
      </div>
    </ModalV2>
  );
};

export default SendQuestionnaireToVendorModal;
