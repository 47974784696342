import { useAppSelector } from "../../_common/types/reduxHooks";
import tmSlice from "../Slice";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import { ThreatMonitoringFeedType } from "../api/types";
import { getDateOfLastFeedDownload } from "../views/ThreatMonitoringView";

export default function useFeedTotals() {
  const feedPages = useAppSelector(tmSlice.selectors.feedPages);
  const feedUpTo = getDateOfLastFeedDownload();

  const toTotalsReqBody = (feedType: ThreatMonitoringFeedType) => {
    const feedPage = feedPages[feedType];
    return {
      feedUpTo,
      feedType,
      filters: feedPage.query.filters,
    };
  };

  const { data: openData } = ThreatMonitoringAPI.useGetResultTotalsV1Query(
    toTotalsReqBody(ThreatMonitoringFeedType.Open),
    { refetchOnMountOrArgChange: true }
  );
  const { data: investigatingData } =
    ThreatMonitoringAPI.useGetResultTotalsV1Query(
      toTotalsReqBody(ThreatMonitoringFeedType.Investigating),
      { refetchOnMountOrArgChange: true }
    );
  const { data: remediatingData } =
    ThreatMonitoringAPI.useGetResultTotalsV1Query(
      toTotalsReqBody(ThreatMonitoringFeedType.Remediating),
      { refetchOnMountOrArgChange: true }
    );
  const { data: closedData } = ThreatMonitoringAPI.useGetResultTotalsV1Query(
    toTotalsReqBody(ThreatMonitoringFeedType.Closed),
    { refetchOnMountOrArgChange: true }
  );

  const totalOpen = openData?.count || 0;
  const totalInvestigating = investigatingData?.count || 0;
  const totalRemediating = remediatingData?.count || 0;
  const totalClosed = closedData?.count || 0;

  const totalResults =
    totalOpen + totalInvestigating + totalRemediating + totalClosed;

  return {
    totalResults,
    totalOpen,
    totalInvestigating,
    totalRemediating,
    totalClosed,
  };
}
