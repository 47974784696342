import { useEffect, useState } from "react";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import {
  ISurveyListItemResponse,
  ISurveyMini,
  SurveyRiskVisibility,
} from "../../_common/types/survey";
import {
  DefaultThunkDispatchProp,
  IBaseSingleVendorData,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { IVendorContactResponse } from "../../_common/types/vendorContact";
import { surveyType, SurveyUsageType } from "../../_common/types/surveyTypes";
import {
  get as _get,
  toInteger,
  union as _union,
  uniqBy as _uniqBy,
} from "lodash";
import {
  formatDate,
  getCurrentOrgFromUserData,
  vendorUrlPrefix,
} from "../../_common/helpers";
import {
  fetchResendableSurveysForVendor,
  fetchSurveyTypes,
  fetchVendorSummaryAndCloudscans,
  fetchVendorWatchStatus,
} from "../reducers/cyberRiskActions";
import {
  SelectSurveyDueDateStep,
  SelectSurveyStep,
  sendSurveyMode,
} from "../components/modals/SendSurveyModal";
import StepsWithSections, {
  IStep,
  Steps,
} from "../../_common/components/StepsWithSections";
import { InfoBar } from "../../_common/components/InfoBar";
import PageHeader from "../../_common/components/PageHeader";
import Button from "../../_common/components/core/Button";
import ActionBar from "../../_common/components/ActionBar";
import SelectVendorCard, {
  SelectAllVendorsButton,
} from "../components/SelectVendorCard";
import { selectArray } from "../helpers/util";
import "../style/views/CreateQuestionnaireBulk.scss";
import ContactSelector, {
  existingUser,
  getBlankNewContact,
  newContact,
  useNewRecipients,
  validateContactSelector,
} from "../components/ContactSelector";
import ReportCard from "../../_common/components/ReportCard";
import TabButtons, { tabButton } from "../../_common/components/TabButtons";
import SearchBox from "../../_common/components/SearchBox";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import ModalV2 from "../../_common/components/ModalV2";
import TextField from "../../_common/components/TextField";
import {
  BulkCreateQuestionnaireVendorDetails,
  createSurveyBulk,
  reopenSurvey,
  SurveyBulkCreatResp,
} from "../reducers/survey.actions";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import BulkSendQuestionnaireStatusModal from "../components/modals/BulkQuestionnaireStatusModal";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import ReactMarkdown from "react-markdown";
import { getVendorWords } from "../../_common/constants";
import { AssuranceType } from "../../_common/types/organisations";
import { fetchVendorMgtContacts } from "../reducers/vendorContacts.actions";
import {
  DefaultTextType,
  OrgDefaultTexts,
  replaceVariablesInDefaultTexts,
} from "../../_common/types/orgDefaultTexts";
import { fetchOrganisationDefaultTexts } from "../reducers/orgDefaultTexts.actions";
import { getDisplayForQuestionnaireRiskVisibility } from "../components/surveys/QuestionnaireRiskVisibilitySelector";
import RichTextEditV2 from "../../_common/components/RichTextEditV2";
import { appConnect } from "../../_common/types/reduxHooks";
import ManagedVendorsAPI from "../reducers/managedVendorsAPI";
import { skipToken } from "@reduxjs/toolkit/query";
export const TitleCharLimit = 120;

type setter<t> = (val: t) => void;

const getSelectedContactNode = ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => {
  return (
    <div key={email} className={"selected-contact"}>
      <span className={"name"}>{name}</span>
      <span className={"email"}>{` (${email})`}</span>
    </div>
  );
};

interface IReviewStepProps {
  surveyTypeName: string;
  dueDate: string;
  reminderDate?: string;
  resendDate?: string;
  vendorContacts: Record<number, IVendorContactsSelection>;
  vendorNames: Record<number, string>;
  title: string;
  message: string;
  assuranceType: AssuranceType;
  riskVisibility: SurveyRiskVisibility;
}

const ReviewStep = ({
  dueDate,
  message,
  reminderDate,
  resendDate,
  surveyTypeName,
  title,
  vendorContacts,
  vendorNames,
  assuranceType,
  riskVisibility,
}: IReviewStepProps) => {
  const vendorWords = getVendorWords(assuranceType);
  return (
    <ReportCard newStyles className={"review-step section-step"}>
      <div className={"header"}>
        Review & send
        <div className={"header-sub"}>
          Please review this carefully before sending.
        </div>
      </div>
      <div className={"content"}>
        <div className={"review-content grid"}>
          <div className={"label"}>Questionnaire</div>
          <div className={"survey-title"}>{surveyTypeName}</div>
          <div className={"label"}>Risk Visibility</div>
          <div className={"survey-title"}>
            {getDisplayForQuestionnaireRiskVisibility(riskVisibility)}
          </div>
          <div className={"label"}>Due date</div>
          <div className={"date due-date"}>{formatDate(dueDate)}</div>
          {reminderDate && (
            <>
              <div className={"label"}>Reminder</div>
              <div className={"date reminder-date"}>
                {formatDate(reminderDate)}
              </div>
            </>
          )}
          {resendDate && (
            <>
              <div className={"label"}>Resend date</div>
              <div className={"date resend-date"}>{formatDate(resendDate)}</div>
            </>
          )}
          <div className={"label"}>Recipients</div>
          <div className={"recipients-table"}>
            <XTable
              columnHeaders={[
                {
                  id: "vendors",
                  text: vendorWords.pluralTitleCase,
                },
                {
                  id: "recipients",
                  text: "Recipient(s)",
                },
              ]}
              rows={Object.values(vendorContacts).map((vc) => ({
                id: vc.vendorId,
                cells: [
                  <XTableCell key={"vendor"} className={"vendor-name"}>
                    {vendorNames[vc.vendorId]}
                  </XTableCell>,
                  <XTableCell
                    key={"selected-recipients"}
                    className={"selected-recipients-cell"}
                  >
                    {[
                      ...vc.existingContacts
                        .filter((ec) => ec.selected)
                        .map((ec) => getSelectedContactNode(ec)),
                      ...vc.newContacts.map((nc) =>
                        getSelectedContactNode({
                          name: nc.firstName + " " + nc.lastName,
                          email: nc.email,
                        })
                      ),
                    ]}
                  </XTableCell>,
                ],
              }))}
            />
          </div>
          <div className={"label"}>Title</div>
          <div className={"title"}>{title}</div>
          <div className={"label"}>Message</div>
          <div className={"message"}>
            <ReactMarkdown source={message} />
          </div>
        </div>
      </div>
    </ReportCard>
  );
};

interface ITitleAndMessageStepProps {
  title: string;
  message: string;
  setTitle: setter<string>;
  setMessage: setter<string>;
  numSelectedVendors: number;
}

export const TitleAndMessageStep = (props: ITitleAndMessageStepProps) => {
  return (
    <ReportCard newStyles className={"title-and-message section-step"}>
      <div className={"header"}>
        Title & message
        <span className={"header-sub"}>
          The title and message will be visible to recipients when they receive
          the questionnaire.
        </span>
      </div>
      <div className={"content"}>
        <div className={" modal-section-box"}>
          <h3>Add a title</h3>
          <div className={"label"}>
            This title will be visible to you and the recipient(s) on various
            screens in Cyber Risk.
          </div>
          <TextField
            value={props.title}
            onChanged={(val) => props.setTitle(val)}
            minLength={3}
            maxLength={TitleCharLimit}
            placeholder={"Add title"}
          />
          <h3>Add a message</h3>
          <div className={"label"}>
            This message will be included in the email we send asking your
            recipient(s) to complete the questionnaire and shown on the
            questionnaire itself.
          </div>
          <RichTextEditV2
            value={props.message}
            onChange={(v) => props.setMessage(v)}
          />
          <label className={"message-label"}>
            {`The variable {{VendorName}} can be used to personalize ${
              props.numSelectedVendors == 1 ? "your" : "each"
            } message.`}
          </label>
        </div>
      </div>
    </ReportCard>
  );
};

interface IVendorContactsSelection {
  vendorId: number;
  existingContacts: existingUser[];
  newContacts: newContact[];
}

interface IContactSelectorModalProps {
  vendorName: string;
  active: boolean;
  onClose: () => void;
  contactSelection: IVendorContactsSelection;
  setSelection: (selection: IVendorContactsSelection) => void;
}

export const ContactSelectorModal = (props: IContactSelectorModalProps) => {
  const [newContacts, addNewContact, deleteNewContact, updateNewContact] =
    useNewRecipients([
      ...props.contactSelection.newContacts.map((nc) => ({ ...nc })),
    ]);
  const [existingUsers, setExistingUsers] = useState([
    ...props.contactSelection.existingContacts.map((ec) => ({ ...ec })),
  ]);
  const setExistingUserSelected = (email: string, selected: boolean) =>
    setExistingUsers((eUsers) => {
      const users = [...eUsers];
      const idx = users.findIndex((u) => u.email === email);
      if (idx !== -1) {
        users[idx].selected = selected;
      }
      return users;
    });

  const onConfirm = () => {
    props.setSelection({
      ...props.contactSelection,
      existingContacts: existingUsers,
      newContacts,
    });
    props.onClose();
  };

  return (
    <ModalV2
      className={"qn-contact-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={
        <>
          <h2 className={"title"}>{`Add contacts to ${props.vendorName}`}</h2>
          <p className={"sub-title"}>
            Select existing contacts or add new ones.
          </p>
        </>
      }
      footerContent={
        <div className={"btn-group"}>
          <Button tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            disabled={
              !validateContactSelector(existingUsers, newContacts, false)
            }
            arrow
            onClick={onConfirm}
          >
            Add recipients
          </Button>
        </div>
      }
    >
      <ContactSelector
        entityName={"contacts"}
        existingUsers={existingUsers}
        newContacts={newContacts}
        setExistingUserSelected={setExistingUserSelected}
        addNewContact={addNewContact}
        updateNewContact={updateNewContact}
        deleteNewContact={deleteNewContact}
        canDeselectExisting
        selectEmailOnly={false}
        hideDescription
        showTitles
      />
    </ModalV2>
  );
};

interface ISingleVendorRecipientsStepProps {
  vendorContacts: IVendorContactsSelection;
  setVendorContacts: setter<IVendorContactsSelection>;
}

const SingleVendorRecipientStep = ({
  vendorContacts,
  setVendorContacts,
}: ISingleVendorRecipientsStepProps) => {
  return (
    <ReportCard newStyles className={"assign-recipients-single section-step"}>
      <div className={"header"}>
        Assign recipients
        <span className={"header-sub"}>
          Select at least one recipient for this questionnaire.
        </span>
      </div>
      <div className={"content recipients-section"}>
        {vendorContacts ? (
          <ContactSelector
            entityName={"Questionnaire"}
            existingUsers={vendorContacts.existingContacts}
            newContacts={vendorContacts.newContacts}
            setExistingUserSelected={(email, selected) => {
              const users = [...vendorContacts.existingContacts];
              const idx = users.findIndex((u) => u.email === email);
              if (idx !== -1) {
                users[idx].selected = selected;
              }
              setVendorContacts({ ...vendorContacts, existingContacts: users });
            }}
            addNewContact={() => {
              const newContacts = [
                ...vendorContacts.newContacts,
                getBlankNewContact(),
              ];
              setVendorContacts({ ...vendorContacts, newContacts });
            }}
            updateNewContact={(tempId, fields) => {
              const newContacts = [...vendorContacts.newContacts];
              const idx = newContacts.findIndex((nc) => nc.tempId === tempId);
              if (idx !== -1) {
                newContacts[idx] = { ...newContacts[idx], ...fields };
              }
              setVendorContacts({ ...vendorContacts, newContacts });
            }}
            deleteNewContact={(tempId) => {
              const newContacts = [...vendorContacts.newContacts];
              const idx = newContacts.findIndex((nc) => nc.tempId === tempId);
              if (idx !== -1) {
                newContacts.splice(idx, 1);
              }
              setVendorContacts({ ...vendorContacts, newContacts });
            }}
            canDeselectExisting
            selectEmailOnly={false}
          />
        ) : (
          <LoadingIcon />
        )}
      </div>
    </ReportCard>
  );
};

interface IChooseRecipientsStepProps {
  vendorContacts: Record<number, IVendorContactsSelection>;
  vendorNames: Record<number, string>;
  vendorHostnames: Record<number, string>;
  openContactModal: (vendorId: number) => void;
  assuranceType: AssuranceType;
}

const AssignRecipientsStep = ({
  openContactModal,
  vendorContacts,
  vendorNames,
  vendorHostnames,
  assuranceType,
}: IChooseRecipientsStepProps) => {
  const vendorWords = getVendorWords(assuranceType);

  type tabId = "all" | "with" | "without";
  const [selectedTab, setSelectedTab] = useState<tabId>("all");
  const [filterText, setFilterText] = useState("");
  const doSearch = (vendorId: number) => {
    const filterLower = filterText.toLowerCase();
    return (
      vendorNames[vendorId].toLowerCase().includes(filterLower) ||
      vendorHostnames[vendorId].toLowerCase().includes(filterLower)
    );
  };

  const validVendorIds = Object.values(vendorContacts)
    .filter((vc) =>
      validateContactSelector(vc.existingContacts, vc.newContacts, false)
    )
    .map((vc) => vc.vendorId);
  const tabs: tabButton<tabId>[] = [
    {
      id: "all",
      text: `View all (${Object.keys(vendorContacts).length})`,
    },
    {
      id: "with",
      text: `With recipients (${validVendorIds.length})`,
    },
    {
      id: "without",
      text: `Without recipients (${
        Object.keys(vendorContacts).length - validVendorIds.length
      })`,
    },
  ];

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "vendors",
      text: vendorWords.pluralTitleCase,
    },
    {
      id: "website",
      text: "Website",
    },
    {
      id: "recipients",
      text: "Selected recipients",
    },
    {
      id: "hidden",
      text: "",
    },
  ];

  const rows: IXTableRow[] = Object.values(vendorContacts)
    .filter(
      (vc) =>
        (selectedTab === "all" ||
          (selectedTab === "with" && validVendorIds.includes(vc.vendorId)) ||
          (selectedTab === "without" &&
            !validVendorIds.includes(vc.vendorId))) &&
        (filterText === "" || doSearch(vc.vendorId))
    )
    .map((vc) => {
      const selectedContacts = [
        ...vc.existingContacts
          .filter((ec) => ec.selected)
          .map((ec) => getSelectedContactNode(ec)),
        ...vc.newContacts.map((nc) =>
          getSelectedContactNode({
            name: nc.firstName + " " + nc.lastName,
            email: nc.email,
          })
        ),
      ];

      return {
        id: vc.vendorId,
        cells: [
          <XTableCell key={"vendor-name"} className={"vendor-name-cell"}>
            {vendorNames[vc.vendorId]}
          </XTableCell>,
          <XTableCell key={"vendor-website"} className={"vendor-website-cell"}>
            {vendorHostnames[vc.vendorId]}
          </XTableCell>,
          <XTableCell
            key={"selected-recipients"}
            className={"selected-recipients-cell"}
          >
            {selectedContacts}
          </XTableCell>,
          <XTableCell key={"button"} className={"button-cell"}>
            <Button link onClick={() => openContactModal(vc.vendorId)}>
              <span className={"cr-icon-pencil"} />
              Add a new recipient
            </Button>
          </XTableCell>,
        ],
      };
    });

  return (
    <ReportCard newStyles className={"assign-recipients section-step"}>
      <div className={"header"}>
        Assign recipients
        <span className={"header-sub"}>
          Select at least one recipient for each of the {vendorWords.plural} you
          have selected.
        </span>
      </div>
      <div className={"content tab-section"}>
        <TabButtons<tabId>
          onChangeTab={setSelectedTab}
          tabs={tabs}
          activeTabId={selectedTab}
        />
        <SearchBox
          value={filterText}
          onChanged={setFilterText}
          placeholder={"Search for vendor"}
        />
      </div>
      <XTable rows={rows} columnHeaders={columnHeaders} />
      {rows.length === 0 && filterText !== "" && (
        <SearchEmptyCard
          searchString={filterText}
          searchItemText={vendorWords.plural}
          onClear={() => setFilterText("")}
        />
      )}
      {rows.length === 0 && filterText === "" && (
        <EmptyCardWithAction
          emptyText={
            selectedTab === "with"
              ? `Get started by adding recipients to each ${vendorWords.singular}.`
              : `Recipients have been added to all ${vendorWords.plural}. You're now ready to proceed to the next step.`
          }
        />
      )}
    </ReportCard>
  );
};

interface IResendIntroStepProps {
  survey?: ISurveyMini;
  vendorName: string;
  assuranceType: AssuranceType;
}

const ResendIntroStep = ({
  survey,
  vendorName,
  assuranceType,
}: IResendIntroStepProps) => {
  const vendorWords = getVendorWords(assuranceType);
  return (
    <ReportCard newStyles className={"section-step resend-step"}>
      <div className={"header"}>
        Resend questionnaire to {vendorName}
        <div className={"header-sub"}>
          {`Resending a completed questionnaire allows the recipient ${vendorWords.singular} to
          confirm, update or alter their original responses (and supplied
          attachments) to the questions provided. The original responses are
          retained, and can be subsequently compared with the new set of
          responses to the resent questionnaire.`}
        </div>
      </div>
      {!survey ? (
        <LoadingBanner />
      ) : (
        <div className={"content"}>
          <div className="modal-section-box review-resend">
            <label className="top-level-question top-margin">
              Questionnaire details
            </label>
            <div className="review-form">
              <div className="resend-review-field">
                <div>Name</div>
                <div>{survey.name}</div>
              </div>
              <div className="resend-review-field">
                <div>Sent On</div>
                <div>
                  <DateTimeFormat dateTime={survey.dateCreated} dateOnly />
                </div>
              </div>
              {survey.type && (
                <div className="resend-review-field">
                  <div>Type</div>
                  <div>{survey.type}</div>
                </div>
              )}
              {survey.sections.length > 0 &&
                !!survey.sections.find((s) => s.length > 0) && (
                  <div className="resend-review-field">
                    <div>Sections</div>
                    <div className="section-list">
                      {survey.sections.map((s) => {
                        return <div key={`section-${s}`}>{s}</div>;
                      })}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </ReportCard>
  );
};

export interface CreateQuestionnaireBulkLocationState extends locationState {
  resendSurvey?: Pick<ISurveyListItemResponse, "id" | "name">;
  vendorManagementRequestId?: number;
  analystWorkflowQuestionnaireOnly?: boolean;
}

type CreateQuestionnaireBulkRouteProps = DefaultRouteProps<
  { orgId?: string; vendorId?: string },
  CreateQuestionnaireBulkLocationState
>;

interface ICreateQuestionnaireBulkOwnProps
  extends CreateQuestionnaireBulkRouteProps {
  selectedVendorIds: number[];
  setSelectedVendorIds: setter<number[]>;
  canSetVendorIds: boolean;
}

interface ICreateQuestionnaireBulkConnectedProps {
  contacts: Record<number, IVendorContactResponse[]>;
  vendorNames: Record<number, string>;
  vendorHostnames: Record<number, string>;
  resendableSurveys?: ISurveyMini[]; // only defined if there is only one vendor selected
  isManagementAnalystSession: boolean;
  managedOrgId: number;
  customerVendorName?: string;
  assuranceType: AssuranceType;
  allSurveyTypes: surveyType[];
  orgName: string;
  orgDefaultTexts: OrgDefaultTexts;
  surveyRiskVisibility?: SurveyRiskVisibility;
}

type CreateQuestionnaireBulkProps = ICreateQuestionnaireBulkOwnProps &
  ICreateQuestionnaireBulkConnectedProps &
  DefaultThunkDispatchProp;

// Note: location.state parameter **analystWorkflowQuestionnaireOnly**
// When true, this flag indicates that the questionnaire send is being used as part of the managed vendor analyst workflow
// to send a questionnaire to a vendor. Specifically, the component will only allow selection of a questionnaire type for sending
// from those types marked as system_types=MVA. Furthermore, there should only be one of these at any one time in the database
// (a constraint makes this so). Under these circumstances, the view is limited to the selection of this one questionnaire type
// and all other selection is limited (and irrelevant) @see SelectSurveyStep

const CreateQuestionnaireBulk = ({
  canSetVendorIds,
  contacts,
  customerVendorName,
  dispatch,
  history,
  assuranceType,
  isManagementAnalystSession,
  location,
  managedOrgId,
  resendableSurveys,
  selectedVendorIds,
  setSelectedVendorIds,
  allSurveyTypes,
  vendorNames,
  vendorHostnames,
  orgName,
  orgDefaultTexts,
  surveyRiskVisibility,
}: CreateQuestionnaireBulkProps) => {
  //fetch data
  useEffect(() => {
    selectedVendorIds.forEach((id) => {
      dispatch(fetchVendorWatchStatus(id));
      dispatch(fetchVendorMgtContacts(id));
      if (selectedVendorIds.length === 1) {
        dispatch(fetchResendableSurveysForVendor(id));
      }
    });
    dispatch(fetchSurveyTypes("external"));
    dispatch(
      fetchOrganisationDefaultTexts(
        false,
        isManagementAnalystSession,
        managedOrgId
      )
    );
  }, [dispatch, selectedVendorIds, isManagementAnalystSession, managedOrgId]);

  const { data: _managedVendorSurveyTypes } =
    ManagedVendorsAPI.useGetSurveyTypeListForAnalystQuery(
      managedOrgId > 0 ? undefined : skipToken
    );
  const managedVendorSurveyTypes = _managedVendorSurveyTypes?.surveyTypes ?? [];

  const [currentStep, setCurrentStep] = useState(canSetVendorIds ? 1 : 2);
  const [surveyMode, setSurveyMode] = useState<sendSurveyMode>(
    location.state && location.state.resendSurvey ? "resend" : "new"
  );

  const [selectedSurveyTypeIdx, setSelectedSurveyTypeIdx] = useState(
    location.state && location.state.analystWorkflowQuestionnaireOnly ? 0 : -1
  );
  const [selectedSurveySections, setSelectedSurveySections] = useState(
    [] as boolean[]
  );
  const [allSurveySectionsSelected, setAllSurveySectionsSelected] =
    useState(false);
  const [selectedResendableSurveyIdx, setSelectedResendableSurveyIdx] =
    useState(-1);

  const filteredSurveyTypes = () => {
    const filteredTypes = location.state?.analystWorkflowQuestionnaireOnly
      ? managedVendorSurveyTypes
      : allSurveyTypes.filter((t) => {
          return t.usageType === SurveyUsageType.Security;
        });
    return filteredTypes;
  };

  const [surveyTypes, setSurveyTypes] = useState(filteredSurveyTypes());
  useEffect(() => {
    setSurveyTypes(filteredSurveyTypes());
    // make sure the analyst workflow questionnaire is selected from the list by default if required
    if (location.state?.analystWorkflowQuestionnaireOnly) {
      selectNewSurvey(0);
      setAllSurveySectionsSelected(true);
    }
  }, [allSurveyTypes]);

  // if we have been asked to resend though the location state make sure we set that up
  const resending = !!(location.state && location.state.resendSurvey);
  useEffect(() => {
    if (resending && resendableSurveys && resendableSurveys.length > 0) {
      const surveyIdx = resendableSurveys.findIndex(
        (rs) => rs.id === location.state.resendSurvey?.id
      );
      setSelectedResendableSurveyIdx(surveyIdx);
    }
  }, [resending, location, resendableSurveys]);

  const selectSurveyMode = (mode: sendSurveyMode) => {
    if (mode === "resend") {
      setSelectedSurveySections([]);
      setAllSurveySectionsSelected(false);
      setSelectedSurveyTypeIdx(-1);
    } else {
      setSelectedResendableSurveyIdx(-1);
    }
    setSurveyMode(mode);
  };

  const selectNewSurvey = (idx: number) => {
    if (idx > -1 && idx < surveyTypes.length) {
      setSelectedSurveySections(surveyTypes[idx].sections.map(() => false));
      setAllSurveySectionsSelected(true);
      setSelectedSurveyTypeIdx(idx);
    } else if (idx === -1) {
      setAllSurveySectionsSelected(false);
      setSelectedSurveyTypeIdx(idx);
    }
  };

  const [selectedRiskVisibility, setSelectedRiskVisibility] =
    useState(surveyRiskVisibility);
  useEffect(() => {
    if (!!surveyRiskVisibility && !selectedRiskVisibility) {
      setSelectedRiskVisibility(surveyRiskVisibility);
    }
  }, [selectedRiskVisibility, surveyRiskVisibility]);

  const onSelectVendor = (id: number, selected: boolean) => {
    setSelectedVendorIds(selectArray(selectedVendorIds, id, selected));
  };
  const [selectVendorFilterText, setSelectVendorFilterText] = useState("");
  const [selectAllVendorsLoading, setSelectAllVendorsLoading] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [totalVendors, setTotalVendors] = useState(0);

  const [dueDate, setDueDate] = useState<string | undefined>(undefined);
  const [reminderDate, setReminderDate] = useState<string | undefined>(
    undefined
  );
  const [resendDate, setResendDate] = useState<string | undefined>(undefined);

  const [vendorContacts, setVendorContacts] = useState<
    Record<number, IVendorContactsSelection>
  >({});
  useEffect(
    () =>
      setVendorContacts((oldVendorContacts) => {
        const newContacts = { ...oldVendorContacts };

        Object.entries(contacts).forEach(([id, thisVendorContacts]) => {
          const vendorId = toInteger(id);
          if (!newContacts[vendorId] && thisVendorContacts) {
            newContacts[vendorId] = {
              vendorId,
              newContacts: [],
              existingContacts: _uniqBy(
                thisVendorContacts,
                (c) => c.emailAddress
              ).map((c, i) => ({
                name: c.name ?? "",
                email: c.emailAddress ?? "",
                alreadyAdded: false,
                isInvite: false,
                selected: i === 0, // select the first contact by default
              })),
            };
          }
        });

        Object.keys(newContacts).forEach((id) => {
          const vendorId = toInteger(id);
          if (!contacts[vendorId]) {
            delete newContacts[vendorId];
          }
        });

        return newContacts;
      }),
    [contacts]
  );

  const [contactsModalOpen, setContactsModalOpen] = useState(false);
  const [editingContactVendorId, setEditingContactVendorId] = useState(0);
  const onOpenVendorContacts = (vendorId: number) => {
    setEditingContactVendorId(vendorId);
    setContactsModalOpen(true);
  };

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (selectedSurveyTypeIdx === -1 && selectedResendableSurveyIdx === -1) {
      return;
    }
    let defaultTitle =
      orgDefaultTexts[DefaultTextType.QuestionnaireTitle]?.defaultText || "";
    let defaultEmailMessage =
      orgDefaultTexts[DefaultTextType.QuestionnaireMessage]?.defaultText || "";

    let surveyName = "";
    if (
      resending &&
      location.state.resendSurvey &&
      location.state.resendSurvey.name
    ) {
      surveyName = location.state.resendSurvey.name;
    } else if (selectedSurveyTypeIdx in surveyTypes) {
      surveyName =
        surveyTypes[selectedSurveyTypeIdx].overrideName ??
        surveyTypes[selectedSurveyTypeIdx].name;
    } else if (
      resendableSurveys &&
      selectedResendableSurveyIdx in resendableSurveys
    ) {
      surveyName = resendableSurveys[selectedResendableSurveyIdx].name;
    }

    [defaultTitle, defaultEmailMessage] = replaceVariablesInDefaultTexts(
      [defaultTitle, defaultEmailMessage],
      {
        "{{AccountName}}": orgName,
        "{{QuestionnaireType}}": surveyName,
      }
    );

    setTitle(defaultTitle);
    setMessage(defaultEmailMessage);
  }, [
    selectedSurveyTypeIdx,
    selectedResendableSurveyIdx,
    orgDefaultTexts,
    resending,
    location,
    surveyTypes,
    resendableSurveys,
    orgName,
  ]);

  const vendorWords = getVendorWords(assuranceType);

  const canGoStep = (step: number): boolean => {
    switch (step) {
      case 1:
        return true;
      case 2:
        return selectedVendorIds.length > 0;
      case 3:
        return (
          (surveyMode === "new" &&
            selectedSurveyTypeIdx >= 0 &&
            allSurveySectionsSelected) ||
          selectedSurveySections.some((s) => s) ||
          (surveyMode === "resend" && selectedResendableSurveyIdx >= 0) ||
          resending
        );
      case 4:
        return (
          !!dueDate &&
          (reminderDate == undefined || !!reminderDate) &&
          (resendDate == undefined || !!resendDate)
        );
      case 5:
        return (
          canGoStep(4) &&
          Object.values(vendorContacts).every((vc) =>
            validateContactSelector(vc.existingContacts, vc.newContacts, false)
          )
        );
      case 6:
        return canGoStep(5) && title !== "" && message !== "​"; // the rich text edit encodes an empty message as a zero width space
      case 7:
        return true;
      default:
        return false;
    }
  };

  const steps: IStep[] = [
    {
      id: "vendors",
      text: `Select ${vendorWords.plural}`,
      onClick: () => setCurrentStep(1),
      disabled: !canGoStep(1),
      hidden: !canSetVendorIds,
    },
    {
      id: "type",
      text: resending ? "Confirm details" : "Select type",
      disabled: !canGoStep(2),
      onClick: () => setCurrentStep(2),
    },
    {
      id: "schedule",
      text: "Select schedule",
      disabled: !canGoStep(3),
      onClick: () => setCurrentStep(3),
    },
    {
      id: "recipient",
      text: "Assign recipients",
      disabled: !canGoStep(4),
      onClick: () => setCurrentStep(4),
    },
    {
      id: "title",
      text: "Title & message",
      disabled: !canGoStep(5),
      onClick: () => setCurrentStep(5),
    },
    {
      id: "send",
      text: "Review & send",
      disabled: !canGoStep(6),
      onClick: () => setCurrentStep(6),
    },
  ];

  const goNext = () => {
    if (currentStep === steps.length) {
      submitSurvey();
    } else {
      setCurrentStep((step) => step + 1);
    }
  };

  const backTo = () => {
    if (location.state?.backContext?.goBack) {
      history.goBack();
    } else if (location.state && location.state.backContext) {
      history.push(
        location.state.backContext.backTo ?? "",
        location.state.backContext.backToContext
      );
    } else {
      if (isManagementAnalystSession) {
        history.push(`/analysts/tpvm/${managedOrgId}/surveys`);
      }
      history.push("/surveys");
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [submitResult, setSubmitResult] = useState<
    undefined | SurveyBulkCreatResp
  >(undefined);

  const submitSurvey = async () => {
    setSubmitting(true);
    const useModal = selectedVendorIds.length > 1;
    setSubmitModalOpen(useModal);

    try {
      let prom = Promise.resolve();
      if (surveyMode === "resend") {
        if (!resendableSurveys) {
          dispatch(addDefaultUnknownErrorAlert("error reopening survey"));
          return;
        }
        // if we are resending then there must only be one vendor/set of contacts selected
        const vendorId = selectedVendorIds[0];
        const toEmails = [
          ...vendorContacts[vendorId].newContacts.map((r) => r.email),
          ...vendorContacts[vendorId].existingContacts
            .filter((e) => e.selected)
            .map((e) => e.email),
        ];
        const toFirstNames = [
          ...vendorContacts[vendorId].newContacts.map((r) => r.firstName),
          ...vendorContacts[vendorId].existingContacts
            .filter((e) => e.selected)
            .map((e) => e.name.split(" ")[0]),
        ];

        prom = prom.then(() =>
          dispatch(
            reopenSurvey(
              resendableSurveys[selectedResendableSurveyIdx].id,
              message,
              true,
              toEmails,
              toFirstNames,
              dueDate,
              reminderDate
            )
          ).then(() => {
            // Navigate to re-opened survey
            history.push(
              `${vendorUrlPrefix(
                selectedVendorIds[0],
                isManagementAnalystSession,
                managedOrgId
              )}/surveys/${resendableSurveys[selectedResendableSurveyIdx].id}`,
              {
                noRemoveWhispers: true,
              } as locationState
            );
          })
        );
      } else {
        prom = dispatch(
          createSurveyBulk({
            surveyName: title,
            emailText: message,
            typeId: surveyTypes[selectedSurveyTypeIdx].id,
            sections: surveyTypes[selectedSurveyTypeIdx].sections
              .filter(
                (_, i) => allSurveySectionsSelected || selectedSurveySections[i]
              )
              .map((s) => s.id),
            customerVendorName: customerVendorName ?? "",
            dueDate: dueDate ?? "",
            recipientReminderDate: reminderDate,
            resendDate: resendDate,
            vendors: Object.values(vendorContacts).reduce(
              (obj: BulkCreateQuestionnaireVendorDetails, vc) => {
                const vcs = [
                  ...vc.newContacts.map((nc) => ({
                    name: nc.firstName + " " + nc.lastName,
                    email: nc.email,
                  })),
                  ...vc.existingContacts
                    .filter((ec) => ec.selected)
                    .map((ec) => ({
                      name: ec.name,
                      email: ec.email,
                    })),
                ];

                obj[vc.vendorId] = {
                  vendorContacts: vcs,
                };

                return obj;
              },
              {}
            ),
            riskVisibility:
              selectedRiskVisibility ?? SurveyRiskVisibility.Visible,
            vendorManagementRequestId:
              location.state?.vendorManagementRequestId ?? undefined,
          })
        )
          .then((resp) => {
            setSubmitResult(resp);
            if (!useModal) {
              // if we haven't used the modal (ie single vendor) then navigate to the details page
              dispatch(
                addDefaultSuccessAlert("Questionnaire sent successfully")
              );

              const surveyId = Object.values(resp.successful).pop();
              history.push(
                `${vendorUrlPrefix(
                  selectedVendorIds[0],
                  isManagementAnalystSession,
                  managedOrgId
                )}/surveys/${surveyId}`,
                {
                  noRemoveWhispers: true,
                } as locationState
              );
            }
          })
          .then(() => {
            if (!vendorContacts) return;
            const vendorIDs = Object.keys(vendorContacts);
            if (vendorIDs.length === 1) {
              dispatch(
                fetchVendorSummaryAndCloudscans(parseInt(vendorIDs[0]), true)
              );
            }
          });
      }

      await prom;
    } catch (e) {
      setSubmitModalOpen(false);
      dispatch(
        addDefaultUnknownErrorAlert(
          `Error sending ${
            selectedVendorIds.length === 1 ? "questionnaire" : "questionnaires"
          }`
        )
      );
    }
    setSubmitting(false);
  };

  const surveyTypeName =
    selectedSurveyTypeIdx in surveyTypes
      ? surveyTypes[selectedSurveyTypeIdx].overrideName ??
        surveyTypes[selectedSurveyTypeIdx].name
      : resendableSurveys && selectedResendableSurveyIdx in resendableSurveys
        ? resendableSurveys[selectedResendableSurveyIdx].name
        : "";

  return (
    <StepsWithSections id={"create_questionnaire_bulk"}>
      {isManagementAnalystSession && (
        <InfoBar
          message={
            "You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)"
          }
        />
      )}
      <PageHeader
        history={history}
        title={resending ? "Resend questionnaire" : "Send questionnaires"}
        vendorId={
          !canSetVendorIds && selectedVendorIds.length === 1
            ? selectedVendorIds[0]
            : undefined
        }
        backAction={backTo}
        backText={
          location.state && location.state.backContext
            ? location.state.backContext.backToText
            : "Back"
        }
        isManagementAnalystSession={isManagementAnalystSession}
        managedOrgId={managedOrgId}
      />
      <Steps steps={steps} currentStep={currentStep} />
      <div className={`section step-${currentStep}`}>
        <SelectVendorCard
          allowMultiSelect
          selectedVendorIDs={selectedVendorIds}
          onSelectVendor={onSelectVendor}
          onClearSelection={() => setSelectedVendorIds([])}
          isVisible={currentStep === 1}
          className={"section-step"}
          setFilterText={setSelectVendorFilterText}
          filterText={selectVendorFilterText}
          loading={selectAllVendorsLoading}
          setFiltersActive={setFiltersActive}
          setTotalVendors={setTotalVendors}
          writablePortfoliosOnly
        />
        {currentStep === 2 && (
          <>
            {resending ? (
              <ResendIntroStep
                survey={
                  resendableSurveys &&
                  selectedResendableSurveyIdx in resendableSurveys
                    ? resendableSurveys[selectedResendableSurveyIdx]
                    : undefined
                }
                vendorName={vendorNames[selectedVendorIds[0]]}
                assuranceType={assuranceType}
              />
            ) : (
              <ReportCard
                newStyles
                className={"select-survey-card section-step"}
              >
                <div className={"header"}>Select questionnaire type</div>
                <div className={"content"}>
                  <SelectSurveyStep
                    surveyMode={surveyMode}
                    setSurveyMode={selectSurveyMode}
                    surveyTypes={surveyTypes}
                    selectedSurveyType={selectedSurveyTypeIdx}
                    setSelectedSurveyType={selectNewSurvey}
                    selectedSurveySections={selectedSurveySections}
                    toggleSelectedSurveySections={(idx) =>
                      setSelectedSurveySections((s) => {
                        const result = [...s];
                        result[idx] = !result[idx];
                        return result;
                      })
                    }
                    allSurveySectionsSelected={allSurveySectionsSelected}
                    toggleAllSurveySectionsSelected={() =>
                      setAllSurveySectionsSelected((s) => !s)
                    }
                    isManagementAnalystSession={isManagementAnalystSession}
                    resendableSurveys={resendableSurveys ?? []}
                    selectedResendableSurvey={selectedResendableSurveyIdx}
                    setSelectedResenableSurvey={setSelectedResendableSurveyIdx}
                    history={history}
                    managedOrgId={managedOrgId}
                    isMulti={selectedVendorIds.length > 1}
                    riskVisibility={
                      selectedRiskVisibility ?? SurveyRiskVisibility.Visible
                    }
                    setRiskVisibility={setSelectedRiskVisibility}
                    analystWorkflowQuestionnaireOnly={
                      location.state?.analystWorkflowQuestionnaireOnly
                    }
                  />
                </div>
              </ReportCard>
            )}
          </>
        )}
        {currentStep === 3 && (
          <ReportCard newStyles className={"schedule-card section-step"}>
            <div className={"header"}>
              Select schedule
              <span className={"header-sub"}>
                Let the vendor know when the questionnaire should be completed
                by. Optionally, you can set reminders to resend the
                questionnaire.
              </span>
            </div>
            <div className={"content"}>
              <SelectSurveyDueDateStep
                setDueDate={setDueDate}
                setReminderDate={setReminderDate}
                setResendDate={setResendDate}
                dueDate={dueDate}
                reminderDate={reminderDate}
                resendDate={resendDate}
              />
            </div>
          </ReportCard>
        )}
        {currentStep === 4 && (
          <>
            {selectedVendorIds.length === 1 ? (
              <SingleVendorRecipientStep
                vendorContacts={vendorContacts[selectedVendorIds[0]]}
                setVendorContacts={(val) =>
                  setVendorContacts((vc) => ({
                    ...vc,
                    [selectedVendorIds[0]]: val,
                  }))
                }
              />
            ) : (
              <AssignRecipientsStep
                vendorContacts={vendorContacts}
                vendorNames={vendorNames}
                vendorHostnames={vendorHostnames}
                openContactModal={onOpenVendorContacts}
                assuranceType={assuranceType}
              />
            )}
          </>
        )}
        {currentStep === 5 && (
          <TitleAndMessageStep
            title={title}
            message={message}
            setTitle={setTitle}
            setMessage={setMessage}
            numSelectedVendors={selectedVendorIds.length}
          />
        )}
        {currentStep === 6 && (
          <ReviewStep
            surveyTypeName={surveyTypeName}
            dueDate={dueDate ?? ""}
            resendDate={resendDate}
            reminderDate={reminderDate}
            vendorContacts={vendorContacts}
            vendorNames={vendorNames}
            title={title}
            message={message}
            assuranceType={assuranceType}
            riskVisibility={
              selectedRiskVisibility ?? SurveyRiskVisibility.Visible
            }
          />
        )}
      </div>
      <ActionBar active className={"send-questionnaires-bar"}>
        {((canSetVendorIds && currentStep !== 1) ||
          (!canSetVendorIds && currentStep !== 2)) && (
          <Button
            className={"back-button"}
            onClick={() => setCurrentStep((step) => step - 1)}
            disabled={submitting}
            leftArrow
          >
            Previous
          </Button>
        )}
        <div className={"vendors-div"}>
          {((currentStep === 1 && selectedVendorIds.length > 0) ||
            selectedVendorIds.length > 1) && (
            <span className={"grey"}>{`${selectedVendorIds.length} ${
              selectedVendorIds.length === 1
                ? vendorWords.singular
                : vendorWords.plural
            } selected`}</span>
          )}
          {currentStep === 1 && filtersActive && totalVendors > 0 && (
            <SelectAllVendorsButton
              onVendorsSelected={(ids) =>
                setSelectedVendorIds(_union(selectedVendorIds, ids))
              }
              setLoading={setSelectAllVendorsLoading}
              filterText={selectVendorFilterText}
              dispatch={dispatch}
              link
              className={"vendor-select-all-btn"}
            >{`Select all ${totalVendors} ${vendorWords.plural} that match the current filter`}</SelectAllVendorsButton>
          )}
        </div>
        <div className={"btn-group"}>
          <Button tertiary onClick={backTo} disabled={submitting}>
            Cancel
          </Button>
          <Button
            primary
            onClick={goNext}
            arrow={currentStep < steps.length}
            disabled={!canGoStep(currentStep + 1)}
            loading={submitting}
          >
            {currentStep === steps.length
              ? `Send questionnaire${selectedVendorIds.length > 1 ? "s" : ""}`
              : "Next"}
          </Button>
        </div>
      </ActionBar>
      {contactsModalOpen && editingContactVendorId !== 0 && (
        <ContactSelectorModal
          vendorName={vendorNames[editingContactVendorId]}
          active
          onClose={() => setContactsModalOpen(false)}
          contactSelection={vendorContacts[editingContactVendorId]}
          setSelection={(selection) =>
            setVendorContacts((c) => ({
              ...c,
              [editingContactVendorId]: selection,
            }))
          }
        />
      )}
      <BulkSendQuestionnaireStatusModal
        active={submitModalOpen}
        onClose={() => history.push(`/surveys/`)}
        vendorNames={vendorNames}
        result={submitResult}
      />
    </StepsWithSections>
  );
};

const ConnectedCreateQuestionnaireBulk = appConnect<
  ICreateQuestionnaireBulkConnectedProps,
  undefined,
  ICreateQuestionnaireBulkOwnProps
>((state: DefaultRootState, props) => {
  let surveyTypes: surveyType[];
  let surveyRiskVisibility: SurveyRiskVisibility | undefined;

  const { orgId } = props.match.params;

  const userIsManagedVendorAnalyst =
    state.common.userData.system_roles &&
    state.common.userData.system_roles.includes("VendorManagementAnalyst");

  const isManagementAnalystSession =
    userIsManagedVendorAnalyst && props.match.path.startsWith("/analysts/tpvm");

  const managedOrgId =
    isManagementAnalystSession && !!orgId ? parseInt(orgId, 10) : 0;

  let customerVendorName;
  let vendorObjs: Record<number, IBaseSingleVendorData | undefined>;
  let orgName;
  let orgDefaultTexts;
  if (isManagementAnalystSession && managedOrgId > 0) {
    surveyTypes = _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgId}].surveyTypes.external`,
      []
    );
    vendorObjs = _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgId}]`,
      {}
    );
    customerVendorName = _get(
      state.cyberRisk.managedVendorData[managedOrgId],
      "name",
      ""
    );
    orgName = state.cyberRisk.managedVendorData[managedOrgId]?.name ?? "";
    orgDefaultTexts =
      state.cyberRisk.managedVendorData[managedOrgId]?.orgDefaultTexts ?? {};
    surveyRiskVisibility =
      state.cyberRisk.managedVendorData[managedOrgId].surveyRiskVisibility;
  } else {
    surveyTypes = _get(
      state.cyberRisk.customerData,
      "surveyTypes.external",
      []
    );
    vendorObjs = state.cyberRisk.vendors;
    const currentOrg = getCurrentOrgFromUserData(state.common.userData);
    customerVendorName = currentOrg ? currentOrg.name : "";
    orgName = currentOrg?.name ?? "";
    orgDefaultTexts = state.cyberRisk.orgDefaultTexts ?? {};
    surveyRiskVisibility = state.cyberRisk.customerData.surveyRiskVisibility;
  }

  const vendorContacts: Record<number, IVendorContactResponse[]> = {};
  const vendorNames: Record<number, string> = {};
  const vendorHostnames: Record<number, string> = {};
  let resendableSurveys;

  props.selectedVendorIds.forEach((vendorId) => {
    vendorContacts[vendorId] = _get(
      vendorObjs,
      `[${vendorId}].mgtlists.contacts.result`,
      null
    );
    vendorNames[vendorId] = _get(vendorObjs, `[${vendorId}].display_name`, "");
    vendorHostnames[vendorId] = _get(
      vendorObjs,
      `[${vendorId}].primary_hostname`,
      ""
    );
    resendableSurveys = _get(vendorObjs, `[${vendorId}].resendableSurveys`, []);
  });

  return {
    allSurveyTypes: surveyTypes,
    vendorNames,
    vendorHostnames,
    contacts: vendorContacts,
    resendableSurveys:
      props.selectedVendorIds.length === 1 ? resendableSurveys : undefined,
    assuranceType: state.common.userData.assuranceType,
    isManagementAnalystSession,
    managedOrgId,
    customerVendorName,
    orgName,
    orgDefaultTexts,
    surveyRiskVisibility,
  } as ICreateQuestionnaireBulkConnectedProps;
})(CreateQuestionnaireBulk);

const CreateQuestionnaireBulkWrapper = (
  props: CreateQuestionnaireBulkRouteProps
) => {
  const { vendorId } = props.match.params;
  const [selectedVendorIds, setSelectedVendorIds] = useState<number[]>(
    vendorId ? [parseInt(vendorId)] : []
  );
  return (
    <ConnectedCreateQuestionnaireBulk
      selectedVendorIds={selectedVendorIds}
      setSelectedVendorIds={setSelectedVendorIds}
      canSetVendorIds={!vendorId}
      history={props.history}
      match={props.match}
      location={props.location}
    />
  );
};

export default CreateQuestionnaireBulkWrapper;
