import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import React, { FC, useCallback, useState } from "react";
import VendorAssessmentAPI from "../../reducers/vendorAssessmentAPI";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import Button from "../../../_common/components/core/Button";
import TextField, {
  useTextWithValid,
} from "../../../_common/components/TextField";
import ColorCheckbox from "../ColorCheckbox";
import TextAreaWithPills from "../../../_common/components/TextAreaWithPills";
import { useDefaultHistory } from "../../../_common/types/router";
import "../../style/components/vendor_assessment/NewAssessmentStreamModal.scss";
import { produce } from "immer";
import classnames from "classnames";
import { useVendorWords } from "../../../_common/hooks";
import { getColorForKey } from "../PillLabel";

export type scopeType = "whole" | "scoped" | undefined;
interface ScopeSelectionProps {
  scopeType: scopeType;
  setScopeType: React.Dispatch<React.SetStateAction<scopeType>>;
  scope: string[];
  setScope: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ScopeSelection: FC<ScopeSelectionProps> = ({
  scope,
  scopeType,
  setScope,
  setScopeType,
}) => {
  const vendorWords = useVendorWords();

  return (
    <div className={"scope-selection"}>
      <div className={"field-label"}>Define scope</div>
      <div
        className={classnames("input-with-icon", {
          selected: scopeType == "whole",
        })}
        onClick={() => setScopeType("whole")}
      >
        <ColorCheckbox
          radio
          checked={scopeType == "whole"}
          onClick={() => setScopeType("whole")}
          label={
            <div className={"selection-label"}>
              <i className={"cr-icon-full-assessment"} />
              <div className={"text-label"}>
                <div className={"label-top"}>
                  <div className={"label-title"}>
                    Whole {vendorWords.singular}
                  </div>
                  {scopeType == "whole" && (
                    <div className={"label-right"}>
                      best for high-level assessments
                    </div>
                  )}
                </div>
                <div className={"sub"}>
                  Assess the entire {vendorWords.singular}.
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div
        className={classnames("input-with-icon", {
          selected: scopeType == "scoped",
        })}
        onClick={() => setScopeType("scoped")}
      >
        <ColorCheckbox
          radio
          checked={scopeType == "scoped"}
          onClick={() => setScopeType("scoped")}
          label={
            <div className={"selection-label"}>
              <i className={"cr-icon-partial-assessment"} />
              <div className={"text-label"}>
                <div className={"label-top"}>
                  <div className={"label-title"}>
                    Part of a {vendorWords.singular}
                  </div>
                  {scopeType == "scoped" && (
                    <div className={"label-right"}>
                      best for products or services
                    </div>
                  )}
                </div>
                <div className={"sub"}>
                  Limits the assessment to specific products or services,
                  regions, teams or custom groups your organization has.
                </div>
              </div>
            </div>
          }
        />
      </div>
      {scopeType == "scoped" && (
        <div className={"tag-selection"}>
          <div className={"field-label"}>Add tags</div>
          <div className={"desc"}>
            Use tags to describe the scope of the assessment. These can help you
            filter and sort your results to find assessments in the future.
          </div>
          <TextAreaWithPills
            delimiters={[","]}
            onNewTokens={(tokens) =>
              setScope((prev) =>
                produce(prev, (draft) => {
                  tokens.forEach((t) => {
                    if (t != "" && !draft.includes(t)) {
                      draft.push(t);
                    }
                  });
                })
              )
            }
            onRemovedToken={(idx) =>
              setScope((prev) => {
                const newScope = [...prev];
                newScope.splice(idx, 1);
                return newScope;
              })
            }
            tokens={scope.map((s) => ({
              value: s,
              color: getColorForKey(s),
            }))}
          />
          <div className={"instructions"}>Separate each tag with a comma.</div>
        </div>
      )}
    </div>
  );
};

interface NewAssessmentStreamModalProps extends BaseModalProps {
  vendorName: string;
  vendorId: number;
  urlPrefix: string;
  goToNewAssessment?: boolean;
  initialName?: string;
  initialScope?: string[];
  copyStreamId?: number;
  managedAssessmentId?: number;
}

const NewAssessmentStreamModal: FC<NewAssessmentStreamModalProps> = (props) => {
  const [title, titleValid, setTitle] = useTextWithValid(
    props.initialName ?? `${props.vendorName} Risk Assessment`.slice(0, 200),
    true
  );
  const [scopeSelection, setScopeSelection] = useState<scopeType>(
    props.copyStreamId
      ? props.initialScope && props.initialScope.length > 0
        ? "scoped"
        : "whole"
      : undefined
  );
  const [scope, setScope] = useState<string[]>(props.initialScope ?? []);

  const [newStreamMutation] =
    VendorAssessmentAPI.useCreateNewVendorAssessmentStreamMutation();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const history = useDefaultHistory();

  const onNewAssessment = useCallback(() => {
    setLoading(true);
    newStreamMutation({
      vendorId: props.vendorId,
      scope: scopeSelection == "scoped" ? scope : undefined,
      assessmentName: title,
      copyStreamId: props.copyStreamId,
      managedAssessmentId: props.managedAssessmentId,
    })
      .unwrap()
      .then((data): void => {
        dispatch(
          addDefaultSuccessAlert(
            props.copyStreamId
              ? "Your risk assessment has been copied"
              : "Successfully created new risk assessment"
          )
        );
        props.onClose();
        if (props.goToNewAssessment) {
          history.push(`${props.urlPrefix}/assessment/${data.newStream.id}`, {
            backContext: {
              goBack: true,
              backToText: "Back to risk assessments",
            },
          });
        }
      })
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert("Error creating new risk assessment")
        );
      })
      .finally(() => setLoading(false));
  }, [
    title,
    scopeSelection,
    scope,
    newStreamMutation,
    props.copyStreamId,
    props.managedAssessmentId,
  ]);

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      className={"new-assessment-stream-modal"}
      headerContent={
        props.copyStreamId
          ? "Copy this risk assessment"
          : "Create new assessment"
      }
      subHeaderContent={
        props.copyStreamId
          ? "A duplicate risk assessment will be created with the same content and scope."
          : undefined
      }
      footerContent={
        <div>
          <Button tertiary onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            primary
            onClick={onNewAssessment}
            disabled={
              !titleValid ||
              scopeSelection == undefined ||
              (scopeSelection == "scoped" && scope.length == 0)
            }
            loading={loading}
            arrow={!props.copyStreamId}
          >
            {props.copyStreamId ? "Confirm" : "Continue"}
          </Button>
        </div>
      }
    >
      <div className={"title"}>
        <div className={"field-label"}>Title</div>
        <TextField
          value={title}
          onChanged={setTitle}
          minLength={2}
          maxLength={200}
          required
        />
      </div>
      <ScopeSelection
        scope={scope}
        scopeType={scopeSelection}
        setScope={setScope}
        setScopeType={setScopeSelection}
      />
    </ModalV2>
  );
};

export default NewAssessmentStreamModal;
