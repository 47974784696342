import { LabelColor } from "../../_common/types/label";
import { ScopeRiskLevel } from "../api/types";

export enum RiskLevelColor {
  Basic = "#CCCFE0",
  Elevated = "#F15F5F",
}

export const riskLevelColor = (riskLevel: ScopeRiskLevel) => {
  return isElevatedRiskLevel(riskLevel)
    ? RiskLevelColor.Elevated
    : RiskLevelColor.Basic;
};

export const isElevatedRiskLevel = (riskLevel: ScopeRiskLevel) =>
  riskLevel >= ScopeRiskLevel.MediumScopeRiskLevel;

export const ElevatedRiskLevelString = "Elevated";
export const BasicRiskLevelString = "Basic";

export const riskLevelString = (riskLevel: ScopeRiskLevel) =>
  isElevatedRiskLevel(riskLevel)
    ? ElevatedRiskLevelString
    : BasicRiskLevelString;

export function getPermissionCategoryDisplayInformation(
  str: string | null | undefined
): {
  displayString: string;
  color: LabelColor;
} {
  if (!str) {
    return { displayString: "Unknown", color: LabelColor.Grey };
  }

  // Not using getUserBaseColorForKey(str) as we are going with Grey for now
  return { displayString: str, color: LabelColor.Grey };
}

export function getPermissionReadWriteDisplayInformation(
  rw: boolean | null | undefined
): {
  displayString: string;
  color: LabelColor;
} {
  if (rw === null) {
    return { displayString: "Unknown", color: LabelColor.Grey };
  }

  if (rw === false) {
    return { displayString: "Read Only", color: LabelColor.Grey };
  }

  return { displayString: "Read/Write", color: LabelColor.Red };
}
