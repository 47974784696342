import { FC } from "react";
import ReportCard from "../../../_common/components/ReportCard";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import Button from "../../../_common/components/core/Button";
import {
  permissionSummaryUrl,
  permissionUsersUrl,
} from "../../UserBaseAppRouter";
import { useDefaultHistory } from "../../../_common/types/router";
import userbaseApi from "../../api/userbase.api";
import GradedUsersOverview from "../users/GradedUsersOverview";
import "./PermissionsUsersOverview.scss";

interface PermissionUsersOverviewProps {
  scopeExternalId: string;
}

const PermissionUsersOverview: FC<PermissionUsersOverviewProps> = ({
  scopeExternalId,
}) => {
  const history = useDefaultHistory();

  // get the data
  const { data, isLoading } = userbaseApi.useGetUserBaseScopeSummaryV1Query({
    scopeExternalId,
  });
  const permission = data?.scope;

  const onViewUsers = () => {
    history.push(permissionUsersUrl(scopeExternalId), {
      backContext: {
        backTo: permissionSummaryUrl(scopeExternalId),
        backToText: `Back to summary`,
      },
    });
  };
  return (
    <ReportCard newStyles className="userbase-permission-users-overview">
      <div className="header">
        <span>Users</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewUsers}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>

      {isLoading && <LoadingBanner />}

      {!isLoading && permission && (
        <div className={"content"}>
          <GradedUsersOverview
            userUUIDs={permission.userUUIDs}
            onClick={onViewUsers}
          />
        </div>
      )}
    </ReportCard>
  );
};

export default PermissionUsersOverview;
