import Button from "../../../_common/components/core/Button";
import { appConnect } from "../../../_common/types/reduxHooks";
import { FC } from "react";
import {
  useGenerateRiskAssessmentReport,
  IGenerateReportProps,
  IGenerateReportConnectProps,
  IGenerateReportOwnProps,
} from "./hooks/useGenerateRiskAssessmentReport";

const GenerateDraftReportButton: FC<IGenerateReportProps> = (
  props: IGenerateReportProps
) => {
  const generateReportCallback = useGenerateRiskAssessmentReport(props);

  return (
    <Button primary={false} onClick={generateReportCallback}>
      <i className={"cr-icon-export"} />
      Generate draft report
    </Button>
  );
};

export default appConnect<
  IGenerateReportConnectProps,
  never,
  IGenerateReportOwnProps
>((state, _) => {
  const connectedProps: IGenerateReportConnectProps = {
    state: state,
  };
  return connectedProps;
})(GenerateDraftReportButton);
