import ModalV2 from "../../../_common/components/ModalV2";
import {
  FC,
  ReactNode,
  Ref,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "../../../_common/types/reduxHooks";
// @ts-ignore
import Coffee from "../../images/coffeecup-animated.gif";
import FilledIcon from "../../../_common/components/core/FilledIcon";
import ProgressBar from "../../../_common/components/ProgressBar";
import Button from "../../../_common/components/core/Button";
import "../../style/components/modals/AutofillProcessModal.scss";
import {
  findNodeByID,
  getNodeTitleDisplay,
  NodeSummaryAndNode,
} from "../../surveyViewer.helpers";
import SurveyViewerItemIcon from "../SurveyViewerItemIcon";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { NodeTypeIconType } from "../../../survey_builder/components/NodeTypeIcon";
import FullPageConfetti from "../../../_common/components/FullPageConfetti";
import { useHistory } from "react-router-dom";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import classNames from "classnames";

interface QuestionBoxProps {
  node: NodeSummaryAndNode;
  ref?: Ref<HTMLDivElement>;
}

const QuestionBox: FC<QuestionBoxProps> = ({ node, ref }) => {
  const nodeName = getNodeTitleDisplay(node, true, true);

  return (
    <div ref={ref} className={"question-box"}>
      <SurveyViewerItemIcon nodeSummary={node} />
      <div
        className={"node-name"}
        dangerouslySetInnerHTML={{ __html: nodeName }}
      />
      <PillLabel color={LabelColor.Blue}>
        Suggestion found <i className={"cr-icon-check"} />{" "}
      </PillLabel>
    </div>
  );
};

interface AutofillProcessModalProps {
  active: boolean;
  onExitAutofillLoading: () => void;
}

const AutofillProcessModal: FC<AutofillProcessModalProps> = ({
  active,
  onExitAutofillLoading,
}) => {
  const history = useHistory();

  const cacheStatus = useAppSelector((state) => state.surveyViewer.gptAutofill);

  const latestSuggestionIDs = useAppSelector(
    (state) => state.surveyViewer.latestGptSuggestions
  );

  const nodeTree = useAppSelector((state) => state.surveyViewer.nodeTree);
  const rootNodeAnswerState = useAppSelector((state) =>
    nodeTree
      ? state.surveyViewer.nodeChildrenAnswered[nodeTree.nodeId]
      : undefined
  );
  const nodeVisibilities = useAppSelector(
    (state) => state.surveyViewer.nodeVisibilities
  );

  const firstNodeRef = useRef<HTMLDivElement>(null);

  // as new nodes come in we want to cache the old ones and just move the new ones in
  const [nodeArray, setNodeArray] = useState<ReactNode[]>([]);
  useEffect(() => {
    setNodeArray((prev) => [
      ...prev,
      ...(latestSuggestionIDs?.map((id, i) => {
        if (!nodeTree) {
          return undefined;
        }

        const qnNode = findNodeByID(nodeTree, id);
        if (!qnNode || qnNode.nodeType == NodeTypeIconType.Risk) {
          return undefined;
        }

        // Hide suggestions for questions that are not currently visible
        if (!nodeVisibilities[id]) {
          return undefined;
        }

        return (
          <QuestionBox
            node={qnNode}
            key={id}
            ref={i == 0 ? firstNodeRef : undefined}
          />
        );
      }) ?? []),
    ]);
  }, [latestSuggestionIDs, nodeTree, nodeVisibilities]);

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const nodeAreaRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const firstNodeHeight =
      scrollAreaRef.current?.getBoundingClientRect().height ?? 0;

    if (scrollAreaRef.current && nodeAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top:
          nodeAreaRef.current.getBoundingClientRect().height + firstNodeHeight,
        behavior: "smooth",
      });
    }
  }, [nodeAreaRef.current?.getBoundingClientRect().height]);

  const [firingConfetti, setFiringConfetti] = useState(false);
  useEffect(() => {
    if (cacheStatus?.jobFinished) {
      setFiringConfetti(true);
      setTimeout(() => setFiringConfetti(false), 10000);
    }
  }, [cacheStatus?.jobFinished]);

  return (
    <ModalV2
      active={active}
      width={800}
      disallowClose
      headerClassName={"autofill-process-header"}
      headerContent={
        <div className={"header"}>
          {cacheStatus?.jobFinished ? (
            <>
              <div className={"icon"}>
                <FilledIcon iconClass={"cr-icon-check"} />
              </div>
              <div className={"header-part"}>
                <span className={"heading"}>
                  Congrats, the autofill is complete!
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={"icon coffee-cup"}>
                <img src={Coffee} />
              </div>
              <div className={"header-part"}>
                <span className={"heading"}>
                  Your questionnaire is being autofilled – time to make a cup of
                  coffee!
                </span>
                <div className={"sub-heading"}>
                  This could take a couple of minutes. You can safely navigate
                  away from this page.
                </div>
              </div>
            </>
          )}
        </div>
      }
      footerClassName={"autofill-process-footer"}
      footerContent={
        <>
          <div className={"footer-left"}>
            <ProgressBar
              className={"autofill-progress-bar"}
              progress={cacheStatus?.jobProgress ?? 0.0}
              leftText={`${Math.round(
                (cacheStatus?.jobProgress ?? 0.0) * 100
              )}% complete`}
              rightText={`${
                (rootNodeAnswerState?.suggested ?? 0) +
                (rootNodeAnswerState?.optionalSuggested ?? 0)
              } suggestions found`}
            />
          </div>
          <div className={"footer-right"}>
            {cacheStatus?.jobFinished ? (
              <Button
                arrow
                onClick={onExitAutofillLoading}
                disabled={!cacheStatus?.jobFinished}
                primary
              >
                Continue answering
              </Button>
            ) : (
              <Button leftArrow onClick={() => history.goBack()}>
                Go back
              </Button>
            )}
          </div>
        </>
      }
      className={"autofill-process-modal"}
    >
      <div
        className={classNames("scroll-area", {
          "has-nodes": nodeArray.length > 0,
        })}
        ref={scrollAreaRef}
      >
        {nodeArray.length > 0 ? (
          <>
            <div className={"filler"} />
            <div className={"nodes-area"} ref={nodeAreaRef}>
              {nodeArray}
            </div>
          </>
        ) : (
          <LoadingIcon />
        )}
      </div>
      {firingConfetti && <FullPageConfetti fireTime={4000} fireKey={"fire"} />}
    </ModalV2>
  );
};

export default AutofillProcessModal;
