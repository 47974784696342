import { useEffect, useState } from "react";

import Button from "../../../_common/components/core/Button";
import SearchBox from "../../../_common/components/SearchBox";
import XTable, {
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { emailExposureSeverityNumberToIconLabel } from "./EmailExposures";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import { NumberWithCommas } from "../../../_common/helpers";
import ActionBar from "../../../_common/components/ActionBar";
import Icon from "../../../_common/components/core/Icon";
import { VIPAccount } from "../../../_common/types/emailExposures";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import Card from "../../../_common/components/core/Card";
import { usePagination, useSorting } from "../../../_common/hooks";
import classnames from "classnames";
import { setVipAccounts } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { History } from "history";
import "../../style/components/email_exposures/VIPCard.scss";
import { AddVIPEmailsModal } from "../modals/AddVIPEmailsModal";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";

interface IVIPCardProps {
  userHasWriteEmailExposuresPermission: boolean;
  userHasManageIntegrationsPermissions: boolean;
  accounts: VIPAccount[];
  isLoading: boolean;
  dispatch: DefaultThunkDispatch;
  reloadData: () => void;
  history: History;
}

export const VIPCard = ({
  userHasWriteEmailExposuresPermission,
  userHasManageIntegrationsPermissions,
  accounts,
  isLoading,
  dispatch,
  reloadData,
  history,
}: IVIPCardProps): JSX.Element => {
  const [addEmailsModalOpen, setAddEmailsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState(accounts || []);
  const [selectedIDs, setSelectedIDs] = useState([] as number[]);
  const [actionBarSubmitting, setActionBarSubmitting] = useState(false);

  const untagAsVIP = async () => {
    setActionBarSubmitting(true);
    const emails = selectedIDs
      .map((id) => accounts.find((a) => id === a.id))
      .filter((a) => a)
      .map((a) => `${a?.name}@${a?.domain}`);

    try {
      await dispatch(setVipAccounts(emails, false));
      dispatch(addDefaultSuccessAlert("Emails have been untagged as VIP"));
      setSelectedIDs([]);
      reloadData();
    } catch (e: any) {
      console.log(e);
      dispatch(
        addDefaultUnknownErrorAlert("An error occurred untagging the emails", [
          e.message,
        ])
      );
    }
    setActionBarSubmitting(false);
  };

  useEffect(() => {
    const filterText = searchText.toLowerCase();
    setFilteredAccounts(
      accounts.filter((a) =>
        `${a.name}@${a.domain}`.toLowerCase().includes(filterText)
      )
    );
  }, [searchText, accounts]);

  const [sortedAccounts, sortedBy, onSortChange] = useSorting<
    VIPAccount,
    "risk_score" | "name" | "domain" | "last_exposed" | "num_hits"
  >(filteredAccounts, "risk_score", SortDirection.DESC, {
    risk_score: {
      orderFuncs: [
        (account) => account.highestBreachRisk || 0,
        (account) => account.name,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    name: {
      orderFuncs: [(account) => account.name],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    domain: {
      orderFuncs: [(account) => account.domain],
      sortDirsDesc: ["desc"],
      sortDirsAsc: ["asc"],
    },
    last_exposed: {
      orderFuncs: [
        (account) => account.dateLastBreach || "unknown",
        (account) => account.name,
      ],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
    num_hits: {
      orderFuncs: [(account) => account.numBreaches, (account) => account.name],
      sortDirsDesc: ["desc", "asc"],
      sortDirsAsc: ["asc", "asc"],
    },
  });

  const [currentPageItems, currentPage, totalPages, onPageChange] =
    usePagination(sortedAccounts, 30);

  let emptyState = <></>;
  if (filteredAccounts.length === 0) {
    if (searchText !== "") {
      emptyState = (
        <SearchEmptyCard
          searchItemText="VIP email"
          onClear={() => setSearchText("")}
        />
      );
    } else {
      emptyState = (
        <Card className="paged-table-card">
          <EmptyCardWithAction
            emptyText={"Add VIP emails to monitor for breaches"}
            emptySubText={
              "After you add some VIP email addresses, we'll trigger specific notifications when we detect an identity breach involving any email in the list."
            }
            actionButtonText={"Add emails"}
            onActionClick={() => setAddEmailsModalOpen(true)}
            actionButtonIconClass={"add-emails"}
          />
        </Card>
      );
    }
  }

  const manageNotifications = () => {
    history.push("/homesettings", {
      searchTerm: "VIP",
      backContext: {
        backTo: "/email_exposures/vip",
        backToText: "Back to Identity Breaches",
      },
    });
  };

  const manageWebhooks = () => {
    history.push("/settings/integrations", {
      backContext: {
        backTo: "/email_exposures/vip",
        backToText: "Back to Identity Breaches",
      },
    });
  };

  const title = isLoading
    ? "VIP List"
    : `VIP List (${filteredAccounts.length})`;

  return (
    <div className={"vip-card"}>
      <div className="card-title-row">
        <div className="card-title">{title}</div>
        <div className="card-title-buttons">
          {userHasManageIntegrationsPermissions && (
            <Button onClick={manageWebhooks}>Manage integrations</Button>
          )}
          <Button onClick={manageNotifications}>Manage notifications</Button>
          {userHasWriteEmailExposuresPermission && (
            <Button
              className="open-selector-button"
              onClick={() => setAddEmailsModalOpen(true)}
              disabled={addEmailsModalOpen}
              loading={isLoading}
            >
              Add emails
            </Button>
          )}
        </div>
      </div>
      <div className={"card-subtitle-row"}>
        <div className={"description"}>
          Add important email addresses from your organization to the VIP list
          and set up corresponding notifications to be alerted immediately when
          an identity breach is detected.
        </div>
      </div>
      <div className="search-row">
        <SearchBox
          key={"vips"}
          placeholder={"Search for email"}
          value={searchText}
          onChanged={setSearchText}
          disabled={isLoading}
        />
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && filteredAccounts.length === 0 && emptyState}
      {!isLoading && currentPageItems.length > 0 && (
        <XTable
          className={classnames("paged-table-card")}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          columnHeaders={[
            { id: "risk_score", text: "Sev.", sortable: true },
            { id: "name", text: "Account", sortable: true },
            {
              id: "domain",
              text: "Domain",
              sortable: true,
            },
            { id: "last_exposed", text: "Last exposed", sortable: true },
            {
              id: "num_hits",
              text: "Hits",
              sortable: true,
            },
          ]}
          rows={currentPageItems.map((account) => {
            const cells = [];
            cells.push(
              <XTableCell key="risk_score" className="breach-risk">
                {account.highestBreachRisk !== null &&
                  account.highestBreachRisk !== undefined &&
                  emailExposureSeverityNumberToIconLabel(
                    account.highestBreachRisk
                  )}
              </XTableCell>
            );
            cells.push(
              <XTableCell key="name" className="account-name">
                {account.name}
              </XTableCell>
            );
            cells.push(
              <XTableCell key="domain" className="account-domain">
                @{account.domain}
              </XTableCell>
            );
            cells.push(
              <XTableCell key="last_exposed" className="account-date">
                {account.dateLastBreach ? (
                  <DateTimeFormat dateTime={account.dateLastBreach} dateOnly />
                ) : (
                  "-"
                )}
              </XTableCell>
            );
            cells.push(
              <XTableCell key="num_hits" className="account-date">
                {account.numBreaches ? account.numBreaches : "-"}
              </XTableCell>
            );

            return {
              id: account.id,
              selected: selectedIDs.includes(account.id),
              cells,
            };
          })}
          stickyColumnHeaders
          pagination={{
            currentPage,
            totalPages,
            onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          selectable={userHasWriteEmailExposuresPermission}
          onSelectClick={(accountID) => {
            const newSelectedIDs = selectedIDs.includes(accountID as number)
              ? selectedIDs.filter((id) => id !== accountID)
              : [...selectedIDs, accountID as number];
            setSelectedIDs(newSelectedIDs);
          }}
          onSelectToggle={() => {
            // if some entries are un-selected then select all
            if (selectedIDs.length !== currentPageItems.length) {
              setSelectedIDs(currentPageItems.map((a) => a.id));
            } else {
              setSelectedIDs([]);
            }
          }}
          onSelectAllClick={() =>
            setSelectedIDs(currentPageItems.map((a) => a.id))
          }
          onSelectNoneClick={() => setSelectedIDs([])}
        />
      )}
      <AddVIPEmailsModal
        active={addEmailsModalOpen}
        reloadData={reloadData}
        onClose={() => {
          setAddEmailsModalOpen(false);
        }}
        dispatch={dispatch}
      />
      <ActionBar
        active={
          userHasWriteEmailExposuresPermission &&
          selectedIDs &&
          selectedIDs.length > 0
        }
      >
        <div className="selection-action-bar">
          <div className="action-bar-description">
            <div className="action-bar-description-main">
              Select emails you wish to untag as VIP
            </div>
          </div>
          <div className="action-bar-selection-counter">
            {NumberWithCommas(selectedIDs.length)}
            {selectedIDs.length === 1 ? " email selected" : " emails selected"}
          </div>
          <div className="action-bar-buttons">
            <Button
              tertiary
              disabled={actionBarSubmitting}
              onClick={() => setSelectedIDs([])}
            >
              Cancel
            </Button>
            <Button
              className="action-bar-submit"
              disabled={selectedIDs.length === 0 || actionBarSubmitting}
              loading={actionBarSubmitting}
              onClick={async () => await untagAsVIP()}
            >
              Untag as VIP
            </Button>
          </div>
          <div className="action-bar-close" onClick={() => setSelectedIDs([])}>
            <Icon name="x" />
          </div>
        </div>
      </ActionBar>
    </div>
  );
};
