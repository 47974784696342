import "./Users.scss";
import UserAvatar, {
  IUserAvatarAndNameProps,
} from "../../_common/components/UserAvatar";
import { PopupOnOverflow } from "../../_common/components/EllipsizedText";
import { IUserAvatarListProps } from "../../_common/components/UserAvatarList";
import { SYSTEM_USER_ID } from "../api/types";
import CircledIcon from "../../_common/components/CircledIcon";

export interface TMUserAndAvatarProps extends IUserAvatarAndNameProps {
  userId?: number;
  extraRow?: React.ReactNode;
}

export function TMUserAndAvatar({
  name,
  avatar,
  email,
  extraRow,
  userId,
}: TMUserAndAvatarProps) {
  let avatarElt: JSX.Element;

  if (userId === SYSTEM_USER_ID) {
    avatarElt = (
      <CircledIcon iconClass="cr-icon-upguard-logo-pick upguard-logo" />
    );
  } else {
    avatarElt = <UserAvatar avatar={avatar} />;
  }

  return (
    <div className="tm-user-and-avatar">
      <PopupOnOverflow text={name}>
        <div className="name-row">
          {avatarElt} <div className="name">{name}</div>
        </div>
      </PopupOnOverflow>
      {email && (
        <PopupOnOverflow text={email}>
          <div className="email">{email}</div>
        </PopupOnOverflow>
      )}
      {extraRow}
    </div>
  );
}

export function TMUserList({ users }: IUserAvatarListProps) {
  if (!users || users.length === 0) {
    return null;
  }
  if (users.length === 1) {
    return <TMUserAndAvatar {...users[0]} />;
  }

  const user = { ...users[0], email: undefined };
  const extraRow = <div className="extra">+ {users.length - 1} more</div>;

  return <TMUserAndAvatar {...user} extraRow={extraRow} />;
}
