import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../types/correspondenceMessage";
import { IUserMini, IUserMiniMap } from "../types/user";
import { DefaultThunkDispatch } from "../types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import {
  conditionalRefreshActivityStreamForOrgUser,
  grabTPVMSession,
  setSurveyData,
  setSurveyDataForAssessment,
} from "./commonActions";
import {
  ISurveyImportDetails,
  ISurveyListItemResponse,
  ISurveyReminder,
  SurveyStatus,
  TimelineStatusMap,
} from "../types/survey";
import { apiTimelineEvent, ITimelineEvent } from "../types/apiTimeline";
import { refreshManagedVendorsList } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import { fetchRemediationRequestListForAllVendors } from "../../vendorrisk/reducers/remediation.actions";
import { getVendorData } from "../../vendorrisk/reducers/cyberRiskActions";
import { setVendorSurveysDataV2 } from "../../vendorrisk/reducers/survey.actions";
import { produce } from "immer";
import VendorSecurityProfileAPI, {
  gapQuestionnaireStatusTag,
} from "../../vendorrisk/reducers/vendorSecurityProfileAPI";

export interface addSurveyMessageResp {
  message: ICorrespondenceMessage<SurveyQuestionMessageMeta>;
  user: IUserMini;
}

export const addSurveyMessage = (
  surveyId: number,
  parentId: number | undefined,
  content: string,
  isPrivate: boolean,
  questionId?: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<addSurveyMessageResp> => {
    const opts = {
      survey_id: surveyId,
      content,
      is_private: isPrivate,
      ...(parentId ? { parent_id: parentId } : null),
      question_id: questionId,
    };

    let result: addSurveyMessageResp;
    try {
      result = await FetchCyberRiskUrl(
        "surveys/messages/v1",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error adding message to survey", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return result;
  };
};

export interface editSurveyMessageResp {
  message: ICorrespondenceMessage<SurveyQuestionMessageMeta>;
}

export const editSurveyMessage = (messageId: number, content: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<editSurveyMessageResp> => {
    const opts = {
      message_id: messageId,
      content,
    };

    let result;
    try {
      result = await FetchCyberRiskUrl<editSurveyMessageResp>(
        "surveys/messages/v1",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error editing message", e);

      throw e;
    }

    return result;
  };
};

export interface fetchSurveyMessagesResp {
  messages: ICorrespondenceMessage<SurveyQuestionMessageMeta>[];
  users: IUserMiniMap;
}

export const fetchSurveyMessages = (
  surveyId: number,
  force = false,
  noMarkRead = false,
  questionId?: string,
  skipQuestionMessages?: boolean,
  noCache?: boolean
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<fetchSurveyMessagesResp> => {
    let surveyInState = getState().common.surveys[surveyId];
    if (!force && surveyInState && surveyInState.messages) {
      return {
        messages: surveyInState.messages.messages,
        users: surveyInState.messages.users || {},
      };
    }

    let messages;
    try {
      messages = await FetchCyberRiskUrl<fetchSurveyMessagesResp>(
        "surveys/messages/v1",
        {
          survey_id: surveyId,
          no_mark_read: noMarkRead,
          question_id: questionId,
          skip_question_messages: skipQuestionMessages,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching survey messages", e);

      throw new Error("Error fetching messages. Please try again later.");
    }

    if (!noCache) {
      dispatch(
        setSurveyData(surveyId, {
          messages: {
            messages: messages.messages,
            users: messages.users,
          },
        })
      );

      // Also reset the survey unread count.
      if (!noMarkRead) {
        surveyInState = getState().common.surveys[surveyId];
        if (surveyInState && surveyInState.survey) {
          dispatch(
            setSurveyData(surveyId, {
              survey: {
                ...surveyInState.survey,
                unreadMessages: 0,
              },
            })
          );
        }
      }
    }

    return messages;
  };
};

export const fetchSurveyMessagesForAssessment = (
  assessmentId: number,
  assessmentPublishedAt: string,
  surveyId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<fetchSurveyMessagesResp> => {
    const surveyInState =
      getState().common.assessmentSurveys?.[assessmentId]?.[surveyId];
    if (surveyInState?.messages) {
      return {
        messages: surveyInState.messages.messages,
        users: surveyInState.messages.users || {},
      };
    }

    let messages;
    try {
      messages = await FetchCyberRiskUrl<fetchSurveyMessagesResp>(
        "surveys/messages/v1",
        {
          survey_id: surveyId,
          max_created_at: assessmentPublishedAt,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching survey messages", e);

      throw new Error("Error fetching messages. Please try again later.");
    }

    dispatch(
      setSurveyDataForAssessment(assessmentId, surveyId, {
        messages: {
          messages: messages.messages,
          users: messages.users,
        },
      })
    );
    return messages;
  };
};

export interface fetchSurveyDetailsResp {
  survey: ISurveyListItemResponse;
  surveyImport?: ISurveyImportDetails;
}

export const fetchSurveyDetails = (surveyId: number, force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<ISurveyListItemResponse> => {
    const surveyInState = getState().common.surveys[surveyId];
    if (!force && surveyInState && surveyInState.survey) {
      return surveyInState.survey;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl<fetchSurveyDetailsResp>(
        "surveys/details/v1/",
        {
          survey_id: surveyId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey details", e);

      throw e;
    }

    dispatch(
      setSurveyData(surveyId, {
        survey: json.survey,
        surveyImport: json.surveyImport,
      })
    );
    return json.survey;
  };
};

export const fetchSurveyDetailsForAssessment = (
  assessmentId: number,
  surveyId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<ISurveyListItemResponse> => {
    const surveyInState =
      getState().common.assessmentSurveys?.[assessmentId]?.[surveyId];
    if (surveyInState?.survey) {
      return surveyInState.survey;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl<fetchSurveyDetailsResp>(
        "surveys/details/byassessment/v1/",
        {
          survey_id: surveyId,
          by_assessment_id: assessmentId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey details", e);

      throw e;
    }

    dispatch(
      setSurveyDataForAssessment(assessmentId, surveyId, {
        survey: json.survey,
      })
    );
    return json.survey;
  };
};

interface fetchSurveyRemindersResp {
  reminders: ISurveyReminder[];
}

export const fetchSurveyReminders = (id: number, force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<ISurveyReminder[]> => {
    const surveyInState = getState().common.surveys[id];
    if (!force && surveyInState && surveyInState.reminders) {
      return surveyInState.reminders;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl<fetchSurveyRemindersResp>(
        "survey/reminders/v1",
        {
          id,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey reminders", e);

      throw new Error(
        "Error fetching questionnaire reminders. Please try again later."
      );
    }

    dispatch(setSurveyData(id, { reminders: json.reminders }));

    return json.reminders;
  };
};

export const sendSurveyReminder = (surveyID: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "survey/reminders/send/v1",
        { survey_id: surveyID },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error sending survey reminder survey", e);

      throw new Error(
        "Error sending questionnaire reminder. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const scheduleSurveyReminder = (surveyId: number, when: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "survey/reminders/v1",
        { id: surveyId, when },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error scheduling survey reminder survey", e);

      throw new Error(
        "Error scheduling questionnaire reminder. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const cancelSurveyReminder = (surveyId: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "survey/reminders/v1",
        { id: surveyId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error cancelling survey reminder", e);

      throw new Error(
        "Error cancelling questionnaire reminder. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export interface timelineData {
  items: ITimelineEvent[];
  users: IUserMiniMap;
}

export const fetchSurveyTimeline = (id: number, force = false) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<timelineData> => {
    const surveyInState = getState().common.surveys[id];
    if (!force && surveyInState && surveyInState.timeline) {
      return surveyInState.timeline;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl<{
        timeline: ITimelineEvent[];
        users: IUserMiniMap;
      }>(
        "survey/timeline/v1",
        {
          id,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey timeline", e);

      throw new Error(
        "Error fetching questionnaire timeline. Please try again later."
      );
    }

    const timeline = {
      items: mapTimelineStatuses(json.timeline),
      users: json.users,
    };

    dispatch(setSurveyData(id, { timeline }));

    return timeline;
  };
};

export const fetchSurveyTimelineForAssessment = (
  assessmentId: number,
  assessmentPublishedAt: string,
  surveyId: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<timelineData> => {
    const surveyInState =
      getState().common.assessmentSurveys?.[assessmentId]?.[surveyId];
    if (surveyInState?.timeline) {
      return surveyInState.timeline;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl<{
        timeline: ITimelineEvent[];
        users: IUserMiniMap;
      }>(
        "survey/timeline/v1",
        {
          id: surveyId,
          max_created_at: assessmentPublishedAt,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey timeline", e);

      throw new Error(
        "Error fetching questionnaire timeline. Please try again later."
      );
    }

    const timeline = {
      items: mapTimelineStatuses(json.timeline),
      users: json.users,
    };

    dispatch(setSurveyDataForAssessment(assessmentId, surveyId, { timeline }));

    return timeline;
  };
};

export const mapTimelineStatuses = (
  timelineItems: apiTimelineEvent[]
): ITimelineEvent[] => {
  return timelineItems.map((item) => {
    if (!item.statusUpdate) {
      return item as ITimelineEvent;
    }

    return {
      ...item,
      statusUpdate: {
        prevStatus: TimelineStatusMap[
          item.statusUpdate.prevStatus as string
        ] || {
          text: item.statusUpdate.prevStatus,
          labelColor: "blue ",
        },
        newStatus: TimelineStatusMap[item.statusUpdate.newStatus as string] || {
          text: item.statusUpdate.newStatus,
          labelColor: "blue ",
        },
      },
    };
  });
};

export const updateSurveyStatus = (
  surveyId: number,
  newStatus: SurveyStatus,
  isRevert = false,
  isManagementAnalystSession = false,
  vendorId?: number
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<unknown> => {
    try {
      await FetchCyberRiskUrl(
        "surveys/status/v1/",
        {
          survey_id: surveyId,
          new_status: newStatus,
          revert: isRevert,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating survey status", e);

      throw e;
    }

    if (isManagementAnalystSession) {
      dispatch(refreshManagedVendorsList());
    }

    // if the updated status is canceled clear the remediation request data so that will be refreshed
    if (
      newStatus === SurveyStatus.Cancelled ||
      newStatus == SurveyStatus.Complete
    ) {
      dispatch(fetchRemediationRequestListForAllVendors(true));
    }

    // if a vendor ID has been provided make sure we update the status in the list
    if (vendorId) {
      const tpmvSession = grabTPVMSession(getState);
      const surveys = getVendorData(getState, vendorId, false, tpmvSession)
        ?.surveyList;

      if (surveys) {
        const newSurveys = produce(surveys, (draft) => {
          const idx = draft.result.findIndex((s) => s.surveyId == surveyId);

          if (idx >= 0) {
            draft.result[idx].status = newStatus;
          }
        });

        dispatch(
          setVendorSurveysDataV2(vendorId, { ...newSurveys }, tpmvSession)
        );
      }
    }

    return Promise.all([
      dispatch(fetchSurveyDetails(surveyId, true)),
      dispatch(fetchSurveyTimeline(surveyId, true)),
      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser()),
      dispatch(
        VendorSecurityProfileAPI.util.invalidateTags([
          { type: gapQuestionnaireStatusTag, id: vendorId },
        ])
      ),
    ]);
  };
};

export const updateSurveyDueDate = (surveyId: number, dueDate: string) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "survey/duedate/v1",
        { survey_id: surveyId, due_date: dueDate },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating survey due date", e);

      throw new Error(
        "Error updating questionnaire due date. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// share a particular survey with another user
export const shareSurveyWithUser = (
  surveyID: number,
  recipientEmailAddress: string,
  recipientFirstName: string,
  emailText: string,
  companyName: string,
  forRemediation = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    // make our request

    try {
      const params = {
        survey_id: surveyID,
        email_address: recipientEmailAddress,
        email_text: emailText,
        customer_vendor_name: companyName,
        for_remediation: forRemediation,
        recipient_first_name: recipientFirstName,
      };
      await FetchCyberRiskUrl(
        "sharesurvey/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error sharing questionnaire: ", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    dispatch(fetchSurveyDetails(surveyID, true));
  };
};

// Resend the email to share a particular survey with another vendor user. This is an email  from one vendor
// with access to the survey to invite another vendor user to colaborate ont he same survey.
export const resendShareInviteForSurvey = (
  surveyID: number,
  recipientFirstName: string,
  recipientEmailAddress: string,
  emailText: string,
  companyName: string
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    // make our request

    try {
      const params = {
        survey_id: surveyID,
        recipient_first_name: recipientFirstName,
        email_address: recipientEmailAddress,
        email_text: emailText,
        customer_vendor_name: companyName,
      };
      await FetchCyberRiskUrl(
        "sharesurvey/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error sharing questionnaire: ", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};
