import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import { useLocaleCompare } from "../../../_common/hooks";

import { App, AppStatus } from "../../api/types";
import { FC } from "react";

import "./AppStatusDisplay.scss";
import classnames from "classnames";
import { statusColor, statusDisplayText, statusIcon } from "../../helpers/apps";
import { getUserBaseColorForKey } from "../../helpers/colors";
import {
  SidePopupV2,
  PopupPosition,
} from "../../../_common/components/DismissablePopup";

interface PartiallyApprovedPopupProps {
  app: App;
}

const PartiallyApprovedPopup: FC<PartiallyApprovedPopupProps> = ({ app }) => {
  const { caseInsensitiveCompare } = useLocaleCompare();

  return (
    <div className="partially-approved-container">
      {app.approvedForTeams.length > 0 && (
        <div id="teams" className="popup-section">
          <div className="popup-header">Teams</div>
          <div className="item-list">
            {app.approvedForTeams
              .map((t) => t.name)
              .sort(caseInsensitiveCompare)
              .map((t) => {
                return (
                  <PillLabel
                    className="item-pill"
                    key={t}
                    color={getUserBaseColorForKey(t)}
                  >
                    {t}
                  </PillLabel>
                );
              })}
          </div>
        </div>
      )}
      {app.approvedForRoles.length > 0 && (
        <div id="roles" className="popup-section">
          <div className="popup-header">Roles</div>
          <div className="item-list">
            {app.approvedForRoles
              .map((r) => r.name)
              .sort(caseInsensitiveCompare)
              .map((r) => {
                return (
                  <PillLabel
                    className="item-pill"
                    key={r}
                    color={LabelColor.Grey}
                  >
                    {r}
                  </PillLabel>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export interface AppStatusProps {
  app: App;
  large?: boolean;
  hoverPosition?: PopupPosition;
}

const AppStatusDisplay: FC<AppStatusProps> = ({
  app,
  large = false,
  hoverPosition = "top",
}) => {
  const icon = statusIcon(app);
  const text = statusDisplayText(app);
  const color = statusColor(app);

  const rv = (
    <PillLabel color={color} large={large}>
      <div className={"app-status"}>
        <i className={classnames("app-status-icon", icon)} />
        <div>{text}</div>
      </div>
    </PillLabel>
  );

  if (app.status == AppStatus.ApprovedAppStatus && !app.approvedForAll) {
    return (
      <SidePopupV2
        text=<PartiallyApprovedPopup app={app} />
        popupClassName={"partially-approved-popup"}
        popupStyle={"light"}
        position={hoverPosition}
        inline
      >
        {rv}
      </SidePopupV2>
    );
  }

  return rv;
};

export default AppStatusDisplay;
