import { locationState, useBack } from "../../../_common/types/router";
import PageHeader from "../../../_common/components/PageHeader";
import { FC, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import StepsWithSections, {
  IStep,
  Steps,
} from "../../../_common/components/StepsWithSections";
import classnames from "classnames";
import {
  RemediationStep,
  remediationStepLabels,
} from "../../../vendorrisk/views/RequestRemediationV2";
import Button from "../../../_common/components/core/Button";
import ActionBar from "../../../_common/components/ActionBar";
import {
  remediationRequestsUrl,
  riskProfileUrl,
} from "../../UserBaseAppRouter";
import "./CreateRemediationRequestView.scss";
import SelectUsersStep from "../../components/remediation_requests/SelectUsersStep";
import userbaseApi from "../../api/userbase.api";
import UserBaseAPI from "../../api/userbase.api";
import { ReviewAndSendStep } from "../../../vendorrisk/views/remediation_request/ReviewAndSendStep";
import { ContactDisplay } from "../../../vendorrisk/components/contacts/ContactSelect";
import {
  createNewRemediationRequest,
  ISaasCheckToAdd,
} from "../../../vendorrisk/reducers/remediation.actions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { computeUsersInRemediation } from "../RiskProfileView";
import { SettingsStep } from "../../../vendorrisk/views/remediation_request/SettingsStep";
import { useSchedule } from "../../../_common/components/remediationDetails/UpdateDueDateModal";
import { fetchOrgUserEmailAddresses } from "../../../vendorrisk/reducers/org.actions";
import { UserDirectoryType } from "../../api/types";

const generateRemediationMessage = (
  appName: string,
  directoryType?: UserDirectoryType
) => `
We've identified potential security concerns regarding the use of the following SaaS application - ${appName}.

${
  directoryType === UserDirectoryType.Google
    ? "Navigate to https://admin.google.com/ac/owl/list to review the list of accessed apps and take action."
    : directoryType === UserDirectoryType.Microsoft365
      ? "Navigate to https://entra.microsoft.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview to review the list of apps and take action."
      : ""
}
`;

type UserRiskRemediationStep =
  | RemediationStep.SelectUsers
  | RemediationStep.Settings
  | RemediationStep.ReviewAndSend;

export interface createRemediationRequestLocationState extends locationState {
  riskId?: string;
  userUUID?: string;
}

const CreateRemediationRequestView: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation<createRemediationRequestLocationState>();
  const history = useHistory();
  const { backAction, backText } = useBack();

  const riskId = location?.state?.riskId;
  const userUUID = location?.state?.userUUID;

  const { data: risksData } = userbaseApi.useGetUserBaseRisksV1Query(
    { riskId: riskId ?? "" },
    { skip: !riskId } // don't fetch the risks unless a valid risk ID is provided
  );
  const { data: riskUsers } = userbaseApi.useGetUserBaseRiskUsersV1Query(
    { riskId: riskId ?? "" },
    { skip: !riskId } // don't fetch the risks unless a valid risk ID is provided
  );
  // we neglect the loading state here because it is handled in the SelectUsersStep
  // component which makes the same API call and doesn't show the full list of users
  // until this API has completed.
  const { data: remediationsData } =
    UserBaseAPI.useGetUserBaseRemediationsV1Query(
      undefined,
      { skip: !riskId } // don't fetch the remediations unless a valid risk ID is provided
    );
  const { data: riskWaiversData } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();
  const [getRemediationRequests] =
    UserBaseAPI.useLazyGetUserBaseRemediationsV1Query();
  const { data: directoryStats } =
    userbaseApi.useGetUserDirectoryStatsV1Query();

  const [currentStep, setCurrentStep] = useState<UserRiskRemediationStep>(
    RemediationStep.SelectUsers
  );
  const [isSending, setIsSending] = useState(false);
  const [selectedUserUUIDs, setSelectedUserUUIDs] = useState<string[]>([]);

  const [title, setTitle] = useState(`Remove token from app`);
  const [emailMessage, setEmailMessage] = useState(
    generateRemediationMessage("app", directoryStats?.stats?.origin)
  );

  const [selectedContacts, setSelectedContacts] = useState(
    [] as ContactDisplay[]
  );

  const orgUsers = useAppSelector(
    (state) => state.cyberRisk.orgUserEmailAddresses.data ?? []
  );

  const [
    dueDate,
    reminderDate,
    reminderSelection,
    setDueDate,
    setReminderDate,
    setReminderSelection,
  ] = useSchedule();

  useEffect(() => {
    dispatch(fetchOrgUserEmailAddresses(false));
  }, []);

  useEffect(() => {
    if (risksData?.risks && risksData.risks.length > 0) {
      setTitle(`Remove token from ${risksData.risks[0].saasAppName || "app"}`);
      setEmailMessage(
        generateRemediationMessage(
          risksData.risks[0].saasAppName || "app",
          directoryStats?.stats?.origin
        )
      );
    }
  }, [risksData, directoryStats]);

  useEffect(() => {
    if (riskUsers) {
      const inRemediations = computeUsersInRemediation(
        remediationsData?.requests ?? []
      );
      const usersUnderRemediation =
        riskId && inRemediations[riskId]
          ? inRemediations[riskId].userUUIDs
          : [];

      const waivedUsers = [
        ...new Set(
          (riskWaiversData?.waivers ?? [])
            .filter((w) => w.riskId === riskId)
            .map((w) => w.userInfos.map((u) => u.uuid))
            .flat()
        ),
      ];

      setSelectedUserUUIDs(
        riskUsers.users
          .filter((u) => !usersUnderRemediation.includes(u.uuid))
          .filter((u) => !waivedUsers.includes(u.uuid))
          .filter((u) => !userUUID || userUUID === u.uuid)
          .map((u) => u.uuid)
      );
    }
  }, [riskUsers, userUUID, riskId, remediationsData?.requests]);

  if (!riskId) {
    return <Redirect to={riskProfileUrl} />;
  }

  const canGoToSettingsStep = selectedUserUUIDs.length > 0;
  const canGoToReviewStep =
    canGoToSettingsStep &&
    title !== "" &&
    emailMessage !== "" &&
    selectedContacts.length > 0;

  const enabledSteps: Record<UserRiskRemediationStep, boolean> = {
    [RemediationStep.SelectUsers]: true,
    [RemediationStep.Settings]: canGoToSettingsStep,
    [RemediationStep.ReviewAndSend]: canGoToReviewStep,
  };

  const steps: IStep<UserRiskRemediationStep>[] = [
    {
      id: RemediationStep.SelectUsers,
      text: remediationStepLabels[RemediationStep.SelectUsers],
      disabled: !enabledSteps[RemediationStep.SelectUsers],
      onClick: () => setCurrentStep(RemediationStep.SelectUsers),
    },
    {
      id: RemediationStep.Settings,
      text: remediationStepLabels[RemediationStep.Settings],
      disabled: !enabledSteps[RemediationStep.Settings],
      onClick: () => setCurrentStep(RemediationStep.Settings),
    },
    {
      id: RemediationStep.ReviewAndSend,
      text: remediationStepLabels[RemediationStep.ReviewAndSend],
      disabled: !enabledSteps[RemediationStep.ReviewAndSend],
      onClick: () => setCurrentStep(RemediationStep.ReviewAndSend),
    },
  ];

  const currentStepIdx = steps.findIndex((s) => s.id === currentStep);

  const goBack = () => {
    if (backAction) {
      backAction();
      return;
    }
    history.push(remediationRequestsUrl);
  };

  const prevStep =
    currentStepIdx > 0 ? steps[currentStepIdx - 1].id : undefined;
  const goPrev = prevStep ? () => setCurrentStep(prevStep) : undefined;

  const nextStep =
    currentStepIdx < steps.length - 1
      ? steps[currentStepIdx + 1].id
      : undefined;
  const goNext =
    nextStep && enabledSteps[nextStep]
      ? () => setCurrentStep(nextStep)
      : undefined;

  const selectedSaasCheck: Record<string, ISaasCheckToAdd> = {
    [riskId]: {
      riskID: riskId,
      userUUIDs: selectedUserUUIDs,
    },
  };

  const onSubmit = () => {
    setIsSending(true);
    dispatch(
      createNewRemediationRequest({
        title,
        emailMessage,
        isSubsidiary: false,
        isDraft: false,
        cloudscanChecksAndWebsites: [],
        surveyChecksToAdd: [],
        additionalEvidenceChecksToAdd: [],
        additionalEvidenceDocumentsToAdd: [],
        inviteEmails: selectedContacts.map((c) => c.emailAddress),
        saasChecksToAdd: [selectedSaasCheck[riskId]],
      })
    )
      .then(() => {
        dispatch(addDefaultSuccessAlert(`Remediation has been requested`));
      })
      .then(() => getRemediationRequests())
      .then(() => {
        if (backAction) {
          backAction();
          return;
        }
        history.push(remediationRequestsUrl);
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred while requesting remediation"
          )
        );
      })
      .finally(() => setIsSending(false));
  };

  return (
    <StepsWithSections
      className={classnames("userbase-create-remediation-request")}
    >
      <PageHeader
        history={history}
        title="Create Remediation Request"
        backAction={backAction}
        backText={backText}
      />
      <Steps steps={steps} currentStep={currentStepIdx + 1} />
      {currentStep === RemediationStep.SelectUsers && (
        <SelectUsersStep
          riskId={location.state.riskId || ""}
          selectedUserUUIDs={selectedUserUUIDs}
          onSelectionChange={setSelectedUserUUIDs}
        />
      )}
      {currentStep === RemediationStep.Settings && (
        <SettingsStep
          title={title}
          setTitle={setTitle}
          emailMessage={emailMessage}
          setEmailMessage={setEmailMessage}
          selectedContacts={selectedContacts}
          onContactSelectionChanged={setSelectedContacts}
          isSelfRemediation={false}
          isUserRisk={true}
          isSubsidiary={false}
          existingUsers={[]}
          dueDate={dueDate}
          setDueDate={setDueDate}
          reminderDate={reminderDate}
          setReminderDate={setReminderDate}
          reminderSelection={reminderSelection}
          setReminderSelectionType={setReminderSelection}
          allSurveys={[]}
          selectedSurveyIds={[]}
          orgUsers={orgUsers}
        />
      )}
      {currentStep === RemediationStep.ReviewAndSend && (
        <ReviewAndSendStep
          selectedContacts={selectedContacts}
          emailMessage={emailMessage}
          title={title}
          isSubsidiary={false}
          isSelfRemediation={true}
          isUserRiskRemediation={true}
          selectedCloudscanChecks={{}}
          selectedSurveyChecks={{}}
          risks={risksData?.risks ? [risksData.risks[0]] : []}
          selectedAdditionalEvidenceChecks={{}}
          selectedSaasChecks={selectedSaasCheck}
          selectedDocuments={[]}
          onDownloadDocument={() => {}}
          onEditDocuments={() => {}}
          onEditSettings={() => setCurrentStep(RemediationStep.Settings)}
          onEditRecipients={() => setCurrentStep(RemediationStep.Settings)}
          dueDate={dueDate}
          reminderDate={reminderDate}
        />
      )}
      <ActionBar active={true}>
        <div className={"left-side"}>
          {!!goPrev && (
            <Button onClick={goPrev} leftArrow>
              Previous
            </Button>
          )}
        </div>
        <div className={"right-side"}>
          <Button onClick={goBack} tertiary>
            Cancel
          </Button>
          {currentStep !== RemediationStep.ReviewAndSend && (
            <Button
              primary
              onClick={goNext}
              arrow={!!goNext}
              disabled={!goNext}
            >
              Next
            </Button>
          )}
          {currentStep === RemediationStep.ReviewAndSend && (
            <Button primary onClick={onSubmit} loading={isSending}>
              Submit request
            </Button>
          )}
        </div>
      </ActionBar>
    </StepsWithSections>
  );
};

export default CreateRemediationRequestView;
