import { XTableCell } from "../../../_common/components/core/XTable";
import UserBaseAPI from "../../api/userbase.api";
import { AppStatus, UserEvent, UserEventType } from "../../api/types";
import { FC } from "react";
import Timeline, { TimelineEvent } from "../Timeline";
import UserIcon from "../../../_common/images/remediation-icon.svg";

const UserTimelineCell: FC<UserEvent> = ({
  eventType,
  metadata,
  triggeringName,
}) => {
  let action: string = "";
  switch (eventType) {
    case UserEventType.UserFirstDetected: {
      action = "User was first detected";
      break;
    }

    case UserEventType.UserAppStatusChange: {
      switch (metadata.status) {
        case AppStatus.ApprovedAppStatus: {
          action = `${triggeringName} approved ${metadata.appName}`;
          break;
        }
        case AppStatus.NotApprovedAppStatus: {
          action = `${triggeringName} marked ${metadata.appName} as not approved`;
          break;
        }
        case AppStatus.InReviewAppStatus: {
          break;
        }
        case AppStatus.NeedsReviewAppStatus: {
          break;
        }
      }

      break;
    }

    case UserEventType.UserRiskWaiverRequested: {
      action = `Risk waiver requested by ${triggeringName} for app ${metadata.appName}`;
      const reason = metadata.requesterReason;
      if (reason) {
        action += ` with reason: ${reason}`;
      }
      break;
    }

    case UserEventType.UserRiskWaiverApproved: {
      action = `Risk waiver approved by ${triggeringName} for app ${metadata.appName}`;
      const reason = metadata.approverReason;
      if (reason) {
        action += ` with reason: ${reason}`;
      }
      break;
    }

    case UserEventType.UserNewApp: {
      action = `Started using app ${metadata.appName}`;
      break;
    }
  }

  return <XTableCell key={"action"}>{action}</XTableCell>;
};

const uniqueKey = (e: UserEvent): string => {
  switch (e.eventType) {
    case UserEventType.UserFirstDetected: {
      return `firstDetected-${e.at}`;
    }

    case UserEventType.UserAppStatusChange: {
      return `appStatusChange-${e.at}-${e.metadata.status}-${e.metadata.appName}`;
    }

    case UserEventType.UserNewApp: {
      return `userNewApp-${e.at}-${e.metadata.appName}`;
    }

    default: {
      return e.at;
    }
  }
};

interface UserTimelineProps {
  userUUID: string;
}

const UserTimeline: FC<UserTimelineProps> = ({ userUUID }) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserTimelineV1Query({
    userUUID: userUUID,
  });

  const userEvents: TimelineEvent[] = (data?.events ?? []).map((e) => ({
    id: uniqueKey(e),
    at: e.at,
    cell: UserTimelineCell(e),
  }));

  return (
    <Timeline
      isLoading={isLoading}
      events={userEvents}
      emptyText={"No events for this user"}
      emptySubtext={
        "There are no events for this user. When certain events occur — if a new app is detected, for example — they will be listed here."
      }
      emptyIcon={UserIcon}
    />
  );
};

export default UserTimeline;
