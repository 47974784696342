import { useDefaultHistory } from "../../../_common/types/router";
import UserBaseAPI from "../../api/userbase.api";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { FC } from "react";
import { appSummaryUrl, riskProfileUrl } from "../../UserBaseAppRouter";
import SeverityIcon from "../../../_common/components/SeverityIcon";
import { pluralise } from "../../../_common/helpers";
import TickShieldIMG from "../../images/tick-shield.svg";
import "./AppRisksOverview.scss";

interface AppRisksOverviewProps {
  appName: string;
}

const AppRisksOverview: FC<AppRisksOverviewProps> = ({ appName }) => {
  const history = useDefaultHistory();

  const { data, isLoading } =
    UserBaseAPI.useGetUserBaseAppRisksBreakdownV1Query({
      app: appName,
    });

  const onViewAll = () => {
    history.push(riskProfileUrl, {
      backContext: {
        backTo: appSummaryUrl(appName),
        backToText: `Back to ${appName}`,
      },
    });
  };

  if (!isLoading && !data?.breakdown) {
    return <></>;
  }

  const sortedRisks =
    data?.breakdown.slice().sort((a, b) => b.severityNum - a.severityNum) ?? [];

  const totalRisks = sortedRisks.reduce((acc, r) => acc + r.numRisks, 0);

  // Show different types of content depending on whether there are risks or not
  const content =
    totalRisks !== 0 ? (
      <div className={"content"}>
        <div className={"subtitle"}>Current risks by severity</div>
        <div className={"breakdown"}>
          {sortedRisks.map((r) => {
            const numberOfRisksToDisplay =
              r.numRisks === 0
                ? "-"
                : `${r.numRisks} ${pluralise(r.numRisks, "risk", "risks")}`;

            return (
              <div key={r.severityNum} className={"risk-severity"}>
                <SeverityIcon severity={r.severity} label />
                {numberOfRisksToDisplay}
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <div className={"content-no-risks"}>
        <img src={TickShieldIMG} width={100} height={100} />
        <div className={"subtitle"}>No risks found</div>
      </div>
    );

  return (
    <ReportCard newStyles className="userbase-app-risks-overview">
      <div className="header">
        <span>Risks</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewAll}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && content}
    </ReportCard>
  );
};

export default AppRisksOverview;
