import Button from "../../_common/components/core/Button";
import contactSupport from "../../_common/contactSupport";
import ErrorIconImg from "../../vendorrisk/images/circle_exclaim_red.svg";

import "./FeedFailed.scss";

// feedFailed
// Renders an empty message indicating we failed to read the feed, with an appropriate action to retry the read.
export interface FeedFailedProps {
  message: string;
  isLoading: boolean;
  refetch: () => void;
}
export function FeedFailed({ message, isLoading, refetch }: FeedFailedProps) {
  return (
    <div className={"threat-monitoring-failed-feed"}>
      <img src={ErrorIconImg} width={100} height={100} />
      <div className={"primary"}>{message}</div>
      <div className={"sub"}>
        You can use the retry button to attempt to read again.
      </div>
      <Button loading={isLoading} onClick={() => refetch()}>
        <>
          <div className={"cr-icon-redo"} />
          {"Retry"}
        </>
      </Button>
      <div className={"contact-support"} onClick={contactSupport}>
        <div className={"text"}>
          {"If the problem persists, please Contact Support"}
        </div>
        <div className={"cr-icon-arrow-right"} />
      </div>
    </div>
  );
}
