import ModalV2, {
  BaseModalProps,
  useModalV2,
} from "../../../_common/components/ModalV2";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ToggleWithLabel from "../../../vendorrisk/components/ToggleWithLabel";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import "../../style/components/modals/AutofillModalV2.scss";
import Button from "../../../_common/components/core/Button";
import FileDropzone from "../../../_common/components/FileDropzone";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import {
  MaxFileSizeB,
  MaxFileSizeDisplay,
} from "../../../_common/types/fileRestrictions";
import SearchBox from "../../../_common/components/SearchBox";
import XTable, {
  IIconOption,
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import moment from "moment";
import classNames from "classnames";
import DocumentSelectionFilter, {
  documentFilterText,
  documentsFilterActive,
  initialDocumentFilterState,
} from "./AutofillModalFilter";
import SurveyViewerAPI, {
  invalidateSources,
} from "../../reducers/SurveyViewerAPI";
import { dateMatchesFilter } from "../../../vendorrisk/components/filter/DateFilter";
import {
  useLocalStorageState,
  usePagination,
  useSelectableRows,
  useSorting,
} from "../../../_common/hooks";
import { GetIconForFilename } from "../../../vendorrisk/helpers/icons";
import ContentLibraryAPI from "../../../contentlibrary/api/contentLibraryAPI";
import {
  useBasicPermissions,
  UserReadContentLibrary,
  UserWriteContentLibrary,
} from "../../../_common/permissions";
import ConfirmationModalV2 from "../../../_common/components/modals/ConfirmationModalV2";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import {
  HoverColor,
  HoverLocation,
} from "../../../_common/components/IconButton";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import { GptUserPreferences } from "../types/autofill.types";
import {
  GetGptAutofillSourcesResp,
  pollGptDocumentExtraction,
  SurveyIDWithPublicKey,
} from "../../reducers/autofill.actions";
import { trackEvent } from "../../../_common/tracking";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import GptAutofillConfigureSourceModal from "./GptAutofillConfigureSourceModal";
import { useFindDuplicateDocumentsForFile } from "../../../contentlibrary/types/contentLibraryHooks";
import { DuplicateDocumentConfirmationModal } from "../../../contentlibrary/components/AddDocumentToContentLibraryModal";

const platformSurveyPrefix = "SURVEY-";
const externalSurveyPrefix = "EXTERNAL-";
const docRepoPrefix = "DOCREPO-";

const prefsToStringSet = (prefs: GptUserPreferences): Set<string> => {
  const newSet = new Set<string>();

  Object.entries(prefs.platformSurveys).forEach(([key, value]) => {
    if (value) {
      newSet.add(platformSurveyPrefix + key);
    }
  });

  Object.entries(prefs.externalDocuments).forEach(([key, value]) => {
    if (value) {
      newSet.add(externalSurveyPrefix + key);
    }
  });

  Object.entries(prefs.contentLibraryDocuments).forEach(([key, value]) => {
    if (value) {
      newSet.add(docRepoPrefix + key);
    }
  });

  return newSet;
};

const stringSetToPrefs = (
  sources: GetGptAutofillSourcesResp,
  set: Set<string>
): GptUserPreferences => {
  const prefs: GptUserPreferences = {
    contentLibraryDocuments: {},
    externalDocuments: {},
    platformSurveys: {},
  };

  sources.platformSurveys?.forEach((ps) => {
    const id = SurveyIDWithPublicKey(ps);
    prefs.platformSurveys[id] = set.has(platformSurveyPrefix + id);
  });

  sources.excelSources?.forEach((es) => {
    prefs.externalDocuments[es.uuid] = set.has(externalSurveyPrefix + es.uuid);
  });

  sources.pdfSources?.forEach((ps) => {
    prefs.contentLibraryDocuments[ps.uuid] = set.has(docRepoPrefix + ps.uuid);
  });

  return prefs;
};

interface AutofillModalV2Props extends BaseModalProps {
  surveyID: number;
  isPublic: boolean;
}

const AutofillModalV2: FC<AutofillModalV2Props> = ({
  active,
  onClose,
  surveyID,
  isPublic,
}) => {
  const dispatch = useAppDispatch();

  const [emptyOnly, setEmptyOnly] = useState(false);
  const [rememberSelection, setRememberSelection] = useState(true);
  const [documentFilterState, setDocumentFilterState] = useState(
    initialDocumentFilterState()
  );

  const perms = useBasicPermissions();
  const userHasWriteContentLibraryPerm =
    !!perms.userPermissions[UserWriteContentLibrary];
  const userHasReadContentLibraryPerm =
    !!perms.userPermissions[UserReadContentLibrary];

  const [filterOpen, setFilterOpen] = useState(false);

  const [selectedDocs, setSelectedDocs] = useState(new Set<string>());

  const canRunAutofill = selectedDocs.size > 0;

  const { data: sources, isFetching: sourcesLoading } =
    SurveyViewerAPI.useGetGptSourcesQuery({
      surveyID,
      isPublic,
    });

  // On opening the modal fire the analytics event
  useEffect(() => {
    if (active) {
      trackEvent("SurveyViewer/AIAutofillModalOpened");
    }
  }, [active]);

  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  // when our sources load any user prefs we have them
  useEffect(() => {
    if (sources && !initiallyLoaded) {
      setSelectedDocs(prefsToStringSet(sources.preferences));
      setInitiallyLoaded(true);
    }
  }, [sources, initiallyLoaded]);

  const [mode, setMode] = useState<"select" | "configure">("select");

  const onOpenConfigureSourceModalClose = useCallback(
    () => setMode("select"),
    []
  );
  const [openConfigureSourceModal, configureSourceModal] = useModalV2(
    GptAutofillConfigureSourceModal,
    onOpenConfigureSourceModalClose
  );

  const makePollerForExtraction = useCallback(
    (uuid: string) => {
      const interval = setInterval(async () => {
        const done = await dispatch(pollGptDocumentExtraction(uuid));
        if (done) {
          clearInterval(interval);
          dispatch(
            addDefaultSuccessAlert(
              "Successfully extracted answers for uploaded document"
            )
          );
          dispatch(invalidateSources(surveyID, isPublic));
          setSelectedDocs(
            (prev) => new Set([...prev, externalSurveyPrefix + uuid])
          );
        }
      }, 5000);
    },
    [dispatch, surveyID, isPublic]
  );

  const onExtractedAnswers = useCallback(
    (uuid: string) => {
      dispatch(addDefaultSuccessAlert(`Q&A columns successfully updated`));
      makePollerForExtraction(uuid);
      setMode("select");
    },
    [dispatch, makePollerForExtraction]
  );

  const onEditConfig = useCallback(
    (uuid: string) => {
      setMode("configure");
      openConfigureSourceModal({
        externalDocUUID: uuid,
        onExtractedAnswers,
      });
    },
    [openConfigureSourceModal, onExtractedAnswers]
  );

  const [uploadExternalDoc] = SurveyViewerAPI.useUploadExternalDocMutation();
  const [filesUploading, setFilesUploading] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const findDuplicates = useFindDuplicateDocumentsForFile(selectedFiles);

  const onSubmit = useCallback(async () => {
    if (!selectedFiles || selectedFiles.length == 0) {
      return;
    }

    setFilesUploading(true);
    await Promise.all(
      selectedFiles.map(async (f) => {
        try {
          const resp = await uploadExternalDoc({ file: f }).unwrap();
          // if this is a PDF then we can select it straight way
          const uuid =
            (resp.contentLibDoc?.uuid
              ? docRepoPrefix + resp.contentLibDoc.uuid
              : undefined) ??
            (resp.document?.uuid
              ? externalSurveyPrefix + resp.document.uuid
              : undefined);
          if (
            uuid &&
            (resp.document?.docType == "PDF" ||
              resp.contentLibDoc?.name?.toLowerCase()?.endsWith(".pdf"))
          ) {
            setSelectedDocs((prev) => new Set([...prev, uuid]));
          }
          dispatch(addDefaultSuccessAlert(`Added file ${f.name}`));
        } catch {
          dispatch(addDefaultUnknownErrorAlert(`Could not add file ${f.name}`));
        }
      })
    ).finally(() => {
      setSelectedFiles(undefined);
      setFilesUploading(false);
    });
  }, [selectedFiles]);

  const [openDuplicateConfirmationModal, duplicateConfirmationModal] =
    useModalV2(DuplicateDocumentConfirmationModal, () =>
      setFilesUploading(false)
    );

  const checkForDuplicates = useCallback(async () => {
    setFilesUploading(true);
    try {
      if (!userHasWriteContentLibraryPerm) {
        // don't need to worry about content lib, so just go straight to submit
        await onSubmit();
        return;
      }

      const duplicateDocuments = await findDuplicates();
      if (duplicateDocuments.documents.length > 0) {
        openDuplicateConfirmationModal({
          onSubmit,
          duplicateDocuments,
          closeAfterSubmit: true,
        });
        return;
      }

      await onSubmit();
    } catch (e) {
      setFilesUploading(false);
    }
  }, [findDuplicates, selectedFiles, userHasWriteContentLibraryPerm]);

  // this seems really hacky but not sure how else to do it with hooks...
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  useEffect(() => {
    if (triggerSubmit) {
      checkForDuplicates();
      setTriggerSubmit(false);
    }
  }, [checkForDuplicates, triggerSubmit]);

  const onFileDrop: DropzoneOptions["onDrop"] = useCallback(
    async (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (rejectedFiles.length > 0) {
        dispatch(
          addSimpleErrorAlert("One or more of your files is unsupported", [
            `Ensure your files are .pdf or .xlsx and are under ${MaxFileSizeDisplay}`,
          ])
        );
        return;
      }
      setFilesUploading(true);

      // if we are using the context library ensure that none of our files are duplicates
      // and show a warning if they are
      setSelectedFiles(acceptedFiles);
      // checkForDuplicates();
      setTriggerSubmit(true);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MaxFileSizeB,
    accept: [".pdf", ".xlsx"],
    onDrop: onFileDrop,
    disabled: false,
    noClick: false,
    multiple: true,
  });

  const [deleteContentLibraryDocs] =
    ContentLibraryAPI.useDeleteContentLibraryDocumentsMutation();
  const [archiveContentLibraryDocs] =
    ContentLibraryAPI.useArchiveContentLibraryDocumentsMutation();
  const [deleteGptSourceDoc] = SurveyViewerAPI.useDeleteExternalDocMutation();

  const [loading, setLoading] = useState(false);

  const [beginAutofill] = SurveyViewerAPI.useGptBeginAutofillMutation();

  const onBeginAutofill = useCallback(() => {
    if (!sources) {
      return;
    }

    setLoading(true);

    const gptSources = stringSetToPrefs(sources, selectedDocs);
    console.log({ gptSources, sources, selectedDocs });

    trackEvent("SurveyViewer/beginGptAutofill", {
      numPlatformSurveys: Object.values(gptSources.platformSurveys).filter(
        (s) => s
      ).length,
      numExternalDocuments: Object.values(gptSources.externalDocuments).filter(
        (s) => s
      ).length,
      numDocReopDocs: Object.values(gptSources.contentLibraryDocuments).filter(
        (s) => s
      ).length,
      surveyID,
      isPublic,
    });

    beginAutofill({
      surveyID: surveyID,
      isPublic: isPublic,
      overrideExistingAnswers: !emptyOnly,
      sources: gptSources,
    })
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch(() =>
        dispatch(addDefaultUnknownErrorAlert("Error starting autofill"))
      )
      .finally(() => setLoading(false));
  }, [surveyID, isPublic, sources, selectedDocs, emptyOnly]);

  const [openConfirmationModal, confirmationModal] =
    useModalV2(ConfirmationModalV2);

  const onDeleteDoc = useCallback(
    (uuid: string, isContentLib: boolean) => {
      openConfirmationModal({
        dangerousAction: true,
        title: "Delete document?",
        description: isContentLib
          ? "This will also remove this document from the content library."
          : undefined,
        width: 600,
        buttonAction: () => {
          setLoading(true);

          const prom = isContentLib
            ? deleteContentLibraryDocs({ uuids: [uuid] })
            : deleteGptSourceDoc({ uuid });

          return prom
            .unwrap()
            .then(() => {
              dispatch(addDefaultSuccessAlert("Document deleted"));
              dispatch(invalidateSources(surveyID, isPublic));
            })
            .catch(() => {
              dispatch(addDefaultUnknownErrorAlert("error deleting document"));
            })
            .finally(() => {
              setLoading(false);
            });
        },
        buttonText: "Delete",
        iconClass: "cr-icon-trash",
      });
    },
    [
      openConfirmationModal,
      setLoading,
      deleteContentLibraryDocs,
      deleteGptSourceDoc,
      dispatch,
      surveyID,
      isPublic,
    ]
  );

  const onArchiveDoc = useCallback(
    (uuid: string) => {
      openConfirmationModal({
        title: "Archive document?",
        description:
          "This document will be moved to the Archive section of your content library.",
        width: 600,
        buttonAction: () => {
          setLoading(true);

          return archiveContentLibraryDocs({ uuids: [uuid], setArchived: true })
            .unwrap()
            .then(() => {
              dispatch(addDefaultSuccessAlert("Archived document"));
              dispatch(invalidateSources(surveyID, isPublic));
            })
            .catch(() => {
              dispatch(addDefaultUnknownErrorAlert("error archiving document"));
            })
            .finally(() => {
              setLoading(false);
            });
        },
        buttonText: "Archive",
        iconClass: "cr-icon-archive",
      });
    },
    [
      openConfirmationModal,
      setLoading,
      dispatch,
      archiveContentLibraryDocs,
      surveyID,
      isPublic,
    ]
  );

  type combinedDoc = {
    id: string;
    uuid: string;
    name: string;
    type: string;
    date?: string;
    author?: string;
    needsConfig: boolean;
    hasConfig?: boolean;
    isExtracting?: boolean;
    trustPageDoc: boolean;
    onDelete?: () => void;
    onArchive?: () => void;
    linkLocation?: string;
  };

  const combinedDocs: combinedDoc[] = useMemo(
    () =>
      !sources
        ? []
        : [
            ...(sources.excelSources?.map((es) => ({
              id: externalSurveyPrefix + es.uuid,
              uuid: es.uuid,
              name: es.name,
              type: es.documentRepositoryType ?? es.docType,
              hasConfig: true,
              needsConfig: !es.processed,
              date: es.createdAt,
              trustPageDoc: !!es.orgID,
              isExtracting: (es.extractionProgress ?? 1) < 1,
              author: sources.users[es.userID]?.name,
              onDelete:
                (userHasWriteContentLibraryPerm && es.orgID) || !es.orgID
                  ? () =>
                      onDeleteDoc(
                        es.documentRepositoryUUID ?? es.uuid,
                        !!es.orgID
                      )
                  : undefined,
              onArchive:
                userHasWriteContentLibraryPerm && es.documentRepositoryUUID
                  ? () => {
                      onArchiveDoc(es.documentRepositoryUUID ?? "");
                    }
                  : undefined,
              linkLocation: es.documentRepositoryUUID
                ? `/contentlibrary/document/${es.documentRepositoryUUID}`
                : undefined,
            })) ?? []),
            ...(sources.pdfSources?.map((pdf) => ({
              id: docRepoPrefix + pdf.uuid,
              uuid: pdf.uuid,
              name: pdf.name,
              type: pdf.documentType,
              needsConfig: false,
              date: pdf.createdAt,
              author: sources.users[pdf.createdBy]?.name,
              trustPageDoc: true,
              onDelete: userHasWriteContentLibraryPerm
                ? () => onDeleteDoc(pdf.uuid, true)
                : undefined,
              onArchive: userHasWriteContentLibraryPerm
                ? () => onArchiveDoc(pdf.uuid)
                : undefined,
              linkLocation: `/contentlibrary/document/${pdf.uuid}`,
            })) ?? []),
            ...(sources.platformSurveys?.map((ps) => ({
              id: platformSurveyPrefix + SurveyIDWithPublicKey(ps),
              uuid: "",
              name: ps.name ?? "",
              type: "Platform questionnaire",
              date: ps.publishedAt,
              author: !ps.isPublic
                ? sources.users[sources.surveyUsers[ps.surveyID]]?.name
                : undefined,
              needsConfig: false,
              trustPageDoc: false,
              linkLocation: ps.isPublic ? undefined : `/surveys/${ps.surveyID}`,
            })) ?? []),
          ],
    [sources, userHasWriteContentLibraryPerm, onDeleteDoc]
  );

  const hasDocs = combinedDocs.length > 0;

  const filteredDocs = useMemo(
    () =>
      combinedDocs.filter(
        (c) =>
          (!documentFilterState.trustPageOnly || c.trustPageDoc) &&
          (documentFilterState.selectedDocumentTypes.length == 0 ||
            documentFilterState.selectedDocumentTypes.includes(c.type)) &&
          dateMatchesFilter(c.date ?? "", documentFilterState.dateFilter) &&
          (documentFilterState.filterText == "" ||
            c.name
              .toLowerCase()
              .includes(documentFilterState.filterText.toLowerCase()))
      ),
    [combinedDocs, documentFilterState]
  );

  type column = "document" | "type" | "updated" | "actions";
  const columns: IXTableColumnHeader<column>[] = [
    {
      id: "document",
      text: "Document",
      sortable: true,
    },
  ];

  if (userHasReadContentLibraryPerm) {
    columns.push({
      id: "type",
      text: "Type",
      sortable: true,
    });
  }

  columns.push(
    ...[
      {
        id: "updated" as column,
        text: "Last updated",
        sortable: true,
      },
      {
        id: "actions" as column,
        text: "Actions",
        sortable: false,
      },
    ]
  );

  const [sortedDocs, sortedBy, onSortChange] = useSorting<combinedDoc>(
    filteredDocs,
    "updated",
    SortDirection.DESC,
    {
      document: {
        orderFuncs: [(d) => d.name],
      },
      type: {
        orderFuncs: [(d) => d.type],
      },
      updated: {
        orderFuncs: [(d) => d.date],
      },
    }
  );

  const rows: IXTableRow<string>[] = useMemo(() => {
    if (!sortedDocs) {
      return [];
    }

    return sortedDocs.map((doc) => {
      const dropdownItems = [];

      if (doc.onArchive) {
        dropdownItems.push(
          <DropdownItem onClick={doc.onArchive} key={"on-archive"}>
            <i className={"cr-icon-archive"} /> Archive
          </DropdownItem>
        );
      }

      if (doc.onDelete) {
        dropdownItems.push(
          <DropdownItem onClick={doc.onDelete} key={"on-delete"}>
            <i className={"cr-icon-trash"} /> Delete
          </DropdownItem>
        );
      }

      const iconOptions: IIconOption[] = [];

      if (doc.hasConfig) {
        if (!doc.isExtracting) {
          iconOptions.push({
            id: "edit",
            icon: <i className={"cr-icon-pencil"} />,
            attention: doc.needsConfig,
            onClick: () => onEditConfig(doc.uuid),
            hoverColor: HoverColor.Blue,
            hoverText: "Edit source",
            hoverLocation: HoverLocation.Top,
            hoverMicro: true,
          });
        } else {
          iconOptions.push({
            id: "loading",
            icon: <LoadingIcon size={12} />,
            hoverText: "extracting info",
            hoverLocation: HoverLocation.Top,
            hoverMicro: true,
          });
        }
      }

      if (dropdownItems.length > 0) {
        iconOptions.push({
          id: `actions`,
          icon: <i className={"cr-icon-dots-menu"} />,
          dropdownItems: dropdownItems,
          hoverText: "Actions",
          hoverLocation: HoverLocation.Top,
          hoverMicro: true,
        });
      }

      const cells = [
        <XTableCell width={300} key="name" className="name-cell">
          <div className="name-cell-inner">
            {doc.type == "Platform questionnaire" ? (
              <i className={"survey-icon cr-icon-q-builder-radio"} />
            ) : (
              <img
                className="file-type"
                alt="File type icon"
                src={GetIconForFilename(
                  doc.name.slice(doc.name.lastIndexOf("."))
                )}
              />
            )}
            <div title={doc.name} className={"name"}>
              {doc.linkLocation ? (
                <a onClick={() => window.open(doc.linkLocation, "_blank")}>
                  {doc.name}
                </a>
              ) : (
                doc.name
              )}
            </div>
          </div>
        </XTableCell>,
      ];

      if (userHasReadContentLibraryPerm) {
        cells.push(
          <XTableCell key="type" className="doc-type-cell">
            {doc.type}
          </XTableCell>
        );
      }

      cells.push(
        ...[
          <XTableCell key="updated" className="updated-cell">
            <SidePopupV2
              text={moment(doc.date).format("LLL")}
              inline
              micro
              noWrap
              position="top"
            >
              {moment(doc.date).format("ll")}
            </SidePopupV2>
            {doc.author ? `, ${doc.author}` : ""}
          </XTableCell>,
        ]
      );

      return {
        id: doc.id,
        iconOptions,
        selected: selectedDocs.has(doc.id),
        selectionDisabled: doc.needsConfig || doc.isExtracting,
        selectionDisabledHelpText:
          "Please configure this document before selecting",
        cells,
      };
    });
  }, [sortedDocs]);

  const selectableRowIds = useMemo(
    () => rows.filter((c) => !c.selectionDisabled).map((c) => c.id),
    [rows]
  );

  const [pagnentedRows, pageNum, totalPages, onSetPage] = usePagination(
    rows,
    7
  );

  const { onSelectClick, onSelectAllClick, onSelectNoneClick } =
    useSelectableRows(selectedDocs, setSelectedDocs, rows);

  const onSelectToggle = useCallback(() => {
    if (selectedDocs.size == selectableRowIds.length) {
      onSelectNoneClick();
    } else {
      onSelectAllClick();
    }
  }, [selectedDocs, selectableRowIds, onSelectAllClick, onSelectNoneClick]);

  const { data: documentTypes } =
    ContentLibraryAPI.useGetContentLibraryDocumentTypesListQuery();

  const [tipDismissed, setTipDismissed] = useLocalStorageState(
    "SurveyAutofillTip",
    false
  );

  return (
    <>
      <ModalV2
        active={active && mode == "select"}
        onClose={onClose}
        width={1000}
        className={"autofill-modal-v2"}
        headerClassName={"autofill-modal-v2-header"}
        headerContent={
          <>
            <h2 className={"title"}>Upload and select documents</h2>
          </>
        }
        footerClassName={"autofill-modal-v2-footer"}
        footerContent={
          <>
            <div className={"footer-left"}>
              <ToggleWithLabel
                name={"empty-only"}
                onClick={() => setEmptyOnly((v) => !v)}
                selected={emptyOnly}
                label={
                  <div className={"toggle-label-inner"}>
                    Autofill empty fields only
                    <SidePopupV2 text={"Turn on to only answer empty fields."}>
                      <i className={"cr-icon-info"} />
                    </SidePopupV2>
                  </div>
                }
              />
            </div>
            <div>
              <Button onClick={onClose} tertiary>
                Cancel
              </Button>
              <Button
                onClick={onBeginAutofill}
                primary
                disabled={!canRunAutofill}
              >
                Run autofill
              </Button>
            </div>
          </>
        }
      >
        {!tipDismissed && (
          <div className={"tip"}>
            <i className={"cr-icon-bulb"} />
            Tip: Add a SOC 2 report and a past questionnaire to answer most of
            this questionnaire instantly.
            <Button
              className={"right"}
              tertiary
              onClick={() => setTipDismissed(true)}
            >
              Dismiss
            </Button>
          </div>
        )}
        <FileDropzone
          className={classNames("autofill-dropzone", {
            slim: hasDocs || sourcesLoading,
            loading: filesUploading,
          })}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          uploading={filesUploading}
        >
          <div className={"desc"}>
            <div className={"top-line"}>
              <i className={"cr-icon-paperclip"} />
              <span className={"blue"}>Click</span> or drag here to upload
            </div>
            {!hasDocs && (
              <div className={"bottom-line"}>.xlsx and .pdf files accepted</div>
            )}
          </div>
        </FileDropzone>
        {(hasDocs || sourcesLoading) && (
          <div className={"documents-section"}>
            <div className={"search-and-filter"}>
              <SearchBox
                onChanged={(filterText) =>
                  setDocumentFilterState((prev) => ({ ...prev, filterText }))
                }
                value={documentFilterState.filterText}
              />
              <div className={"filter-and-surface"}>
                <Button
                  active={filterOpen}
                  onClick={() => setFilterOpen((prev) => !prev)}
                >
                  <i className={"cr-icon-filter"} />
                  {"Filter" +
                    (documentsFilterActive(documentFilterState)
                      ? ": " + documentFilterText(documentFilterState)
                      : "")}
                </Button>
                {filterOpen && (
                  <DocumentSelectionFilter
                    initialFilterState={documentFilterState}
                    setFilterState={setDocumentFilterState}
                    documentTypes={
                      documentTypes?.types.map((t) => t.name) ?? []
                    }
                    active={filterOpen}
                    onClose={() => setFilterOpen(false)}
                  />
                )}
              </div>
            </div>

            {selectedDocs.size > 0 && (
              <div className={"selection-info-box"}>
                <div className={"left"}>
                  <span className={"text"}>
                    {selectedDocs.size == selectableRowIds.length && "All "}
                    {selectedDocs.size} documents selected
                  </span>
                  <span className={"divider"}>|</span>
                  <Button
                    link
                    onClick={
                      selectedDocs.size == selectableRowIds.length
                        ? onSelectNoneClick
                        : onSelectAllClick
                    }
                  >
                    {selectedDocs.size == selectableRowIds.length
                      ? "Clear selection"
                      : `Select all ${selectableRowIds.length} selectable documents`}
                  </Button>
                </div>
                <div className={"right"}>
                  <ToggleWithLabel
                    onClick={() => setRememberSelection((val) => !val)}
                    name={"remember-selection"}
                    selected={rememberSelection}
                    label={
                      <div className={"toggle-label-inner"}>
                        Remember my selection
                        <SidePopupV2
                          text={
                            "Turn on to keep these documents selected for next time."
                          }
                        >
                          <i className={"cr-icon-info"} />
                        </SidePopupV2>
                      </div>
                    }
                  />
                </div>
              </div>
            )}

            <XTable<string, column>
              className={"docs-table"}
              selectable
              columnHeaders={columns}
              rows={pagnentedRows}
              loading={sourcesLoading || loading || filesUploading}
              sortedBy={sortedBy}
              onSortChange={onSortChange}
              pagination={{
                currentPage: pageNum,
                totalPages,
                hidePaginationIfSinglePage: true,
                onPageChange: onSetPage,
              }}
              onSelectAllClick={onSelectAllClick}
              onSelectNoneClick={onSelectNoneClick}
              onSelectToggle={onSelectToggle}
              onSelectClick={onSelectClick}
              iconOptions
              compact
            />
            {rows.length == 0 &&
              (documentsFilterActive(documentFilterState) ||
                documentFilterState.filterText != "") && (
                <SearchEmptyCard
                  onClear={() =>
                    setDocumentFilterState(initialDocumentFilterState())
                  }
                  searchItemText={"documents"}
                />
              )}
          </div>
        )}
        {confirmationModal}
      </ModalV2>
      {configureSourceModal}
      {duplicateConfirmationModal}
    </>
  );
};

export default AutofillModalV2;
