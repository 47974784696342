import { FC, ReactNode } from "react";
import PageHeaderV2, {
  PageHeaderV2Props,
} from "../../../_common/components/pageHeader/PageHeaderV2";
import UserBaseAPI from "../../api/userbase.api";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../../vendorrisk/components/Score";
import { pluralise } from "../../../_common/helpers";
import { App, AppStatus } from "../../api/types";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import AppStatusDisplay from "../applications/AppStatusDisplay";
import { PageTitleSectionProps } from "../../../_common/components/pageHeader/PageTitleSection";
import { HeaderActionGroup } from "../../../_common/components/pageHeader/MainSection";
import { useModalV2 } from "../../../_common/components/ModalV2";
import AppApprovalModal, {
  AppApprovalModalMode,
} from "../applications/AppApprovalModal";
import AppInReviewModal from "../applications/AppInReviewModal";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../../_common/permissions";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import "./UserBaseApplicationHeader.scss";

interface UserBaseApplicationHeaderProps extends PageTitleSectionProps {
  appName: string;
}

const UserBaseApplicationHeader: FC<UserBaseApplicationHeaderProps> = ({
  appName,
  ...restProps
}) => {
  const [openAppApprovalModal, appApprovalModal] = useModalV2(AppApprovalModal);
  const [openAppInReviewModal, appInReviewModal] = useModalV2(AppInReviewModal);

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const { data } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  const app = data?.app;
  const appDisplayName = app?.name ?? "Application";
  const appDomain = app?.domain;
  const biggestAppRiskLevel = app?.highestScopeRiskLevel;
  const appUsers = app?.numCurrentUsers;
  const appScore = app?.securityScore;

  const onManageAppAccess = (app: App) => {
    openAppApprovalModal({ app, modalMode: AppApprovalModalMode.ApproveApp });
  };
  const onDoNotApproveApp = (app: App) => {
    openAppApprovalModal({
      app,
      modalMode: AppApprovalModalMode.DoNotApprovedApp,
    });
  };
  const onMarkAsInReview = (app: App) => {
    openAppInReviewModal({ app });
  };

  const nameWidgets: ReactNode[] = [];
  const metadataWidgets: ReactNode[] = [];
  const actionGroups: HeaderActionGroup[] = [];
  if (!!app) {
    nameWidgets.push(
      <>
        <ColorGrade score={appScore} size={ColorGradeSize.Small} />
        <Score score={appScore} small />
      </>
    );

    metadataWidgets.push(
      <>
        <div className={`header-icon cr-icon-users-empty`} />
        <div className="header-label">
          {appUsers} {pluralise(appUsers, "user", "users")}
        </div>
      </>,
      <>
        <div className={"exposure-level"}>Exposure level:</div>
        <ScopeRiskLevelDisplay riskLevel={biggestAppRiskLevel} />
      </>,
      !!app ? (
        <AppStatusDisplay app={app} large hoverPosition={"bottom"} />
      ) : (
        <></>
      )
    );

    if (userHasUserBaseWrite) {
      const manageOptions = [
        {
          text:
            app.status === AppStatus.ApprovedAppStatus
              ? "Manage application access"
              : "Approve application",
          description: "Grant access for all or selected teams",
          onClick: () => onManageAppAccess(app),
        },
      ];
      if (app.status !== AppStatus.InReviewAppStatus) {
        manageOptions.push({
          text: "Mark as ‘in review’",
          description: "Review this application",
          onClick: () => onMarkAsInReview(app),
        });
      }
      if (app.status !== AppStatus.NotApprovedAppStatus) {
        manageOptions.push({
          text: "Do not approve application",
          description: "Deny access for this application",
          onClick: () => onDoNotApproveApp(app),
        });
      }
      actionGroups.push({
        kind: "dropdown",
        text: "Manage access",
        options: manageOptions,
      });
    }
  }

  const appIcon = <CompanyLogo domain={appDomain} name={appDisplayName} />;

  const pageHeaderV2Props: PageHeaderV2Props = {
    ...restProps,
    name: appDisplayName,
    domain: appDomain,
    icon: appIcon,
    className: "userbase-header",
    nameWidgets,
    actionGroups,
    metadataWidgets,
  };

  return (
    <>
      <PageHeaderV2 {...pageHeaderV2Props} />
      {appApprovalModal}
      {appInReviewModal}
    </>
  );
};

export default UserBaseApplicationHeader;
