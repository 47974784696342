import { ReactNode, useState } from "react";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import ThreatMonitoringAPI, {
  ThreatMonitoringTagTypes,
} from "../api/threatmonitoring.api";
import {
  ThreatMonitoringHistoryTarget,
  ThreatMonitoringResultStateType,
  ThreatMonitoringResultsFeedType,
} from "../api/types";

// useUpdateResultState provides a hook for updating the state of a result in the context of a feed.
export default function useUpdateResultState({
  feedType,
  state,
  successMsg,
  failMsg,
  finally: finallyCb,
}: {
  feedType: ThreatMonitoringResultsFeedType;
  state: ThreatMonitoringResultStateType;
  successMsg: ReactNode | ((uuids: string[]) => ReactNode) | null;
  failMsg: string | ((uuids: string[]) => string);
  finally?: () => void;
}) {
  const dispatch = useAppDispatch();
  const [updateResultState] =
    ThreatMonitoringAPI.useUpdateResultStateSimpleV1Mutation();

  const [isUpdating, setIsUpdating] = useState(false);

  const update = async (
    uuidParam: string | string[],
    opts?: { target?: ThreatMonitoringHistoryTarget }
  ) => {
    const uuids = Array.isArray(uuidParam) ? uuidParam : [uuidParam];

    if (uuids.length === 0) {
      return;
    }

    try {
      setIsUpdating(true);
      await updateResultState({ uuids, state, target: opts?.target });

      if (successMsg === null) {
        // If successMsg is explicitly null, don't show a success message.
      } else if (typeof successMsg === "function") {
        dispatch(addDefaultSuccessAlert(successMsg(uuids)));
      } else {
        dispatch(addDefaultSuccessAlert(successMsg));
      }

      dispatch(
        ThreatMonitoringAPI.util.invalidateTags([
          {
            type: ThreatMonitoringTagTypes.results,
            id: feedType,
          },
        ])
      );
    } catch (error) {
      console.error("Error updating result state", error);

      if (typeof failMsg === "function") {
        dispatch(addDefaultUnknownErrorAlert(failMsg(uuids)));
      } else {
        dispatch(addDefaultUnknownErrorAlert(failMsg));
      }
    } finally {
      setIsUpdating(false);
      finallyCb?.();
    }
  };

  return [update, isUpdating] as const;
}
