import vendorSecurityProfileAPI from "../../reducers/vendorSecurityProfileAPI";
import StatusIcon from "./StatusIcon";
import SeverityIcon, {
  AdjustedSeverityIcon,
} from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { useHistory, useLocation } from "react-router-dom";
import { FC, useCallback } from "react";
import { OpenSidePanel } from "../DomainsAndIPsPanelGroup";
import { locationState } from "../../../_common/types/router";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "../../style/components/securityprofile/CheckResultLine.scss";
import { ControlState } from "../../types/securityProfile";

interface CheckResultLineProps {
  vendorId: number;
  controlId: string;
  checkId: string;
}

const CheckResultLine: FC<CheckResultLineProps> = ({
  vendorId,
  controlId,
  checkId,
}) => {
  const { data: controlData } =
    vendorSecurityProfileAPI.useGetVendorControlDataQuery({
      vendorId: vendorId,
      controlId: controlId,
    });

  const { data: securityProfile } =
    vendorSecurityProfileAPI.useGetSecurityProfileQuery({
      vendorId: vendorId,
    });

  // find the check for this and then find the relevant evidence which will either be
  // a scanning risk or document evidence
  const check = controlData?.control.checks.find((c) => c.id == checkId);
  const checkState = securityProfile?.controlStates[checkId];
  const documentCheck = securityProfile?.documentChecks[checkId];
  const scanningChecks = securityProfile?.scanningChecks[checkId];

  const history = useHistory();
  const location = useLocation<locationState>();

  const openCheckPanel = useCallback(
    (scanRiskId?: string) =>
      OpenSidePanel(
        history,
        {
          vendorId,
          checkId,
          scanRiskId,
        },
        controlData?.control.text,
        location
      ),
    [vendorId, checkId, controlData, history, location]
  );

  if (!check) {
    return <></>;
  }

  // if we have scanning risks then output one line per result
  if (scanningChecks) {
    return (
      <>
        {scanningChecks.map((scanCheck) => (
          <div
            key={scanCheck.id}
            className={"check-result-line"}
            onClick={() => openCheckPanel(scanCheck.id)}
          >
            <div className={"icon"}>
              {checkState == ControlState.Failed ? (
                <AdjustedSeverityIcon
                  severity={SeverityAsString(scanCheck.severity)}
                  baseSeverity={
                    scanCheck.baseSeverity
                      ? SeverityAsString(scanCheck.baseSeverity)
                      : undefined
                  }
                />
              ) : (
                <StatusIcon controlState={checkState ?? ControlState.Unknown} />
              )}
            </div>
            <div className={"check-text"}>
              <div className={"header-text"}>{scanCheck.title}</div>
            </div>
            <i className={"cr-icon-chevron "} />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={"check-result-line"} onClick={() => openCheckPanel()}>
      <div className={"icon"}>
        {checkState == ControlState.Failed ? (
          <SeverityIcon severity={SeverityAsString(check.severity)} />
        ) : (
          <StatusIcon controlState={checkState ?? ControlState.Unknown} />
        )}
      </div>
      <div className={"check-text"}>
        <div className={"header-text"}>{check.text}</div>
        {(documentCheck?.length ?? 0) > 0 && (
          <PillLabel color={LabelColor.Blue}>
            {documentCheck?.length} citation
            {(documentCheck?.length ?? 0) != 1 && "s"}
          </PillLabel>
        )}
      </div>
      <i className={"cr-icon-chevron "} />
    </div>
  );
};

export default CheckResultLine;
