import { VendorAssessmentStream } from "../../reducers/vendorAssessmentAPI";
import { FC, useState } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import classNames from "classnames";
import ColorCheckbox from "../ColorCheckbox";
import "../../style/components/vendor_assessment/NewAssessmentConfirmModal.scss";

interface NewAssessmentConfirmModalProps extends BaseModalProps {
  draftAssessments: VendorAssessmentStream[];
  onContinueAssessment: (streamId: number) => void;
  onStartNewAssessment: () => void;
}

const NewAssessmentConfirmModal: FC<NewAssessmentConfirmModalProps> = (
  props
) => {
  const [selectedStream, setSelectedStream] = useState(
    props.draftAssessments.length == 1
      ? props.draftAssessments[0].id
      : undefined
  );

  return (
    <ModalV2
      className={"confirm-new-assessment-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={"Are you sure you want to create a new assessment?"}
      footerContent={
        <>
          <div className={"footer-left"}>
            <Button
              onClick={() => {
                props.onClose();
                props.onStartNewAssessment();
              }}
            >
              <i className={"cr-icon-plus"} />
              Create new assessment
            </Button>
          </div>
          <div className={"left-content"}>
            <Button
              primary
              disabled={selectedStream == undefined}
              onClick={
                selectedStream
                  ? () => props.onContinueAssessment(selectedStream)
                  : undefined
              }
              arrow
            >
              Continue
            </Button>
          </div>
        </>
      }
    >
      <div className={"count-text"}>
        You currently have {props.draftAssessments.length} risk assessment
        {props.draftAssessments.length > 1 ? "s" : ""} in progress:
      </div>
      {props.draftAssessments.map((a) => (
        <div
          className={classNames("draft-assessment-block", {
            single: props.draftAssessments.length == 1,
            selected: selectedStream == a.id,
          })}
          key={a.id}
        >
          <ColorCheckbox
            checked={selectedStream == a.id}
            onClick={() => setSelectedStream(a.id)}
            radio
            disabled={props.draftAssessments.length == 1}
            label={
              <div className={"assessment-label"}>
                <div className={"assessment-title"}>{a.name}</div>
                <div className={"assessment-author"}>
                  Started by {a.authorName}
                </div>
              </div>
            }
          />
        </div>
      ))}
    </ModalV2>
  );
};

export default NewAssessmentConfirmModal;
