import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import {
  OrganisationAcceptedRisk,
  OrganisationAcceptedRiskStatus,
  RiskAssetType,
  updateCustomerAcceptedRiskOpts,
} from "../../reducers/customerAcceptedRisks.actions";
import Button from "../../../_common/components/core/Button";
import { useEffect, useMemo, useState } from "react";
import { TextFieldData } from "../../../_common/components/TextField";
import moment from "moment";
import { IUserMiniMap } from "../../../_common/types/user";
import "../../style/components/risk_waivers/EditRiskWaiverModal.scss";
import { Steps } from "../../../_common/components/StepsWithSections";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
} from "../../../_common/types/vendorSummary";
import {
  calculateCustomerWaivedAssetsForRisk,
  WaivedRiskAssetsMap,
} from "../../reducers/risks.actions";
import { isEqual as _isEqual } from "lodash";
import OrganisationFlagsAPI from "../../../_common/api/organisationFlagsAPI";
import BreachSightWaiverDetailsStep, {
  ApproverType,
  ExpireType,
} from "./BreachSightWaiverDetailsStep";
import BreachSightWaiverConfirmStep from "./BreachSightWaiverConfirmStep";
import {
  AssetSelection,
  getBlankAssetSelection,
  getRiskAssetText,
  RiskAssetSelectionForWaiver,
} from "./SelectRiskTable";
import {
  cloudscanSeverityNumberToText,
  LogError,
  severityMap,
} from "../../../_common/helpers";
import { getRepoManifestLocation } from "../../../appguard/api/manifestLocation.helper";

interface EditRiskWaiverModalProps extends BaseModalProps {
  waiver?: OrganisationAcceptedRisk;
  usersMap: IUserMiniMap;
  riskWaivers?: OrganisationAcceptedRisk[];
  risks?: VendorSummaryRisk[];
  canUsePublicWaivers: boolean;
  onUpdate: (opts: updateCustomerAcceptedRiskOpts) => Promise<void>;
}

const EditRiskWaiverModal = ({
  active,
  onClose,
  canUsePublicWaivers,
  waiver,
  usersMap,
  riskWaivers,
  risks,
  onUpdate,
}: EditRiskWaiverModalProps) => {
  const { data: { BreachSightRequireApprovalsRiskWaivers } = {} } =
    OrganisationFlagsAPI.useGetOrganisationFlagsV1Query();

  const [isSaving, setIsSaving] = useState(false);
  const [approverRequired, setApproverRequired] = useState(false);
  const [approverEmail, setApproverEmail] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [expires, setExpires] = useState(false);
  const [expiryDate, setExpiryDate] = useState<string | undefined>();
  const [isPublic, setIsPublic] = useState(false);
  const [justification, setJustification] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [assetSelection, setAssetSelection] = useState<AssetSelection>(
    getBlankAssetSelection()
  );

  useEffect(
    function orgFlagsLoading() {
      setApproverRequired(BreachSightRequireApprovalsRiskWaivers ?? true);
    },
    [BreachSightRequireApprovalsRiskWaivers]
  );

  // Set from existing waiver details on load
  useEffect(() => {
    if (waiver && active) {
      let approverEmail = waiver.approverEmail;
      if (waiver.approverId) {
        approverEmail = usersMap[waiver.approverId]?.email;
      }

      setApproverRequired(!!waiver.approverId || !!waiver.approverEmail);
      setApproverEmail({
        value: approverEmail ?? "",
        isValid: approverEmail !== undefined,
      });
      setExpires(waiver.expiresAt !== undefined && waiver.expiresAt !== null);
      setExpiryDate(
        moment(waiver.expiresAt)
          .utcOffset(moment().utcOffset())
          .format("YYYY-MM-DD")
      );
      setIsPublic(waiver.isPublic);
      setJustification({
        value: waiver.requesterReason ?? waiver.approverReason ?? "",
        isValid: true,
      });
      setCurrentStep(1);

      switch (waiver.riskAssetType) {
        case RiskAssetType.cloudscan:
          setAssetSelection({
            ...assetSelection,
            hostnameSelection: {
              allSelected: waiver.allWebsites,
              includeFuture: waiver.allWebsites,
              selected: waiver.websites,
            },
          });
          break;
        case RiskAssetType.appguardRepo:
          // Done when we also have risk info
          break;
        case RiskAssetType.appguardManifest:
          // Done when we also have get risk info
          break;
        default:
          LogError(
            `missing asset selection for asset type: ${waiver.riskAssetType}`
          );
          setAssetSelection(getBlankAssetSelection());
      }
    }
  }, [waiver, usersMap, active]);

  // Adjust isPublic selection if necessary
  useEffect(() => {
    if (assetSelection.hostnameSelection.includeFuture) {
      setIsPublic(false);
    } else if (
      assetSelection.hostnameSelection.customDomains &&
      assetSelection.hostnameSelection.customDomains.length > 0
    ) {
      setIsPublic(false);
    }
  }, [assetSelection]);

  const waivedAssets = useMemo(() => {
    const risk = risks?.find((r) => r.id === waiver?.riskId);
    if (risk) {
      const waivedAssets = calculateCustomerWaivedAssetsForRisk(
        risk,
        riskWaivers,
        waiver?.id
      );
      return {
        [risk.id]: waivedAssets,
      } as WaivedRiskAssetsMap;
    } else {
      return {} as WaivedRiskAssetsMap;
    }
  }, [waiver, riskWaivers, risks]);

  // Ensure we only select assets that are still currently on the risk
  // Done seperately due to lazy loading domains
  useEffect(() => {
    const risk = risks?.find((r) => r.id === waiver?.riskId);

    if (risk && waiver) {
      switch (waiver.riskAssetType) {
        case RiskAssetType.cloudscan:
          if (
            risk.failedCloudscans &&
            assetSelection.hostnameSelection.selected.length > 0
          ) {
            const selectedDomainsStillOnRisk: string[] = [];
            const riskFailedCloudscansMap: { [hostname: string]: boolean } = {};
            risk.failedCloudscans?.map((c) => {
              riskFailedCloudscansMap[c.hostname] = true;
            });

            assetSelection.hostnameSelection.selected.forEach((w) => {
              if (riskFailedCloudscansMap[w]) {
                selectedDomainsStillOnRisk.push(w);
              }
            });

            if (
              !_isEqual(
                selectedDomainsStillOnRisk,
                assetSelection.hostnameSelection.selected
              )
            ) {
              setAssetSelection({
                ...assetSelection,
                hostnameSelection: {
                  ...assetSelection.hostnameSelection,
                  allSelected: waiver.allWebsites,
                  includeFuture: waiver.allWebsites,
                  selected: selectedDomainsStillOnRisk,
                },
              });
            }
          }
          break;
        case RiskAssetType.appguardRepo:
          const rUuids: string[] = [];

          risk.repositories?.forEach((rr) => {
            const matched = waiver.repoNames.find((wr) => wr === rr.repoName);
            if (matched) {
              rUuids.push(rr.uuid);
            }
          });

          setAssetSelection({
            ...assetSelection,
            repositorySelection: {
              ...assetSelection.repositorySelection,
              isAllSelected: false,
              selectedUuids: rUuids,
            },
          });
          break;
        case RiskAssetType.appguardManifest:
          const mUuids: string[] = [];

          risk.manifests?.forEach((m) => {
            const matched = waiver.manifestLocations.find(
              (wm) =>
                `${getRepoManifestLocation(m.repoName, m.manifestLocation)}` ===
                wm
            );
            if (matched) {
              mUuids.push(m.manifestUUID);
            }
          });

          setAssetSelection({
            ...assetSelection,
            manifestSelection: {
              ...assetSelection.manifestSelection,
              isAllSelected: false,
              selectedUuids: mUuids,
            },
          });
          break;
        default:
          LogError(
            `missing asset selection for asset type: ${waiver.riskAssetType}`
          );
          setAssetSelection(getBlankAssetSelection());
      }
    }
  }, [risks, waiver, assetSelection]);

  // Reset selection when closing modal
  useEffect(() => {
    if (!active) {
      setAssetSelection(getBlankAssetSelection());
    }
  }, [active]);

  const save = () => {
    setIsSaving(true);

    // TODO appguard waivers part 2: update to support other asset types

    onUpdate({
      id: waiver?.id ?? 0,
      changeExpiryDate: expires ? expiryDate : undefined,
      removeExpiryDate: !expires && waiver?.expiresAt !== undefined,
      changeApprover:
        approverRequired &&
        waiver?.status === OrganisationAcceptedRiskStatus.AwaitingApproval
          ? approverEmail.value
          : undefined,
      removeApprover:
        !approverRequired && (!!waiver?.approverId || !!waiver?.approverEmail),
      isPublic: isPublic,
      justification: justification.value,
      domains: assetSelection.hostnameSelection.selected,
      allDomains: assetSelection.hostnameSelection.includeFuture,
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const risk = risks?.find((r) => r.id === waiver?.riskId);

  let disablePublicText: string | undefined = undefined;
  if (
    assetSelection.hostnameSelection.includeFuture ||
    (assetSelection.hostnameSelection.customDomains &&
      assetSelection.hostnameSelection.customDomains.length > 0)
  ) {
    disablePublicText =
      "A public risk waiver cannot include custom domains, or domains & IPs we detect in the future.";
  } else if (!risk || risk?.numFailedCloudscans === 0) {
    disablePublicText =
      "A waiver cannot be set as public if there are no domains/IPs with this risk.";
  }

  const isStepTwoDisabled = () => {
    if (!risk) return true;
    switch (risk?.riskType) {
      case VendorSummaryRiskType.Cloudscan:
        return (
          !assetSelection.hostnameSelection.includeFuture &&
          assetSelection.hostnameSelection.selected.length === 0
        );
      case VendorSummaryRiskType.AppguardPackageVuln:
        return (
          (assetSelection.manifestSelection?.selectedUuids ?? []).length === 0
        );
      case VendorSummaryRiskType.AppguardRepoConfig:
        return (
          (assetSelection.repositorySelection?.selectedUuids ?? []).length === 0
        );
      default:
        return false;
    }
  };

  const isStepThreeDisabled = () => {
    if (isStepTwoDisabled()) {
      return true;
    }

    return (
      (approverRequired && !approverEmail.isValid) ||
      !justification.isValid ||
      (expires &&
        (!expiryDate || moment().add(1, "day").isAfter(expiryDate, "day")))
    );
  };

  return (
    <ModalV2
      width={1000}
      headerClassName={"edit-risk-waiver-modal-header"}
      headerContent={"Edit risk waiver"}
      footerClassName={"edit-risk-waiver-modal-footer"}
      className={"edit-risk-waiver-modal"}
      active={active}
      onClose={onClose}
      footerContent={
        !risk ? (
          <>
            <div className={"btn-group"}>
              <Button tertiary onClick={onClose}>
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            {currentStep == 1 && (
              <>
                <div className={"btn-group"}>
                  <Button tertiary onClick={onClose} disabled={isSaving}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    arrow
                    disabled={isStepTwoDisabled()}
                    onClick={() => setCurrentStep(2)}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {currentStep == 2 && (
              <>
                <div className={"btn-group"}>
                  <Button onClick={() => setCurrentStep(1)} leftArrow>
                    Back
                  </Button>
                </div>
                <div className={"btn-group"}>
                  <Button tertiary onClick={onClose} disabled={isSaving}>
                    Cancel
                  </Button>
                  <Button
                    arrow
                    disabled={isStepThreeDisabled()}
                    onClick={() => setCurrentStep(3)}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {currentStep == 3 && (
              <>
                <div className={"btn-group"}>
                  <Button onClick={() => setCurrentStep(2)} leftArrow>
                    Back
                  </Button>
                </div>
                <div className={"btn-group"}>
                  <Button tertiary onClick={onClose} disabled={isSaving}>
                    Cancel
                  </Button>
                  <Button
                    primary
                    onClick={save}
                    loading={isSaving}
                    disabled={isStepThreeDisabled()}
                  >
                    Save changes
                  </Button>
                </div>
              </>
            )}
          </>
        )
      }
    >
      <>
        <Steps
          steps={[
            {
              id: "1",
              text: "Select assets",
              onClick: () => setCurrentStep(1),
            },
            {
              id: "2",
              text: "Configure",
              disabled: isStepTwoDisabled(),
              onClick: () => setCurrentStep(2),
            },
            {
              id: "3",
              text: "Review",
              disabled: isStepThreeDisabled(),
              onClick: () => setCurrentStep(3),
            },
          ]}
          currentStep={currentStep}
        />

        {currentStep == 1 &&
          (!!risk ? (
            <>
              <div className="risk-header">
                {severityMap[cloudscanSeverityNumberToText(risk.severity)].icon}
                <div className={"risk-text-container"}>
                  <div className={"risk-text"}>{risk.title}</div>
                  <div className={"risk-cat-title"}>{risk.categoryTitle}</div>
                </div>
                <div className="num-assets">
                  {getRiskAssetText(risk, true, assetSelection, waivedAssets)}
                </div>
              </div>
              <h4>Overview</h4>
              <div>{risk.description}</div>
              <br />
              <RiskAssetSelectionForWaiver
                isCustomer
                risk={risk}
                selected={true}
                selection={assetSelection}
                onSelectionChange={setAssetSelection}
                editableDomainPortfolioIds={[]}
                orgSupportsDomainPortfolios={true}
                skipWaiverId={waiver?.id}
              />
            </>
          ) : (
            <>
              <div className="empty-risk-card">
                <h4>Inactive Risk</h4>
                <div>
                  Unable to edit risk waiver. This risk is no longer active for
                  any current assets.
                </div>
              </div>
            </>
          ))}
        {currentStep == 2 && (
          <BreachSightWaiverDetailsStep
            requireApprovalsRiskWaivers={
              BreachSightRequireApprovalsRiskWaivers ?? false
            }
            hideApproval={
              waiver?.status !==
                OrganisationAcceptedRiskStatus.AwaitingApproval ||
              BreachSightRequireApprovalsRiskWaivers
            }
            approverType={
              approverRequired ? ApproverType.Other : ApproverType.Self
            }
            approverEmail={approverEmail}
            onSetApprover={(approverType, approverEmail) => {
              setApproverEmail(approverEmail);
              setApproverRequired(approverType === ApproverType.Other);
            }}
            canUsePublicWaivers={canUsePublicWaivers}
            isPublic={isPublic}
            disablePublicText={disablePublicText}
            onSetPublic={setIsPublic}
            justification={justification}
            onSetJustification={setJustification}
            expireType={expires ? ExpireType.Other : ExpireType.Never}
            expireDate={expiryDate}
            onSetExpire={(eType, eDate) => {
              setExpires(eType === ExpireType.Other);
              setExpiryDate(eDate);
            }}
          />
        )}
        {currentStep == 3 && (
          <BreachSightWaiverConfirmStep
            selectedRisk={risk}
            assetSelection={assetSelection}
            approverType={
              approverRequired ? ApproverType.Other : ApproverType.Self
            }
            approverEmail={approverEmail.value}
            justification={justification.value}
            canUsePublicWaivers={canUsePublicWaivers}
            isPublic={isPublic}
            expireType={expires ? ExpireType.Other : ExpireType.Never}
            expireDate={expiryDate ?? ""}
          />
        )}
      </>
    </ModalV2>
  );
};

export default EditRiskWaiverModal;
