import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import Button from "../../../_common/components/core/Button";
import TextField, {
  MaxLengthType,
  useTextWithValid,
} from "../../../_common/components/TextField";
import SeveritySelector from "../SeveritySelector";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import {
  Severity,
  SeverityAsString,
  SeverityInt,
} from "../../../_common/types/severity";
import { useVendorWords } from "../../../_common/hooks";
import "../../style/components/GenericAddRiskModal.scss";
import ColorCheckbox from "../ColorCheckbox";
import SeverityIcon from "../../../_common/components/SeverityIcon";
import {
  RiskClassification,
  RiskClassificationString,
} from "../../../_common/types/risk/classification";
import ClassificationSelector from "../../../_common/components/ClassificationSelector";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export interface GenericCustomRisk {
  id: number;
  name: string;
  impact: string;
  category: string;
  classification: RiskClassification;
  severity: SeverityInt;
}

export interface GenericRiskSubmitParams {
  existingRisk?: number;
  newFindingName?: string;
  newFindingImpact?: string;
  newFindingSeverity?: string;
  newFindingClassification?: RiskClassification;
}

export interface GenericRiskEditParams {
  existingRisk: number;
  newFindingName: string;
  newFindingImpact: string;
  newFindingSeverity: string;
  newFindingClassification?: RiskClassification;
}

interface GenericAddRiskModalProps extends BaseModalProps {
  headerNode?: ReactNode;
  showManualRiskWarning?: boolean;
  availableRisks: GenericCustomRisk[];
  allExistingRiskNames: string[];
  editRiskId?: number; // if defined this puts the modal in edit mode
  onSubmit: (params: GenericRiskSubmitParams) => Promise<any>;
  onEdit?: (params: GenericRiskEditParams) => Promise<any>;
  showClassifications?: boolean;
}

const GenericAddRiskModal: FC<GenericAddRiskModalProps> = (props) => {
  const [createNewRisk, _setCreateNewRisk] = useState(false);
  const [newRiskCustomErrors, setNewRiskCustomErrors] = useState<string[]>([]);

  // new risk state
  const [newFindingName, newFindingNameValid, setNewFindingName] =
    useTextWithValid("");
  const [newFindingImpact, newFindingImpactValid, setNewFindingImpact] =
    useTextWithValid("");
  const [newFindingSeverity, setNewFindingSeverity] = useState<Severity>();
  const [classification, setClassification] = useState<RiskClassification>();

  useEffect(() => {
    if (props.editRiskId != undefined) {
      const risk = props.availableRisks.find((r) => r.id == props.editRiskId);
      if (risk) {
        _setCreateNewRisk(true);
        setNewFindingName(risk.name, true);
        setNewFindingSeverity(SeverityAsString(risk.severity));
        setNewFindingImpact(risk.impact, true);
        setClassification(risk.classification);
      }
    }
  }, [props.editRiskId, props.availableRisks]);

  const [loading, setLoading] = useState(false);

  const vendorWords = useVendorWords();

  const options: OptionType<string>[] = props.availableRisks.map((r) => ({
    value: r.name,
    label: r.name,
  }));

  const [selectedRisk, setSelectedRisk] = useState<GenericCustomRisk>();
  const selectValue = selectedRisk
    ? { value: selectedRisk.name, label: selectedRisk.name }
    : undefined;

  const setCreateNewRisk = (createNewRisk: boolean) => {
    _setCreateNewRisk(createNewRisk);
    if (!createNewRisk) {
      setNewFindingName("", false);
      setNewFindingImpact("", false);
      setNewFindingSeverity(undefined);
      setClassification(undefined);
    } else {
      setSelectedRisk(undefined);
    }
  };

  const onSubmit = useCallback(() => {
    setLoading(true);
    props
      .onSubmit({
        existingRisk: selectedRisk?.id,
        newFindingImpact,
        newFindingSeverity,
        newFindingName,
        newFindingClassification: classification,
      })
      .then(() => {
        props.onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    props.onSubmit,
    selectedRisk,
    newFindingName,
    newFindingImpact,
    newFindingSeverity,
    classification,
  ]);

  const onEdit = useCallback(() => {
    if (
      !props.editRiskId ||
      !props.onEdit ||
      !newFindingSeverity ||
      (props.showClassifications && !classification)
    ) {
      return;
    }
    setLoading(true);
    props
      .onEdit({
        existingRisk: props.editRiskId,
        newFindingImpact,
        newFindingSeverity,
        newFindingName,
        newFindingClassification: classification,
      })
      .then(props.onClose)
      .finally(() => setLoading(false));
  }, [
    props.editRiskId,
    props.onEdit,
    newFindingImpact,
    newFindingSeverity,
    newFindingName,
    classification,
  ]);

  const canSubmit =
    !!selectedRisk || (newFindingNameValid && newFindingImpactValid);

  const isRiskNameUnique = (newRiskName: string) => {
    const alreadyExist = props.allExistingRiskNames.some(
      (existingName) => existingName === newRiskName
    );
    const customErrors = alreadyExist
      ? ["A risk with that name already exists"]
      : [];
    setNewRiskCustomErrors(customErrors);
    return !alreadyExist;
  };

  return (
    <ModalV2
      active={props.active}
      scroll={"content"}
      className={"generic-add-risk-modal"}
      onClose={props.onClose}
      headerContent={
        <h2>{props.editRiskId ? "Edit manual risk" : "Manually add a risk"}</h2>
      }
      footerContent={
        <>
          <Button className={"cancel-button"} tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className={"submit-button"}
            primary
            onClick={props.editRiskId ? onEdit : onSubmit}
            loading={loading}
            disabled={!canSubmit}
          >
            {props.editRiskId ? "Edit risk" : "Add risk"}
          </Button>
        </>
      }
    >
      <div className={"manual-risk-modal"}>
        {props.headerNode && <div className={"header"}>{props.headerNode}</div>}
        {!props.editRiskId && (
          <div className={"option"}>
            <div className={"option-heading"}>Risk</div>
            <ColorCheckbox
              radio
              label={"Select an existing risk"}
              checked={!createNewRisk}
              onClick={() => setCreateNewRisk(false)}
            />
            <ColorCheckbox
              radio
              label={"Create a new manual risk"}
              checked={createNewRisk}
              onClick={() => setCreateNewRisk(true)}
            />
          </div>
        )}
        <div className={"option"}>
          <div className={"option-heading"}>Finding</div>
          {createNewRisk ? (
            <TextField
              className={"risk-name-text-field"}
              type={"text"}
              name={"finding_name"}
              value={newFindingName}
              placeholder={"e.g. “Audit report does not cover relevant scope”"}
              maxLength={MaxLengthType.reasonableLength}
              minLength={1}
              onChanged={(val) => setNewFindingName(val, isRiskNameUnique(val))}
              errorTexts={newRiskCustomErrors}
              required
            />
          ) : (
            <SelectV2<false, OptionType<string>>
              placeholder={"Select an existing manual risk"}
              onChange={(value) =>
                setSelectedRisk(
                  props.availableRisks.find((r) => r.name == value?.value)
                )
              }
              options={options}
              value={selectValue}
              menuPosition="fixed"
            />
          )}
        </div>
        {(createNewRisk || !!selectedRisk) && (
          <div className={"option"}>
            <div className={"option-heading"}>Severity</div>
            {createNewRisk ? (
              <SeveritySelector
                isDisabled={!createNewRisk}
                onChange={setNewFindingSeverity}
                severity={newFindingSeverity}
                placeholder={"Select a severity for this risk"}
                usedFixedPosition
              />
            ) : (
              <SeverityIcon
                severity={SeverityAsString(
                  selectedRisk?.severity ?? SeverityInt.PassSeverity
                )}
                label
              />
            )}
          </div>
        )}
        {(createNewRisk || !!selectedRisk) && (
          <div className={"option"}>
            <div className={"option-heading"}>
              Impact{" "}
              <SidePopupV2
                text={
                  'Provide detail about the impact this risk carries, or what consequences could arise if not addressed. E.g. "Susceptible to man-in-the-middle attacks".'
                }
                position={"right"}
              >
                <i className={"cr-icon-info"} />{" "}
              </SidePopupV2>
            </div>
            <TextField
              type={"text"}
              name={"impact"}
              placeholder={"Outline the impact or consequences of this risk"}
              multiLine
              value={
                createNewRisk ? newFindingImpact : selectedRisk?.impact ?? ""
              }
              maxLength={MaxLengthType.message}
              onChanged={setNewFindingImpact}
              readonly={!createNewRisk}
              required
            />
          </div>
        )}
        {props.showClassifications && (createNewRisk || !!selectedRisk) && (
          <div className={"option"}>
            <div className={"option-heading"}>Category</div>
            {createNewRisk || props.editRiskId ? (
              <ClassificationSelector
                onSelectClassification={setClassification}
                selectedClassification={classification}
              />
            ) : (
              RiskClassificationString(
                selectedRisk?.classification,
                "Questionnaire risk",
                true
              )
            )}
          </div>
        )}
        {props.showManualRiskWarning && (
          <div className={"option"}>
            <div className={"option-heading"}>Manual risk visibility</div>
            <div className={"visibility"}>
              <i className={"cr-icon-noeye"} /> {vendorWords.pluralTitleCase}{" "}
              are not able to see any manual risks added, unless remediation is
              requested by your organization.
            </div>
          </div>
        )}
      </div>
    </ModalV2>
  );
};

export default GenericAddRiskModal;
