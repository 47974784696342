import { FC, useState } from "react";

import Button from "../../_common/components/core/Button";

import "./EditKeywordModal.scss";
import TextField, { MaxLengthType } from "../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import {
  MinKeywordLength,
  ThreatMonitoringCriticality,
  ThreatMonitoringKeyword,
  ThreatMonitoringKeywordType,
} from "../api/types";
import { SelectV2 } from "../../_common/components/SelectV2";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import ThreatMonitoringAPI from "../api/threatmonitoring.api";
import {
  criticalityColor,
  criticalityStr,
  keywordTypeStr,
} from "./KeywordsTable";
import {
  isIPAddress,
  validateDomainName,
  validateEmail,
  validatePhone,
} from "../../_common/helpers";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { ResponseError } from "../../_common/api";

export type newKeyword = {
  emailAddress: string;
  notes?: string;
  phoneMobile?: string;
  name: string;
  title?: string;
};

interface EditKeywordModalProps extends BaseModalProps {
  keyword?: ThreatMonitoringKeyword;
}

const EditKeywordModal: FC<EditKeywordModalProps> = (props) => {
  const [keyword, setKeyword] = useState(props.keyword?.keyword);
  const [keywordType, setKeywordType] = useState(props.keyword?.keywordType);
  const [criticality, setCriticality] = useState(props.keyword?.criticality);
  const [validationError, setValidationError] = useState("");
  const [keywordTypeError, setKeywordTypeError] = useState(false);
  const dispatch = useAppDispatch();

  const [addKeyword, addStatus] =
    ThreatMonitoringAPI.useCreateKeywordV1Mutation();
  const [editKeyword, editStatus] =
    ThreatMonitoringAPI.useEditKeywordV1Mutation();

  const submitStatus = props.keyword ? editStatus : addStatus;

  const submit = () => {
    setValidationError("");
    setKeywordTypeError(false);
    if (!keywordType) {
      setKeywordTypeError(true);
      // we'll check the keyword length validation at the same time, so don't return yet
    }
    const k = keyword?.trim();
    if (!k || k.length < MinKeywordLength) {
      setValidationError(
        `Keyword must contain at least ${MinKeywordLength} characters`
      );
      return;
    }
    if (!keywordType) {
      // Can return now after checking keyword length as well
      return;
    }
    if (!validateKeyword()) {
      setValidationError(
        `Invalid value for type "${keywordTypeStr(keywordType)}"`
      );
      return;
    }
    props.keyword
      ? editKeyword({
          uuid: props.keyword.uuid,
          criticality: criticality ?? "",
          active: props.keyword.active, // new keywords are active by default
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Keyword updated"));
            props.onClose();
          })
          .catch((e) => {
            console.error(e);
            dispatch(addDefaultUnknownErrorAlert("Failed to update keyword"));
          })
      : addKeyword({
          keyword: keyword ? keyword.trim() : "",
          keywordType: keywordType ?? "",
          criticality: criticality,
          active: true,
        })
          .unwrap()
          .then(() => {
            dispatch(addDefaultSuccessAlert("Keyword created"));
            props.onClose();
          })
          .catch((e: ResponseError) => {
            if (e.message === "KEYWORD_EXISTS") {
              dispatch(
                addSimpleErrorAlert("Failed to create keyword", [
                  "This keyword already exists.",
                ])
              );
            } else {
              dispatch(addDefaultUnknownErrorAlert("Failed to create keyword"));
            }
          });
  };

  const validateKeyword = () => {
    switch (keywordType) {
      case ThreatMonitoringKeywordType.EmailAddress:
        return validateEmail(keyword);
      case ThreatMonitoringKeywordType.PhoneNumber:
        return validatePhone(keyword);
      case ThreatMonitoringKeywordType.Domain:
        return validateDomainName(keyword ?? "");
      case ThreatMonitoringKeywordType.IPAddress:
        return isIPAddress(keyword);
      case ThreatMonitoringKeywordType.FreeText:
        return true;
      /*
      BIN/CC keyword types will only be enabled when we have a safe solution
      case ThreatMonitoringKeywordType.CreditCardNumber:
        return isNumber(keyword);
      case ThreatMonitoringKeywordType.BankIdentificationNumber:
        return isNumber(keyword);
        */
      default:
        return false;
    }
  };

  const criticalityOptions = () => {
    const opts = Object.values(ThreatMonitoringCriticality).map((val) => ({
      value: val,
      label: criticalityStr(val),
    }));

    opts.push({
      value: "" as ThreatMonitoringCriticality,
      label: "Not set",
    });

    return opts;
  };

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      disallowClose={true}
      headerContent={props.keyword ? "Edit keyword" : "Add keyword"}
      headerClassName={"edit-keyword-modal-header"}
      className={"edit-keyword-modal"}
      footerContent={
        <>
          <Button
            tertiary
            onClick={props.onClose}
            disabled={submitStatus.isLoading}
          >
            Cancel
          </Button>
          <Button
            primary
            loading={submitStatus.isLoading}
            onClick={submit}
            disabled={
              (!props.keyword &&
                (!keyword || !keywordType || criticality === undefined)) ||
              (props.keyword && criticality === props.keyword?.criticality)
            }
          >
            {props.keyword ? "Save changes" : "Add keyword"}
          </Button>
        </>
      }
    >
      <div className={"keyword-details"}>
        <div className={"label"}>Type</div>
        <div className={"input-field-wrapper"}>
          <div className={props.keyword && "input-disabled"}>
            <SelectV2
              className={classNames("input-field", {
                "input-disabled": props.keyword,
              })}
              placeholder={"Select a type"}
              isDisabled={!!props.keyword}
              onChange={(val) => setKeywordType(val?.value)}
              options={Object.values(ThreatMonitoringKeywordType)
                .map((t) => ({
                  value: t,
                  label: keywordTypeStr(t),
                }))
                .sort((a, b) => {
                  if (a.label < b.label) return -1;
                  if (a.label > b.label) return 1;
                  return 0;
                })}
              value={
                keywordType
                  ? {
                      value: keywordType,
                      label: keywordTypeStr(keywordType),
                    }
                  : undefined
              }
            />
          </div>
          {keywordTypeError && (
            <CSSTransition timeout={250} classNames="fade-transition">
              <div className={"error-text"}>
                <i className={"icon-info"} />
                Keyword type is required
              </div>
            </CSSTransition>
          )}
        </div>
        <div className={"label"}>Keyword</div>
        <div className={"input-field-wrapper"}>
          <TextField
            value={keyword || ""}
            errorTexts={validationError ? [validationError] : []}
            onChanged={setKeyword}
            required
            maxLength={MaxLengthType.label}
            placeholder={`Enter your keyword (e.g. "globalbank.ca")`}
            className={"input-field"}
            disabled={!!props.keyword}
          />
        </div>
        <div className={"label"}>Service criticality</div>
        <div className={"caption"}>
          This will affect the severity of detected threats.
        </div>
        <SelectV2
          onChange={(val) => {
            setCriticality(val?.value);
          }}
          placeholder={"Select a service criticality"}
          options={criticalityOptions()}
          formatOptionLabel={(opt) => (
            <div
              className={classNames(
                "criticality-selection",
                criticalityColor(opt.value)
              )}
            >
              {opt.label}
            </div>
          )}
          value={
            criticality
              ? {
                  value: criticality,
                  label: criticalityStr(criticality),
                }
              : undefined
          }
        />
      </div>
    </ModalV2>
  );
};

export default EditKeywordModal;
