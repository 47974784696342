import { FC, ReactNode, useState } from "react";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import {
  IAnswerValue,
  IAnswerWeights,
  IAutomationQuestion,
  IAutomationWeight,
  IAutomationWeights,
} from "../../types/automation";
import ReportCard from "../../../_common/components/ReportCard";
import "../../style/components/automation/DefineAdvancedWeightsStep.scss";
import Button from "../../../_common/components/core/Button";
import XTable, {
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import Icon from "../../../_common/components/core/Icon";
import DropdownV2, {
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import classnames from "classnames";
import {
  DataEntryErrorAttribute,
  EditQuestionnairePages,
  QuestionTypes,
} from "../../views/EditQuestionnaireAutomation";
import WeightsUploadModal from "./modals/WeightsUploadModal";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
interface IDefineAdvancedWeightsStepProps {
  loading: boolean;
  isEditing: boolean;
  surveyTypeId: number;
  recipeUUID: string;
  questions?: IAutomationQuestion[];
  weights?: IAutomationWeights;
  setWeightsFunc: (weights: IAutomationWeights, clearInputs: boolean) => void;
  setDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute | string,
    msg: string
  ) => void;
  deleteDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute | string
  ) => void;
  retrieveDataEntryErrorFunc: (
    page: number,
    id: string,
    attribute: DataEntryErrorAttribute | string
  ) => string | undefined;
  userHasWriteAutomationEnabled: boolean;
  inputText: {
    [key: string]: string;
  };
  setInputTextFunc: (page: number, id: string, value: string) => void;
  saveAutomationFunc: () => void;
}

const DefineAdvancedWeightsStep: FC<IDefineAdvancedWeightsStepProps> = ({
  loading,
  isEditing,
  recipeUUID,
  questions,
  weights,
  setWeightsFunc,
  setDataEntryErrorFunc,
  deleteDataEntryErrorFunc,
  retrieveDataEntryErrorFunc,
  userHasWriteAutomationEnabled,
  inputText,
  setInputTextFunc,
  saveAutomationFunc,
}) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadSaving, setUploadSaving] = useState(false);

  // defaultWeightConfig
  // for new questions, create a new starter weight definition record
  const defaultWeightConfig = (q: IAutomationQuestion): IAutomationWeight => {
    const weight: IAutomationWeight = {
      questionText: q.questionText,
      answerWeights: {} as IAnswerWeights,
      function: "sum",
      questionType: q.questionType,
      questionIDAlias: q.id,
      weightNone: 0,
    };
    q.answers?.forEach((a) => {
      weight.answerWeights[a.answerId] = 0;
    });
    return weight;
  };

  // setQuestionCustomId
  // given a specific question, set the customId (alias) for it in the weight definitions
  const setQuestionCustomId = (q: IAutomationQuestion, customQnId: string) => {
    customQnId = customQnId.trim();
    let duplicateAliasError = false;
    if (weights) {
      let qnWeight = weights[q.id];
      if (!qnWeight) {
        qnWeight = defaultWeightConfig(q);
      }
      qnWeight.questionIDAlias = customQnId;
      const newWeights = {
        ...weights,
        [q.id]: qnWeight,
      };
      setWeightsFunc(newWeights, false);
      Object.keys(weights).forEach((id) => {
        if (id != q.id && weights[id].questionIDAlias == customQnId) {
          duplicateAliasError = true;
        }
      });
    }
    if (customQnId == "") {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.CustomAttribute,
        "Cannot be empty"
      );
    } else if (duplicateAliasError) {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.CustomAttribute,
        "Alias must be unique"
      );
    } else {
      deleteDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.CustomAttribute
      );
    }
  };

  // setQuestionAnswerWeight
  // given a specific question, set the specific weight value for a specific answerId
  const setQuestionAnswerWeight = (
    q: IAutomationQuestion,
    ansId: string,
    weight: number
  ) => {
    const w = isNaN(weight) ? 0 : weight;
    if (weights) {
      const newWeights = {
        ...weights,
      };
      let qnWeight = weights[q.id];
      if (!qnWeight) {
        qnWeight = defaultWeightConfig(q);
      }
      qnWeight.answerWeights[ansId] = w;
      newWeights[q.id] = qnWeight;
      setWeightsFunc(newWeights, false);
    }
    if (isNaN(weight)) {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        ansId,
        "Weight must be entered, and must be a number"
      );
    } else {
      deleteDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        ansId
      );
    }
  };

  // setQuestionAnswerWeight
  // given a specific question, set the specific weight value for the 'not-answered' case
  const setQuestionNoneWeight = (q: IAutomationQuestion, weight: number) => {
    const w = isNaN(weight) ? 0 : weight;
    if (weights) {
      let qnWeight = weights[q.id];
      if (!qnWeight) {
        qnWeight = defaultWeightConfig(q);
      }
      qnWeight.weightNone = w;
      const newWeights = {
        ...weights,
        [q.id]: qnWeight,
      };
      setWeightsFunc(newWeights, false);
    }
    if (isNaN(weight)) {
      setDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.NoneWeight,
        "Weight must be entered, and must be a number"
      );
    } else {
      deleteDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.NoneWeight
      );
    }
  };

  // setQuestionAnswerWeight
  // given a specific (multi-select) question, set the function to be used to combined answer weights into a final weight for the question
  const setQuestionFunction = (q: IAutomationQuestion, fn: string) => {
    if (weights) {
      let qnWeight = weights[q.id];
      if (!qnWeight) {
        qnWeight = defaultWeightConfig(q);
      }
      qnWeight.function = fn;
      const newWeights = {
        ...weights,
        [q.id]: qnWeight,
      };
      setWeightsFunc(newWeights, false);
    }
  };

  // getAnswerText
  // Builds the text that sits to the RHS of the weight input control to indicate the answer to which the weight corresponds
  const getAnswerText = (a: IAnswerValue): ReactNode => {
    const val =
      a.value == "(FREETEXT)"
        ? "<i>Contains answer</i>"
        : a.value == "(UPLOAD)"
          ? "<i>Contains upload</i>"
          : a.value;
    return (
      <div
        key={a.answerId}
        className={"value-cell with-top-gap"}
        dangerouslySetInnerHTML={{ __html: val }}
      />
    );
  };

  // getNoAnswerText
  // Builds the text that sits to the RHS of the 'no anwer' weight input control to indicate the weight for the 'not answered' condition
  // @see getAnswerText
  const getNoAnswerText = (q: IAutomationQuestion): ReactNode => {
    const noAnswerText =
      q.questionType == QuestionTypes.Upload ? "No upload" : "No answer";
    return (
      <div key={"noanswer"} className={"value-cell with-top-gap"}>
        <i>{noAnswerText}</i>
      </div>
    );
  };

  // getMultiSelectCombineFunctionLabel
  // at the bottom of the list if weights/answers for a multi-select question, this label sits to the RHS of a selector that
  // allows thew user to choose the mechanism to combine answer values
  const getMultiSelectCombineFunctionLabel = (): ReactNode => {
    return (
      <div key={"func"} className={"value-cell with-fn-top-gap"}>
        <span className={"formula-text"}>{"Combine weights using"}</span>
      </div>
    );
  };

  // getMultiSelectCombineFunctionSelect
  // for a multi-select question, provides an input control through which to choose thw method with which answer weights should be commbined
  // @see getMultiSelectCombineFunctionLabel
  const getMultiSelectCombineFunctionSelect = (
    weight: IAutomationWeight,
    q: IAutomationQuestion
  ) => {
    return (
      <div key={"func"} className={"value-cell func-select"}>
        <DropdownV2
          disabled={!isEditing || !userHasWriteAutomationEnabled}
          popupItem={
            <PillLabel
              color={LabelColor.Blue}
              className={isEditing ? "clickable" : undefined}
            >
              {`${weight.function.toUpperCase()}`}{" "}
              <Icon name="chevron" direction={180} />
            </PillLabel>
          }
        >
          <DropdownItem
            key={"sum"}
            onClick={() => {
              setQuestionFunction(q, "sum");
            }}
          >
            {"SUM"}
          </DropdownItem>
          <DropdownItem
            key={"avg"}
            onClick={() => {
              setQuestionFunction(q, "avg");
            }}
          >
            {"AVG"}
          </DropdownItem>
          <DropdownItem
            key={"max"}
            onClick={() => {
              setQuestionFunction(q, "max");
            }}
          >
            {"MAX"}
          </DropdownItem>
          <DropdownItem
            key={"min"}
            onClick={() => {
              setQuestionFunction(q, "min");
            }}
          >
            {"MIN"}
          </DropdownItem>
        </DropdownV2>
      </div>
    );
  };

  // getAnswerWeightInput
  // Provides the input control to enter a the weight for a specific answer of a specific question
  // @see getAnswerText
  const getAnswerWeightInput = (
    weight: IAutomationWeight,
    q: IAutomationQuestion,
    a: IAnswerValue
  ): ReactNode => {
    const answerWeightError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        a.answerId
      ) ?? "";

    const txtKey = q.id + "__" + a.answerId;
    return (
      <div key={txtKey} className={"value-cell"}>
        <SidePopupV2 text={answerWeightError}>
          <input
            key={txtKey}
            className={classnames("answer-weight", {
              error: answerWeightError,
            })}
            type="text"
            value={
              inputText[txtKey] != undefined
                ? inputText[txtKey]
                : weight.answerWeights[a.answerId]
                  ? weight.answerWeights[a.answerId].toString(10)
                  : "0"
            }
            disabled={!isEditing || !userHasWriteAutomationEnabled}
            onChange={(e) => {
              setInputTextFunc(
                EditQuestionnairePages.EditAutomationAdvancedWeights,
                txtKey,
                e.target.value
              );
              const regexp = /^-?\d+(\.\d{1,2})?$/;
              const w = regexp.test(e.target.value)
                ? parseFloat(e.target.value)
                : NaN;
              setQuestionAnswerWeight(q, a.answerId, w);
            }}
          />
        </SidePopupV2>
      </div>
    );
  };

  // getAnswerWeightInput
  // Provides the input control to enter the weight for the 'no answer' case of a specific question
  // @see getNoAnswerText
  const getNoAnswerInput = (
    weight: IAutomationWeight,
    q: IAutomationQuestion
  ): ReactNode => {
    const noAnswerError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.NoneWeight
      ) ?? "";

    return (
      <div key={q.id + "_noans"} className={"value-cell"}>
        <SidePopupV2 text={noAnswerError}>
          <input
            key={q.id + "_noans"}
            className={classnames("answer-weight", {
              error: noAnswerError,
            })}
            type="text"
            value={
              inputText[q.id + "__noans"] != undefined
                ? inputText[q.id + "__noans"]
                : weight.weightNone.toString(10)
            }
            disabled={!isEditing || !userHasWriteAutomationEnabled}
            onChange={(e) => {
              setInputTextFunc(
                EditQuestionnairePages.EditAutomationAdvancedWeights,
                q.id + "__noans",
                e.target.value
              );
              const regexp = /^-?\d+(\.\d{1,2})?$/;
              const w = regexp.test(e.target.value)
                ? parseFloat(e.target.value)
                : NaN;
              setQuestionNoneWeight(q, w);
            }}
          />
        </SidePopupV2>
      </div>
    );
  };

  // getQuestionNameText
  // Renders the name of a question along with icon and numbering.
  const getQuestionNameText = (q: IAutomationQuestion): ReactNode => {
    const questionSplit = q.questionText.split(" ");
    let qnNum = questionSplit[0];
    let qnTxt = questionSplit.slice(1).join(" ");
    if (qnNum[0] < "0" || qnNum[0] > "9") {
      qnNum = "";
      qnTxt = q.questionText;
    }
    return (
      <div className={"text"}>
        <div className={classnames("qn-num", { empty: qnNum.length == 0 })}>
          {q.questionType == QuestionTypes.Section && (
            <i className={"cr-icon-q-builder-flag"} />
          )}
          {q.questionType == QuestionTypes.SingleSelect && (
            <i className={"cr-icon-q-builder-radio"} />
          )}
          {q.questionType == QuestionTypes.MultiSelect && (
            <i className={"cr-icon-q-builder-checkbox"} />
          )}
          {q.questionType == QuestionTypes.FreeText && (
            <i className={"cr-icon-q-builder-document"} />
          )}
          {q.questionType == QuestionTypes.Upload && (
            <i className={"cr-icon-q-builder-attachment"} />
          )}
          {qnNum}
        </div>
        <div className={"label"} dangerouslySetInnerHTML={{ __html: qnTxt }} />
      </div>
    );
  };

  // getAnswerWeightInput
  // Provides the input control to enter the alias string for a specific question
  // @see getNoAnswerText
  const getQuestionAliasEdit = (
    weight: IAutomationWeight,
    q: IAutomationQuestion
  ) => {
    const custIDError =
      retrieveDataEntryErrorFunc(
        EditQuestionnairePages.EditAutomationAdvancedWeights,
        q.id,
        DataEntryErrorAttribute.CustomAttribute
      ) ?? "";
    return (
      <div className={"id"}>
        <SidePopupV2 text={custIDError ? custIDError : weight.questionIDAlias}>
          <input
            className={custIDError ? "error" : undefined}
            width={"100%"}
            name={q.id}
            value={weight.questionIDAlias}
            disabled={!isEditing || !userHasWriteAutomationEnabled}
            onChange={(e) => {
              setQuestionCustomId(q, e.target.value);
            }}
          />
        </SidePopupV2>
        {isEditing && weight.questionIDAlias !== q.id && (
          <i
            className="cr-icon-undo"
            onClick={() => {
              setQuestionCustomId(q, q.id);
            }}
          />
        )}
      </div>
    );
  };

  const getWeightRow = (
    firstRow: boolean,
    lastRow: boolean,
    weight: IAutomationWeight,
    q: IAutomationQuestion,
    a: IAnswerValue | undefined,
    suffix?: string
  ): IXTableRow => {
    const cells: React.ReactNode[] = [
      <XTableCell
        key={"id"}
        className={classnames("id-cell", {
          "section-cell": q.questionType == QuestionTypes.Section,
          "data-cell": q.questionType !== QuestionTypes.Section,
        })}
      >
        {firstRow && getQuestionAliasEdit(weight, q)}
      </XTableCell>,
      <XTableCell
        key={"text"}
        className={classnames("name-cell", {
          "section-cell": q.questionType == QuestionTypes.Section,
          "data-cell": q.questionType !== QuestionTypes.Section,
        })}
      >
        {firstRow && getQuestionNameText(q)}
      </XTableCell>,
      <XTableCell
        key={"type"}
        className={classnames("type-cell", {
          "section-cell": q.questionType == QuestionTypes.Section,
          "data-cell": q.questionType !== QuestionTypes.Section,
        })}
      >
        {firstRow && weight.questionType != QuestionTypes.Section && (
          <div className={"label"}>{q.questionType}</div>
        )}
      </XTableCell>,
      <XTableCell
        key={"weights"}
        className={classnames("name-cell", {
          "section-cell": q.questionType == QuestionTypes.Section,
          "data-cell": q.questionType !== QuestionTypes.Section,
        })}
      >
        {q.questionType != QuestionTypes.Section && (
          <>
            {!lastRow && !!a && getAnswerWeightInput(weight, q, a)}
            {lastRow && getNoAnswerInput(weight, q)}
            {lastRow &&
              q.multiValue &&
              getMultiSelectCombineFunctionSelect(weight, q)}
          </>
        )}
      </XTableCell>,
      <XTableCell
        key={"answers"}
        className={classnames("answers-cell", {
          "section-cell": q.questionType == QuestionTypes.Section,
          "data-cell": q.questionType !== QuestionTypes.Section,
        })}
      >
        {q.questionType != QuestionTypes.Section && (
          <>
            {!lastRow && !!a && getAnswerText(a)}
            {lastRow && getNoAnswerText(q)}
            {lastRow && q.multiValue && getMultiSelectCombineFunctionLabel()}
          </>
        )}
      </XTableCell>,
    ];
    return {
      className: classnames("row", {
        "section-row": q.questionType == QuestionTypes.Section,
        "last-row": lastRow && q.questionType !== QuestionTypes.Section,
        "weight-row": !lastRow && q.questionType !== QuestionTypes.Section,
        "first-row": firstRow && q.questionType !== QuestionTypes.Section,
      }),
      id: q.id + (suffix ? suffix : ""),
      cells: cells,
    };
  };

  // getWeightRows
  // render each of the XTableRows for our weight edit view. Typically each row is either
  // a) a section header line
  // b) an answer weight for the question (the first answer row has the question's other attributes)
  // c) a 'not-answered' weight
  const getWeightRows = () => {
    const rows = [] as IXTableRow[];
    if (!questions || !weights) {
      return rows;
    }
    questions.map((q) => {
      let weight = weights[q.id];
      if (!weight) {
        weight = defaultWeightConfig(q);
      }
      if (!weight.answerWeights) {
        weight.answerWeights = {} as IAnswerWeights;
      }
      if (q.questionType == QuestionTypes.Section) {
        rows.push(getWeightRow(true, false, weight, q, undefined));
      } else {
        q.answers?.forEach((a, idx) => {
          rows.push(getWeightRow(idx == 0, false, weight, q, a, "_" + idx));
        });
        rows.push(getWeightRow(false, true, weight, q, undefined));
      }
    });
    return rows;
  };

  if (loading) {
    return <LoadingBanner />;
  }

  return (
    <div className="define-advanced-weights-step">
      <ReportCard newStyles className="report-card">
        <div className={"header"}>
          <div className="header-inner">
            <div className={"header-left"}>
              <div className={"header-title"}>Configure weights</div>
            </div>
            <div className={"header-right"}>
              {isEditing ? (
                <Button
                  className={"excel-button"}
                  loading={uploadSaving}
                  disabled={!isEditing || !userHasWriteAutomationEnabled}
                  onClick={async () => {
                    setUploadSaving(true);
                    try {
                      await saveAutomationFunc();
                    } catch (e) {
                      return;
                    }
                    setUploadModalOpen(true);
                    setUploadSaving(false);
                  }}
                >
                  Input via Excel
                </Button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={"info-section"}>
            The advanced method of tiering recipe works by assigning weights to
            each answer in a questionnaire. The weights of each answer are added
            together, and the final result will be assigned a property based on
            ranges defined by you. To learn more about configuring automations
            read our{" "}
            <a
              href={
                "https://help.upguard.com/en/articles/8073950-how-to-use-automation-to-apply-tiers-labels-portfolios-and-custom-attributes-to-your-vendors"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              support article
            </a>
            .
          </div>
        </div>
        <div className={"body"}>
          <XTable
            columnHeaders={[
              {
                id: "custom_id",
                text: "Alias",
                helpText:
                  "Provide a shortcut name for this section or question to use when defining your weighted calculation",
              },
              {
                id: "qn_text",
                text: "Question",
              },
              {
                id: "qn_type",
                text: "Type",
              },
              {
                id: "qn_weight",
                text: "Weight",
                helpText:
                  "The weight value to be used when this question is answered or specific answer is selected",
              },
              { id: "qn_ans", text: "Answers" },
            ]}
            rows={getWeightRows()}
          ></XTable>
        </div>
        <WeightsUploadModal
          recipeUUID={recipeUUID}
          existingWeights={weights}
          onSuccess={(weights: IAutomationWeights) => {
            setWeightsFunc(weights, true);
          }}
          active={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
          alreadyExported={false} // shrug
        />
      </ReportCard>
    </div>
  );
};

export default DefineAdvancedWeightsStep;
