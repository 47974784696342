import {
  ButtonWithDropdownV2,
  DropdownItem,
} from "../../../_common/components/core/DropdownV2";
import BetaLabel from "../../../_common/components/BetaLabel";
import { LabelColor } from "../../../_common/types/label";
import VendorAssessmentAPI, {
  VendorAssessmentStream,
} from "../../reducers/vendorAssessmentAPI";
import { useManagedOrgID, useVendorURLPrefix } from "../../../_common/hooks";
import { useDefaultHistory } from "../../../_common/types/router";
import { VendorAssessmentStatus } from "../../types/vendorAssessments";
import { useCallback } from "react";
import { useModalV2 } from "../../../_common/components/ModalV2";
import NewAssessmentStreamModal from "./NewAssessmentStreamModal";
import NewAssessmentConfirmModal from "./NewAssessmentConfirmModal";
import { skipToken } from "@reduxjs/toolkit/query";
import "../../style/components/vendor_assessment/NewAssessmentMenu.scss";

interface NewAssessmentMenuProps {
  vendorId: number;
  vendorName: string;
}

const NewAssessmentMenu = ({
  vendorId,
  vendorName,
}: NewAssessmentMenuProps) => {
  const { data: streamList } =
    VendorAssessmentAPI.useGetVendorAssessmentStreamsV1Query({
      vendorID: vendorId,
    });

  const hasSecurityProfileAssessment =
    streamList?.streams.some((s) => s.forSecurityProfile) ?? false;

  const urlPrefix = useVendorURLPrefix(vendorId);

  const history = useDefaultHistory<{ selectedVersion?: number }>();

  const [openNewAssessmentModal, newAssessmentModal] = useModalV2(
    NewAssessmentStreamModal
  );
  const [openConfirmNewAssessmentModal, confirmNewAssessmentModal] = useModalV2(
    NewAssessmentConfirmModal
  );

  const goToAssessment = useCallback(
    (streamId: number, versionId?: number) => {
      history.push(`${urlPrefix}/assessment/${streamId}`, {
        backContext: {
          goBack: true,
          backToText: "Back to risk assessments",
        },
        selectedVersion: versionId,
      });
    },
    [history, urlPrefix]
  );

  const managedOrgId = useManagedOrgID();

  const { data: unstartedManagedVendorRequestID } =
    VendorAssessmentAPI.useGetLatestUnstartedManagedVendorRequestForVendorQuery(
      managedOrgId ? { vendorId: vendorId } : skipToken
    );

  const onNewAssessment = useCallback(() => {
    const openNew = () =>
      openNewAssessmentModal({
        vendorId: vendorId,
        vendorName: vendorName,
        urlPrefix,
        goToNewAssessment: true,
        managedAssessmentId:
          managedOrgId && unstartedManagedVendorRequestID?.result
            ? unstartedManagedVendorRequestID.result
            : undefined,
      });

    const assessmentWasRaisedByCurrentUserType = (s: VendorAssessmentStream) =>
      !!managedOrgId === (s.managedAssessmentID != null);

    const draftAssessments =
      streamList?.streams.filter(
        (s) =>
          s.status == VendorAssessmentStatus.Draft &&
          !s.deleted &&
          assessmentWasRaisedByCurrentUserType(s)
      ) ?? [];
    if (draftAssessments.length > 0) {
      openConfirmNewAssessmentModal({
        draftAssessments: draftAssessments,
        onContinueAssessment: goToAssessment,
        onStartNewAssessment: openNew,
      });
    } else {
      openNew();
    }
  }, [
    vendorId,
    vendorName,
    urlPrefix,
    streamList?.streams,
    goToAssessment,
    managedOrgId,
    unstartedManagedVendorRequestID,
  ]);

  return (
    <div className={"new-assessment-menu"}>
      <ButtonWithDropdownV2
        primary
        tertiary={false}
        showChevron
        text={"Conduct assessment"}
      >
        {!hasSecurityProfileAssessment && (
          <DropdownItem
            onClick={() => {
              history.push(`${urlPrefix}/securityprofile/riskassessment`, {
                backContext: {
                  goBack: true,
                  backToText: "Back to risk assessments",
                },
              });
            }}
          >
            <div className="dropdown-with-text">
              <div className="dropdown-title">
                Security profile assessment{" "}
                <BetaLabel color={LabelColor.LightBlue} />
              </div>
              <div className="dropdown-text">
                Use the security profile to generate AI-powered commentary on
                risks and passed checks for each control group.
              </div>
            </div>
          </DropdownItem>
        )}
        <DropdownItem onClick={onNewAssessment}>
          <div className="dropdown-with-text">
            <div className="dropdown-title">Classic risk assessment</div>
            <div className="dropdown-text">
              Assessment based on selected evidence and risk profile.
            </div>
          </div>
        </DropdownItem>
      </ButtonWithDropdownV2>
      {newAssessmentModal}
      {confirmNewAssessmentModal}
    </div>
  );
};

export default NewAssessmentMenu;
