import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getVendorDataFromState,
  VendorOverlayInjectedProps,
  wrapInVendorOverlay,
} from "./VendorOverlayWrapper";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import { fetchOrganisationDefaultTexts } from "../reducers/orgDefaultTexts.actions";
import { IStep, Steps } from "../../_common/components/StepsWithSections";
import ActionBar from "../../_common/components/ActionBar";
import Button, { TooltipButton } from "../../_common/components/core/Button";
import PageHeader from "../../_common/components/PageHeader";
import { getVendorPageBreadcrumbs } from "../../_common/components/Breadcrumbs";
import "../style/views/VendorAssessmentV3.scss";
import {
  AssessmentDraftUpdate,
  updateAssessmentDraftData,
  UpdateCopyForVendorAssessmentV1,
  VendorAssessmentCopyUpdateType,
} from "../reducers/vendorAssessment.actions";
import {
  AutofillSummarySection,
  VendorAssessment,
  VendorAssessmentKeyRisk,
  VendorAssessmentNarrativeSection,
  VendorAssessmentStatus,
} from "../types/vendorAssessments";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import {
  analystAssignVendorAssessmentToManagedAssessment,
  analystGetSingleRequest,
  getLatestUnstartedRequestForVendor,
} from "../../analyst_portal/reducers/analystManagedVendors.actions";
import { fetchVendorSummaryAndCloudscans } from "../reducers/cyberRiskActions";
import VendorAssessmentV3SelectEvidenceStep from "../components/VendorAssessment/VendorAssessmentV3SelectEvidenceStep";
import VendorAssessmentV3RisksCard from "../components/VendorAssessment/VendorAssessmentV3RisksCard";
import VendorAssessmentV3SummaryBar from "../components/VendorAssessment/VendorAssessmentV3SummaryBar";
import { formatTimeAsLocal } from "../../_common/helpers";
import VendorAssessmentV3AssessmentCard from "../components/VendorAssessment/VendorAssessmentV3AssessmentCard";
import { fetchVendorRiskWaivers } from "../reducers/vendorRiskWaiver.actions";
import { useConfirmationModalV2 } from "../../_common/components/modals/ConfirmationModalV2";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { trackEvent } from "../../_common/tracking";
import {
  getUserPermissionsForVendorFromState,
  OrgAccessAdditionalEvidence,
  OrgAccessDefaultTexts,
  OrgAccessSurveys,
  OrgQuestionnaireScores,
  UserVendorRiskWrite,
  UserWriteOwnOrganisation,
  UserWriteVendorAssessments,
} from "../../_common/permissions";
import {
  IVerifiedVendorAccessItemNda,
  SharedAssessmentAccessResult,
} from "../types/sharedAssessment";
import VendorAssessmentV2SharedAssessmentSideBar from "../components/VendorAssessment/VendorAssessmentV2SharedAssessmentSideBar";
import { useModalV2 } from "../../_common/components/ModalV2";
import RequestAccessModal, {
  IRequestAccessModalProps,
} from "../components/modals/VendorSharedAssetsRequestModal";
import CompleteVendorAssessmentModal from "../components/modals/CompleteVendorAssessmentModal";
import { AssuranceType } from "../../_common/types/organisations";
import { SurveyStatus } from "../../_common/types/survey";
import moment from "moment";
import { IRisk } from "../components/RiskSummaryDetails";
import { getVendorWords, RiskProfileRiskTypes } from "../../_common/constants";
import CreateVendorRiskWaiverModal from "../components/modals/CreateVendorRiskWaiverModal";
import DomainsAndIPsPanelGroup, {
  GetVulnPanel,
  OpenSidePanel,
} from "../components/DomainsAndIPsPanelGroup";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";
import { useInterval, useLocationStateUpdater } from "../../_common/hooks";
import InfoBanner, { BannerType } from "../components/InfoBanner";
import {
  fetchRemediationRequestDetails,
  fetchRemediationRequestDetailsForVendorAssessment,
} from "../../_common/reducers/remediationRequest.actions";
import { RemediationRequest } from "../../_common/types/remediation";
import { WaiverType } from "../../_common/types/vendorRiskWaivers";
import RequestVerifiedVendorAccessModal from "../../_common/components/modals/RequestVerifiedVendorAccessModal";
import NdaSigningModal from "../components/shared_profile/NdaSigningModal";
import NdaSignedModal from "../components/shared_profile/NdaSignedModal";
import { throttle as _throttle } from "lodash";
import { InfoBar } from "../../_common/components/InfoBar";
import UnwatchedVendorCard from "../components/UnwatchedVendorCard";
import VendorAssessmentPickDomainsModal from "../components/VendorAssessment/VendorAssessmentPickDomainsModal";
import RequestRemediationModal from "../components/modals/RequestRemediationModal";
import {
  appConnect,
  useAppDispatch,
  useAppSelector,
} from "../../_common/types/reduxHooks";
import { ILabel } from "../../_common/types/label";
import { Portfolio } from "../reducers/portfolios.actions";
import { skipToken } from "@reduxjs/toolkit/query";
import VendorAssessmentAPI, {
  commentsUpdate,
  invalidateVendorAssessmentDraftsForVendor,
} from "../reducers/vendorAssessmentAPI";
import IconButton from "../../_common/components/IconButton";
import RenameAssessmentModal from "../components/VendorAssessment/RenameAssessmentModal";
import VendorAssessmentsV3VersionsSideBar from "../components/VendorAssessment/VendorAssessmentsV3VersionsSideBar";
import VendorAssessmentAutofillModal, {
  VendorAssessmentAutofillSections,
} from "../components/VendorAssessment/VendorAssessmentAutofillModal";
import ManagedVendorsAPI from "../reducers/managedVendorsAPI";
import VendorAssessmentAutofillAssessmentCard from "../components/VendorAssessment/VendorAssessmentAutofillAssessmentCard";
import { JobStatus } from "../types/jobs";
import ProgressBar from "../../_common/components/ProgressBar";
import classnames from "classnames";
import { ConfettiFullPage } from "../../_common/components/ConfettiFullPage";
import { setPLGTaskCompleteIfIncomplete } from "../reducers/plgOnboardingChecklistActions";
import { useOrgDefaultTexts } from "../reducers/orgDefaultTexts.hooks";
import { DefaultTextType } from "../../_common/types/orgDefaultTexts";
import GenerateDraftReportButton from "../components/reporting/GenerateDraftReportButton";
import TrustPageAPI from "../../verifiedvendors/api/trustpage.api";

export interface VendorAssessmentLocationState extends locationState {
  selectedVersion?: number; // open a specific version
  viewingSharedAssessmentForOrgId?: number;
  viewingSharedAssessmentOrgName?: string;
  vendorManagementRequestId?: number;
}

/* Additional rules for managed vendor assessments
* Customer can not start a new assessment, if this is an empty state message will state this
** Otherwise they can still view the published assessment
* If they try to view the managed assessment they will be told they need to wait till the analyst is done
** Once the assessment is with customer they can view, edit and publish the assessment
See https://whimsical.com/managed-vendor-assessment-workflow-G1Hnr3Y3GMvZaFiSkeLsC1 for full details
 */
export enum VendorAssessmentAnalystMode {
  None = "none", // This is not a managed assessment, or it has been published
  IsAnalyst = "isAnalyst", // This is the analyst performing a managed assessment
  Closed = "Closed", // Customers can not perform action on this assessment
  Open = "open", // The analyst has put it with the customer
}

export const EditTimeout = 5 * 60; // five minutes

export type VendorAssessmentUpdate =
  | { type: "toggle_survey"; id: number; shared: boolean }
  | { type: "toggle_evidence"; id: number; shared: boolean }
  | { type: "toggle_page"; category: string; url: string }
  | { type: "toggle_scanning" }
  | { type: "toggle_waivers" }
  | { type: "update_commentary"; comments: string; customerComments: string }
  | {
      type: "toggle_evidence_type";
      evidenceType: "questionnaires" | "additionalEvidence" | "evidencePages";
    };

export const removeByAssessmentPathSuffix = (path: string) =>
  path.replace(/\/byassessment\/\d+$/, "");

const useLocking = (
  lockTime: number,
  userEmail: string,
  version?: VendorAssessment
) => {
  const [isLocked, setIsLocked] = useState(
    !!version &&
      version.status == VendorAssessmentStatus.Draft &&
      moment(version.editInProgressAt).add(lockTime, "s").isAfter(moment())
  );
  const [hasLock, setHasLock] = useState(
    !!version && isLocked && userEmail == version.editingEmail
  );

  useEffect(() => {
    const locked =
      !!version &&
      version.status == VendorAssessmentStatus.Draft &&
      moment(version.editInProgressAt).add(lockTime, "s").isAfter(moment());
    setIsLocked(locked);
    setHasLock(locked && userEmail == version.editingEmail);
  }, [
    lockTime,
    userEmail,
    version?.editInProgressAt,
    version?.editingEmail,
    version?.status,
  ]);

  return [isLocked, hasLock] as const;
};

export type VendorAssessmentsLocationState = {
  tab?: string;
  selectedVersion?: number;
  viewingSharedOrgId?: number;
  currentStep?: number;
  startInitial?: boolean;
};

interface VendorAssessmentV3ConnectedProps {
  userHasWritePermission: boolean;
  userHasWriteAssessmentPermission: boolean;
  userIsAdmin: boolean;
  currentUserEmail: string;
  assuranceType: AssuranceType;
  currentOrgIsInAccountGroup?: boolean;
  orgHasAdditionalEvidenceAccess: boolean;
  orgHasSurveyAccess: boolean;
  orgHasCustomTemplatesAccess: boolean;
  orgHasSurveyScoresEnabled: boolean;
  remediationRequests?: { [key: number]: RemediationRequest };
  verifiedVendor?: boolean;
  labels?: ILabel[];
  tier?: number;
  portfolios?: Portfolio[];
}

type VendorAssessmentV3OwnProps = {
  vendorId: number;
} & VendorOverlayInjectedProps &
  DefaultRouteProps<
    { streamId: string; sharedOrgId: string },
    VendorAssessmentsLocationState
  >;

type VendorAssessmentV3Props = VendorAssessmentV3ConnectedProps &
  VendorAssessmentV3OwnProps;

export type VendorAssessmentCopyMap = {
  [key in VendorAssessmentCopyUpdateType]?: string;
};

// VendorAssessmentV3
// the long awaited third version of vendor assessments
const VendorAssessmentV3: FC<VendorAssessmentV3Props> = (props) => {
  // fetch our list of vendor assessments using the api
  const {
    data: assessmentListResp,
    isLoading: assessmentListLoading,
    isFetching: assessmentListFetching,
  } = VendorAssessmentAPI.useGetVendorAssessmentListQuery({
    vendorID: props.vendorId,
  });

  // if this org doesn't have the dev toggle then we need to select the only stream from the fetched data
  const [selectedStreamId, setSelectedStreamId] = useState(
    parseInt(props.match.params.streamId)
  );
  useEffect(() => {
    if (!props.match.params.streamId && assessmentListResp) {
      setSelectedStreamId(
        parseInt(Object.keys(assessmentListResp.assessments)[0])
      );
    }
  }, [props.match.params.streamId, assessmentListResp]);

  const { data: sharedAssessment } =
    TrustPageAPI.useGetVendorsSharedAssessmentV1Query(
      {
        vendorId: props.vendorId,
      },
      { skip: !props.vendorVerified }
    );

  const { data: streams } =
    VendorAssessmentAPI.useGetVendorAssessmentStreamsV1Query({
      vendorID: props.vendorId,
    });

  const selectedStream =
    streams?.streams.find((s) => s.id == selectedStreamId) ??
    streams?.archivedStreams.find((s) => s.id == selectedStreamId);

  const assessmentList =
    assessmentListResp?.assessments[selectedStreamId] ?? [];

  const [refreshEvidence] =
    VendorAssessmentAPI.useRefreshEvidenceForAssessmentMutation();

  // selectedVersionID can be used for both our own assessments and shared ones
  const [selectedVersionID, setSelectedVersionID] = useState<
    number | undefined
  >(props?.location?.state?.selectedVersion);
  // viewingSharedOrgId indicates we are looking at a published version by a shared org
  const [viewingSharedOrgId, setViewingSharedOrgId] = useState<
    number | undefined
  >(props.location?.state?.viewingSharedOrgId);

  const numVersions = assessmentList.length;

  // once we have our list automatically select the latest version
  useEffect(() => {
    if (
      !selectedVersionID &&
      !assessmentListFetching &&
      assessmentList &&
      assessmentList.length > 0 &&
      !props.match.params.sharedOrgId
    ) {
      setSelectedVersionID(assessmentList[0].id);
    }
    // if we are looking at a shared assessment load that instead
    if (!!assessmentListResp && props.match.params.sharedOrgId) {
      const orgId = parseInt(props.match.params.sharedOrgId);
      const relatedOrg = assessmentListResp.sharedAssessments.find(
        (sa) => sa.organisationID == orgId
      );
      if (relatedOrg) {
        setViewingSharedOrgId(orgId);
        setSelectedVersionID(relatedOrg.id);
      }
    }
  }, [
    assessmentListResp,
    assessmentList,
    selectedVersionID,
    assessmentListFetching,
    props.match.params.orgId,
  ]);

  // If we have a selectedVersion in the location.state, we should only use it on the initial page load when navigating back from another page
  const deletingVersion = useRef(false);

  const {
    data: assessmentData,
    isFetching: assessmentLoading,
    refetch: refetchVendorAssessmentData,
  } = VendorAssessmentAPI.useGetVendorAssessmentDataQuery(
    !selectedVersionID || deletingVersion.current
      ? skipToken
      : {
          vendorID: props.vendorId,
          versionID: selectedVersionID,
        }
  );

  const version = assessmentData?.assessment;
  const evidence = assessmentData?.evidence;
  const narrativeSections = assessmentData?.narrativeSections?.filter((s) => {
    if (assessmentData?.assessment.publishedAt) {
      return s.published;
    }

    return true;
  });

  const {
    data: scoringAndRiskCalcStatus,
    isFetching: scoreAndRiskCalcStatusLoading,
    refetch: refetchScoringAndRiskCalcStatus,
  } = VendorAssessmentAPI.useGetVendorAssessmentScoringAndRiskCalcStatusQuery(
    !selectedVersionID || !version || deletingVersion.current
      ? skipToken
      : { vendorID: props.vendorId, versionID: selectedVersionID }
  );

  const { data: vendorAssessmentRisks, refetch: refetchAssessmentRisks } =
    VendorAssessmentAPI.useGetRisksForAssessmentQuery(
      !selectedVersionID || !version || deletingVersion.current
        ? skipToken
        : { vendorId: props.vendorId, assessmentId: selectedVersionID }
    );

  const [startVendorAssessmentScoringJob, { isLoading: scoreJobLoading }] =
    VendorAssessmentAPI.useStartNewVendorAssessmentScoringJobMutation();

  const refreshScore = useCallback(() => {
    if (scoringAndRiskCalcStatus?.status == "Started" && !!selectedVersionID) {
      refetchScoringAndRiskCalcStatus()
        .unwrap()
        .then((status) => {
          if (
            status.status == "Complete" &&
            version &&
            !deletingVersion.current
          ) {
            refreshEvidence({
              vendorID: props.vendorId,
              versionID: version.id,
            });
          }
        });
    }
  }, [
    scoringAndRiskCalcStatus,
    selectedVersionID,
    refetchScoringAndRiskCalcStatus,
    props.vendorId,
    version?.id,
    deletingVersion,
  ]);

  // if our scoring job is not complete poll it
  useInterval(refreshScore, 5000);

  // ExecutingAutofillJobID - store the ID of a currently executing autofill job, if one exists
  const [executingAutofillJobID, setExecutingAutofillJobID] = useState<
    number | undefined
  >(undefined);

  const [launchVendorAssessmentAutofill] =
    VendorAssessmentAPI.useLaunchVendorAssessmentAutofillMutation();

  const onStartAutofill = useCallback(
    (
      selectedSections: VendorAssessmentAutofillSections,
      selectedEvidence: number[]
    ) => {
      if (!version?.id) {
        return;
      }

      launchVendorAssessmentAutofill({
        assessmentId: version.id,
        includeExecutiveSummary: selectedSections.executiveSummary,
        includeVendorBackground: selectedSections.vendorBackground,
        riskClassifications: selectedSections.assessmentSummary,
        evidenceIds: selectedEvidence,
      })
        .unwrap()
        .then((response) => {
          setExecutingAutofillJobID(response.jobId);
        })
        .catch(() => {
          dispatch(
            addDefaultUnknownErrorAlert(
              "error starting risk assessment autofill"
            )
          );
        });
    },
    [version?.id, launchVendorAssessmentAutofill]
  );

  // Check for a running autofill job, if we are an analyst viewing an unpublished, autofillable assessment
  const {
    data: assessmentAutofillRunningStatusData,
    isFetching: assessmentAutofillRunningStatusLoading,
  } = VendorAssessmentAPI.useGetVendorAssessmentAutofillStatusQuery(
    !props.managedOrgId ||
      assessmentLoading ||
      !version ||
      version.publishedAt ||
      !version.canAutofill
      ? skipToken
      : { assessmentId: version!.id },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (
      !assessmentAutofillRunningStatusLoading &&
      assessmentAutofillRunningStatusData
    ) {
      setExecutingAutofillJobID(
        assessmentAutofillRunningStatusData.autofillJobId
      );
    }
  }, [
    assessmentAutofillRunningStatusData,
    assessmentAutofillRunningStatusLoading,
  ]);

  // Check for a specific autofill job status
  const {
    data: assessmentAutofillJobData,
    isFetching: assessmentAutofillJobLoading,
    refetch: assessmentAutofillJobRefetch,
  } = VendorAssessmentAPI.useGetVendorAssessmentAutofillJobStatusQuery(
    executingAutofillJobID
      ? { assessmentId: version!.id, jobId: executingAutofillJobID }
      : skipToken
  );

  const refetchAutofillJobStatus = useCallback(() => {
    if (executingAutofillJobID) {
      assessmentAutofillJobRefetch();
    }
  }, [executingAutofillJobID]);

  useInterval(refetchAutofillJobStatus, 5000);

  useEffect(() => {
    if (!assessmentAutofillJobLoading && assessmentAutofillJobData) {
      if (assessmentAutofillJobData.autofillStatus == JobStatus.Complete) {
        setExecutingAutofillJobID(undefined);
        refetchVendorAssessmentData();
        dispatch(
          addDefaultSuccessAlert(
            "Autofill on your managed risk assessment is now complete"
          )
        );
      } else if (assessmentAutofillJobData.autofillStatus == JobStatus.Error) {
        setExecutingAutofillJobID(undefined);
        dispatch(
          addDefaultUnknownErrorAlert(
            "There was an error running the autofill job on your managed risk assessment"
          )
        );
      }
    }
  }, [assessmentAutofillJobData, assessmentAutofillJobLoading]);

  // Send analytics event for viewing the assessment.
  useEffect(() => {
    if (version) {
      trackEvent("VendorAssessment_View", {
        published: version.status === VendorAssessmentStatus.Published,
      });
    }
  }, [version?.status]);

  const isOldVersion = useMemo(() => {
    return (
      (assessmentList.some(
        (a) =>
          moment(a.publishedAt).isAfter(version?.publishedAt) &&
          a.id != version?.id
      ) ??
        false) &&
      !viewingSharedOrgId
    );
  }, [version, assessmentList, viewingSharedOrgId]);

  // STATE
  const [currentStep, setCurrentStep] = useState(
    props.location?.state?.currentStep ?? 1
  );

  // whenever we change our step from step 1 to another step we want to start a scoring job
  useEffect(() => {
    if (
      currentStep != 1 &&
      version?.id &&
      version?.status != VendorAssessmentStatus.Published
    ) {
      startVendorAssessmentScoringJob({
        vendorID: props.vendorId,
        versionID: version.id,
      });
    }
  }, [currentStep, version?.status, version?.id, refreshScore]);

  const [numSaving, setNumSaving] = useState(0);
  const [versionsOpen, setVersionsOpen] = useState(false);
  const [sharedAssessmentsOpen, setSharedAssessmentsOpen] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);

  const [canCreateNewAssessment, setCanCreateNewAssessment] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState<string>();

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [ndaModalOpen, setNdaModalOpen] = useState(false);
  const [signedNdaModalOpen, setSignedNdaModalOpen] = useState(false);
  const [signedNda, setSignedNda] = useState<
    IVerifiedVendorAccessItemNda | undefined
  >(undefined);

  const [startInitialAssessment, setStartInitialAssessment] = useState(
    props.location?.state?.startInitial ?? false
  );

  // Determine analyst mode - this drives multiple behaviours of the screen
  const analystMode = useMemo(() => {
    if (props.isManagementAnalystSession) {
      return VendorAssessmentAnalystMode.IsAnalyst;
    } else if (version?.managedAssessmentId && !version.publishedAt) {
      return version.withCustomer
        ? VendorAssessmentAnalystMode.Open
        : VendorAssessmentAnalystMode.Closed;
    }
    return VendorAssessmentAnalystMode.None;
  }, [
    version?.managedAssessmentId,
    version?.publishedAt,
    version?.withCustomer,
    props.isManagementAnalystSession,
  ]);

  useLocationStateUpdater<VendorAssessmentsLocationState>({
    selectedVersion: selectedVersionID,
    viewingSharedOrgId,
    currentStep,
  });

  useEffect(() => {
    if (
      !!assessmentList &&
      assessmentList.length > 0 &&
      !deletingVersion.current
    ) {
      // check if we can create a new assessment after we've loaded
      setCanCreateNewAssessment(
        props.userHasWriteAssessmentPermission &&
          props.userHasWritePermission &&
          assessmentList[0].status == VendorAssessmentStatus.Published
      );
    }

    // if we no longer have any versions make sure that the sidebar is closed
    if (!!assessmentList && assessmentList.length == 0) {
      setVersionsOpen(false);
    }
  }, [assessmentList, deletingVersion]);

  const dispatch = useAppDispatch();

  // locking functionality
  const [lockLoaded, setLockLoaded] = useState(false);
  const [isLocked, hasLock] = useLocking(
    EditTimeout,
    props.currentUserEmail,
    version
  );
  const [lockAssessmentMutation] =
    VendorAssessmentAPI.useLockEditForVendorAssessmentMutation();
  const doLock = useCallback(
    (assessmentID: number) => {
      return lockAssessmentMutation({
        vendorID: props.vendorId,
        assessmentID,
      }).catch(() =>
        dispatch(
          addDefaultUnknownErrorAlert("error obtaining lock for assessment")
        )
      );
    },
    [dispatch, props.vendorId, lockAssessmentMutation]
  );

  // if we don't have the lock and can obtain a lock do it
  useEffect(() => {
    if (
      version &&
      version.status == VendorAssessmentStatus.Draft &&
      analystMode !== VendorAssessmentAnalystMode.Closed &&
      !isLocked
    ) {
      doLock(version.id).finally(() => setLockLoaded(true));
    } else if (version) {
      setLockLoaded(true);
    }
  }, [doLock, isLocked, version?.status, version?.id]);

  const [assessmentCopy, setAssessmentCopy] = useState<VendorAssessmentCopyMap>(
    {}
  );

  const [publishDraftMutation, { isLoading: publishingAssessment }] =
    VendorAssessmentAPI.usePublishDraftForAssessmentMutation();

  const { data: managedVendorRequest, isLoading: managedVendorRequestLoading } =
    ManagedVendorsAPI.useGetManagedVendorAssessmentDetailsQuery(
      props.managedOrgId &&
        version &&
        !assessmentLoading &&
        version.managedAssessmentId
        ? {
            requestId: version.managedAssessmentId,
          }
        : skipToken,
      { refetchOnMountOrArgChange: true }
    );

  const {
    data: mergeTags,
    isFetching: mergeTagsLoading,
    refetch: refetchMergeTags,
  } = VendorAssessmentAPI.useGetVendorAssessmentMergeTagsQuery(
    version &&
      version.status != VendorAssessmentStatus.Published &&
      !publishingAssessment &&
      !assessmentLoading
      ? {
          vendorId: props.vendorId,
          versionId: version.id,
        }
      : skipToken
  );

  useEffect(() => {
    if (version) {
      if (version.status === VendorAssessmentStatus.Published) {
        // For a published assessment, only add in the sections that have a value
        const copy: VendorAssessmentCopyMap = {};
        if (version.publishedBackground !== null && !version.hideBackground) {
          copy.background = version.publishedBackground;
        }

        if (version.publishedCommentary !== null && !version.hideCommentary) {
          copy.commentary = version.publishedCommentary;
        }

        if (version.conclusion !== null && !version.hideConclusion) {
          copy.conclusion = version.publishedConclusion;
        }

        if (
          version.publishedIntroduction !== null &&
          !version.hideIntroduction
        ) {
          copy.intro = version.publishedIntroduction;
        }

        if (version.customerCommentary !== null) {
          copy.customerCommentary = version.customerCommentary;
        }

        setAssessmentCopy(copy);
      } else {
        setAssessmentCopy({
          background: version.background,
          commentary: version.commentary,
          conclusion: version.conclusion,
          intro: version.introduction,
          customerCommentary: version.customerCommentary,
        });
      }
    }
  }, [
    version?.id,
    version?.status,
    version?.background,
    version?.introduction,
    version?.conclusion,
    version?.commentary,
    version?.publishedIntroduction,
    version?.publishedBackground,
    version?.publishedConclusion,
    version?.publishedCommentary,
    version?.customerCommentary,
    dispatch,
    props.vendorId,
  ]);

  // if our tier, labels or portfolio changes we need to refetch merge tags
  useEffect(() => {
    if (
      version &&
      version.status == VendorAssessmentStatus.Draft &&
      !assessmentLoading
    ) {
      refetchMergeTags();
    }
  }, [version?.status, props.tier, props.labels, props.portfolios]);

  // fetch data
  useEffect(() => {
    if (props.watching) {
      dispatch(fetchOrganisationDefaultTexts());
      dispatch(fetchVendorRiskWaivers(props.vendorId));
      dispatch(fetchVendorSummaryAndCloudscans(props.vendorId, false));
    }
  }, [dispatch, props.vendorId, props.vendorVerified, props.watching]);

  // when a version is opened grab the remediation request for it
  useEffect(() => {
    if (version) {
      dispatch(fetchRemediationRequestDetailsForVendorAssessment(version.id));
    }
  }, [version?.id]);

  // Reset open version when we delete an assessment
  useEffect(() => {
    if (deletingVersion.current) {
      deletingVersion.current = false;
      if (assessmentList && assessmentList.length >= 1) {
        setSelectedVersionID(assessmentList[0].id);
      }
      setVersionsOpen(false);
    }
  }, []);

  // Get remediation request associated with the assessment, if it exists
  useEffect(() => {
    if (version?.remediationRequestId) {
      dispatch(
        fetchRemediationRequestDetails(version.remediationRequestId, false)
      );
    }
  }, [version?.remediationRequestId]);

  // effect
  // if this component is navigated to using the location state value 'startInitial' = true, then the component will
  // attempt to start a brand-new assessment version on load, but ONLY if there are no other versions - that is to say,
  // it represents the very first assessment for the vendor.
  useEffect(() => {
    if (!assessmentListLoading && startInitialAssessment) {
      if (
        assessmentList.length == 0 &&
        props.userHasWriteAssessmentPermission
      ) {
        startNewAssessment();
      }
      setStartInitialAssessment(false);
    }
  }, [assessmentListLoading, assessmentList]);

  // if this is a managed vendor session, and this assessment is not linked to a managed service check if there's one we
  // could link it to
  const [unlinkedManagedAssessmentId, setUnlinkedManagedAssessmentId] =
    useState<number | undefined>(undefined);
  useEffect(() => {
    if (
      version &&
      props.isManagementAnalystSession &&
      !version.managedAssessmentId
    ) {
      dispatch(
        getLatestUnstartedRequestForVendor(version.datastoreVendorID)
      ).then((result) => setUnlinkedManagedAssessmentId(result));
    }
  }, [
    version?.managedAssessmentId,
    version?.datastoreVendorID,
    props.isManagementAnalystSession,
  ]);

  const [openConfirmationModal, confirmationModal] = useConfirmationModalV2();
  const [openSetAssessmentDateModal, setAssessmentDateModal] = useModalV2(
    CompleteVendorAssessmentModal
  );

  const [openRiskWaiverModal, riskWaiverModal] = useModalV2(
    CreateVendorRiskWaiverModal
  );

  const [openRequestRemediationModal, requestRemediationModal] = useModalV2(
    RequestRemediationModal
  );

  const [_openDomainsModal, domainsModal] = useModalV2(
    VendorAssessmentPickDomainsModal
  );
  const openDomainsModal = useCallback(
    (readOnly = false) => {
      if (version && evidence) {
        _openDomainsModal({
          vendorAssessmentID: version.id,
          vendorID: props.vendorId,
          managedOrgID: props.managedOrgId,
          initialAllSelected: version.includeAllHostnames,
          initialSelectedHostnames: evidence.includedHostnames ?? [],
          readOnly,
          hostnameSnapshot: evidence.hostnameSnapshots,
        });
      }
    },
    [version, evidence, props.vendorId, props.managedOrgId]
  );

  const [openRenameAssessmentModal, renameAssessmentModal] = useModalV2(
    RenameAssessmentModal
  );

  const [openAssessmentAutofillModal, assessmentAutofillModal] = useModalV2(
    VendorAssessmentAutofillModal
  );

  const analystInfoText = useMemo(() => {
    return version?.managedAssessmentId && !version.publishedAt
      ? props.isManagementAnalystSession
        ? !version?.withCustomer
          ? "You are editing this managed vendor assessment as an analyst. Currently the customer cannot see your changes. When you are done, 'Share with customer' to allow them to view it"
          : "You are editing this managed vendor assessment as an analyst. It has been shared with the customer for review. You can still make changes, but they will be immediately visible to the customer"
        : analystMode == VendorAssessmentAnalystMode.Closed
          ? "One of our analysts is currently compiling this risk assessment. When they they are done, you'll be notified and be able to review their work."
          : "Our analyst has completed this risk assessment. Please feel free to add your own commentary and publish the assessment when you are done."
      : undefined;
  }, [
    analystMode,
    props.isManagementAnalystSession,
    version?.managedAssessmentId,
    version?.publishedAt,
    version?.withCustomer,
  ]);

  // ACTIONS
  const onSelectVersion = useCallback((id?: number) => {
    setVersionsOpen(false);
    setViewingSharedOrgId(undefined);
    setSelectedVersionID(id);
  }, []);

  const [deleteAssessment] =
    VendorAssessmentAPI.useDeleteVendorAssessmentMutation();
  const onDeleteVersion = (assessmentId: number) => {
    openConfirmationModal({
      title: "Delete this version of the risk assessment?",
      description: "This action can't be undone.",
      iconClass: "cr-icon-trash",
      buttonAction: async () => {
        deletingVersion.current = true;

        // we want to reset what version we have selected before the delete otherwise rtk
        // will try to reload our current assessment when we delete the assessment
        //
        // if this was the latest version set this to the previous version
        let newId: number | undefined;
        if (assessmentList.length > 1 && assessmentList[0].id == assessmentId) {
          newId = assessmentList[1].id;
        }

        return deleteAssessment({
          versionID: assessmentId,
          vendorID: props.vendorId,
        })
          .then(() => {
            setSelectedVersionID(newId);
            dispatch(
              fetchVendorSummaryAndCloudscans(props.vendorId, true, false)
            );
            dispatch(
              addDefaultSuccessAlert(
                "Successfully deleted your risk assessment version"
              )
            );
            // if this was the last version then go back to the assessment list
            if (assessmentList.length <= 1) {
              props.history.goBack();
            }
          })
          .catch(() =>
            dispatch(
              addDefaultUnknownErrorAlert("Error deleting risk assessment")
            )
          )
          .finally(() => {
            deletingVersion.current = false;
          });
      },
      buttonText: "Delete version",
      dangerousAction: true,
    });
  };

  const [startNewDraftAssessment, { isLoading: startingNewDraft }] =
    VendorAssessmentAPI.useStartNewDraftAssessmentMutation();
  const startNewAssessment = useCallback(() => {
    if (!assessmentList) {
      return;
    }
    if (assessmentList.length == 0) {
      console.error("can no longer start brand new assessment from this page");
    } else {
      setLockLoaded(false);
      startNewDraftAssessment({
        vendorID: props.vendorId,
        streamID: selectedStreamId,
      })
        .unwrap()
        .then(({ newID }) => {
          setSelectedVersionID(newID);
        })
        .catch(() =>
          dispatch(
            addDefaultUnknownErrorAlert("error starting new risk assessment")
          )
        );
    }
  }, [assessmentList, props.vendorId, dispatch, selectedStreamId]);

  const [toggleSurveyMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftSurveyMutation();
  const [toggleEvidenceMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftEvidenceMutation();
  const [togglePageMutation] =
    VendorAssessmentAPI.useToggleAssessmentDraftPageMutation();

  const [defaultCommentaryWithWebsiteRisks, defaultCommentaryNoWebsiteRisks] =
    useOrgDefaultTexts([
      DefaultTextType.RiskAssessmentFindings,
      DefaultTextType.RiskAssessmentFindingsNoWebRisk,
    ]);

  const updateAssessment = useCallback(
    (update: VendorAssessmentUpdate) => {
      if (!version || !evidence) {
        return;
      }

      const currentIncludeWaivers = version.includeWaivers;

      const findState = (
        id: number,
        array: { id: number; selected: boolean }[]
      ) => array.find((i) => i.id == id)?.selected;

      let prom = Promise.resolve();
      switch (update.type) {
        case "toggle_survey": {
          const state = findState(
            update.id,
            update.shared ? evidence.publicSurveys : evidence.surveys
          );

          prom = toggleSurveyMutation({
            vendorID: version.datastoreVendorID,
            assessmentID: version.id,
            state: !state,
            shared: update.shared,
            surveyID: update.id,
          }).unwrap();
          break;
        }
        case "toggle_evidence": {
          const state = findState(
            update.id,
            update.shared ? evidence.publicAdditional : evidence.additional
          );

          prom = toggleEvidenceMutation({
            vendorID: version.datastoreVendorID,
            assessmentID: version.id,
            evidenceID: update.id,
            shared: update.shared,
            state: !state,
          }).unwrap();
          break;
        }
        case "toggle_page": {
          const state = evidence.pages?.find(
            (p) =>
              p.url.toLowerCase() == update.url.toLowerCase() &&
              update.category == p.category
          )?.selected;

          prom = togglePageMutation({
            vendorID: version.datastoreVendorID,
            assessmentID: version.id,
            category: update.category,
            url: update.url,
            state: !state,
          }).unwrap();
          break;
        }
        case "toggle_scanning":
          // https://upguard.atlassian.net/browse/APAC-4853
          // If the user toggles including automated scanning results in the risk remediation, update the
          // default commentary text to either include or exclude the automated scanning paragraph appropriately.
          // If the user has edited the default text, do not replace their edits.
          const assessmentDraftUpdate: Partial<AssessmentDraftUpdate> = {
            includeWebsiteRisks: !version.includeWebsiteRisks,
          };
          if (
            assessmentDraftUpdate.includeWebsiteRisks &&
            !version.customerCommentary &&
            !version.publishedCommentary &&
            version.commentary === defaultCommentaryNoWebsiteRisks
          ) {
            assessmentDraftUpdate.commentary =
              defaultCommentaryWithWebsiteRisks;
          } else if (
            !assessmentDraftUpdate.includeWebsiteRisks &&
            !version.customerCommentary &&
            !version.publishedCommentary &&
            version.commentary === defaultCommentaryWithWebsiteRisks
          ) {
            assessmentDraftUpdate.commentary = defaultCommentaryNoWebsiteRisks;
          }

          prom = dispatch(
            updateAssessmentDraftData(
              version.datastoreVendorID,
              version.id,
              assessmentDraftUpdate
            )
          );
          break;
        case "toggle_waivers":
          prom = dispatch(
            updateAssessmentDraftData(version.datastoreVendorID, version.id, {
              includeWaivers: !currentIncludeWaivers,
            })
          );
          break;
      }

      // if we have updated anything other than the commentary we need to reload the risks
      // but only if any other updates haven't fired since...
      setNumSaving((i) => i + 1);
      try {
        prom
          .then(() => {
            if (
              version.managedAssessmentId &&
              props.isManagementAnalystSession
            ) {
              dispatch(
                analystGetSingleRequest(version.managedAssessmentId, true)
              );
            }
          })
          .then(() => setLastSavedAt(moment().format()))
          .then(() => setNumSaving((i) => i - 1));
      } catch {
        setNumSaving((i) => i - 1);
        addDefaultUnknownErrorAlert("Error updating assessment draft");
      }
    },
    [
      dispatch,
      props.isManagementAnalystSession,
      version?.managedAssessmentId,
      version?.id,
      version?.datastoreVendorID,
      version?.includeWaivers,
      version?.includeWebsiteRisks,
      version?.commentary,
      props.vendorId,
      evidence,
    ]
  );

  const doUpdateCopy = useCallback(
    (updateType: string, text: string) => {
      if (!version) {
        return;
      }

      setAssessmentCopy((copy) => ({ ...copy, [updateType]: text }));
    },
    [version]
  );

  const [updateVendorAssessmentNarrativeSection] =
    VendorAssessmentAPI.useUpdateVendorAssessmentNarrativeSectionMutation();

  const saveCopyThrottled = useCallback(
    _throttle(
      async (updateType: string, text: string) => {
        if (!version) {
          return Promise.resolve();
        }

        setNumSaving((i) => i + 1);
        if (version.canAutofill) {
          const section = assessmentData?.narrativeSections?.find(
            (section) => section.section === updateType
          );

          if (section) {
            const updatedSection = { ...section, content: text };

            return updateVendorAssessmentNarrativeSection({
              vendorId: props.vendorId,
              assessmentId: version.id,
              section: updatedSection,
              skipOptimisticUpdate: true,
            })
              .unwrap()
              .catch(() => {
                dispatch(
                  addDefaultUnknownErrorAlert("error saving risk assessment")
                );
                return Promise.resolve();
              })
              .finally(() => setNumSaving((i) => i - 1));
          } else {
            // We don't know this section, something weird has happened so just return
            return Promise.resolve();
          }
        }

        return dispatch(
          UpdateCopyForVendorAssessmentV1(props.vendorId, {
            id: version.id,
            updateType,
            text,
          })
        )
          .then(() => setLastSavedAt(moment().format()))
          .catch(() => {
            dispatch(
              addDefaultUnknownErrorAlert("error saving risk assessment")
            );
            return Promise.resolve();
          })
          .finally(() => setNumSaving((i_1) => i_1 - 1));
      },
      3000,
      { leading: false, trailing: true }
    ),
    [
      dispatch,
      version?.id,
      version?.canAutofill,
      props.vendorId,
      assessmentData?.narrativeSections,
    ]
  );

  const saveCopy = useCallback(
    (updateType: string, text: string) => {
      // first perform an optimistic update to avoid stale data feedback
      if (version && version.canAutofill) {
        const section = assessmentData?.narrativeSections?.find(
          (section) => section.section === updateType
        );

        if (section) {
          const updatedSection = { ...section, content: text };

          dispatch(
            VendorAssessmentAPI.util.updateQueryData(
              "getVendorAssessmentData",
              { vendorID: props.vendorId, versionID: version.id },
              (draft) => {
                const newSections: VendorAssessmentNarrativeSection[] = [];

                draft.narrativeSections?.forEach((s) => {
                  if (s.section !== updatedSection.section) {
                    newSections.push(s);
                  }
                });

                draft.narrativeSections = [...newSections, updatedSection];
              }
            )
          );
        }
      }

      return saveCopyThrottled(updateType, text) ?? Promise.resolve();
    },

    [
      version,
      saveCopyThrottled,
      assessmentData?.narrativeSections,
      dispatch,
      props.vendorId,
    ]
  );

  const [updateRiskCommentsMutation] =
    VendorAssessmentAPI.useUpdateRiskCommentsForVendorAssessmentMutation();
  const onUpdateRiskComments = useCallback(
    (updates: commentsUpdate) => {
      if (!version) {
        return;
      }

      setNumSaving((i) => i + 1);

      updateRiskCommentsMutation({
        comments: updates,
        assessmentID: version.id,
        vendorId: version.datastoreVendorID,
      })
        .unwrap()
        .catch(() =>
          dispatch(addDefaultUnknownErrorAlert("error saving risk assessment"))
        )
        .finally(() => setNumSaving((i) => i - 1));
    },
    [dispatch, version?.id, version?.datastoreVendorID]
  );

  const [changeReassessmentDate] =
    VendorAssessmentAPI.useUpdateReassessmentDateMutation();
  const onChangeReassessmentDate = useCallback(
    async (streamId: number, reassessmentDate?: string) => {
      await changeReassessmentDate({
        vendorId: props.vendorId,
        reassessmentDate: reassessmentDate
          ? moment(reassessmentDate).format()
          : undefined,
        streamId,
      })
        .unwrap()
        .then(() =>
          addDefaultSuccessAlert("Successfully updated reassessment date")
        )
        .catch(() =>
          addDefaultUnknownErrorAlert("Error updating reassessment date")
        );
    },
    [dispatch, props.vendorId, changeReassessmentDate]
  );

  const onPublishAssessment = useCallback(
    async (reassessmentDate?: string) => {
      // Deprecated: duplicate of AuditLog_RiskAssessmentPublished.
      trackEvent("RiskAssessment_Publish");

      publishDraftMutation({
        vendorID: props.vendorId,
        reassessmentDate: reassessmentDate
          ? moment(reassessmentDate).format()
          : undefined,
        streamID: selectedStreamId,
      })
        .unwrap()
        .then(() => {
          dispatch(
            fetchVendorSummaryAndCloudscans(props.vendorId, true, false)
          );
          refetchAssessmentRisks();
          setCurrentStep(1);
          dispatch(
            addDefaultSuccessAlert("Your risk assessment is now published")
          );
        })
        .catch(() =>
          dispatch(addDefaultUnknownErrorAlert("Error publishing assessment"))
        );
      dispatch(
        setPLGTaskCompleteIfIncomplete("Checklist_VendorRisk_Assessment")
      ).then((result) => {
        if (result) {
          setFireConfetti(true);
        }
      });
    },
    [dispatch, props.vendorId, selectedStreamId]
  );

  const [openRelatedOrgAccessModal, relatedOrgAccessModal] =
    useModalV2<IRequestAccessModalProps>(RequestAccessModal);

  const onSelectSharedAssessment = (id: number, orgId: number) => {
    if (!assessmentListResp?.sharedAssessments) {
      return;
    }
    const relatedOrg = assessmentListResp.sharedAssessments.find(
      (sa) => sa.organisationID == orgId
    );
    if (relatedOrg && !relatedOrg.sharedAccessGranted) {
      openRelatedOrgAccessModal({
        dispatch,
        vendorId: props.vendorId,
        vendorName: props.vendorName,
        orgId,
        orgName: relatedOrg.sharedOrgName,
      });
    } else {
      setSelectedVersionID(id);
      setViewingSharedOrgId(orgId);
      setSharedAssessmentsOpen(false);
    }
  };

  const urlPrefix = props.isManagementAnalystSession
    ? `/analysts/tpvm/${props.managedOrgId}/${props.vendorId}`
    : `/vendor/${props.vendorId}`;

  const backContext = useMemo(
    () => ({
      backContext: {
        goBack: true,
        backToText: "Back to risk assessment",
      },
    }),
    []
  );

  const goToRiskProfile = useCallback(
    () =>
      props.history.push(
        version && version.publishedAt
          ? `${urlPrefix}/risk_profile/byassessment/${version?.id}`
          : `${urlPrefix}/risk_profile`,
        backContext
      ),
    [backContext, props.history, urlPrefix, version?.publishedAt, version?.id]
  );

  const goToWaivers = useCallback(
    () =>
      props.history.push(
        version && version.publishedAt
          ? `${urlPrefix}/riskwaivers/byassessment/${version?.id}`
          : `${urlPrefix}/riskwaivers`,
        backContext
      ),
    [backContext, props.history, urlPrefix, version?.publishedAt, version?.id]
  );

  const goToSurvey = useCallback(
    (id: number, isPublic: boolean) => {
      if (isPublic) {
        props.history.push(
          version?.publishedAt
            ? `${urlPrefix}/${
                viewingSharedOrgId && viewingSharedOrgId > 0
                  ? `sharedassets/${viewingSharedOrgId}/sharedassessment`
                  : "sharedassessment"
              }/surveys/${id}/byassessment/${version?.id}`
            : `${urlPrefix}/${
                props.currentOrgIsInAccountGroup
                  ? "sharedassets/vendor"
                  : "sharedassessment"
              }/surveys/${id}`,
          backContext
        );
      } else {
        props.history.push(
          version?.publishedAt
            ? `${urlPrefix}${
                viewingSharedOrgId && viewingSharedOrgId > 0
                  ? `/sharedassets/${viewingSharedOrgId}`
                  : ""
              }/surveys/${id}/byassessment/${version?.id}`
            : `${urlPrefix}/surveys/${id}`,
          backContext
        );
      }
    },
    [
      props.history,
      urlPrefix,
      backContext,
      version?.publishedAt,
      version?.id,
      props.currentOrgIsInAccountGroup,
      viewingSharedOrgId,
    ]
  );

  const goToAdditionalEvidence = useCallback(
    (id: number, isPublic: boolean) =>
      props.history.push(
        isPublic
          ? `${urlPrefix}/sharedAssessment`
          : version && version.publishedAt
            ? `${urlPrefix}/evidence/details/${id}/byassessment/${version.id}`
            : `${urlPrefix}/evidence/details/${id}`,
        backContext
      ),
    [props.history, urlPrefix, backContext, version?.id, version?.publishedAt]
  );

  const goToEvidencePage = useCallback(
    (url: string, category: string) => {
      let navUrl = `${urlPrefix}/evidence/pages/details?category=${category}&url=${encodeURIComponent(
        url
      )}`;
      if (version && version.publishedAt) {
        navUrl += `&assessment_id=${version.id}`;
      }
      props.history.push(navUrl, backContext);
    },
    [urlPrefix, version?.publishedAt, version?.id, props.history, backContext]
  );

  const goToPages = useCallback(
    () => props.history.push(`${urlPrefix}/evidence/pages`, backContext),
    [props.history, urlPrefix, backContext]
  );

  const goToTemplates = useCallback(
    () =>
      props.history.push(`/settings/defaulttexts`, {
        ...backContext,
        tab: "riskAssessmentsV2",
      }),
    [backContext, props.history]
  );

  const goToRemediationRequest = useCallback(() => {
    if (version?.remediationRequestId) {
      props.history.push(
        `${urlPrefix}/remediation/${version?.remediationRequestId}`,
        backContext
      );
    }
  }, [props.history, urlPrefix, backContext, version?.remediationRequestId]);

  const orgName = useAppSelector(
    (state) =>
      state.common.userData.orgList?.find(
        (o) => o.id == state.common.userData.currentOrgID
      )?.name ?? ""
  );

  const onAddAnotherRisk = useCallback(
    (
      risk: IRisk,
      hostname?: string,
      surveyId?: number,
      isPublicSurvey?: boolean
    ) => {
      props.history.push(
        `${urlPrefix}/remediation/add?forVendorAssessment=${version?.id}`,
        {
          ...backContext,
          ...(risk.surveys
            ? {
                surveyIdsWithRisk:
                  surveyId && !isPublicSurvey
                    ? [surveyId]
                    : !isPublicSurvey
                      ? risk.surveys
                          .filter((s) => !s.inRemediation && !s.publicSurvey)
                          .map((s) => s.surveyId)
                      : [],
                publicSurveyIdsWithRisk:
                  surveyId && isPublicSurvey
                    ? [surveyId]
                    : isPublicSurvey
                      ? risk.surveys
                          .filter((s) => !s.inRemediation && s.publicSurvey)
                          .map((s) => s.surveyId)
                      : [],
                riskId: risk.id,
                riskType: RiskProfileRiskTypes.Survey,
              }
            : risk.additionalEvidences
              ? {
                  riskId: risk.id,
                  riskType: RiskProfileRiskTypes.Evidence,
                }
              : {
                  riskId: risk.id,
                  filterForWebsite: hostname ? hostname : null,
                  riskType: RiskProfileRiskTypes.Cloudscan,
                }),
        }
      );
    },
    [props.history, urlPrefix, backContext, version?.id]
  );

  const onRequestRemediationForRisk = useCallback(
    (
      risk: IRisk,
      hostname?: string,
      surveyId?: number,
      isPublicSurvey?: boolean
    ) => {
      openRequestRemediationModal({
        vendorID: props.vendorId,
        risk,
        hostname,
        surveyId,
        isPublicSurvey,
        vendorName: props.vendorName,
        vendorAssessmentID: version?.id ?? 0,
        orgName,
        managedOrgID: props.managedOrgId,
        onAddAnotherRisk,
      });
    },
    [
      openRequestRemediationModal,
      props.vendorId,
      props.vendorName,
      version?.id,
      props.managedOrgId,
      orgName,
      onAddAnotherRisk,
    ]
  );

  const onCreateVendorRiskWaiverForRisk = useCallback(
    (
      risk: IRisk,
      hostname?: string,
      surveyId?: number,
      isPublicSurvey?: boolean,
      waiverType?: WaiverType
    ) => {
      openRiskWaiverModal({
        vendorId: props.vendorId,
        vendorName: props.vendorName,
        risk,
        website: hostname,
        surveyId,
        isPublicSurvey,
        vendorAssessmentId: version?.id,
        managedOrgId: props.managedOrgId,
        isManagementAnalystSession: props.isManagementAnalystSession,
        assuranceType: props.assuranceType,
        waiverType: waiverType ?? WaiverType.RiskWaiver,
      });
    },
    [
      openRiskWaiverModal,
      props.vendorId,
      props.vendorName,
      props.managedOrgId,
      props.isManagementAnalystSession,
      props.assuranceType,
      version?.id,
    ]
  );

  const onOpenCloudscanPanel = useCallback(
    (scan: string, risk: string) =>
      OpenSidePanel(props.history, { scan, risk }),
    [props.history]
  );

  const [updateAssessmentWithCustomerStatus] =
    VendorAssessmentAPI.useUpdateWithCustomerStatusForAssessmentMutation();

  const openSetWithCustomer = () =>
    openConfirmationModal({
      title: version?.withCustomer
        ? "Unshare with customer?"
        : "Share with customer?",
      description: version?.withCustomer
        ? "If you unshare, the customer will no longer be able to view the assessment."
        : "This notifies the customer that the assessment is available for review. If you share this, the customer will be able to view the assessment including any edits you subsequently make.",
      primaryAction: true,
      buttonText: "Confirm",
      buttonAction: () => {
        if (version && version.id !== 0) {
          updateAssessmentWithCustomerStatus({
            vendorId: props.vendorId,
            assessmentId: version.id,
            withCustomer: !version.withCustomer,
          })
            .unwrap()
            .then(() =>
              dispatch(
                addDefaultSuccessAlert(
                  !version?.withCustomer
                    ? "Successfully shared with customer"
                    : "Successfully unshared with customer"
                )
              )
            )
            .catch(() =>
              dispatch(
                addDefaultUnknownErrorAlert("Error setting with customer state")
              )
            );
        }
      },
    });

  const openAssignToManagedAssessment = () => {
    if (version && unlinkedManagedAssessmentId) {
      openConfirmationModal({
        title: "Link assessment to managed request?",
        description:
          "This will link the the assessment to the managed request.",
        buttonAction: () =>
          dispatch(
            analystAssignVendorAssessmentToManagedAssessment(
              version.id,
              unlinkedManagedAssessmentId
            )
          )
            .then(() => {
              dispatch(
                addDefaultSuccessAlert(
                  "Successfully linked assessment to managed assessment"
                )
              );
              dispatch(
                invalidateVendorAssessmentDraftsForVendor(props.vendorId)
              );
            })
            .catch(() =>
              dispatch(
                addDefaultUnknownErrorAlert(
                  "Error linking assessment to managed request"
                )
              )
            ),
        buttonText: "Link assessment",
        primaryAction: true,
      });
    }
  };

  const [setKeyRisksMutation] = VendorAssessmentAPI.useSetKeyRisksMutation();
  const toggleKeyRisk = useCallback(
    (risk: VendorAssessmentKeyRisk, selected: boolean) => {
      if (version) {
        let keyRisks = vendorAssessmentRisks?.keyRisks
          ? [...vendorAssessmentRisks.keyRisks]
          : [];

        if (selected) {
          // Add the risk if it isn't there already
          if (
            !keyRisks.some((r) => {
              return r.riskID === risk.riskID && r.severity === risk.severity;
            })
          ) {
            keyRisks.push(risk);
          }
        } else {
          // Deselected - filter out this risk
          keyRisks = keyRisks.filter((r) => {
            return r.riskID !== risk.riskID || r.severity !== risk.severity;
          });
        }
        setNumSaving((i) => i + 1);
        setKeyRisksMutation({
          vendorId: props.vendorId,
          assessmentId: version.id,
          keyRisks,
        })
          .unwrap()
          .catch(() =>
            dispatch(
              addDefaultUnknownErrorAlert("error updating assessment state")
            )
          )
          .finally(() => setNumSaving((i) => i - 1));
      }
    },
    [version?.id, vendorAssessmentRisks?.keyRisks, props.vendorId]
  );

  const [setContentSectionHiddenStatusMutation] =
    VendorAssessmentAPI.useSetContentSectionHiddenStatusMutation();

  const toggleHideSection = useCallback(
    (type: string, hidden: boolean) => {
      if (version) {
        setNumSaving((i) => i + 1);

        if (version.canAutofill && type != AutofillSummarySection) {
          const section = assessmentData?.narrativeSections?.find(
            (section) => section.section === type
          );

          if (section) {
            const updatedSection = { ...section, hidden: hidden };
            updateVendorAssessmentNarrativeSection({
              vendorId: props.vendorId,
              assessmentId: version.id,
              section: updatedSection,
            })
              .unwrap()
              .catch(() => {
                dispatch(
                  addDefaultUnknownErrorAlert("error saving risk assessment")
                );
              })
              .finally(() => setNumSaving((i) => i - 1));
          }
          return;
        }

        setContentSectionHiddenStatusMutation({
          vendorId: props.vendorId,
          assessmentId: version.id,
          sectionType: type,
          hidden,
        })
          .unwrap()
          .catch(() => {
            dispatch(
              addDefaultUnknownErrorAlert("error updating assessment state")
            );
          })
          .finally(() => setNumSaving((i) => i - 1));
      }
    },
    [
      version?.id,
      props.vendorId,
      dispatch,
      version?.canAutofill,
      assessmentData?.narrativeSections,
    ]
  );

  const hasSomeEvidence =
    version?.includeWebsiteRisks ||
    version?.includeWaivers ||
    evidence?.surveys?.some((s) => s.selected) ||
    evidence?.publicSurveys?.some((s) => s.selected) ||
    evidence?.additional?.some((a) => a.selected) ||
    evidence?.publicAdditional?.some((a) => a.selected) ||
    evidence?.pages?.some((p) => p.selected);

  type tsteps = "evidence" | "risks" | "review";
  const steps: IStep<tsteps>[] = [
    {
      id: "evidence",
      text: "Select evidence",
      onClick: () => setCurrentStep(1),
    },
    {
      id: "risks",
      text: "Manage risks",
      onClick: hasSomeEvidence ? () => setCurrentStep(2) : undefined,
    },
    {
      id: "review",
      text: "Review assessment",
      onClick: hasSomeEvidence ? () => setCurrentStep(3) : undefined,
    },
  ];

  const vendorWords = getVendorWords(props.assuranceType);

  const introTexts = [
    `Select the evidence you want to form the basis of this risk assessment, select as little or as many sources as required. The evidence is gathered as part of our automated scanning, questionnaires you’ve sent, additional evidence, and risk waivers generated.`,
    `Use this section to provide commentary on any specific risks you identify during your review, waive risks, and remediate risks. Highlight risks you want to draw your attention on for this risk assessment.`,
    `Review your assessment carefully before publishing it. Completing this assessment will finalize this version, capturing the set of risks at this point in time. It will also become visible to all users within your account.`,
  ];

  // The remedationRequest associated with the current assessment version
  const remediationRequest = useMemo(() => {
    if (
      version?.remediationRequestId &&
      props.remediationRequests &&
      props.remediationRequests[version.remediationRequestId]
    ) {
      return props.remediationRequests[version.remediationRequestId]?.details;
    }

    return undefined;
  }, [version?.remediationRequestId, props.remediationRequests]);

  const loading =
    assessmentList == undefined ||
    assessmentListLoading ||
    (numVersions > 0 && !version) ||
    assessmentLoading ||
    startingNewDraft ||
    publishingAssessment ||
    !lockLoaded ||
    managedVendorRequestLoading;

  const isEditing =
    !loading &&
    hasLock &&
    version &&
    !version.publishedAt &&
    evidence &&
    props.userHasWriteAssessmentPermission &&
    analystMode !== VendorAssessmentAnalystMode.Open &&
    analystMode !== VendorAssessmentAnalystMode.Closed;

  const downloadDraftReportButton = (
    <GenerateDraftReportButton
      vendorId={props.vendorId}
      riskAssessmentId={version?.id}
      isSubsidiary={props.isSubsidiary}
    ></GenerateDraftReportButton>
  );

  const nextButton = (
    <TooltipButton
      arrow={
        currentStep != 3 && analystMode !== VendorAssessmentAnalystMode.Open
      }
      disabled={
        !hasSomeEvidence ||
        (currentStep == 3 &&
          (scoringAndRiskCalcStatus?.status != "Complete" ||
            scoreJobLoading ||
            mergeTagsLoading ||
            scoreAndRiskCalcStatusLoading ||
            !!executingAutofillJobID ||
            assessmentAutofillRunningStatusLoading ||
            assessmentAutofillJobLoading))
      }
      tooltipContent={
        currentStep == 3 && scoringAndRiskCalcStatus?.status != "Complete"
          ? "We are calculating the score for this assessment. You'll be able to publish once this is completed."
          : undefined
      }
      onClick={
        currentStep != 3 && analystMode !== VendorAssessmentAnalystMode.Open
          ? () => {
              setCurrentStep((val) => val + 1);
              window.scrollTo(0, 0);
            }
          : () => {
              saveCopyThrottled.flush();
              openSetAssessmentDateModal({
                confirm: onPublishAssessment,
                newReassessmentDate:
                  selectedStream?.reassessmentDate ??
                  moment().add(1, "y").format("YYYY-MM-DD"),
                hasUnfinishedSurveys:
                  evidence?.surveys.some(
                    (s) => s.state != SurveyStatus.Complete && s.selected
                  ) ?? false,
                newlyPublished: true,
              });
            }
      }
      primary={true}
    >
      {currentStep == 3 || analystMode === VendorAssessmentAnalystMode.Open
        ? "Publish assessment"
        : "Next"}
    </TooltipButton>
  );

  const goBack = useCallback(() => {
    if (props.location.state?.backContext?.goBack) {
      props.history.goBack();
    } else if (props.location.state?.backContext?.backTo) {
      props.history.push(props.location.state.backContext.backTo);
    } else {
      props.history.push(`${urlPrefix}/assessment`);
    }
  }, [props.history]);
  const [backToText, setBackToText] = useState("Back to Risk Assessments");
  useEffect(() => {
    if (props.location.state && props.location.state.backContext) {
      setBackToText(props.location.state.backContext.backToText);
    } else {
      setBackToText("Back to Risk Assessments");
    }
  }, [props.location]);

  const title = version?.name ?? "Risk assessment";

  interface domainsAndIPsPanelGroupRiskParams {
    riskPanel?: JSX.Element;
    getVulnPanel?: GetVulnPanel;
  }

  const [selectedRiskParams, setSelectedRiskParams] = useState<
    domainsAndIPsPanelGroupRiskParams | undefined
  >(undefined);

  const onSelectRisk = (riskId: string | undefined) => {
    if (!riskId) {
      return;
    }

    setSelectedRiskParams(undefined);
    OpenSidePanel(props.history, { risk: riskId });
  };

  const onSelectedRiskParamsChange = useCallback(
    (riskPanel?: JSX.Element, getVulnPanel?: GetVulnPanel) => {
      setSelectedRiskParams({
        riskPanel: riskPanel,
        getVulnPanel: getVulnPanel,
      });
    },
    []
  );

  const onOpenCloudscanPanelFromRiskPanel = useCallback(
    (scan: string, riskTitle: string, risk?: string) => {
      OpenSidePanel(
        props.history,
        { scan: scan, risk: risk },
        "'" + riskTitle + "'",
        props.location
      );
    },
    [props.history, props.location]
  );

  const onOpenCVEPanelFromRiskPanel = useCallback(
    (riskId: string, riskTitle: string, vuln: string, verified?: boolean) => {
      OpenSidePanel(
        props.history,
        { vuln, verified: verified ?? false, risk: riskId },
        "'" + riskTitle + "'",
        props.location
      );
    },
    [props.history, props.location]
  );

  const onClickAssetInVulnPanel = useCallback(
    (currentPanelTitle: string, asset: string) => {
      OpenSidePanel(
        props.history,
        { scan: asset },
        "'" + currentPanelTitle + "'",
        props.location
      );
    },
    [props.history, props.location]
  );

  return (
    <div className={"vendor-assessment-v3"}>
      {fireConfetti && <ConfettiFullPage />}
      {props.isManagementAnalystSession && (
        <InfoBar
          message={`You are viewing a vendor’s profile as an Analyst (Managed Vendor Assessment)`}
        />
      )}
      <PageHeader
        vendorId={props.vendorId}
        titleTag={title}
        title={
          <div className={"assessment-title"}>
            <div className={"title-top"}>
              {title}
              {isEditing && (
                <IconButton
                  icon={
                    <i
                      className={"cr-icon-pencil"}
                      onClick={() =>
                        openRenameAssessmentModal({
                          vendorId: props.vendorId,
                          currentName: version.name,
                          assessmentId: version.id,
                        })
                      }
                    />
                  }
                />
              )}
            </div>
            {selectedStream?.scope && selectedStream.scope.length > 0 && (
              <div className={"sub-title"}>
                Assessment scope: {selectedStream.scope.join(", ")}
              </div>
            )}
          </div>
        }
        history={props.history}
        backAction={goBack}
        backText={backToText}
        breadcrumbs={[
          ...getVendorPageBreadcrumbs(
            props.vendorId,
            props.vendorName,
            props.assuranceType
          ),
          {
            text: "Risk Assessments",
            to: `${urlPrefix}/assessment`,
          },
          { text: version?.name ?? "Risk Assessment" },
        ]}
        isManagementAnalystSession={props.isManagementAnalystSession}
        managedOrgId={props.managedOrgId}
        infoSectionPageKey={"infoSection_vendorRiskAssessment"}
        rightSection={
          <>
            {(
              assessmentListResp?.sharedAssessments?.filter(
                (sa) => !sa.sharedAccessRejected
              ) ?? []
            ).length > 0 &&
              !loading && (
                <Button onClick={() => setSharedAssessmentsOpen(true)}>
                  <i className={"cr-icon-shared-assessment"} />
                  Shared (
                  {
                    (
                      assessmentListResp?.sharedAssessments?.filter(
                        (sa) => !sa.sharedAccessRejected
                      ) ?? []
                    ).length
                  }
                  )
                </Button>
              )}
            {!version?.publishedAt &&
              !loading &&
              (assessmentList.length ?? 0) > 1 && (
                <Button onClick={() => setVersionsOpen(true)}>
                  <i className={"cr-icon-timer"} />
                  Version history
                </Button>
              )}
            {canCreateNewAssessment &&
              selectedStream &&
              !selectedStream.archivedAt &&
              !selectedStream.managedAssessmentID &&
              !loading && (
                <Button
                  primary
                  onClick={startNewAssessment}
                  loading={startingNewDraft}
                >
                  Reassess
                </Button>
              )}
          </>
        }
        infoSection={
          <p>
            Create a risk assessment to get a snapshot of your{" "}
            {vendorWords.possessive} current risk profile. Combine information
            from different sources, review evidence, manage and waive risks, and
            document findings at a point in time for future reference and
            comparison.
          </p>
        }
        infoSectionButtons={
          <Button
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/6504360-using-the-risk-assessment-framework-in-upguard"
              )
            }
          >
            Learn more about risk assessments{" "}
            <div className="cr-icon-arrow-right" />
          </Button>
        }
        infoBarText={
          isLocked && !hasLock
            ? `Risk assessment is being edited by ${version?.editingName}`
            : undefined
        }
        infoBarColour={isEditing ? BannerType.INFO : BannerType.WARNING}
      />
      {analystInfoText && (
        <InfoBanner type={BannerType.INFO} centered message={analystInfoText} />
      )}
      {version && !loading && (
        <VendorAssessmentV3SummaryBar
          onDelete={() => onDeleteVersion(version.id)}
          onEditAssessmentDate={() =>
            openSetAssessmentDateModal({
              confirm: (reassessmentDate) =>
                onChangeReassessmentDate(version.streamID, reassessmentDate),
              newlyPublished: false,
              hasUnfinishedSurveys: false,
              newReassessmentDate: selectedStream?.reassessmentDate,
            })
          }
          status={version.status}
          archived={!!selectedStream?.archivedAt}
          authorAvatar={version.authorAvatar}
          authorEmail={version.authorEmail}
          authorName={version.authorName}
          assessmentDate={selectedStream?.reassessmentDate}
          publishedDate={version.publishedAt}
          onOpenVersionHistory={
            (assessmentList.length ?? 0) > 0
              ? () => setVersionsOpen(true)
              : undefined
          }
          isOldVersion={isOldVersion}
          vendorName={props.vendorName}
          sharedByOrg={(
            assessmentListResp?.sharedAssessments?.filter(
              (sa) => !sa.sharedAccessRejected
            ) ?? []
          ).find((o) => o.organisationID == viewingSharedOrgId)}
          isEditable={
            props.userHasWriteAssessmentPermission &&
            analystMode !== VendorAssessmentAnalystMode.Closed &&
            (version.status === VendorAssessmentStatus.Published ||
              (version.status === VendorAssessmentStatus.Draft &&
                isLocked &&
                hasLock))
          }
          isManaged={!!version.managedAssessmentId}
        />
      )}
      {isEditing && (
        <>
          <Steps steps={steps} currentStep={currentStep} />
          <div className={"intro-section"}>
            <div className={"intro-text"}>{introTexts[currentStep - 1]}</div>
            {version?.canAutofill && currentStep == 3 && isEditing && (
              <div className={"autofill-section"}>
                {/*APAC-3592 TODO: implement undo autofill button*/}
                {/*<Button*/}
                {/*  loading={loading}*/}
                {/*  disabled={!version?.autofilled}*/}
                {/*  onClick={() => {}}*/}
                {/*  tertiary*/}
                {/*>*/}
                {/*  <i className={"cr-icon-undo"} />*/}
                {/*  Undo autofill*/}
                {/*</Button>*/}

                <Button
                  disabled={
                    !!executingAutofillJobID ||
                    assessmentAutofillRunningStatusLoading ||
                    assessmentAutofillJobLoading
                  }
                  className={classnames(
                    "autofill",
                    !!executingAutofillJobID ? "running" : undefined
                  )}
                  onClick={() => {
                    if (managedVendorRequest) {
                      openAssessmentAutofillModal({
                        requestDetails: { ...managedVendorRequest.result },
                        requestOrgId: version.organisationID,
                        onStartAutofill: onStartAutofill,
                      });
                    }
                  }}
                >
                  <i className={"cr-icon-magic-wand"} />
                  {!!executingAutofillJobID
                    ? "Autofill is running"
                    : "Run autofill"}
                </Button>
                {!!executingAutofillJobID && (
                  <ProgressBar
                    progress={assessmentAutofillJobData?.autofillProgress ?? 0}
                    className={"autofill-progress"}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
      {!props.watching ? (
        <UnwatchedVendorCard
          vendorId={props.vendorId}
          vendorName={props.vendorName}
          vendorPrimaryHostname={props.vendorPrimaryHostname}
          text={`This ${vendorWords.singular} must be monitored in order to conduct risk assessments.`}
        />
      ) : loading ? (
        <LoadingBanner />
      ) : evidence && analystMode !== VendorAssessmentAnalystMode.Closed ? (
        <>
          {!isEditing && version && !version.canAutofill && (
            <VendorAssessmentV3AssessmentCard
              risksReadOnly
              assessment={version}
              evidence={evidence}
              mergeTags={mergeTags ?? []}
              readonly
              assessmentCopy={assessmentCopy}
              goToWaivers={goToWaivers}
              goToSurvey={goToSurvey}
              goToEvidencePage={goToEvidencePage}
              goToAdditionalEvidence={goToAdditionalEvidence}
              urlPrefix={urlPrefix}
              managedOrgId={props.managedOrgId}
              includeRiskWaivers={version.includeWaivers}
              onRequestRemediationForRisk={onRequestRemediationForRisk}
              onCreateVendorRiskWaiverForRisk={onCreateVendorRiskWaiverForRisk}
              onOpenCloudscanPanel={onOpenCloudscanPanel}
              numCurrentActiveRiskWaivers={evidence.numActiveRiskWaivers}
              numUnapprovedRiskWaivers={evidence.numUnapprovedRiskWaivers}
              numPendingPublicRiskWaivers={evidence.numPendingPublicRiskWaivers}
              orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
              remediationRequest={remediationRequest}
              goToRemediationRequest={goToRemediationRequest}
              analystMode={analystMode}
              onEditText={doUpdateCopy}
              onSaveCopy={saveCopy}
              orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
              keyRisks={vendorAssessmentRisks?.keyRisks}
              scoringAndRisksCalcComplete={
                scoringAndRiskCalcStatus?.status == "Complete"
              }
              onOpenDomainsModal={() => openDomainsModal(true)}
              onSelectRisk={onSelectRisk}
              onSelectedRiskParamsChange={onSelectedRiskParamsChange}
              onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
              onOpenCloudscanPanelFromRiskPanel={
                onOpenCloudscanPanelFromRiskPanel
              }
              onClickAssetInVulnPanel={onClickAssetInVulnPanel}
            />
          )}
          {!isEditing && version && version.canAutofill && (
            <VendorAssessmentAutofillAssessmentCard
              assessment={version}
              evidence={evidence}
              vendorName={props.vendorName ?? ""}
              mergeTags={mergeTags ?? []}
              readonly
              goToWaivers={goToWaivers}
              goToSurvey={goToSurvey}
              goToEvidencePage={goToEvidencePage}
              goToAdditionalEvidence={goToAdditionalEvidence}
              urlPrefix={urlPrefix}
              managedOrgId={props.managedOrgId}
              includeRiskWaivers={version.includeWaivers}
              onOpenCloudscanPanel={onOpenCloudscanPanel}
              numCurrentActiveRiskWaivers={evidence.numActiveRiskWaivers}
              numUnapprovedRiskWaivers={evidence.numUnapprovedRiskWaivers}
              numPendingPublicRiskWaivers={evidence.numPendingPublicRiskWaivers}
              orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
              remediationRequest={remediationRequest}
              goToRemediationRequest={goToRemediationRequest}
              analystMode={analystMode}
              onSaveCopy={saveCopy}
              orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
              onToggleHideSection={toggleHideSection}
              keyRisks={vendorAssessmentRisks?.keyRisks}
              scoringAndRisksCalcComplete={
                scoringAndRiskCalcStatus?.status == "Complete"
              }
              onOpenDomainsModal={() => openDomainsModal(true)}
              narrativeSections={narrativeSections}
              risks={vendorAssessmentRisks?.risks}
              waivers={vendorAssessmentRisks?.riskWaivers}
              assessmentScores={scoringAndRiskCalcStatus?.scores}
              onSelectRisk={onSelectRisk}
              onSelectedRiskParamsChange={onSelectedRiskParamsChange}
              onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
              onOpenCloudscanPanelFromRiskPanel={
                onOpenCloudscanPanelFromRiskPanel
              }
              onClickAssetInVulnPanel={onClickAssetInVulnPanel}
            />
          )}
          {isEditing && currentStep == 1 && !viewingSharedOrgId && (
            <VendorAssessmentV3SelectEvidenceStep
              assessment={version}
              evidence={evidence}
              updateAssessment={updateAssessment}
              vendorName={props.vendorName ?? ""}
              vendorId={props.vendorId}
              managedOrgId={props.managedOrgId}
              isManagedAnalystSession={props.isManagementAnalystSession}
              goToRiskProfile={goToRiskProfile}
              goToWaivers={goToWaivers}
              goToSurvey={goToSurvey}
              goToEvidencePage={goToEvidencePage}
              goToAdditionalEvidence={goToAdditionalEvidence}
              goToPages={goToPages}
              orgHasAdditionalEvidenceAccess={
                props.orgHasAdditionalEvidenceAccess
              }
              orgHasSurveyAccess={props.orgHasSurveyAccess}
              orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
              assuranceType={props.assuranceType}
              sharedAssessment={sharedAssessment}
              setRequestAccessModalOpen={setRequestModalOpen}
              setNDAModalOpen={setNdaModalOpen}
              openDomainsModal={() => openDomainsModal(false)}
              calculatingRisks={
                version !== undefined &&
                !version.includeAllHostnames &&
                scoringAndRiskCalcStatus?.status != "Complete"
              }
            />
          )}
          {isEditing && currentStep == 2 && !viewingSharedOrgId && (
            <VendorAssessmentV3RisksCard
              readonly={false}
              urlPrefix={urlPrefix}
              assessmentId={version.id}
              isPublishedAssessment={
                version.status === VendorAssessmentStatus.Published
              }
              vendorId={props.vendorId}
              managedOrgId={props.managedOrgId}
              includeRiskWaivers={version.includeWaivers}
              onRequestRemediationForRisk={onRequestRemediationForRisk}
              onCreateVendorRiskWaiverForRisk={onCreateVendorRiskWaiverForRisk}
              onOpenCloudscanPanel={onOpenCloudscanPanel}
              remediationRequest={remediationRequest}
              goToRemediationRequest={goToRemediationRequest}
              onUpdateComments={onUpdateRiskComments}
              isManagedAssessment={!!props.managedOrgId}
              calculatingRisks={
                version !== undefined &&
                !version.includeAllHostnames &&
                scoringAndRiskCalcStatus?.status != "Complete"
              }
              showRiskClassification={version.canAutofill}
              onSelectRisk={onSelectRisk}
              onSelectedRiskParamsChange={onSelectedRiskParamsChange}
              onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
              onOpenCloudscanPanelFromRiskPanel={
                onOpenCloudscanPanelFromRiskPanel
              }
              onClickAssetInVulnPanel={onClickAssetInVulnPanel}
            />
          )}
          {isEditing &&
            currentStep == 3 &&
            !viewingSharedOrgId &&
            (version.canAutofill ? (
              <VendorAssessmentAutofillAssessmentCard
                assessment={version}
                evidence={evidence}
                vendorName={props.vendorName ?? ""}
                readonly={false}
                mergeTags={mergeTags ?? []}
                goToWaivers={goToWaivers}
                goToSurvey={goToSurvey}
                goToEvidencePage={goToEvidencePage}
                goToAdditionalEvidence={goToAdditionalEvidence}
                urlPrefix={urlPrefix}
                onSaveCopy={saveCopy}
                goToTemplates={props.userIsAdmin ? goToTemplates : undefined}
                managedOrgId={props.managedOrgId}
                includeRiskWaivers={version.includeWaivers}
                onOpenCloudscanPanel={onOpenCloudscanPanel}
                numCurrentActiveRiskWaivers={evidence.numActiveRiskWaivers}
                numUnapprovedRiskWaivers={evidence.numUnapprovedRiskWaivers}
                numPendingPublicRiskWaivers={
                  evidence.numPendingPublicRiskWaivers
                }
                orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
                remediationRequest={remediationRequest}
                goToRemediationRequest={goToRemediationRequest}
                analystMode={analystMode}
                orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
                loading={mergeTagsLoading}
                onToggleKeyRisk={toggleKeyRisk}
                keyRisks={vendorAssessmentRisks?.keyRisks}
                onToggleHideSection={toggleHideSection}
                scoringAndRisksCalcComplete={
                  scoringAndRiskCalcStatus?.status == "Complete" &&
                  !scoreJobLoading &&
                  !mergeTagsLoading &&
                  !scoreAndRiskCalcStatusLoading
                }
                onOpenDomainsModal={() => openDomainsModal(true)}
                narrativeSections={narrativeSections}
                risks={vendorAssessmentRisks?.risks}
                waivers={vendorAssessmentRisks?.riskWaivers}
                autofillRunning={!!executingAutofillJobID}
                assessmentScores={scoringAndRiskCalcStatus?.scores}
                onSelectRisk={onSelectRisk}
                onSelectedRiskParamsChange={onSelectedRiskParamsChange}
                onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
                onOpenCloudscanPanelFromRiskPanel={
                  onOpenCloudscanPanelFromRiskPanel
                }
                onClickAssetInVulnPanel={onClickAssetInVulnPanel}
              />
            ) : (
              <VendorAssessmentV3AssessmentCard
                assessment={version}
                evidence={evidence}
                readonly={false}
                risksReadOnly={true}
                mergeTags={mergeTags ?? []}
                goToWaivers={goToWaivers}
                goToSurvey={goToSurvey}
                goToEvidencePage={goToEvidencePage}
                goToAdditionalEvidence={goToAdditionalEvidence}
                onEditText={doUpdateCopy}
                assessmentCopy={assessmentCopy}
                urlPrefix={urlPrefix}
                onSaveCopy={saveCopy}
                goToTemplates={props.userIsAdmin ? goToTemplates : undefined}
                managedOrgId={props.managedOrgId}
                includeRiskWaivers={version.includeWaivers}
                onRequestRemediationForRisk={onRequestRemediationForRisk}
                onCreateVendorRiskWaiverForRisk={
                  onCreateVendorRiskWaiverForRisk
                }
                onOpenCloudscanPanel={onOpenCloudscanPanel}
                numCurrentActiveRiskWaivers={evidence.numActiveRiskWaivers}
                numUnapprovedRiskWaivers={evidence.numUnapprovedRiskWaivers}
                numPendingPublicRiskWaivers={
                  evidence.numPendingPublicRiskWaivers
                }
                orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
                remediationRequest={remediationRequest}
                goToRemediationRequest={goToRemediationRequest}
                analystMode={analystMode}
                orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
                loading={mergeTagsLoading}
                onToggleKeyRisk={toggleKeyRisk}
                keyRisks={vendorAssessmentRisks?.keyRisks}
                onToggleHideSection={toggleHideSection}
                scoringAndRisksCalcComplete={
                  scoringAndRiskCalcStatus?.status == "Complete" &&
                  !scoreJobLoading &&
                  !mergeTagsLoading &&
                  !scoreAndRiskCalcStatusLoading
                }
                onOpenDomainsModal={() => openDomainsModal(true)}
                onSelectRisk={onSelectRisk}
                onSelectedRiskParamsChange={onSelectedRiskParamsChange}
                onOpenCVEPanelFromRiskPanel={onOpenCVEPanelFromRiskPanel}
                onOpenCloudscanPanelFromRiskPanel={
                  onOpenCloudscanPanelFromRiskPanel
                }
                onClickAssetInVulnPanel={onClickAssetInVulnPanel}
              />
            ))}
        </>
      ) : undefined}
      {(isEditing || analystMode === VendorAssessmentAnalystMode.Open) && (
        <ActionBar className={"vendor-assessment-bar"} active newStyles>
          <div className={"left-content"}>
            {isEditing && currentStep != 1 && (
              <Button
                leftArrow
                onClick={() => setCurrentStep((val) => val - 1)}
              >
                Previous
              </Button>
            )}
          </div>
          <div className={"saving-section"}>
            {numSaving > 0 ? (
              <>
                Saving <LoadingIcon size={12} />
              </>
            ) : (
              <>
                Last saved at {formatTimeAsLocal(moment(lastSavedAt))}
                <i className={"cr-icon-check"} />
              </>
            )}
          </div>
          <div className={"right-content"}>
            {isEditing &&
              currentStep == 3 &&
              !viewingSharedOrgId &&
              downloadDraftReportButton}
            {props.isManagementAnalystSession && (
              <>
                {!!version?.managedAssessmentId ? (
                  <Button
                    onClick={openSetWithCustomer}
                    disabled={
                      !!executingAutofillJobID ||
                      assessmentAutofillRunningStatusLoading ||
                      assessmentAutofillJobLoading
                    }
                  >
                    <i
                      className={
                        version.withCustomer
                          ? "cr-icon-cancel"
                          : "cr-icon-check"
                      }
                    />
                    {version.withCustomer
                      ? "Unshare with customer"
                      : "Share with customer"}
                  </Button>
                ) : (
                  unlinkedManagedAssessmentId && (
                    <Button onClick={openAssignToManagedAssessment}>
                      Link to managed service
                    </Button>
                  )
                )}
              </>
            )}
            {!hasSomeEvidence ? (
              <SidePopupV2
                text={
                  "At least one evidence type must be included to perform the assessment."
                }
                position={"top"}
              >
                {nextButton}
              </SidePopupV2>
            ) : (
              nextButton
            )}
          </div>
        </ActionBar>
      )}
      <VendorAssessmentsV3VersionsSideBar
        assessmentList={assessmentList ?? []}
        onOpenVersion={(_streamId, versionId) => onSelectVersion(versionId)}
        active={versionsOpen}
        onClose={() => setVersionsOpen(false)}
        onDeleteVersion={
          props.userHasWritePermission && !version?.managedAssessmentId
            ? onDeleteVersion
            : undefined
        }
        selectedVersion={selectedVersionID}
      />
      <VendorAssessmentV2SharedAssessmentSideBar
        relatedOrgAssessments={assessmentListResp?.sharedAssessments ?? []}
        onSelect={onSelectSharedAssessment}
        active={sharedAssessmentsOpen}
        onClose={() => setSharedAssessmentsOpen(false)}
      />
      <DomainsAndIPsPanelGroup
        history={props.history}
        location={props.location}
        isSubsidiary={false}
        vendorId={props.vendorId}
        isVendorPortal={false}
        riskPanel={selectedRiskParams?.riskPanel}
        getVulnPanel={selectedRiskParams?.getVulnPanel}
      />
      {confirmationModal}
      {relatedOrgAccessModal}
      {setAssessmentDateModal}
      {riskWaiverModal}
      {requestRemediationModal}
      {domainsModal}
      {renameAssessmentModal}
      {assessmentAutofillModal}
      {sharedAssessment && sharedAssessment.linkedVendor && (
        <>
          <RequestVerifiedVendorAccessModal
            vendorId={sharedAssessment.linkedVendor.id}
            vendorName={sharedAssessment.linkedVendor.name}
            dispatch={dispatch}
            active={requestModalOpen}
            onClose={() => {
              setRequestModalOpen(false);
            }}
            onRequested={() => {
              setRequestModalOpen(false);
            }}
          />
          <NdaSigningModal
            vendorName={sharedAssessment.linkedVendor.name}
            vendorId={sharedAssessment.linkedVendor.id}
            ndaId={sharedAssessment.ndaId}
            ndaText={sharedAssessment.ndaText ?? ""}
            dispatch={dispatch}
            active={ndaModalOpen}
            onClose={() => setNdaModalOpen(false)}
            onAccepted={(r) => {
              setSignedNdaModalOpen(true);
              setNdaModalOpen(false);
              setSignedNda(r.signedNda);
            }}
          />
          <NdaSignedModal
            dispatch={dispatch}
            vendorId={sharedAssessment.linkedVendor.id}
            vendorName={sharedAssessment.linkedVendor.name}
            userEmailAddress={props.currentUserEmail}
            mustRequestAccess={
              sharedAssessment.orgLevelStatus ===
                SharedAssessmentAccessResult.MustRequest ||
              sharedAssessment.orgLevelStatus ===
                SharedAssessmentAccessResult.PendingRequest
            }
            signedNdaId={signedNda?.id ?? 0}
            active={signedNdaModalOpen}
            onClose={() => setSignedNdaModalOpen(false)}
          />
        </>
      )}
    </div>
  );
};

export default wrapInVendorOverlay(
  appConnect<
    VendorAssessmentV3ConnectedProps,
    never,
    VendorAssessmentV3OwnProps
  >((state, props) => {
    const data = getVendorDataFromState(
      state.cyberRisk,
      props.vendorId,
      props.managedOrgId
    );

    const { userData } = state.common;
    let currentOrgIsInAccountGroup = false;
    if (userData.orgList && userData.currentOrgID > 0) {
      for (let i = 0; i < userData.orgList.length; i++) {
        if (userData.orgList[i].id === userData.currentOrgID) {
          currentOrgIsInAccountGroup =
            !!userData.orgList[i].organisationGroupId;
          break;
        }
      }
    }

    const isVendor = !!props.vendorId && !props.isSubsidiary;
    const userPerms = getUserPermissionsForVendorFromState(
      state,
      isVendor ? props.vendorId : 0
    );

    // Get org access to permissions used in assessments
    let orgPerms;
    if (props.isManagementAnalystSession && props.managedOrgId) {
      const managedOrgEntitlements = state.cyberRisk.managedVendorData[
        props.managedOrgId as number
      ]
        ? state.cyberRisk.managedVendorData[props.managedOrgId as number]
            .entitlements
        : [];
      orgPerms = managedOrgEntitlements
        ? managedOrgEntitlements.reduce(
            (prev: Record<string, true | undefined>, perm: string) => {
              prev[perm] = true;
              return prev;
            },
            {}
          )
        : [];
    } else {
      orgPerms = state.common.userData.orgPermissions
        ? state.common.userData.orgPermissions.reduce(
            (prev: Record<string, true | undefined>, perm) => {
              prev[perm] = true;
              return prev;
            },
            {}
          )
        : [];
    }

    const orgHasAdditionalEvidencePermission =
      orgPerms[OrgAccessAdditionalEvidence] === true;

    const orgHasSurveyPermission = orgPerms[OrgAccessSurveys] === true;

    const orgHasCustomTemplatesPermission =
      orgPerms[OrgAccessDefaultTexts] === true;

    const orgHasSurveyScoresEnabled = orgPerms[OrgQuestionnaireScores] === true;

    const p: VendorAssessmentV3ConnectedProps = {
      userHasWritePermission:
        !!userPerms[UserVendorRiskWrite] || !!props.isManagementAnalystSession,
      userHasWriteAssessmentPermission:
        !!userPerms[UserWriteVendorAssessments] ||
        !!props.isManagementAnalystSession,
      userIsAdmin: userData.userPermissions.includes(UserWriteOwnOrganisation),
      currentUserEmail: userData.emailAddress,
      assuranceType: userData.assuranceType,
      currentOrgIsInAccountGroup,
      orgHasAdditionalEvidenceAccess: orgHasAdditionalEvidencePermission,
      orgHasSurveyAccess: orgHasSurveyPermission,
      orgHasCustomTemplatesAccess: orgHasCustomTemplatesPermission,
      orgHasSurveyScoresEnabled: orgHasSurveyScoresEnabled,
      remediationRequests: state.common?.remediationRequests,
      verifiedVendor: data?.verified,
      tier: data?.summary?.result?.vendorTier,
      labels: data?.summary?.result?.labels,
      portfolios: data?.summary?.result?.vendorPortfolios,
    };
    return p;
  })(VendorAssessmentV3)
);
