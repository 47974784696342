import { FC, useState, useEffect, useMemo } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import ColorCheckbox from "../ColorCheckbox";
import {
  DisplayableManagedVendorAssessment,
  ManagedVendorEvidenceSummary,
} from "../../../_common/types/thirdPartyMangedVendors";
import XTable, {
  ISortedBy,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { HoverColor } from "../../../_common/components/IconButton";
import { GetIconForFilename } from "../../helpers/icons";
import { formatDateAsLocal } from "../../../_common/helpers";
import { getAuthFromLocalStorage } from "../../../_common/reducers/commonActions";
import { ChildCheckboxPropType, NestedCheckbox } from "../NestedCheckbox";
import "../../style/components/vendor_assessment/VendorAssessmentAutofillModal.scss";
import {
  RiskClassification,
  RiskClassificationString,
} from "../../../_common/types/risk/classification";

export type VendorAssessmentAutofillSections = {
  executiveSummary: boolean;
  vendorBackground: boolean;
  assessmentSummary: RiskClassification[];
};

interface VendorAssessmentAutofillModalProps extends BaseModalProps {
  requestDetails: DisplayableManagedVendorAssessment;
  requestOrgId: number;
  onStartAutofill: (
    selectedSections: VendorAssessmentAutofillSections,
    selectedEvidence: number[]
  ) => void;
}

// VendorAssessmentAutofillFromEvidenceModal - select sections and source documents for vendor assessment autofill
// TODO: Lots of this code is copied from QuestionnaireAutofillFromEvidenceModal - refactor to share document selection code
const VendorAssessmentAutofillModal: FC<VendorAssessmentAutofillModalProps> = (
  props
) => {
  // selectedEvidence
  // store evidence selections to be used during autofill.
  const [selectedEvidence, setSelectedEvidence] = useState(new Map());

  const availableClassifications = [
    RiskClassification.SecurityPoliciesAndProcesses,
    RiskClassification.AssetManagement,
    RiskClassification.InfrastructureManagement,
    RiskClassification.DataProtection,
    RiskClassification.ApplicationSecurity,
    RiskClassification.RiskManagement,
    RiskClassification.OperationalResilience,
  ];

  const [selectedSections, setSelectedSections] =
    useState<VendorAssessmentAutofillSections>({
      executiveSummary: true,
      vendorBackground: true,
      assessmentSummary: [...availableClassifications],
    });

  // getAssessmentSummarySectionCheckboxes -
  const getAssessmentSummarySectionCheckboxes = () => {
    const result: ChildCheckboxPropType[] = [];
    availableClassifications.forEach((classification) => {
      result.push({
        id: classification,
        title: RiskClassificationString(classification, "", true),
        value: selectedSections.assessmentSummary.includes(classification),
        disabled: false,
      });
    });

    return result;
  };

  // sortBy
  // evidence table sort parameters
  const [sortBy, setSortBy] = useState<ISortedBy>({
    columnId: "uploaded",
    direction: SortDirection.DESC,
  });

  const selectEvidence = (id: number, isSelected: boolean) => {
    const s = new Map([...selectedEvidence, [id, isSelected]]);
    setSelectedEvidence(s);
  };
  const toggleEvidenceSelection = (id: number) => () => {
    const selected = selectedEvidence.has(id) && selectedEvidence.get(id);
    selectEvidence(id, !selected);
  };
  const selectAll = (selected: boolean) => {
    if (props.requestDetails?.additionalEvidence) {
      const s = new Map([...selectedEvidence]);

      props.requestDetails.additionalEvidence.forEach((v) => {
        const ext = (v.filename.split(".").pop() || "").toLowerCase();
        if (ext === "pdf") {
          s.set(v.id, selected);
        }
      });

      setSelectedEvidence(s);
    }
  };

  const sectionsSelected = () => {
    return (
      selectedSections.executiveSummary ||
      selectedSections.vendorBackground ||
      selectedSections.assessmentSummary?.length > 0
    );
  };

  useEffect(() => {
    // Default our evidence selection to be all pdf files. Note that at the moment only pdf evidence types can be selected for autofill.
    const s = new Map([...selectedEvidence]);
    if (props.requestDetails?.additionalEvidence) {
      props.requestDetails.additionalEvidence.forEach((v) => {
        const ext = (v.filename.split(".").pop() || "").toLowerCase();
        if (ext === "pdf") {
          s.set(v.id, true);
        }
      });
    }
    setSelectedEvidence(s);
  }, []);

  // TODO: this needs to be tested for public evidence that has been selected from the vendor document repository
  const openAdditionalEvidence = (evidence: ManagedVendorEvidenceSummary) => {
    const auth = getAuthFromLocalStorage({ tpvm_o: props.requestOrgId });
    let url: string;
    if (!evidence.isPublic) {
      url = `/api/vendor/evidence/download/v1/?apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}&document_id=${
        evidence.documentId
      }`;
    } else {
      url = `/api/sharedassessment/documents/v1/?apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}&vendor_id=${encodeURIComponent(
        props.requestDetails.vendorID
      )}&uuid=${encodeURIComponent(evidence.documentUUID)}`;
    }
    window.open(url, "_blank");
  };

  const sortedEvidence = useMemo(() => {
    let evidence: ManagedVendorEvidenceSummary[] = [];
    if (props.requestDetails?.additionalEvidence?.length > 0) {
      evidence = [...props.requestDetails?.additionalEvidence];
    }
    evidence.sort((v1, v2) => {
      switch (sortBy.columnId) {
        case "document":
          if (v1.name < v2.name) return -1;
          if (v1.name > v2.name) return 1;
          return 0;
        case "risks":
          if (v1.numRisks < v2.numRisks) return -1;
          if (v1.numRisks > v2.numRisks) return 1;
          return 0;
        case "uploaded":
          if (v1.uploadedAt < v2.uploadedAt) return -1;
          if (v1.uploadedAt > v2.uploadedAt) return 1;
          return 0;
      }
      return 0;
    });
    if (sortBy.direction === SortDirection.DESC) {
      evidence.reverse();
    }
    return evidence;
  }, [props.requestDetails, sortBy]);

  return (
    <ModalV2
      className={"vendor-assessment-autofill-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={"Run autofill on risk assessment"}
      headerClassName={"vendor-assessment-autofill-modal-header"}
      subHeaderContent={
        "Use autofill to populate your risk assessment. It runs in the background and can take up to 10 minutes."
      }
      footerContent={
        <div className={"left-content"}>
          <Button
            tertiary
            onClick={() => {
              props.onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            disabled={!sectionsSelected()}
            onClick={() => {
              const evidence: number[] = [];
              selectedEvidence.forEach((_, key) => {
                evidence.push(key);
              });

              props.onStartAutofill(selectedSections, [
                ...selectedEvidence.keys(),
              ]);
              props.onClose();
            }}
            arrow
          >
            Run autofill
          </Button>
        </div>
      }
    >
      <div className={"autofill-scope"}>
        <div className={"heading"}>Define scope</div>
        <div className={"options"}>
          <ColorCheckbox
            key={"scope-executive-summary"}
            label={"Executive summary"}
            checked={selectedSections.executiveSummary}
            onClick={() => {
              setSelectedSections((s) => ({
                ...s,
                executiveSummary: !s.executiveSummary,
              }));
            }}
          />
          <ColorCheckbox
            key={"scope-vendor-background"}
            label={"Vendor background"}
            checked={selectedSections.vendorBackground}
            onClick={() => {
              setSelectedSections((s) => ({
                ...s,
                vendorBackground: !s.vendorBackground,
              }));
            }}
          />
          <NestedCheckbox
            checkbox={{
              id: "scope-assessment-summary",
              title: "Assessment summary",
              value: selectedSections.assessmentSummary.length > 0,
              disabled: false,
              children: getAssessmentSummarySectionCheckboxes(),
            }}
            onClick={(_id, classificationID) => {
              if (classificationID) {
                setSelectedSections((s) => ({
                  ...s,
                  assessmentSummary: s.assessmentSummary.includes(
                    classificationID as RiskClassification
                  )
                    ? s.assessmentSummary.filter((v) => v !== classificationID)
                    : [
                        ...s.assessmentSummary,
                        classificationID as RiskClassification,
                      ],
                }));
              } else {
                // Toggle all classifications
                setSelectedSections((s) => ({
                  ...s,
                  assessmentSummary:
                    s.assessmentSummary.length > 0
                      ? []
                      : [...availableClassifications],
                }));
              }
            }}
          />
        </div>
      </div>
      <div className={"selectable-evidence"}>
        <div className={"heading"}>Select source documents</div>
        <div className={"table"}>
          <XTable
            emptyContent={
              <p>
                Optionally include some evidence documents on the managed
                request to help autofill this assessment.
              </p>
            }
            hideColumnHeaders={
              (props.requestDetails?.additionalEvidence?.length ?? 0) == 0
            }
            stickyColumnHeaders
            iconOptions
            selectable
            sortedBy={sortBy}
            onSortChange={(columnId: string, direction: SortDirection) =>
              setSortBy({
                columnId,
                direction,
              })
            }
            onSelectClick={(id, selected) => {
              selectEvidence(id, selected);
            }}
            onSelectAllClick={() => selectAll(true)}
            onSelectNoneClick={() => selectAll(false)}
            columnHeaders={[
              {
                id: "document",
                text: "Document",
                sortable: true,
              },
              {
                id: "risks",
                text: "Risks",
                sortable: true,
              },
              {
                id: "uploaded",
                text: "Uploaded",
                sortable: true,
              },
            ]}
            rows={sortedEvidence.map((i) => ({
              id: i.id,
              selectionDisabledHelpText:
                "Currently only PDF files can be used for autofill",
              selectionDisabled:
                (i.filename.split(".").pop() || "").toLowerCase() !== "pdf",
              selected:
                selectedEvidence.has(i.id) && selectedEvidence.get(i.id),
              onClick: toggleEvidenceSelection(i.id),
              iconOptions: [
                {
                  id: "view",
                  icon: <span className="cr-icon-external-link" />,
                  onClick: () => {
                    openAdditionalEvidence(i);
                  },
                  hoverText: "View",
                  hoverColor: HoverColor.Blue,
                },
              ],
              cells: [
                <XTableCell key="name" className={"name-cell"}>
                  <div className={"name"}>
                    <img
                      className={"icon"}
                      alt="File type icon"
                      src={GetIconForFilename(i.filename)}
                    />
                    <div className={"label"}>{i.name}</div>
                  </div>
                </XTableCell>,
                <XTableCell key="risks" className="risks-cell">
                  {i.numRisks}
                </XTableCell>,
                <XTableCell key="uploaded" className={"uploaded-cell"}>
                  {formatDateAsLocal(i.uploadedAt)}
                </XTableCell>,
              ],
            }))}
            className={"evidence-table"}
          />
        </div>
      </div>
    </ModalV2>
  );
};

export default VendorAssessmentAutofillModal;
