import { FC, useEffect, useState } from "react";

import classnames from "classnames";

import "./style/FreeTrialRegister.scss";
import { appendParams } from "../vendorrisk/helpers/util";
interface HubspotMeetingProps {
  calendarId: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  onMeetingBooked: () => void;
}

export const HubspotMeeting: FC<HubspotMeetingProps> = ({
  calendarId,
  emailAddress,
  firstName,
  lastName,
  onMeetingBooked,
}) => {
  const [meetingBooked, setMeetingBooked] = useState(false);

  useEffect(() => {
    loadScript(
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js",
      document.head
    );

    const hubspotListenerFunc: (this: Window, ev: MessageEvent<any>) => any = (
      event
    ) => {
      if (
        event.origin == "https://meetings.hubspot.com" &&
        event.data &&
        event.data.meetingBookSucceeded
      ) {
        setMeetingBooked(true);
        onMeetingBooked();
      }
      return true;
    };

    // Add a listener to receive the meeting booking event from the HubSpot calendar
    window.addEventListener("message", hubspotListenerFunc);

    return () => {
      window.removeEventListener("message", hubspotListenerFunc);
    };
  }, []);

  const loadScript = function (src: string, target: any) {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    target.appendChild(tag);
  };

  const src = appendParams(`https://meetings.hubspot.com/${calendarId}`, {
    embed: "true",
    firstName: firstName,
    lastName: lastName,
    email: emailAddress,
  });

  return (
    <div
      className={classnames("meetings-iframe-container", {
        completed: meetingBooked,
      })}
      data-src={src}
    ></div>
  );
};
