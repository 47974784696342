import React, { useEffect } from "react";
import ColorCheckbox from "./ColorCheckbox";
import { RemediationRequestCounts } from "../../_common/types/remediation";
import LoadingBanner from "../../_common/components/core/LoadingBanner";

export interface IRemediationExportOptions {
  include_in_progress: boolean;
  include_awaiting_review: boolean;
  include_completed: boolean;
  include_archived: boolean;
  include_risk_details: boolean;
  include_asset_details: boolean;
}

export const defaultRemediationExportCustomFilter =
  (): IRemediationExportOptions => ({
    include_in_progress: true,
    include_awaiting_review: true,
    include_completed: true,
    include_archived: false,
    include_risk_details: false,
    include_asset_details: false,
  });

interface IRemediationOptionsProps {
  customFilter: IRemediationExportOptions;
  updateCustomFilter: (filter: IRemediationExportOptions) => void;
  counts?: RemediationRequestCounts;
  isSubsidiary?: boolean;
  isUserRisk?: boolean;
}

const RemediationExportOptions: React.VFC<IRemediationOptionsProps> = ({
  customFilter,
  updateCustomFilter,
  counts,
  isSubsidiary = false,
  isUserRisk = false,
}: IRemediationOptionsProps) => {
  useEffect(() => {
    // once we have counts unset any options where there are none
    if (!!counts) {
      updateCustomFilter({
        ...customFilter,
        include_in_progress:
          counts.inProgress > 0 ? customFilter.include_in_progress : false,
        include_awaiting_review:
          counts.awaitingReview > 0
            ? customFilter.include_awaiting_review
            : false,
        include_completed:
          counts.completed > 0 ? customFilter.include_completed : false,
        include_archived:
          counts.archived > 0 ? customFilter.include_archived : false,
      });
    }
  }, [counts]);

  return (
    <>
      <div className={"left grid-section"}>
        <h3>Select remediation requests to export</h3>
        <p>What remediation requests do you want to export?</p>
      </div>
      <div className={"right grid-section"}>
        {counts ? (
          <>
            <ColorCheckbox
              checked={customFilter.include_in_progress}
              onClick={() =>
                updateCustomFilter({
                  ...customFilter,
                  include_in_progress: !customFilter.include_in_progress,
                })
              }
              label={"In Progress"}
            />
            <ColorCheckbox
              checked={customFilter.include_awaiting_review}
              onClick={() =>
                updateCustomFilter({
                  ...customFilter,
                  include_awaiting_review:
                    !customFilter.include_awaiting_review,
                })
              }
              label={"Awaiting Review"}
            />
            <ColorCheckbox
              checked={customFilter.include_completed}
              onClick={() =>
                updateCustomFilter({
                  ...customFilter,
                  include_completed: !customFilter.include_completed,
                })
              }
              label={"Completed"}
            />
            <ColorCheckbox
              checked={customFilter.include_archived}
              onClick={() =>
                updateCustomFilter({
                  ...customFilter,
                  include_archived: !customFilter.include_archived,
                })
              }
              label={"Archived"}
            />
          </>
        ) : (
          <LoadingBanner tight />
        )}
      </div>
      <div className={"left grid-section"}>
        <h3>Select information to include in export</h3>
        <p>What additional information do you want to export?</p>
      </div>
      <div className={"right grid-section"}>
        <ColorCheckbox
          checked={customFilter.include_risk_details}
          onClick={() =>
            updateCustomFilter({
              ...customFilter,
              include_risk_details: !customFilter.include_risk_details,
            })
          }
          label={"Risk details"}
        />
        <ColorCheckbox
          checked={
            customFilter.include_asset_details &&
            customFilter.include_risk_details
          }
          disabled={!customFilter.include_risk_details}
          helpPopup={
            !customFilter.include_risk_details
              ? `Risk details need to be included to include associated ${
                  isUserRisk ? "users" : "assets"
                }`
              : undefined
          }
          onClick={() =>
            updateCustomFilter({
              ...customFilter,
              include_asset_details: !customFilter.include_asset_details,
            })
          }
          label={
            isUserRisk
              ? "Associated users"
              : isSubsidiary
                ? "Associated assets (domains)"
                : "Associated assets (domains & questionnaires)"
          }
        />
      </div>
    </>
  );
};

export default RemediationExportOptions;
