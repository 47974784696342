import PageHeader from "../../../_common/components/PageHeader";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useBack } from "../../../_common/types/router";
import { useFilters } from "../../../vendorrisk/reducers/filters.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import SlidePanel from "../../../_common/components/SlidePanel";
import {
  FilterBar,
  FilterLabelContainer,
  FilterPanelContainer,
  FilterTypes,
  isFilterActive,
} from "../../../vendorrisk/components/filter";

import OrgPermissionsTable from "../../components/permissions/OrgPermissionsTable";
import { DashboardStatToShow } from "../../components/stats/DashboardStat";
import TitleStat from "../../components/stats/TitleStat";
import { getClearedFilters } from "../../../vendorrisk/components/filter/FilterPanel";
import { parseUserRiskFilterFromQuery } from "../../helpers/filters";
import { Filters } from "../../../vendorrisk/components/filter/types";
import { setCustomerDataFilters } from "../../../vendorrisk/reducers/cyberRiskActions";

export const supportedFilters = [
  FilterTypes.USERRISK_SCOPE_RISK_LEVEL,
  FilterTypes.USERRISK_SCOPE_READWRITE,
  FilterTypes.USERRISK_SCOPE_CATEGORIES,
];

interface PermissionsViewProps {}

const PermissionsView: FC<PermissionsViewProps> = ({}) => {
  const { backAction, backText } = useBack();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const filters = useFilters();
  const filtersActive = isFilterActive(filters, supportedFilters);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  useEffect(() => {
    const queryParamFilters = {
      ...getClearedFilters(supportedFilters),
      ...parseUserRiskFilterFromQuery(history),
    } as Filters;
    if (isFilterActive(queryParamFilters, supportedFilters)) {
      dispatch(setCustomerDataFilters(queryParamFilters));
    }
  }, []);

  return (
    <div className="userbase-permissions">
      <FilterBar
        dispatch={dispatch}
        isCustomer={true}
        active={filtersActive}
        supportedFilters={supportedFilters}
      />
      <PageHeader
        history={history}
        title={<TitleStat statToShow={DashboardStatToShow.PermissionCount} />}
        backAction={backAction}
        backText={backText}
        infoSectionPageKey="infoSection_userBasePermissions"
        infoSection={
          <>
            <p>
              Permissions shows all the SaaS permissions granted by users for
              your organisation
            </p>
          </>
        }
        rightSection={
          <>
            <FilterLabelContainer
              onClick={() => setFilterPanelOpen(!filterPanelOpen)}
              supportedFilters={supportedFilters}
              isCustomer={true}
              disabled={false} // TODO
            />
          </>
        }
      />

      <OrgPermissionsTable />
      <SlidePanel
        active={filterPanelOpen}
        title="Filter by"
        newStyles
        dimContent
        onClose={() => setFilterPanelOpen(false)}
      >
        <FilterPanelContainer
          closePanel={() => setFilterPanelOpen(false)}
          supportedFilters={supportedFilters}
          isCustomer={true}
          startAllExpanded={true}
        />
      </SlidePanel>
    </div>
  );
};

export default PermissionsView;
