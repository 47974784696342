import { Component, Fragment } from "react";

import _, { sortBy as _sortBy } from "lodash";
import {
  getCurrentOrgFromUserData,
  userOrgHasSubsidiaries,
} from "../../../_common/helpers";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import {
  fetchArchivedAnalystReportExports,
  fetchArchivedReportExports,
  openModal,
  setSingleExportItem,
  setSingleExportItemArchived,
} from "../../../_common/reducers/commonActions";
import { getCyberRiskAuth } from "../../../_common/session";
import CircleDocumentSvg from "../../../_common/images/circle_doc_attachment.svg";
import TabButtons, {
  tabButtonsStylingType,
} from "../../../_common/components/TabButtons";
import { ConfirmationModalName } from "../../../_common/components/modals/ConfirmationModal";
import ActionBar from "../../../_common/components/ActionBar";
import SearchBox from "../../../_common/components/SearchBox";
import ReportExportsList, {
  getReportMeta,
  userReportExportModules,
} from "../../components/reporting/ReportExportsList";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import "../../style/views/reporting/ReportExports.scss";
import {
  DisplayableExportSchedule,
  ExportItem,
} from "../../../_common/types/exportReport";
import {
  ISortedBy,
  SortDirection,
} from "../../../_common/components/core/XTable";
import ReportScheduleList from "../../components/reporting/ReportScheduleList";
import { getScheduledExports } from "../../reducers/export.actions";
import { IntervalSortOrder } from "../../../_common/types/asyncSchedule";
import EditReportScheduleModal from "../../../_common/components/modals/EditReportScheduleModal";
import {
  hasOrgPermission,
  hasUserPermission,
  OrgAccessCustomerInfo,
  OrgAccessVendorAssessments,
  OrgAccessVendors,
  UserBreachsightEnabled,
  UserVendorRiskEnabled,
} from "../../../_common/permissions";
import classnames from "classnames";
import ScheduledIcon from "../../../_common/images/scheduled.svg";
import EmptyCardWithAction from "../../../_common/components/EmptyCardWithAction";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { fetchBreachsightSubsidiaries } from "../../reducers/breachsightSubsidiaries.actions";
import PageHeader from "../../../_common/components/PageHeader";
import { History, Location } from "history";
import { ExecutiveReportType } from "../../components/reporting/ReportTypeBadge";
import { ExecutiveReportingMode } from "../../../_common/components/modals/ExecutiveReportingModal";
import PillLabel from "../../components/PillLabel";
import { ILabel, LabelColor } from "../../../_common/types/label";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { locationState } from "../../../_common/types/router";
import { AssuranceType } from "../../../_common/types/organisations";
import { IVendorHierarchy } from "../../../_common/types/vendor";
import ReportsLibrary from "../../components/reporting/ReportsLibrary";
import { appConnect } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import RenameReportModal, {
  ItemIdName,
} from "../../components/modals/RenameReportsModal";
import {
  archiveReportExports,
  deleteReportExports,
  downloadReportExports,
  renameReportExports,
} from "../../reducers/reports.actions";
import { ConfettiFullPage } from "../../../_common/components/ConfettiFullPage";
import { setPLGTaskCompleteIfIncomplete } from "../../reducers/plgOnboardingChecklistActions";

export const userReportExportStatuses = {
  Pending: 0,
  Complete: 1,
  Error: 2,
};

const pageLimit = 10;

interface IReportExportsOwnProps {
  dispatch: DefaultThunkDispatch;
  location: Location<locationState>;
  history: History;
  match: any;
}

interface ISuppliedExportItems {
  data: ExportItem[];
  loading: boolean;
}

interface ISuppliedDisplayableExportSchedule {
  loading: boolean;
  data: DisplayableExportSchedule[];
}

interface IReportExportsConnectedProps {
  exportItems?: ISuppliedExportItems;
  exportItemsArchived?: ISuppliedExportItems;
  scheduledExports: ISuppliedDisplayableExportSchedule;
  orgHasBreachsightEnabled: boolean;
  userHasGlobalBreachsightPermission: boolean;
  userHasPortfolioSpecificBreachsightPermission: boolean;
  orgHasVendorRiskEnabled: boolean;
  userHasGlobalVendorRiskPermission: boolean;
  userHasPortfolioSpecificVendorRiskPermission: boolean;
  vendorName: string;
  vendorPrimaryHostname: string;
  vendorVerified?: boolean;
  currentTab: string;
  userData: any;
  availableLabels: ILabel[];
  assuranceType: AssuranceType;
  orgHasAssessmentsEnabled?: boolean;
  orgHasSubsidiaries: boolean;
  subsidiaries?: IVendorHierarchy;
}

export type IReportExportsProps = IReportExportsOwnProps &
  IReportExportsConnectedProps;

interface IReportExportsV2State {
  moreLoading: boolean;
  sortedBy: ISortedBy;
  selectedIds: number[];
  filterText: string;
  expandedScheduleRows: { [rowId: string]: true | undefined };
  editModalOpen: boolean;
  renameModalOpen: boolean;
  modalSchedule?: DisplayableExportSchedule;
  currentPage: number;
  reportingModalOpen: boolean;
  reportingModalMode: ExecutiveReportingMode | undefined;
  reportingSelectedReportType: ExecutiveReportType | undefined;
  itemsToRename: ItemIdName[];
  fireConfetti: boolean;
}

class ReportExportsV2 extends Component<
  IReportExportsProps,
  IReportExportsV2State
> {
  static defaultProps = {
    exportItems: undefined,
    vendorVerified: false,
    exportItemsArchived: undefined,
    currentTab: "library",
  };

  constructor(props: IReportExportsProps) {
    super(props);

    this.state = {
      moreLoading: false,
      sortedBy: {
        columnId: this.props.currentTab === "scheduled" ? "next" : "requested",
        direction: SortDirection.DESC,
      },
      selectedIds: [],
      filterText: "",
      expandedScheduleRows: {} as { [rowId: string]: true | undefined },
      editModalOpen: false,
      currentPage: 1,
      reportingModalOpen: false,
      reportingModalMode: ExecutiveReportingMode.VendorRiskReporting,
      reportingSelectedReportType: undefined,
      renameModalOpen: false,
      itemsToRename: [],
      fireConfetti: false,
    };

    // Always fetch the archived reports, so we can show the number in the tab bar
    this.fetchArchived();
  }

  componentDidMount() {
    if (
      this.props.currentTab === "scheduled" &&
      this.props.scheduledExports.data.length === 0
    ) {
      this.fetchScheduled();
    }

    // If the archived tab is selected but there are no archived reports rediect to main tab
    if (
      this.props.currentTab === "generated-archived" &&
      !this.props.exportItemsArchived?.loading &&
      this.props.exportItemsArchived?.data.length === 0
    ) {
      this.props.history.replace(this.switchPathToTab("generated"));
    }

    // Set sort dir based on tab
    const { currentTab } = this.props;
    const columnId = currentTab === "scheduled" ? "next" : "requested";
    const direction =
      currentTab === "scheduled" ? SortDirection.ASC : SortDirection.DESC;
    this.setState({
      sortedBy: {
        columnId,
        direction,
      },
    });

    if (!!this.props.userData.plgOnboarding) {
      if (!!this.props.exportItems?.data) {
        for (
          let i = 0;
          i < Math.max(this.props.exportItems?.data.length, 10);
          i++
        ) {
          if (!!this.props.exportItems?.data[i]) {
            const { modules } = getReportMeta(
              this.props.exportItems?.data[i],
              this.props.assuranceType
            );
            if (modules.indexOf(userReportExportModules.vendorRisk) > -1) {
              this.props
                .dispatch(
                  setPLGTaskCompleteIfIncomplete("Checklist_VendorRisk_Report")
                )
                .then((result) => {
                  if (result) {
                    this.setState({ fireConfetti: true });
                  }
                });
            } else if (
              modules.indexOf(userReportExportModules.breachSight) > -1
            ) {
              this.props
                .dispatch(
                  setPLGTaskCompleteIfIncomplete("Checklist_BreachSight_Report")
                )
                .then((result) => {
                  if (result) {
                    this.setState({ fireConfetti: true });
                  }
                });
            }
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps: IReportExportsProps) {
    // If the component has updated, and we are in the archived tab but there are
    // no longer any archived reports, redirect to main tab
    if (
      this.props.currentTab === "generated-archived" &&
      !this.props.exportItemsArchived?.loading &&
      this.props.exportItemsArchived?.data.length === 0
    ) {
      this.props.history.replace(this.switchPathToTab("generated"));
    }

    if (
      this.props.currentTab === "scheduled" &&
      prevProps.currentTab !== "scheduled"
    ) {
      // we just switched to the scheduled tab so make sure we reset the sort by column
      this.setState({
        sortedBy: {
          columnId: "next",
          direction: SortDirection.ASC,
        },
      });
    }
  }

  switchPathToTab = (target: string): string => {
    const currentPath = this.props.history.location.pathname;
    const idx = currentPath.indexOf("/reportexports") + 14;
    const basePath = currentPath.substring(0, idx);

    return (
      basePath + (basePath[basePath.length - 1] != "/" ? "/" : "") + target
    );
  };

  fetchArchived = () => {
    this.props
      .dispatch(
        this.props.userData.currentOrgID
          ? fetchArchivedReportExports()
          : fetchArchivedAnalystReportExports()
      )
      .catch(() => {
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error loading more archived exports")
        );
      });
  };

  fetchSubsidiaryStructure = () => {
    this.props.dispatch(fetchBreachsightSubsidiaries()).catch(() => {
      this.props.dispatch(
        addDefaultUnknownErrorAlert("Error loading organization subsidiaries")
      );
    });
  };

  fetchScheduled = () => {
    if (
      this.props.orgHasSubsidiaries &&
      this.props.userHasGlobalBreachsightPermission
    ) {
      this.fetchSubsidiaryStructure();
    }
    this.props.dispatch(getScheduledExports()).catch(() => {
      this.props.dispatch(
        addDefaultUnknownErrorAlert("Error loading scheduled exports")
      );
    });
  };

  downloadItem = (item: ExportItem, isArchived: boolean) => {
    // Mark this item as read
    if (!isArchived) {
      this.props.dispatch(
        setSingleExportItem({
          ...item,
          read: true,
        })
      );
    } else {
      this.props.dispatch(
        setSingleExportItemArchived({
          ...item,
          read: true,
        })
      );
    }

    // Start the download
    const { apiKey, token } = getCyberRiskAuth();
    window.open(
      `/api/exports/download/v1?id=${item.id}&apikey=${apiKey}&token=${token}`
    );
  };

  archiveItems = (items: ExportItem[], isArchive: boolean) => {
    this.setState({ moreLoading: true });

    this.props.dispatch(archiveReportExports(items, isArchive)).then(() => {
      this.doAfterUpdate(items);
    });
  };

  deleteItems = (items: ExportItem[]) => {
    const thisThese = items.length > 1 ? "these reports" : "this report";

    this.props.dispatch(
      openModal(
        ConfirmationModalName,
        {
          title: `Are you sure you want to delete ${thisThese}?`,
          iconClass: "cr-icon-trash",
          description: `Deleted reports cannot be retrieved.`,
          buttonText: "Yes, delete",
          dangerousAction: true,
          buttonAction: async () => {
            this.setState({ moreLoading: true });

            try {
              this.props.dispatch(deleteReportExports(items)).then(() => {
                this.doAfterUpdate(items);
              });
            } catch (e) {
              this.props.dispatch(
                addDefaultUnknownErrorAlert(
                  "An error occurred deleting the selected report(s)"
                )
              );
              throw e;
            }
          },
        },
        true,
        "report-delete"
      )
    );
  };

  renameItems = (items: ExportItem[]) => {
    this.setState({
      itemsToRename: [{ id: items[0].id, name: items[0].filename }],
      renameModalOpen: true,
    });
  };

  onItemsRenamed = (items: ItemIdName[], isArchivedView: boolean) => {
    if (items && items.length > 0) {
      try {
        this.props
          .dispatch(renameReportExports(items, isArchivedView))
          .then(() => {
            const msg =
              items[0].orig && items[0].orig.length > 0
                ? `"${items[0].orig}" has been renamed to "${items[0].name}"`
                : `Report renamed to "${items[0].name}"`;
            this.props.dispatch(addDefaultSuccessAlert(msg));
          });
      } catch (e) {
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            "An error occurred renaming the selected report"
          )
        );
        throw e;
      } finally {
        this.setState({ renameModalOpen: false });
      }
    }
  };

  downloadItems = (items: ExportItem[], isArchived: boolean) => {
    this.props.dispatch(downloadReportExports(items, isArchived)).then(() => {
      this.setState({ selectedIds: [] });
    });

    // Start the download
    const { apiKey, token } = getCyberRiskAuth();

    const ids = items.map((i) => i.id).join(",");
    window.open(
      `/api/exports/download/v1?id=${ids}&apikey=${apiKey}&token=${token}`
    );
  };

  doAfterUpdate = (itemsUpdated: ExportItem[]) => {
    const itemIdsToRemove = itemsUpdated.map((i: ExportItem) => i.id);

    // Remove items from selection
    this.setState({
      moreLoading: false,
      selectedIds: this.state.selectedIds.filter(
        (i: number) => itemIdsToRemove.indexOf(i) === -1
      ),
    });
  };

  selectClick = (idStringOrNumber: string | number) => {
    const selectedIds = [...this.state.selectedIds];
    let id: number;
    if (typeof idStringOrNumber !== "number") {
      id = parseInt(idStringOrNumber as string, 10);
    } else {
      id = idStringOrNumber as number;
    }
    const idx = selectedIds.indexOf(id);
    if (idx === -1) {
      selectedIds.push(id);
    } else {
      selectedIds.splice(idx, 1);
    }

    this.setState({
      selectedIds: selectedIds,
    });
  };

  selectAll = (select: boolean) => {
    const items =
      this.props.currentTab === "generated-archived"
        ? this.props.exportItemsArchived?.data
        : this.props.exportItems?.data;

    if (items) {
      this.setState({
        selectedIds: select ? items.map((i) => i.id) : [],
      });
    }
  };

  selectAllSchedule = (select: boolean, ids: number[]) => {
    this.setState((prevState) => {
      const selectedIds: number[] = prevState.selectedIds;
      return {
        selectedIds: select
          ? selectedIds.concat(ids)
          : selectedIds.filter((id) => ids.indexOf(id) === -1),
      };
    });
  };

  getSelectedItems = (): ExportItem[] => {
    if (this.props.currentTab === "generated-archived") {
      return (
        this.props.exportItemsArchived?.data.filter(
          (i: ExportItem) => this.state.selectedIds.indexOf(i.id) !== -1
        ) || []
      );
    } else {
      return (
        this.props.exportItems?.data.filter(
          (i: ExportItem) => this.state.selectedIds.indexOf(i.id) !== -1
        ) || []
      );
    }
  };

  setTab = (tab: string) => {
    // Set back to defaults
    const columnId = tab === "scheduled" ? "next" : "requested";
    const direction =
      tab === "scheduled" ? SortDirection.ASC : SortDirection.DESC;
    this.setState({
      selectedIds: [],
      filterText: "",
      sortedBy: {
        columnId,
        direction,
      },
      currentPage: 1,
    });

    this.props.history.replace(this.switchPathToTab(tab));

    // Load initial archived exports if not already loaded
    if (
      tab === "generated-archived" &&
      this.props.exportItemsArchived?.data.length === 0
    ) {
      this.fetchArchived();
    }

    // Load schedules if not already loaded
    if (tab === "scheduled" && this.props.scheduledExports.data.length === 0) {
      this.fetchScheduled();
    }
  };

  onPageChange = (newPage: number): void => {
    this.setState({ currentPage: newPage });
  };

  getSortedAndFilteredExports = (
    items: ExportItem[],
    sortedBy: ISortedBy,
    filterText: string
  ) => {
    let sortedExports = [];

    switch (sortedBy.columnId) {
      case "filename":
        sortedExports = _sortBy(items, ["filename"]);
        break;
      case "type":
        sortedExports = _sortBy(items, (item) => {
          return getReportMeta(item, this.props.assuranceType).reportName;
        });
        break;
      case "module":
        sortedExports = _sortBy(items, (item) => {
          return getReportMeta(item, this.props.assuranceType).modules[0];
        });
        break;
      default:
        sortedExports = _sortBy(items, ["createdAt"]);
        break;
    }

    if (this.state.sortedBy.direction !== SortDirection.ASC) {
      sortedExports.reverse();
    }

    if (filterText && filterText.length > 0) {
      const filterTextLower = filterText.toLocaleLowerCase();

      sortedExports = sortedExports.filter(
        (i) => i.filename.toLocaleLowerCase().indexOf(filterTextLower) !== -1
      );
    }

    return sortedExports;
  };

  getSortedAndFilteredSchedules = (
    items: DisplayableExportSchedule[],
    sortedBy: ISortedBy,
    filterText: string
  ): DisplayableExportSchedule[] => {
    let sortedSchedules: DisplayableExportSchedule[] = [];

    switch (sortedBy.columnId) {
      case "report":
        sortedSchedules = _sortBy(
          items,
          (item) => getReportMeta(item, this.props.assuranceType).reportName
        );
        break;
      case "org":
        sortedSchedules = _sortBy(items, (item) =>
          _.get(item.exportMeta, "vendorName", this.props.vendorName)
        );
        break;
      case "next":
        sortedSchedules = _sortBy(items, ["nextRun"]);
        break;
      case "frequency":
        sortedSchedules = _sortBy(items, (item) =>
          IntervalSortOrder(item.interval)
        );
        break;
    }

    if (this.state.sortedBy.direction !== SortDirection.ASC) {
      sortedSchedules.reverse();
    }

    if (filterText && filterText.length > 0) {
      const filterTextLower = filterText.toLocaleLowerCase();

      sortedSchedules = sortedSchedules.filter((i) => {
        const { reportName } = getReportMeta(i, this.props.assuranceType);
        const vendorName = _.get(
          i.exportMeta,
          "vendorName",
          this.props.vendorName
        );
        return (
          reportName.toLowerCase().indexOf(filterTextLower) !== -1 ||
          (vendorName &&
            vendorName.toLowerCase().indexOf(filterTextLower) !== -1)
        );
      });
    }

    return sortedSchedules;
  };

  toggleScheduleRow = (_rowId: string | number) => {
    const rowId = _rowId as string;
    this.setState(({ expandedScheduleRows }) => {
      if (expandedScheduleRows[rowId]) {
        const newExpandedRows = { ...expandedScheduleRows };
        delete newExpandedRows[rowId];
        return { expandedScheduleRows: newExpandedRows };
      }
      return {
        expandedScheduleRows: { ...expandedScheduleRows, [rowId]: true },
      };
    });
  };

  toggleEditScheduleModal = (item: DisplayableExportSchedule) => {
    this.setState({ editModalOpen: true, modalSchedule: item });
  };

  toggleReportsModal = (
    visible: boolean,
    selectedReport: ExecutiveReportType,
    mode: ExecutiveReportingMode | undefined
  ) => {
    this.setState({
      reportingModalOpen: visible,
      reportingModalMode: mode,
      reportingSelectedReportType: selectedReport,
    });
  };

  renderLibraryPage = () => {
    return <ReportsLibrary history={this.props.history} />;
  };

  renderGeneratedPage = (subTab: string) => {
    const isArchivedView = subTab === "archived";
    const exportsToDisplay = isArchivedView
      ? this.props.exportItemsArchived?.data
      : this.props.exportItems?.data;

    const activeTabText =
      "Active" +
      (!this.props.exportItems?.loading
        ? ` (${this.props.exportItems?.data.length})`
        : "");
    const archivedTabText =
      "Archived" +
      (!this.props.exportItemsArchived?.loading
        ? ` (${this.props.exportItemsArchived?.data.length})`
        : "");

    const isReportListLoading = isArchivedView
      ? this.props.exportItemsArchived?.loading
      : this.props.exportItems?.loading;

    const isReports = isArchivedView
      ? this.props.exportItemsArchived &&
        this.props.exportItemsArchived.data &&
        this.props.exportItemsArchived.data.length > 0
      : this.props.exportItems &&
        this.props.exportItems.data &&
        this.props.exportItems.data.length > 0;

    const sortedAndFilteredExports = this.getSortedAndFilteredExports(
      exportsToDisplay || [],
      this.state.sortedBy,
      this.state.filterText
    );

    let numPages = Math.ceil(sortedAndFilteredExports.length / pageLimit);
    if (numPages === 0) {
      numPages = 1;
    }

    const currentPage =
      this.state.currentPage > 1 && this.state.currentPage > numPages
        ? numPages
        : this.state.currentPage;

    const pageStart = Math.floor((currentPage - 1) * pageLimit);
    const pageEnd = Math.min(
      Math.ceil(currentPage * pageLimit),
      sortedAndFilteredExports.length
    );
    const currentPageItems = sortedAndFilteredExports.slice(pageStart, pageEnd);

    const emptyText = isArchivedView
      ? "You currently have no archived reports."
      : "You currently have no completed or pending reports.";

    const emptySubText = isArchivedView
      ? "When an existing report is archived, it will appear here."
      : "When you request an export, you will be able to track its progress and download it here.";

    const bulkArchiveText = isArchivedView ? "Unarchive" : "Archive";

    const hasArchivedReports =
      this.props.exportItemsArchived &&
      this.props.exportItemsArchived.data &&
      !this.props.exportItemsArchived.loading &&
      this.props.exportItemsArchived.data.length > 0;

    return (
      <>
        {this.state.fireConfetti && <ConfettiFullPage />}
        <Fragment>
          <ReportCard newStyles>
            <div className="header">
              <div className="header-text">Generated reports</div>
              <SearchBox
                key={"filterText"}
                value={this.state.filterText}
                onChanged={(newVal) => this.setState({ filterText: newVal })}
                placeholder={"Search generated reports"}
              />
            </div>
            <div
              className={classnames({
                "secondary-header": true,
                "search-only": !hasArchivedReports,
              })}
            >
              {hasArchivedReports && (
                <TabButtons
                  className={"active-tabs"}
                  tabs={[
                    {
                      id: "active",
                      text: activeTabText,
                    },
                    {
                      id: "archived",
                      text: archivedTabText,
                    },
                  ]}
                  activeTabId={subTab}
                  onChangeTab={(tabId) => {
                    this.setTab("generated-" + tabId);
                  }}
                />
              )}
            </div>
            {!isReports && !isReportListLoading && (
              <EmptyCardWithAction
                iconSrc={CircleDocumentSvg}
                emptyText={emptyText}
                emptySubText={emptySubText}
              />
            )}
            <div>
              {(sortedAndFilteredExports.length > 0 || isReportListLoading) && (
                <>
                  <ReportExportsList
                    sortedAndFilteredExports={currentPageItems}
                    sortedBy={this.state.sortedBy}
                    selectedIds={this.state.selectedIds}
                    loading={!!isReportListLoading}
                    assuranceType={this.props.assuranceType}
                    onSortChange={(columnId, direction) =>
                      this.setState({
                        sortedBy: { columnId, direction },
                        currentPage: 1,
                      })
                    }
                    onSelectAll={this.selectAll}
                    onSelect={this.selectClick}
                    onDownloadItem={(item) =>
                      this.downloadItem(item, isArchivedView)
                    }
                    onArchiveItems={(items, isArchive) =>
                      this.archiveItems(items, isArchive)
                    }
                    onDeleteItems={(items) => this.deleteItems(items)}
                    onRenameItems={(items) => this.renameItems(items)}
                    pagination={{
                      currentPage: currentPage,
                      totalPages: numPages,
                      onPageChange: this.onPageChange,
                    }}
                  />
                </>
              )}
              {isReports &&
                sortedAndFilteredExports.length === 0 &&
                !this.state.moreLoading && (
                  <SearchEmptyCard
                    searchItemText="reports"
                    onClear={() => this.setState({ filterText: "" })}
                  />
                )}
            </div>
          </ReportCard>
        </Fragment>
        <ActionBar active={this.state.selectedIds.length > 0}>
          <div>{this.state.selectedIds.length} report(s) selected</div>
          <div className={"action-buttons"}>
            <Button
              tertiary
              disabled={this.state.moreLoading}
              onClick={() => this.selectAll(false)}
            >
              Cancel
            </Button>
            <Button
              danger
              disabled={this.state.moreLoading}
              onClick={() => this.deleteItems(this.getSelectedItems())}
            >
              <div className="cr-icon-trash" /> Delete
            </Button>
            <Button
              className={isArchivedView ? "un-archive-btn" : "archive-btn"}
              disabled={this.state.moreLoading}
              onClick={() =>
                this.archiveItems(this.getSelectedItems(), !isArchivedView)
              }
            >
              <div className="cr-icon-archive" />
              {bulkArchiveText}
            </Button>

            <SidePopupV2
              position={"left"}
              text={
                this.state.selectedIds.length > 99
                  ? "Please select less than 100 reports to enable download"
                  : ""
              }
            >
              <Button
                disabled={
                  this.state.moreLoading || this.state.selectedIds.length > 99
                }
                onClick={() =>
                  this.downloadItems(this.getSelectedItems(), isArchivedView)
                }
              >
                <div className="cr-icon-download" /> Download
              </Button>
            </SidePopupV2>
          </div>
        </ActionBar>
        <RenameReportModal
          active={this.state.renameModalOpen}
          items={this.state.itemsToRename}
          update={(items: ItemIdName[]) =>
            this.onItemsRenamed(items, isArchivedView)
          }
          onClose={() => this.setState({ renameModalOpen: false })}
        ></RenameReportModal>
      </>
    );
  };

  renderScheduledPage = () => {
    const exportsBySchedule: { [key: number]: ExportItem[] } = {};

    if (this.props.exportItems && this.props.exportItems.data) {
      this.props.exportItems.data.forEach((item: ExportItem) => {
        if (item.scheduledExport) {
          if (!_.has(exportsBySchedule, item.scheduledExport)) {
            exportsBySchedule[item.scheduledExport] = [];
          }
          exportsBySchedule[item.scheduledExport].push(item);
        }
      });
    }

    const sortedSchedules = this.getSortedAndFilteredSchedules(
      this.props.scheduledExports.data,
      this.state.sortedBy,
      this.state.filterText
    );

    let scheduledNumPages = Math.ceil(sortedSchedules.length / pageLimit);
    if (scheduledNumPages === 0) {
      scheduledNumPages = 1;
    }

    const currentPage =
      this.state.currentPage > 1 && this.state.currentPage > scheduledNumPages
        ? scheduledNumPages
        : this.state.currentPage;

    const scheduledPageStart = Math.floor((currentPage - 1) * pageLimit);
    const scheduledPageEnd = Math.min(
      Math.ceil(currentPage * pageLimit),
      sortedSchedules.length
    );
    const scheduledCurrentPageItems = sortedSchedules.slice(
      scheduledPageStart,
      scheduledPageEnd
    );

    return (
      <>
        <ReportCard newStyles className={"scheduled-reports"}>
          <div className={"header"}>
            <div className={"header-text"}>Recurring reports</div>
            <SearchBox
              key={"scheduled-search"}
              value={this.state.filterText}
              onChanged={(newVal) => this.setState({ filterText: newVal })}
              placeholder={"Search recurring reports"}
            />
          </div>
          {!this.props.scheduledExports.loading &&
            this.props.scheduledExports.data.length === 0 && (
              <EmptyCardWithAction
                iconSrc={ScheduledIcon}
                emptyText={"No recurring reports scheduled"}
                emptySubText={
                  "No reports have been scheduled to export. You can set up a scheduled report while exporting."
                }
                supportLinkHref={
                  "https://help.upguard.com/en/articles/4988051-what-are-recurring-reports"
                }
              />
            )}
          {(this.props.scheduledExports.loading ||
            scheduledCurrentPageItems.length > 0) && (
            <ReportScheduleList
              scheduledExports={scheduledCurrentPageItems}
              loading={this.props.scheduledExports.loading}
              exportsForSchedule={exportsBySchedule}
              orgName={this.props.vendorName}
              onDownloadItem={(item) => this.downloadItem(item, false)}
              onArchiveItems={(items) => this.archiveItems(items, true)}
              onDeleteItems={(items) => this.deleteItems(items)}
              selectedIds={this.state.selectedIds}
              onSelectAll={this.selectAllSchedule}
              onSelect={this.selectClick}
              toggleRow={this.toggleScheduleRow}
              expandedRows={this.state.expandedScheduleRows}
              sortedBy={this.state.sortedBy}
              onSortChange={(columnId, direction) =>
                this.setState({
                  sortedBy: { columnId, direction },
                })
              }
              onClickRow={this.toggleEditScheduleModal}
              pagination={{
                currentPage: currentPage,
                totalPages: scheduledNumPages,
                onPageChange: this.onPageChange,
              }}
              assuranceType={this.props.assuranceType}
            />
          )}
          {this.props.scheduledExports.data.length > 0 &&
            sortedSchedules.length === 0 &&
            !this.props.scheduledExports.loading && (
              <SearchEmptyCard
                searchItemText="schedules"
                onClear={() => this.setState({ filterText: "" })}
              />
            )}
        </ReportCard>
        <EditReportScheduleModal
          schedule={this.state?.modalSchedule}
          active={this.state.editModalOpen}
          onClose={() =>
            this.setState({ modalSchedule: undefined, editModalOpen: false })
          }
          availableLabels={this.props.availableLabels}
          dispatch={this.props.dispatch}
          subsidiaries={this.props.subsidiaries}
        />
        <ActionBar active={this.state.selectedIds.length > 0}>
          <div>{this.state.selectedIds.length} report(s) selected</div>
          <div className={"action-buttons"}>
            <Button
              tertiary
              disabled={this.state.moreLoading}
              onClick={() => this.selectAll(false)}
            >
              Cancel
            </Button>
            <Button
              className={"archive-btn"}
              disabled={this.state.moreLoading}
              onClick={() => this.archiveItems(this.getSelectedItems(), true)}
            >
              <div className="cr-icon-archive" /> Archive
            </Button>
            <Button
              danger
              disabled={this.state.moreLoading}
              onClick={() => this.deleteItems(this.getSelectedItems())}
            >
              <div className="cr-icon-trash" /> Delete
            </Button>
          </div>
        </ActionBar>
      </>
    );
  };

  render() {
    let numUnread = 0;
    let numPending = 0;
    if (this.props.exportItems?.data) {
      for (let i = 0; i < this.props.exportItems.data.length; i++) {
        if (
          this.props.exportItems.data[i].status ===
          userReportExportStatuses.Pending
        ) {
          numPending += 1;
        } else if (
          this.props.exportItems.data[i].status ===
            userReportExportStatuses.Complete &&
          !this.props.exportItems.data[i].read
        ) {
          numUnread += 1;
        }
      }
    }

    let generatedTabLabel = <span>Generated reports</span>;
    if (numUnread > 0) {
      generatedTabLabel = (
        <div className="gen-reports-row">
          <span>Generated reports</span>
          <PillLabel>{`${numUnread} new`}</PillLabel>
        </div>
      );
    } else if (numPending > 0) {
      generatedTabLabel = (
        <div className="gen-reports-row">
          <span>Generated reports</span>
          <PillLabel color={LabelColor.Blue}>
            <LoadingIcon size={8} />
            {`${numPending} in queue`}
          </PillLabel>
        </div>
      );
    }

    const canSeeLibrary =
      (this.props.orgHasBreachsightEnabled &&
        (this.props.userHasGlobalBreachsightPermission ||
          this.props.userHasPortfolioSpecificBreachsightPermission)) ||
      (this.props.orgHasVendorRiskEnabled &&
        (this.props.userHasGlobalVendorRiskPermission ||
          this.props.userHasPortfolioSpecificVendorRiskPermission));

    const tabDefinitions = [] as any[];
    if (canSeeLibrary) {
      tabDefinitions.push({ id: "library", text: "Templates" });
    }
    tabDefinitions.push({ id: "generated", text: generatedTabLabel });
    tabDefinitions.push({ id: "scheduled", text: "Recurring reports" });

    const subTabs = this.props.currentTab.split("-");
    const currentTab = subTabs[0]
      ? subTabs[0]
      : canSeeLibrary
        ? "library"
        : "generated";
    const subTab = subTabs.length > 1 ? subTabs[1] : "active";

    return (
      <div id="report_exports">
        <PageHeader
          history={this.props.history}
          title="Reports"
          backAction={
            this.props.location.state?.backContext?.goBack
              ? this.props.history.goBack
              : this.props.location.state?.backContext?.backTo
                ? () =>
                    this.props.history.push(
                      this.props.location.state.backContext?.backTo as string
                    )
                : undefined
          }
          backText={this.props.location.state?.backContext?.backToText}
          infoSectionPageKey="infoSection_reports"
          infoSection={
            <>
              <p>
                Use a library of customizable reports to quickly surface and
                visualize the data that is important to you. Reports can be done
                as needed, or set to recur at specified intervals. You can check
                the status of your generated reports and download, delete or
                archive any completed reports.
              </p>
            </>
          }
          infoSectionButtons={
            <Button
              tertiary
              onClick={() =>
                window.open(
                  "https://help.upguard.com/en/articles/6339293-reporting-in-upguard"
                )
              }
            >
              View support article <div className="cr-icon-arrow-right" />
            </Button>
          }
        />
        <div className={"header-tabs"}>
          {tabDefinitions.length > 1 && (
            <TabButtons
              styling={tabButtonsStylingType.FullWidthBanner}
              onChangeTab={this.setTab}
              tabs={tabDefinitions}
              activeTabId={currentTab}
            />
          )}
        </div>
        {currentTab === "library" && this.renderLibraryPage()}
        {currentTab === "scheduled" && this.renderScheduledPage()}
        {currentTab === "generated" && this.renderGeneratedPage(subTab)}
      </div>
    );
  }
}

export default appConnect<
  IReportExportsConnectedProps,
  undefined,
  IReportExportsOwnProps
>((state, props) => {
  const orgHasAssessmentsEnabled = hasOrgPermission(
    state.common.permissions,
    OrgAccessVendorAssessments
  );
  const currentOrg = getCurrentOrgFromUserData(state.common.userData);
  let { currentTab } = props.match.params;
  if (!currentTab) {
    currentTab = ""; // Default to active
  }

  const connectedProps: IReportExportsConnectedProps = {
    exportItems: state.common.exportItems,
    exportItemsArchived: state.common.exportItemsArchived,
    scheduledExports: state.common.scheduledExports,
    orgHasBreachsightEnabled: hasOrgPermission(
      state.common.permissions,
      OrgAccessCustomerInfo
    ),
    userHasGlobalBreachsightPermission: hasUserPermission(
      state.common.permissions,
      UserBreachsightEnabled
    ),
    userHasPortfolioSpecificBreachsightPermission: !!Object.values(
      state.common.userData.domainPortfolioSpecificPermissions
    ).find((perms) => perms?.includes(UserBreachsightEnabled)),
    orgHasVendorRiskEnabled: hasOrgPermission(
      state.common.permissions,
      OrgAccessVendors
    ),
    userHasGlobalVendorRiskPermission: hasUserPermission(
      state.common.permissions,
      UserVendorRiskEnabled
    ),
    userHasPortfolioSpecificVendorRiskPermission: !!Object.values(
      state.common.userData.vendorPortfolioSpecificPermissions
    ).find((perms) => perms?.includes(UserVendorRiskEnabled)),
    vendorName: currentOrg ? currentOrg.name : "",
    vendorPrimaryHostname: currentOrg ? currentOrg.mainHostname : "",
    vendorVerified: currentOrg ? currentOrg.isVerifiedVendor : false,
    currentTab: currentTab,
    userData: state.common.userData,
    availableLabels: state.cyberRisk.availableLabels,
    subsidiaries: state.cyberRisk.customerData.subsidiaries,
    assuranceType: state.common.userData.assuranceType,
    orgHasAssessmentsEnabled: orgHasAssessmentsEnabled,
    orgHasSubsidiaries: userOrgHasSubsidiaries(state),
  };

  return connectedProps;
})(ReportExportsV2);
