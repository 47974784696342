import UserBaseAPI from "../../api/userbase.api";
import ReportCard from "../../../_common/components/ReportCard";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import InfoTable, { InfoTableRow } from "../../../_common/components/InfoTable";
import IdentityBreachDate from "./IdentityBreachDate";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import IdentityBreachSeverityIcon from "./IdentityBreachSeverity";
import classnames from "classnames";
import "./IdentityBreachAbout.scss";
import { NumberWithCommas } from "../../../_common/helpers";
import { FC } from "react";

interface IdentityBreachAboutProps {
  breachID: number;
}

const IdentityBreachAbout: FC<IdentityBreachAboutProps> = ({ breachID }) => {
  const { data, isLoading } =
    UserBaseAPI.useGetUserBaseIdentityBreachSummaryV1Query({
      breachID: breachID,
    });

  return (
    <ReportCard newStyles className="userbase-identity-breach-about">
      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && data && (
        <>
          <div className="header">
            <span>About {data?.breach.name ?? "Identity Breach"}</span>
          </div>
          <div className={"content"}>
            <InfoTable>
              {data.breach.description && (
                <InfoTableRow
                  label="Breach details"
                  rowClass={"no-max-height"}
                  value={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.breach.description,
                      }}
                    ></div>
                  }
                />
              )}
              {data.breach.pasteURL && (
                <>
                  <InfoTableRow
                    label="Paste details"
                    rowClass={classnames("no-max-height", "breach-paste")}
                    value={
                      <>
                        <div className="breach-detail-description-text">
                          A paste is a data dump containing email addresses,
                          often found on &quot;paste&quot; sites like Pastebin.
                          The presence of an email address in this data
                          doesn&apos;t always mean it&apos;s been compromised in
                          a breach. The process that scans for addresses is
                          entirely autonomous - there&apos;s no human review.
                          The data should be reviewed to assess the impact to
                          the listed accounts, as it may include other data such
                          as passwords or user information.
                        </div>
                        <div className="breach-detail-description-text">
                          <a
                            href={data.breach.pasteURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.breach.pasteURL}
                          </a>
                        </div>
                        <div className="breach-detail-description-text">
                          <em>
                            Note: paste URLs are often transient and may be
                            deleted soon after they are published
                          </em>
                        </div>
                      </>
                    }
                  />
                </>
              )}
              <InfoTableRow
                label="Date of breach"
                rowClass={"no-max-height"}
                value={<IdentityBreachDate breach={data.breach} />}
              />
              <InfoTableRow
                label="Date published"
                rowClass={"no-max-height"}
                value={
                  <DateTimeFormat
                    dateTime={data.breach.publishedDate}
                    dateOnly
                  />
                }
              />
              <InfoTableRow
                label="Severity"
                rowClass={"no-max-height"}
                value={<IdentityBreachSeverityIcon breach={data.breach} />}
              />
              <InfoTableRow
                label="Breach type"
                rowClass={"no-max-height"}
                value={<span>{data.breach.breachType}</span>}
              />
              <InfoTableRow
                label="Total involved"
                rowClass={"no-max-height"}
                value={
                  <span>{NumberWithCommas(data.breach.exposuresCount)}</span>
                }
              />
            </InfoTable>
          </div>
        </>
      )}
    </ReportCard>
  );
};

export default IdentityBreachAbout;
