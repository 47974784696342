import { useEnv } from "../../_common/hooks";
import { ReactNode, useState } from "react";
import "../style/components/DebugMenu.scss";

// DebugMenu is a component that displays a debug menu in the bottom right corner of the screen.
// It is only visible in development and local environments.
// It is useful for providing any actions or info which is should only be visible during the dev and review cycle
// e.g. setting repeatable state for PM and QA review.
export default function DebugMenu({ children }: { children?: ReactNode }) {
  const env = useEnv();
  const [open, setOpen] = useState(false);

  if (env !== "development" && env !== "local") {
    return null;
  }

  return (
    <>
      {!open && (
        <div className="debug-menu closed" onClick={() => setOpen(true)}>
          🐞
        </div>
      )}
      {open && (
        <div className="debug-menu open">
          <div className="content">{children}</div>
          <div className="title">
            🐞 menu{" "}
            <div className="x" onClick={() => setOpen(false)}>
              X
            </div>
          </div>
        </div>
      )}
    </>
  );
}
