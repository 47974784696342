import { autoBatchEnhancer, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import commonReducer from "../reducers/commonReducer";
import vendorPortalReducer from "../../vendor_portal/reducers/reducer";
import analystPortalReducer from "../../analyst_portal/reducers/reducer";
import cyberRiskReducer from "../../vendorrisk/reducers/cyberRiskReducer";
import {
  surveyBuilderReducer,
  SurveyBuilderState,
} from "../../survey_builder/reducers/reducer";
import {
  surveyViewerReducer,
  SurveyViewerState,
} from "../../survey_viewer/reducers/reducer";
import {
  IAdminState,
  IAnalystPortalState,
  ICommonState,
  ICyberRiskState,
  IVendorPortalState,
} from "./redux";
import { Reducer } from "redux";
import adminReducer from "../../admin_portal/reducers/adminReducer";
import { Connect } from "react-redux";
import BaseAPI from "../rtkQueryApi";
import appguardSlice from "../../appguard/slice";
import threatmonSlice from "../../threatmonitoring/Slice";

const enableDebugMiddleware = process.env.ENABLE_REDUX_MIDDLEWARE === "true";

export const createStore = (preloadedState?: unknown) =>
  configureStore({
    reducer: {
      common: commonReducer as Reducer<ICommonState>,
      vendorPortal: vendorPortalReducer as Reducer<IVendorPortalState>,
      analystPortal: analystPortalReducer as Reducer<IAnalystPortalState>,
      cyberRisk: cyberRiskReducer as Reducer<ICyberRiskState>,
      admin: adminReducer as Reducer<IAdminState>,
      surveyBuilder: surveyBuilderReducer as Reducer<SurveyBuilderState>,
      surveyViewer: surveyViewerReducer as Reducer<SurveyViewerState>,

      [appguardSlice.reducerPath]: appguardSlice.reducer,
      [threatmonSlice.reducerPath]: threatmonSlice.reducer,

      // RTK Query API slices. Recommended usage is to just have one, but it's
      // conceivable that more could be added in future for different base URLs.
      [BaseAPI.reducerPath]: BaseAPI.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: enableDebugMiddleware
          ? {
              warnAfter: 50, // Increase the warning threshold for this check to cut down on noise
              ignoredPaths: ["common.modalData"], // Disable checks for old method of passing data into modals
            }
          : false,
        serializableCheck: enableDebugMiddleware
          ? {
              ignoredPaths: ["common.modalData"],
              ignoredActions: ["ADD_MESSAGE_ALERT"],
            }
          : false,
      }).concat(BaseAPI.middleware),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(autoBatchEnhancer());
    },
  });

const store = createStore();

// Allows the use of things like refetchOnFocus
// https://redux-toolkit.js.org/rtk-query/api/setupListeners
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Add some type overrides to the react-redux package.
declare module "react-redux" {
  // react-redux v8 no longer has the concept of DefaultRootState, but we'll
  // add it back in here to avoid having to change a lot of instances where we imported it.
  export type DefaultRootState = RootState;

  // Type the connect function by default with our RootState
  export const connect: Connect<RootState>;
}

export default store;
