import { IdentityBreachRouteParams } from "../../UserBaseNavItems";
import { useBack } from "../../../_common/types/router";
import UserBaseAPI from "../../api/userbase.api";
import {
  identityBreachesUrl,
  identityBreachSummaryUrl,
} from "../../UserBaseAppRouter";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import UserBaseIdentityBreachHeader from "../../components/headers/UserBaseIdentityBreachHeader";
import InfoBanner from "../../../vendorrisk/components/InfoBanner";
import IdentityBreachAbout from "../../components/identity_breaches/IdentityBreachAbout";

import "./IdentityBreachSummaryView.scss";
import IdentityBreachDataExposedSummary from "../../components/identity_breaches/IdentityBreachDataExposedSummary";

const IdentityBreachSummaryView: FC = ({}) => {
  const match = useRouteMatch<IdentityBreachRouteParams>();
  const { backAction, backText } = useBack();

  const breachID = Number(match.params.breachID);

  const { data, isLoading } =
    UserBaseAPI.useGetUserBaseIdentityBreachSummaryV1Query({
      breachID: breachID,
    });

  return (
    <div className="userbase-ib-summary">
      <UserBaseIdentityBreachHeader
        breachID={breachID}
        breadcrumbs={[
          { text: "Identity Breaches", to: identityBreachesUrl },
          {
            text: data?.breach.name ?? "Identity Breach",
            to: identityBreachSummaryUrl(breachID),
          },
          {
            text: "Summary",
            to: identityBreachSummaryUrl(breachID),
          },
        ]}
        title={<div className="identity-breaches-container">Summary</div>}
        backAction={backAction}
        backText={backText}
      />
      {data?.breach.isDarkWebBreach && (
        <div className="breach-detail-title-row-info-banner">
          <InfoBanner
            message={
              "Posts from dark web actors are presented here for informational purposes. While UpGuard does consider this a legitimate source, it may contain information that has not been confirmed, and may be misleading and/or false."
            }
          />
        </div>
      )}

      {isLoading && <LoadingBanner size={70} />}
      {!isLoading && (
        <div className="two-grid no-stretch">
          <IdentityBreachAbout breachID={breachID} />
          <IdentityBreachDataExposedSummary breachID={breachID} />
        </div>
      )}
    </div>
  );
};

export default IdentityBreachSummaryView;
