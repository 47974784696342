import { useNavigateTo } from "../../_common/types/router";

export function useNavToThreatDetails() {
  const navTo = useNavigateTo();

  return (uuid: string, startWithCommentsOpen?: boolean) => () => {
    let url = `/breachrisk/threatmonitoring/threat/${uuid}`;

    if (startWithCommentsOpen) {
      url = `${url}?open_comments=true`;
    }

    navTo(url);
  };
}

export function useNavToRemediationRequest() {
  const navTo = useNavigateTo();

  return (remediationRequestID: number) => () =>
    navTo(`/selfremediation/${remediationRequestID}`);
}
