import { FC } from "react";
import UserBaseAPI from "../../api/userbase.api";
import { XTableCell } from "../../../_common/components/core/XTable";
import { AppEvent, AppEventType, AppStatus } from "../../api/types";
import Timeline, { TimelineEvent } from "../Timeline";

const AppEventCell: FC<AppEvent> = ({
  eventType,
  metadata,
  userName,
  userEmail,
}) => {
  let action: string;
  const noun = userName || userEmail;

  switch (eventType) {
    case AppEventType.ReviewedAppUpdateEvent:
      let verb: string;
      switch (metadata.status) {
        case AppStatus.ApprovedAppStatus:
          verb = "approved the app";
          if (metadata.approvedForAll) {
            verb += " for all";
          } else if (
            metadata.approvedTeams &&
            metadata.approvedTeams.length > 0
          ) {
            verb += ` for teams: ${metadata.approvedTeams.join(", ")}`;
          } else if (
            metadata.approvedRoles &&
            metadata.approvedRoles.length > 0
          ) {
            verb += ` for roles: ${metadata.approvedRoles.join(", ")}`;
          }
          break;
        case AppStatus.NotApprovedAppStatus:
          verb = "marked the app as not approved";
          break;
        case AppStatus.InReviewAppStatus:
          verb = "marked the app as in review";
          break;
        case AppStatus.NeedsReviewAppStatus:
          throw Error("App event can't have needs review status");
      }
      action = `${noun} ${verb}`;
      if (metadata.note) {
        action += ` with note: ${metadata.note}`;
      }
      break;
    case AppEventType.AppFirstDetectedEvent:
      action = "App first detected";
      break;
  }

  return <XTableCell key={"action"}>{action}</XTableCell>;
};

interface AppTimelineProps {
  appName: string;
}

const AppTimeline: FC<AppTimelineProps> = ({ appName }) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppTimelineV1Query({
    app: appName,
  });

  const appEvents: TimelineEvent[] = (data?.events ?? []).map((e) => ({
    at: e.at,
    cell: AppEventCell(e),
  }));

  return (
    <Timeline
      isLoading={isLoading}
      events={appEvents}
      emptyText={"No events for this application"}
      emptySubtext={
        "There are no events for this application. When certain events occur — if the app is approved, for example — they will be listed here."
      }
    />
  );
};

export default AppTimeline;
