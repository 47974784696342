import { DefaultThunkDispatchProp } from "../../../../_common/types/redux";
import { useDefaultHistory } from "../../../../_common/types/router";
import useDefaultCannedExportConfig from "./useDefaultCannedExportConfig";
import { AvailableExportConfig } from "../../../../_common/types/exportConfig";
import { useConfiguredExportConfigReducer } from "../exportConfigReducer";
import {
  exportReport,
  fetchExportSections,
} from "../../../reducers/export.actions";
import {
  ExportFiletype,
  ExportSection,
  ExportType,
} from "../../../../_common/types/exportReport";
import { getExportSections } from "../../../reducers/cyberRiskSelectors";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../../_common/reducers/messageAlerts.actions";
import { useCallback, useEffect, useState } from "react";

// Provides a callback that can be used to instantly generate a
// risk_assessment_vendor_report based on a specified risk assessment.
// This skips all user interaction for specifying report options, and
// is intended to quickly generate a draft report based on an in-progress
// risk assessment.

export interface IGenerateReportOwnProps {
  vendorId?: number;
  riskAssessmentId?: number;
  isSubsidiary?: boolean;
}

export interface IGenerateReportConnectProps {
  state: any;
}

export type IGenerateReportProps = IGenerateReportOwnProps &
  IGenerateReportConnectProps &
  DefaultThunkDispatchProp;

export const useGenerateRiskAssessmentReport = (
  props: IGenerateReportProps
): (() => void) => {
  const history = useDefaultHistory();
  const exportOptions: Record<string, unknown> = {
    vendor_id: props.vendorId,
    risk_assessment_id: props.riskAssessmentId,
  };

  const cannedExportConfig = useDefaultCannedExportConfig(
    "risk_assessment_vendor_report"
  );

  const [exportSectionsFetched, setExportSectionsFetched] = useState(false);
  const [availableConfig, setAvailableConfig] =
    useState<AvailableExportConfig>();
  const [configuredExportConfig, configDispatch] =
    useConfiguredExportConfigReducer(
      availableConfig,
      cannedExportConfig?.config
    );

  useEffect(() => {
    const fetch = async () => {
      await fetchExportSections({
        exportType:
          cannedExportConfig?.exportType || ExportType.DetailedVendorReportV2,
        exportOptions: exportOptions,
        applyFilters: false,
        vendorId: props.vendorId,
        isSubsidiary: props.isSubsidiary,
      })(props.dispatch, () => props.state);
      setExportSectionsFetched(true);
    };
    fetch().catch(console.error);
  }, [cannedExportConfig, props.vendorId]);

  useEffect(() => {
    if (!exportSectionsFetched) {
      return;
    }

    const exportSections = getExportSections(
      props.state,
      cannedExportConfig?.exportType,
      false,
      exportOptions,
      props.vendorId,
      false,
      false,
      {}
    ) as ExportSection;

    const available =
      exportSections.data?.availableConfigByFiletype[
        cannedExportConfig?.filetype || ExportFiletype.PDF
      ];
    if (available) {
      setAvailableConfig(available);
    }
  }),
    [exportSectionsFetched, cannedExportConfig, props.vendorId];

  useEffect(() => {
    configDispatch({
      type: "SET_OPTION",
      id: "select_risk_assessment_version",
      isRadio: false,
      valueBool: false,
      valueText: `${props.riskAssessmentId}`,
    });
  }, [availableConfig, exportSectionsFetched]);

  return useCallback(async () => {
    if (!cannedExportConfig) {
      return;
    }
    if (!props.vendorId || !props.riskAssessmentId) {
      return;
    }

    try {
      await props.dispatch(
        exportReport({
          exportType: cannedExportConfig.exportType,
          fileType: cannedExportConfig.filetype,
          cannedReportName: cannedExportConfig.name,
          exportOptions: exportOptions,
          applyFilters: false, // Filtering not supported yet
          config: configuredExportConfig.configuredExportConfig,
          isCustom: false,
        })
      );
      props.dispatch(
        addDefaultSuccessAlert("Generating report", [
          "This report will take a few minutes to generate.",
        ])
      );

      history.push("/reportexports/generated");
    } catch (e) {
      props.dispatch(addDefaultUnknownErrorAlert("Error generating report"));
    }
  }, [
    cannedExportConfig,
    props.vendorId,
    props.riskAssessmentId,
    configuredExportConfig,
  ]);
};
