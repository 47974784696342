import PropTypes from "prop-types";

import "../style/components/BulkActions.scss";

const BulkActions = ({ className, children }) => (
  <div className={`bulk-actions ${className}`}>{children}</div>
);

BulkActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

BulkActions.defaultProps = {
  className: "",
};

export default BulkActions;
