import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import TextField, {
  MaxLengthType,
} from "../../../_common/components/TextField";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { LabelColor } from "../../../_common/types/label";
import { pluralise } from "../../../_common/helpers";
import { useDefaultHistory } from "../../../_common/types/router";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

import { appUsersUrl } from "../../UserBaseAppRouter";
import CheckboxMultiSelect, {
  ICheckboxOption,
} from "../CheckboxMultiSelect_UB";
import { App, AppStatus } from "../../api/types";
import userbaseApi, { UpdateReviewedAppV1Req } from "../../api/userbase.api";
import { getUserBaseColorForKey } from "../../helpers/colors";
import "./AppApprovalModal.scss";

import { ReactElement, useState, useEffect } from "react";
import _ from "lodash";

export enum AppApprovalModalMode {
  ApproveApp = "approve_app",
  DoNotApprovedApp = "do_not_approve_app",
}

interface IAppApprovalModalProps extends BaseModalProps {
  app: App;
  modalMode: AppApprovalModalMode;
}

const AppApprovalModal = ({
  active,
  onClose,
  app,
  modalMode,
}: IAppApprovalModalProps) => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const [hasChanges, setHasChanges] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isApprovedForAll, setIsApprovedForAll] = useState(app.approvedForAll);
  const [isPartiallyApproved, setIsPartiallyApproved] = useState(
    app.approvedForTeams.length > 0 || app.approvedForRoles.length > 0
  );
  const [note, setNote] = useState("");

  //
  // Team state and data
  //
  const [teamCount, setTeamCounts] = useState<Map<string, number>>(new Map());
  const [approvedTeams, setApprovedTeams] = useState(
    app.approvedForTeams.map((t) => t.name)
  );
  const { data: teams, isLoading: teamsIsLoading } =
    userbaseApi.useGetUserBaseTeamsV1Query();

  const [teamCheckboxOptions, setTeamCheckboxOptions] = useState<
    ICheckboxOption[]
  >([]);

  useEffect(() => {
    setTeamCheckboxOptions(
      (teams?.teams ?? []).map((t) => {
        return {
          label: t.name,
          value: t.name,
          selected: approvedTeams.some((at) => at === t.name),
        };
      })
    );

    setTeamCounts(
      (teams?.teams ?? []).reduce((m, t) => {
        m.set(t.name, t.userCount);
        return m;
      }, new Map())
    );
  }, [teams]);

  //
  // Role state and data
  //
  const [roleCount, setRoleCounts] = useState<Map<string, number>>(new Map());
  const [approvedRoles, setApprovedRoles] = useState(
    app.approvedForRoles.map((r) => r.name)
  );
  const { data: roles, isLoading: rolesIsLoading } =
    userbaseApi.useGetUserBaseRolesV1Query();

  const [roleCheckboxOptions, setRoleCheckboxOptions] = useState<
    ICheckboxOption[]
  >([]);

  useEffect(() => {
    setRoleCheckboxOptions(
      (roles?.roles ?? []).map((r) => {
        return {
          label: r.name,
          value: r.name,
          selected: approvedRoles.some((ar) => ar === r.name),
        };
      })
    );

    setRoleCounts(
      (roles?.roles ?? []).reduce((m, r) => {
        m.set(r.name, r.userCount);
        return m;
      }, new Map())
    );
  }, [roles]);

  //
  // UI state and actions
  //

  const [updateReviewedAppV1] =
    userbaseApi.useUpdateUserBaseReviewedAppV1Mutation();

  const onApproveForAllClick = () => {
    if (!isApprovedForAll) setHasChanges(true);
    setIsApprovedForAll(true);
    setIsPartiallyApproved(false);
  };
  const onPartiallyApproveClick = () => {
    if (!isPartiallyApproved) setHasChanges(true);
    setIsApprovedForAll(false);
    setIsPartiallyApproved(true);
  };
  const onNoteChange = (value: string) => {
    setHasChanges(true);
    setNote(value);
  };

  const onImpactedUsersClick = () => {
    history.push(appUsersUrl(app.name), {
      backContext: {
        backTo: "/userbase/applications",
        backToText: "Back to Applications",
      },
    });
  };

  const onSubmit = () => {
    setIsUpdating(true);
    let updateReq: UpdateReviewedAppV1Req;
    switch (modalMode) {
      case AppApprovalModalMode.ApproveApp:
        updateReq = {
          appName: app.name,
          status: AppStatus.ApprovedAppStatus,
          approvedForAll: isApprovedForAll,
          approvedForTeams: isApprovedForAll ? [] : approvedTeams,
          approvedForRoles: isApprovedForAll ? [] : approvedRoles,
          note: note,
        };
        break;
      case AppApprovalModalMode.DoNotApprovedApp:
        updateReq = {
          appName: app.name,
          status: AppStatus.NotApprovedAppStatus,
          approvedForAll: false,
          approvedForTeams: [],
          approvedForRoles: [],
          note: note,
        };
        break;
    }

    updateReviewedAppV1(updateReq)
      .unwrap()
      .then(() => {
        let successMsg: string;
        switch (modalMode) {
          case AppApprovalModalMode.ApproveApp:
            successMsg = isApprovedForAll
              ? `${app.name} is approved for all`
              : `${app.name} is approved for specific teams and roles`;
            break;
          case AppApprovalModalMode.DoNotApprovedApp:
            successMsg = `${app.name} is not approved`;
            break;
        }
        dispatch(addDefaultSuccessAlert(successMsg));
      })
      .catch(() =>
        dispatch(
          addDefaultUnknownErrorAlert("Error updating application approval")
        )
      )
      .finally(() => {
        setIsUpdating(false);
        onClose();
      });
  };

  const approvalSummary = (): ReactElement => {
    if (approvedTeams.length == 0 && approvedRoles.length == 0) {
      return <></>;
    }
    const teamPlural = approvedTeams.length == 1 ? "team" : "teams";
    const rolePlural = approvedRoles.length == 1 ? "role" : "roles";

    const roleCounts = approvedRoles.reduce(
      (n, r) => n + (roleCount.get(r) ?? 0),
      0
    );
    const teamCounts = approvedTeams.reduce(
      (n, t) => n + (teamCount.get(t) ?? 0),
      0
    );
    const totalCounts = roleCounts + teamCounts;

    return (
      <>
        {approvedTeams.length > 0 && (
          <SidePopupV2
            text={truncatedList(approvedTeams)}
            className="approval-popup-link"
            popupClassName="approval-popup"
          >
            <span>
              {approvedTeams.length} {teamPlural}
            </span>
          </SidePopupV2>
        )}
        {approvedTeams.length > 0 && approvedRoles.length > 0 && <>and</>}
        {approvedRoles.length > 0 && (
          <SidePopupV2
            text={truncatedList(approvedRoles)}
            className="approval-popup-link"
            popupClassName="approval-popup"
          >
            <span>
              {approvedRoles.length} {rolePlural}
            </span>
          </SidePopupV2>
        )}
        <>
          approved, affecting{" "}
          <strong>
            {totalCounts} {totalCounts == 1 ? "user" : "users"}
          </strong>
        </>
      </>
    );
  };

  const MaxItemsInTruncatedList = 10;
  const truncatedList = (things: string[]): ReactElement => {
    if (things.length > MaxItemsInTruncatedList) {
      return (
        <>
          {_.map(_.sortBy(_.slice(things, 0, MaxItemsInTruncatedList)), (t) => (
            <div id={t}>{t}</div>
          ))}
          <div>+ {things.length - MaxItemsInTruncatedList} more</div>
        </>
      );
    } else {
      return (
        <>
          {_.map(_.sortBy(things), (t) => (
            <div id={t}>{t}</div>
          ))}
        </>
      );
    }
  };

  const isValidApprovalState =
    isApprovedForAll ||
    (!isApprovedForAll &&
      (approvedTeams.length > 0 || approvedRoles.length > 0));
  const canSubmit =
    // not approval doesn't need required info so it's always possible to submit
    modalMode === AppApprovalModalMode.DoNotApprovedApp ||
    (hasChanges && isValidApprovalState);

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className={"app-approval-modal"}
        headerContent={
          modalMode === AppApprovalModalMode.ApproveApp
            ? `Approve ${app.name}`
            : `Do not approve access for ${app.name}`
        }
        footerClassName={"app-approval-modal-footer"}
        footerContent={
          <>
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
            <Button
              primary
              onClick={onSubmit}
              loading={isUpdating}
              disabled={!canSubmit}
            >
              Save changes
            </Button>
          </>
        }
      >
        <div className={"description"}>
          {modalMode === AppApprovalModalMode.ApproveApp ? (
            <>
              <p>
                Approve this app for all users in your organization or approve
                it for specific teams and roles only.
              </p>
            </>
          ) : (
            <p>
              Setting an application as not approved means no one in your
              organization should be using this application.
            </p>
          )}
        </div>
        {(teamsIsLoading || rolesIsLoading) && <LoadingIcon />}
        {!teamsIsLoading && !rolesIsLoading && (
          <>
            {modalMode === AppApprovalModalMode.ApproveApp && (
              <div className={"approve"}>
                <div className={"header"}>
                  <div className="header-desc">Select approval type</div>
                  <div className="header-subdesc">
                    {"Choose how you'd like to grant access to the app"}
                  </div>
                </div>
                <div className={"options"}>
                  <ColorCheckbox
                    radio
                    label="Approve for all"
                    onClick={() => onApproveForAllClick()}
                    checked={isApprovedForAll}
                  />
                  <div className="partially-approve-box">
                    <div className="partially-approve-subbox">
                      <ColorCheckbox
                        radio
                        label="Partially approve"
                        onClick={() => onPartiallyApproveClick()}
                        checked={isPartiallyApproved}
                      />
                      {isPartiallyApproved && (
                        <div className="header-subdesc approve-box-indent">
                          Access granted only to specific teams or roles
                        </div>
                      )}
                    </div>
                    {isPartiallyApproved && (
                      <>
                        <div className="approve-multiselect-box approve-box-indent">
                          <div className="header-desc">Approved teams</div>
                          <CheckboxMultiSelect
                            key="team-select"
                            className="multiselect"
                            options={teamCheckboxOptions}
                            searchPlaceholder="Type to search teams..."
                            selectPlaceholder="Select teams"
                            onSelectionChanged={(selectedValues) => {
                              const newOptions = teamCheckboxOptions.map(
                                (o) => {
                                  return {
                                    label: o.label,
                                    value: o.value,
                                    selected: selectedValues.some(
                                      (v) => v === o.value
                                    ),
                                  };
                                }
                              );
                              setTeamCheckboxOptions(newOptions);
                              setApprovedTeams(selectedValues);
                              setHasChanges(true);
                            }}
                            pillColorFunction={(
                              teamString: string
                            ): LabelColor => {
                              return getUserBaseColorForKey(teamString);
                            }}
                          />
                        </div>
                        <div className="approve-multiselect-box approve-box-indent">
                          <div className="header-desc">Approved roles</div>
                          <CheckboxMultiSelect
                            key="role-select"
                            className="multiselect"
                            options={roleCheckboxOptions}
                            searchPlaceholder="Type to search roles..."
                            selectPlaceholder="Select roles"
                            onSelectionChanged={(selectedValues) => {
                              const newOptions = roleCheckboxOptions.map(
                                (o) => {
                                  return {
                                    label: o.label,
                                    value: o.value,
                                    selected: selectedValues.some(
                                      (v) => v === o.value
                                    ),
                                  };
                                }
                              );
                              setRoleCheckboxOptions(newOptions);
                              setApprovedRoles(selectedValues);
                              setHasChanges(true);
                            }}
                            pillColorFunction={() => {
                              return LabelColor.Grey;
                            }}
                          />
                        </div>
                        {(approvedTeams.length > 0 ||
                          approvedRoles.length > 0) && (
                          <div className="approval-summary approve-box-indent">
                            <div className="summary-icon cr-icon-partialaccepted" />
                            <div className="summary-text">
                              {approvalSummary()}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {modalMode === AppApprovalModalMode.DoNotApprovedApp && (
              <>
                <InfoBanner
                  className={"do-not-approve"}
                  type={BannerType.WARNING}
                  message={
                    <>
                      This impacts{" "}
                      <span
                        className={"impacted"}
                        onClick={onImpactedUsersClick}
                      >
                        {app.numCurrentUsers}{" "}
                        {pluralise(app.numCurrentUsers, "user", "users")}
                      </span>
                      {` who currently ${pluralise(
                        app.numCurrentUsers,
                        "has",
                        "have"
                      )} access to this app`}
                    </>
                  }
                />
              </>
            )}
            <div
              className={
                modalMode == AppApprovalModalMode.ApproveApp
                  ? "two-col-note"
                  : "one-col-note"
              }
            >
              <div className="header">
                <div className={"header-desc"}>Add note (optional)</div>
                <div className={"header-subdesc"}>
                  This will appear in the timeline log
                </div>
              </div>

              <TextField
                className="note-input"
                value={note ?? ""}
                onChanged={(val) => onNoteChange(val)}
                multiLine
                placeholder="Provide documentation"
                maxLength={MaxLengthType.reasonableLength}
              />
            </div>
          </>
        )}
      </ModalV2>
    </>
  );
};

export default AppApprovalModal;
