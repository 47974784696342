import PageHeader from "../../../_common/components/PageHeader";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useBack } from "../../../_common/types/router";
import userbaseApi from "../../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../../_common/hooks";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../../vendorrisk/components/Score";
import { App, AppWithVendor } from "../../api/types";
import ReportCard from "../../../_common/components/ReportCard";
import SearchBox from "../../../_common/components/SearchBox";
import "./ApplicationsView.scss";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import AppStatusDisplay from "../../components/applications/AppStatusDisplay";
import CompanyLogo from "../../../_common/components/CompanyLogo";
import ScopeRiskLevelDisplay from "../../components/permissions/ScopeRiskLevelDisplay";
import ManageAppButton from "../../components/applications/ManageAppButton";
import { useModalV2 } from "../../../_common/components/ModalV2";
import AppApprovalModal, {
  AppApprovalModalMode,
} from "../../components/applications/AppApprovalModal";
import AppInReviewModal from "../../components/applications/AppInReviewModal";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../../_common/permissions";
import AppVendorMonitored from "../../components/applications/AppVendorMonitored";
import {
  FilterBar,
  FilterLabelContainer,
  FilterPanelContainer,
  FilterTypes,
  isFilterActive,
} from "../../../vendorrisk/components/filter";
import { useFilters } from "../../../vendorrisk/reducers/filters.actions";
import { getClearedFilters } from "../../../vendorrisk/components/filter/FilterPanel";
import { parseUserRiskFilterFromQuery } from "../../helpers/filters";
import { Filters } from "../../../vendorrisk/components/filter/types";
import { setCustomerDataFilters } from "../../../vendorrisk/reducers/cyberRiskActions";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import SlidePanel from "../../../_common/components/SlidePanel";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import { statusDisplayText } from "../../helpers/apps";
import { DashboardStatToShow } from "../../components/stats/DashboardStat";
import TitleStat from "../../components/stats/TitleStat";
import {
  ExportFiletypeXLSX,
  ExportType,
} from "../../../_common/types/exportReport";
import ExportReportModal from "../../../vendorrisk/components/modals/ExportReportModal";
import Button from "../../../_common/components/core/Button";

export const supportedFilters = [
  FilterTypes.USERRISK_APPS,
  FilterTypes.USERRISK_APP_STATUS,
  FilterTypes.USERRISK_APP_APPROVED_TEAMS,
  FilterTypes.USERRISK_APP_APPROVED_ROLES,
  FilterTypes.USERRISK_APP_FIRST_DETECTED,
  FilterTypes.USERRISK_APP_RISK_LEVEL,
  FilterTypes.USERRISK_APP_SCORES,
];

const PAGE_SIZE = 100;

const NAME_COL = "name_col";
const CURRENT_USERS_COL = "current_users_col";
const VENDOR_MONITORED_COL = "vendor_monitored_col";
const SECURITY_RATING_COL = "security_rating_col";
const PERMISSIONS_COL = "permissions_col";
const STATUS_COL = "status_col";
const UNAPPROVED_USERS_COL = "unapproved_users_col";
const ACTIONS_COL = "actions_col";

interface ApplicationsViewProps {}

const ApplicationsView: FC<ApplicationsViewProps> = ({}) => {
  const [searchText, setSearchText] = useState("");
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [openAppApprovalModal, appApprovalModal] = useModalV2(AppApprovalModal);
  const [openAppInReviewModalv2, appInReviewModalv2] =
    useModalV2(AppInReviewModal);
  const { backAction, backText } = useBack();
  const { pathname } = useLocation();
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const filters = useFilters();
  const filtersActive = isFilterActive(filters, supportedFilters);

  const { data, isFetching } = userbaseApi.useGetUserBaseAppsV1Query({
    appNames: filters.userRiskAppNames,
    excludeApps: filters.userRiskExcludeApps,
    statuses: filters.userRiskAppStatuses,
    excludeStatuses: filters.userRiskExcludeStatuses,
    approvedTeams: filters.userRiskTeams,
    excludeApprovedTeams: filters.userRiskExcludeTeams,
    approvedRoles: filters.userRiskRoles,
    excludeApprovedRoles: filters.userRiskExcludeRoles,
    minFirstDetected: filters.userRiskMinFirstDetected,
    maxFirstDetected: filters.userRiskMaxFirstDetected,
    minRiskLevel: filters.userRiskMinRiskLevel,
    maxRiskLevel: filters.userRiskMaxRiskLevel,
    minScore: filters.userRiskAppMinScore,
    maxScore: filters.userRiskAppMaxScore,
  });

  useEffect(() => {
    const queryParamFilters = {
      ...getClearedFilters(supportedFilters),
      ...parseUserRiskFilterFromQuery(history),
    } as Filters;
    if (isFilterActive(queryParamFilters, supportedFilters)) {
      dispatch(setCustomerDataFilters(queryParamFilters));
    }
  }, []);

  const filteredApps = useMemo(
    () =>
      searchText.length > 1
        ? data?.apps.filter(
            (a) => a.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
        : data?.apps,
    [data, searchText]
  );

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: NAME_COL,
      text: "App name",
      sortable: true,
      className: "app-name-col",
    },
    {
      id: CURRENT_USERS_COL,
      text: "Current users",
      sortable: true,
      className: "current-users-col",
    },
    {
      id: VENDOR_MONITORED_COL,
      text: "Vendor Monitored",
      sortable: true,
      className: "vendor-monitored-col",
    },
    {
      id: SECURITY_RATING_COL,
      text: "Security rating",
      sortable: true,
      className: "security-rating-col",
    },
    {
      id: PERMISSIONS_COL,
      text: "Exposure level",
      sortable: true,
      className: "permissions-col",
    },
    {
      id: STATUS_COL,
      text: "Status",
      sortable: true,
      className: "status-col",
    },
    {
      id: UNAPPROVED_USERS_COL,
      text: "Not approved users",
      sortable: true,
      className: "unapproved-users-col",
    },
    {
      id: ACTIONS_COL,
      text: "",
      className: "actions-col",
    },
  ];

  const [
    sortedApps,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    AppWithVendor,
    | typeof NAME_COL
    | typeof CURRENT_USERS_COL
    | typeof SECURITY_RATING_COL
    | typeof PERMISSIONS_COL
    | typeof STATUS_COL
    | typeof UNAPPROVED_USERS_COL
    | typeof VENDOR_MONITORED_COL
  >(
    filteredApps ?? [],
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(a) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [CURRENT_USERS_COL]: {
        orderFuncs: [(u) => u.numCurrentUsers],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(a) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [PERMISSIONS_COL]: {
        orderFuncs: [(a) => a.highestScopeRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [STATUS_COL]: {
        orderFuncs: [(a) => statusDisplayText(a)],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [UNAPPROVED_USERS_COL]: {
        orderFuncs: [(a) => a.numUnapprovedUsers],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [VENDOR_MONITORED_COL]: {
        orderFuncs: [(a) => a.isVendorMonitored],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const onManageAppAccess = (app: App) => {
    openAppApprovalModal({ app, modalMode: AppApprovalModalMode.ApproveApp });
  };
  const onDoNotApproveApp = (app: App) => {
    openAppApprovalModal({
      app,
      modalMode: AppApprovalModalMode.DoNotApprovedApp,
    });
  };
  const onMarkAsInReview = (app: App) => {
    openAppInReviewModalv2({
      app,
    });
  };

  const goToAppPage = (app: App) => {
    history.push(`/userbase/application/${app.name}`, {
      from: "applications",
      backContext: {
        backTo: "/userbase/applications",
        backToText: "Back to Applications",
      },
    });
  };

  const rows = sortedApps.map<IXTableRow>((a) => {
    return {
      id: a.name,
      onClick: () => goToAppPage(a),
      cells: [
        <XTableCell key={NAME_COL}>
          <CompanyLogo
            domain={a.domain}
            name={a.name}
            displayCategory={true}
            category={a.category}
          />
        </XTableCell>,
        <XTableCell key={CURRENT_USERS_COL}>{a.numCurrentUsers}</XTableCell>,

        <XTableCell key={VENDOR_MONITORED_COL}>
          <AppVendorMonitored
            isMonitored={a.isVendorMonitored}
            showText={false}
          />
        </XTableCell>,

        <XTableCell key={SECURITY_RATING_COL}>
          <div className="application-score-container">
            <ColorGrade score={a.securityScore} size={ColorGradeSize.Small} />
            <Score score={a.securityScore} small />
          </div>
        </XTableCell>,

        <XTableCell key={PERMISSIONS_COL}>
          <ScopeRiskLevelDisplay riskLevel={a.highestScopeRiskLevel} />
        </XTableCell>,
        <XTableCell key={STATUS_COL}>
          <AppStatusDisplay app={a} />
        </XTableCell>,
        <XTableCell key={UNAPPROVED_USERS_COL}>
          {a.numUnapprovedUsers}
        </XTableCell>,
        <XTableCell
          key={ACTIONS_COL}
          className={"actions-cell shrink-cell"}
          showContentOnlyOnHover
        >
          {userHasUserBaseWrite && (
            <ManageAppButton
              isLoading={isFetching}
              app={a}
              onManageAppAccess={() => onManageAppAccess(a)}
              onDoNotApproveApp={() => onDoNotApproveApp(a)}
              onMarkAsInReview={() => onMarkAsInReview(a)}
            />
          )}
        </XTableCell>,
      ],
      iconOptions: [
        {
          id: "click",
          icon: <div className={"cr-icon-chevron"} />,
          disabled: false,
          onClick: () => goToAppPage(a),
        },
      ],
    };
  });

  return (
    <div className="userbase-applications">
      <FilterBar
        dispatch={dispatch}
        isCustomer={true}
        active={filtersActive}
        supportedFilters={supportedFilters}
      />
      <PageHeader
        history={history}
        title={<TitleStat statToShow={DashboardStatToShow.AppCount} />}
        backAction={backAction}
        backText={backText}
        infoSectionPageKey="infoSection_userBaseApps"
        infoSection={
          <>
            <p>
              Applications shows the SaaS applications users access as reported
              by your organization&apos;s workspace system (Google Workspace or
              Microsoft 365). This list shows each application&apos;s
              information, including its approval status and the highest
              exposure level users have granted it.
            </p>
          </>
        }
        rightSection={
          <>
            <FilterLabelContainer
              onClick={() => setFilterPanelOpen(!filterPanelOpen)}
              supportedFilters={supportedFilters}
              isCustomer={true}
              disabled={isFetching}
            />
            <Button onClick={() => setExportModalOpen(true)}>
              <div className="cr-icon-export" /> Export
            </Button>
          </>
        }
      />
      <ReportCard newStyles>
        <SearchBox
          onChanged={(v) => {
            onPageChange(1);
            setSearchText(v);
          }}
          value={searchText}
          disabled={isFetching}
        />
        {!isFetching &&
          sortedApps.length === 0 &&
          (searchText.length > 0 || filtersActive) && (
            <SearchEmptyCard
              onClear={() => {
                setSearchText("");
                dispatch(
                  setCustomerDataFilters(getClearedFilters(supportedFilters))
                );
              }}
              clearText={"Clear filter"}
              searchItemText={"application"}
            />
          )}
        {!isFetching &&
          sortedApps.length === 0 &&
          searchText.length === 0 &&
          !filtersActive && (
            <EmptyCard text={"There are no applications at this time."} />
          )}
        {!isFetching && sortedApps.length > 0 && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            className={"apps-container"}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isFetching}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
      <SlidePanel
        active={filterPanelOpen}
        title="Filter by"
        newStyles
        dimContent
        onClose={() => setFilterPanelOpen(false)}
      >
        <FilterPanelContainer
          closePanel={() => setFilterPanelOpen(false)}
          supportedFilters={supportedFilters}
          isCustomer={true}
          startAllExpanded={true}
        />
      </SlidePanel>
      <ExportReportModal
        title={`Export Applications`}
        exportType={ExportType.UserRiskApplicationsExport}
        supportedFilters={supportedFilters}
        active={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        supportedFileTypes={ExportFiletypeXLSX}
        shouldFetchExportSections
      />
      {appApprovalModal}
      {appInReviewModalv2}
    </div>
  );
};

export default ApplicationsView;
