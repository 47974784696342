import { FC, useEffect, useMemo, useState } from "react";
import PageHeader from "../../_common/components/PageHeader";
import { useHistory } from "react-router-dom";
import { DefaultRouteProps } from "../../_common/types/router";
import { useAppDispatch, useAppSelector } from "../../_common/types/reduxHooks";
import "./ThreatMonitoringView.scss";
import {
  _debugUnsetCompletedOnboardingStepLocally,
  fetchUserData,
} from "../../_common/reducers/commonActions";
import UsersAPI from "../../_common/api/usersAPI";
import Button from "../../_common/components/core/Button";
import ThreatMonitoringFeed from "../components/ThreatMonitoringFeed";
import ReportCard from "../../_common/components/ReportCard";
import ThreatMonitoringTabs from "../components/ThreatMonitoringTabs";
import {
  GetCRSessionAttribute,
  SetCRSessionAttribute,
} from "../../_common/session";
import moment from "moment";
import { ThreatMonitoringFeedType } from "../api/types";
import {
  ExportFiletypeXLSX,
  ExportType,
} from "../../_common/types/exportReport";
import ExportReportModal from "../../vendorrisk/components/modals/ExportReportModal";
import InvestigatingFeed from "../components/InvestigatingFeed";
import ClosedFeed from "../components/ClosedFeed";
import RemediatingFeed from "../components/RemediatingFeed";
import tmSlice, { maxDatePeriod } from "../Slice";
import useFeedTotals from "../funcs/useFeedTotals";
import { areFiltersDefaultExcludingDate } from "../components/FilterPanel";
import WelcomeTour from "../components/WelcomeTour";
import { OnboardingStepName } from "../../_common/types/onboardingStepsMeta";
import { useCurrentOrg } from "../../_common/selectors/commonSelectors";
import DebugMenu from "../../_common/components/DebugMenu";

export enum Tab {
  Open = 0,
  Investigating = 1,
  Remediating = 2,
  Closed = 3,
}

const tabFeedMap = {
  [Tab.Open]: ThreatMonitoringFeedType.Open,
  [Tab.Investigating]: ThreatMonitoringFeedType.Investigating,
  [Tab.Remediating]: ThreatMonitoringFeedType.Remediating,
  [Tab.Closed]: ThreatMonitoringFeedType.Closed,
};

// getDateOfLastFeedDownload
// When viewing the threat monitoring feed, we keep track of when the user last refreshed their feed (manually). This is to give
// the user a feed list that they can keep track of (with known, controlled updates). They can work to reduce their current list of threats
// without the list being continually added to as new threats are discovered. We do this by storing the datetime of the last refresh
// in browser local storage. No, scrap that - we store it against the local session.
export const getDateOfLastFeedDownload = (): Date => {
  // let last = getLocalStorageItem("threatMonitoringLastFeedDownload", true);
  let last = GetCRSessionAttribute("threatMonitoringLastFeedDownload");
  if (!last) {
    last = resetDateOfLastFeedDownload();
  }
  return moment(last).toDate();
};

// resetDateOfLastFeedDownload
// When the threat feed is refreshed (manually or otherwie), we store the timestamp of the refresh against the current session.
export const resetDateOfLastFeedDownload = () => {
  const last = moment.utc().format();
  // setLocalStorageItem("threatMonitoringLastFeedDownload", last);
  SetCRSessionAttribute("threatMonitoringLastFeedDownload", last);
  return last;
};

interface ThreatMonitoringViewOwnProps extends DefaultRouteProps {}

// ThreatMonitoringView
// Represents the main landing page for the threat monitoring feature. At its core is a list of identified threats
// for the user to peruse, dismiss or action. This base component provides the welcome walkthrough modals and the tabbing implementation.
// @see ThreatMonitoringFeed, WelcomeModal
const ThreatMonitoringView: FC<ThreatMonitoringViewOwnProps> = ({
  location,
  match,
}) => {
  const { url } = match;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const org = useCurrentOrg();

  // which of our result lists are we currently viewing?
  const tabSelected = useAppSelector(tmSlice.selectors.currentTab);
  const pageQueries = useAppSelector(tmSlice.selectors.feedPages);

  const feedType = tabFeedMap[tabSelected];
  const filters = pageQueries[feedType].query.filters;

  // used to show/hide the exports modal
  const [exportModalOpen, setExportModalOpen] = useState(false);

  // used to show/hide the main welcome flow modal. we initialise this flag based on the state if the ThreatMonitoringFirstVisit
  // onboarding step. ie. has the flow been successfully completed / dismissed in the past?
  const displayWelcome = !org?.completedOnboardingSteps?.includes(
    OnboardingStepName.ThreatMonitoringFirstVisit
  );
  const pageHeaderBackAction = useMemo(
    () =>
      location.state?.backContext?.goBack
        ? history.goBack
        : location.state?.backContext
          ? () =>
              history.push(
                location.state.backContext?.backTo ?? "",
                location.state.backContext?.backToContext
              )
          : undefined,
    [history, location.state?.backContext]
  );

  const { totalOpen, totalInvestigating, totalClosed, totalRemediating } =
    useFeedTotals();

  // grab the current org's user data now as we'll need it all later and it would be better to cache it all before we get there..
  UsersAPI.useListOrganisationUserEmailAddressesV1Query();

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  // navigate to the threat monitoring settings tab for the account
  const gotoSettings = () => {
    const settingsUrl = `/settings/threatmonitoring`;
    history.push(settingsUrl, {
      backContext: {
        backTo: url,
        backToText: `Back to Threat Monitoring`,
      },
    });
  };

  // getExportTitle
  // determine the title for the export modal based on the selected tab
  const getExportTitle = (tabSelected: Tab): string => {
    return tabSelected == Tab.Open
      ? "Export Open Threats"
      : tabSelected == Tab.Investigating
        ? "Export Threats under Investigation"
        : tabSelected == Tab.Remediating
          ? "Export Threats under Remediation"
          : "Export Closed Threats";
  };

  const reportsDisabled =
    (feedType === ThreatMonitoringFeedType.Open && totalOpen == 0) ||
    (feedType === ThreatMonitoringFeedType.Closed && totalClosed == 0) ||
    (feedType === ThreatMonitoringFeedType.Investigating &&
      totalInvestigating == 0) ||
    (feedType === ThreatMonitoringFeedType.Remediating &&
      totalRemediating == 0);

  const welcomeMe = () => {
    dispatch(
      _debugUnsetCompletedOnboardingStepLocally(
        OnboardingStepName.ThreatMonitoringFirstVisit
      )
    );
  };

  const isFilterActive =
    areFiltersDefaultExcludingDate(filters, feedType) ||
    filters.datePeriod !== maxDatePeriod;

  const reportExportOptions = {
    ...filters,
    feedType,
  };

  return (
    <div className={"threat-monitoring-view"}>
      <PageHeader
        history={history}
        title={"Threat Monitoring"}
        backAction={pageHeaderBackAction}
        backText={location.state?.backContext?.backToText ?? undefined}
        infoSectionPageKey={"threatMonitoring"}
        infoSection={<p>This page provides access to identified threats.</p>}
        rightSection={
          <>
            <Button
              onClick={() => setExportModalOpen(true)}
              disabled={reportsDisabled}
            >
              <>
                <div className={"cr-icon-export-thin"} />
                {"Export"}
              </>
            </Button>
            <Button onClick={() => gotoSettings()}>
              <>
                <div className={"cr-icon-settings"} />
                {"Keywords & Settings"}
              </>
            </Button>
          </>
        }
      />
      <ReportCard newStyles className={"report-card"}>
        <ThreatMonitoringTabs
          onSelect={(tab) => {
            dispatch(tmSlice.actions.setCurrentTab(tab));
          }}
          selectedIdx={tabSelected}
          totalOpen={totalOpen}
          totalInvestigating={totalInvestigating}
          totalClosed={totalClosed}
          totalRemediating={totalRemediating}
        />
        <div className="feed-container">
          <ThreatMonitoringFeed
            hidden={tabSelected !== Tab.Open}
            feedType={ThreatMonitoringFeedType.Open}
          />
          <InvestigatingFeed hidden={tabSelected !== Tab.Investigating} />
          <RemediatingFeed hidden={tabSelected !== Tab.Remediating} />
          <ClosedFeed hidden={tabSelected !== Tab.Closed} />
        </div>
      </ReportCard>
      <WelcomeTour show={displayWelcome} />
      <ExportReportModal
        title={getExportTitle(tabSelected)}
        exportType={ExportType.ThreatMonitoringReport}
        isFilterActive={isFilterActive}
        supportedFilters={[]}
        active={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        exportOptions={reportExportOptions}
        supportedFileTypes={ExportFiletypeXLSX}
      />
      <DebugMenu>
        <Button onClick={welcomeMe}>Re-welcome Me</Button>
      </DebugMenu>
    </div>
  );
};

export default ThreatMonitoringView;
