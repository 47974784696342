import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import { FC } from "react";
import "../style/components/GradeWithScore.scss";

export enum GradeWithScoreSize {
  Small,
  Medium,
  Large,
}

interface GradeWithScoreProps {
  score: number;
  size?: GradeWithScoreSize;
  outOf?: number;
}

const GradeWithScore: FC<GradeWithScoreProps> = ({
  score,
  size = GradeWithScoreSize.Small,
  outOf,
}) => {
  let gradeSize: ColorGradeSize | undefined = undefined;
  switch (size) {
    case GradeWithScoreSize.Small:
      gradeSize = ColorGradeSize.Small;
      break;
    case GradeWithScoreSize.Medium:
      gradeSize = ColorGradeSize.Medium;
      break;
  }
  return (
    <div className="grade-with-score">
      <ColorGrade size={gradeSize} score={score} />
      <Score
        score={score}
        small={size === GradeWithScoreSize.Small}
        large={size === GradeWithScoreSize.Large}
        outOf={outOf}
        colored
      />
    </div>
  );
};

export default GradeWithScore;
