import { useDefaultHistory } from "../../../_common/types/router";
import UserBaseAPI from "../../api/userbase.api";
import ReportCard from "../../../_common/components/ReportCard";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { FC } from "react";
import { userRiskProfileUrl, userSummaryUrl } from "../../UserBaseAppRouter";
import SeverityIcon from "../../../_common/components/SeverityIcon";
import { pluralise } from "../../../_common/helpers";
import { SeverityAsString, SeverityInt } from "../../../_common/types/severity";
import TickShieldIMG from "../../images/tick-shield.svg";

import "./UserRisksOverview.scss";

interface UserRisksOverviewProps {
  userUUID: string;
}

const UserRisksOverview: FC<UserRisksOverviewProps> = ({ userUUID }) => {
  const history = useDefaultHistory();

  const { data: userSummary } = UserBaseAPI.useGetUserBaseUserSummaryV1Query({
    userUUID: userUUID,
  });

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserRiskProfileV1Query({
    userUUID: userUUID,
  });

  const onViewAll = () => {
    history.push(userRiskProfileUrl(userUUID), {
      backContext: {
        backTo: userSummaryUrl(userUUID),
        backToText: `Back to ${userSummary?.user?.name}`,
      },
    });
  };

  if (!isLoading && !data?.risks) {
    return <></>;
  }

  const risksToUse = data?.risks ?? [];

  // Count the number of risks per severity. We initialize the map with 0 for each severity
  // so they will show up on the UI even if there are no risks of that severity to match the
  // behaviour for user risks
  const severityMap: Map<SeverityInt, number> = new Map([
    [SeverityInt.LowSeverity, 0],
    [SeverityInt.MediumSeverity, 0],
    [SeverityInt.HighSeverity, 0],
    [SeverityInt.CriticalSeverity, 0],
  ]);

  risksToUse.forEach((risk) => {
    // We don't want to count pass risks
    if (risk.severity > SeverityInt.InfoSeverity && !risk.isWaived) {
      const severity = risk.severity;
      const numRisks = severityMap.get(severity) ?? 0;
      severityMap.set(severity, numRisks + 1);
    }
  });

  // Put map into an array for easier processing
  const risksBySeverity = Array.from(severityMap.entries()).map(
    ([severity, numRisks]) => {
      return {
        severity,
        numRisks,
      };
    }
  );

  // Sort the risks by severity
  risksBySeverity.sort((a, b) => b.severity - a.severity);

  const totalRisks = risksBySeverity.reduce((acc, r) => acc + r.numRisks, 0);

  // Show different types of content depending on whether there are risks or not
  const content =
    totalRisks !== 0 ? (
      <div className={"content"}>
        <div className={"subtitle"}>Current risks by severity</div>
        <div className={"breakdown"}>
          {risksBySeverity.map((r) => {
            const numberOfRisksToDisplay =
              r.numRisks === 0
                ? "-"
                : `${r.numRisks} ${pluralise(r.numRisks, "risk", "risks")}`;

            return (
              <div key={r.severity} className={"risk-severity"}>
                <SeverityIcon severity={SeverityAsString(r.severity)} label />
                {numberOfRisksToDisplay}
              </div>
            );
          })}
        </div>
      </div>
    ) : (
      <div className={"content-no-risks"}>
        <img src={TickShieldIMG} width={100} height={100} />
        <div className={"subtitle"}>No risks found</div>
      </div>
    );

  return (
    <ReportCard newStyles className="userbase-user-risks-overview">
      <div className="header">
        <span>Risks</span>
        <div className={"header-link"}>
          <Button tertiary onClick={onViewAll}>
            View all <div className="cr-icon-arrow-right" />
          </Button>
        </div>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && content}
    </ReportCard>
  );
};

export default UserRisksOverview;
