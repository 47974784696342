import { ComponentProps, ReactNode, useEffect, useRef, useState } from "react";
import { SidePopupV2 } from "./DismissablePopup";
import { debounce } from "lodash";
import "../style/components/EllipsizedText.scss";
import classNames from "classnames";
import { useDebounceCallback } from "@react-hook/debounce";
import useResizeObserver, {
  UseResizeObserverCallback,
} from "@react-hook/resize-observer";

/**
 * Omit the "inline" prop inherited from SidePopupV2 because
 * that prevents the content from ellipsizing
 */
export interface EllipsizedTextProps
  extends Omit<ComponentProps<typeof SidePopupV2>, "inline"> {
  /**
   * This component requires a single child element
   */
  children: JSX.Element | string;
}

function computeContentRequiresPopup(element: HTMLElement | null): boolean {
  if (!element) return false;

  // Precaution in case `style=...` was being used
  const originalWidthStyle = element.style.width;
  element.style.width = "max-content";
  const maxBounds = element.getBoundingClientRect();
  element.style.width = originalWidthStyle;

  const cssBounds = element.getBoundingClientRect();

  return maxBounds.width > cssBounds.width;
}

/**
 * EllipsizedText allows you to show popup content on hover when an element is
 * being ellipsized by CSS - also on resize. Ideally suited to single-line ellipses.
 *
 * ❗
 * If you have issues, review the following:
 *
 * → Check Storybook stories (various options to try out): `frontends/_common/components/EllipsizedText.stories.tsx`
 *
 * → CSS Tricks demo (flexbox): https://css-tricks.com/flexbox-truncated-text/#aa-demo
 * ```
 *
 * ℹ️
 * CSS rules for `overflow: hidden`, `text-overflow: ellipsis` and
 * `white-space: nowrap` will be applied to the immediate child element, whatever
 * element that happens to be.
 *
 * 🛑
 * This component makes a reasonably strong attempt to take accurate measurements
 * but there may be some situations or browsers where sub-pixel rounding is
 * responsible for the component to misbehave.
 */
export default function EllipsizedText({
  children,
  text,
  className,
  popupClassName,
  ...props
}: EllipsizedTextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [shouldDisplayPopup, setShouldDisplayPopup] = useState<boolean>(false);

  useEffect(function handleResize() {
    if (!ref.current) return;

    const observer = new ResizeObserver(
      debounce<ResizeObserverCallback>(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
              const result = computeContentRequiresPopup(entry.target);
              setShouldDisplayPopup(result);
            }
          });
        },
        100,
        { leading: false, trailing: true }
      )
    );

    const current = ref.current;
    observer.observe(current);
    return () => observer.unobserve(current);
  }, []);

  return (
    <SidePopupV2
      className={classNames("ellipsized-text-container", className)}
      popupClassName={classNames("ellipsized-text-popup", popupClassName)}
      popupHoverable
      // Control whether any popup should display
      text={shouldDisplayPopup ? text : undefined}
      // Apply sensible default props
      // generally try to fit the whole content without wrapping...
      width="max-content"
      // Smother overrides from any supplied props
      {...props}
    >
      <div className="ellipsized-text" ref={ref}>
        {children}
      </div>
    </SidePopupV2>
  );
}

export function useElementOverflowObserver() {
  const [target, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [overflowing, setOverflowing] = useState<boolean>(false);

  const observe: UseResizeObserverCallback = (entry) => {
    if (entry.target instanceof HTMLElement) {
      const result = computeContentRequiresPopup(entry.target);
      setOverflowing(result);
    }
  };

  const observeDebounced = useDebounceCallback(observe, 100);

  useResizeObserver(target, observeDebounced);

  return { ref: setTargetRef, overflowing };
}

export interface PopupOnOverflowProps {
  popupProps?: Omit<ComponentProps<typeof SidePopupV2>, "inline" | "text">;
  children?: ReactNode;
  text?: ReactNode;
  className?: string;
}

// PopupOnOverflow is a generalised version of EllipsizedText.
// It allows you to show popup content on hover when the wrapped children are overflowing, and the overflowed size is smaller than the content size.
//
// Overflows are detected by briedly applying `width: max-content` to the wrapped element and comparing with the displayed size.
//
// This allows you to show a popup over a component whose overflowing behaviour is complex or composite.
//
// A general approach to using this:
// 1. Make sure your component can be overflowed correctly when it's content is too big.
// This often means
// - setting `min-width: 0px` and `overflow: hidden`. In flexbox, play with `flex`. In tables, set 'table-layout: fixed'.
//
// 2. Apply any CSS to style the overflowed content.
// This usually means setting `text-overflow: ellipsis`, `white-space: nowrap`, and `overflow: hidden`.
//
// 3. Wrap your component in PopupOnOverflow to show the full, un-overflowed text in a tooltip.
// Set `text` to the full, un-overflowed text you want to show in the popup. For simple content, `children` can be used for the content of the popup too.
//
// Popup content: text or children
// Child content: children or text
//
export function PopupOnOverflow({
  children,
  text,
  popupProps,
  className,
}: PopupOnOverflowProps) {
  const { ref, overflowing } = useElementOverflowObserver();

  return (
    <SidePopupV2
      popupHoverable
      // Control whether any popup should display
      text={overflowing ? text ?? children : undefined}
      // Apply sensible default props
      // generally try to fit the whole content without wrapping...
      width="max-content"
      // Smother overrides from any supplied props
      {...popupProps}
    >
      <div className={className} ref={ref}>
        {children ?? text}
      </div>
    </SidePopupV2>
  );
}
