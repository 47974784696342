import { FC, useState } from "react";
import PageHeader from "../../../_common/components/PageHeader";
import { useHistory } from "react-router-dom";
import { useBack } from "../../../_common/types/router";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../../_common/permissions";
import {
  RemediationRequestTableV2Mode,
  RemediationRequestTableV2,
} from "../../../vendorrisk/components/RemediationRequestTableV2";
import UserBaseAPI from "../../api/userbase.api";
import {
  ExportFiletypesBoth,
  ExportType,
} from "../../../_common/types/exportReport";
import RemediationExportOptions, {
  defaultRemediationExportCustomFilter,
} from "../../../vendorrisk/components/RemediationExportOptions";
import ExportReportModal from "../../../vendorrisk/components/modals/ExportReportModal";
import Button from "../../../_common/components/core/Button";

const RemediationsView: FC = () => {
  const history = useHistory();
  const { backAction, backText } = useBack();
  const dispatch = useAppDispatch();

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [customExportFilter, setCustomExportFilter] = useState(
    defaultRemediationExportCustomFilter()
  );

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const { data, isFetching } = UserBaseAPI.useGetUserBaseRemediationsV1Query();

  const requests = data?.requests.map((r) => ({ details: r })) ?? [];
  const countsToUse = data?.count;

  return (
    <div id="userrisk-remediations">
      <PageHeader
        history={history}
        title="Remediation"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey={"userriskRemediations"}
        rightSection={
          <>
            <Button onClick={() => setExportModalOpen(true)}>
              <div className={"cr-icon-export"} />
              Export
            </Button>
          </>
        }
        infoSection={
          <p>
            This page allows you to create, see, and respond to remediation
            requests. Each remediation request outlines risks that need to be
            remediated, the requester, any correspondence, and more.
          </p>
        }
      />
      <RemediationRequestTableV2
        dispatch={dispatch}
        remediationRequests={requests}
        remediationRequestIdsLoading={isFetching}
        userHasWriteRemediationPermission={userHasUserBaseWrite}
        mode={RemediationRequestTableV2Mode.userrisk}
      />
      <ExportReportModal
        exportType={ExportType.RemediationRequests}
        supportedFilters={[]}
        supportedFileTypes={ExportFiletypesBoth}
        active={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        exportOptions={{
          is_for_subsidiaries: false,
          is_for_self_remediation: false,
          is_userrisk_remediation: true,
          ...customExportFilter,
        }}
        exportDisabled={
          !(
            customExportFilter.include_archived ||
            customExportFilter.include_completed ||
            customExportFilter.include_in_progress ||
            customExportFilter.include_awaiting_review
          ) || !countsToUse
        }
        title={"Export remediation requests"}
      >
        <RemediationExportOptions
          customFilter={customExportFilter}
          updateCustomFilter={(f) => setCustomExportFilter(f)}
          counts={countsToUse}
          isUserRisk={true}
        />
      </ExportReportModal>
    </div>
  );
};

export default RemediationsView;
