import * as React from "react";
import classnames from "classnames";

import "../style/components/InfoBanner.scss";
import Icon from "../../_common/components/core/Icon";
import Button from "../../_common/components/core/Button";

export enum BannerType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
  CHAT = "chat",
  QUESTION = "question",
}

export enum SubItemType {
  PARAGRAPH = "paragraph",
  BULLET = "bullet",
  NUMBER = "number",
  OTHER = "other",
}

export interface IInfoBannerProps {
  className?: string;
  message?: React.ReactNode;
  subItems?: React.ReactNode[];
  subItemType?: SubItemType;
  linkText?: string;
  linkURL?: string;
  linkSameTab?: boolean;
  type?: BannerType;
  onDismiss?: () => void;
  centered?: boolean;
  overrideIcon?: BannerType;
  disableIcon?: boolean;
  button?: React.ReactNode;
}

const InfoBanner = (props: IInfoBannerProps) => {
  const {
    className,
    message,
    subItems,
    linkText,
    linkURL,
    linkSameTab,
    type,
    onDismiss,
    subItemType,
    centered,
    button,
  } = props;

  const classes: { [x: string]: boolean } = {
    border: true,
    centered: !!centered,
  };
  if (type) {
    const typeStr = type.toString();
    classes[typeStr] = true;
  }

  let subItemDisplay: JSX.Element | undefined = undefined;
  if (subItems && subItems.length > 0) {
    let subItemDisplays;
    if (
      subItemType === SubItemType.NUMBER ||
      subItemType === SubItemType.BULLET
    ) {
      subItemDisplays = subItems?.map((item, idx) => <li key={idx}>{item}</li>);
    } else if (subItemType === SubItemType.PARAGRAPH) {
      subItemDisplays = subItems?.map((item, idx) => <p key={idx}>{item}</p>);
    } else {
      subItemDisplays = subItems.map((item, idx) => (
        <React.Fragment key={idx}>{item}</React.Fragment>
      ));
    }

    switch (subItemType) {
      case SubItemType.NUMBER:
        subItemDisplay = (
          <div className={"info-banner-block"}>
            <ol>{subItemDisplays}</ol>
          </div>
        );
        break;
      case SubItemType.BULLET:
        subItemDisplay = (
          <div className={"info-banner-block"}>
            <ul>{subItemDisplays}</ul>
          </div>
        );
        break;
      default:
        subItemDisplay = (
          <div className={"info-banner-block"}>{subItemDisplays}</div>
        );
        break;
    }
  }

  const noDismiss = !button && !onDismiss;

  return (
    <div className={classnames("info-banner", className, classes)}>
      <div className={"info-banner-line"}>
        {!props.disableIcon && (
          <div
            className={iconClassMap.get(props.overrideIcon ?? props.type ?? "")}
          />
        )}
        {message && <div className={"message"}>{message}</div>}
        <div
          className={classnames("info-banner-actions", {
            "no-margin": noDismiss,
          })}
        >
          {linkURL !== "" && linkText !== "" && (
            <div className={"link"}>
              {linkSameTab && <a href={linkURL}>{linkText}</a>}
              {!linkSameTab && (
                <a href={linkURL} target="_blank" rel="noreferrer noopener">
                  {linkText}
                  <i className="btn-arrow icon-arrow rotate-90" />
                </a>
              )}
            </div>
          )}
          {button && <div className={"button"}>{button}</div>}
          {onDismiss && (
            <Button className={"dismiss"} tertiary onClick={onDismiss}>
              <Icon name="x" />
            </Button>
          )}
        </div>
      </div>
      {subItemDisplay}
    </div>
  );
};

const iconClassMap = new Map<BannerType | "", string>([
  ["", "cr-icon-info"],
  [BannerType.INFO, "cr-icon-info"],
  [BannerType.WARNING, "cr-icon-risk"],
  [BannerType.ERROR, "cr-icon-risk"],
  [BannerType.SUCCESS, "cr-icon-accepted"],
  [BannerType.CHAT, "cr-icon-chat-messages"],
  [BannerType.QUESTION, "cr-icon-question-alert"],
]);

InfoBanner.defaultProps = {
  className: "",
  message: "",
  subItems: [],
  border: true,
  linkURL: "",
  linkText: "",
  linkSameTab: false,
  type: BannerType.INFO,
};

export default InfoBanner;
