import { FC, useState } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import ReportCard from "../../../_common/components/ReportCard";
import { useSortingWithPagination } from "../../../_common/hooks";
import { AppScope } from "../../api/types";
import ScopeRiskLevelDisplay from "../permissions/ScopeRiskLevelDisplay";
import AppPermissionUser from "./AppPermissionsExpandedTable";
import _ from "lodash";

import UserBaseAPI from "../../api/userbase.api";
import "./AppPermissions.scss";
import EmptyCard from "../../../vendorrisk/components/EmptyCard";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import SearchBox from "../../../_common/components/SearchBox";
import { riskLevelString } from "../../helpers/scopes";

const PAGE_SIZE = 50;
const EXPOSURE_LEVEL_COL = "exposure_level_col";
const PERMISSION_NAME_COL = "permission_name_col";
const NUM_USERS_FOR_PERMISSION_COL = "num_users_for_permissions_col";

interface AppPermissionsProps {
  appName: string;
}

const AppPermissions: FC<AppPermissionsProps> = ({ appName }) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppScopesV1Query({
    app: appName,
  });

  const [searchText, setSearchText] = useState("");

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: EXPOSURE_LEVEL_COL,
      text: "Exposure level",
      sortable: true,
    },
    {
      id: PERMISSION_NAME_COL,
      text: "Description",
      sortable: true,
    },
    {
      id: NUM_USERS_FOR_PERMISSION_COL,
      text: "Users",
      sortable: true,
    },
  ];

  const scopes = data?.scopes ?? [];
  const filteredScopes = scopes.filter(
    (scope) =>
      scope.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
      scope.description
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||
      riskLevelString(scope.riskLevel)
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase()) ||
      (scope.category ?? "")
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase())
  );

  const [
    sortedPermissions,
    sortedBy,
    onSortChange,
    currentPageNumber,
    totalPages,
    setCurrentPage,
  ] = useSortingWithPagination<
    AppScope,
    | typeof EXPOSURE_LEVEL_COL
    | typeof PERMISSION_NAME_COL
    | typeof NUM_USERS_FOR_PERMISSION_COL
  >(
    filteredScopes,
    EXPOSURE_LEVEL_COL,
    SortDirection.DESC,
    {
      [PERMISSION_NAME_COL]: {
        orderFuncs: [(p) => p.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [EXPOSURE_LEVEL_COL]: {
        orderFuncs: [(p) => p.riskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NUM_USERS_FOR_PERMISSION_COL]: {
        orderFuncs: [(p) => p.numUsers],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE
  );

  // Keep track of which users' rows are expanded
  const [expanded, setExpanded] = useState<Set<string>>(new Set());

  const toggleRowExpansion = (s: AppScope) => {
    const newExpanded = _.clone(expanded);
    if (newExpanded.has(s.name)) {
      newExpanded.delete(s.name);
    } else {
      newExpanded.add(s.name);
    }
    setExpanded(newExpanded);
  };

  if (!isLoading && data?.scopes === undefined) {
    return <></>;
  }

  const rows = sortedPermissions.map((p: AppScope): IXTableRow<string> => {
    return {
      id: p.externalID,
      onClick: () => toggleRowExpansion(p),
      expanded: expanded.has(p.name),
      expandContent: (
        <AppPermissionUser scopeId={p.externalID} appName={appName} />
      ),
      cells: [
        <XTableCell key={EXPOSURE_LEVEL_COL}>
          <ScopeRiskLevelDisplay riskLevel={p.riskLevel} />
        </XTableCell>,
        <XTableCell
          className="description-with-subtext"
          key={PERMISSION_NAME_COL}
        >
          <div className="description-maintext">{p.description}</div>
          <div className="description-subtext">{p.name}</div>
        </XTableCell>,
        <XTableCell key={NUM_USERS_FOR_PERMISSION_COL}>
          {p.numUsers}
        </XTableCell>,
      ],
    };
  });

  const isEmptyResult =
    !isLoading && !searchText && sortedPermissions.length === 0;
  const isEmptySearch =
    !isLoading && !!searchText && sortedPermissions.length === 0;

  return (
    <ReportCard newStyles>
      <SearchBox
        placeholder={"Search permissions"}
        onChanged={(q) => {
          setCurrentPage(1);
          setSearchText(q);
        }}
        value={searchText}
      />
      {isEmptyResult && (
        <EmptyCard
          text={"There are no permissions for this application at this time."}
        />
      )}
      {isEmptySearch && (
        <SearchEmptyCard
          clearText={"Clear filter"}
          onClear={() => {
            setCurrentPage(1);
            setSearchText("");
          }}
          searchItemText={"permission"}
        />
      )}

      {!isEmptyResult && !isEmptySearch && (
        <XTable
          stickyColumnHeaders={false}
          numLoadingRows={3}
          className={"app-permissions"}
          columnHeaders={columnHeaders}
          rows={rows}
          loading={isLoading}
          iconOptions
          expandableRows
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          pagination={{
            currentPage: currentPageNumber,
            totalPages: totalPages,
            onPageChange: setCurrentPage,
            hidePaginationIfSinglePage: true,
          }}
        />
      )}
    </ReportCard>
  );
};

export default AppPermissions;
