import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import "../../style/components/AddEvidencePageModal.scss";
import { useState } from "react";
import ModalV2 from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { vendorEvidencePagesSupportUrl } from "../shared_profile/SharedProfileEditEvidenceModal";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import { EvidenceCategory, VendorEvidencePage } from "../../types/evidence";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import { SelectV2 } from "../../../_common/components/SelectV2";
import {
  evidenceCategoryToExampleUrl,
  evidenceCategoryToStr,
} from "../shared_profile/SharedProfileEvidencePagesTable";
import TextField from "../../../_common/components/TextField";
import RichTextEditV2 from "../../../_common/components/RichTextEditV2";
import { errorMessagesForUrl } from "../../views/EvidencePageDetails";
import { validateUrlWithProtocol } from "../../../_common/helpers";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { updateEvidencePage } from "../../reducers/vendorEvidencePages.actions";

interface AddEvidencePageModalProps {
  active: boolean;
  onClose: () => void;
  assuranceType: AssuranceType;
  vendorId: number;
  evidencePages?: VendorEvidencePage[];
  onAddSuccess?: (category: EvidenceCategory, url: string) => void; // Callback to execute when a new evidence page is added successfully
}

const AddEvidencePageModal = (
  props: AddEvidencePageModalProps & DefaultThunkDispatchProp
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [category, setCategory] = useState(EvidenceCategory.Security);
  const [url, setUrl] = useState("");
  const [comments, setComments] = useState("");

  const saveEdit = () => {
    setIsSaving(true);

    // Check we aren't creating a duplicate category + URL record
    const existingIdx =
      props.evidencePages?.findIndex(
        (ev) =>
          ev.category === category && ev.url.toLowerCase() === url.toLowerCase()
      ) ?? -1;
    if (existingIdx > -1) {
      setIsSaving(false);
      props.dispatch(
        addSimpleErrorAlert(
          "A security and privacy page with that category and URL already exists"
        )
      );
      return;
    }

    const updateEvidenceRequest = {
      vendorId: props.vendorId,
      oldCategory: category,
      oldUrl: url,
      newCategory: category,
      newUrl: url,
      newNote: comments,
    };

    props
      .dispatch(updateEvidencePage(updateEvidenceRequest))
      .then(() => {
        props.dispatch(
          addDefaultSuccessAlert("Successfully added security and privacy page")
        );

        if (props.onAddSuccess) {
          props.onAddSuccess(category, url);
        }
      })
      .catch(() => {
        props.dispatch(
          addDefaultUnknownErrorAlert("Error adding security and privacy page")
        );
      })
      .finally(() => {
        props.onClose();
      });
  };

  const vendorWords = getVendorWords(props.assuranceType);
  const evidenceIsValid = category && validateUrlWithProtocol(url);

  return (
    <ModalV2
      className="add-evidence-page-modal"
      active={props.active}
      onClose={props.onClose}
      headerContent="Add security and privacy page"
      footerContent={
        <>
          <Button tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            primary
            onClick={saveEdit}
            loading={isSaving}
            disabled={!evidenceIsValid}
          >
            Add page
          </Button>
        </>
      }
    >
      <div className={"description"}>
        <p className="description-text">
          These links can be used as supplementary evidence for others
          performing vendor risk assessments, questionnaires and remediation
          flows.
        </p>
        <Button
          tertiary
          onClick={() => window.open(vendorEvidencePagesSupportUrl)}
        >
          <span className="description-link">
            Learn more about security and privacy pages{" "}
            <div className="cr-icon-arrow-right" />
          </span>
        </Button>
      </div>
      <div className={"content"}>
        <div className="input-header">Category</div>
        <SelectV2
          options={Object.values(EvidenceCategory).map((c) => ({
            value: c,
            label: evidenceCategoryToStr(c),
          }))}
          onChange={(val) => {
            if (val) {
              setCategory(val.value as EvidenceCategory);
            }
          }}
          value={{
            value: category,
            label: evidenceCategoryToStr(category),
          }}
        />
        <div className="input-header">URL</div>
        <TextField
          value={url ?? ""}
          onChanged={(val) => {
            setUrl(val);
          }}
          placeholder="Enter a URL"
          errorsTimeout={0}
          errorTexts={url ? errorMessagesForUrl(url) : undefined}
          infoText={`E.g. ${evidenceCategoryToExampleUrl(category)}`}
        />
        <div className="input-header comments">
          Comments
          <SidePopupV2
            position={"right"}
            text={`Record your notes and observations about the content of the page. Note anything specific that might relate to your risk assessment of this ${vendorWords.singular}.`}
          >
            <span className="cr-icon-info" />
          </SidePopupV2>
        </div>
        <RichTextEditV2
          value={comments}
          placeholder={"Record your notes and observations about this page"}
          onChange={(val) => {
            setComments(val);
          }}
          className="evidence-page-comments-rte"
        />
      </div>
    </ModalV2>
  );
};

export default AddEvidencePageModal;
