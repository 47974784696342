import {
  ISurveyListItemResponse,
  SurveyRiskVisibility,
  SurveyStatus,
} from "../../types/survey";
import { DefaultThunkDispatchProp } from "../../types/redux";
import { FC, useEffect, useState } from "react";
import {
  UserSurveyShareAnswersWithOrg,
  UserWritePrefilledSurveys,
  UserWriteSurveyImportExport,
} from "../../permissions";
import {
  updateVendorSurveyAddToSharedProfile,
  updateVendorSurveyShareAnswersWithOrg,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import { fetchSurveyDetails } from "../../reducers/surveyDetails.actions";
import { addDefaultUnknownErrorAlert } from "../../reducers/messageAlerts.actions";
import { surveySubmitted } from "../../views/SurveyDetails";
import { SurveyUsageType } from "../../types/surveyTypes";
import ReportCard from "../ReportCard";
import ToggleSwitch from "../core/ToggleSwitch";
import ConfirmationModalV2 from "../modals/ConfirmationModalV2";
import "../../style/components/SurveyDetailsOptionsCard.scss";
import { appConnect } from "../../types/reduxHooks";
import { SidePopupV2 } from "../DismissablePopup";
import TrustPageAPI, {
  TrustPageTagTypes,
} from "../../../verifiedvendors/api/trustpage.api";

interface ISurveyDetailsOptionsCardOwnProps {
  isVendorPortal: boolean;
  survey: ISurveyListItemResponse;
}

interface ISurveyDetailsOptionsCardConnectedProps {
  userIsInVerifiedOrg: boolean;
  userHasWritePrefilledSurveysInVerifiedOrg: boolean;
  userHasAnswerShareInVerifiedOrg: boolean;
  userHasWriteSurveyImportExportPermission: boolean;
  userIsListedRecipient: boolean;
}

type ISurveyDetailsOptionsCardProps = ISurveyDetailsOptionsCardOwnProps &
  ISurveyDetailsOptionsCardConnectedProps &
  DefaultThunkDispatchProp;

const SurveyDetailsOptionsCard: FC<ISurveyDetailsOptionsCardProps> = ({
  dispatch,
  isVendorPortal,
  survey,
  userIsInVerifiedOrg,
  userHasWritePrefilledSurveysInVerifiedOrg,
  userHasAnswerShareInVerifiedOrg,
  userHasWriteSurveyImportExportPermission,
  userIsListedRecipient,
}) => {
  const [addToSharedProfileToggle, setAddToSharedProfileToggle] = useState(
    survey?.addToSharedProfile || false
  );
  const [toggleOnAddToSharedProfileModal, setToggleOnAddToSharedProfileModal] =
    useState(false);
  const [
    toggleOffAddToSharedProfileModal,
    setToggleOffAddToSharedProfileModal,
  ] = useState(false);

  const [shareAnswersWithOrgToggle, setShareAnswersWithOrgToggle] = useState(
    survey.answersSharedWithVerifiedOrg ?? false
  );

  // Make sure we keep the toggles up to date when the survey data updates
  useEffect(() => {
    if (survey) {
      setAddToSharedProfileToggle(survey.addToSharedProfile);
      setShareAnswersWithOrgToggle(
        survey.answersSharedWithVerifiedOrg ?? false
      );
    }
  }, [survey]);

  const asyncSetAddToSharedProfile = async (
    newVal: boolean,
    refreshSharedProfile = false
  ) => {
    setAddToSharedProfileToggle(newVal);
    return dispatch(updateVendorSurveyAddToSharedProfile(survey.id, newVal))
      .then(() => dispatch(fetchSurveyDetails(survey.id, true)))
      .then(() => {
        // Refresh the shared profile in the background if necessary
        if (refreshSharedProfile) {
          dispatch(
            TrustPageAPI.util.invalidateTags([TrustPageTagTypes.ownTrustPage])
          );
        }
      })
      .catch((e) => {
        console.error(e);
        setAddToSharedProfileToggle(!newVal);
        dispatch(addDefaultUnknownErrorAlert("Error updating questionnaire"));
      });
  };

  const toggleAddToSharedProfile = () => {
    if (surveySubmitted(survey.status)) {
      // If the survey has already been submitted, we need to give the user more detail on what's going to happen,
      // via confirmation modals.
      if (addToSharedProfileToggle) {
        setToggleOffAddToSharedProfileModal(true);
      } else {
        setToggleOnAddToSharedProfileModal(true);
      }
      return;
    }

    // We can just set the toggle immediately without asking the user anything.
    // Update the toggle and refetch the survey in the background
    const newVal = !addToSharedProfileToggle;
    asyncSetAddToSharedProfile(newVal, true);
  };

  const setShareAnswersWithOrg = async () => {
    const newVal = !survey.answersSharedWithVerifiedOrg;
    setShareAnswersWithOrgToggle(newVal);

    try {
      await dispatch(updateVendorSurveyShareAnswersWithOrg(survey.id, newVal));
      await dispatch(fetchSurveyDetails(survey.id, true));
    } catch (e) {
      console.error(e);
      setShareAnswersWithOrgToggle(!newVal);
      dispatch(addDefaultUnknownErrorAlert("Error updating questionnaire"));
    }
  };

  const showAddToSharedProfileButton =
    isVendorPortal &&
    (survey.isSystemSurvey || survey.isImportedSurvey) &&
    userIsInVerifiedOrg &&
    survey.status !== SurveyStatus.Cancelled &&
    !survey.archived &&
    survey.usageType === SurveyUsageType.Security &&
    survey.riskVisibility === SurveyRiskVisibility.Visible;

  const canAddToSharedProfile =
    showAddToSharedProfileButton &&
    ((!survey.isImportedSurvey && userHasWritePrefilledSurveysInVerifiedOrg) ||
      (survey.isImportedSurvey &&
        userHasWritePrefilledSurveysInVerifiedOrg &&
        userHasWriteSurveyImportExportPermission));

  // In order for a user to share answers with their verified org, the current user needs to not be a limited user,
  // and also a listed recipient on the questionnaire.
  const showShareAnswersWithOrgToggle =
    isVendorPortal &&
    userIsInVerifiedOrg &&
    userHasAnswerShareInVerifiedOrg &&
    !survey.isPrefilledSurvey &&
    survey.usageType === SurveyUsageType.Security &&
    userIsListedRecipient;

  if (!showAddToSharedProfileButton && !showShareAnswersWithOrgToggle) {
    return null;
  }

  return (
    <ReportCard newStyles className="survey-details-options-card">
      <div className="header">Options</div>
      <div className="options-content">
        {showAddToSharedProfileButton && (
          <>
            <div className="option-toggle">
              <SidePopupV2
                position={"left"}
                text={
                  !userHasWritePrefilledSurveysInVerifiedOrg
                    ? "You currently only have read access to your Trust Page. Administrators of your company's account have been notified and may grant you full access."
                    : !survey.isImportedSurvey
                      ? ""
                      : userHasWriteSurveyImportExportPermission
                        ? ""
                        : "You currently only have read access to imported questionnaires. Administrators of your company's account may grant you full access."
                }
              >
                <ToggleSwitch
                  name="add-to-shared-profile"
                  selected={addToSharedProfileToggle}
                  onClick={toggleAddToSharedProfile}
                  disabled={!canAddToSharedProfile}
                />
              </SidePopupV2>{" "}
              Include in Trust Page{" "}
              <a
                href="https://help.upguard.com/en/articles/3797568-what-is-an-upguard-trust-page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SidePopupV2
                  position={"left"}
                  text={"Click for more information about Trust Pages"}
                >
                  <div className="cr-icon-info" />
                </SidePopupV2>
              </a>
            </div>
            <ConfirmationModalV2
              title="Include in security profile?"
              description="The submitted answers on this questionnaire will be immediately published on your Trust Page."
              buttonText="Confirm"
              cancelText="Cancel"
              buttonAction={async () => {
                await asyncSetAddToSharedProfile(true, true);
              }}
              active={toggleOnAddToSharedProfileModal}
              onClose={() => setToggleOnAddToSharedProfileModal(false)}
            />
            <ConfirmationModalV2
              title="Stop including in security profile?"
              description="This questionnaire will be removed from your Trust Page."
              buttonText="Confirm"
              cancelText="Cancel"
              buttonAction={async () => {
                await asyncSetAddToSharedProfile(false, true);
              }}
              active={toggleOffAddToSharedProfileModal}
              onClose={() => setToggleOffAddToSharedProfileModal(false)}
            />
          </>
        )}
        {showShareAnswersWithOrgToggle && (
          <>
            <div className="option-toggle">
              <ToggleSwitch
                name="share-answers"
                selected={shareAnswersWithOrgToggle}
                onClick={setShareAnswersWithOrg}
              />{" "}
              Allow others in my account to re-use answers from this
              questionnaire{" "}
              <a
                href="https://help.upguard.com/en/articles/6434680-how-to-autofill-a-questionnaire-based-on-your-previous-responses"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SidePopupV2
                  position={"left"}
                  text={
                    "When enabled, other people in your account will be able to draw on answers from this questionnaire when autofilling future questionnaires. Click here for more information."
                  }
                >
                  <div className="cr-icon-info" />
                </SidePopupV2>
              </a>
            </div>
          </>
        )}
      </div>
    </ReportCard>
  );
};

export default appConnect<
  ISurveyDetailsOptionsCardConnectedProps,
  never,
  ISurveyDetailsOptionsCardOwnProps
>((state, props) => {
  let userIsInVerifiedOrg = false;
  let userHasWritePrefilledSurveysInVerifiedOrg = false;
  let userHasAnswerShareInVerifiedOrg = false;
  let userHasWriteSurveyImportExportPermission = false;

  if (state.common.userData.orgList) {
    // Look through the user's org and see if they are part of the verified org for this survey.
    for (let i = 0; i < state.common.userData.orgList.length; i++) {
      const org = state.common.userData.orgList[i];
      if (
        org.isVerifiedVendor &&
        (org.verifiedDatastoreVendorID === props.survey.vendorId ||
          (props.survey.isImportedSurvey &&
            org.id === props.survey.organisationId))
      ) {
        userIsInVerifiedOrg = true;
        userHasWritePrefilledSurveysInVerifiedOrg =
          org.permissions?.includes(UserWritePrefilledSurveys) ?? false;
        userHasAnswerShareInVerifiedOrg =
          org.permissions?.includes(UserSurveyShareAnswersWithOrg) ?? false;
        userHasWriteSurveyImportExportPermission =
          org.permissions?.includes(UserWriteSurveyImportExport) ?? false;
      }
    }
  }

  const userIsListedRecipient = props.survey.sharedWith.some(
    (u) => u.id === state.common.userData.id
  );

  return {
    userIsInVerifiedOrg,
    userHasWritePrefilledSurveysInVerifiedOrg,
    userHasAnswerShareInVerifiedOrg,
    userHasWriteSurveyImportExportPermission,
    userIsListedRecipient,
  };
})(SurveyDetailsOptionsCard);
