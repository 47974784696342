import { FC, useEffect, useState } from "react";
import moment from "moment";
import Icon from "../../_common/components/core/Icon";
import { RiskProfileRiskTypes } from "../../_common/constants";
import XTable, { XTableCell } from "../../_common/components/core/XTable";
import CVEMiniList, {
  GetCPEFromRiskID,
  GetCVEFromRiskID,
  IsCPERisk,
  IsCVERisk,
  IsVerifiedCVE,
} from "./CVEMiniList";
import "../style/components/RiskSummaryDetails.scss";
import RiskVendorQuestionnaires from "../views/VendorPortfolioRiskProfile/RiskVendorQuestionnaires";
import { History } from "history";
import { CloudscanInRemediation } from "../../_common/types/remediation";
import {
  VendorSummaryRisk,
  VendorSummaryRiskType,
  VendorSummaryRiskSurvey,
} from "../../_common/types/vendorSummary";
import RiskVendorDomains from "../components/RiskVendorDomains";
import { WaivedAssets } from "../reducers/risks.actions";
import RiskSummaryComment from "./RiskSummaryComment";
import { WaiverType } from "../../_common/types/vendorRiskWaivers";
import {
  VendorRiskWaiversLocationState,
  VendorRiskWaiverTabType,
} from "../views/VendorRiskWaivers";
import RiskSaaSUsers from "../../userbase/components/risks/RiskSaaSUsers";
import RiskRepositories from "../../appguard/components/RiskRepositories";
import RiskAffectedManifests from "../../appguard/components/RiskAffectedManifests";
import { riskProfileUrl } from "../../userbase/UserBaseAppRouter";
import { useHistory, useLocation } from "react-router-dom";

export interface RiskDescriptionRisk {
  riskType: VendorSummaryRiskType;
  description: string;
  saasAppName?: string;
  summary?: string;
}

export const RiskDescription: FC<{
  risk: RiskDescriptionRisk;
}> = ({ risk }) => {
  const history = useHistory();
  const { search } = useLocation();

  if (risk.riskType === RiskProfileRiskTypes.Evidence) {
    return (
      <p>
        {"This risk was raised as a result of reviewing additional evidence."}
      </p>
    );
  }

  if (risk.riskType == RiskProfileRiskTypes.SecurityProfile) {
    return <p>This risk was raised as a result of security profile scanning</p>;
  }

  if (
    risk.riskType === RiskProfileRiskTypes.SaaS &&
    risk.saasAppName &&
    risk.description.startsWith(risk.saasAppName)
  ) {
    // for saas risks if the risk description starts with the app
    // name we want to create a link to the UserBase SaaS app page
    // from the risk description
    const description = risk.description.replace(risk.saasAppName, "");
    const onClick = () => {
      if (risk.saasAppName) {
        const queryParams = search.replace(/^\?/, "").split("&");
        queryParams.push(`saasAppName=${risk.saasAppName}`);
        history.push(`${riskProfileUrl}?${queryParams.join("&")}`, {
          sidePanelBackText: "Back to risk details",
        });
      }
    };
    return (
      <div className={"risk-description-details"}>
        <span className={"saas-app-link"} onClick={onClick}>
          {risk.saasAppName}
        </span>
        <span dangerouslySetInnerHTML={{ __html: `${description}` }} />
      </div>
    );
  }
  return (
    <div className={"risk-description-details"}>
      {risk.summary ? (
        <p>{risk.summary}</p>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: `${risk.description}`,
          }}
        />
      )}
    </div>
  );
};

export type IRiskSurvey = VendorSummaryRiskSurvey;

export type IRisk = VendorSummaryRisk;

export type ICloudscansInRemediation = Record<
  string,
  CloudscanInRemediation | undefined
>;

export interface IRiskSummaryDetailsProps {
  risk: IRisk;
  vendorId?: number;
  isSubsidiary?: boolean;
  forSpecificAssessment?: number;
  forPublishedAssessment?: boolean;
  cloudscansInRemediation?: ICloudscansInRemediation;
  onOpenCloudscanPanel?: (hostname: string, riskId: string) => void;
  onOpenCVEPanel?: (vuln: any, verified: any) => void;
  onRequestRemediationForRisk?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean
  ) => void;
  onCreateRiskWaiver?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean,
    waiverType?: WaiverType
  ) => void;
  onCreateRiskAdjustment?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean,
    waiverType?: WaiverType
  ) => void;
  onQuestionnaireClicked: (survey: IRiskSurvey) => void;
  userHasWriteRiskWaiversPermission?: boolean;
  editableDomainPortfolioIds?: number[];
  getCustomerRiskHostnames: (riskId: string) => string[] | undefined;
  history: History;
  urlPrefix: () => string;
  onToggleCloudscanDiffPanel?: (
    hostname: string,
    startDate: number,
    endDate: number,
    openInitialId: string
  ) => void;
  changesMode?: boolean;
  changesStartDate?: number;
  changesEndDate?: number;
  isPassive?: boolean;
  readOnly?: boolean;
  waivedAssets?: WaivedAssets;
  isRisksNowScored?: boolean;
  showWaived?: boolean;
  noDetectedDates?: boolean;
  backToText?: string;
  riskComment?: string;
  onUpdateComment?: (val: string) => void;
  hideWaivedOrAdjustedStatus?: boolean;
  hideRepositoryColumn?: boolean;
}

const RiskSummaryDetails: FC<IRiskSummaryDetailsProps> = ({
  risk,
  vendorId,
  isSubsidiary,
  forSpecificAssessment,
  forPublishedAssessment,
  cloudscansInRemediation,
  onOpenCloudscanPanel,
  onOpenCVEPanel,
  onRequestRemediationForRisk,
  onCreateRiskWaiver,
  onCreateRiskAdjustment,
  onQuestionnaireClicked,
  userHasWriteRiskWaiversPermission,
  editableDomainPortfolioIds,
  getCustomerRiskHostnames,
  history,
  urlPrefix,
  onToggleCloudscanDiffPanel,
  changesMode,
  changesStartDate = 0,
  changesEndDate = 0,
  isPassive,
  readOnly,
  waivedAssets,
  isRisksNowScored,
  showWaived = false,
  noDetectedDates = false,
  backToText,
  riskComment,
  onUpdateComment,
  hideWaivedOrAdjustedStatus,
  hideRepositoryColumn,
}) => {
  const getDiffMetaValue = (status: string, meta: string) => {
    if (status === "UNKNOWN") {
      return "[no data]";
    } else if (status === "WAIVED") {
      return "[risk waived]";
    }

    return meta;
  };

  const isCustomer = !vendorId && !isSubsidiary;
  const [allSitesRemediationId, setAllSitesRemediationId] = useState<
    number | undefined
  >(undefined);
  const [sitesInRemediation, setSitesInRemediation] = useState<{
    [key: string]: number[];
  }>({});

  useEffect(() => {
    if (risk.riskType === RiskProfileRiskTypes.Cloudscan) {
      const remediation = cloudscansInRemediation?.[risk.id];
      setAllSitesRemediationId(remediation?.allWebsitesRequestId);
      setSitesInRemediation(remediation?.websites ?? {});
    }
  }, [isPassive, readOnly, risk, cloudscansInRemediation]);

  const navigateToWaiver = (
    id?: number,
    isPublic?: boolean,
    waiverType?: WaiverType
  ) => {
    (history as History<VendorRiskWaiversLocationState>).push(
      `${urlPrefix()}/riskwaivers`,
      {
        waiverId: !isPublic ? id : undefined,
        publicWaiverId: isPublic ? id : undefined,
        currentTab:
          waiverType === WaiverType.SeverityAdjustment
            ? VendorRiskWaiverTabType.SeverityAdjustments
            : VendorRiskWaiverTabType.Waivers,
        backContext: {
          goBack: true,
          backToText: backToText ?? "Back to Risk Profile",
        },
      }
    );
  };

  if (changesMode) {
    const cloudscanDiffsHeaders = [];
    if (risk.cloudscanDiffs && risk.cloudscanDiffs.length > 0) {
      cloudscanDiffsHeaders.push(
        {
          id: "hostname",
          text: "Hostname",
          sortable: false,
        },
        {
          id: "expected",
          text: "Expected",
          sortable: false,
        }
      );

      if (!isRisksNowScored) {
        cloudscanDiffsHeaders.push({
          id: "from",
          text: `From: ${moment(changesStartDate).format("D MMM, YYYY")}`,
          sortable: false,
        });
      }

      cloudscanDiffsHeaders.push(
        {
          id: "to",
          text: isRisksNowScored
            ? `Actual`
            : `To: ${moment(changesEndDate).format("D MMM, YYYY")}`,
          sortable: false,
        },
        {
          id: "changed",
          text: isRisksNowScored ? `Date scored` : `Date Changed`,
          sortable: false,
        }
      );
    }

    const vendorDiffHeaders = [];
    if (!!risk.vendorDiff && !!risk.vendorDiff.expected) {
      vendorDiffHeaders.push({
        id: "expected",
        text: "Expected",
        sortable: false,
      });

      if (!isRisksNowScored) {
        vendorDiffHeaders.push({
          id: "from",
          text: `From: ${moment(changesStartDate).format("ll")}`,
          sortable: false,
        });
      }

      vendorDiffHeaders.push({
        id: "to",
        text: isRisksNowScored
          ? `Actual`
          : `To: ${moment(changesEndDate).format("ll")}`,
        sortable: false,
      });
    }

    return (
      <div className="risk-summary-details">
        <div className="description-text">
          <RiskDescription risk={risk} />
        </div>
        {risk.cloudscanDiffs && risk.cloudscanDiffs.length > 0 && (
          <XTable
            stickyColumnHeaders={false}
            className="changes-table"
            columnHeaders={cloudscanDiffsHeaders}
            rows={risk.cloudscanDiffs.map((diff) => ({
              id: diff.hostname,
              onClick: () =>
                onToggleCloudscanDiffPanel
                  ? onToggleCloudscanDiffPanel(
                      diff.hostname,
                      changesStartDate,
                      changesEndDate,
                      risk.id
                    )
                  : undefined,
              cells: [
                <XTableCell key="hostname" className="hostname-cell">
                  {diff.hostname}
                </XTableCell>,
                <XTableCell key="expected" className="pre">
                  {diff.property}: {diff.expected}
                </XTableCell>,
                !isRisksNowScored && (
                  <XTableCell key="from" className="pre">
                    {getDiffMetaValue(diff.statusA, diff.metaValueA)}
                  </XTableCell>
                ),
                <XTableCell key="to" className="pre">
                  {getDiffMetaValue(diff.statusB, diff.metaValueB)}
                </XTableCell>,
                <XTableCell key="changed">
                  {moment(diff.dateB).format("D MMM, YYYY")}
                </XTableCell>,
              ],
            }))}
          />
        )}
        {!!risk.vendorDiff && !!risk.vendorDiff.expected && (
          <XTable
            stickyColumnHeaders={false}
            className="changes-table"
            columnHeaders={vendorDiffHeaders}
            rows={[
              {
                id: "vendor-diff",
                cells: [
                  <XTableCell key="expected" className="pre">
                    {risk.vendorDiff.expected}
                  </XTableCell>,
                  !isRisksNowScored && (
                    <XTableCell key="from" className="pre">
                      {getDiffMetaValue(
                        risk.vendorDiff.statusA,
                        risk.vendorDiff.metaValueA
                      )}
                    </XTableCell>
                  ),
                  <XTableCell key="to" className="pre">
                    {getDiffMetaValue(
                      risk.vendorDiff.statusB,
                      risk.vendorDiff.metaValueB
                    )}
                  </XTableCell>,
                ],
              },
            ]}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="risk-summary-details">
        <div className={"risk-expanded-content"}>
          {!readOnly && onUpdateComment && (
            <RiskSummaryComment
              comment={riskComment ?? ""}
              title={"risk treatment plan"}
              readonly={!!readOnly}
              onUpdateComment={onUpdateComment}
            />
          )}
          <div className={"risk-top-line"}>
            <div className="risk-detail">
              <div className="description-text">
                <span className="description-header">Overview:</span>
                <RiskDescription risk={risk} />
              </div>
            </div>
          </div>
          <div className="risk-detail-rest">
            <div className="failed-entities-container">
              {risk.numFailedCloudscans > 0 && (
                <>
                  <div className="failed-entities-list">
                    <RiskVendorDomains
                      userHasWriteRiskWaiversPermission={
                        userHasWriteRiskWaiversPermission
                      }
                      editableDomainPortfolioIds={editableDomainPortfolioIds}
                      vendorId={vendorId}
                      riskId={risk.id}
                      isSubsidiary={isSubsidiary ?? false}
                      forSpecificAssessment={forSpecificAssessment}
                      onClickDomain={(
                        riskId: string,
                        _vendorId: number | undefined,
                        hostname: string
                      ) =>
                        onOpenCloudscanPanel
                          ? onOpenCloudscanPanel(hostname, riskId)
                          : undefined
                      }
                      allDomainsRemediationID={allSitesRemediationId}
                      domainsInRemediation={sitesInRemediation}
                      onRequestRemediation={
                        onRequestRemediationForRisk
                          ? (domain: string) => {
                              onRequestRemediationForRisk(
                                risk,
                                domain,
                                undefined
                              );
                            }
                          : undefined
                      }
                      onCreateWaiver={
                        onCreateRiskWaiver
                          ? (hostname: string) => {
                              onCreateRiskWaiver(
                                risk,
                                hostname,
                                undefined,
                                undefined
                              );
                            }
                          : undefined
                      }
                      isPassive={isPassive}
                      readonly={readOnly}
                      passed={risk.passed}
                      isWaived={risk.isWaived ?? false}
                      waivedAssets={waivedAssets}
                      onViewWaivers={navigateToWaiver}
                      onViewRemediations={(remediationRequestID?: number) => {
                        if (vendorId) {
                          let location = `${urlPrefix()}/remediation`;
                          if (remediationRequestID) {
                            location += `/${remediationRequestID}`;
                          }
                          history.push(location, {
                            backContext: {
                              goBack: true,
                              backToText: backToText ?? "Back to Risk Profile",
                            },
                          });
                        } else {
                          history.push(`/selfremediation`, {
                            backContext: {
                              goBack: true,
                              backToText: backToText ?? "Back to Risk Profile",
                            },
                          });
                        }
                      }}
                      showWaived={showWaived}
                      noDetectedDates={noDetectedDates}
                      hideWaivedOrAdjustedStatus={hideWaivedOrAdjustedStatus}
                    />
                  </div>
                </>
              )}
              {risk.surveys && risk.surveys.length > 0 && (
                <>
                  <div className="failed-entities-list">
                    <RiskVendorQuestionnaires
                      loading={false}
                      questionnaires={risk.surveys}
                      risk={risk}
                      onQuestionnaireLinkClick={onQuestionnaireClicked}
                      userHasWriteRiskWaiversPermission={
                        userHasWriteRiskWaiversPermission
                      }
                      onRequestRemediation={
                        onRequestRemediationForRisk
                          ? (surveyId: number, isPublicSurvey: boolean) => {
                              onRequestRemediationForRisk(
                                risk,
                                undefined,
                                surveyId,
                                isPublicSurvey
                              );
                            }
                          : undefined
                      }
                      onCreateWaiver={
                        onCreateRiskWaiver
                          ? (surveyId: number, isPublic: boolean | null) => {
                              onCreateRiskWaiver(
                                risk,
                                undefined,
                                surveyId,
                                isPublic ?? undefined,
                                WaiverType.RiskWaiver
                              );
                            }
                          : undefined
                      }
                      onCreateAdjustment={
                        onCreateRiskAdjustment
                          ? (surveyId: number, isPublic: boolean | null) => {
                              onCreateRiskAdjustment(
                                risk,
                                undefined,
                                surveyId,
                                isPublic ?? undefined,
                                WaiverType.SeverityAdjustment
                              );
                            }
                          : undefined
                      }
                      isPassive={isPassive}
                      readOnly={readOnly}
                      waivedAssets={waivedAssets}
                      onViewWaivers={navigateToWaiver}
                      onViewRemediations={(remediationRequestID?: number) => {
                        let location = `${urlPrefix()}/remediation`;
                        if (remediationRequestID) {
                          location += `/${remediationRequestID}`;
                        }
                        history.push(location, {
                          backContext: {
                            goBack: true,
                            backToText: backToText ?? "Back to Risk Profile",
                          },
                        });
                      }}
                      showWaived={showWaived}
                    />
                  </div>
                </>
              )}
              {risk.additionalEvidences &&
                risk.additionalEvidences.length > 0 && (
                  <>
                    <div className="description-header">
                      Raised in additional evidence:
                    </div>
                    <div className="failed-entities-list">
                      {risk.additionalEvidences.map((ev: any) => (
                        <div
                          key={ev.id}
                          className="clickable"
                          onClick={() => {
                            if (!ev.vendorId) {
                              // Weird - these should all have vendorIDs.
                              return;
                            }

                            history.push(
                              `${urlPrefix()}/evidence/details/${ev.id}` +
                                (forSpecificAssessment && forPublishedAssessment
                                  ? `/byassessment/${forSpecificAssessment}`
                                  : ""),
                              {
                                backContext: {
                                  goBack: true,
                                  backToText:
                                    backToText ?? "Back to Risk Profile",
                                },
                              }
                            );
                          }}
                        >
                          <div className="entity">{ev.name}</div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              {risk.saasUsers && risk.saasUsers.length > 0 && (
                <>
                  <div className="failed-entities-list">
                    <RiskSaaSUsers
                      riskId={risk.id}
                      showWaived={showWaived}
                      waivedAssets={waivedAssets}
                    />
                  </div>
                </>
              )}
              {risk.manifests && risk.manifests.length > 0 && (
                <div className="failed-entities-list">
                  <RiskAffectedManifests
                    risk={risk}
                    hideRepositoryColumn={hideRepositoryColumn}
                    showWaived={showWaived}
                    waivedAssets={waivedAssets}
                  />
                </div>
              )}
              {risk.repositories && risk.repositories.length > 0 && (
                <div className="failed-entities-list">
                  <RiskRepositories
                    risk={risk}
                    showWaived={showWaived}
                    waivedAssets={waivedAssets}
                  />
                </div>
              )}
            </div>
            {IsCVERisk(risk.id) && onOpenCVEPanel && (
              <div className="cve-link">
                <a
                  className="arrow-link"
                  onClick={() =>
                    onOpenCVEPanel(
                      GetCVEFromRiskID(risk.id),
                      IsVerifiedCVE(risk.id)
                    )
                  }
                >
                  View CVE details <Icon name="arrow" direction={90} />
                </a>
              </div>
            )}
            {IsCPERisk(risk.id) &&
              onOpenCVEPanel &&
              (!isCustomer || !!getCustomerRiskHostnames(risk.id)) && (
                <>
                  <div className={"cve-mini-title"}>
                    {"Potential Vulnerabilities"}
                  </div>
                  <CVEMiniList
                    cpeName={GetCPEFromRiskID(risk.id)}
                    isCustomer={isCustomer}
                    hostnames={getCustomerRiskHostnames(risk.id)}
                    onClickCVE={(riskID) => onOpenCVEPanel(riskID, false)}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
};

export default RiskSummaryDetails;
